export const formFields = t => {
  return [
    {
      fields: [
        {
          name: "commission",
          title: t("config.comition"),
          placeHolder: t("config.comition"),
          type: "Input",
          colSize: 16,
          validation: {
            // required: true,
            custom: vals =>
              (vals.commission === null) || !isNaN(parseInt(vals.commission)),
            errorMessage: t("commons.mustBeNumber"),
            regexType: "double"
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "linkValidity",
          title: t("config.validTime"),
          placeHolder: t("config.validTime"),
          type: "Input",
          colSize: 16,
          props: {
            type: "number"
          },
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
        }
      ]
    },
    {
      fields: [
        {
          name: "blockTime",
          title: t("config.blokedTime"),
          placeHolder: t("config.blokedTime"),
          type: "Input",
          colSize: 16,
          props: {
            type: "number"
          },
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
        }
      ]
    },
    {
      fields: [
        {
          title: 'TPV',
          type: "Divider",
        }
      ]
    }
  ];
};

export const formNotificationFields = t => {
  return [
    {
      fields: [
        {
          title: t("config.notifications"),
          type: "Divider",
        }
      ]
    },
    {
      
      fields: [
        {
          name: "notifications_answer_mails",
          title: t("config.notifications_answer_mails"),
          placeHolder: t("config.notifications_answer_mails"),
          type: "Input",
          colSize: 16,
          validation: {
            errorMessage: t("commons.INVALID_EMAIL"),
            regexType: "email"
          }
        }
      ]
    }
  ];
};
