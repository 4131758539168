import { clientApi } from "./index";

export default {
  getCategory: async id => {
    try {
      const response = await clientApi.getClient().get(`/category/${id}`);
      return response.data;
    } catch (error) {}
  },
  getByModality: async ({ id, params }) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/modality/${id}/categories`, { params: { ...params, channel_id: 1 }});
      return response.data;
    } catch (err) {}
  },
  createCategory: async params => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/categories`, { ...params, channel_id: 1});
      return response.data;
    } catch (error) {}
  },
  updateCategory: async params => {
    try {
      const { id } = params;
      const response = await clientApi
        .getClient()
        .put(`/category/${id}`, { ...params, channel_id: 1});
      return response.data;
    } catch (error) {}
  },
  deleteCategory: async id => {
    try {
      const response = await clientApi.getClient().delete(`/category/${id}`, { params: { channel_id: 1 }});
      return response.data;
    } catch (error) {}
  }
};
