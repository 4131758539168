export const backwardsItems = (t, raceId, isEvent = false, isAssociate = false) => [
  {
    title: t("raceDetail.menu.modality.modalities"),
    icon: "arrow alternate circle left",
    customOnClick: `/race/${raceId}#modalities`,
  },
  {
    title: isEvent
      ? t("raceDetail.menu.modality.event")
      : isAssociate
      ? t("raceDetail.menu.modality.associate")
      : t("raceDetail.menu.modality.race"),
    icon: "arrow alternate circle left outline",
    customOnClick: `/race/${raceId}#summary`,
  },
];

export const items = (t, isEventOrAssociate = false) => [
  {
    title: t("raceDetail.menu.modality.detail"),
    name: "summary",
    href: "summary"
  },
  {
    title: t("raceDetail.menu.modality.edit"),
    name: "edit",
    href: "edit"
  },
  {
    title: t("raceDetail.menu.modality.inscriptionsConfiguration"),
    name: "inscriptionconfig",
    href: "inscriptionconfig"
  },
  {
    title: t("raceDetail.menu.modality.prices"),
    name: "prices",
    href: "prices"
  },
  {
    title: t("raceDetail.menu.modality.inscriptions"),
    name: "inscriptions",
    href: "inscriptions"
  },
  {
    title: t("raceDetail.menu.modality.attributes"),
    name: "attributes",
    href: "attributes"
  },
  {
    title: t("raceDetail.menu.modality.categories"),
    name: "categories",
    href: "categories"
  },
  ...(!isEventOrAssociate ? [
    {
      title: t("raceDetail.menu.modality.teamTypes"),
      name: "teamtypes",
      href: "teamtypes"
    }
  ]: [])
];
