import moment from "moment";
import { dateIsBetween, getDatesErrorMessage } from "../../../util/util";

export const filterFields = (t, usedCombo) => [
  {
    div: "row",
    fields: [
      {
        name: "createdAt",
        placeHolder: t("raceDiscounts.table.createdAt"),
        type: "DatePicker",
        colSize: 4
      },
      {
        name: "discountCode",
        placeHolder: t("raceDiscounts.table.discountCode"),
        type: "Input",
        colSize: 4
      },
      {
        name: "description",
        placeHolder: t("raceDiscounts.table.description"),
        type: "Input",
        colSize: 4
      },
      {
        name: "used",
        placeHolder: t("raceDiscounts.table.used"),
        data: usedCombo,
        type: "Select",
        colSize: 4
      }
    ]
  }
];

export const tableHeaders = t => [
  {
    content: t("raceDiscounts.table.createdAt"),
    accessor: "createdAt"
  },
  {
    content: t("raceDiscounts.table.discountCode"),
    accessor: "discountCode"
  },
  {
    content: t("raceDiscounts.table.value"),
    accessor: "value"
  },
  {
    content: t("raceDiscounts.table.used"),
    accessor: "inscriptionId"
  },
  {
    content: t("raceDiscounts.table.description"),
    accessor: "description"
  },
  {
    content: t("raceDiscounts.table.limitDate"),
    accessor: "limitDate"
  },
  {
    content: t("raceDiscounts.table.booked"),
    accessor: "booked"
  }
];

export const formFields = (t, discountType, raceStartDate, raceFinishDate) => [
  {
    fields: [
      {
        name: "totalCodes",
        title: t("raceDiscounts.createForm.totalCodes"),
        placeHolder: t("raceDiscounts.createForm.totalCodes"),
        type: "Input",
        colSize: 8,
        validation: {
          required: true,
          custom: vals =>
            vals.totalCodes ? !isNaN(parseInt(vals.totalCodes)) : false,
          errorMessage: t("commons.mustBeNumber")
        }
      },
      {
        name: "limitDate",
        title: t("raceDiscounts.createForm.limitDate"),
        placeHolder: t("raceDiscounts.createForm.limitDate"),
        type: "DatePicker",
        colSize: 10,
        props:{
          id :'0'
        },
        validation: {
          custom: vals => {
            if (vals.limitDate) {
              return vals.limitDate <= raceStartDate
            }
            return {
              valid: false,
              errorMessage: t("racesScreen.startDateLowerThanRaceDate", {
                startDate: moment(raceStartDate).format("LL")
              })
          }
        },
        }
      }
    ]
  },
  {
    fields: [
      {
        name: "description",
        title: t("raceDiscounts.createForm.description"),
        placeHolder: t("raceDiscounts.createForm.description"),
        type: "Input",
        colSize: 16,
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired")
        },
        props: {
          onKeyPress: (e) => {
            if (e.which == 35) {
              e.preventDefault();
              return false;
            }
          },
        },
      }
    ]
  },
  {
    fields: [
      {
        name: "amount",
        title: t("raceDiscounts.createForm.amount"),
        placeHolder: t("raceDiscounts.createForm.amount"),
        type: "Input",
        colSize: 8,
        props: {
          icon: discountType === "VAL" ? "euro" : "percent"
        },
        validation: {
          required: true,
          custom: vals =>
            vals.amount
              ? !isNaN(parseFloat(vals.amount.replace(",", ".")))
              : false,
          errorMessage: t("commons.mustBeNumber")
        }
      },
      {
        name: "discountType",
        title: t("raceDiscounts.createForm.type"),
        type: "RadioButtons",
        colSize: 8,
        deflt: "PCT",
        radios: [
          {
            label: t("raceDiscounts.createForm.typePCT"),
            value: "PCT"
          },
          {
            label: t("raceDiscounts.createForm.typeVAL"),
            value: "VAL"
          }
        ]
      }
    ]
  }
];
