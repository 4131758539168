//@ts-check
import React, { Component } from "react";

import {
  Container,
  Loader,
  Segment,
  Header,
  Divider,
  Dimmer,
  Grid,
  Button,
} from "semantic-ui-react";

import { withTranslation } from "react-i18next";

import DTable from "../../../components/DTable";
import {
  tableHeaders,
  tableHeadersDetail,
  tableHeadersEvent,
  tableHeadersPanel,
  tableHeadersCommission,
} from "./util";

import racesApi from "../../../apis/races";
import AsyncButton from "../../../components/AsyncButton";
import fileDownload from "js-file-download";

@withTranslation()
export default class Accounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      // raceId: null,
      data: [],
      count: {},
      detail: {
        title: "",
        rows: [],
        footer: {},
      },
      modalitiesTables: [],
      panel: [],
      commissionTable: [],
      generatingPdf: false,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    racesApi.getAccountData(id).then((response) => {
      this.setState({ count: response });
      // this.setState({ raceId: response.detail.raceId })
      this.addRowNames(response);
      this.setDetailTable(response);
      this.setState({ loading: false });
      this.setModalitiesTables(response);
      this.setPanelTable(response);
      this.setComissionTable(response);
    });
  }

  async getAccountCsv() {
    const { id } = this.props;
    const response = await racesApi.accountsCsv(id);

    if (response && response.status == 200) {
      const file = response.data;
      fileDownload("\uFEFF" + file, "account.csv");
    }
  }

  handleTitleClick = (e, itemProps) => {
    const { index } = itemProps;
    const { activeIndex } = this.state;
    let newState;

    if (activeIndex.indexOf(index) > -1) {
      newState = activeIndex.filter((i) => i !== index);
    } else {
      newState = [...activeIndex, index];
    }

    this.setState({ activeIndex: newState });
  };
  // componentDidUpdate() {
  //   const { t, history } = this.props;
  //   const { data, count } = this.state;

  //   const translation = t("raceAccounts.tableRows.withTpv");
  //   const current = data[0] ? data[0].name : "";

  //   if (translation !== current) {
  //     this.addRowNames(count);
  //   }
  // }

  addRowNames(count) {
    const { t } = this.props;
    let data = {
      withTpv: {},
      withOutTpv: {},
      cancelled: {},
      cancelledRefund: {},
      cancelledNotRefund: {},
      paidInPanel: {},
      total: {},
    };

    Object.entries(count).forEach((item) => {
      if (item[0] !== "detail") {
        data[item[0]]["name"] = t(`raceAccounts.tableRows.${item[0]}`);
        data[item[0]]["inscriptions"] = item[1]["count"];
        data[item[0]]["price"] = item[1]["price"];
      }
    });

    this.setState({
      data: [
        { ...data.withTpv },
        { ...data.withOutTpv },
        { ...data.cancelled },
        { ...data.cancelledRefund },
        { ...data.cancelledNotRefund },
        { ...data.paidInPanel },
        { ...data.total },
      ],
    });
  }
  setDetailTable(response) {
    const { t } = this.props;
    const { detail } = response;
    const { title, total, attributes, refunds, discounts, footer } = detail;

    const detailRows = [
      {
        name: t(`raceAccounts.tableRows.${total.name}`),
        inscriptions: total.count,
        price: total.price,
      },
      ...attributes.map((row) => {
        return {
          name: row.name,
          inscriptions: row.count,
          price: row.price,
        };
      }),
      {
        name: t(`raceAccounts.tableRows.${discounts.name}`),
        inscriptions: discounts.count,
        price: discounts.price,
      },
      {
        name: t(`raceAccounts.tableRows.${refunds.name}`),
        inscriptions: refunds.count,
        price: refunds.price,
      },
      {
        name: t(`raceAccounts.tableRows.${footer.name}`),
        inscriptions: "",
        price: footer.price,
      },
    ];

    this.setState({
      detail: {
        title,
        rows: [...detailRows],
      },
    });
  }
  setModalitiesTables(response) {
    const { t } = this.props;

    const { modalitiesDetail: modalities } = response.detail;
    // console.log({ modalities });

    let key = 0;
    const modalitiesTables = modalities.map((modality) => {
      // console.log({ modality });
      const {
        modalityName,
        modalityAttributes,
        discounts,
        refunds,
        footer,
        basePrice,
      } = modality;

      const detail = [
        {
          name: t(`raceAccounts.tableRows.${basePrice.name}`),
          inscriptions: basePrice.count,
          price: basePrice.price,
        },
        ...modalityAttributes.map((row) => {
          // console.log({ row });

          return {
            name: row.name,
            inscriptions: row.count,
            price: row.price,
          };
        }),
        {
          name: t(`raceAccounts.tableRows.${discounts.name}`),
          inscriptions: discounts.count,
          price: discounts.price,
        },
        {
          name: t(`raceAccounts.tableRows.${refunds.name}`),
          inscriptions: refunds.count,
          price: refunds.price,
        },
        {
          name: t(`raceAccounts.tableRows.${footer.name}`),
          inscriptions: "",
          price: footer.price,
        },
      ];
      // console.log({detailRows});
      return {
        key: key++,
        title: modalityName,
        detail,
      };
    });
    // console.log({ modalitiesTables });

    this.setState({
      modalitiesTables,
    });
  }

  setPanelTable(response) {
    const { t } = this.props;

    const { paidInPanel } = response;
    const { count, price } = paidInPanel;

    const panelTable = [
      {
        name: t(`raceAccounts.tableRows.paidInPanel`),
        inscriptions: count,
        price,
      },
    ];

    this.setState({
      panelTable,
    });
  }

  setComissionTable(response) {
    const { t } = this.props;

    const { commissions, commissionsList } = response.detail;

    const commissionTable = [
      ...commissionsList.map((o) => {
        return {
          name:
            o.tpv ||
            t(`raceAccounts.tableRows.comision_inscripciones_gratuitas`),
          inscriptions: o.count,
          price: o.price,
        };
      }),
      {
        name: t(`raceAccounts.tableRows.${commissions.name}`),
        inscriptions: commissions.count,
        price: commissions.price,
      },
    ];

    this.setState({
      commissionTable,
    });
  }

  async downloadPdf() {
    const { id } = this.props;
    this.setState({ loading: true, generatingPdf: true });
    const response = await racesApi.getPdfAccountData(id);
    this.setState({ loading: false, generatingPdf: false });
  }

  render() {
    const { t, history } = this.props;
    const {
      data,
      detail,
      loading,
      modalitiesTables,
      panelTable,
      commissionTable,
      generatingPdf,
    } = this.state;

    return (
      <Container>
        {loading ? (
          // <Dimmer active style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          <Dimmer active style={{ height: "1400px" }}>
            <Loader>
              {generatingPdf
                ? t("raceAccounts.waitForPdf")
                : t("raceAccounts.wait")}
            </Loader>
          </Dimmer>
        ) : (
          <div>
            <Button
              style={{ margin: "5px 2px" }}
              // floated="left"
              // fluid={isMobile}
              color="vk"
              onClick={() => this.downloadPdf()}
            >
              {t("raceAccounts.downloadPdf")}
            </Button>

            <AsyncButton onClick={() => this.getAccountCsv()}>
              {t("raceAccounts.downloadCSV")}
            </AsyncButton>

            <Segment>
              <Header as="h2"> {t("raceAccounts.total")}</Header>
              <DTable
                style={{ cursor: "default" }}
                headers={tableHeaders(t)}
                contentData={data}
                loading={loading}
                showPagination={false}
                parseData={(row) => ({
                  price: `${parseFloat(row.price).toFixed(2)} €`,
                })}
                showItemsPerPage={false}
              />{" "}
              <Divider />
              {t("raceAccounts.tableTitles.main")}
              <DTable
                style={{ cursor: "default" }}
                headers={tableHeadersDetail(t)}
                contentData={detail.rows}
                loading={loading}
                showPagination={false}
                parseData={(row) => ({
                  price: `${parseFloat(row.price).toFixed(2)} €`,
                })}
                showItemsPerPage={false}
              />{" "}
              <Divider />
              {modalitiesTables && (
                <div>
                  {t("raceAccounts.tableTitles.event")}
                  {modalitiesTables.map((modalityTable) => (
                    <DTable
                      key={modalityTable.key}
                      style={{ cursor: "default" }}
                      headers={tableHeadersEvent(t, modalityTable.title)}
                      contentData={modalityTable.detail}
                      loading={loading}
                      showPagination={false}
                      parseData={(row) => ({
                        price: `${parseFloat(row.price).toFixed(2)} €`,
                      })}
                      showItemsPerPage={false}
                    />
                  ))}
                </div>
              )}
              <Header as="h2"> {t("raceAccounts.tableTitles.panel")}</Header>
              <DTable
                style={{ cursor: "default" }}
                headers={tableHeadersPanel(t)}
                contentData={panelTable}
                loading={loading}
                showPagination={false}
                parseData={(row) => ({
                  price: `${parseFloat(row.price).toFixed(2)} €`,
                })}
                showItemsPerPage={false}
              />{" "}
              <Divider></Divider>
              <Header as="h2">
                {" "}
                {t("raceAccounts.tableTitles.commission")}
              </Header>
              <DTable
                style={{ cursor: "default" }}
                headers={tableHeadersCommission(t)}
                contentData={commissionTable}
                loading={loading}
                showPagination={false}
                parseData={(row) => ({
                  price: `${parseFloat(row.price).toFixed(2)} €`,
                })}
                showItemsPerPage={false}
              />{" "}
              <Divider></Divider>
            </Segment>
          </div>
        )}
      </Container>
    );
  }
}
