import React, { Component } from "react";
import { Container, Loader, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import { getRace } from "../../reducers/races";

import NoMatch from "../../components/NoMatch";
import RaceUsers from "./RaceUsers";
import RaceMenu from "../../components/RaceMenu";
import RaceDetail from "./RaceDetail";
import RaceEdit from "./RaceEdit";
import RaceInscriptions from "./RaceInscriptions";
import RaceClassifications from "./RaceClassifications";
import RaceDiscounts from "./RaceDiscounts";
import RaceModalities from "./RaceModalities";
import RaceAccounts from "./RaceAccounts";
import RaceDocs from "./RaceDocs";

@withTranslation()
@connect(
  state => ({
    race: state.races.actualRace,
    rol: state.user.role
  }),
  dispatch => ({
    actions: bindActionCreators({ getRace }, dispatch)
  })
)
export default class Race extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { match, actions } = this.props;
    const {
      params: { id }
    } = match;
    actions.getRace(id);
  }

  getRoutes(hash) {
    const { match, history, rol } = this.props;
    const {
      params: { id }
    } = match;

    if (!hash) {
      hash = "summary";
    }

    const scenario = {
      ADMINISTRATOR: ["*"],
      PLANNER: ["summary", "edit", "inscriptions", "classifications", "users", "discounts", "modalities", "documents"],
      COMPANY: ["*"],
      POINT_OF_SALE: ["summary", "inscriptions", "documents"]
    };

    const componentRoutes = {
      summary: <RaceDetail id={id} history={history} />,
      edit: <RaceEdit history={history} />,
      inscriptions: <RaceInscriptions id={id} history={history} />,
      classifications: <RaceClassifications id={id} history={history} />,
      users: <RaceUsers id={id} />,
      discounts: <RaceDiscounts id={id} history={history} />,
      modalities: <RaceModalities id={id} history={history} />,
      accounts: <RaceAccounts id={id} history={history} />,
      documents: <RaceDocs id={id} />
    };

    let RbacComponentRoutes = {};

    const type = scenario[rol];

    if (type[0].includes("*")) {
      RbacComponentRoutes = componentRoutes;
    } else {
      scenario[rol].forEach(view => {
        RbacComponentRoutes[view] = componentRoutes[view];
      });
    }

    return RbacComponentRoutes[hash] ? RbacComponentRoutes[hash] : <NoMatch />;
  }

  onItemClick = item => {
    const { history } = this.props;

    history.push(item);
  };

  render() {
    const { location, match, race, t } = this.props;
    const hash = location.hash.split("#")[1];
    const raceDeleted = race.deleted;

    if (!race?.id) return <Loader />;
    return (
      <React.Fragment>
        {raceDeleted &&
          (<Segment padded color="red" textAlign="center" inverted>{t('racesScreen.detail.deleted')}</Segment>)
        }
        <RaceMenu
          raceId={match.params.id}
          activeItem={!hash ? "summary" : hash}
          onItemClick={this.onItemClick}
        />


        {this.getRoutes(hash)}
      </React.Fragment>
    );
  }
}
