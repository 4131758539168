import React from "react";
import { Responsive, Button } from "semantic-ui-react";

const FluidResponsiveButton = props => {
  const createResponsiveButton = isFluid => {
    return (
      <Button {...props} fluid={isFluid}>
        {props.children}
      </Button>
    );
  };

  return (
    <div>
      <Responsive minWidth={768}>{createResponsiveButton(false)}</Responsive>
      <Responsive maxWidth={767}>{createResponsiveButton(true)}</Responsive>
    </div>
  );
};

export default FluidResponsiveButton;
