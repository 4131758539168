export const tableHeaders = t => [
  {
    content: t("modalityAttributes.table.createdAt"),
    accessor: "createdAt"
  },
  {
    content: t("modalityAttributes.table.name"),
    accessor: "name"
  },
  {
    content: t("modalityAttributes.table.type"),
    accessor: "type"
  },
  {
    content: t("modalityAttributes.table.required"),
    accessor: "required"
  },
  {
    content: t("modalityAttributes.table.visible"),
    accessor: "visible"
  },
  {
    content: t("modalityAttributes.table.isToBeDelivered"),
    accessor: "isToBeDelivered"
  }
];
