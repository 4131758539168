import { clientApi } from ".";

export default {
  fetchTypes: async () => {
    try {
      const response = await clientApi.getClient().get("/raceTypes/");
      return response.data;
    } catch (err) {}
  },
  fetchTypesPaginated: async params => {
    try {
      const response = await clientApi
        .getClient()
        .get("/raceTypesPaginated", { params });
      return response.data;
    } catch (err) {}
  },
  addType: async params => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/raceTypes`, { ...params, channel_id: 1 });
      return response;
    } catch (err) {}
  },
  update: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/raceType/${id}`, { ...params, channel_id: 1 });
      return response;
    } catch (err) {}
  },
  fetchActivityTypes: async () => {
    try{
      const response = await clientApi
        .getClient()
        .get(`/activityTypes`, {params: {channel_id: 1}});
      return response.data;
    } catch(err) {}
  }
};
