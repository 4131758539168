import { clientApi } from "./index";

export default {
  get: async params => {
    try {
      const response = await clientApi.getClient().get("/clients", { params: {params, channel_id: 1} });
      return response.data;
    } catch (error) {}
  },
  getCSV: async params => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/clients/csv`, { params : {params, channel_id: 1} });
      return response.data;
    } catch (error) {}
  },
  getClient: async id => {
    try {
      const response = await clientApi.getClient().get(`/client/${id}`, { params: {channel_id: 1} });
      return response.data;
    } catch (error) {}
  },
  create: async params => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/companies`, { params: {...params, channel_id: 1} });
      return response.data;
    } catch (error) {}
  },
  update: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/user/${id}`, { ...params, channel_id: 1 });
      return response.data;
    } catch (error) {}
  }
};
