import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Divider, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import Form from "../../components/Form";
import ButtonGroup from "../../components/ButtonGroup";
import { DAlert } from "../../components/Dalert";
import { modalHandler } from "../../components/DModal";

import { raceDetailForm } from "./util";
import companiesApi from "../../apis/companies";
import racesApi from "../../apis/races";
import raceTypes from "../../apis/raceTypes";
import usersApi from "../../apis/users";
import provincesApi from "../../apis/regions";
import { getCountries } from "../../reducers/countries";
import moment from "moment";

@connect(
  state => ({
    countries: state.countries.countries,
    user: state.user
  }),
  dispatch => ({
    actions: bindActionCreators({ getCountries }, dispatch)
  })
)
@withTranslation()
export default class RaceCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      currentCompany: null,
      raceType: [],
      race: {},
      showValidation: false,
      provinces: [],
      activityTypes: [],
    };
  }

  componentDidMount() {
    const { actions, countries } = this.props;

    actions.getCountries(countries);
    this.fetchProvinces();
    this.getData();

  }

  async fetchProvinces() {
    await provincesApi.fetchRegions().then((res) => {
      this.setState({
        provinces: res.map(provinces => {return {name:provinces.name, slug:provinces.slug}})
      });
    });
  }

  async getData() {
    let companies = [];
    if (this.props.user.role !== 'COMPANY') { //get all companies
      companies = (await companiesApi.get({ pageSize: -1 })).rows.map(company => ({
        value: company.id,
        text: company.name
      }));
    } else {// use logged user company
      const user = await usersApi.fetchUser({ id: this.props.user.id });
      const company = await companiesApi.getCompany(user.company_id);
      this.setState({
        currentCompany: company.id
      })
      // companies = [{
      //   value: company.id,
      //   text: company.name
      // }]
    }

    const raceType = (await racesApi.fetchRaceTypes()).map(raceT => ({
      value: raceT.id,
      text: raceT.type
    }));

    const activityTypes = await raceTypes.fetchActivityTypes();

    this.setState({
      companies,
      raceType,
      activityTypes,
    });
  }

  async saveRace(t, history, race) {
    try {
      const result = await racesApi.create({ ...race, language: "es" });
      if (result.id) {
        DAlert.success({ message: t("commons.savedSuccess") });
        history.push(`/race/${result.id}`);
      } else {
        DAlert.error({ message: t("commons.errorSaving") });
      }
    } catch (error) {
      DAlert.error({ message: t("commons.errorSaving") });
    }

  }


  async save() {
    const { race, currentCompany } = this.state;
    const { t, history } = this.props;

    if (currentCompany) {//logged user is company user
      race.company_id = currentCompany
    }

    this.setState({ showValidation: true });

    if (race.ISFORMVALID) {
      if (race.start_date) {// races can have older date (for classifications upload)
        const startDate = moment(race.start_date);
        if (startDate.isValid()) {
          if (!startDate.isSameOrAfter(moment(new Date()).startOf("day"))) {
            modalHandler.open({
              header: t("racesScreen.modal.dateIsBefore"),
              content: <Modal.Description>{t("commons.areYouSure")}</Modal.Description>,
              basic: false,
              size: "small",
              onYes: async () => {
                this.saveRace(t, history, race);
                modalHandler.close();
              }
            });
          } else {
            this.saveRace(t, history, race);
            modalHandler.close();
          }
        }
      }
    }
  }

  render() {
    const { t, countries, history } = this.props;
    const {
      raceType,
      companies,
      race,
      showValidation,
      provinces,
      activityTypes,
    } = this.state;
    const comesFrom = history?.location?.state?.comesFrom;

    const actTypes = activityTypes.map((actType) => ({
      text: t(`commons.activities.${actType.type}`),
      value: actType.id,
    }));
    const actTypeDefault = activityTypes.find(
      (ac) => ac.type === comesFrom
    );

    return (
      <Container>
        <Form
          defaultState={
            comesFrom && actTypeDefault
              ? {
                  ...race,
                  activityTypeId: actTypeDefault.id,
                }
              : race
          }
          onFormChange={(val) => this.setState({ race: val })}
          fields={raceDetailForm(t, {
            raceType,
            companies,
            countries,
            provinces,
            activityTypes: actTypes,
            comesFrom
          })}
          showValidation={showValidation}
        />
        <Divider />
        <ButtonGroup onSave={() => this.save()} />
      </Container>
    );
  }
}
