import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Container,
  Segment,
  Grid,
  Dimmer,
  Loader,
  Button,
  Modal,
  Divider,
  Table,
  Header,
  Icon,
  Pagination
} from "semantic-ui-react";
import Form from "../../components/Form";
import configApi from "../../apis/appConfig";
import notificationsApi from "../../apis/notifications";
import raceTypesApi from "../../apis/raceTypes";
import { formFields, formNotificationFields } from "./util";
import { DAlert } from "../../components/Dalert";
import { modalHandler } from "../../components/DModal";
import RichTextEditor from "../../components/RichTextEditor";
import TPVConfigHandler from "./TPVHandler"

@withTranslation()
export default class Config extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: {},
      notificationsConfig: {},
      configModal: {},
      t: this.props.t,
      types: [],
      totalPages: 0,
      page: 1,
      loading: false,
      data: {},
      showValidation: false
    };

    this.formHandler = this.formHandler.bind(this);
  }

  componentDidMount() {
    this.fetchConfig();
    this.fetchTypes();
  }

  async fetchTypes(params) {
    if (params === undefined) {
      params = {channel_id : 1}
    } else {
      params["channel_id"] = 1
    }
    await raceTypesApi.fetchTypesPaginated(params).then(types => {
      this.setState({
        types: types.rows,
        totalPages: Math.ceil(types.count / 5)
      });
    });
  }

  fetchConfig() {
    configApi.fetchConfig().then(config => {
      config.commission = config.commission.toString().replace(/\./, ",");
      this.setState({ ...config, config });
      this.setState({ configModal: { ...config } })
    });
    notificationsApi.getNotificationsConfig().then(notificationsConfig => {
      this.setState({ notificationsConfig });
    });
  }

  async updateConfig(t, config) {
    if (config.ISFORMVALID) {
      this.setState({ loading: true });
      const formToSend = {
        ...config,
        commission: parseFloat(config.commission.toString().replace(",", ".")),
        channel_id: 1,
      };
      const {notificationsConfig} = this.state;
      
      await configApi.update(formToSend).then(async config => {
        if (config.status == 200 || config.status == 204) {
          DAlert.success({ title: t("commons.savedSuccess") });
          this.setState({ config: config.data });
        } else {
          DAlert.error({ title: t("commons.errorSaving") });
        }
        this.setState({ loading: false });
      });

      notificationsConfig['channel_id'] = 1;
      await notificationsApi.changeNotificationsConfig(notificationsConfig).then(async config => {
        if (config.id) {
          //DAlert.success({ title: t("commons.savedSuccess") });
        } else {
          DAlert.error({ title: t("commons.errorSaving") });
        }
      });
    }
    this.fetchConfig()
  }

  async edit(id) {
    const { t } = this.props;
    let exist = false;

    this.state.types.map(item => {
      if (
        item.code === this.state.data.code &&
        item.type === this.state.data.type
      ) {
        DAlert.error({ title: t("config.typeExist") });
        exist = true;
        this.setState({ loading: false, data: {}, showValidation: false });
      }
    });
    if (!exist) {
      raceTypesApi
        .update(id, {
          code: this.state.data.code,
          type: this.state.data.type
        })
        .then(res => {
          if (res !== undefined && res.status === 200) {
            this.fetchTypes();
            DAlert.success({ title: t("commons.savedSuccess") });
          } else {
            DAlert.error({ title: t("commons.errorSaving") });
          }
          this.setState({ loading: false, data: {}, showValidation: false });
        });
    }
  }

  async handler() {
    const { t } = this.props;
    let exist = false;

    this.state.types.map(item => {
      if (
        item.code == this.state.data.code ||
        item.type == this.state.data.type
      ) {
        DAlert.error({ title: t("config.typeExist") });
        exist = true;
        this.setState({ loading: false, data: {}, showValidation: false });
      }
    });
    if (!exist) {
      raceTypesApi
        .addType({
          type: this.state.data.type,
          code: this.state.data.code
        })
        .then(res => {
          if (res !== undefined && res.status === 200) {
            this.fetchTypes();
            DAlert.success({ title: t("commons.savedSuccess") });
          } else {
            DAlert.error({ title: t("commons.errorSaving") });
          }
          this.setState({ loading: false, data: {}, showValidation: false });
        });
    }
  }

  async formHandler(formData) {
    if (formData.ISFORMVALID) {
      await this.setState({
        type: formData.type,
        code: formData.code,
        showValidation: false
      });
    }
  }

  addType(t, id) {
    try {
      const { data, showValidation } = this.state;

      modalHandler.open({
        header: t(`config.addRaceType`),
        size: "tiny",
        content: (
          <Modal.Description>
            <Grid padded>
              <Grid.Row>
                <Grid.Column width="16">
                  <Form
                    defaultState={data}
                    showValidation={showValidation}
                    onFormChange={stateForm =>
                      this.setState({ data: stateForm })
                    }
                    fields={[
                      {
                        fields: [
                          {
                            name: "type",
                            title: t("config.type"),
                            placeHolder: t("config.type"),
                            type: "Input",
                            colSize: 16,
                            validation: {
                              required: true,
                              errorMessage: t("commons.fieldRequired")
                            }
                          }
                        ]
                      },
                      {
                        fields: [
                          {
                            name: "code",
                            title: t("config.code"),
                            placeHolder: t("config.code"),
                            type: "Input",
                            colSize: 16,
                            validation: {
                              required: true,
                              errorMessage: t("commons.fieldRequired")
                            }
                          }
                        ]
                      }
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Description>
        ),
        actions: (
          <Grid>
            <Grid.Column width="16">
              <Button
                fluid
                color="vk"
                onClick={() => {
                  if (this.state.data.ISFORMVALID) {
                    if (id !== undefined) {
                      this.setState({
                        loading: true,
                        loaderText: t(`config.loader`)
                      });
                      this.edit(id);
                      modalHandler.close();
                    } else {
                      this.formHandler(this.state.data);
                      this.setState({
                        loading: true,
                        loaderText: t(`config.loader`)
                      });
                      this.handler();
                      modalHandler.close();
                    }
                  }
                }}
              >
                {t("commons.ok")}
              </Button>
            </Grid.Column>
          </Grid>
        )
      });
    } catch (e) {}
  }

  openTPVHandler() {
    const { t } = this.props
    const { configModal } = this.state
    modalHandler.open({
      header: this.props.t("config.tpv"),
      content: (
        <TPVConfigHandler
          t={t}
          data={configModal}
          updateForm={this.fetchConfig.bind(this)}
        />
      ),
      actions: <></>,
    });
  }

  changeTermsAndConditions(type, t) {
    const { TC_panel, TC_web } = this.state;

    modalHandler.open({
      header: t(`config.terms.title${type}`),
      size: "large",
      content: (
        <Modal.Description>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width="16">
                <RichTextEditor
                  defaultContent={type === "PANEL" ? TC_panel : TC_web}
                  onBlurEditor={stateForm =>
                    this.setState({ terms: stateForm })
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      ),
      actions: (
        <Grid>
          <Grid.Column width="16">
            <Button
              fluid
              color="vk"
              onClick={() => {
                this.updateTerms(type);
                this.setState({ loading: false });
                modalHandler.close();
              }}
            >
              {t("commons.ok")}
            </Button>
          </Grid.Column>
        </Grid>
      )
    });
  }

  async updateTerms(type) {
    const { t } = this.props;
    const { terms } = this.state;
    this.setState({ loading: true });

    const params = {
      typeof: type,
      conditions: terms ? terms : null,
      channel_id: 1
    };
    configApi.updateTermsAndConditions(params).then(async config => {
      if (config.status == 200) {
        DAlert.success({ title: t("commons.savedSuccess") });
        await this.setState({
          TC_panel: config.data.TC_panel,
          TC_web: config.data.TC_web
        });
      } else {
        DAlert.error({ title: t("commons.errorSaving") });
      }
    });
  }

  render() {
    const { t } = this.props;
    const { config, types, loading, totalPages, notificationsConfig } = this.state;
    return (
      <Container>
        <Divider />
        <Divider hidden />
        <Grid columns={2}>
          <Grid.Row stretched>
            <Grid.Column style={{ maxHeight: "50%" }}>
              <Segment>
                <Form
                  defaultState={config}
                  fields={formFields(t)}
                  onFormChange={(config) =>
                    this.setState({ ...config, config })
                  }
                />
                <Button
                  disabled={loading}
                  onClick={() => {
                    this.openTPVHandler();
                  }}
                  className="ui vk button"
                  color="vk"
                  floated="right"
                  fluid
                >
                  {t("config.seeTPV")}
                </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Header textAlign="center" as="h3">
                  {t("config.sportType")}
                </Header>
                <Table striped celled selectable>
                  <Table.Body>
                    {types.length > 0 ? (
                      types.map((type, i) => {
                        return (
                          <Table.Row
                            key={i}
                            onClick={async () => {
                              await this.setState({
                                data: {
                                  ISFORMVALID: true,
                                  code: type.code,
                                  type: type.type,
                                },
                              });
                              this.addType(t, type.id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Table.Cell>{type.type}</Table.Cell>
                            <Table.Cell>{type.code}</Table.Cell>
                          </Table.Row>
                        );
                      })
                    ) : (
                      <Table.Row warning>
                        <Table.Cell>
                          <Icon name="attention" />
                          {t("config.noTypes")}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
                {types.length > 0 ? (
                  <Pagination
                    style={{ width: "auto" }}
                    totalPages={totalPages}
                    onPageChange={(page, data) => {
                      this.fetchTypes({
                        pageNumber: data.activePage,
                      });
                    }}
                    {...(window.innerWidth <= 700
                      ? { firstItem: null, lastItem: null, siblingRange: 0 }
                      : {})}
                  />
                ) : null}
              </Segment>
              <div>
                <Button
                  disabled={loading}
                  onClick={async () => {
                    await this.setState({
                      data: {
                        ISFORMVALID: false,
                        code: "",
                        type: "",
                      },
                    });
                    this.addType(t);
                  }}
                  className="ui vk button"
                  color="vk"
                  floated="right"
                  fluid
                >
                  {t("config.addType")}
                </Button>
              </div>
            </Grid.Column>
            <Grid.Column style={window.innerWidth > 700 ? { maxHeight: "50%" }: { maxHeight: "50%", width: "100%" }}>
              <Divider hidden />
              <Segment>
                <Form
                  defaultState={notificationsConfig}
                  fields={formNotificationFields(t)}
                  onFormChange={async (notificationsConfigEmail) => {
                    notificationsConfig.notifications_answer_mails =
                      notificationsConfigEmail.notifications_answer_mails;
                    await this.setState({ ...notificationsConfig });
                  }}
                />
                <Header as="h5">{t("config.notificationsFooter")}</Header>
                <RichTextEditor
                  defaultContent={notificationsConfig.footer}
                  onBlurEditor={(data) => {
                    notificationsConfig.footer = data;
                    this.setState({ ...notificationsConfig });
                  }}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider />
        <Divider hidden />
        <Button
          style={{ marginBottom: "50px" }}
          disabled={loading}
          onClick={() => this.updateConfig(t, config)}
          className="ui vk button"
          color="vk"
          floated="right"
        >
          {t("commons.ok")}
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            this.changeTermsAndConditions("PANEL", t);
          }}
          className="ui vk button"
          color="vk"
          floated="left"
          style={{ marginBottom: 10 }}
        >
          {t("config.terms.titlePANEL")}
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            {
              this.changeTermsAndConditions("WEB", t);
            }
          }}
          className="ui vk button"
          color="vk"
          style={{ marginBottom: 10 }}
          floated="left"
        >
          {t("config.terms.titleWEB")}
        </Button>
        {loading ? (
          <Dimmer active>
            <Loader />
          </Dimmer>
        ) : null}
      </Container>
    );
  }
}
