import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container,
  Segment,
  Header,
  Image,
  Grid,
  Label,
  Divider,
  Icon,
  Button,
  Modal,
} from "semantic-ui-react";
import authApi from "../../../apis/auth";
import usersApi from "../../../apis/users";
import countriesApi from "../../../apis/countries";
import provincesApi from "../../../apis/regions";
import Form from "../../../components/Form";
import ButtonGroup from "../../../components/ButtonGroup";
import forms from "./forms";
import { DAlert } from "../../../components/Dalert";
import { modalHandler } from "../../../components/DModal";
import { getCountries } from "../../../reducers/countries";

const roleForm = {
  ADMINISTRATOR: forms.admin,
  COMPANY: forms.company,
  PLANNER: forms.planner,
  POINT_OF_SALE: forms.pointOfSale,
  ATHLETE: forms.client,
  QR_READER: forms.qr_reader,
};

@connect(
  (state) => ({
    countries: state.countries.countries,
  }),
  (dispatch) => ({
    actions: bindActionCreators({ getCountries }, dispatch),
  })
)
@withTranslation()
class UserDetail extends Component {
  constructor() {
    super();

    this.state = {
      data: {},
      currentData: {},
      countryCodes: [],
      provinces: [],
      showValidation: false,
      changingPassword: false,
    };
  }

  componentDidMount() {
    const { actions, countries } = this.props;

    actions.getCountries(countries);
    this.getData();
  }

  async getStatus() {
    const { data } = this.state;
    this.setState({ blocked: data.status === "BLOCKED" });
  }

  async getData() {
    const data = await usersApi.fetchUser({ id: this.props.match.params.id, channel_id: 1});

    const countryCodes = await countriesApi.fetchCountryCodes();
    provincesApi.fetchRegions().then((provinces) => {
      this.setState({
        provinces: provinces.map((provinces) => ({ title: provinces.name })),
      });
    });

    this.setState({ data });
    this.setState({ countryCodes });
    this.getStatus();
  }

  async save() {
    const { t } = this.props;
    const { data, currentData } = this.state;

    if (currentData.ISFORMVALID) {
      currentData.dni ? currentData.dni.toUpperCase() : null;
      currentData['channel_id'] = 1;
      await usersApi.update(currentData.id, currentData);
      this.getStatus();
      DAlert.success({ title: t("usersScreen.messages.saveCorrect") });
      return;
    }

    this.setState({ showValidation: true });
  }

  async softdelete() {
    const { data } = this.state;
    const { t } = this.props;
    const modalConfirm = {
      header: t("commons.areYouSure"),
      content: t(`usersScreen.modalDeleted.deleted`),
      onYes: async () => {
        const result = await usersApi.softdelete(data.id);

        modalHandler.close();
        this.props.history.goBack();
        DAlert.success({ title: t(`usersScreen.modalDeleted.okMessage`) });
      },
    };

    modalHandler.open(modalConfirm);
  }

  async resendEmail() {
    const { data } = this.state;
    const { t } = this.props;
    const result = await usersApi.createUser({
      email: data.email,
      role_id: data.role_id,
      company_id: data.company_id,
      channel_id: 1,
    });

    if (result.message == "EMAIL_SENT") {
      modalHandler.open({
        header: t("usersScreen.messages.emailResend"),
        content: t("usersScreen.messages.aNewMail"),
        actions: () => <></>,
      });
    } else {
    }
  }

  modalOptions() {
    const { t } = this.props;
    const { changingPassword, data } = this.state;
    const email = data?.email;
    const userId = data?.user_id;

    return {
      header: t("clientScreen.resetPassword.beforeHeader"),
      content: (
        <Modal.Description>
          {t("clientScreen.resetPassword.beforeContent", { email })}
          {changingPassword && (
            <Dimmer active style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
              <Loader />
            </Dimmer>
          )}
        </Modal.Description>
      ),
      onYes: async () => {
        try {
          await this.setState({ changingPassword: true });
          // modalHandler.refreshOptions({ ...this.modalOptions() });
          await authApi.recoverPassword({ email, userId, channel_id: 1 });
          this.setState({ changingPassword: false });

          modalHandler.open({
            header: null,
            size: "small",
            content: t("login.recoverEmailSent"),
            actions: (
              <Grid>
                <Grid.Column width="16">
                  <Button
                    fluid
                    color="vk"
                    onClick={() => {
                      modalHandler.close();
                    }}
                  >
                    {t("commons.ok")}
                  </Button>
                </Grid.Column>
              </Grid>
            ),
          });
        } catch (err) {
          this.setState({ changingPassword: false });
        }
      },
    };
  }

  render() {
    const { t, countries } = this.props;
    const {
      data,
      currentData,
      countryCodes,
      provinces,
      showValidation,
      blocked,
    } = this.state;

    const avatarUrl = data.avatar_url
      ? data.avatar_url
      : "https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg";
    let selectedForm = [];
    if (data.role) {
      selectedForm = roleForm[data.role](t, countries, provinces);
    }

    return (
      <Container>
        <Segment basic>
          <Grid>
            <Grid.Column width={8}>
              {blocked && (
                <Label
                  as="a"
                  // color="blue"
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    cursor: "default",
                  }}
                >
                  <Icon name="ban"></Icon>
                  {t("login.messages.USER_DISABLED.header")}
                </Label>
              )}
            </Grid.Column>
            <Grid.Column width={8}>
              <Label
                as="a"
                // color="blue"
                ribbon="right"
                style={{
                  backgroundColor: "#4d7198",
                  color: "#fff",
                  cursor: "default",
                }}
              >
                {t("userScreen.fields.role").toLowerCase()}:{" "}
                {t(`roles.${data.role}`)}
              </Label>
            </Grid.Column>
          </Grid>
          <Divider />
          <Grid>
            <Grid.Column width={3}>
              <Image
                id="UserAvatar"
                src={avatarUrl}
                avatar
                circular
                size={"small"}
                verticalAlign="middle"
              />
            </Grid.Column>
            <Grid.Column width={13}>
              <Segment basic>
                {data.status == "NOT_CONFIRMED" && (
                  <Button
                    content={t("usersScreen.resendEmail")}
                    color="blue"
                    onClick={() => this.resendEmail()}
                  />
                )}
                <Header size="medium">{t("userScreen.personalData")}</Header>
                <Form
                  fields={selectedForm}
                  onFormChange={(formData) => {
                    this.setState({
                      currentData: { ...currentData, ...formData, id: data.id },
                    })
                  }
                  }
                  defaultState={this.state.data}
                  showValidation={showValidation}
                />
              </Segment>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column width={4}>
              <Button
                fluid
                color="vk"
                onClick={() => modalHandler.open(this.modalOptions())}
              >
                {t("clientScreen.detail.changePassword")}
              </Button>
            </Grid.Column>
            <Grid.Column width={3}>
              <Button onClick={() => this.softdelete(data.role_id)} negative>
                {t("userScreen.delete")}
              </Button>
            </Grid.Column>
            <Grid.Column width={9}>
              <ButtonGroup onSave={() => this.save()} />
            </Grid.Column>
          </Grid>
        </Segment>
      </Container>
    );
  }
}

export default UserDetail;
