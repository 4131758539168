import React from "react";
import { Form } from "semantic-ui-react";

export const filterFields = (t) => [
  {
    div: "row",
    fields: [
      {
        name: "name",
        placeHolder: t("raceDetail.documents.table.name"),
        type: "Input",
        colSize: 4,
      },
    ],
  },
];

export const tableHeaders = (t) => [
  {
    accessor: "name",
    content: t("raceDetail.documents.table.name"),
    props: { style: { wordBreak: "break-word" } },
  },
  {
    accessor: "publicName",
    content: t("raceDetail.documents.table.publicName"),
    props: { style: { wordBreak: "break-word" } },
  },
  {
    accessor: "createdAt",
    content: t("raceDetail.documents.table.createdAt"),
  },
  {
    accessor: "mimeType",
    content: t("raceDetail.documents.table.mimeType"),
    props: { style: { wordBreak: "break-word" } },
  },
  {
    accessor: "path",
    content: t("raceDetail.documents.table.path"),
    props: { style: { wordBreak: "break-word" } },
  },
  {
    accessor: "isPublic",
    content: t("raceDetail.documents.table.isPublic"),
  },
  {
    accessor: "actions",
    content: "",
    noSort: true,
    headerProps: { sorted: true },
  },
];

const commonDocFields = (t) => ({
  fields: [
    {
      name: "name",
      title: t("raceDetail.documents.table.name"),
      type: "Input",
      colSize: 6,
      validation: {
        required: true,
      },
    },
    {
      name: "publicName",
      title: t("raceDetail.documents.table.publicName"),
      type: "Input",
      colSize: 6,
      validation: {
        required: true,
      },
    },
    {
      name: "isPublic",
      title: t("raceDetail.documents.table.isPublic"),
      type: "Checkbox",
      colSize: 6,
    },
  ],
});

export const uploadFormFields = (t, showValidation) => [
  commonDocFields(t),
  {
    fields: (state) => [
      {
        name: "document",
        component: (_, __, onChange) => (
          <Form.Input
            type="file"
            label={t("raceDetail.documents.table.document")}
            onChange={(e) => onChange(e.target.files[0])}
            error={
              showValidation && !state.document
                ? t("commons.fieldRequired")
                : undefined
            }
          />
        ),
        validation: {
          required: true,
        },
      },
    ],
  },
];

export const updateFormFields = (t) => [
  {
    fields: [
      {
        type: "Input",
        name: "id",
        title: t("raceDetail.documents.table.id"),
        props: { readOnly: true },
        colSize: 2,
      },
      {
        type: "Input",
        name: "email",
        title: t("raceDetail.documents.table.uploadedBy"),
        props: { readOnly: true },
        colSize: 10,
      },
      {
        type: "Input",
        name: "createdAtParsed",
        title: t("raceDetail.documents.table.createdAt"),
        props: { readOnly: true },
        colSize: 4,
      },
    ],
  },
  commonDocFields(t),
];
