import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Modal, Grid, Message, Button } from "semantic-ui-react";
import { useSelector } from "react-redux";

import pricesApi from "../../../apis/prices";
import Form from "../../../components/Form";
import { formFields, checkDates } from "./util";
import { modalHandler } from "../../../components/DModal";
import { DAlert } from "../../../components/Dalert";
import FluidResponsiveButton from "../../../components/FluidResponsiveButton";
import AsyncButton from "../../../components/AsyncButton";

const CreatePriceButton = (props) => {
  const [priceForm, setPriceForm] = useState();
  const [showValidation, setShowValidation] = useState(false);
  const [error, setError] = useState();
  const [t] = useTranslation();

  const race = useSelector((state) => state.races.actualRace);
  const modality = useSelector((state) => state.modalities.actualModality);

  const formHandler = (formData) => {
    setError(undefined);
    setPriceForm(formData);
  };

  const createPriceHandler = async () => {
    if (!priceForm.ISFORMVALID) {
      setShowValidation(true);
      return;
    }
    const areValidDates = await checkDates(priceForm, modality, t);
    if (!areValidDates.valid) {
      DAlert.errorPlus({
        message: areValidDates.message,
      });
      return;
    }

    if (priceForm.maxAthletes == "") {
      priceForm.maxAthletes = null;
    }
    //check if price is upper default?

    const formToSend = {
      ...priceForm,
      maxAthletes: priceForm.maxAthletes
        ? +priceForm.maxAthletes
        : priceForm.maxAthletes,
      dateFinish: priceForm.dateFinish === "" ? null : priceForm.dateFinish,
      timeStart: priceForm.timeStart || "00:00",
      timeEnd: priceForm.timeEnd || "00:00",
      amount: parseFloat(priceForm.amount.replace(",", ".")),
    };
    await pricesApi
      .createPrice(props.modalityId, formToSend)
      .then(async (response) => {
        if (response.ok !== undefined && !response.ok) {
          DAlert.error({ title: t("commons.errorSaving") });
          setError(response.error.message);
          return;
        } else {
          DAlert.success({ title: t("commons.savedSuccess") });
          modalHandler.close();
          setPriceForm();
          await props.getData();
        }
      });
  };

  useEffect(() => {
    modalHandler.refreshOptions({
      ...modalOptions,
    });
  }, [error, priceForm, showValidation, race, modality]);

  const modalOptions = {
    header: t("pricesScreen.new"),
    content: (
      <Modal.Description>
        <Grid padded>
          <Grid.Row>
            <Grid.Column width="16">
              <Form
                showValidation={showValidation}
                fields={formFields(t, modality)}
                // onFormChange={formHandler}
                defaultState={priceForm ? priceForm : null}
                onFormChange={(data) => setPriceForm(data)}
              />
            </Grid.Column>
          </Grid.Row>
          {error && (
            <Grid.Row>
              <Grid.Column width="16">
                <Message
                  error
                  header={t(`usersScreen.modal.messages.${error}.header`)}
                  content={t(`usersScreen.modal.messages.${error}.message`)}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Modal.Description>
    ),
    actions: (
      <Grid>
        <Grid.Column width="16">
          <AsyncButton fluid color="vk" onClick={createPriceHandler}>
            {t("pricesScreen.new")}
          </AsyncButton>
        </Grid.Column>
      </Grid>
    ),
  };

  return (
    <FluidResponsiveButton
      icon
      labelPosition="left"
      size="small"
      primary
      onClick={async () => await modalHandler.open(modalOptions)}
    >
      <Icon name="add" />
      {t("commons.new")}
    </FluidResponsiveButton>
  );
};

export default CreatePriceButton;
