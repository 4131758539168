import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Container, Label, Divider, Responsive } from "semantic-ui-react";
import { connect } from "react-redux";

import FluidResponsiveButton from "../../../components/FluidResponsiveButton";
import ModalitySubtitle from "../../../components/ModalitySubtitle";
import Form from "../../../components/Form";
import {
  formField,
  formAttrFields,
  formFieldsLeft,
  formFieldsRight,
} from "./util";
import modalityApi from "../../../apis/modalities";
import { DAlert } from "../../../components/Dalert";

@connect((state) => ({
  modality: state.modalities.actualModality,
  race: state.races.actualRace,
}))
@withTranslation()
export default class ModalityConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      config: {},
      formValidity: {},
      disableFields: false,
      showValidation: false,
    };

    this.saveConfigurationHandler = this.saveConfigurationHandler.bind(this);
    this.checkDateValidity = this.checkDateValidity.bind(this);
  }

  async componentDidMount() {
    const { t } = this.props;

    await modalityApi.fetchInscriptions(this.props.id).then(async (res) => {
      if (res.count !== undefined && res.count > 0) {
        DAlert.warning({ message: t("modalityConfiguration.savedInfo") });
      }
    });
    const response = await modalityApi.fetchModalityConfiguration(
      this.props.id
    );
    this.setState({ config: response });
  }

  formHandler(formData, formValidityId) {
    const { config, formValidity } = this.state;

    this.setState({
      config: {
        ...this.state.config,
        maxInscriptions: formData.maxInscriptions,
      },
    });
    const formValidityObj = {
      ...formValidity,
      [formValidityId]: formData.ISFORMVALID,
    };

    const isFormValid = Object.keys(formValidityObj).every(
      (key) => formValidityObj[key] !== false
    );

    this.setState({
      formValidity: formValidityObj,
      config: {
        ...config,
        ...formData,
        ISFORMVALID: isFormValid,
      },
    });
  }

  async checkDateValidity({ params }) {
    let { minimumAge, maximumAge, minimumAgeType, maximunAgeType } = params;
    if (
      minimumAge === null &&
      maximumAge === null &&
      minimumAgeType === null &&
      maximunAgeType === null
    ) {
      //if there's no restrictions
      return true;
    }
    try {
      minimumAge !== null &&
        parseInt(minimumAge) !== NaN &&
        (minimumAge = parseInt(minimumAge));

      maximumAge !== null &&
        parseInt(maximumAge) !== NaN &&
        (maximumAge = parseInt(maximumAge));
    } catch (error) {
      return false;
    }

    if (minimumAgeType && !minimumAge) {
      return false;
    }
    if (!minimumAgeType && minimumAge) {
      return false;
    }
    if (maximunAgeType && !maximumAge) {
      return false;
    }
    if (!maximunAgeType && maximumAge) {
      return false;
    }

    if (minimumAgeType !== maximunAgeType) {
      const currentYear = new Date().getFullYear();

      if (minimumAgeType === "YEAR") {
        const age = currentYear - minimumAge;

        return age && maximumAge ? age < maximumAge : false;
      } else {
        const maxAge = currentYear - maximumAge;

        return minimumAge < maxAge;
      }
    } else {
      if (minimumAgeType === "YEAR") {
        return minimumAge > maximumAge;
      } else {
        return minimumAge < maximumAge;
      }
    }
  }

  _getDateAndTime(date, time) {
    try {
      date = date.slice(0, 10);

      return moment(date + " " + time);
    } catch (error) {}
    return date;
  }

  async _checkDates(config, t) {
    let { startDate, endDate, timeStart, timeEnd } = config;
    const { start_date, time_start } = this.props.modality;

    const start = this._getDateAndTime(startDate, timeStart);
    const end = this._getDateAndTime(endDate, timeEnd);
    const modalityDate = this._getDateAndTime(start_date, time_start);

    let valid = true;
    let message = "";
    if (end && end.isBefore(start)) {
      valid = false;
      message = t("modalityConfiguration.finishDateError1", {
        startConfigDate: start.format("LLL"),
      });
    } else if (modalityDate.isBefore(end)) {
      valid = false;
      message = t("modalityConfiguration.finishDateError2", {
        startModalityDate: modalityDate.format("LLL"),
      });
    }

    return { valid, message };
  }

  async saveConfigurationHandler() {
    const { t, id } = this.props;

    let { config } = this.state;
    if (!config.ISFORMVALID) {
      this.setState({ showValidation: true });
      return;
    }

    //check max/min ageType
    let { minimumAge, maximumAge, minimumAgeType, maximunAgeType } = config;
    if(minimumAgeType===null)minimumAge=null;
    if(maximunAgeType===null)maximumAge=null;
    config = {...config, minimumAge, maximumAge, minimumAgeType, maximunAgeType}
    const isValid = await this.checkDateValidity({
      params: { minimumAge, maximumAge, minimumAgeType, maximunAgeType },
    });

    //check if price is lower specific prices?

    if (isValid) {
      const areValidDates = await this._checkDates(config, t);
      if (!areValidDates.valid) {
        DAlert.errorPlus({
          message: areValidDates.message,
        });
        return;
      }
      const numericFields = [
        "maxInscriptions",
        "limitWebInscriptions",
        "maximumAge",
        "minimumAge",
      ];
      const floatFields = ["priceDefault"];
      const booleanFields = ["allowInscriptions"];

      let objectToSend = { ...config };

      Object.keys(config).map((key) => {
        objectToSend[key] =
          config[key] === ""
            ? null
            : booleanFields.includes(key)
              ? config[key]
              : numericFields.includes(key)
                ? config[key] !== null
                  ? +config[key]
                  : null
                : floatFields.includes(key) && typeof config[key] === "string"
                  ? parseFloat(config[key].replace(",", "."))
                  : config[key];
      });
      await modalityApi.fetchInscriptions(this.props.id).then(async (res) => {
        if (!objectToSend.ISFORMVALID) {
          this.setState({ showValidation: true });
          DAlert.error({ message: t("commons.fieldsRequired") });
          return;
        }
        
        const response = await modalityApi
          .updateModalityConfiguration(id, objectToSend)
          .then((response) => {
            DAlert.success({
              message: t("modalityConfiguration.savedSuccess"),
            });
          })
          .catch((error) => {
            
            /*const {
              response: {
                data: {
                  error: { message },
                },
              },
            } = error;*/
            if (error.response.data.message === "MODALITY_MAX_INSCRIPTIONS") {
              DAlert.error({
                message: t("modalityConfiguration.notValidMaxInscriptions"),
              });
              return;
            } else {
              DAlert.error({
                message: t("modalityConfiguration.savedFailure.common"),
              });
              return;
            }
          });
      });
    } else {
      DAlert.error({
        message: t("modalityConfiguration.savedFailure.dateValidity"),
      });
      return;
    }
  }

  render() {
    const { t, modality, race } = this.props;
    const { config, screenWidth, disableFields, showValidation } = this.state;

    return (
      <Container>
        <ModalitySubtitle
          icon="cogs"
          title="raceDetail.menu.modality.inscriptionsConfiguration"
        />
        <Form
          fields={formField(t, disableFields)}
          defaultState={{
            allowInscriptions:
              config.allowInscriptions === true ? "ALLOW" : "NOT_ALLOW",
          }}
          onFormChange={(data) =>
            this.formHandler(
              {
                ...data,
                allowInscriptions:
                  data.allowInscriptions === "NOT_ALLOW" ? false : true,
              },
              "0"
            )
          }
          showValidation={showValidation}
        />
        <Responsive
          onUpdate={(_, { width }) => this.setState({ screenWidth: width })}
        >
          <div
            style={{
              display: "flex",
              flexDirection: screenWidth <= 991 ? "column" : "row",
            }}
          >
            <Form
              executeChangeOnBlur={false}
              fields={formFieldsLeft(t, config, modality, disableFields)}
              defaultState={config}
              onFormChange={(data) => {
                this.formHandler(data, "1");
              }}
              showValidation={showValidation}
            />
            <Form
              fields={formFieldsRight(t, disableFields)}
              defaultState={config}
              onFormChange={(data) => {
                data.minimumAgeType =
                  data.minimumAgeType == 0 ? null : data.minimumAgeType;
                data.maximunAgeType =
                  data.maximunAgeType == 0 ? null : data.maximunAgeType;
                this.formHandler(data, "2");
              }}
              showValidation={showValidation}
            />
          </div>
        </Responsive>
        <Divider horizontal style={{ margin: "30px 0 30px" }}>
          <Label horizontal size="large">
            {t("modalityConfiguration.dividerText")}
          </Label>
        </Divider>
        <Form
          fields={formAttrFields(t, disableFields)}
          defaultState={config}
          onFormChange={(data) => this.formHandler(data, "3")}
          showValidation={showValidation}
        />
        <br />
        {!race.deleted && <FluidResponsiveButton
          disabled={disableFields}
          primary
          onClick={this.saveConfigurationHandler}
          style={{ tabIndex: 99 }}
        >
          {t("commons.save")}
        </FluidResponsiveButton>}
      </Container>
    );
  }
}
