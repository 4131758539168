import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Image as ImageComponent, Label, Icon } from "semantic-ui-react";

import { toBase64 } from "../../util/util";
import { DAlert } from "../Dalert";
import { useTranslation } from "react-i18next";

const ImagePicker = props => {
  const [t] = useTranslation();
  const [buttonContent, setButtonContent] = useState();

  const imageRef = useRef(null);
  const fileChange = async e => {
    const imageFile = e.target.files[0];
    const fileSize = imageFile.size;

    if (fileSize > props.maxImageSize) {
      DAlert.error({
        message: t("commons.imageTooBig", {
          maxSize: props.maxImageSize / 1000
        })
      });
      return;
    }

    try {
      setButtonContent(imageFile.name);
      const validMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/webp",
      ];

      if (!validMimeTypes.includes(imageFile.type)) {
        throw new Error("Not valid format");
      }

      const url = window.URL || window.webkitURL;
      const image = new Image();

      image.onerror = () => {
        setButtonContent();
        DAlert.error({
          message: t("commons.invalidImageFormat"),
        });
        return;
      };

      image.onload = async () => {
        const base64Image = await toBase64(imageFile);
        const mime = base64Image.split(":").pop().split(";")[0];
        if (validMimeTypes.includes(mime)) {
          props.onSelect(base64Image);
        } else {
          throw new Error("Not valid format");
        }
      }
      
      image.src = url.createObjectURL(imageFile);
    } catch (err) {
      console.error(err);
      setButtonContent();
      DAlert.error({
        message: t("commons.invalidImageFormat"),
      });
    }
  };

  const onOpenHandler = e => {
    e.stopPropagation();
    window.open(props.imageSrc, "_blank");
  };

  return (
    <div>
      <Button as="div" labelPosition="right" {...props.buttonProps}>
        <Button
          onClick={() => imageRef.current.click()}
          labelPosition="left"
          color="vk"
          icon
          {...props.buttonProps}
        >
          <Icon name="file image outline" />
          {buttonContent ? buttonContent : props.content}
        </Button>
        {props.markAsRequired && (
          <Label
            pointing="left"
            basic
            prompt
            content={t("commons.fieldRequired")}
            color="red"
          />
        )}
        {props.showLabelToOpen && props.imageSrc && (
          <Label
            as="a"
            basic
            color="blue"
            pointing="left"
            onClick={e => onOpenHandler(e)}
          >
            {t("commons.open")}
          </Label>
        )}
      </Button>
      <input
        ref={imageRef}
        type="file"
        hidden
        onChange={fileChange}
        accept={props.accept}
      />
      {props.showImage && (
        <ImageComponent
          src={props.imageSrc}
          size="medium"
          centered
          style={{ marginTop: 5 }}
        />
      )}
    </div>
  );
};

ImagePicker.propTypes = {
  content: PropTypes.string,
  onSelect: PropTypes.func,
  maxImageSize: PropTypes.number, // in bytes
  showImage: PropTypes.bool,
  buttonProps: PropTypes.object,
  imageSrc: PropTypes.string,
  buttonDirection: PropTypes.oneOf(["left", "right"]),
  showLabelToOpen: PropTypes.bool,
  accept: PropTypes.string
};

ImagePicker.defaultProps = {
  content: "Select",
  onSelect: PropTypes.func,
  maxImageSize: 2000000, // 2000kb,
  buttonProps: {},
  showImage: true,
  imageSrc: null,
  buttonDirection: "left",
  showLabelToOpen: true,
  accept: "image/x-png,image/jpeg,image/webp"
};

export default ImagePicker;
