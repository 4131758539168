import React, { Component } from "react";
import { Container, Divider, Card, Grid, List } from "semantic-ui-react";
import ModalitySubtitle from "../../../components/ModalitySubtitle";
import modalityApi from "../../../apis/modalities";
import Form from "../../../components/Form";
import { formFields } from "./util";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ModalitySummaryCard from "../../../components/ModalitySummaryCard";
import pricesApi from "../../../apis/prices";
import categoriesApi from "../../../apis/categories";
import teamTypeApi from "../../../apis/teamTypes";
import CreateModalityButton from "../../Race/RaceModalities/CreateModalityButton";
import { connect } from "react-redux";

const styles = {
  MarginBottom: {
    marginBottom: "4vh"
  }
};

@connect(state => ({
  race: state.races.actualRace.id,
  actualRace: state.races.actualRace,
}))
@withTranslation()
export default class ModalitySummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      general: { inscriptions: '0' },
      data: []
    };
  }

  async componentDidMount() {
    await this.fetchInscriptions();
    await this.fetchModality();
    await this.fetchModalityConfig();
    await this.getPrices();
    await this.getAttributes();
    await this.getCategory();
    
    const isEventOrAssociate =
      this.props.actualRace?.activityType?.type === "EVENT" ||
      this.props.actualRace?.activityType?.type === "ASSOCIATES";
    if (!isEventOrAssociate) {
      await this.getTeamsType();
    }
    
  }

  async fetchModalityConfig() {
    const { t } = this.props;
    const inscriptionStatus = await modalityApi.getModalityInscriptionStatus(
      this.props.id
    );

    await modalityApi.fetchModalityConfiguration(this.props.id).then(res => {
      if (res) {
        var aux = this.state.data;
        aux.push({
          name: "inscriptionconfig",
          type: 0,
          rows: [
            {
              key: "state",
              value: t(`inscriptionStatus.${inscriptionStatus}`)
            },
            {
              key: "defauldPrices",
              value: res.priceDefault
                ? `${res.priceDefault.toFixed(2)} €`
                : "---"
            },
            {
              key: "init",
              value: res.startDate ? moment(res.startDate).format("L") : "---"
            },
            {
              key: "end",
              value: res.endDate ? moment(res.endDate).format("L") : "---"
            },
            {
              key: "max",
              value: res.maxInscriptions
            }
          ]
        });
        this.setState({ data: aux });
      }
    });
  }

  async getCategory() {
    const { t } = this.props;
    await categoriesApi
      .getByModality({
        id: this.props.id
      })
      .then(res => {
        const rows = res.rows.length
          ? res.rows.map(element => {
            return {
              name: element.name
            };
          })
          : [];
        const data = [
          ...this.state.data,
          {
            name: "categories",
            type: 1,
            header: "headerCategorie",
            rows: rows
          }
        ];
        this.setState({ data });
      });
  }

  async getTeamsType() {
    const { t } = this.props;
    await teamTypeApi.getTeamTypes(this.props.id).then(res => {
      const rows = res.length
        ? res.map(element => {
          return {
            name: element.name,
            minAthletes: element.minAthletes,
            maxAthletes: element.maxAthletes
          };
        })
        : [];
      const data = [
        ...this.state.data,
        {
          name: "teamtypes",
          type: 1,
          header: "headerTeamType",
          rows: rows
        }
      ];
      this.setState({ data });
    });
  }

  async getAttributes() {
    const { t, id } = this.props;
    await modalityApi.fetchAttributes(id).then(res => {
      const rows = res.rows.length
        ? res.rows.map(element => {
          return {
            name: element.name,
            type: element.type,
            request: element.required
          };
        })
        : [];
      const data = [
        ...this.state.data,
        {
          name: "attributes",
          type: 1,
          header: "headerAttribute",
          rows: rows
        }
      ];
      this.setState({ data });
    });
  }

  async getPrices() {
    const { t } = this.props;
    await pricesApi.getByModality({ id: this.props.id }).then(res => {
      const rows = res.rows.length
        ? res.rows.map(element => {
          return {
            name: element.name,
            PRECIO: `${element.amount.toFixed(2)} €`,
            DESDE: element.dateStart,
            HASTA: element.dateFinish
          };
        })
        : [];
      const data = [
        ...this.state.data,
        {
          name: "prices",
          type: 1,
          header: "headerPrice",
          rows: rows
        }
      ];
      this.setState({ data });
    });
  }

  async fetchModality() {
    await modalityApi.fetchModality(this.props.id).then(response => {
      this.setState(({ general }) => ({
        general: {
          ...general,
          name: response.name,
          modalityDate: response.start_date
            ? moment(response.start_date).format("L")
            : "---",
          modalityHour: response.time_start
          // modalityHour: response.start_date
          //   ? moment(response.start_date).format("LTS")
          //   : "---"
        }
      }));
    });
  }

  async fetchInscriptions() {
    await modalityApi.fetchInscriptionsCount(this.props.id).then(response => {
      this.setState(({ general }) => ({
        general: {
          ...general,
          inscriptions: response.totalInscriptions
        }
      }));
    });
  }

  render() {
    const { t, history } = this.props;
    const { general, data } = this.state;
    
    return (
      // <Container style={styles.TopMargin}>
      <Container>
        <Divider horizontal style={{ ...styles.MarginBottom }}>
          {t("raceDetail.detail.generalInformation.divider")}
        </Divider>
        <div style={{ ...styles.MarginBottom }}>
          <Form defaultState={{ ...general }} fields={formFields(t)} />
        </div>
        <Divider horizontal style={{ ...styles.MarginBottom }}>
          {t("raceDetail.menu.modality.detail")}
        </Divider>
        {/* <Container
          style={{
            margin: 30,
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <CreateModalityButton race_id={this.props.race} />
        </Container> */}
        <Card.Group itemsPerRow={2} stackable>
          {data.map((card, index) => (
            <ModalitySummaryCard
              data={card}
              id={this.props.id}
              history={history}
              key={index}
            />
          ))}
        </Card.Group>
      </Container>
    );
  }
}
