import { clientApi } from ".";

export default {
  
  fetchPointOfSaleInRace: async (id) => {
    try {
      const response = await clientApi.getClient().get(`/point_of_sale_race/${id}`, {params: {channel_id: 1}});
      return response.data;
    } catch (err) {}
  },
  linkPlannerToRace: async params => {
    try {
      const response = await clientApi.getClient().post("/point_of_sale_race", { ...params, channel_id: 1 });
      return response;
    } catch (err) { }
  },
  updateEnabled: async (params) => {
    try {
      const response = await clientApi.getClient().put(`/point_of_sale_race_update/`, { ...params, channel_id: 1 });
      return response.data;
    } catch (err) { }
  },
};