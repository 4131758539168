//@ts-check
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Container,
  Icon,
  Menu,
  Modal,
  Segment,
  Grid,
  Button,
} from "semantic-ui-react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { changeTab } from "../../reducers/notifications";

import FilterableTable from "../../components/FilterableTable";
import { modalHandler } from "../../components/DModal";
import companiesApi from "../../apis/companies";
import racesApi from "../../apis/races";
import templatesApi from "../../apis/templates";
import notificationsApi from "../../apis/notifications";
import { getTableHeaders, getFilterFields } from "./util";
import FluidResponsiveButton from "../../components/FluidResponsiveButton";
import { DAlert } from "../../components/Dalert";

import "./index.css";
import { template } from "lodash";

// @ts-ignore
@connect(
  (state) => ({
    rol: state.user.role,
    userId: state.user.id,
    tab: state.notifications.activeTab,
  }),
  (dispatch) => ({
    actions: bindActionCreators({ changeTab }, dispatch),
  })
)
@withTranslation()
export default class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      totalData: 0,
      companies: [],
      regions: [],
      raceTypes: [],
      activeItem: this.props.tab,
      races: [],
      templates: [],
      deleteItem: null,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true, activeItem: this.props.tab });
    await this.getData(this.props.tab);
    this.setState({ loading: false });
    this.getCompanies();
    this.getRaces();
    this.getTemplates();
  }

  async deleteSelection() {
    const { t } = this.props;
    if (this.state.activeItem === "list") {
      try {
        await notificationsApi.deleteNotification(this.state.deleteItem);
        DAlert.success({ title: t("notificationScreen.deleteSuccess") });
      } catch (error) {
        DAlert.error({ title: t("notificationScreen.error") });
      }
    } else {
      try {
        await templatesApi.deleteTemplate(this.state.deleteItem);
        DAlert.success({ title: t("notificationScreen.deleteSuccess") });
      } catch (error) {
        DAlert.error({ title: t("notificationScreen.error") });
      }
    }
    await this.getData(this.state.activeItem);
  }

  deleteModalHandler() {
    modalHandler.open(this.modalOptions());
  }

  modalOptions = () => {
    const { t } = this.props;
    return {
      header: t(`notificationScreen.modal.deleteTitle`),
      size: "large",
      content: (
        <Modal.Description>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width="16">
                {t("notifications.confirmDelete")}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      ),
      actions: (
        <Grid>
          <Grid.Column width="8"></Grid.Column>
          <Grid.Column width="4">
            <Button
              fluid
              color="vk"
              onClick={() => {
                this.deleteSelection();
                modalHandler.close();
              }}
            >
              {t("commons.ok")}
            </Button>
          </Grid.Column>
          <Grid.Column width="4">
            <Button
              fluid
              color="vk"
              onClick={() => {
                this.setState({ loading: false });
                modalHandler.close();
              }}
            >
              {t("commons.cancel")}
            </Button>
          </Grid.Column>
        </Grid>
      ),
    };
  };

  async getTemplates() {
    const data = await templatesApi.fetchTemplates({ pageSize: -1 });
    const templates = data.rows.map((template) => ({
      text: template.name ? template.name : "",
      value: template.id,
    }));
    this.setState({ templates });
  }

  async getCompanies() {
    const data = await companiesApi.getMin();

    const companies = data.map((company) => ({
      text: company.name,
      value: company.id,
    }));

    this.setState({ companies });
  }

  async getRaces() {
    const data = await racesApi.fetchRaces({ pageSize: -1, type: -1 });

    const races = data.rows.map((race) => ({
      text: race.name,
      value: race.id,
    }));
    this.setState({ races });
  }

  async filterTable(parameters, filters) {
    this.getData(this.state.activeItem, { ...parameters, ...filters });
  }

  editRegister(id, editable, status) {
    this.props.history.push({
      pathname:
        this.state.activeItem === "list"
          ? `/notifications/notification/${id}`
          : `/notifications/template/${id}`,
      state: { id: id },
      editable: editable,
      isSent: status === "SENT",
    });
  }

  async getData(activeItem, params, filters) {
    const response =
      activeItem !== "list"
        ? await templatesApi.fetchTemplates(params)
        : await notificationsApi.fetchNotifications(params);
    const editableRows = [];
    response.rows.map((row) => {
      let editableRow = row;
      if (
        activeItem === "list" &&
        row.status !== "SENT" &&
        ((!row.company_id && this.props.rol === "ADMINISTRATOR") ||
          row.company_id)
      )
        editableRow.edit = (
          <Grid>
            <Grid.Column textAlign="center">
              <Icon
                name="delete"
                color="red"
                link
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ deleteItem: row.id });
                  this.deleteModalHandler();
                }}
              />
            </Grid.Column>
          </Grid>
        );
      else
        editableRow.edit = (
          <Grid>
            <Grid.Column></Grid.Column>
          </Grid>
        );
      editableRows.push(editableRow);
    });
    this.setState({ data: editableRows, totalData: response.count });
  }

  async handleMenu(e, { name }) {
    if (this.state.activeItem !== name) this.getData(name);
    this.props.actions.changeTab(name);
    this.setState({ activeItem: name });
  }

  render() {
    const { t } = this.props;
    const loggedRole = this.props.rol || "";
    const {
      loading,
      data,
      totalData,
      companies,
      activeItem,
      races,
      templates,
    } = this.state;

    const filterFields = getFilterFields(
      t,
      companies,
      loggedRole,
      activeItem,
      [], // todo -> sent/not sent,
      races,
      templates
    );
    const tableHeaders = getTableHeaders(t, loggedRole, activeItem);
    return (
      <Container>
        <Menu secondary>
          <Menu.Item
            name="list"
            active={activeItem === "list"}
            onClick={this.handleMenu.bind(this)}
          >
            {this.props.t("notifications.list")}
          </Menu.Item>
          <Menu.Item
            name="templates"
            active={activeItem === "templates"}
            onClick={this.handleMenu.bind(this)}
          >
            {this.props.t("notifications.templates")}
          </Menu.Item>
        </Menu>
        <FilterableTable
          id={"NotificationsFilterableTable"}
          filterFields={filterFields}
          onChange={(table, filters) => this.filterTable(table, filters)}
          tableHeaders={tableHeaders}
          onRowClick={(data) => {
            this.props.history.push({
              pathname:
                activeItem === "list"
                  ? `/notifications/notification/${data.id}`
                  : `/notifications/template/${data.id}`,
              editable:
                (!data.company_id && this.props.rol === "ADMINISTRATOR") ||
                data.company_id,
              state: { id: data.id },
            });
          }}
          contentData={data}
          parseData={(data) => ({
            createdAt: moment(data.createdAt).format("L"),
            status: data.status
              ? t(`notifications.table.${data.status}`)
              : t("notifications.table.unknown"),
            company_id: data.company_id ? data.company.name : "",
            race_id: data.race_id ? data.race.name : "",
            template_id: data.template_id ? data.template.name : "",
            notification_type: data.notification_type
              ? t(`notifications.notifiationsTypes.${data.notification_type}`)
              : "",
            template_type: data.template_type
              ? t(`notifications.notifiationsTypes.${data.template_type}`)
              : "",
          })}
          totalData={totalData}
          loading={loading}
          rowProps={(data) =>
            data.deleted ? { negative: true, className: "strikethrough" } : {}
          }
        />

        <div className="button-group">
          <FluidResponsiveButton
            id="NotificationsCreateButton"
            icon
            labelPosition="left"
            size="small"
            primary
            onClick={async () =>
              await this.props.history.push({
                pathname: `/notifications/${
                  activeItem === "list" ? "createNotif" : "createTemplate"
                }`,
                editable: true,
              })
            }
          >
            <Icon
              name={
                activeItem === "list"
                  ? "envelope outline"
                  : "file alternate outline"
              }
            />
            {activeItem === "list"
              ? this.props.t("notifications.createNotification")
              : this.props.t("notifications.createTemplate")}
          </FluidResponsiveButton>
        </div>
      </Container>
    );
  }
}
