import { clientApi } from "./index";

export default {
  get: async (params) => {
    try {
      const response = await clientApi
        .getClient()
        .get("/qrReaders", { params });
      return response.data;
    } catch (error) {}
  },
  create: async (email) => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/qrReaders`, { email });
      return response.data;
    } catch (err) {}
  },
  changeStatus: async (id, enabled) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/qrReaders/${id}`, { enabled });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
  delete: async (id) => {
    try {
      const response = await clientApi.getClient().delete(`/qrReaders/${id}`);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },

  //Race-users screen
  updateEnabled: async (params) => {
    try {
      const response = await clientApi.getClient().put(`/qr_reader_race_update`, { ...params, channel_id: 1 });
      return response.data;
    } catch (err) { }
  },
  fetchQrReadersInRace: async (id) => {
    try {
      const response = await clientApi.getClient().get(`/qr_reader_race/${id}`, {params: {channel_id: 1}});
      return response.data;
    } catch (err) { }
  },
  linkQrReaderToRace: async params => {
    try {
      const response = await clientApi.getClient().post("/qr_reader_race", { ...params, channel_id: 1 });
      return response;
    } catch (err) { }
  }
};
