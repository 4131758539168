export default t => {
  return [
    {
      fields: [
        {
          name: "email",
          title: t("login.email"),
          placeHolder: t("login.email"),
          type: "Input",
          colSize: 16,
          validation: {
            required: true,
            regexType: "email",
            errorMessage: t("login.invalidEmailFormat")
          },
          props: {
            id:"LoginEmail",
            autoFocus: true
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "password",
          title: t("login.password"),
          placeHolder: t("login.password"),
          colSize: 16,
          type: "Input",
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
          props: {
            id:"LoginPassword",
            type: "password"
          }
        }
      ]
    }
  ];
};

export const recoverFields = t => [
  {
    fields: [
      {
        name: "email",
        title: t("login.recoverEmail"),
        placeHolder: t("login.email"),
        type: "Input",
        colSize: 16,
        validation: {
          required: true,
          regexType: "email",
          errorMessage: t("commons.INVALID_EMAIL")
        },
        props: {
          id:"RecoverEmail",
          icon: "at",
          iconPosition: "left"
        }
      }
    ]
  }
];
