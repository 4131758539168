import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "semantic-ui-react";
import { useHistory } from "react-router";

import PageNotFoundEs from "../../../public/images/404es.png";
import PageNotFoundEn from "../../../public/images/404en.png";

class NoMatchHandler {
  constructor() {
    this.open = null;
    this.isOpen = null;
    this.history = null;
  }

  setOptions(open, isOpen, history) {
    this.open = open;
    this.isOpen = isOpen;
    this.history = history;
  }
}

export const noMatchHandler = new NoMatchHandler();

export const NoMatchPage = () => {
  const [open, setIsOpen] = useState(false);
  const history = useHistory();

  noMatchHandler.setOptions(setIsOpen, open, history);

  return null;
};

const NoMatch = () => {
  const [t] = useTranslation();

  const lang = t("currentLanguage");

  const src = lang == "en" ? PageNotFoundEn : PageNotFoundEs;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Image src={src} style={{ objectFit: "contain" }} />
    </div>
  );
};

export default NoMatch;
