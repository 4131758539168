//@ts-check
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Modal } from 'semantic-ui-react';
import FilterableTable from '../../../../components/FilterableTable';
import { getTableHeaders } from './util';
import racesApi from '../../../../apis/races';
import notificationApi from '../../../../apis/notifications';
import { Subject } from 'rxjs';
import { has } from 'lodash';

export const actionSubject = new Subject();

/**
 *
 * @typedef {{ [key:number]:boolean|string}} Recipients
 */

/**
 *
 * @param {{
 * companyId: number | null;
 * raceId: number | null
 * modalityId: number | null
 * region: string | null
 * recipients:Recipients
 * onRecipientsChange:(val:Recipients)=>void;
 * }} props
 */
export default function Recipients(props) {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const tableHeaders = getTableHeaders(t);
  const [selectedRecipients, setSelectedRecipients] = useState(
    props.recipients
  );
  const hash = useMemo(
    () =>
      `${props.companyId || '-'},${props.raceId || '-'},${
        props.modalityId || '-'
      },${props.region || '-'}`,
    [props.companyId, props.raceId, props.modalityId, props.region]
  );
  /**
   *
   * @param {{
   * pageSize:number,
   * pageNumber:number
   * sort?: 'ASC' | 'DESC'
   * field?: string;
   * name?: string;
   * }} params
   */
  const getData = async params => {
    const inscriptions = await notificationApi.getRecipents({
      companyId: props.companyId,
      raceId: props.raceId,
      modalityId: props.modalityId,
      region: props.region,
      pageSize: params.pageSize || 10,
      pageNumber: params.pageNumber || 1,
      sort: params.sort,
      field: params.field,
      name: params.name
    });

    setTotalData(inscriptions.count);
    setData(inscriptions.rows);

    setLoading(false);

    mergeRecipients(inscriptions.rows);
  };

  //keep only the recipients that are in the old selectedRecipients
  const mergeRecipients = data => {
    const newRecipients = Object.entries(selectedRecipients).reduce(
      (acc, [id, val]) => {
        if (val == hash) {
          return { ...acc, [id]: hash };
        }
        return acc;
      },
      {}
    );

    setSelectedRecipients(newRecipients);
    props.onRecipientsChange(newRecipients);
  };

  const selectAll = selected => {
    const newRecipients = data.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.athleteId || curr.id]: selected ? hash : false
      }),
      selectedRecipients
    );

    setSelectedRecipients(newRecipients);
    props.onRecipientsChange(newRecipients);
  };

  useEffect(() => {
    getData({ pageNumber: 1, pageSize: 10 });
  }, []);

  const onRowClick = row => {
    const newRecipients = { ...selectedRecipients };

    const id = row.athleteId || row.id;
    newRecipients[id] = newRecipients[id] ? false : hash;

    setSelectedRecipients(newRecipients);
    props.onRecipientsChange(newRecipients);
  };

  useEffect(() => {
    actionSubject.subscribe(selected => {
      selectAll(selected);
    });
  }, [data]);

  return (
    <Modal.Description>
      <Grid padded>
        <Grid.Row>
          <Grid.Column width="16">
            <FilterableTable
              filterFields={[
                {
                  div: 'row',
                  fields: [
                    {
                      name: 'name',
                      placeHolder: t('notificationScreen.recipentsTable.name'),
                      type: 'Input'
                    }
                  ]
                }
              ]}
              id={'RecipentsTable'}
              tableHeaders={tableHeaders}
              onRowClick={row => onRowClick(row)}
              contentData={data}
              totalData={totalData}
              parseData={row => ({
                send:
                  selectedRecipients[row.athleteId || row.id] == hash || false,
                athleteId: row.athleteId || row.id,
                athleteFullName: row.athleteFullName || row.fullName
              })}
              onChange={(table, filter) => getData({ ...table, ...filter })}
              loading={loading}
              // hidePagination={true}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal.Description>
  );
}
