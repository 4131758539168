import { clientApi } from "./index";

export default {
  create: async params => {
    try {
      const response = await clientApi.getClient().post("/modalities", { ...params, channel_id: 1});
      return response.data;
    } catch (err) { }
  },
  fetchModality: async id => {
    try {
      const response = await clientApi.getClient().get(`/modality/${id}`, { params: { channel_id: 1 }});
      return response.data;
    } catch (error) { }
  },
  updateModality: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/modality/${id}`, { ...params, channel_id: 1 });
      return response.data;
    } catch (error) { }
  },
  deleteModality: async id => {
    try {
      const response = await clientApi.getClient().delete(`/modality/${id}`, { params: { channel_id: 1 }});
      return response.data;
    } catch (error) { }
  },
  fetchModalityConfiguration: async id => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/modality/${id}/config_inscription`, {params: {channel_id:1}});
      return response.data;
    } catch (err) { }
  },
  updateModalityConfiguration: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/modality/${id}/config_inscription`, { ...params, channel_id: 1 });
      return response.data;
    } catch (err) { 
      throw err
    }
  },
  fetchInscriptions: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/modalities/${id}/inscriptions`, { params: { ...params, channel_id: 1 } });
      return response.data;
    } catch (err) { }
  },
  fetchInscriptionsCount: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/modality/${id}/inscriptions`, { params: { ...params, channel_id: 1 } });
      return response.data;
    } catch (err) { }
  },
  fetchAttributes: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/attributes/${id}`, { params: { ...params, channel_id: 1 } });
      return response.data;
    } catch (err) { }
  },
  fetchAttribute: async id => {
    try {
      const response = await clientApi.getClient().get(`/attribute/${id}`, {params: {channel_id: 1}});
      return response.data;
    } catch (err) { }
  },
  deleteAttribute: async id => {
    try {
      const response = await clientApi.getClient().delete(`/attribute/${id}`, { params: { channel_id: 1 } });
      return response.data;
    } catch (err) { return err}
  },
  createAttribute: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/attributes/${id}`, { ...params, channel_id: 1 });
      return response.data;
    } catch (err) { return err}
  },
  updateAttribute: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/attribute/${id}`, { ...params, channel_id: 1 });
      return response.data;
    } catch (err) { return err }
  },
  fetchInscriptionInfo: async slug => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/modality/${slug}/inscription`, {params: {channel_id:1}});
      return response.data;
    } catch (err) { }
  },
  getModalityInscriptionStatus: async id => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/modalities/${id}/inscription_status`,{params: {channel_id: 1}});
      return response.data;
    } catch (err) { }
  },
  fetchInscriptionsCsv: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/modalities/inscriptions/csv/${id}`, { params : {...params, channel_id: 1} , timeout: 35000 });
      return response;
    } catch (err) { }
  },
  getVoucherPdf: async (id, params, modalitySlug) => {

    try {
      const response = await clientApi
        .getClient()
        .get(`/modalities/${id}/voucher`, {
          params : {...params, channel_id:1},
          headers: {
            "Content-Type": "application/pdf"
          },
          responseType: "blob",
          timeout: 35000
        });

      var url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.download = `Justificante-${modalitySlug}.pdf`;
      link.setAttribute("download", `Justificante-${modalitySlug}`);
      link.click();
      link.remove();

      return response.data;
    } catch (err) {}

  },
  resetQr: async (id) =>{
    try {
      const response = await clientApi
        .getClient()
        .post(`/modality/${id}/resetqr`, {  
          headers: {
            "Content-Type": "application/pdf"
          },
           timeout: 15000,
           channel_id: 1
        });
      return response;
    } catch (error) {}
  }

};