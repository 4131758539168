import React, { Component } from "react";
import DateTime from "react-datetime";

import { Label } from "semantic-ui-react";
import "./style.css";

export default class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMode: "days",
      dateFormat: "DD/MM/YYYY",
      timeFormat: "HH:mm",
      input: true,
      utc: false,
      closeOnSelect: true,
      closeOnTab: true
    };
  }

  getDefaultDate(value) {
    let ret;

    if (value) {
      ret = new Date(value);
    } else {
      ret = "";
    }

    return ret;
  }

  render() {
    const { label, value } = this.props;

    const dd = this.getDefaultDate(value);
    this.state.value = dd;

    return (
      <div className="form-horizontal">
        <label
          style={{
            padding: "3px",
            display: "block",
            margin: "0 0 .28571429rem 0",
            color: "rgba(0,0,0,.87)",
            fontSize: ".92857143em",
            fontWeight: "700",
            textTransform: "none",
            backgroundColor: "transparent"
          }}
        >
          {label}
        </label>

        <DateTime
          defaultValue={dd}
          onChange={value => this.props.onChange(value)}
          {...this.state}
        />
      </div>
    );
  }
}
