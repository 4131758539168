import React, { Component } from "react";
import { connect } from "react-redux";
import racesApi from "../../../apis/races";
import { withTranslation } from "react-i18next";
import Form from "../../../components/Form";
import ModalityCardList from "../../../components/ModalityCardList";
import { Divider, Container } from "semantic-ui-react";
import moment from 'moment';

import { formFields } from "./util";

const styles = {
  MarginBottom: {
    marginBottom: "4vh"
  }
};

@withTranslation()
@connect(state => ({
  race: state.races.actualRace
}))
export default class RaceDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      cardsPerRow: 3,
      modalities: [],
      showValidation: false,
      showColorSelector: false,
      color: "#ABB8C3"
    };
  }

  async componentDidMount() {
    //await this.getRaceData();

    await this.getModalities();
    window.addEventListener("resize", this.handleResize);
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize(e) {
    try {
      if (window.innerWidth < 736) {
        this.setState({ cardsPerRow: 1 });
      } else if (window.innerWidth < 992) {
        this.setState({ cardsPerRow: 2 });
      } else {
        this.setState({ cardsPerRow: 3 });
      }

    } catch (error) {
    }
  }

  // async getRaceData() {
  //   racesApi.fetchRace(this.props.id).then(response => {
  //     this.setState({ data: response });
  //   });
  // }

  async getModalities() {
    await racesApi
      .fetchModalities({ id: this.props.id })
      .then(response => {
        this.setState({
          modalities: response
        });
      })
      .catch(e => {
        // console.log(e);
      });
  }

  render() {
    let { history, t, race: data } = this.props;
    const { showValidation, cardsPerRow, modalities } = this.state;
    data = { ...data, start_date: moment(data.start_date).format('LL') };

    return (
      <Container>
        <Divider horizontal style={{ ...styles.MarginBottom }}>
          {t("raceDetail.detail.generalInformation.divider")}
        </Divider>

        <div style={{ ...styles.MarginBottom }}>
          <Form
            defaultState={{
              ...data,
              company: data?.company?.name
            }}
            onFormChange={data => {
              this.setState({ data: { ...data, ...data } });
            }}
            fields={formFields(t, data.deleted)}
            showValidation={showValidation}
          />
        </div>
        <Divider horizontal style={{ ...styles.MarginBottom }}>
          {t("raceDetail.detail.events.divider")}
        </Divider>
        <ModalityCardList
          racePublised={data.is_published}
          raceDeleted={data.deleted}
          raceId={data.id}
          modalities={modalities}
          cardsPerRow={cardsPerRow}
          history={history}
        />
      </Container>
    );
  }
}
