//@ts-check
import React, { Component, PureComponent } from "react";
import FormComponentes from "../FormComponents";
// import { compose, withState, withHandlers, lifecycle, pure } from 'recompose'
import PropTypes from "prop-types";
import { get as get_ } from "lodash";
import regex from "./regex";
import { FormGroup } from "semantic-ui-react";

const GetComponent = props => {
  if (props.component == null) {
    const Elm = FormComponentes[props.type];

    if (
      (props.type == "Input" || props.type == "TextArea") &&
      props.executeChangeOnBlur
    ) {
      return (
        <Elm
          colSize={3}
          onChange={val => props.onFieldsChange(props.name, val, props, false)}
          onBlur={val => props.onFieldsChange(props.name, val, props, true)}
          executeChangeOnBlur
          {...props}
        />
      );
    }

    return (
      <Elm
        colSize={3}
        onChange={val => props.onFieldsChange(props.name, val, props, true)}
        {...props}
      />
    );
  } else {
    //the output state, the state of the form , onChange
    const Elm = props.component(
      props.fieldsState,
      props.defaultState,
      data => props.onFieldsChange(props.name, data, props, true),
      props.showValidation
    );
    return Elm;
  }
};

class Form extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fieldsState: {},
      validation: {},
      oldState: {},
      usedFields: []
    };

    this.onFieldsChange = this.onFieldsChange.bind(this);
    this.getDataDependsOn = this.getDataDependsOn.bind(this);
  }

  componentWillUpdate(nextProps) {
    if (
      (this.props.fields.length > 0 &&
        Object.keys(this.state.fieldsState).length == 0) ||
      JSON.stringify(nextProps.defaultState) !=
        JSON.stringify(this.props.defaultState) ||
      JSON.stringify(nextProps.fields) != JSON.stringify(this.props.fields)
    ) {
      this.generateValues(nextProps);
    }
  }

  componentDidMount() {
    if (
      this.props.fields.length > 0 &&
      Object.keys(this.state.fieldsState).length == 0
    ) {
      this.generateValues();
    }
  }

  generateValues(nextProps) {
    //create the default state
    let state = {};
    const { fieldsState, usedFields } = this.state;

    let defaultState, parseState, fields;
    if (nextProps) {
      defaultState = nextProps.defaultState;
      parseState = nextProps.parseState;
      fields = nextProps.fields;
    } else {
      defaultState = this.props.defaultState;
      parseState = this.props.parseState;
      fields = this.props.fields;
    }

    const defaultStateKeys = defaultState ? Object.keys(defaultState) : [];
    let validations = {};
    let ISFORMVALID = true;

    fields.forEach(field => {
      const fFields =
        typeof field.fields == "function"
          ? field.fields(fieldsState)
          : field.fields;

      fFields.forEach(typeField => {
      //  if (typeField.component) return;

        let val = typeField.value == null ? "" : typeField.value;
        //val = typeField.type === "Checkbox" ? false : val;

        if (val == "" && fieldsState[typeField.name]) {
          val = fieldsState[typeField.name];
        }

        //if exist defaultState
        if (defaultStateKeys.length > 0) {
          //if in parseState exist get it from
          if (parseState && parseState[typeField.name]) {
            val = get_(defaultState, parseState[typeField.name]);
          } else {
            val = defaultState[typeField.name]
              ? defaultState[typeField.name]
              : typeField.type === "Checkbox"
              ? false
              : null;
          }
        }

        //parse val tu number
        if (
          typeField.props &&
          typeof typeField.props.type != "undefined" &&
          typeField.props.type == "number" &&
          (val != "" || val != null || !isNaN(val))
        ) {
          val = parseFloat(val | 0);
        }

        if (typeField.returnOnlyValue == true) {
          const getValue =
            typeof typeField.getOptionValue == "string"
              ? typeField.getOptionValue
              : "value";

          if (val && val[getValue]) val = val[getValue];
          //   newState = { ...fieldsState, [field]: val[getValue], }
        }

        //set validations
        if (typeField.validation) {
          let isValid = true;
          let message = "";

          if (typeField.validation.required) {
            if (val == "" || val == null || val == undefined || (typeof val === "string" && val.trim() == "")) {
              isValid = false;
              ISFORMVALID = false;
            }
          }

          //check regex
          if (typeField.validation.regexType && val) {
            const result = val
              .toString()
              .match(regex[typeField.validation.regexType]);

            if (!result) {
              ISFORMVALID = false;
              isValid = false;
              message = typeField.validation.errorMessage;
            }
          }

          // check custom of generate values validation
          if (typeField.validation.custom) {
            let customErrorMessage = null;
            let result = typeField.validation.custom({
              ...state,
              [typeField.name]: val,
              ISFORMVALID
            });

            if (typeof result == "object") {
              customErrorMessage = result.errorMessage;
              result = result.valid;
            }

            if (!result) {
              ISFORMVALID = false;
              isValid = false;

              message = customErrorMessage
                ? customErrorMessage
                : typeField.validation.errorMessage;
            }
          }

          validations = {
            ...validations,
            [typeField.name]: {
              ...typeField.validation,
              isValid,
              message,
              errorMessage: message
                ? message
                : typeField.validation.errorMessage
            }
          };
        }
        state = { ...state, [typeField.name]: val, ISFORMVALID };
      });
    });

    this.setState({
      fieldsState: state,
      validation: validations,
      oldState: state
    });

    if (this.props.fields.length > 0) {
      this.props.onFormChange({ ...state });
    }
  }

  onFieldsChange(field, val, opts, doOnChange) {
    const { fieldsState, validation, oldState, usedFields } = this.state;

    this.setState({ oldState: fieldsState });
    // setOldState(fieldsState);
    let newValidation = { ...validation };
    let ISFORMVALID = true;
    let message = "";

    //parse val tu number
    if (
      opts.props &&
      typeof opts.props.type != "undefined" &&
      opts.props.type == "number" &&
      (val != "" || val != null)
    ) {
      val = parseFloat(val || 0);
    }

    if (validation[field]) {
      let isValid = true;

      //check the type and
      if (validation[field].type && typeof val !== validation[field].type) {
        ISFORMVALID = false;
        isValid = false;
      }
      if (validation[field].required && (val == "" || val == null || (typeof val === "string" && val.trim() == ""))) {
        ISFORMVALID = false;
        isValid = false;
        message =
          validation[field] && validation[field].errorMessage
            ? validation[field].errorMessage
            : "This field is required";
      }

      //check regex
      if (validation[field].regexType) {
        const result =
          typeof val !== "undefined" &&
          val !== null &&
          val.match(regex[validation[field].regexType]);

        if (!result) {
          ISFORMVALID = false;
          isValid = false;
          message = validation[field].errorMessage;
        }
      }

      //check custom of onFieldsChange validation
      if (validation[field].custom) {
        let customErrorMessage = null;
        let result = validation[field].custom({
          ...fieldsState,
          [field]: val,
          ISFORMVALID
        });

        if (typeof result == "object") {
          customErrorMessage = result.errorMessage;
          result = result.valid;
        }

        if (!result) {
          ISFORMVALID = false;
          isValid = false;

          message = customErrorMessage
            ? customErrorMessage
            : validation[field].errorMessage;
        }
      }

      newValidation = {
        ...newValidation,
        [field]: {
          ...validation[field],
          isValid,
          message,
          errorMessage: message ? message : validation[field].errorMessage
        }
      };
    }

    const validationKeys = Object.keys(newValidation);

    const totalValid = validationKeys.filter(
      key => newValidation[key].isValid == true
    );

    ISFORMVALID = totalValid.length == validationKeys.length;

    let newState = { ...fieldsState, [field]: val, ISFORMVALID };

    if (opts.returnOnlyValue) {
      const getValue =
        typeof opts.getOptionValue == "string" ? opts.getOptionValue : "value";

      newState = {
        ...fieldsState,
        [field]: val ? val[getValue] : val,
        ISFORMVALID
      };
    }

    //use for know if the the field was used
    const newUsedFields = [...usedFields];
    if (!usedFields.includes(field)) {
      newUsedFields.push(field);
    }

    if (val === "") newState = { ...newState, [field]: null }
    
    if (typeof val === "string") newState = { ...newState, [field]: val.trimStart() }

    this.setState({
      fieldsState: newState,
      validation: newValidation,
      usedFields: newUsedFields
    });

    if (doOnChange) {
      return this.props.onFormChange(newState);
    }
  }

  getDataDependsOn(typeField) {
    if (typeField.dataDependsOn) {
      const data = get_(this.state.fieldsState, typeField.dataDependsOn);
      return data ? data : [];
    }
  }

  getValue(typeField) {
    const { fieldsState, oldState } = this.state;

    if (typeField.dataDependsOn) {
      const fieldDepends = typeField.dataDependsOn.split(".")[0];
      const current = get_(fieldsState, fieldDepends);
      const old = get_(oldState, fieldDepends);
      if (JSON.stringify(current) !== JSON.stringify(old)) {
        // fieldsState[typeField.name] = null;//prevent do this, just in extrme cases;
        return null;
      } else {
        return fieldsState[typeField.name];
      }
    } else {
      return fieldsState[typeField.name];
    }
  }

  render() {
    const {
      fields,
      showValidation,
      executeChangeOnBlur,
      defaultState,
      style,
      className
    } = this.props;

    const { validation, fieldsState, usedFields } = this.state;

    return (
      <div style={style} className={className}>
        {fields.map((field, key) => {
          const fFields =
            typeof field.fields == "function"
              ? field.fields(fieldsState)
              : field.fields;
          return (
            <FormGroup widths="16" key={key}>
              {fFields.map((typeField, key2) => {
                return (
                  <GetComponent
                    key={key2}
                    data={this.getDataDependsOn(typeField)}
                    {...typeField}
                    value={this.getValue(typeField)}
                    fieldsState={fieldsState}
                    onFieldsChange={this.onFieldsChange}
                    validation={validation}
                    showValidation={showValidation}
                    executeChangeOnBlur={executeChangeOnBlur}
                    defaultState={defaultState}
                    usedFields={usedFields}
                  />
                );
              })}
            </FormGroup>
          );
        })}
      </div>
    );
  }
}

Form.propTypes = {
  /**Hello */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      div: PropTypes.string,
      fields: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            title: PropTypes.string,
            type: PropTypes.oneOf([
              "Input",
              "Select",
              "DatePicker",
              "DateTimePicker",
              "Switch",
              "TextArea",
              "Label",
              "RadioButtons",
              "Checkbox",
              "Null",
              "Divider"
            ]),
            value: PropTypes.any,
            data: PropTypes.any,
            returnOnlyValue: PropTypes.bool,
            /** you can pass any object here */
            props: PropTypes.any,
            colSize: PropTypes.number,
            /**returns the state of the form */
            component: PropTypes.func,
            validation: PropTypes.shape({
              required: PropTypes.bool,
              regexType: PropTypes.string,
              errorMessage: PropTypes.any
            })
          })
        ),
        PropTypes.func
      ])
    })
  ),
  onFind: PropTypes.func,
  defaultState: PropTypes.any,
  parseState: PropTypes.any,
  onFormChange: PropTypes.func
};

Form.defaultProps = {
  fields: [],
  onFormChange: () => {},
  executeChangeOnBlur: true,
  defaultState: {},
  parseState: {},
  showValidation: false,
  className: "ui form",
  style: {}
};

export default Form;
