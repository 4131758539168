import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Modal,
  Grid,
  Button,
  ButtonGroup,
  Message
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { DAlert } from "../../../components/Dalert";
import CreateDiscountButton from "./CreateDiscountButton";
import raceApi from "../../../apis/races";
import discountApi from "../../../apis/discounts";
import FilterableTable from "../../../components/FilterableTable";
import { filterFields, tableHeaders } from "./util";
import { discountFields } from "./DiscountDetail/util"
import { modalHandler } from "../../../components/DModal";
import Form from "../../../components/Form";

@withTranslation()
@connect(
  (state) => ({
    race: state.races.actualRace,
  })
)
export default class RaceDiscounts extends Component {
  constructor(props) {
    super(props);
    this.usedCombo = [
      { text: props.t("raceDiscounts.table.used"), value: "true" },
      { text: props.t("raceDiscounts.table.notUsed"), value: "false" }
    ];

    this.state = {
      isLoading: false,
      data: [],
      totalData: 0,
      error: null,
      showValidation: false,
      editDiscountForm: {}
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.getData();
    this.setState({ isLoading: false });
  }

  async getData(params) {
    const race = await raceApi.fetchRace(this.props.id);

    const response = await raceApi.fetchDiscounts({
      id: this.props.id,
      params
    });
    (response.rows).map(d => {
      let usedBy = "";
      let usedByEmail = "";
      let usedOn = "";
      d.race = race.name;
      d.used = !!d.inscriptionId
      d.value = `${d.value + (d.type === "VAL" ? " €" : " %")}`
      if (d.inscription) {
        usedBy = `${d.inscription?.name} ${d.inscription?.surnames}`;
        usedByEmail = d.inscription?.email;
        usedOn = d.inscription?.createdAt;
      }
      d.usedBy = usedBy
      d.usedByEmail = usedByEmail
      d.usedOn = usedOn
    })
    // (response.rows).map(d => { this.parseDiscount(d)})


    this.setState({ data: response.rows, totalData: response.count });
  }

  parseDiscount(discount) {
    /*
    In some cases (older cases) discount.inscriptionId 
    can point to a non existent inscription and therefore name, surnames,
    email and createdAt will not exist
    */
    let usedBy = "";
    let usedByEmail = "";
    let usedOn = "";
    if (discount.inscription) {
      usedBy = `${discount.inscription?.name} ${discount.inscription?.surnames}`;
      usedByEmail = discount.inscription?.email;
      usedOn = discount.inscription?.createdAt;
    }

    return {
      ...discount,
      used: !!discount.inscription_id,
      value: `${discount.value + (discount.type === "VAL" ? " €" : " %")}`,
      usedBy,
      usedByEmail,
      usedOn,
    };
  }
  modalOptions = data => {
    const { t, race } = this.props;
    const { error, showValidation } = this.state;

    const canEdit = !data.used && !data.booked

    return {
      header: t("raceDiscounts.modal.editDiscount"),
      size: "large",
      content: (
        <Modal.Description>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width="16">
                <Form
                  showValidation={showValidation}
                  defaultState={data}
                  fields={discountFields(t, data)}
                  onFormChange={data => {
                    this.setState({ editDiscountForm: data }, () => {
                      modalHandler.refreshOptions({
                        ...this.modalOptions(data)
                      });
                    });
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            {error && (
              <Grid.Row>
                <Grid.Column width="16">
                  <Message error content={error} />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Modal.Description>
      ),
      actions: (
        <Grid>
          <Grid.Column width="16">
           {!race.deleted && <Button
              floated="left"
              color="red"
              icon="trash alternate outline"
              disabled={!canEdit}
              content={t("commons.delete")}
              onClick={async () => {
                await this.deletediscount(data);
              }}
            />}
            <ButtonGroup floated="right">
              <Button color="google plus" onClick={() => modalHandler.close()}>
                {t("commons.cancel")}
              </Button>
              {/* <Button
                primary
                disabled={!canEdit}
                onClick={async () => {
                  await this.updateDiscount(data);
                }}
              >
                {t("commons.save")}
              </Button> */}
            </ButtonGroup>
          </Grid.Column>
        </Grid>
      )
    };
  };

  deletediscount = async discountData => {
    const { t } = this.props;

    modalHandler.open({
      header: null,
      content: <Modal.Description>{t("commons.areYouSure")}</Modal.Description>,
      basic: false,
      size: "tiny",
      onYes: async () => {
        const response = await discountApi.deleteDiscount(this.state.id);

        if (typeof response === "undefined") {
          this.setState({ error: t("raceDiscounts.modal.cannotDelete") }, () => {
            modalHandler.refreshOptions({
              ...this.modalOptions(discountData)
            });
          });
        } else {
          DAlert.success({ title: t("commons.erasedSuccessfully") });
          modalHandler.close();
          await this.getData();
        }
      }
    });
  };

  render() {
    const { t, history, id, race } = this.props;

    const { data, totalData, loading } = this.state;


    return (
      <Container>
        <FilterableTable
          filterFields={filterFields(t, this.usedCombo)}
          onChange={(table, filters) => this.getData({ ...table, ...filters })}
          tableHeaders={tableHeaders(t)}
          loading={loading}
          contentData={data}
          totalData={totalData}
          parseData={data => ({
            createdAt: moment(data.createdAt).format("L"),
            // value: `${data.value + (data.type === "VAL" ? " €" : " %")}`,
            limitDate: data.limitDate
              ? moment(data.limitDate).format("L")
              : "---",
            inscriptionId: data.inscriptionId === null ? false : true,
            booked: data.booked === null ? false : true
          })}
          // onRowClick={data =>
          //   history.push(`/race/${data.raceId}/discount/${data.id}`)
          // }
          onRowClick={data => {
            this.setState({ id: data.id });
            this.setState({ error: null, showValidation: false }, () => {
              modalHandler.open(this.modalOptions(data));
            });
          }}
        />
        {!race.deleted && <CreateDiscountButton raceId={id} getData={() => this.getData()} />}
      </Container>
    );
  }
}
