export const tableHeaders = t => {
  return [
    {
      content: t("raceAccounts.tableColumns.inscriptionType"),
      accessor: "name"
    },
    {
      content: t("raceAccounts.tableColumns.inscriptions"),
      accessor: "inscriptions",
      props: {
        textAlign: "right"
      }
    },
    {
      content: t("raceAccounts.tableColumns.raised"),
      accessor: "price",
      props: {
        textAlign: "right"
      }
    }
  ];
};
export const tableHeadersDetail = t => {
  return [
    {
      content: "",
      accessor: "name"
    },
    {
      content: t("raceAccounts.tableColumns.inscriptions"),
      accessor: "inscriptions",
      props: {
        textAlign: "right"
      }
    },
    {
      content: t("raceAccounts.tableColumns.raised"),
      accessor: "price",
      props: {
        textAlign: "right"
      }
    }
  ];
};

export const tableHeadersPanel = (t, modalityName) => {
  return [
    {
      content: "",
      accessor: "name"
    },
    {
      content: t("raceAccounts.tableColumns.inscriptions"),
      accessor: "inscriptions",
      props: {
        textAlign: "right"
      }
    },
    {
      content: t("raceAccounts.tableColumns.raised"),
      accessor: "price",
      props: {
        textAlign: "right"
      }
    }
  ];
};

export const tableHeadersEvent = (t, modalityName) => {
  let name ="";
  if(modalityName) {
    name = modalityName.toUpperCase();
  }
  return [
    {
      content: name,
      accessor: "name"
    },
    {
      content: t("raceAccounts.tableColumns.inscriptions"),
      accessor: "inscriptions",
      props: {
        textAlign: "right"
      }
    },
    {
      content: t("raceAccounts.tableColumns.raised"),
      accessor: "price",
      props: {
        textAlign: "right"
      }
    }
  ];
};

export const tableHeadersCommission = (t) => {
  return [
    {
      content: t("raceAccounts.tableColumns.concept"),
      accessor: "name"
    },
    {
      content: t("raceAccounts.tableColumns.quantity"),
      accessor: "inscriptions",
      props: {
        textAlign: "right"
      }
    },
    {
      content: t("raceAccounts.tableColumns.totalCommissions"),
      accessor: "price",
      props: {
        textAlign: "right"
      }
    }
  ];
};
