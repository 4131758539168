import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";
import moment from "moment";

import FilterableTable from "../../components/FilterableTable";
import AssignPlaceButton from "../../components/AssignPlaceButton";

import inscriptionApi from "../../apis/inscriptions";
import racesTypesApi from "../../apis/raceTypes";
import { filterFields, tableHeaders } from "./util";
import dictionary from "../../locales/default.en";
import { DAlert } from "../../components/Dalert";

@withTranslation()
export default class Inscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      totalData: 0,
      loading: false,
      activityTypes: [],
      params: {}
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getData();
    await this.getActivityTypes();
    this.setState({ loading: false });
  }

  async getActivityTypes() {
    const { t } = this.props;

    const activityTypesRaw = await racesTypesApi.fetchActivityTypes();
    const activityTypes = activityTypesRaw.map((act) => ({
      text: t(`commons.activities.${act.type}`),
      value: act.id,
    }));

    this.setState({ activityTypes });
  }

  async getData(params) {
    const { t } = this.props;
    const data = await inscriptionApi.get(params);
    if(data){
      this.setState({ data: data.rows, totalData: data.count, params });
    } else {
      DAlert.error({ title: t("commons.errorReading") });
      this.setState({ loading: false });
    }
  }

  render() {
    const { t } = this.props;
    const { data, totalData, loading, activityTypes, params } = this.state;

    return (
      <div>
        <Container>
          <FilterableTable
            filterFields={filterFields(t, activityTypes)}
            tableHeaders={tableHeaders(t, params)}
            contentData={data}
            onRowClick={(data) =>
              this.props.history.push(
                `/race/${data.raceId}/inscription/${
                  data.clientInscriptionId
                }?f=${data.statusId !== 1 ? true : false}`,
                { data }
              )
            }
            totalData={totalData}
            onChange={(table, filter) => this.getData({ ...table, ...filter })}
            parseData={(row) => ({
              inscriptionDate: moment(row.inscriptionDate).format("L"),
              // inscriptionStatus: t(`paymentStatus.${row.inscriptionStatus}`),
              combinedStatus: row.combinedStatus
                ? t(`paymentStatus.${row.combinedStatus}`)
                : "",
              paymentOperation_code:
                row.paymentOperation_code &&
                `${row.paymentOperation_code} ${row.paymentAuthCode &&
                  `(${row.paymentAuthCode})`}`,
              inscriptionType:
                row.statusId !== 1 ? null : row.athleteStatus ===
                  "CANCELLED" ? (
                  t("inscriptionsScreen.notFinished")
                ) : (
                  <AssignPlaceButton inscriptionId={row.clientInscriptionId} asIcon/>
                ),
              inscriptionFrom:
                row.inscriptionFrom &&
                Object.keys(dictionary.commons.origin).includes(row.inscriptionFrom )
                  ? t(`commons.origin.${row.inscriptionFrom}`)
                  : t(`commons.origin.UNKNOWN`),
            })}
            loading={loading}
            rowProps={(data) =>
              data.athleteStatus
                ? { negative: true, className: "strikethrough" }
                : {}
            }
            fixed
          />
        </Container>
      </div>
    );
  }
}
