export default [
  {
    path: "/home",
    header: "home"
  },
  {
    path: "/users",
    header: "users",
    routes: [
      {
        title: "users"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER", "POINT_OF_SALE"]
  },
  {
    path: "/user/:id",
    header: "user",
    routes: [
      {
        title: "users",
        path: "/users"
      },
      {
        title: "user"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY"]
  },
  {
    path: "/companies",
    header: "companies",
    routes: [{ title: "companies" }],
    roles: ["ADMINISTRATOR"]
  },
  {
    path: "/company/:id",
    header: "company",
    routes: [
      {
        title: "companies",
        path: "/companies"
      },
      {
        title: "company"
      }
    ],
    roles: ["ADMINISTRATOR"]
  },
  {
    path: "/company",
    header: "company_new",
    routes: [
      {
        title: "companies",
        path: "/companies"
      },
      {
        title: "company",
        path: "/company"
      }
    ],
    roles: ["ADMINISTRATOR"]
  },
  {
    path: "/qrreaders",
    header: "qrReaders",
    routes: [
      {
        title: "qrReaders"
      }
    ],
    roles: ["PLANNER"]
  },
  {
    path: "/races",
    header: "races",
    routes: [
      {
        title: "races"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER", "POINT_OF_SALE"]
  },
  {
    path: "/events",
    header: "events",
    routes: [
      {
        title: "events"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER", "POINT_OF_SALE"]
  },
  {
    path: "/associates",
    header: "associates",
    routes: [
      {
        title: "associates"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER", "POINT_OF_SALE"]
  },
  {
    path: "/race/:id",
    header: "race",
    routes: [
      {
        title: "races",
        path: "/races"
      },
      {
        title: "race"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER", "POINT_OF_SALE"]
  },
  {
    path: "/race/create",
    header: "newRace",
    routes: [
      {
        title: "newRace"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY"]
  },
  {
    path: "/race/copy",
    header: "copyRace",
    routes: [
      {
        title: "copyRace"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY"]
  },
  {
    path: "/race/:raceId/inscription/:id",
    header: "inscription",
    routes: [
      {
        title: "races",
        path: "/races"
      },
      {
        title: "race",
        path: "/race/:id",
        onFallback: location =>
          `/race/${location.pathname.split("/")[2]}#inscriptions`
      },
      {
        title: "inscription"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER", "POINT_OF_SALE"]
  },
  {
    path: "/race/:raceId/discount/:id",
    header: "discount",
    routes: [
      {
        title: "races",
        path: "/races"
      },
      {
        title: "race",
        path: "/race/:id",
        onFallback: location =>
          `/race/${location.pathname.split("/")[2]}#discounts`
      },
      {
        title: "discount"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER"]
  },
  {
    path: "/race/:raceId/modality/:id",
    header: "modality",
    routes: [
      {
        title: "races",
        path: "/races"
      },
      {
        title: "race",
        path: "/race/:id",
        onFallback: location => `/race/${location.pathname.split("/")[2]}`
      },
      {
        title: "modality"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER"]
  },
  {
    path: "/race/:raceId/modality/:modalityId/attribute/:id",
    header: "attribute",
    routes: [
      {
        title: "races",
        path: "/races"
      },
      {
        title: "race",
        path: "/race/:id",
        onFallback: location => `/race/${location.pathname.split("/")[2]}`
      },
      {
        title: "modality",
        path: "/race/:id/modality/:id",
        onFallback: location => {
          const loc = location.pathname.split("/");
          return `/race/${loc[2]}/modality/${loc[4]}#attributes`;
        }
      },
      {
        title: "attribute"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER"]
  },
  {
    path: "/race/:id/modality/:modalityId/inscription/create",
    header: "inscription",
    routes: [
      {
        title: "races",
        path: "/races"
      },
      {
        title: "race",
        path: "/race/:id",
        onFallback: location => `/race/${location.pathname.split("/")[2]}`
      },
      {
        title: "inscriptions",
        path: "/race/:id/modality/:id",
        onFallback: location => {
          const loc = location.pathname.split("/");
          return `/race/${loc[2]}/modality/${loc[4]}#inscriptions`;
        }
      },
      {
        title: "inscription"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER", "POINT_OF_SALE"]
  },
  {
    path: "/clients",
    header: "clients",
    routes: [
      {
        title: "clients",
        path: "/clients"
      }
    ],
    roles: ["ADMINISTRATOR"]
  },
  {
    path: "/client/:id",
    header: "client",
    routes: [
      {
        title: "clients",
        path: "/clients"
      },
      {
        title: "client",
        path: "/client/:id"
      }
    ],
    roles: ["ADMINISTRATOR"]
  },
  {
    path: "/inscriptions",
    header: "inscriptions",
    routes: [
      {
        title: "inscriptions",
        path: "/inscriptions"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER", "POINT_OF_SALE"]
  },
  {
    path: "/inscription/:id",
    header: "inscription",
    routes: [
      {
        title: "inscriptions",
        path: "/inscriptions"
      },
      {
        title: "inscription",
        path: "/inscription/:id"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY", "PLANNER", "POINT_OF_SALE"]
  },
  {
    path: "/notifications",
    header: "notifications",
    routes: [
      {
        title: "notifications",
        path: "/notifications"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY"]
  },
  {
    path: "/notifications/:id",
    header: "notifications",
    routes: [
      {
        title: "notification"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY"]
  },
  {
    path: "/notifications/createNotif",
    header: "notifications",
    routes: [
      {
        title: "notification"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY"]
  },
  {
    path: "/notifications/template/:id",
    header: "template",
    routes: [
      {
        title: "notifications",
        path: "/notifications"
      },
      {
        title: "template",
        path: "/template/:id"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY"]
  },
  {
    path: "/notifications/notification/:id",
    header: "notification",
    routes: [
      {
        title: "notifications",
        path: "/notifications"
      },
      {
        title: "notification",
        path: "/notification/:id"
      }
    ],
    roles: ["ADMINISTRATOR", "COMPANY"]
  },
  {
    path: "/config",
    header: "config",
    routes: [
      {
        title: "config"
      }
    ],
    roles: ["ADMINISTRATOR"]
  }
];
