import { clientApi } from ".";

export default {
  fetchTemplates: async (params) => {
    try {
      const response = await clientApi
        .getClient()
        .get("/templates", { params : {...params, channel_id: 1} });

      return response.data;
    } catch (err) {}
  },
  getTemplate: async (id) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/template/${id}`, {params : {channel_id: 1}});

      return response.data;
    } catch (err) {}
  },
  updateTemplate : async(id, data) => {
    try {
        const response = await clientApi
          .getClient()
          .put(`/template/${id}`, { ...data, channel_id: 1 });
  
        return response.data;
      } catch (err) {}
  },
  createTemplate : async(data) => {
    try {
      console.log(data)
        const response = await clientApi
          .getClient()
          .post(`/templates`, { ...data, channel_id: 1 });
  
        return response.data;
      } catch (err) {}
  },
  deleteTemplate : async(id) => {
    try {
        const response = await clientApi
          .getClient()
          .delete(`/template/${id}`, {params: {channel_id: 1}});
  
        return response.data;
      } catch (err) {}
  }
};
