//@ts-check
import React, { useEffect, useState, useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Table,
  Grid,
  Pagination,
  Dropdown,
  Segment,
  Responsive,
  Popup
} from "semantic-ui-react";

import TrueFalse from "../../components/TrueFalse";
import { get, isArray, slice } from "lodash";

const PAGINATION = "PAGINATION";
const ROWS_PER_PAGE = "ROWS_PER_PAGE";
const SORT_DIRECTION = "SORT_DIRECTION";

const initialState = {
  changed: false,
  activePage: 1,
  rowsPerPage: 10,
  sortDirection: null,
  columnSelected: null
};

const tableReducer = (state, action) => {
  switch (action.type) {
    case PAGINATION:
      return {
        ...state,
        changed: true,
        activePage: action.activePage
      };

    case SORT_DIRECTION:
      if (state.columnSelected !== action.clickedColumn) {
        return {
          ...state,
          changed: true,
          activePage: 1,
          columnSelected: action.clickedColumn,
          sortDirection: "ascending"
        };
      }

      return {
        ...state,
        changed: true,
        activePage: 1,
        sortDirection:
          state.sortDirection === "ascending" ? "descending" : "ascending"
      };

    case ROWS_PER_PAGE:
      return {
        ...state,
        changed: true,
        activePage: 1,
        rowsPerPage: action.rowsPerPage
      };
  }
};

const getCells = (data, dataIndex, props) => {
  const newData = { ...data, ...props.parseData(data, dataIndex) };

  return props.headers.map((header, i2) => (
    <Table.Cell
      className="TableCell"
      key={`${props.id}Cell${i2}Row${dataIndex}`}
      {...header.props}
    >
      {isArray(get(newData, header.accessor)) ? (
        get(newData, header.accessor).map((a, i) => (
          <React.Fragment key={`${props.id}Cell${i2}Row${dataIndex}Row${i}`}>
            {a}
            <br />
          </React.Fragment>
        ))
      ) : typeof get(newData, header.accessor) === "boolean" ? (
        <TrueFalse value={get(newData, header.accessor)} />
      ) : (
        get(newData, header.accessor)
      )}
    </Table.Cell>
  ));
};

const MyTable = props => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [responsivePaginationProps, setResponsivePaginationProps] = useState();
  const [selectedRow, setSelectedRow] = useState(props.defaultSelectedRow);
  const [state, dispatch] = useReducer(tableReducer, initialState);
  const { activePage, rowsPerPage, sortDirection, columnSelected } = state;

  const [t] = useTranslation();

  useEffect(()=>{

    if (props.rowsPerPage) {
      dispatch({ type: ROWS_PER_PAGE, rowsPerPage: props.rowsPerPage })
    }

  },[])

  useEffect(() => {
    if (state.changed) {
      props.onTableChange({
        pageNumber: activePage,
        pageSize: rowsPerPage,
        sort:
          sortDirection === "ascending"
            ? "asc"
            : sortDirection === "descending"
            ? "desc"
            : null,
        field: columnSelected
      });
    }
  }, [state]);

  useEffect(() => {
    if (screenWidth < 768) {
      setResponsivePaginationProps({
        ellipsisItem: null,
        siblingRange: 1,
        firstItem: null,
        lastItem: null,
        prevItem: null,
        nextItem: null
      });
    } else {
      setResponsivePaginationProps({});
    }
  }, [screenWidth]);

  useEffect(() => {
    setSelectedRow(props.defaultSelectedRow);
  }, [props.defaultSelectedRow]);

  const getData = () => {
    if (props.autoPaginate) {
      let useData = [...props.contentData];

      let from = activePage == 1 ? 0 : activePage - 1;

      from = from * rowsPerPage;

      let to = activePage * rowsPerPage;

      return slice(useData, from, to);
    }

    return props.contentData;
  };

  return (
    <Table sortable selectable color={"purple"} striped id={props.id} fixed={props.fixed}>
      <Table.Header className="TableHead">
        <Table.Row className="TableHeadRow">
        {props.headers.map((header) =>
            props && props.fixed && header.content ? (
              <Popup
                content={header.content}
                trigger={
                  <Table.HeaderCell
                    className="TableHeading"
                    style={{ cursor: props.style?.cursor || "pointer" }}
                    key={`${props.id}${header.content}`}
                    sorted={
                      columnSelected === header.accessor ? sortDirection : null
                    }
                    onClick={() => {
                      if (!header.noSort) {
                        dispatch({
                          type: SORT_DIRECTION,
                          clickedColumn: header.accessor,
                        });
                      }
                    }}
                    textAlign={header.textAlign}
                    {...header.headerProps}
                  >
                    {header.content}
                  </Table.HeaderCell>
                }
              />
            ) : (
              <Table.HeaderCell
                className="TableHeading"
                style={{ cursor: props.style?.cursor || "pointer" }}
                key={`${props.id}${header.content}`}
                sorted={
                  columnSelected === header.accessor ? sortDirection : null
                }
                onClick={() => {
                  if (!header.noSort) {
                    dispatch({
                      type: SORT_DIRECTION,
                      clickedColumn: header.accessor,
                    });
                  }
                }}
                textAlign={header.textAlign}
                {...header.headerProps}
              >
                {header.content}
              </Table.HeaderCell>
            )
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body className="TableBody">
        {props.contentData.length > 0 ? (
          getData().map((data, i) => (
            <Table.Row
              className="TableRow"
              active={props.showSelectedRow ? i === selectedRow : undefined}
              key={`${props.id}Row${i}`}
              onClick={(e) => {
                setSelectedRow(i);
                let element = e.target;
                if (props.onRowClick && element.tagName === "TD") {
                  props.onRowClick(data, i, e);
                }
              }}
              style={{ cursor: props.style?.cursor || "pointer" }}
              {...props.rowProps(data, i)}
            >
              {getCells(data, i, props)}
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.Cell
              id="TableNoContent"
              colSpan={props.headers.length}
              textAlign="center"
            >
              {t("tableComponent.noContent")}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer className="TableFooter">
        <Table.Row>
          <Table.HeaderCell colSpan={props.headers.length}>
            <Grid columns={1}>
              <Grid.Column textAlign="right">
                {props.showNumberOfRecords && props.totalData > 0 && (
                  <Segment basic floated="left" style={{ margin: 0 }}>
                    {t("tableComponent.totalItems", {
                      rowsPerPage: getData().length,
                      total: props.totalData
                    })}
                  </Segment>
                )}
                {props.showItemsPerPage && (
                  <Dropdown
                    compact
                    selection
                    onChange={(_, { value }) =>
                      dispatch({ type: ROWS_PER_PAGE, rowsPerPage: value })
                    }
                    value={rowsPerPage}
                    options={[5, 10, 20, 30, 40, 50].map(number => ({
                      text: t("tableComponent.rowsPerPage", { number }),
                      value: number
                    }))}
                  />
                )}
                {props.showPagination && props.totalData > rowsPerPage && (
                  <Responsive
                    className="PaginationBar"
                    as={Pagination}
                    style={{ marginLeft: 10 }}
                    onUpdate={(_, data) => setScreenWidth(data.width)}
                    activePage={
                      props.activePage ? props.activePage : activePage
                    }
                    totalPages={Math.ceil(props.totalData / rowsPerPage)}
                    onPageChange={(_, data) => {
                      dispatch({
                        type: PAGINATION,
                        activePage: data.activePage
                      });
                    }}
                    {...responsivePaginationProps}
                  />
                )}
              </Grid.Column>
            </Grid>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

MyTable.propTypes = {
  id: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      content: PropTypes.any
    })
  ),
  parseData: PropTypes.func,
  contentData: PropTypes.arrayOf(PropTypes.object),
  onRowClick: PropTypes.func,
  activePage: PropTypes.number,
  totalData: PropTypes.number,
  onTableChange: PropTypes.func,
  showTotalItems: PropTypes.bool,
  showPagination: PropTypes.bool,
  showItemsPerPage: PropTypes.bool,
  showSelectedRow: PropTypes.bool,
  defaultSelectedRow: PropTypes.number,
  rowProps: PropTypes.func,
  rowsPerPage:PropTypes.number
};

MyTable.defaultProps = {
  id: "TableComponent",
  headers: [],
  parseData: () => {},
  contentData: [],
  onRowClick: () => {},
  activePage: undefined,
  totalData: 0,
  onTableChange: () => {},
  showTotalItems: true,
  showPagination: true,
  showItemsPerPage: true,
  showNumberOfRecords: true,
  showSelectedRow: false,
  defaultSelectedRow: null,
  rowProps: () => {},
  rowsPerPage:0,
};

export default MyTable;
