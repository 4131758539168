export const filterFields = (t) => [
  {
    fields: [
      {
        name: "name",
        placeHolder: t("qrReadersScreen.table.name"),
        type: "Input",
        colSize: 8,
        props: { id: "QRReadersFilterName" },
      },
      {
        name: "email",
        placeHolder: t("qrReadersScreen.table.email"),
        type: "Input",
        colSize: 8,
        props: { id: "QRReadersFilterEmail" },
      },
    ],
  },
];

export const tableHeaders = (t) => [
  {
    content: t("qrReadersScreen.table.date"),
    accessor: "createdAt",
  },
  {
    content: t("qrReadersScreen.table.name"),
    accessor: "name",
  },
  {
    content: t("qrReadersScreen.table.surname"),
    accessor: "surnames",
  },
  {
    content: t("qrReadersScreen.table.email"),
    accessor: "email",
  },
  {
    content: t("qrReadersScreen.table.enabled"),
    accessor: "enabled",
  },
];

export const modalField = (t) => [
  {
    fields: [
      {
        name: "email",
        placeHolder: t("qrReadersScreen.table.email"),
        type: "Input",
        colSize: 16,
        validation: {
          regexType: "email",
          required: true,
          errorMessage: t("commons.INVALID_EMAIL"),
        },
      },
    ],
  },
];

export const rowDetails = (t) => [
  {
    fields: [
      {
        type: "Input",
        title: t("qrReadersScreen.table.name"),
        name: "name",
        colSize: 6,
        props: {
          readOnly: true,
        },
      },
      {
        type: "Input",
        title: t("qrReadersScreen.table.surname"),
        name: "surnames",
        colSize: 6,
        props: {
          readOnly: true,
        },
      },
      {
        type: "Input",
        title: t("qrReadersScreen.table.status"),
        name: "status",
        colSize: 4,
        props: {
          readOnly: true,
        },
      },
    ],
  },
  {
    fields: [
      {
        type: "Input",
        title: t("qrReadersScreen.table.email"),
        name: "email",
        colSize: 8,
        props: {
          readOnly: true,
        },
      },
      {
        type: "Input",
        title: t("qrReadersScreen.table.createdAt"),
        name: "createdAt",
        colSize: 4,
        props: {
          readOnly: true,
        },
      },
      {
        type: "Input",
        title: t("qrReadersScreen.table.updatedAt"),
        name: "updatedAt",
        colSize: 4,
        props: {
          readOnly: true,
        },
      },
    ],
  },
];
