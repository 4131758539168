//@ts-check
import React, { Component } from "react";
import {
  Header,
  Container,
  Button,
  Placeholder,
  Segment,
  List,
  Label,
  Divider,
  Grid,
  Message,
  Icon,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { isEmpty, times, camelCase } from "lodash";
import { bindActionCreators } from "redux";

import Form from "../../../components/Form";
import { DAlert } from "../../../components/Dalert";
import Table from "../../../components/DTable";

import {
  selectModalityForm,
  modalityForm,
  totalInsert,
  paymentStatus,
  teamNameChange,
  inscriptionDoneHeader,
  changeDni,
  changeDiscount,
} from "./createInscriptionForm";
import { checkNIF, getObjectByStart } from "../../../util/util";
import raceApi from "../../../apis/races";
import clubApi from "../../../apis/clubs";
import provincesApi from "../../../apis/regions";
import discountApi from "../../../apis/discounts";
import modalityApi from "../../../apis/modalities";
import inscriptionsApi from "../../../apis/inscriptions";
import FluidResponsiveButton from "../../../components/FluidResponsiveButton";
import es from "../../../locales/default.es";
import { getCountries } from "../../../reducers/countries";

@connect(
  (state) => ({
    countries: state.countries.countries,
  }),
  (dispatch) => ({
    actions: bindActionCreators({ getCountries }, dispatch),
  })
)
@withTranslation()
export default class CreateInscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      raceSlug: null,
      raceModalities: [],
      clubs: [],
      provinces: [],
      countries: [],
      modalitySlugSelected: null,
      isLoading: false,
      modality: {},
      actualInscription: {
        "inscription.selected": null,
      },
      showValidation: false,
      inscriptions: [],
      extraPrice: 0,
      isTeamInscription: false,
      teamName: null,
      teamMembers: 0,
      customPrice: null,
      resetForm: false,
      discountSelected: null,
      paymentStatus: "PENDING",
      inscriptionDone: false,
      inscriptionError: null,
      inscriptionResponse: null,
      athletesWithBadDiscountsList: [],
      athletesWithBadDiscounts: [],
    };
  }

  componentDidUpdate(_, prevState) {
    const { modalitySlugSelected, modality, inscriptions } = this.state;

    if (
      modalitySlugSelected &&
      modalitySlugSelected !== prevState.modalitySlugSelected
    ) {
      this.setState({ isLoading: true });
      this.getModalityInscription();
    }

    if (
      !modalitySlugSelected &&
      modalitySlugSelected !== prevState.modalitySlugSelected
    ) {
      this.setState({ modality: {} });
    }

    const extra = this.calculateExtraPrices();

    if (extra !== prevState.extraPrice) {
      this.setState({ extraPrice: extra });
    }
  }

  async componentDidMount() {
    const { match, actions, countries } = this.props;
    const {
      params: { id, modalityId },
    } = match;
    const { slug } = await raceApi.fetchRace(id);
    const race = await raceApi.fetchModalitiesStatus(slug);
    const raceModalities = race.race.modalities;

    this.setState({ raceSlug: slug });

    if (!modalityId) {
      this.setState({ raceModalities });
    } else {
      const inscriptionStatus = await modalityApi.getModalityInscriptionStatus(
        modalityId
      );
      if (inscriptionStatus === "ENABLED" || inscriptionStatus === "CLOSED_ENABLED") {
        const { slug } = await modalityApi.fetchModality(modalityId);

        this.setState({ modalitySlugSelected: slug }, () => {
          this.getModalityInscription();
        });
      } else {
        DAlert.error({
          message: t("inscriptionsScreen.detail.inscriptionsNotEnable"),
        });
      }
    }

    clubApi.get().then((clubs) => {
      this.setState({ clubs: clubs.map((club) => ({ title: club.name })) });
    });

    provincesApi.fetchRegions().then((provinces) => {
      this.setState({
        provinces: provinces.map((provinces) => ({ title: provinces.name })),
      });
    });

    actions.getCountries(countries);
  }

  async getModalityInscription() {
    const { modalitySlugSelected } = this.state;

    const modality = await modalityApi.fetchInscriptionInfo(
      modalitySlugSelected
    );

    this.setState({ modality, isLoading: false });
  }

  async bookDiscount(discountCode) {
    const { t } = this.props;

    const { raceSlug } = this.state;
    const discount = await discountApi.validateDiscount(discountCode, raceSlug);

    if (discount.code && discount.code === 404) {
      DAlert.error({ message: t("inscriptionsScreen.detail.discountNoValid") });
      return;
    }

    this.setState({
      discountSelected: { ...discount, discountByCode: discountCode },
    });

    return discount;
  }

  validateInscription() {
    const { actualInscription, modality } = this.state;

    const {
      "athlete.birthday": birthday,
      "athlete.parentName": parentName,
      "athlete.parentSurnames": parentSurnames,
      "athlete.parentDni": parentDni,
      "athlete.photo": photo,
      ISFORMVALID,
    } = actualInscription;

    const { additionalFields, inscriptions } = modality;
    const {
      parentalLeave: parentalLeaveField,
      photo: photoField,
    } = additionalFields;
    let valid = ISFORMVALID;

    // if (parentalLeaveField) {
    //   const today = new Date();
    //   const especialDate = new Date(inscriptions.dateCheckAge);
    //   let eighteenYearsAgo = new Date(
    //     new Date(today).setFullYear(today.getFullYear() - 18)
    //   );

    //   if (especialDate) {
    //     eighteenYearsAgo = new Date(
    //       new Date(especialDate).setFullYear(especialDate.getFullYear() - 18)
    //     );
    //   }

    //   if (new Date(birthday) > eighteenYearsAgo) {
    //     if (!parentName || !parentSurnames || !parentDni) {
    //       return false;
    //     } else {
    //       valid = true;
    //     }
    //   }
    // }

    if (photoField) {
      if (!photo) {
        return false;
      } else {
        valid = true;
      }
    }

    if (!valid && !ISFORMVALID) {
      return false;
    }

    return valid;
  }

  addInscription(clb, add = false) {
    //add param is true when comes from addAndCreateInscription()
    const { t } = this.props;
    const {
      actualInscription,
      inscriptions,
      modalitySlugSelected,
      teamMembers,
      customPrice,
      discountSelected,
      paymentStatus,
      modality,
    } = this.state;

    const teamSize = actualInscription.teamSize;
    const actualInscriptions = inscriptions.length + 1;

    //dont check teamSize if comes from addAndCreateInscription()
    if (!add && teamSize && teamSize === actualInscriptions) {
      DAlert.error({
        message: t("inscriptionsScreen.detail.alerts.cantAddMore", {
          teamSize,
          actualInscriptions,
        }),
      });
      return;
    }

    if (!this.validateInscription()) {
      this.setState({ showValidation: true });
      return;
    }

    const athleteObject = getObjectByStart("athlete", actualInscription);

    const inscriptionObject = getObjectByStart(
      "inscription",
      actualInscription
    );
    const attributesObject = getObjectByStart("attrs", inscriptionObject);

    const parsedInscriptionObject = Object.keys(inscriptionObject).reduce(
      (acc, key) => ({
        ...acc,
        [key]: inscriptionObject[key] === "" ? null : inscriptionObject[key],
      }),
      {}
    );

    const isTeamInscription =
      inscriptionObject.teamName && inscriptionObject.teamId;
    const calculatedPrice = this.calculatePrice();
    const inscription = {
      athlete: {
        ...Object.entries(athleteObject).reduce(
          (acc, [key, value]) =>
            value === "" ? { ...acc, [key]: null } : { ...acc, [key]: value },
          {}
        ),
      },
      inscription: {
        ...parsedInscriptionObject,
        priceId: +actualInscription.priceId,
        priceAmount:
          !customPrice && customPrice !== 0
            ? calculatedPrice.priceAmount
            : typeof customPrice === "string"
            ? parseFloat(customPrice.replace(",", "."))
            : customPrice === "0"
            ? parseInt(customPrice)
            : customPrice,
        modalitySlug: modalitySlugSelected,
        attributes: Object.keys(attributesObject).map((attrId) => ({
          id: +attrId,
          value: `${
            attributesObject[attrId] ? attributesObject[attrId] : null
          }`,
        })),
        discountId: discountSelected ? discountSelected.id : undefined,
        teamId: isTeamInscription
          ? inscriptions.length > 0
            ? inscriptions[0]?.inscription?.teamId
            : parsedInscriptionObject.teamId
          : null,
        paymentStatus,
      },
    };

    // Validate a unique DNI
    const athleteDNI = inscriptions.find(({ athlete }) => athlete.dni === athleteObject.dni);

    if (athleteDNI) {
      DAlert.error({
        message: `${t("inscriptionsScreen.detail.dni")} ${t(
          "inscriptionsScreen.detail.inscriptionErrors.TAX_IDS_DUPLICATED"
        )}`,
      });
      return;
    }

    if (isTeamInscription) {
      const team = modality.ModalityTeamTypes.find(
        (teamType) => teamType.id == actualInscription["inscription.teamId"]
      );

      //check gender type
      if (
        team &&
        team.gender != "BOTH" &&
        actualInscription["athlete.gender"] != team.gender
      ) {
        DAlert.error({
          message: `${t("inscriptionsScreen.detail.teamGender")} ${t(
            `commons.gender.${team.gender}`
          )}`,
        });
        return;
      }

      if (inscriptions.length === 0 && 1 > actualInscription.teamSize) {
        DAlert.error({
          message: t("inscriptionsScreen.detail.teamSizeMax"),
        });
        return;
      } else if (
        inscriptions.length !== 0 &&
        inscriptions.length + 1 > teamMembers
      ) {
        DAlert.error({
          message: t("inscriptionsScreen.detail.teamSizeMax"),
        });
        return;
      }
    }

    this.setState(
      {
        inscriptions: [...inscriptions, { ...inscription }],
        actualInscription: {},
        discountSelected: null,
        extraPrice: 0,
        showValidation: false,
        resetForm: true,
        isTeamInscription,
        teamName: isTeamInscription ? inscriptionObject.teamName : null,
        teamMembers: isTeamInscription ? actualInscription.teamSize : 0,
      },
      () => {
        if (clb) {
          clb();
        }
        this.setState({ resetForm: false });
      }
    );
  }

  addAndCreateInscription() {
    // if (!this.validateInscription()) {
    //   return;
    // }

    //check number of inscriptions
    const { t } = this.props;
    const teamSize = this.state.actualInscription.teamSize;
    const actualInscriptions = this.state.inscriptions.length + 1;

    if (teamSize && teamSize !== actualInscriptions) {
      DAlert.error({
        message: t(
          "inscriptionsScreen.detail.alerts.teamMembersLengthDontMatch",
          { teamSize, actualInscriptions }
        ),
      });
      return;
    }

    this.setState({ showValidation: true });
    if (!this.state.actualInscription.ISFORMVALID) {
      return;
    }

    this.addInscription(() => {
      this.createInscriptions();
    }, true); //send true so it will not check team size in addInscription()
  }

  calculateExtraPrices() {
    const { actualInscription, modality } = this.state;

    const inscriptionObject = getObjectByStart(
      "inscription",
      actualInscription
    );
    const attributesObject = getObjectByStart("attrs", inscriptionObject);
    const parsedAttributes = Object.keys(attributesObject).map((attrId) => ({
      id: +attrId,
      value: `${attributesObject[attrId]}`,
    }));

    if (!isEmpty(modality)) {
      return parsedAttributes.reduce((acc, attr) => {
        const attribute = modality.attributes.find(
          (modalityAttr) => modalityAttr.id === attr.id
        );

        if (attribute) {
          if (attribute.type === "PRODUCT") {
            const optionSelected = attribute.ModalityAttributesOptions.find(
              (option) => option.id === +attr.value
            );

            if (optionSelected) {
              return acc + optionSelected.price;
            }
          }

          if (attribute.type === "BOOLEAN") {
            if (attr.value === "true") {
              return acc + attribute.price;
            }
          }
        }

        return acc;
      }, 0);
    } else {
      return 0;
    }
  }

  onFormChange(actualInscription) {
    const {
      modality: { prices },
    } = this.state;

    if (prices.length == 1) {
      this.setState({
        actualInscription: {
          ...actualInscription,
          price: `${prices[0].name} (${prices[0].amount}€)`,
          priceId: prices[0].id,
          "inscription.club": actualInscription.club
            ? actualInscription.club
            : null,
        },
      });
      return;
    }
    delete actualInscription["club"];
    const priceId = parseInt(actualInscription["inscription.price"])
    this.setState({
       actualInscription: {
         ...actualInscription,
         priceId: priceId}
        });
    }

  async createInscriptions() {
    const { inscriptions } = this.state;

    this.setState({ isLoading: true });

    const response = await inscriptionsApi.createInscriptions(inscriptions);

    this.setState({
      isLoading: false,
      inscriptionDone: true,
      inscriptionError:
        typeof response.ok !== "undefined" && !response.ok
          ? response.error
          : null,
      inscriptionResponse:
        typeof response.ok !== "undefined" && !response.ok ? null : response,
      athletesWithBadDiscounts:
        typeof response.ok !== "undefined" &&
        !response.ok &&
        response.error.description[0]
          ? response.error.description
          : null,
    });
  }

  handlePrices = () => {
    const { modality, inscriptions } = this.state;

    const prices = modality.prices
      .map((price) => {
        const totalAthletesWithPrice = inscriptions.filter(
          ({ inscription }) => {
            if (isNaN(inscription.priceId)) {
              return parseInt(inscription.price) === parseInt(price.id);
            }

            return parseInt(inscription.priceId) === parseInt(price.id);
          }
        );

        if (price.maxAthletes === null) {
          return price;
        } else if (totalAthletesWithPrice.length - price.maxAthletes >= 0) {
          return null;
        }

        return price;
      })
      .filter(Boolean);

    this.setState({
      modality: {
        ...this.state.modality,
        prices,
      },
    });
  };

  calculatePrice() {
    const {
      modality,
      actualInscription,
      extraPrice,
      discountSelected,
      inscriptions,
    } = this.state;

    const defaultPrice = { name: "default", amount: modality.default_price };

    let priceSelected = modality?.prices
      ? modality?.prices.length > 1
        ? modality?.prices?.find(({ id }) =>
            typeof actualInscription["inscription.price"] !== "undefined"
              ? actualInscription["inscription.price"] !== ""
                ? id === JSON.parse(actualInscription["inscription.price"])
                : undefined
              : undefined
          )
        : modality?.prices[0]
      : defaultPrice;

    const priceAmount = priceSelected
      ? priceSelected?.amount || 0
      : ''//defaultPrice.amount;

    const subTotal = priceAmount + extraPrice;
    const discountApplied =
      discountSelected && subTotal
        ? !discountSelected.type || discountSelected.type === "PCT"
          ? -(subTotal * (discountSelected.value / 100))
          : -discountSelected.value
        : 0;

    return {
      priceAmount,
      extraPrice,
      subTotal,
      discountApplied,
      total: subTotal + discountApplied,
    };
  }

  calculatePriceForInscriptionIndex(index) {
    if (index == null) {
      return 0;
    }

    const { inscriptions, extraPrice, modality } = this.state;

    const attributesIndex = inscriptions[index].inscription.attributes.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr.value }),
      {}
    );

    const attributesTotal = modality?.attributes?.reduce((acc, attr) => {
      if (attr.id in attributesIndex) {
        if (attr.type == "PRODUCT" || attr.type == "PRODUCT") {
          const optionSelected = attr.ModalityAttributesOptions.find(
            (option) => option.id === +attributesIndex[attr.id]
          );

          if (optionSelected) {
            return acc + optionSelected.price;
          }
        }

        if (attr.type == "BOOLEAN") {
          const price = attributesIndex[attr.id] == "true" ? attr.price : 0;

          return acc + price;
        }
      }

      return acc;
    }, 0);

    const subTotal =
      inscriptions[index].inscription.priceAmount + attributesTotal;

    let discount = 0;

    const discountData = inscriptions[index].inscription.discount;

    if (discountData) {
      discount = discountData.value;
      if (discountData.type === "PCT") {
        discount = parseFloat((subTotal * (discount / 100)).toFixed(2));
      }
    }

    return (
      inscriptions[index].inscription.priceAmount + attributesTotal - discount
    );
  }

  render() {
    const {
      t,
      history,
      match: {
        params: { id, modalityId },
      },
      countries,
    } = this.props;
    const originRegex = /(\/race\/\d+\/inscription\/create)/g;
    const {
      raceModalities,
      modality,
      isLoading,
      actualInscription,
      inscriptions,
      showValidation,
      resetForm,
      isTeamInscription,
      teamName,
      teamMembers,
      clubs,
      provinces,
      discountSelected,
      inscriptionDone,
      inscriptionError,
      inscriptionResponse,
      athletesWithBadDiscounts,
    } = this.state;

    const {
      priceAmount,
      extraPrice,
      discountApplied,
      total,
    } = this.calculatePrice();
    const price = total > 0 ? total.toFixed(2) : "0.00";

    const areAdditionalFields = modality.additionalFields
      ? Object.values(modality?.additionalFields)
          .filter((v) => typeof v !== "string")
          .some((v) => v)
      : null;

    const teamInformation = {
      teamMembers,
      teamName,
      teamSelected: inscriptions[0]?.inscription?.teamId,
    };

    const res = raceModalities.some(
      (modality) => modality.inscriptionsState == "ENABLED"
    );

    return (
      <Container>
        <Header
          textAlign="center"
          as="h2"
          style={{ marginTop: 15, marginBottom: 15 }}
        >
          {t("inscriptionsScreen.inscriptionButton")}
        </Header>
        {raceModalities.length && res ? (
          <Form
            fields={selectModalityForm(t, raceModalities, modality, modalityId)}
            onFormChange={({ modality }) => {
              if (!modalityId)
                this.setState({ modalitySlugSelected: modality });
            }}
          />
        ) : history.location.pathname.match(originRegex) ? (
          <Message warning attached="bottom">
            <Icon name="warning" />
            {t("inscriptionsScreen.inscriptionsNotEnable")}
          </Message>
        ) : null}
        {isLoading && (
          <Placeholder fluid>
            {times(19, (i) => (
              <Placeholder.Line key={i} />
            ))}
          </Placeholder>
        )}
        {!resetForm && !isEmpty(modality) && !isLoading && !inscriptionDone && (
          <React.Fragment>
            <Form
              showValidation={showValidation}
              fields={modalityForm(
                t,
                modality,
                showValidation,
                isTeamInscription,
                teamInformation,
                clubs,
                provinces,
                countries,
                this.bookDiscount.bind(this),
                discountSelected
              )}
              defaultState={actualInscription}
              onFormChange={(data) => {
                this.onFormChange({
                  ...data,
                  "athlete.region":data["athlete.country"]==='ES'
                    ?provinces.some(p =>p.title=== data["athlete.region"])
                      ?data["athlete.region"]
                      : null
                    : data["athlete.region"],
                  "athlete.dni": data["athlete.dni"]
                    ? data["athlete.dni"].toUpperCase()
                    : null,
                  "athlete.parentDni": data["athlete.parentDni"]
                    ? data["athlete.parentDni"].toUpperCase()
                    : null,
                  "inscription.teamId": teamInformation["teamSelected"]
                    ? teamInformation["teamSelected"]
                    : data["inscription.teamId"],
                  "inscription.teamName": teamInformation["teamName"]
                    ? teamInformation["teamName"]
                    : data["inscription.teamName"],
                  teamSize: teamInformation["teamMembers"]
                    ? teamInformation["teamMembers"]
                    : data["teamSize"],
                });
              }}
            />
            <Segment>
              <Segment basic>
                <Grid columns={2} relaxed="very" stackable>
                  <Grid.Column>
                    <List divided verticalAlign="middle" relaxed>
                      <List.Item>
                        <List.Content floated="right">
                          {priceAmount ? `${priceAmount.toFixed(2)}€` : ``}
                        </List.Content>
                        <List.Content floated="left" verticalAlign="middle">
                          <Header
                            sub
                            content={t("inscriptionsScreen.detail.basePrice")}
                          />
                        </List.Content>
                      </List.Item>
                      {areAdditionalFields && (
                        <List.Item>
                          <List.Content floated="right">
                            {`${extraPrice.toFixed(2)}€`}
                          </List.Content>
                          <List.Content floated="left" verticalAlign="middle">
                            <Header sub content={"Extra"} />
                          </List.Content>
                        </List.Item>
                      )}
                      {discountSelected && (
                        <List.Item>
                          <List.Content floated="right">
                            {`${discountApplied.toFixed(2)}€`}
                          </List.Content>
                          <List.Content floated="left" verticalAlign="middle">
                            <Header sub>
                              {t("inscriptionsScreen.detail.discount")}
                            </Header>
                          </List.Content>
                        </List.Item>
                      )}
                    </List>
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle">
                    <List>
                      <List.Item>
                        <List.Content floated="right">
                          <Label size="large" color="blue">{`${price}€`}</Label>
                        </List.Content>
                        <List.Content floated="left" verticalAlign="middle">
                          <Header as="h3">Total</Header>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content>
                          <Form
                            fields={paymentStatus(t)}
                            onFormChange={(data) =>
                              this.setState({
                                paymentStatus: data?.paymentStatus,
                              })
                            }
                          />
                        </List.Content>
                      </List.Item>
                    </List>
                  </Grid.Column>
                </Grid>
                {window.innerWidth > 768 && (
                  <Divider vertical content="~" />
                )}
              </Segment>
              <Divider horizontal>{t("commons.or")}</Divider>
              <Form
                fields={totalInsert(t, price)}
                onFormChange={(data) =>
                  this.setState({ customPrice: data?.total })
                }
              />
            </Segment>
          </React.Fragment>
        )}

        {inscriptionDone && inscriptionError && !isLoading && (
          <Message negative>
            <Message.Header>
              {t("inscriptionsScreen.detail.inscriptionError")}
            </Message.Header>
            <Message.Content>
              {inscriptionError.message in
              es.inscriptionsScreen.detail.inscriptionErrors
                ? t(
                    `inscriptionsScreen.detail.inscriptionErrors.${inscriptionError.message}`
                  )
                : t("commons.serverErrorMessage")}
              <Segment basic padded="very">
                {inscriptionError.message === "TAX_IDS_DUPLICATED" && (
                  <List divided verticalAlign="middle">
                    {inscriptions.map(({ athlete }, key) => {
                      if (
                        !!JSON.parse(inscriptionError.description[0].dnis).some(
                          (dni) => dni === athlete.dni
                        )
                      ) {
                        return (
                          <List.Item key={key}>
                            <List.Content
                              floated="right"
                              verticalAlign="middle"
                              style={{ width: "30%" }}
                            >
                              <Form
                                showValidation={showValidation}
                                fields={changeDni(t)}
                                onFormChange={(data) => {
                                  if (data.dni !== "") {
                                    this.setState({
                                      inscriptions: [
                                        ...inscriptions.slice(0, key),
                                        {
                                          ...inscriptions[key],
                                          athlete: {
                                            ...inscriptions[key].athlete,
                                            dni: data.dni.toUpperCase(),
                                          },
                                        },
                                        ...inscriptions.slice(key + 1),
                                      ],
                                    });
                                  }
                                }}
                              />
                            </List.Content>
                            <List.Content
                              floated="left"
                              verticalAlign="middle"
                              style={{ padding: "1em" }}
                            >
                              {`${athlete.name} ${athlete.surnames}`}
                            </List.Content>
                          </List.Item>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </List>
                )}
                {inscriptionError.message === "TEAM_NAME_DUPLICATED" && (
                  <Form
                    fields={teamNameChange(t)}
                    onFormChange={(data) =>
                      this.setState({
                        inscriptions: inscriptions.map((inscription) => ({
                          ...inscription,
                          inscription: {
                            ...inscription.inscription,
                            teamName: data.teamName,
                          },
                        })),
                      })
                    }
                  />
                )}
                {inscriptionError.message === "DISCOUNT_NOT_AVAILABLE" && (
                  <List divided verticalAlign="middle">
                    {athletesWithBadDiscounts.map(({ athlete, index }, key) => (
                      <List.Item key={key}>
                        <List.Content
                          floated="right"
                          verticalAlign="middle"
                          style={{ width: "30%" }}
                        >
                          <Form
                            showValidation={showValidation}
                            fields={changeDiscount(
                              t,
                              this.bookDiscount.bind(this)
                            )}
                            onFormChange={(data) => {
                              this.setState({
                                inscriptions: [
                                  ...inscriptions.slice(0, index),
                                  {
                                    inscription: {
                                      ...inscriptions[key].inscription,
                                      discountId: data.discount.id,
                                      discount: data.discount,
                                    },
                                    athlete: {
                                      ...inscriptions[key].athlete,
                                    },
                                  },
                                  ...inscriptions.slice(index + 1),
                                ],
                              });
                            }}
                          />
                        </List.Content>
                        <List.Content
                          floated="left"
                          verticalAlign="middle"
                          style={{ padding: "1em" }}
                        >
                          {`${athlete} - ${this.calculatePriceForInscriptionIndex(
                            index
                          )} €`}
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                )}

                <Button
                  color="vk"
                  onClick={() => {
                    if (
                      inscriptions.every(({ athlete }) =>
                        checkNIF(athlete.dni, ["DNI", "NIE"])
                      )
                    ) {
                      this.createInscriptions();
                    } else {
                      this.setState({ showValidation: true });
                    }
                  }}
                >
                  {t("inscriptionsScreen.detail.inscribe", {
                    number: inscriptions.length,
                  })}
                </Button>
              </Segment>
            </Message.Content>
          </Message>
        )}
        {inscriptionDone && !inscriptionError && (
          <React.Fragment>
            <Table
              headers={inscriptionDoneHeader(t)}
              contentData={inscriptionResponse}
              showNumberOfRecords={false}
              showPagination={false}
              showTotalItems={false}
              showItemsPerPage={false}
            />
            <FluidResponsiveButton
              onClick={async () =>
                await history.push(`/race/${id}#inscriptions`)
              }
              size="small"
              primary
            >
              {t("commons.ok")}
            </FluidResponsiveButton>
          </React.Fragment>
        )}

        <Segment basic>
          {!inscriptionDone && raceModalities.length && res ? (
            <Button
              color="google plus"
              onClick={() => history.goBack()}
              style={{ marginBottom: 10 }}
            >
              {t("commons.cancel")}
            </Button>
          ) : null}
          {!isEmpty(modality) &&
            !isEmpty(actualInscription) &&
            !isLoading &&
            !inscriptionDone && (
              <React.Fragment>
                <Button
                  color="vk"
                  style={{ marginBottom: 10 }}
                  onClick={async () => {
                    this.addInscription(() => {
                      if (this.state.modality.prices && inscriptions) {
                        this.handlePrices();
                      }
                    });
                  }}
                >
                  {t("inscriptionsScreen.detail.addInscription")}
                </Button>
                <Button
                  color="vk"
                  onClick={() => this.addAndCreateInscription()}
                >
                  {t("inscriptionsScreen.detail.inscribe", {
                    number: inscriptions.length + 1,
                  })}
                </Button>
              </React.Fragment>
            )}
        </Segment >
      </Container>
    );
  }
}
