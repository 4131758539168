import moment from "moment";

export const discountFields = (t, discount) => {
  
  const wasUsed = !!discount.inscription_id;
  
  const { canEdit } = false;// discount;

  const usedFields = [
    {
      div: "row",
      fields: [
        {
          name: "usedBy",
          title: t("raceDiscounts.detail.usedByName"),
          placeHolder: t("commons.none"),
          type: "Input",
          colSize: 7,
          deflt: discount.usedBy,
          props: {
            readOnly: true,
            value: discount.usedBy
          }
        },
        {
          name: "usedByEmail",
          title: t("raceDiscounts.detail.usedByEmail"),
          placeHolder: t("commons.none"),
          type: "Input",
          colSize: 7,
          props: {
            readOnly: true,
            value: discount.usedByEmail
          }
        },
        {
          name: "usedOn",
          title: t("raceDiscounts.detail.usedOn"),
          placeHolder: t("commons.none"),
          type: "DatePicker",
          colSize: 2,
          props: {
            id: '0',
            clearable: false,
            readOnly: true,
            value: moment(discount.usedOn).format("L")
          }
        }
      ]
    }
  ];

  const fields = [
    {
      div: "row",
      fields: [
        {
          name: "createdAt",
          title: t("raceDiscounts.table.createdAt"),
          placeHolder: t("commons.none"),
          type: "DatePicker",
          colSize: 4,
          props: {
            id: '1',
            clearable: false,
            readOnly: true
          }
        },
        {
          name: "description",
          title: t("raceDiscounts.table.description"),
          placeHolder: t("commons.none"),
          type: "Input",
          colSize: 5,
          props: {
            readOnly: !canEdit
          }
        },
        {
          name: "discountCode",
          title: t("raceDiscounts.table.discountCode"),
          placeHolder: t("commons.none"),
          type: "Input",
          colSize: 5,
          props: {
            readOnly: !canEdit
          }
        },
        {
          name: "value",
          title: t("raceDiscounts.table.value"),
          placeHolder: t("commons.none"),
          type: "Input",
          colSize: 2,
          props: {
            readOnly: true
          }
        }
      ]
    },
    {
      div: "row",
      fields: [
        {
          name: "race",
          title: t("title.race"),
          placeHolder: t("commons.none"),
          type: "Input",
          colSize: 10,
          props: {
            readOnly: true
          }
        },
        {
          name: "limitDate",
          title: t("raceDiscounts.table.limitDate"),
          placeHolder: t("commons.none"),
          type: "DatePicker",
          colSize: 4,
          props: {
            id: '2',
            clearable: false,
            readOnly: true
          }
        },
        {
          name: "used",
          title: t("raceDiscounts.table.used"),
          placeHolder: t("commons.none"),
          type: "Checkbox",
          colSize: 2,
          props: {
            readOnly: true
          }
        }
      ]
    }
  ];

  return wasUsed ? [...fields, ...usedFields] : [...fields];
};
