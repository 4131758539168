export const getTableHeaders = (t, role) => {
  if (role === 'COMPANY') {
    return [
      {
        content: t('usersScreen.table.date'),
        accessor: 'createdAt'
      },
      {
        content: t('usersScreen.table.username'),
        accessor: 'name'
      },
      {
        content: t('usersScreen.table.email'),
        accessor: 'email'
      },
      {
        content: t('usersScreen.table.phone'),
        accessor: 'phone'
      },
      {
        content: t('usersScreen.table.status'),
        accessor: 'status'
      }
    ];
  } else {
    return [
      {
        content: t('usersScreen.table.date'),
        accessor: 'createdAt'
      },
      {
        content: t('usersScreen.table.username'),
        accessor: 'name'
      },
      {
        content: t('usersScreen.table.email'),
        accessor: 'email'
      },
      {
        content: t('usersScreen.table.phone'),
        accessor: 'phone'
      },
      // {
      //   content: t("usersScreen.table.web"),
      //   accessor: "web"
      // },
      {
        content: t('usersScreen.table.role'),
        accessor: 'role'
      },
      {
        content: t('usersScreen.table.status'),
        accessor: 'status'
      }
    ];
  }
};

export const getFilterFields = (t, roles, status, role) => {
  function removeItemFromArr(arr, item) {
    return arr.filter(function (e) {
      return e.value !== item;
    });
  }

  status = removeItemFromArr(status, 'DELETED');

  if (role === 'COMPANY') {
    return [
      {
        div: 'row',
        fields: [
          {
            name: 'name',
            placeHolder: t('usersScreen.table.username'),
            type: 'Input'
          },
          {
            name: 'email',
            placeHolder: t('usersScreen.table.email'),
            type: 'Input'
          },
          {
            name: 'status',
            placeHolder: t('usersScreen.table.status'),
            type: 'Select',
            data: status
          }
        ]
      }
    ];
  } else {
    return [
      {
        div: 'row',
        fields: [
          {
            name: 'name',
            placeHolder: t('usersScreen.table.username'),
            type: 'Input',
            props: {
              id: 'UsersFilterName'
            }
          },
          {
            name: 'email',
            placeHolder: t('usersScreen.table.email'),
            type: 'Input',
            props: {
              id: 'UsersFilterEmail'
            }
          },
          {
            name: 'role',
            placeHolder: t('usersScreen.table.role'),
            type: 'Select',
            data: roles,
            props: {
              id: 'UsersFilterRole'
            }
          },
          {
            name: 'status',
            placeHolder: t('usersScreen.table.status'),
            type: 'Select',
            data: status,
            props: {
              id: 'UsersFilterStatus'
            }
          }
        ]
      }
    ];
  }
};

export const getFilterFieldsTwoRows = (t, roles, status) => [
  {
    div: 'row',
    fields: [
      {
        name: 'name',
        placeHolder: t('usersScreen.table.username'),
        type: 'Input'
      },
      {
        name: 'email',
        placeHolder: t('usersScreen.table.email'),
        type: 'Input'
      }
    ]
  },
  {
    div: 'row',
    fields: [
      {
        name: 'role',
        placeHolder: t('usersScreen.table.role'),
        type: 'Select',
        data: removeItemFromArr(roles, 'DELETED')
      },
      {
        name: 'status',
        placeHolder: t('usersScreen.table.status'),
        type: 'Select',
        data: status
      }
    ]
  }
];

export const createUserForm = (t, roles, isCompanyUser, companies, rol) => {
  const rolesWithOutClient = roles.reduce((acc, role) => {

    if (role.text != 'ATHLETE') {
      return [...acc, { text: t(`roles.${role.text}`), value: role.value }];
    }

    return acc;
  }, []);

  const defaultFields = [
    {
      fields: [
        {
          name: 'email',
          placeHolder: t('usersScreen.modal.email'),
          type: 'Input',
          colSize: 16,
          validation: {
            required: true,
            regexType: 'email',
            errorMessage: t('commons.INVALID_EMAIL')
          },
          props: {
            id: 'CreateUserEmail',
            icon: 'at',
            iconPosition: 'left'
          }
        }
      ]
    },
    {
      fields: [
        {
          name: 'role',
          placeHolder: t('usersScreen.modal.role'),
          type: 'Select',
          data: rolesWithOutClient,
          colSize: 16,
          validation: {
            required: true
          },
          props: {
            id: 'CreateUserRole'
          }
        }
      ]
    }
  ];

  const companyField = [
    {
      fields: [
        {
          name: 'company',
          placeHolder: t('usersScreen.modal.company'),
          type: 'Select',
          data: companies,
          colSize: 16
        }
      ]
    }
  ];

  const defaultFieldsCompany = [
    {
      fields: [
        {
          name: 'email',
          placeHolder: t('usersScreen.modal.email'),
          type: 'Input',
          colSize: 16,
          validation: {
            required: true,
            regexType: 'email',
            errorMessage: t('commons.INVALID_EMAIL')
          },
          props: {
            icon: 'at',
            iconPosition: 'left'
          }
        }
      ]
    }
  ];
  if (rol == 'COMPANY') {
    return defaultFieldsCompany;
  } else {
    return !isCompanyUser
      ? [...defaultFields]
      : [...defaultFields, ...companyField];
  }
};
