import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Modal, Grid, Message, Button } from "semantic-ui-react";

import categoriesApi from "../../../apis/categories";
import Form from "../../../components/Form";
import { formFields, validateAges } from "./util";
import { modalHandler } from "../../../components/DModal";
import FluidResponsiveButton from "../../../components/FluidResponsiveButton";

const CreateCategoryButton = props => {
  const [categoryForm, setCategoryForm] = useState({});
  const [showValidation, setShowValidation] = useState(false);
  const [ageRequired, setAgeRequired] = useState(false);
  const [error, setError] = useState();
  const [t] = useTranslation();

  const formHandler = formData => {
    setError(undefined);

    const { ageType, minimumAge, maximumAge, ageCheckDate } = formData;
    let ageRequired = false;

    if (!!ageType || !!minimumAge || !!maximumAge || !!ageCheckDate) {
      ageRequired = true;
    }

    setAgeRequired(ageRequired);
    setCategoryForm(formData);
  };

  const createCategoryHandler = async () => {
    if (!categoryForm.ISFORMVALID) {
      setShowValidation(true);
      return;
    }
    setShowValidation(false)
    
    const formToSend = {
      modalityId: +props.modalityId,
      ...categoryForm,
      // maxAthletes: categoryForm.maxAthletes ? +categoryForm.maxAthletes : null,
      minimumAge:
        categoryForm.minimumAge === "" ? null : +categoryForm.minimumAge,
      maximumAge:
        categoryForm.maximumAge === "" ? null : +categoryForm.maximumAge,
      ageCheckDate:
        categoryForm.ageCheckDate === "" ? null : categoryForm.ageCheckDate
    };
    
    await categoriesApi.createCategory(formToSend).then(async response => {
      if (response.ok !== undefined && !response.ok) {
        setError(response.error.message);
        return;
      } else {
        modalHandler.close();
        await props.getData();
      }
    });
  };

  useEffect(() => {
    modalHandler.refreshOptions({
      ...modalOptions(categoryForm, ageRequired)
    });
  }, [error, categoryForm, showValidation, ageRequired]);
  
  const modalOptions = (data = {}, ageRequired = false) => {
    const agesValidation = validateAges(data, true);
    return {
      header: t("categoriesScreen.new"),
      content: (
        <Modal.Description>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width="16">
                <Form
                  defaultState={data}
                  showValidation={showValidation}
                  fields={formFields(t, agesValidation)}
                  onFormChange={formHandler}
                />
              </Grid.Column>
            </Grid.Row>
            {error && (
              <Grid.Row>
                <Grid.Column width="16">
                  <Message
                    error
                    header={t(`usersScreen.modal.messages.${error}.header`)}
                    content={t(`usersScreen.modal.messages.${error}.message`)}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Modal.Description>
      ),
      actions: (
        <Grid>
          <Grid.Column width="16">
            <Button fluid color="vk" onClick={
              createCategoryHandler}>
              {t("categoriesScreen.new")}
            </Button>
          </Grid.Column>
        </Grid>
      )
    };
  };

  return (
    <FluidResponsiveButton
      icon
      labelPosition="left"
      size="small"
      primary
      onClick={async () => {
        await setShowValidation(false)
        modalHandler.open(modalOptions())}}
    >
      <Icon name="add" />
      {t("commons.new")}
    </FluidResponsiveButton>
  );
};

export default CreateCategoryButton;
