import React from "react";
import { Segment, List, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const PriceDetail = props => {
  const {
    inscriptionPrice,
    inscriptionDiscount,
    inscriptionAttributes,
    inscriptionExtra,
    total,
    commision,
    activeInscription,
    totalRefund,
    defaultPrice
  } = props;
  const [t] = useTranslation();
  let selectedPrice = `0€`;

  const safeInscriptionPrice = inscriptionPrice || { amount: defaultPrice };

  if (safeInscriptionPrice) {
    selectedPrice = `${
      safeInscriptionPrice?.amount ? safeInscriptionPrice?.amount.toFixed(2) : 0
      }€`;
  } else if (activeInscription.inscription) {
    if (activeInscription.inscription.modality.Prices.length > 0) {
      selectedPrice = `${activeInscription.inscription.modality.Prices[0].amount.toFixed(
        2
      )}€`;
    }
  }

  let inscriptionAttributesAmount = 0;
  inscriptionAttributes?.map(({ value, modalityAttributeId }, index) => {
    const {
      type,
      price,
      ModalityAttributesOptions: attributesOptions
    } = inscriptionExtra.find(
      attribute => attribute.id === modalityAttributeId
    );

    let valueToShow = null;
    let priceToShow = 0;
    if (type === "BOOLEAN") {
      const selectedValue = JSON.parse(value);

      if (selectedValue) {
        if (price) {
          priceToShow = price;
        }
      } else {
        return null;
      }
    } else if (type === "PRODUCT" && value !== "null") {
      const selectedValue = attributesOptions.find(
        option => option.id === parseInt(value)
      );
      if (selectedValue) {
        valueToShow = selectedValue.name;
        priceToShow = selectedValue.price;
      }
    }

    inscriptionAttributesAmount += priceToShow;
  })

  let customAmount = parseFloat((total - inscriptionAttributesAmount).toFixed(2))

  let customPrice = safeInscriptionPrice ?
    safeInscriptionPrice.amount : 0

  if (inscriptionAttributesAmount) {
    customPrice = customPrice + inscriptionAttributesAmount
  }

  if (inscriptionDiscount) {
    if (inscriptionDiscount.type == 'PCT') {//recalculate total from discount
      customAmount = (total / ((100 -inscriptionDiscount.value)/100) )-inscriptionAttributesAmount
    } else {
      customAmount = total + inscriptionDiscount.value - inscriptionAttributesAmount
    }
  }

  return (
    <Segment>
      <List divided verticalAlign="middle" relaxed>
        {customPrice == total || commision ?
          <List.Item>
            <List.Content floated="right">{selectedPrice}</List.Content>
            <List.Content floated="left" verticalAlign="middle">
              <Header
                sub
                content={`${t(
                  "inscriptionsScreen.detail.basePrice"
                )} (${inscriptionPrice?.name || "Default"})`}
              />
            </List.Content>
          </List.Item>
          :
          <List.Item>
            <List.Content floated="right">{customAmount ? `${customAmount.toFixed(2)}€` : "0.00€"}</List.Content>
            <List.Content floated="left" verticalAlign="middle">
              <Header
                sub
                content={`${t(
                  "inscriptionsScreen.detail.basePrice"
                )} (${t(
                  "inscriptionsScreen.detail.custom"
                )})`}
              />
            </List.Content>
          </List.Item>
        }
        {inscriptionAttributes?.map(({ value, modalityAttributeId }, index) => {
          const {
            name,
            type,
            price,
            ModalityAttributesOptions: attributesOptions
          } = inscriptionExtra.find(
            attribute => attribute.id === modalityAttributeId
          );

          let valueToShow = null;
          let priceToShow = 0;
          if (type === "BOOLEAN") {
            const selectedValue = JSON.parse(value);

            if (selectedValue) {
              if (price) {
                priceToShow = price;
              }
            } else {
              return null;
            }
          } else if (type === "PRODUCT" && value !== "null") {
            const selectedValue = attributesOptions.find(
              option => option.id === parseInt(value)
            );
            if (selectedValue) {
              valueToShow = selectedValue.name;
              priceToShow = selectedValue.price;
            }
          }

          priceToShow = parseFloat(priceToShow).toFixed(2);
          return (
            priceToShow != 0 && (
              <List.Item key={index}>
                <List.Content floated="right">{`${priceToShow}€`}</List.Content>
                <List.Content floated="left" verticalAlign="middle">
                  <Header sub>{`${name} ${
                    valueToShow ? `(${valueToShow})` : ""
                    }`}</Header>
                </List.Content>
              </List.Item>
            )
          );
        })}

        {inscriptionDiscount ? (
          <List.Item>
            <List.Content floated="right">
              {inscriptionDiscount
                ? ` ${inscriptionDiscount.discountCode} [${inscriptionDiscount.description}]
                  - ${inscriptionDiscount?.value.toFixed(2)} ${
                inscriptionDiscount?.type === "" ||
                  inscriptionDiscount?.type === "PCT"
                  ? "%"
                  : "€"
                }`
                : "0.00€"}
            </List.Content>
            <List.Content floated="left" verticalAlign="middle">
              <Header sub>{t("inscriptionsScreen.detail.discount")} </Header>
            </List.Content>
          </List.Item>
        ) : (<div></div>)}
        {commision ? (
          <List.Item>
            <List.Content floated="right">
              {commision > 0 ? `${commision.toFixed(2)}€` : "0.00"}
            </List.Content>
            <List.Content floated="left" verticalAlign="middle">
              <Header sub>
                {t("inscriptionsScreen.detail.commissionWithoutParenteses")}
              </Header>
            </List.Content>
          </List.Item>
        ) : (<div></div>)}
        <List.Item>
          <List.Content floated="right">
            <Header>{total ? `${total.toFixed(2)}€` : "0.00€"}</Header>
          </List.Content>
          <List.Content floated="left" verticalAlign="middle">
            <Header>Total</Header>
          </List.Content>
        </List.Item>
        {totalRefund ? (
          <List.Item>
            <List.Content floated="right">
              {`-${totalRefund.toFixed(2)}€`}
            </List.Content>
            <List.Content floated="left" verticalAlign="middle">
              <Header sub>
                {t("inscriptionsScreen.detail.totalRefunded")}
              </Header>
            </List.Content>
          </List.Item>
        ) : (<div></div>)}
      </List>
    </Segment>
  );
};

export default PriceDetail;
