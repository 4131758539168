import { clientApi } from ".";

export default {
  fetchNotifications: async params => {
    try {
      const response = await clientApi
        .getClient()
        .get("/notifications", { params: { ...params, channel_id: 1 } });

      return response.data;
    } catch (err) {}
  },
  getNotification: async id => {
    try {
      const response = await clientApi.getClient().get(`/notification/${id}`, {params: {channel_id: 1}});

      return response.data;
    } catch (err) {}
  },
  getNotificationStatus: async id => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/notification/${id}/status`, {params:{channel_id: 1}});

      return response.data;
    } catch (err) {}
  },
  updateNotification: async (id, data) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/notification/${id}`, { ...data, channel_id: 1 });

      return response.data;
    } catch (err) {}
  },
  createNotification: async data => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/notifications`, { ...data, channel_id: 1 });

      return response.data;
    } catch (err) {}
  },
  send: async data => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/notifications/send`, { ...data, channel_id: 1 });

      return response.data;
    } catch (err) {}
  },
  deleteNotification: async id => {
    try {
      const response = await clientApi
        .getClient()
        .delete(`/notification/${id}`, {params: {channel_id: 1}});

      return response.data;
    } catch (err) {}
  },
  getNotificationsConfig: async () => {
    try {
      const response = await clientApi.getClient().get(`/notifications/config`, {params : { channel_id: 1}});

      return response.data;
    } catch (err) {}
  },
  changeNotificationsConfig: async params => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/notifications/config`, { ...params, channel_id: 1 } );

      return response.data;
    } catch (err) {}
  },
  verify: async () => {
    try {
      const response = await clientApi.getClient().get(`/notifications/verify`,{ params: { channel_id: 1 } });

      return response.data;
    } catch (err) {}
  },
  /**
   *
   * @param {{
   *  companyId: number | null,
   *  raceId: number | null,
   *  modalityId: number | null,
   *  region: string | null,
   *  pageSize: number;
   *  pageNumber: number;
   *  sort?: 'ASC' | 'DESC'
   *  field?: string;
   *  name?: string;
   * }} params
   */
  getRecipents: async params => {
    try {
      const response = await clientApi
        .getClient()
        .get(`notification/recipents`, { params: { ...params, channel_id: 1 }  });

      return response.data;
    } catch (err) {}
  }
};
