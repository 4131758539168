import React, { useState, useEffect, useMemo } from "react";

import Form from "../../components/Form";

import AsyncButton from "../../components/AsyncButton";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import configApi from "../../apis/appConfig";
import { DAlert } from "../../components/Dalert";
import { modalHandler } from "../../components/DModal";

export default function TPVConfigHandler({ t, data, updateForm }) {
  const [formState, setFormState] = useState(data);
  const [showValidation, setShowValidation] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    modalHandler.refreshOptions();
  }, [formState]);

  const save = async () => {
    setShowValidation(true);
    if (!formState.ISFORMVALID) {
      return;
    }
    setLoading(true)
    configApi.update(formState).then(config => {
      setLoading(false)
      if (config.status == 200) {
        DAlert.success({ title: t("commons.savedSuccess") });
        setFormState(config.data)
        updateForm()
      } else {
        DAlert.error({ title: t("commons.errorSaving") });
      }
    });
    // modalHandler.close();
  };
  return (
    <div>
      <Form
        defaultState={formState}
        showValidation={showValidation}
        onFormChange={(state) => setFormState(state)}
        fields={[
          {
            fields: [
              {
                name: "Ds_Merchant_MerchantCode",
                type: "Input",
                title: "Ds_Merchant_MerchantCode",
                colSize: 16,
                validation: {
                  required: true,
                },
              },
              {
                name: "Ds_Merchant_MerchantSignature",
                title: "Ds_Merchant_MerchantSignature",
                type: "Input",
                colSize: 16,
                validation: {
                  required: true,
                },
                props: {
                  icon: {
                    name: passwordVisible ? 'eye slash' : 'eye', circular: true, link: true, onClick: () => {
                      setPasswordVisible(!passwordVisible)
                    }
                  },
                  type: passwordVisible ? 'input' : "password"
                }
              },
            ],
          },
          {
            fields: [
              {
                name: "Ds_SignatureVersion",
                type: "Input",
                title: "Ds_SignatureVersion",
                colSize: 16,
              },
              {
                name: "Ds_Merchant_Terminal",
                title: "Ds_Merchant_Terminal",
                type: "Input",
                colSize: 16,
              },
              {
                name: "Ds_MerchantCurrency",
                title: "Ds_MerchantCurrency",
                type: "Input",
                colSize: 16,
              },
            ],
          },
          {
            fields: [{ type: "Divider", title: "SANDBOX" }],
          },
          {
            fields: [
              {
                name: "sandbox_redirect",
                type: "Input",
                title: "Redirect",
                colSize: 16,
                validation: {
                  required: true,
                },
              },
              {
                name: "sandbox_webservice",
                title: "Webservice",
                type: "Input",
                colSize: 16,
                validation: {
                  required: true,
                },
              },
              {
                name: "sandbox_ws",
                title: "WS",
                type: "Input",
                colSize: 16,
                validation: {
                  required: true,
                },
              },
            ],
          },
          {
            fields: [{ type: "Divider", title: "PRODUCTION" }],
          },
          {
            fields: [
              {
                name: "production_redirect",
                type: "Input",
                title: "Redirect",
                colSize: 16,
                validation: {
                  required: true,
                },
              },
              {
                name: "production_webservice",
                title: "Webservice",
                type: "Input",
                colSize: 16,
                validation: {
                  required: true,
                },
              },
              {
                name: "production_ws",
                title: "WS",
                type: "Input",
                colSize: 16,
                validation: {
                  required: true,
                },
              },
            ],
          },
        ]}
      />

      <Grid>
        <Grid.Column width="16">
          <AsyncButton fluid color="vk" onClick={() => save()}>
            {t('commons.save')}
          </AsyncButton>
        </Grid.Column>
      </Grid>
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : null}
    </div>
  );
}
