import React from "react";
import { Tab } from "semantic-ui-react";

import BasicInfo from "./BasicInfo";
import Regulation from "./Regulation";
import Customize from "./Customize";
import Advanced from "./Advanced";

export const tabs = t => [
  {
    menuItem: {
      key: "basicInformation",
      icon: "clipboard outline",
      content: t("raceEdit.basicInformationTab")
    },
    render: () => (
      <Tab.Pane style={{ minHeight: "45vh" }}>
        <BasicInfo />
      </Tab.Pane>
    )
  },
  {
    menuItem: {
      key: "regulation",
      icon: "file alternate outline",
      content: t("raceEdit.regulationTab")
    },
    render: () => (
      <Tab.Pane style={{ minHeight: "45vh" }}>
        <Regulation />
      </Tab.Pane>
    )
  },
  {
    menuItem: {
      key: "custom",
      icon: "adjust",
      content: t("raceEdit.customTab")
    },
    render: () => (
      <Tab.Pane style={{ minHeight: "45vh" }}>
        <Customize />
      </Tab.Pane>
    )
  },
  {
    menuItem: {
      key: "advance",
      icon: "list ul",
      content: t("raceEdit.advancedTab")
    },
    render: () => (
      <Tab.Pane style={{ minHeight: "45vh" }}>
        <Advanced />
      </Tab.Pane>
    )
  }
];
