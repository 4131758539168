import React, { Component } from "react";
import {
  Container,
  Grid,
  Button,
  Divider,
  Transition,
  Header,
  Checkbox, 
  GridColumn,
  Input,
  Label
} from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Form from "../../components/Form";
import { withTranslation } from "react-i18next";
import MyTable from "../../components/DTable";
import companies from "../../apis/companies";
import provincesApi from "../../apis/regions";
import { DAlert } from "../../components/Dalert";
import { modalHandler } from "../../components/DModal";
import SearchInput from "../../components/SearchInput";

import ButtonGroup from "../../components/ButtonGroup";
import ImagePicker from "../../components/ImagePicker";
import CreateUserButton from "./CreateUserButton";

import { getCountries } from "../../reducers/countries";
import { roles as userRoles } from "../../util/usersUtil";
import { checkNIF } from "../../util/util";
import TPVHandler from "./TPVHandler";
import FilterableTable from "../../components/FilterableTable";

const tableHeaders = (t) => {
  return [
    {
      content: t("companyDetail.name"),
      accessor: "name",
    },
    {
      content: t("companyDetail.email"),
      accessor: "email",
    },
    {
      content: t("companyDetail.phone"),
      accessor: "phone",
    },
  ];
};

const tableHeadersTPVS = (t) => {
  return [
    {
      content: t("companyDetail.name"),
      accessor: "name",
    },
    {
      content: t("companyDetail.description"),
      accessor: "description",
    },
  ];
};

const formFields = (t, provinces, countries) => {
  return [
    {
      fields: [
        {
          name: "name",
          title: t("companyDetail.name"),
          placeHolder: t("companyDetail.name"),
          type: "Input",
          colSize: 16,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired"),
          },
          props: {
            id: "CompanyDetailsName",
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "email",
          title: t("companyDetail.email"),
          placeHolder: t("companyDetail.email"),
          type: "Input",
          colSize: 16,
          validation: {
            required: true,
            regexType: "email",
            errorMessage: t("login.invalidEmailFormat"),
          },
          props: {
            id: "CompanyDetailsEmail",
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "phone",
          title: t("companyDetail.phone"),
          placeHolder: t("companyDetail.phone"),
          type: "Input",
          colSize: 10,
          validation: {
            regexType: "phone",
            errorMessage: t("validation.phone"),
          },
          props: {
            id: "CompanyDetailsPhone",
          },
        },
        {
          name: "cif",
          title: t("companyDetail.cifnif"),
          placeHolder: t("companyDetail.cifnif"),
          type: "Input",
          colSize: 6,
          validation: {
            custom: (values) => {
              return values.cif ? checkNIF(values.cif, ["cif", "nif"]) : false;
            },
            errorMessage: t("companyDetail.messages.invalidCiforNif"),
          },
          props: {
            id: "CompanyDetailsCif",
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "comissionManagement",
          title: t("companyDetail.managementCommission"),
          placeHolder: t("companyDetail.managementCommission"),
          type: "Input",
          colSize: 6,
          deflt: 0,
          validation: {
            custom: (values) => {
              let parsedComission = parseFloat(
                values.comissionManagement?.toString().replace(",", ".")
              );
              if (isNaN(parsedComission)) {
                parsedComission = 0;
                values.comissionManagement = parsedComission;
              }
              return !isNaN(parsedComission);
            },
            errorMessage: t("commons.mustBeNumber"),
            regexType: "double",
          },
          props: {
            id: "CompanyDetailsComissionManagement",
          },
        },

        {
          name: "comission_type",
          title: t("companyDetail.comisionType"),
          type: "RadioButtons",
          colSize: 8,
          deflt: "PCT",
          radios: [
            {
              label: t("companyDetail.comisionType_PCT"),
              value: "PCT",
            },
            {
              label: t("companyDetail.comisionType_VAL"),
              value: "VAL",
            },
          ],
          props: {
            id: "CompanyDetailsComissionType",
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "contacto",
          title: t("companyDetail.contact"),
          type: "Label",
        },
      ],
    },
    {
      fields: [
        {
          name: "address",
          title: t("companyDetail.address"),
          placeHolder: t("companyDetail.address"),
          type: "Input",
          colSize: 10,
          props: {
            id: "CompanyDetailsAddress",
          },
        },
        {
          name: "zip_code",
          title: t("companyDetail.postalCode"),
          placeHolder: t("companyDetail.postalCode"),
          type: "Input",
          colSize: 6,
          props: {
            id: "CompanyDetailsZip",
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "country",
          title: t("companyDetail.country"),
          placeHolder: t("companyDetail.country"),
          type: "Select",
          colSize: 10,
          data: countries.map((countryCode) => ({
            text: t(`countries.${countryCode}`),
            value: countryCode,
          })),
          props: {
            id: "CompanyDetailsCountry",
          },
        },
      ],
    },
    {
      fields: (state) => [
        {
          name: "region",
          type: state.country == "ES" ? "Select" : "Input",
          data:
            state.country == "ES"
              ? provinces.map((province) => ({
                  text: province.name,
                  value: province.name,
                }))
              : null,
          props: {
            clearable: true
          },
          title: t("companyDetail.province"),
          placeHolder: t("companyDetail.province"),
          validation: {
            required: true,
            regexType: "text",
            errorMessage: t("commons.fieldRequired"),
          },
          colSize: 8,
        },
        {
          name: "city",
          title: t("companyDetail.city"),
          placeHolder: t("companyDetail.city"),
          type: "Input",
          colSize: 8,
          props: {
            id: "CompanyDetailsCity",
          },
        },
      ],
    },
  ];
};

@connect(
  (state) => ({
    countries: state.countries.countries,
  }),
  (dispatch) => ({
    actions: bindActionCreators({ getCountries }, dispatch),
  })
)
@withTranslation()
export default class CompanyManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      users: [],
      totalUsers: 0,
      isNew: false,
      showValidation: false,
      showUsers: false,
      base64_logo: "",
      provinces: [],
      allowNotifications : false,
      notifications_answer_mails : null,
      notifEmailError : false
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.props.actions.getCountries(this.props.countries);
    this.getData();
    this.fetchProvinces();
  }

  async getData() {
    if (this.props.match.params.id) {
      const data = await companies.getCompany(this.props.match.params.id);
      this.setState({ data, showUsers: true, allowNotifications : data.can_send_notifications ? data.can_send_notifications : false, notifications_answer_mails : data.notifications_answer_mails });
    } else {
      this.setState({ isNew: true });
      return;
    }

    //get users
    if (this.props.match.params.id) {
      this.fetchUsers();
    }
  }

  async fetchUsers(params) {
      const users = await companies.getCompanyUsers(this.props.match.params.id, params);
      this.setState({ users: users.rows, totalUsers: users.count });
  }

  async fetchProvinces() {
    await provincesApi.fetchRegions().then((res) => {
      this.setState({
        provinces: res.map((province) => ({ name: province.name, slug:province.slug })),
      });
    });
  }

  async create() {
    const { data } = this.state;
    const { t } = this.props;

    this.setState({ showValidation: true });

    if (data.ISFORMVALID && !this.state.notifEmailError) {
      data['channel_id'] = 1;
      const result = await companies.create(data);

      if (result.id) {
        this.setState({ isNew: false, showUsers: true, data: result });
        DAlert.success({ title: t("commons.savedSuccess") });
        this.props.history.push(`/company/${result.id}`);
      } else {
        DAlert.error({ title: t("commons.errorSaving") });
      }

      return;
    }
  }

  async update() {
    const { data } = this.state;
    const { t } = this.props;

    data['channel_id'] = 1;
    this.setState({ showValidation: true });
    if (data.ISFORMVALID && !this.state.notifEmailError) {
      await companies.update(data.id, data).then((result) => {
        if (result.id) {
          DAlert.success({ title: t("commons.savedSuccess") });

          this.setState({ data: result });
        } else {
          DAlert.error({ title: t("commons.errorSaving") });
        }
      });

      return;
    }
  }

  allowNotifications(){
    const {allowNotifications, data} = this.state;
    data.can_send_notifications = !allowNotifications;
    this.setState({allowNotifications : !allowNotifications, data})
    if(allowNotifications)
      this.setState({notifications_answer_mails : null, notifEmailError : false})
  }

  notificationEmailHandler(email){
    const { data } = this.state;
    data.notifications_answer_mails = email;
    this.setState({data, notifications_answer_mails : email})
    this.setState({notifEmailError : this.validateEmail(email)})
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(email).toLowerCase());
}

  async block(data) {
    const { t } = this.props;

    const modalConfirm = {
      header: t("commons.areYouSure"),
      content: t(`companyDetail.blockCompany.modalMessage.${data.blocked}`),
      onYes: async () => {
        const result = await companies.block(data.id);

        modalHandler.close();
        DAlert.success({
          title: t(`companyDetail.blockCompany.okMessage.${result.blocked}`),
        });
        this.getData();
      },
    };

    modalHandler.open(modalConfirm);
  }

  save() {
    return this.state.isNew ? this.create() : this.update();
  }

  cardImageChange(base64img) {
    const { data } = this.state;
    data.base64_logo = base64img; //insert logo in data
    this.setState({ ...this.state, data, base64_logo: base64img });
  }

  openTPVHandler(data = null) {
    modalHandler.open({
      header: this.props.t(
        data ? "companyDetail.editTPV" : "companyDetail.addTPV"
      ),
      content: (
        <TPVHandler
          companyId={this.props.match.params.id}
          data={data}
          updateTPVS={this.getData}
        />
      ),
      actions: <></>,
    });
  }

  setCommisionValue() {
    const el = document.querySelector("#CompanyDetailsComissionManagement");
    if (el) {
      setTimeout(() => {
        if (el.value.length === 0) {
          el.value = 0;
        }
      }, 25);
    }
  }

  render() {
    const { t, countries } = this.props;
    const {
      isNew,
      showValidation,
      users,
      showUsers,
      totalUsers,
      provinces,
    } = this.state;
    const blocked = this.state.data ? this.state.data.blocked : false;
    const totalTPVs = this.state.data?.CompanyTPVs ? this.state.data?.CompanyTPVs.length : 0
    {
      this.setCommisionValue();
    }

    return (
      <Container style={{ padding: 20, margin: 20 }}>
        <Grid stackable divided="vertically">
          <Grid.Row columns={isNew ? 1 : 2}>
            <Grid.Column>
              {blocked && (
                <Header as="h1" inverted color="red">
                  {t(`companyDetail.blockCompany.message`)}
                </Header>
              )}
              <Form
                defaultState={this.state.data}
                onFormChange={(data) => {
                  this.setState({ data: { ...this.state.data, ...data } });
                }}
                fields={formFields(t, provinces, countries)}
                showValidation={showValidation}
              />
              <br />

              <Grid.Column mobile={16} computer={16}>
                <Header as="h5">{t(`companyDetail.logo`)}</Header>
                <ImagePicker
                  content={t("commons.selectImage")}
                  onSelect={(data) => this.cardImageChange(data)}
                  imageSrc={
                    isNew
                      ? this.state.base64_logo
                      : this.state.data
                      ? this.state.data.url_logo
                      : ""
                  }
                  buttonProps={{
                    fluid: true,
                  }}
                />
              </Grid.Column>
              <br />
              <Grid.Column mobile={16} computer={16}>
              <Checkbox
                      //toggle
                      label={t(`companyDetail.allowNotifications`)}
                      checked={this.state.allowNotifications}
                      onClick={(status) => {
                        this.allowNotifications()
                      }}
                      color='blue'
                    />
              {
                this.state.allowNotifications ?
                
                <GridColumn>
                  <br />
                  <label style={{fontWeight : "bold", fontSize : 13}}>{t("companyDetail.notificationEmail")}</label>
                  <Input
                    error = {this.state.notifEmailError}
                    value={this.state.notifications_answer_mails}
                    placeholder = {t(`companyDetail.notificationEmail`)}
                    fluid
                    onChange={(_, { value }) =>
                      this.notificationEmailHandler(value)
                    }
                  />
                  {this.state.notifEmailError && <Label as='a' basic color='red' pointing >{t(`companyDetail.notificationEmailError`)}</Label>}
                </GridColumn> : null
              }
              </Grid.Column>

              {!isNew && (
                <Grid.Row
                  columns={3}
                  verticalAlign={"middle"}
                  style={{ marginTop: 20 }}
                  centered
                >
                  <Grid columns={2}>
                    <Grid.Column>
                      <Button
                        fluid
                        color="vk"
                        onClick={() => this.block(this.state.data)}
                      >
                        {t(`companyDetail.blockCompany.btnMessage.${blocked}`)}
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Grid.Row>
              )}
            </Grid.Column>

            <Transition visible={showUsers} animation="scale" duration={500}>
              <>
                <Grid.Column style={{ marginTop: "2%" }}>
                  <FilterableTable
                    id="CompanyDetailsTable"
                    tableHeaders={tableHeaders(t)}
                    contentData={users}
                    totalData={totalUsers}
                    hideFilters="true"
                    onChange={(table, filter) => this.fetchUsers({ ...table, ...filter })}
                  />
                  <CreateUserButton
                    updateUsers={this.getData}
                    companyId={this.state.data?.id}
                    roleId={userRoles.COMPANY}
                  />

                  <MyTable
                    id="CompanyTPVS"
                    headers={tableHeadersTPVS(t)}
                    onRowClick={(tpv) => this.openTPVHandler(tpv)}
                    contentData={
                      this.state.data?.CompanyTPVs
                        ? this.state.data.CompanyTPVs
                        : []
                    }
                    totalData={totalTPVs}
                  />
                  <Button color="vk" onClick={() => this.openTPVHandler()}>
                    {t("companyDetail.addTPV")}
                  </Button>
                </Grid.Column>
              </>
            </Transition>
          </Grid.Row>
        </Grid>

        <Divider />

        <ButtonGroup onSave={() => this.save()} url="/companies" />
      </Container>
    );
  }
}
