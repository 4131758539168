export default {
  races: {
    actualRace: {}
  },
  user: {
    token: null,
    role: null,
    id: null
  },
  modalities: {
    actualModality: {}
  },
  countries: {
    countries: []
  },
  notifications: {
    activeTab: "list"
  }
};
