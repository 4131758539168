import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Message,
  Button,
  Icon,
  Modal,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import FluidResponsiveButton from "../../components/FluidResponsiveButton";
import { modalHandler } from "../../components/DModal";
import { useSelector } from "react-redux";
import { createUserForm } from "./util";
import companiesApi from "../../apis/companies";
import usersApi from "../../apis/users";
import Form from "../../components/Form";

const CreateUserModal = (props) => {
  const user = useSelector((state) => state.user);
  const [myUser, setUser] = useState({});
  const [isCompanyUser, setIsCompanyUser] = useState(false);
  const [error, setError] = useState();
  const [userForm, setUserForm] = useState();
  const [companies, setCompanies] = useState();

  const [t] = useTranslation();

  const formHandler = (formData) => {
    setError(undefined);
    setUserForm(formData);

    if (!!formData.role && formData.role === 2) {
      setIsCompanyUser(true);
    } else {
      setIsCompanyUser(false);
    }
  };

  const createUserHandler = async () => {
    if (!isCompanyUser) {
      if (!userForm.ISFORMVALID) {
        setError("EMPTY_FIELDS");
        return;
      }
    }

    if (
      isCompanyUser &&
      (userForm.company === undefined || !!!userForm.company)
    ) {
      setError("NOT_COMPANY_ID");
      return;
    }
    let response = {};
    if (user.role == "COMPANY") {
      response = await usersApi.createUser({
        email: userForm.email,
        role_id: myUser.role_id,
        company_id: myUser.company_id,
        channel_id: 1,
      });
    } else {
      response = await usersApi.createUser(
        userForm.company
          ? {
              email: userForm.email,
              role_id: userForm.role,
              company_id: userForm.company,
              channel_id: 1,
            }
          : {
              email: userForm.email,
              role_id: userForm.role,
              channel_id: 1,
            }
      );
    }

    if (response.ok !== undefined && !response.ok) {
      setError(response.error.message);
      return;
    } else {

      await props.updateUsers()
      modalHandler.close();
      modalHandler.open(afterUserCreation);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    getMycompany(user.id);
  }, [user]);

  useEffect(() => {
    modalHandler.refreshOptions({
      ...modalOptions,
    });
  }, [isCompanyUser, error, companies, userForm]);

  const getCompanies = async () => {
    const data = await companiesApi.getMin();

    const companies = data.map((company) => ({
      text: company.name,
      value: company.id,
    }));
    setCompanies(companies);
  };

  const getMycompany = async (id) => {
    await usersApi.fetchUser({ id }).then((res) => {
      setUser(res);
    });
  };

  const afterUserCreation = {
    header: t(`usersScreen.modal.createdSuccess`),
    content: (
      <Modal.Description>
        {t(`usersScreen.modal.createdSuccessMessage`)}
      </Modal.Description>
    ),
    actions: (
      <Modal.Actions>
        <Button
          icon="check"
          color={"instagram"}
          content={t(`usersScreen.modal.createdSuccessButton`)}
          onClick={() => modalHandler.close()}
        />
      </Modal.Actions>
    ),
  };

  const modalOptions = {
    header: t("usersScreen.addUser"),
    content: (
      <Modal.Description id="CreateUserModal">
        <Grid padded>
          <Grid.Row>
            <Grid.Column width="16">
              <Form
                onFormChange={formHandler}
                fields={createUserForm(
                  t,
                  props.roles,
                  isCompanyUser,
                  companies,
                  user.role
                )}
              />
            </Grid.Column>
          </Grid.Row>

          {error && (
            <Grid.Row>
              <Grid.Column width="16">
                <Message
                  id="CreateUserError"
                  error
                  header={t(`usersScreen.modal.messages.${error}.header`)}
                  content={t(`usersScreen.modal.messages.${error}.message`)}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Modal.Description>
    ),
    actions: (
      <Grid>
        <Grid.Column width="16">
          <Button
            id="CreateUserConfirmButton"
            fluid
            color="vk"
            onClick={createUserHandler}
          >
            {t("usersScreen.addUser")}
          </Button>
        </Grid.Column>
      </Grid>
    ),
  };

  return (
    <>
      <FluidResponsiveButton
        id="CreateUserButton"
        icon
        labelPosition="left"
        size="small"
        onClick={async () => await modalHandler.open(modalOptions)}
        primary
      >
        <Icon name="user" />
        {t("usersScreen.addUser")}
      </FluidResponsiveButton>
    </>
  );
};

export default CreateUserModal;
