import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import promiseMiddleware from "redux-promise";
import initialState from "../reducers/initialState";

let middleware = [promiseMiddleware, thunk];

const reduxImmutableStateInvariant = require("redux-immutable-state-invariant").default();
const createLogger = require("redux-logger").createLogger;

const logger = createLogger({ collapsed: true });

middleware = [...middleware, reduxImmutableStateInvariant];

if (process.env.NODE_ENV == "development") {
  middleware = [...middleware, reduxImmutableStateInvariant, logger];
}

export default function configureStore() {
  return createStore(rootReducer, initialState, applyMiddleware(...middleware));
}
