import React from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const TrueFalse = props => {
  return (
    <div style={{ textAlign: "center", ...props.style }}>
      {props.value ? (
        <Icon name="check" style={{ color: "darkgreen" }} />
      ) : (
        <Icon name="times" style={{ color: "#db2828" }} />
      )}
    </div>
  );
};

TrueFalse.propTypes = {
  value: PropTypes.bool
};

export default TrueFalse;
