import React, { useState, useEffect } from "react";
import { Container, Placeholder, Button, Icon, Message, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { times } from "lodash";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import CustomDotGroup from "../../../../components/CustomDotGroup";
import Form from "../../../../components/Form";

import { selectModality, inscriptionFieldsToChangeModality } from "./util";
import raceApi from "../../../../apis/races";
import modalityApi from "../../../../apis/modalities";
import provincesApi from "../../../../apis/regions";
import countriesApi from "../../../../apis/countries";
import teamTypesApi from "../../../../apis/teamTypes";
import moment from "moment";

const ChangeModality = props => {
  const [t] = useTranslation();
  const [modalities, setModalities] = useState([]);
  const [selectedModality, setSelectedModality] = useState(null);
  const [modalityConfig, setModalityConfig] = useState();
  const [provinces, setProvinces] = useState();
  const [countries, setCountries] = useState();
  const [editedInscriptions, setEditedInscriptions] = useState(props.inscriptions);
  const [destinationInscriptions, setDestinationInscriptions] = useState({})
  const [loadingConfig, setLoadingConfig] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {

    const race = await raceApi.fetchRace(props.raceId);
    const raceConfig = await raceApi.fetchModalitiesStatus(race.slug);

    //check what modalities are for teams
    let teamModalities = [];
    await Promise.all(raceConfig.race.modalities.map(async modality => {
      const teamTypes = await teamTypesApi.getTeamTypes(modality.id, {});
      return teamTypes.forEach(t => teamModalities.push(t.modality_id));
    }))

    const modalities = raceConfig.race.modalities
      .filter(modality => (modality.id !== props.modalityId && !teamModalities.includes(modality.id)))
      .map(modality => ({
        id: modality.id,
        text:
          modality.inscriptionsState === "ENABLED"
            ? modality.name
            : `${modality.name} (${t(
              `inscriptionStatus.${modality.inscriptionsState}`
            )})`,
        value: modality.slug,
        disabled: !(
          modality.inscriptionsState === "ENABLED" ||
          modality.inscriptionsState === "FULL_RACE" ||
          modality.inscriptionsState === "FULL_MODALITY"
        )
      }));

    const provinces = await provincesApi.fetchRegions();
    setProvinces(provinces.map(provinces => ({ title: provinces.name })));

    const countries = await countriesApi.fetchCountryCodes();
    setCountries(countries)

    setModalities(modalities);
  };

  const getModalityConfiguration = async modalitySlug => {
    const modality = await modalityApi.fetchInscriptionInfo(modalitySlug);
    const inscriptions = await modalityApi.fetchInscriptions(modality.id)
    setDestinationInscriptions(inscriptions)
    setModalityConfig(modality);
    setLoadingConfig(false);
  };

  const hasExtraFields =
    modalityConfig ?.attributes.length > 0 ||
      (modalityConfig &&
        Object.values(modalityConfig ?.additionalFields).some(f => f === true));

  const validateChange = () => {
    const maxDate = modalityConfig.inscriptions.birthMax ? moment(modalityConfig.inscriptions.birthMax) : undefined
    const minDate = modalityConfig.inscriptions.birthMin ? moment(modalityConfig.inscriptions.birthMin) : undefined
    let age = false
    let dni = true

    const res = editedInscriptions.reduce((acc, curr) => {
      const birthday = moment(curr.birthday)

      if (minDate && maxDate) {
        age = birthday.isBetween(minDate, maxDate);
      } else {
        if (!minDate && !maxDate) {
          age = true
        } else {
          if (!minDate) {
            age = birthday.isBefore(maxDate);
          }
          if (!maxDate) {
            age = birthday.isAfter(minDate);
          }
        }
      }

      if (destinationInscriptions && destinationInscriptions.count) {
        dni = destinationInscriptions.rows.some(inscription => {
          return inscription.athleteDni === curr.dni && inscription.inscriptionStatus !== "CANCELED"
        })
      } else {
        dni = false
      }
      return (acc &&
        (modalityConfig.inscriptions.gender === 'BOTH' || modalityConfig.inscriptions.gender === curr.gender) &&
        age && !dni)
    }, true)

    return res
  }

  return (
    <Container>
      <Form
        fields={selectModality(t, modalities)}
        onFormChange={formData => {
          setEditedInscriptions(props.inscriptions);
          if (formData.ISFORMVALID && !!formData.selectedModality) {
            setSelectedModality(formData.selectedModality);
            setLoadingConfig(true);
            props.onFormChange(formData);
            getModalityConfiguration(formData.selectedModality);
          } else {
            setSelectedModality(null);
          }
        }}
      />
      <p>{t("inscriptionsScreen.detail.changeModalityMessage")}</p>
      {loadingConfig && (
        <Placeholder fluid>
          {times(10, i => (
            <Placeholder.Line key={i} />
          ))}
        </Placeholder>
      )}
      {selectedModality && modalityConfig && !loadingConfig && !validateChange() && (

        <Grid.Row>
          <Grid.Column width="16">
            <Message
              error
              header={"Error"}
              content={t('inscriptionsScreen.detail.changeModalityValidation')}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      {selectedModality && modalityConfig && !loadingConfig && validateChange() && (
        <CarouselProvider
          totalSlides={props.inscriptions.length}
          naturalSlideWidth={100}
          naturalSlideHeight={hasExtraFields ? 30 : 20}
          lockOnWindowScroll
          dragEnabled={false}
          touchEnabled={false}
        >
          <Slider>
            {props.inscriptions.map((inscription, index) => (
              <Slide key={inscription.id} index={index}>
                <Form
                  showValidation={props.showValidations}
                  style={{ padding: 10 }}
                  fields={inscriptionFieldsToChangeModality(
                    t,
                    modalityConfig,
                    provinces,
                    countries,
                    props.showValidations,
                    inscription
                  )}
                  defaultState={inscription}
                  onFormChange={formData => {
                    const newInscriptions = [
                      ...editedInscriptions.slice(0, index),
                      { ...editedInscriptions[index], ...formData },
                      ...editedInscriptions.slice(index + 1)
                    ];
                    setEditedInscriptions(newInscriptions);
                    props.onInscriptionsChange(
                      !hasExtraFields
                        ? newInscriptions.map(i => ({
                          ...i,
                          ISFORMVALID: true
                        }))
                        : newInscriptions
                    );
                  }}
                />
              </Slide>
            ))}
          </Slider>
          <CustomDotGroup slides={props.inscriptions.length} />
        </CarouselProvider>
      )}
      <div style={{
        margin: "10px",
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <Button
          primary
          style={{ flex: 'end' }}
          disabled={selectedModality === null || loadingConfig || (modalityConfig && !validateChange())}
          onClick={() => {
            setLoadingConfig(true)
            props.onMoveInscriptions();
            setLoadingConfig(false)
          }}
        >
          <Icon name="exchange" />
          {t("inscriptionsScreen.detail.changeModality")}
        </Button>
      </div>
    </Container>
  );
};

export default ChangeModality;
