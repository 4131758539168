import React, { useState, useEffect } from "react";
import {
  Grid,
  Image,
  Segment,
  Button,
  Message,
  Flag,
  Dimmer,
  Loader,
  Label,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Modal } from "semantic-ui-react";
import KeyboardEventHandler from "react-keyboard-event-handler";

import Form from "../../components/Form";
import Logo from "../../../public/images/logo-ged-web.jpg";
import bgImage from "../../../public/images/loginBg.jpg";
import formFields, { recoverFields } from "./formFields";
import authApi from "../../apis/auth";
import { clientApi } from "../../apis";
import { modalHandler } from "../../components/DModal";

const Login = (props) => {
  const [t, i18n] = useTranslation();
  const [error, setError] = useState();
  const [modalError, setModalError] = useState();
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [recoverEmail, setRecoverEmail] = useState({});
  const [percentage, setPercentage] = useState(
    window.innerWidth > 1200 ? "25%" : window.innerWidth > 900 ? "45%" : "85%"
  );
  const [_, setCookie] = useCookies();

  useEffect(() => {
    const handleResize = (e) => {
      setPercentage(
        window.innerWidth > 1200
          ? "25%"
          : window.innerWidth > 900
          ? "45%"
          : "85%"
      );
    };
    const enter = (e) => {
      if (e.key === "Enter") {
        const enter = document.querySelector(
          "#root > div:nth-child(2) > div > div > div:nth-child(2) > div > div > button"
        );
        enter.focus();
        enter.click();
      }
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("keypress", enter);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("keypress", enter);
    };
  }, []);

  useEffect(() => {
    modalHandler.refreshOptions({
      ...modalOptions,
    });
  }, [recoverEmail, isModalLoading, modalError]);

  const loginHandler = async () => {
    setIsLoading(true);
    if (data.ISFORMVALID) {
      const { email, password } = data;
      const res = await authApi.login({ email, password });

      if (res?.token) {
        setCookie("token", res, { path: "/" });
        clientApi.setToken(res.token);
        props.history.replace("/home");
      } else {
        setError(res?.error.message);
      }
    } else {
      setError("EMPTY_FIELDS");
    }
    setIsLoading(false);
  };

  const recoverPasswordHanlder = async () => {
    if (recoverEmail?.ISFORMVALID) {
      setIsModalLoading(true);
      try {
        await authApi.recoverPassword({
          channel_id: 1,
          email: recoverEmail.email,
          origin: "PANEL",
        });

        modalHandler.open({
          header: null,
          size: "small",
          content: t("login.recoverEmailSent"),
          actions: (
            <Grid>
              <Grid.Column width="16">
                <Button
                  fluid
                  color="vk"
                  onClick={() => {
                    setIsModalLoading(false);
                    modalHandler.close();
                  }}
                >
                  {t("commons.ok")}
                </Button>
              </Grid.Column>
            </Grid>
          ),
        });
      } catch (err) {
        setIsModalLoading(false);
        setModalError("EMAIL_NOT_FOUND");
        return;
      }
    }
  };

  const modalOptions = {
    header: t("login.recoverHeader"),
    size: "small",
    content: (
      <Modal.Description>
        <Form
          fields={recoverFields(t)}
          onFormChange={(data) => {
            setModalError(undefined);
            setRecoverEmail(data);
          }}
        />
        {isModalLoading && (
          <Dimmer active style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
            <Loader />
          </Dimmer>
        )}
        {modalError && (
          <Message
            error
            header={t(`login.messages.${modalError}.header`)}
            content={t(`login.messages.${modalError}.message`)}
          />
        )}
      </Modal.Description>
    ),
    actions: (
      <Grid>
        <Grid.Column width="16">
          <Button fluid color="vk" onClick={recoverPasswordHanlder}>
            {t("login.recoverSend")}
          </Button>
        </Grid.Column>
      </Grid>
    ),
  };

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <KeyboardEventHandler
          handleKeys={["enter"]}
          onKeyEvent={(key, e) => loginHandler()}
        />
        <Grid
          textAlign="center"
          style={{
            height: "100%",
            width: "100%",
          }}
          verticalAlign="middle"
        >
          <Grid.Row style={{ height: "5%" }}>
            <Button.Group
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                borderRadius: 10,
              }}
              toggle
            >
              <Button
                icon
                onClick={() => i18n.changeLanguage("es")}
                style={{ backgroundColor: "white" }}
              >
                <Flag name="es" style={{ margin: 1 }} />
              </Button>
              <Button
                icon
                onClick={() => i18n.changeLanguage("en")}
                style={{ backgroundColor: "white" }}
              >
                <Flag name="gb" style={{ margin: 1 }} />
              </Button>
            </Button.Group>
          </Grid.Row>
          <Grid.Row style={{ height: "95%" }}>
            <Grid.Column style={{ maxWidth: percentage }}>
              <Segment>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    id="LoginLogo"
                    src={Logo}
                    size="medium"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <Form
                  style={{ padding: "30px 0px 10px" }}
                  onFormChange={(newData) => setData(newData)}
                  fields={formFields(t)}
                />
                <br />
                <Button
                  id="LoginButton"
                  color="vk"
                  fluid
                  size="large"
                  onClick={loginHandler}
                >
                  {t("login.loginText")}
                </Button>
                {error && (
                  <Message
                    id="LoginError"
                    error
                    header={t(`login.messages.${error}.header`)}
                    content={t(`login.messages.${error}.message`)}
                  />
                )}
                {isLoading && (
                  <Dimmer
                    active
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                  >
                    <Loader />
                  </Dimmer>
                )}
                <Label
                  style={{
                    backgroundColor: "transparent",
                    margin: "10px 0px 0px 0px",
                    cursor: "pointer",
                  }}
                  size={"medium"}
                  onClick={() => modalHandler.open(modalOptions)}
                >
                  {t("login.recoverText")}
                </Label>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default Login;
