import React, { Component } from "react";
import { Card, Segment } from "semantic-ui-react";
import ModalityCard from "../ModalityCard";
import { withTranslation } from "react-i18next";

@withTranslation()
export default class ModalityCardList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { history, modalities, cardsPerRow, raceId, t, racePublised, raceDeleted } = this.props;

    return (
      <Card.Group itemsPerRow={cardsPerRow} stackable>
        {modalities.length > 0 ? (
          modalities.map((modality, i) => (
            <ModalityCard
              racePublised={racePublised}
              raceDeleted={raceDeleted}
              key={i}
              raceId={raceId}
              key={modality.id}
              modality={modality}
              history={history}
            />
          ))
        ) : (
          <Segment padded basic>
            {t("racesScreen.noModalities")}
          </Segment>
        )}
      </Card.Group>
    );
  }
}
