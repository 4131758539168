import React, { useState, useEffect, Component } from "react";
import {
  Grid,
  Container,
  Button,
  Divider,
  Header,
  Icon,
  Responsive,
  Placeholder,
  Modal,
  Dimmer,
  Loader
} from "semantic-ui-react";
import Form from "../../../components/Form";
import { formFields, textArea } from "./util";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { getModality } from "../../../reducers/modalities";
import { getDateAndTime } from '../../../util/util'
import RichTextEditor from "../../../components/RichTextEditor";
import racesApi from "../../../apis/races";
import modalititesApi from "../../../apis/modalities";
import { DAlert } from "../../../components/Dalert";
import { modalHandler } from "../../../components/DModal";
import ModalitySubtitle from "../../../components/ModalitySubtitle";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

const styles = {
  BottomMargin: {
    marginBottom: "3%"
  },
  TopMargin: {
    marginTop: "1%"
  }
};

@connect(
  state => ({
    modality: state.modalities.actualModality,
    race: state.races.actualRace
  }),
  dispatch => ({
    actions: bindActionCreators({ getModality }, dispatch)
  })
)
@withTranslation()
export default class EditModalityM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      raceTypes: [],
      showValidation: false,
      btnQrDisable : false,
      modalityForm: {}
    };
  }
   resetQr = async ()=>{
    this.setState({btnQrDisable: true})
    const { t ,modality } = this.props
    const res = await modalititesApi.resetQr(modality.id)
    if (res.status === 200) {
      DAlert.success({ message: t("commons.resetQrSuccess") });
    } else {
      DAlert.error({ message: t("commons.resetQrError") });
    }
    this.setState({btnQrDisable: false})
  }
  getRaceTypes = async () => {
    await racesApi.fetchRaceTypes().then(response => {
      const raceTypes = response.map(type => ({
        text: type.type,
        value: type.id
      }));
      this.setState({ raceTypes });
    });
  };

  async componentDidMount() {
    this.getRaceTypes();
  }

  _getFirstDorsalToAssign(form) {
    const { automatic_dorsal_assignation, first_dorsal_to_assign } = form
    if (automatic_dorsal_assignation === "" || !automatic_dorsal_assignation) {
      return null
    } else {
      return first_dorsal_to_assign === null
        ? 1
        : parseInt(first_dorsal_to_assign)
    }

  }

  updateModality() {
    const { modalityForm } = this.state;
    const { modality, t, actions } = this.props;
    const start = getDateAndTime(modalityForm.start_date, modalityForm.time_start)
    const firstDorsalToAssign = this._getFirstDorsalToAssign(modalityForm);

    if(modalityForm.distance === null){
      modalityForm.distance = 0.00
    }

    if (modalityForm.ISFORMVALID) {
      const modalityToSend = {
        ...modalityForm,
        race_type: parseInt(modalityForm.race_type),
        distance: parseFloat(parseFloat(
          typeof modalityForm.distance === "string"
            ? modalityForm.distance.replace(",", ".")
            : modalityForm.distance
        ).toFixed(2)),
        start_date: start,
        first_dorsal_to_assign: firstDorsalToAssign,
        automatic_dorsal_assignation:
          modalityForm.automatic_dorsal_assignation === ""
            ? false
            : modalityForm.automatic_dorsal_assignation,
        is_published:
          modalityForm.is_published === "" ? false : modalityForm.is_published,
        is_dorsal_to_be_delivered:
          modalityForm.is_dorsal_to_be_delivered === "" 
            ? false 
            : modalityForm.is_dorsal_to_be_delivered,
        qr_auto_reset:
          modalityForm.qr_auto_reset === "" 
            ? false 
            : modalityForm.qr_auto_reset,
      };

      modalititesApi
        .updateModality(modality.id, modalityToSend)
        .then(res => {
          if (res.id !== undefined) {
            actions.getModality(res.id)
            DAlert.success({
              title: t("modalities.edit.alert.successTitle"),
              message: t("modalities.edit.alert.successMessage")
            });
          } else {
            DAlert.error({
              title: t("modalities.edit.alert.errorTitle"),
              message: t("modalities.edit.alert.errorMessage")
            });
          }
        })
        .catch(error => {
            DAlert.error({
              title: t("modalities.edit.alert.errorTitle"),
              message: t("modalities.edit.alert.errorMessage")
            })
          }
        );
    } else {
      DAlert.error({ message: t("commons.fieldsRequired") });
      this.setState({ showValidation: true });
    }
  }

  deleteModality() {
    const { modality, t, history } = this.props;

    modalHandler.open({
      header: t("modalities.edit.deleteModal.title"),
      content: <Modal.Description>{t("commons.areYouSure")}</Modal.Description>,
      basic: false,
      size: "tiny",
      onYes: async () => {
        try {
          const response = await modalititesApi.deleteModality(modality.id);
          if (response && !response.ok) {
            throw {
              message: response.error.message
            };
          }
          history.push(`/race/${modality.race_id}#summary`);

          DAlert.success({ title: t("modalities.edit.alert.deleteSuccess") });
        } catch (error) {
          if (error.message) {
            DAlert.error({
              title: t("modalities.edit.alert.deleteErrorTitle"),
              message: t(`commons.${error.message}`)
            });
          } else {
            DAlert.error({
              title: t("modalities.edit.alert.deleteErrorTitle")
            });
          }
        }
        modalHandler.close();
      }
    });
  }

  render() {
    const { t, modality, race, history } = this.props;
    const { showValidation, modalityForm, raceTypes , btnQrDisable} = this.state;


    const isEventOrAssociate =
      race?.activityType?.type === "EVENT" ||
      race?.activityType?.type === "ASSOCIATES";
    return (
      <Container>
        <ModalitySubtitle icon="edit" title="raceDetail.menu.modality.edit" />
        <Responsive minWidth={500}>
          <Grid columns={2} style={styles.BottomMargin}>
            <Grid.Column width={8}>
              <Form
                defaultState={modality}
                showValidation={showValidation}
                fields={textArea(t, isEventOrAssociate)}
                onFormChange={data =>
                  this.setState({ modalityForm: { ...modalityForm, ...data } })
                }
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Form
                defaultState={modality}
                showValidation={showValidation}
                fields={formFields(t, raceTypes, modality, race.start_date, race.finish_date, race.auto_dorsal_assignment, isEventOrAssociate)}
                onFormChange={data => {
                  this.setState({ modalityForm: { ...modalityForm, ...data } });
                }}
              />
              <Button
                style={{ margin: '5px 2px' }}
                color="vk"
                onClick={() => this.resetQr()}
                disabled= {btnQrDisable}
                >
                {t("modalities.edit.resetQr")}
              </Button>
            </Grid.Column>
          </Grid>
        </Responsive>
        {/* RESPONSIVE */}
        <Responsive maxWidth={500}>
          <Grid columns={1} style={styles.BottomMargin}>
            <Grid.Column width={16}>
              <Form
                defaultState={modality}
                showValidation={showValidation}
                fields={textArea(t)}
                onFormChange={data => {
                  this.setState({ modalityForm: { ...modalityForm, ...data } });
                }}
              />
              <Form
                defaultState={modality}
                showValidation={showValidation}
                // fields={formFields(t, raceTypes, modality)}
                fields={formFields(t, raceTypes, modality, race.start_date, race.finish_date, race.auto_dorsal_assignment)}
                onFormChange={data => {
                  this.setState({ modalityForm: { ...modalityForm, ...data } });
                }}
              />
            <Button
              style={{ margin: '5px 2px' }}
              color="vk"
              onClick={() => this.resetQr()}
              disabled= {btnQrDisable}
              >

              {t("modalities.edit.resetQr")}
            </Button>
            </Grid.Column>
          </Grid>
        </Responsive>

        <Divider horizontal style={styles.BottomMargin}>
          <Header as="h4">
            <Icon name="thumbtack" />
            {t("modalities.edit.moreInformation")}
          </Header>
        </Divider>

        {modality.description ? (
          <div style={styles.BottomMargin}>
            <RichTextEditor
              defaultContent={modality.description}
              onBlurEditor={data => {
                this.setState({
                  modalityForm: { ...modalityForm, description: data }
                });
              }}
            />
          </div>
        ) : (
            <Placeholder fluid style={styles.BottomMargin}>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          )}

        <Responsive minWidth={500}>
          <Grid columns={2}>
            <Grid.Column width={8} textAlign="left">
              {!race.deleted && <Button color="vk" onClick={this.updateModality.bind(this)}>
                {t("modalities.edit.save")}
              </Button>
              }
              <Button
                color="google plus"
                onClick={() =>
                  history.push(`/race/${modality.race_id}#summary`)
                }
              >
                {t("modalities.edit.cancel")}
              </Button>
            </Grid.Column>
            {!race.deleted && <Grid.Column width={8} textAlign="right">
              <Button color="red" onClick={this.deleteModality.bind(this)}>
                {t("modalities.edit.delete")}
              </Button>
            </Grid.Column>}
          </Grid>
        </Responsive>

        {/* RESPONSIVE */}
        <Responsive maxWidth={500}>
          <Grid columns={1} style={styles.TopMargin}>
            <Button
              onClick={this.updateModality.bind(this)}
              color="vk"
              fluid
              style={styles.BottomMargin}
            >
              {t("modalities.edit.save")}
            </Button>
            <Button
              fluid
              color="google plus"
              style={styles.BottomMargin}
              onClick={() => history.push(`/race/${modality.race_id}#summary`)}
            >
              {t("modalities.edit.cancel")}
            </Button>
            <Button
              color="red"
              fluid
              style={styles.BottomMargin}
              onClick={this.deleteModality.bind(this)}
            >
              {t("modalities.edit.delete")}
            </Button>
          </Grid>
        </Responsive>
        {!Object.keys(modality).length ? (
          <Dimmer active>
            <Loader />
          </Dimmer>
        ) : null}
      </Container>
    );
  }
}
