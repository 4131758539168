import { clientApi } from "./index";

export default {
  getDiscount: async id => {
    try {
      const response = await clientApi.getClient().get(`race/discount/${id}`, { params: { channel_id: 1 } });
      return response.data;
    } catch (error) {}
  },
  createDiscounts: async (raceId, params) => {
    try {
      const response = await clientApi
        .getClient()
        .post(`race/discounts/${raceId}`, { ...params, channel_id: 1 });
      return response.data;
    } catch (error) {}
  },
  validateDiscount: async (discountCode, raceSlug) => {
    try {
      const response = await clientApi
        .getClient()
        .get(encodeURI(`/race/${raceSlug}/discount/${discountCode}`), {params:{channel_id: 1}});
      return response.data;
    } catch (error) {}
  },
  deleteDiscount: async (discountId) => {
    try {
      const response = await clientApi
        .getClient()
        .delete(`discount/${discountId}`, { params: {channel_id: 1}});
      return response.data;
    } catch (error) {}
  },
};
