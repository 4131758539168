import { clientApi } from "./index";

export default {
  fetchUsers: async params => {
    const response = await clientApi.getClient().get("/users", { params:{ ...params }});
    return response.data;
  },
  createUser: async params => {
    const response = await clientApi.getClient().post("/users", { ...params, channel_id: 1 });
    return response.data;
  },
  fetchUser: async params => {
    const response = await clientApi.getClient().get(`/user/${params.id}`, {params: {channel_id: 1}});

    return response.data;
  },
  update: async (id, params) => {
    const response = await clientApi
      .getClient()
      .put(`/user/${id}`, {  ...params, channel_id: 1  });
    return response.data;
  },
  changeStatus: async (id, status, token = "") => {
    try {

      const response = await clientApi
        .getClient()
        .put(`/user/${id}/status`, { status, token });
      return response.data;
    } catch (error) {}
  },
  softdelete: async id => {
    try {
      const response = await clientApi
        .getClient()
        .delete(`/user/${id}/softdelete`, {params: {channel_id: 1}});
      return response.data;
    } catch (error) {}
  },
  changePasword: async (userId, params) => {
    const response = await clientApi
      .getClient()
      .put(`/user/password/${userId}`, { ...params, channel_id: 1 });
    return response.data;
  },
  changeLanguage: async (userId, params) => {
    const response = await clientApi
      .getClient()
      .put(`/user/language/${userId}`, { ...params, channel_id : 1});
    return response;
  },
  checkToken: async token => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/user/checktoken/${token}`, {params:{channel_id: 1}});
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  activateNewUser: async (id, params) => {
    const response = await clientApi
      .getClient()
      .put(`/user/activateNewUser/${id}`, { ...params });
    return response.data;
  }
};
