import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Divider, Grid, Header, Segment } from "semantic-ui-react";

import { capitalize } from "../../../../util/util";
import { updateRace } from "../../../../reducers/races";
import Form from "../../../../components/Form";
import { formFields } from "./util";
import racesApi from "../../../../apis/races";
import countriesApi from "../../../../apis/countries";
import provincesApi from "../../../../apis/regions";
import { DAlert } from "../../../../components/Dalert";
import ImagePicker from "../../../../components/ImagePicker";
import { getCountries } from "../../../../reducers/countries";

@withTranslation()
@connect(
  state => ({
    race: state.races.actualRace,
    countries: state.countries.countries
  }),
  dispatch => ({
    actions: bindActionCreators({ updateRace, getCountries }, dispatch)
  })
)
export default class BasicInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      raceTypes: [],
      countryCodes: [],
      provinces: [],
      showValidation: false,
      formData: {}
    };

    this.formHandler = this.formHandler.bind(this);
    this.headerImageChange = this.headerImageChange.bind(this);
    this.cardImageChange = this.cardImageChange.bind(this);
  }

  componentDidMount() {
    this.getProvinces();
    this.getCountryCodes();
    this.props.actions.getCountries(this.props.countries)
    this.getRaceTypes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.race?.ISFORMVALID !== this.props.race?.ISFORMVALID) {
      if (prevProps.race?.ISFORMVALID) {
        this.setState({ showValidation: !this.props.race.ISFORMVALID });
      }
    }
  }

  async getCountryCodes() {
    const countryCodes = await countriesApi.fetchCountryCodes();
    this.setState({ countryCodes });
  }

  async getProvinces() {
    provincesApi.fetchRegions().then(provinces => {
      this.setState({
        provinces: provinces.map(provinces => {return {name:provinces.name, slug:provinces.slug}})
      });
    });
  }

  async getRaceTypes() {
    const data = await racesApi.fetchRaceTypes();

    const raceTypes = data.map(type => ({
      text: `${type.type} (${type.code})`,
      value: type.id
    }));

    this.setState({ raceTypes });
  }

  formHandler(formData) {
    const newFormData = {
      ...formData,
      country: formData.country,
      region: formData.region,
      start_city: capitalize(formData.start_city),
      finish_date: formData.finish_date === "" ? null : formData.finish_date
    };

    this.props.actions.updateRace(newFormData);
  }

  cardImageChange(data) {
    this.props.actions.updateRace({
      card_image: data
    });
  }

  headerImageChange(data) {
    this.props.actions.updateRace({
      header_image: data
    });
  }

  render() {
    const { t, race, countries: countryCodes } = this.props;
    const { raceTypes, provinces, showValidation } = this.state;

    const isEventOrAssociate =
      race.activityType.type === "EVENT" ||
      race.activityType.type === "ASSOCIATES";
    return (
      <Segment basic padded>
        <Form
          defaultState={race}
          showValidation={showValidation}
          fields={formFields(t, raceTypes, countryCodes, provinces, isEventOrAssociate)}
          onFormChange={this.formHandler}
        />
        <br />
        <Divider horizontal>
          {t("raceEdit.basicTabContent.imagesSection")}
        </Divider>
        <Grid columns={2}>
          <Grid.Column mobile={16} computer={8}>
            <Header as="h5">{t("raceEdit.basicTabContent.cardImage", {dimensions:"250px x 100px"})}</Header>
            {!race.deleted && <ImagePicker
              content={t("commons.selectImage")}
              onSelect={data => this.cardImageChange(data)}
              imageSrc={race?.card_image_url}
              buttonProps={{
                fluid: true
              }}
            />}
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <Header as="h5">{t("raceEdit.basicTabContent.bannerImage", {dimensions:"700px x 100px"})}</Header>
            {!race.deleted && <ImagePicker
              content={t("commons.selectImage")}
              onSelect={data => this.headerImageChange(data)}
              imageSrc={race?.header_image_url}
              buttonProps={{
                fluid: true
              }}
            />}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}
