import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Container, Icon } from "semantic-ui-react";
import fileDownload from "js-file-download";
import moment from "moment";

import FluidResponsiveButton from "../../components/FluidResponsiveButton";
import FilterableTable from "../../components/FilterableTable";

import clientsApi from "../../apis/clients";
import { filterFields, tableHeaders } from "./util";

@withTranslation()
export default class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      params: [],
      fields: [],
      totalData: 0,
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getData();
    this.setState({ loading: false });
  }

  async getData(params) {
    const data = await clientsApi.get(params);
    this.setState({ data: data.rows, totalData: data.count });
  }

  async getCSV() {
    const { params, fields } = this.state;
    let paramss = { ...params, ...fields };
    delete params.pageNumber;
    delete params.pageSize;

    const file = await clientsApi.getCSV(paramss);
    fileDownload(file, "clients.csv");
  }

  render() {
    const { t } = this.props;
    const { data, totalData, loading } = this.state;

    return (
      <div>
        <Container>
          <FilterableTable
            filterFields={filterFields(t)}
            tableHeaders={tableHeaders(t)}
            contentData={data}
            parseData={(row) => ({
              date: moment(row.createdAt).format("L"),
            })}
            onRowClick={(data) =>
              this.props.history.push(`/client/${data.id}`, { data })
            }
            totalData={totalData}
            onChange={(table, filter) => this.getData({ ...table, ...filter })}
            loading={loading}
          />

          <FluidResponsiveButton
            id="DownloadCSVButton"
            onClick={() => this.getCSV()}
            icon
            labelPosition="left"
            size="small"
            primary
          >
            <Icon name="download" />
            {this.props.t("clientScreen.downloadCSV")}
          </FluidResponsiveButton>
        </Container>
      </div>
    );
  }
}
