import { combineReducers } from 'redux'
import * as Reducers from '.';

const exclude = ['index', 'initialState'];
let reducers = {};

//filter reducers
Object.keys(Reducers)
  .filter(reduce => !exclude.includes(reduce))
  .forEach(reduce => {
    reducers = { ...reducers, [reduce]: Reducers[reduce] }
  })

export default combineReducers({ ...reducers });