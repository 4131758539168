import { dateIsBetween, getDatesErrorMessage } from "../../../util/util";

let currentDateStart;
export const tableHeaders = t => {
  return [
    {
      content: t("pricesScreen.table.name"),
      accessor: "name"
    },
    {
      content: t("pricesScreen.table.dateStart"),
      accessor: "dateStart"
    },
    {
      content: t("pricesScreen.table.dateFinish"),
      accessor: "dateFinish"
    },
    {
      content: t("pricesScreen.table.amount"),
      accessor: "amount",
      props: {
        textAlign: "right"
      }
    },
    {
      content: t("pricesScreen.table.maxAthletes"),
      accessor: "maxAthletes"
    }
  ];
};

export const formFields = (t, modality, disabled) => [

  {
    fields: [
      {
        name: "name",
        title: t("pricesScreen.table.name"),
        placeHolder: t("pricesScreen.table.name"),
        colSize: 10,
        type: "Input",
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired")
        },
        props:{
          disabled
        }
      },
      {
        name: "dateStart",
        title: t("pricesScreen.table.dateStart"),
        placeHolder: t("pricesScreen.table.dateStart"),
        colSize: 4,
        type: "DatePicker",
        validation: {
          required: true,
          custom: vals => {
            const startRaceDate = moment(modality.start_date);
            const dateStartPrice = moment(vals.dateStart);
            return startRaceDate > dateStartPrice;
          },
          errorMessage: t("racesScreen.startDateLowerThanRaceDate", {
            startDate: moment(modality.start_date).format("LL")
          })
        },
        props: {
          id: 'dateStart',
          disabled
        }
      },
      {
        name: "timeStart",
        title: t("dateTimePicker.hour"),
        colSize: 2,
        type: "Input",
        deflt: '00:00',
        props: {
          type: "time",
          id: 'timeStart',
          step: 15 * 60,
          disabled
        }
      },
    ]
  },
  {
    fields: [
      {
        name: "amount",
        title: t("pricesScreen.table.amount"),
        placeHolder: t("pricesScreen.table.amount"),
        colSize: 5,
        type: "Input",
        validation: {
          required: true,
          errorMessage: t("commons.INVALID_DOUBLE"),
          regexType: "double"
        },
        props:{
          disabled
        }
      },
      {
        name: "maxAthletes",
        title: t("pricesScreen.table.maxAthletes"),
        placeHolder: t("pricesScreen.table.maxAthletes"),
        colSize: 5,
        type: "Input",
        validation: {
          regexType: "number",
          errorMessage: t("commons.INVALID_NUMBER")
        },
        props:{
          disabled
        }
      },
      {
        name: "dateFinish",
        title: t("pricesScreen.table.dateFinish"),
        placeHolder: t("pricesScreen.table.dateFinish"),
        colSize: 4,
        type: "DatePicker",
        validation: {
          required: true,
          custom: vals => {
            var patt = new RegExp("Invalid Date");
            if (vals.dateStart && !patt.test(vals.dateStart)) {
              const startDate = moment(vals.dateStart);
              const finishDate = moment(modality.start_date);
              const valid = dateIsBetween(
                startDate,
                finishDate,
                vals.dateFinish
              );
              return {
                valid: valid,
                errorMessage: getDatesErrorMessage(
                  t,
                  startDate,
                  finishDate,
                  "LL"
                )
              };
            } else {
              return {
                valid: false,
                errorMessage: t("raceEdit.fieldEmpty", {
                  field: t("pricesScreen.table.dateStart")
                })
              };
            }
          }
        },
        props: {
          id: 'dateFinish',
          disabled
        }
      },
      {
        name: "timeEnd",
        title: t("dateTimePicker.hour"),
        colSize: 2,
        deflt: '00:00',
        type: "Input",
        props: {
          type: "time",
          id: 'timeEnd',
          step: 15 * 60,
          disabled
        }
      },
    ]
  }
];


const _getDateAndTime = (date, time) => {
  try {
    date = date.slice(0, 10)

    return moment(date + ' ' + time)
  } catch (error) {}
  return date
}

export const checkDates = (config, modality, t) => {

  let { dateStart, dateFinish, timeStart, timeEnd } = config
  const { start_date, time_start } = modality

  const start = _getDateAndTime(dateStart, timeStart)
  const end = _getDateAndTime(dateFinish, timeEnd)
  const modalityDate = _getDateAndTime(start_date, time_start)

  let valid = true
  let message = "";
  if (end.isBefore(start)) {
    valid = false;
    message = t("modalityConfiguration.finishDateError1", { startConfigDate: start.format('LLL') })
  } else if (modalityDate.isBefore(end)) {
    valid = false
    message = t("modalityConfiguration.finishDateError2", { startModalityDate: modalityDate.format('LLL') })
  }

  return { valid, message };
}