import moment from 'moment';

window.moment = moment;

export const getTableHeaders = (t, role, selection) => {
  if (selection === 'list') {
    if (role === 'ADMINISTRATOR') {
      return [
        {
          content: t('notifications.table.date'),
          accessor: 'createdAt'
        },
        {
          content: t('notifications.table.name'),
          accessor: 'race_id'
        },
        {
          content: t('notifications.table.company'),
          accessor: 'company_id'
        },
        {
          content: t('notifications.table.template'),
          accessor: 'template_id'
        },
        {
          content: t('notifications.table.status'),
          accessor: 'status'
        },
        {
          content: t('notifications.table.type'),
          accessor: 'notification_type'
        },
        {
          content: '',
          accessor: 'edit',
          noSort: true
        }
      ];
    } else {
      return [
        {
          content: t('notifications.table.date'),
          accessor: 'createdAt'
        },
        {
          content: t('notifications.table.name'),
          accessor: 'race_id'
        },
        {
          content: t('notifications.table.template'),
          accessor: 'template_id'
        },
        {
          content: t('notifications.table.status'),
          accessor: 'status'
        },
        {
          content: t('notifications.table.type'),
          accessor: 'notification_type'
        },
        {
          content: '',
          accessor: 'edit',
          noSort: true
        }
      ];
    }
  } else {
    if (role === 'ADMINISTRATOR') {
      return [
        {
          content: t('notifications.table.template'),
          accessor: 'name'
        },
        {
          content: t('notifications.table.company'),
          accessor: 'company_id'
        },
        {
          content: t('notifications.table.type'),
          accessor: 'template_type'
        }
      ];
    } else {
      return [
        {
          content: t('notifications.table.template'),
          accessor: 'name'
        }
      ];
    }
  }
};

export const getFilterFields = (
  t,
  companies,
  role,
  selection,
  status,
  racesList,
  templatesList
) => {
  if (selection === 'list') {
    if (role !== 'ADMINISTRATOR') {
      return [
        {
          div: 'row',
          fields: [
            {
              name: 'from',
              //placeHolder: t("notifications.table.date"),
              type: 'DatePicker',
              props: {
                id: 'NotificationsFilterDate'
              }
            },
            {
              name: 'raceId',
              placeHolder: t('notificationScreen.fields.race'),
              type: 'Select',
              data: racesList.map(race => ({
                text: race.text,
                value: race.value
              })),
              colSize: 6,
              props: {
                clearable: true
              }
            },
            {
              name: 'templateId',
              placeHolder: t('notifications.table.template'),
              type: 'Select',
              data: templatesList,
              colSize: 6,
              props: {
                clearable: true
              }
            },
            {
              id: 'NotificationsFilterStatus',
              name: 'status',
              data: [
                {
                  text: t('notifications.table.PENDING'),
                  value: 'PENDING'
                },
                {
                  text: t('notifications.table.CANCELLED'),
                  value: 'CANCELLED'
                },
                {
                  text: t('notifications.table.CREATED'),
                  value: 'CREATED'
                },
                {
                  text: t('notifications.table.SENT'),
                  value: 'SENT'
                }
              ],
              placeHolder: t('notifications.table.status'),
              type: 'Select',
              props: {
                id: 'NotificationsFilterStatus'
              }
            }
          ]
        },
        {
          div: 'row',
          fields: []
        }
      ];
    } else {
      return [
        {
          div: 'row',
          fields: [
            {
              name: 'from',
              //placeHolder: t("notifications.table.date"),
              type: 'DatePicker',
              props: {
                id: 'NotificationsFilterDate'
              }
            },
            {
              name: 'companyId',
              placeHolder: t('notifications.table.company'),
              type: 'Select',
              data: companies,
              props: {
                id: 'NotificationsFilterCompany'
              }
            },
            {
              name: 'raceId',
              placeHolder: t('notificationScreen.fields.race'),
              type: 'Select',
              data: racesList.map(race => ({
                text: race.text,
                value: race.value
              })),
              colSize: 6,
              props: {
                clearable: true
              }
            },
            {
              name: 'templateId',
              placeHolder: t('notifications.table.template'),
              type: 'Select',
              data: templatesList,
              colSize: 6,
              props: {
                clearable: true
              }
            },
            {
              id: 'NotificationsFilterStatus',
              name: 'status',
              data: [
                {
                  text: t('notifications.table.PENDING'),
                  value: 'PENDING'
                },
                {
                  text: t('notifications.table.CANCELLED'),
                  value: 'CANCELLED'
                },
                {
                  text: t('notifications.table.CREATED'),
                  value: 'CREATED'
                },
                {
                  text: t('notifications.table.SENT'),
                  value: 'SENT'
                }
              ],
              placeHolder: t('notifications.table.status'),
              type: 'Select',
              props: {
                id: 'NotificationsFilterStatus'
              }
            }
          ]
        },
        {
          div: 'row',
          fields: []
        }
      ];
    }
  } else {
    if (role !== 'ADMINISTRATOR') {
      return [
        {
          div: 'row',
          fields: [
            {
              id: 'NotificationsFilterName',
              name: 'name',
              placeHolder: t('notifications.table.template'),
              type: 'Input',
              props: {
                id: 'NotificationsFilterName'
              }
            }
          ]
        },
        {
          div: 'row',
          fields: []
        }
      ];
    } else {
      return [
        {
          div: 'row',
          fields: [
            {
              id: 'NotificationsFilterName',
              name: 'name',
              placeHolder: t('notifications.table.template'),
              type: 'Input',
              props: {
                id: 'NotificationsFilterName',
                clearable: true
              }
            },
            {
              name: 'companyId',
              placeHolder: t('notifications.table.company'),
              type: 'Select',
              data: companies,
              props: {
                id: 'NotificationsFilterCompany'
              }
            }
          ]
        },
        {
          div: 'row',
          fields: []
        }
      ];
    }
  }
};
