export const tableHeaders = (t, showNotFinished) => [
  {
    content: t("modalityInscriptions.table.inscriptionDate"),
    accessor: "inscriptionDate",
    props: { style: { wordBreak: "break-word" } },
  },
  {
    content: t("modalityInscriptions.table.athleteDni"),
    accessor: "athleteDni",
    props: { style: { wordBreak: "break-word" } },
  },
  {
    content: t("modalityInscriptions.table.athleteFullName"),
    accessor: "athleteFullName",
    headerProps: {
      width: 3,
    },
    props: { style: { wordBreak: "break-word" } },
  },
  {
    content: t("modalityInscriptions.table.athleteEmail"),
    accessor: "athleteEmail",
    headerProps: {
      width: 3,
    },
    props: { style: { wordBreak: "break-word" } },
  },
  // {
  //   content: t("modalityInscriptions.table.inscriptionStatus"),
  //   accessor: "inscriptionStatus"
  // },
  {
    content: t("modalityInscriptions.table.inscriptionStatus"),
    accessor: "combinedStatus",
    props: { style: { wordBreak: "break-word" } },
  },
  {
    content: t("modalityInscriptions.table.locator"),
    accessor: "athleteLocator",
    headerProps: {
      width: 1,
    },
    props: { style: { wordBreak: "break-word" } },
  },
  {
    content: t("modalityInscriptions.table.operationCode"),
    accessor: "paymentOperation_code",
    props: { style: { wordBreak: "break-word" } },
  },
  {
    content: t("modalityInscriptions.table.origin"),
    accessor: "inscriptionFrom",
    headerProps: {
      width: 2,
    },
    props: { style: { wordBreak: "break-word" } },
  },
  ...(showNotFinished
    ? [
        {
          content: "",
          accessor: "inscriptionType",
          headerProps: {
            width: 1,
          },
        },
      ]
    : []),
];
