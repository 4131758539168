import React, { Component } from "react";
import Lottie from "react-lottie";
import ErrorAnimation from "./error.json";
import "./style.css";
import { withTranslation } from "react-i18next";
@withTranslation()
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // console.log(error, info);

    // logErrorToMyService(error, info);
  }

  goBack() {
    window.history.back();

    document.location.href = String(document.location.href).replace(/\#.+/, "");
  }

  goHome() {
    document.location.href = "/home";
  }

  render() {
    if (this.state.hasError) {
      const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: ErrorAnimation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      const { t } = this.props;
      // You can render any custom fallback UI
      return (
        <div>
          <Lottie options={defaultOptions} height={700} width={700} />
          <div className="error-boundary">
            <div className="error-boundary-text-container">
              <p>{t("commons.someWrong")}</p>
              <div>
                <a onClick={this.goBack}>{t("commons.goBack")}</a>
                {` ${t("commons.or")} `}
                <a onClick={this.goHome}>{t("commons.goHome")}</a>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
