import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Container, Icon, Grid, Dimmer, Loader, Checkbox } from "semantic-ui-react";
import moment from "moment";
import fileDownload from "js-file-download";

import { tableHeaders } from "./util";
import modalityApi from "../../../apis/modalities";
import Table from "../../../components/DTable";
import ModalitySubtitle from "../../../components/ModalitySubtitle";
import FluidResponsiveButton from "../../../components/FluidResponsiveButton";
import { DAlert } from "../../../components/Dalert";
import AssignPlaceButton from "../../../components/AssignPlaceButton";
import dictionary from "../../../locales/default.en";

@withTranslation()
@connect(state => ({
  race: state.races.actualRace
}))
export default class ModalityInscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      totalData: 0,
      inscriptionStatus: "NOT_STARTED_YET",
      loading: false,
      showNotFinished: false,
      params: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData(params) {
    const { id, t } = this.props;

    const response = await modalityApi.fetchInscriptions(id, {
      ...(params || this.state.params),
      showNotFinished: this.state.showNotFinished,
    });

    const inscriptionStatus = await modalityApi.getModalityInscriptionStatus(
      id
    );

    this.setState({
      data: response.rows,
      totalData: response.count,
      inscriptionStatus,
      params: params || this.state.params
    });

    if (inscriptionStatus === "FULL_RACE") {
      DAlert.warning({ message: t(`inscriptionStatus.${inscriptionStatus}`) });
    }
  }

  render() {
    const { t, history, raceId, id, race } = this.props;
    const { data, totalData, inscriptionStatus, loading, showNotFinished } = this.state;

    return (
      <Container>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ModalitySubtitle
            icon="pin"
            title="raceDetail.menu.modality.inscriptions"
          />
          <Checkbox
            toggle
            label={t("inscriptionsScreen.filter.showNotFinished")}
            onChange={(_, { checked }) => {
              this.setState({ showNotFinished: checked }, () => {
                this.getData()
              })
            }}
          />
        </div>
        <Table
          headers={tableHeaders(t, showNotFinished)}
          contentData={data}
          totalData={totalData}
          onTableChange={filter => this.getData(filter)}
          onRowClick={(data) =>
            history.push(
              `/race/${data.raceId}/inscription/${data.clientInscriptionId}?f=${
                data.statusId !== 1 ? true : false
              }`
            )
          }
          parseData={data => ({
            inscriptionDate: moment(data.inscriptionDate).format("L"),
            // inscriptionStatus: t(`paymentStatus.${data.inscriptionStatus}`),
            combinedStatus: data.combinedStatus ? t(`paymentStatus.${data.combinedStatus}`) : '',
            paymentOperation_code:
              data.paymentOperation_code &&
              `${data.paymentOperation_code} ${data.paymentAuthCode &&
              `(${data.paymentAuthCode})`}`,
            inscriptionType:
              data.statusId !== 1 ? null : data.athleteStatus ===
                "CANCELLED" ? (
                t("inscriptionsScreen.notFinished")
              ) : (
                <AssignPlaceButton inscriptionId={data.clientInscriptionId} asIcon />
              ),
            inscriptionFrom:
              data.inscriptionFrom &&
              Object.keys(dictionary.commons.origin).includes(data.inscriptionFrom )
                ? t(`commons.origin.${data.inscriptionFrom}`)
                : t(`commons.origin.UNKNOWN`),
          })}
          rowProps={data =>
            data.athleteStatus
              ? { negative: true, className: "strikethrough" }
              : {}
          }
          fixed
        />

        <Grid>
          <Grid.Row>
            <Grid.Column width="16">
              {!race.deleted && <FluidResponsiveButton
                icon
                labelPosition="left"
                size="small"
                primary
                floated="left"
                disabled={
                  !(
                    inscriptionStatus === "CLOSED_ENABLED" ||
                    inscriptionStatus === "ENABLED" ||
                    inscriptionStatus === "FULL_RACE"
                  )
                }
                onClick={async () => {
                  await history.push(
                    `/race/${raceId}/modality/${id}/inscription/create`
                  );
                }}
              >
                <Icon name="flag" />
                {inscriptionStatus === "ENABLED" ||
                  inscriptionStatus === "FULL_RACE"
                  ? t("inscriptionsScreen.inscriptionButton")
                  : t(`inscriptionStatus.${inscriptionStatus}`)}
              </FluidResponsiveButton>}
              <FluidResponsiveButton
                icon
                labelPosition="left"
                size="small"
                primary
                disabled={totalData === 0}
                floated="right"
                onClick={async () => {
                  this.setState({ loading: true })
                  const res = await modalityApi.fetchInscriptionsCsv(id, { language: this.props.i18n.language.toUpperCase() });
                  if (res && res.status == 200) {
                    const file = res.data
                    fileDownload("\uFEFF" + file, 'inscriptions.csv', 'text/csv');
                  }
                  else {
                    DAlert.error({
                      title: t("commons.serverError"),
                      message: t("commons.serverErrorMessage")
                    });
                  }
                  this.setState({ loading: false })
                }}
              >
                <Icon name="file alternate outline" />
                {t("inscriptionsScreen.downloadCsvButton")}
              </FluidResponsiveButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {loading ? (
          // <Dimmer active style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          <Dimmer active>
            <Loader />
          </Dimmer>
        ) : null}
      </Container>
    );
  }
}
