export const gendersList = ["BOTH", "MALE", "FEMALE"];
export const ageTypesList = ["AGE", "YEAR"];
export const modalityAttributeTypeslist = [
  "STRING",
  "NUMBER",
  "BOOLEAN",
  "SELECT",
  "PRODUCT"
];

export const status = t => [
  { text: t("status.ACTIVE"), value: "ACTIVE" },
  { text: t("status.BLOCKED"), value: "BLOCKED" },
  { text: t("status.NOT_CONFIRMED"), value: "NOT_CONFIRMED" },
  { text: t("status.DELETED"), value: "DELETED" }
];
export const autotexts = t => [
  { text: t("notificationScreen.data.autotext.surnames"), value: "%recipient.surname%" },
  { text: t("notificationScreen.data.autotext.club"), value: "%recipient.club%" },
  { text: t("notificationScreen.data.autotext.dni"), value: "%recipient.dni%" },
  { text: t("notificationScreen.data.autotext.birthDate"), value: "%recipient.birthday%" },
  { text: t("notificationScreen.data.autotext.locator"), value: "%recipient.locator%" },
  { text: t("notificationScreen.data.autotext.name"), value: "%recipient.name%" },
  { text: t("notificationScreen.data.autotext.nameSurnames"), value: "%recipient.fullName%" },
];

export const deliverStatus = t => [
  { text: t("notificationScreen.data.deliverStatus.accepted"), value: "accepted" },
  { text: t("notificationScreen.data.deliverStatus.rejected"), value: "rejected" },
  { text: t("notificationScreen.data.deliverStatus.delivered"), value: "delivered" },
  { text: t("notificationScreen.data.deliverStatus.failed"), value: "failed" },
  { text: t("notificationScreen.data.deliverStatus.opened"), value: "opened" },
  { text: t("notificationScreen.data.deliverStatus.clicked"), value: "clicked" },
  { text: t("notificationScreen.data.deliverStatus.complained"), value: "complained" },
  { text: t("notificationScreen.data.deliverStatus.stored"), value: "stored" },
  { text: t("notificationScreen.data.deliverStatus.unsubscribed"), value: "unsubscribed" },
];

export const roles = {
  ADMINISTRATOR: 1,
  COMPANY: 2,
  PLANNER: 3,
  POINT_OF_SALE: 4,
  ATHLETE: 5
};

export const genders = t =>
  gendersList.map(gender => ({
    text: t(`commons.gender.${gender}`),
    value: gender
  }));

export const ageTypes = t =>
  ageTypesList.map(ageType => ({
    text: t(`commons.ageType.${ageType}`),
    value: ageType
  }));

export const modalityAttributeTypes = t =>
  modalityAttributeTypeslist.map(attrType => ({
    text: t(`modalityAttributes.types.${attrType}`),
    value: attrType
  }));
