"use strict";
import React, { Component } from "react";
import moment from "moment";
import { FormGroup } from "semantic-ui-react";
import FormComponents from "../FormComponents";

export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privateState: {},
      fieldsState: {},
      oldState: {},
      render: false,
    };

    this.onFieldsChange = this.onFieldsChange.bind(this);
  }

  onFieldsChange(field, val, props) {
    const { privateState, fieldsState, oldState } = this.state;

    let value = val;

    if (props.returnOnlyValue) {
      const getValue =
        typeof props.getOptionValue == "string"
          ? props.getOptionValue
          : "value";

      if (val) {
        value = val[getValue];
      }
      // newState = { ...fieldsState, [field]: val[getValue], ISFORMVALID }
    }

    let newState = {
      ...this.state,
      privateState: {
        ...privateState,
        [field]: val,
      },
      fieldsState: {
        ...fieldsState,
        [field]: value,
      },
      oldState: {
        ...privateState,
      },
      render: true,
    };

    //remove object from state if is null or empty
    if (
      Object.keys(newState.fieldsState).includes(field) &&
      (newState.fieldsState[field] == null || newState.fieldsState[field] == "")
    ) {
      delete newState.fieldsState[field];
    }

    this.setState(newState);

    if (this.props.useOnChange) {
      this.props.onChange(newState.fieldsState);
    } else {
      if (props.type == "Input") {
        return;
      }
      this.props.onFind(newState.fieldsState);
    }
  }

  getDataDependsOn(typeField) {
    if (typeField.dataDependsOn) {
      const data = get_(state.privateState, typeField.dataDependsOn);
      return data ? data : [];
    }
  }

  getValue(typeField) {
    const { privateState, oldState, fieldsState } = this.state;

    if (typeField.dataDependsOn) {
      //compare if the dataDependsOn change and if change return null or not
      const fieldDepends = typeField.dataDependsOn.split(".")[0];
      const current = get_(privateState, fieldDepends);
      const old = get_(oldState, fieldDepends);
      if (JSON.stringify(current) !== JSON.stringify(old)) {
        //prevent do this, just in extrme cases;
        privateState[typeField.name] = null;
        delete fieldsState[typeField.name];
        //devil don't do this
        return null;
      } else {
        return privateState[typeField.name];
      }
    } else {
      return privateState[typeField.name];
    }
  }

  generateValues() {
    //create the default state
    let state = {};
    this.props.fields.forEach((field) => {
      field.fields.forEach((typeField) => {
        state = {
          ...state,
          [typeField.name]: typeField.value == null ? "" : typeField.value,
        };
      });
    });
    return false;
  }

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(this.props.fields) !== JSON.stringify(nextProps.fields)
    ) {
      return true;
    }

    if (JSON.stringify(nextProps.state) !== JSON.stringify(this.props.state)) {
      return nextProps.state.render;
    }

    return true;
  }

  render() {
    return (
      <div className={this.props.className} style={{ paddingTop: 30 }}>
        {this.props.fields.map((field, key) => (
          <FormGroup className="resize-group" widths="equal" key={key}>
            {field.fields.map((typeField, key2) => {
              return (
                <GetComponent
                  onFieldsChange={this.onFieldsChange}
                  key={key.toString() + key2.toString()}
                  data={this.getDataDependsOn(typeField)}
                  onFind={() => this.props.onFind(this.state.fieldsState)}
                  {...typeField}
                  value={this.getValue(typeField)}
                />
              );
            })}
          </FormGroup>
        ))}
      </div>
    );
  }
}

Filter.defaultProps = {
  fields: [],
  onFind: (e) => {},
  onFindClear: () => {},
  defaultState: {},
  parseState: {},
  onChange: () => {},
  useOnChange: false,
  className: "ui form segment",
};

const GetComponent = (props) => {
  const Elm = FormComponents[props.type];

  if (props.type === "Input") {
    let newProps = {
      ...props,
      props: {
        ...props.props,
        onKeyPress: (e) => {
          if (e.key === "Enter") {
            props.onFind();
          }
        },
      },
    };

    return (
      <Elm
        // colSize={3}
        {...newProps}
        onChange={(val) =>
          newProps.onFieldsChange(newProps.name, val, newProps)
        }
      />
    );
  }

  return (
    <Elm
      // colSize={3}
      {...props}
      onChange={(val) => props.onFieldsChange(props.name, val, props)}
    />
  );
};
