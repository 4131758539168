import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Loader,
  Radio,
  Icon,
  Modal,
  Button,
  Grid,
  Message,
} from "semantic-ui-react";
import moment from "moment";

import FilterableTable from "../../components/FilterableTable";
import FluidResponsiveButton from "../../components/FluidResponsiveButton";
import Form from "../../components/Form";
import { modalHandler } from "../../components/DModal";
import AsyncButton from "../../components/AsyncButton";

import qrReaders from "../../apis/qrReaders";
import { filterFields, tableHeaders, modalField, rowDetails } from "./util";

const QRReaders = () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [totalData, setTotalData] = useState();
  const [error, setError] = useState();

  const [showValidation, setShowValidation] = useState(false);
  const [emailToCreate, setEmailToCreate] = useState();
  const [filters, setFilters] = useState();

  const [userEnabling, setUserEnabling] = useState();
  const [loadingEnabling, setLoadingEnabling] = useState(false);

  const getData = async (params) => {
    setLoading(true);
    setFilters(params);
    const data = await qrReaders.get(params);
    setData(data.rows.map((user) => ({ ...user, email: user.user.email })));
    setTotalData(data.count);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    modalHandler.refreshOptions(openCreateUserModalOptions);
  }, [emailToCreate, showValidation, error]);

  const updateUser = async (id, enabled) => {
    setUserEnabling(id);
    setLoadingEnabling(true);
    await qrReaders.changeStatus(id, enabled);
    await getData(filters);
    setLoadingEnabling(false);
  };

  const createUser = async () => {
    if (!emailToCreate) {
      setError("EMPTY_FIELDS")
      setShowValidation(true);
      return;
    }

    const data = await qrReaders.create(emailToCreate);

    if (data.ok !== undefined && !data.ok) {
      setError(data.error.message);
    } else {
      modalHandler.close();
      await getData(filters);
      modalHandler.open(afterUserCreation);
    }
  };

  const afterUserCreation = {
    header: t(`usersScreen.modal.createdSuccess`),
    content: (
      <Modal.Description>
        {t(`usersScreen.modal.createdSuccessMessage`)}
      </Modal.Description>
    ),
    actions: (
      <Modal.Actions>
        <Button
          icon="check"
          color={"instagram"}
          content={t(`usersScreen.modal.createdSuccessButton`)}
          onClick={() => modalHandler.close()}
        />
      </Modal.Actions>
    ),
  };

  const openCreateUserModalOptions = {
    header: t("qrReadersScreen.createUser"),
    content: (
      <>
        <Form
          fields={modalField(t)}
          showValidation={showValidation}
          onFormChange={(data) => {
            if (data.ISFORMVALID) {
              setEmailToCreate(data.email);
            }
          }}
        />
        {error && (
          <Grid.Row>
            <Grid.Column width="16">
              <Message
                id="CreateUserError"
                error
                header={t(`usersScreen.modal.messages.${error}.header`)}
                content={t(`usersScreen.modal.messages.${error}.message`)}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </>
    ),
    actions: (
      <>
        <Button color="google plus" onClick={() => modalHandler.close()}>
          {t("commons.cancel")}
        </Button>
        <AsyncButton color="instagram" onClick={createUser}>
          {t("qrReadersScreen.createUser")}
        </AsyncButton>
      </>
    ),
  };

  const resendEmail = async (data) => {
    const response = await qrReaders.create(data.email);

    if (response.ok !== undefined && !response.ok) {
      modalHandler.close();
    } else {
      modalHandler.open({
        header: t("usersScreen.messages.emailResend"),
        content: t("usersScreen.messages.aNewMail"),
        actions: () => <></>,
      });
    }
  };

  return (
    <Container>
      <FilterableTable
        id={"QrReaders"}
        filterFields={filterFields(t)}
        tableHeaders={tableHeaders(t)}
        contentData={data}
        parseData={({ createdAt, id, enabled }) => ({
          createdAt: moment(createdAt).format("L"),
          enabled:
            loadingEnabling && id === userEnabling ? (
              <Loader active inline="centered" />
            ) : (
              <Radio
                toggle
                checked={enabled}
                onChange={async (_, { checked }) =>
                  await updateUser(id, checked)
                }
              />
            ),
        })}
        onRowClick={(data, _, e) => {
          let element = e.target;
          if (element.tagName === "TD") {
            modalHandler.open({
              header:
                data.name && data.surnames
                  ? `${data.name} ${data.surnames}`
                  : data.email,
              content: (
                <div>
                  {data.user.status === "NOT_CONFIRMED" && (
                    <AsyncButton
                      primary
                      onClick={async () => await resendEmail(data)}
                      style={{ marginBottom: 10 }}
                    >
                      {t("usersScreen.resendEmail")}
                    </AsyncButton>
                  )}
                  <Form
                    defaultState={{
                      ...data,
                      createdAt: moment(data.createdAt).format("L"),
                      updatedAt: moment(data.updatedAt).format("L"),
                      status: t(`status.${data.user.status}`),
                    }}
                    fields={rowDetails(t)}
                  />
                </div>
              ),
              actions: () => <></>,
            });
          }
        }}
        onChange={(table, filter) => getData({ ...table, ...filter })}
        totalData={totalData}
        loading={loading}
      />
      <FluidResponsiveButton
        id="CreateQRReaderButton"
        icon
        labelPosition="left"
        size="small"
        primary
        onClick={() => modalHandler.open(openCreateUserModalOptions)}
      >
        <Icon name="qrcode" />
        {t("qrReadersScreen.createUser")}
      </FluidResponsiveButton>
    </Container>
  );
};

export default QRReaders;
