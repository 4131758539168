import { clientApi } from ".";

export default {
  fetchPlannersMin: async () => {
    try {
      const response = await clientApi.getClient().get(`/planners/combos`, {params: {channel_id: 1}});
      return response.data;
    } catch (err) { }
  },

  updateEnabled: async (params) => {
    try {
      const response = await clientApi.getClient().put(`/planner_race_update`, { ...params, channel_id: 1 });
      return response.data;
    } catch (err) { }
  },

  fetchPlannersInRace: async (id) => {
    try {
      const response = await clientApi.getClient().get(`/planner_race/${id}`, {params: {channel_id: 1}});
      return response.data;
    } catch (err) { }
  },

  linkPlannerToRace: async params => {
    try {
      const response = await clientApi.getClient().post("/planner_race", { ...params, channel_id: 1 });
      return response;
    } catch (err) { }
  }
};