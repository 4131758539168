import { clientApi } from ".";

export default {
  fetchConfig: async () => {
    try {
      const response = await clientApi.getClient().get("/app_config", {params: {channel_id: 1}});
      return response.data;
    } catch (err) {}
  },
  update: async (params) => {
    const response = await clientApi
      .getClient()
      .put(`/app_config`, { ...params, channel_id: 1 });
    return response;
  },
  updateTermsAndConditions: async (params) => {
    const response = await clientApi
      .getClient()
      .put(`/app_config/Terms_and_conditions`, { ...params, channel_id: 1 });
    return response;
  },
  getTermsAndConditions: async (params) => {
    const response = await clientApi
      .getClient()
      .get(`/app_config/Terms_and_conditions`, { params, channel_id: 1 });
    return response;
  }
};