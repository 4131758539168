import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Container,
  Modal,
  Grid,
  Button,
  ButtonGroup,
  Message
} from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";

import CreatePriceButton from "./CreatePriceButton";
import { modalHandler } from "../../../components/DModal";
import ModalitySubtitle from "../../../components/ModalitySubtitle";
import { DAlert } from "../../../components/Dalert";
import Table from "../../../components/DTable";

import pricesApi from "../../../apis/prices";
import { tableHeaders, formFields, checkDates } from "./util";
import Form from "../../../components/Form";

@withTranslation()
@connect(state => ({
  race: state.races.actualRace,
  modality: state.modalities.actualModality
}))
export default class ModalityPrices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
      editPriceForm: {},
      error: null,
      showValidation: false,
      id: null
    };
  }

  componentDidMount = async () => {
    await this.getData();
  };

  async getData(params) {
    const data = await pricesApi.getByModality({ id: this.props.id, params });
    this.setState({ data: data.rows, totalData: data.count });
  }

  getDate = date => (date ? moment(date).format("LL") : null);

  getMaxAthletes = (t, maxAthletes) =>
    maxAthletes ? maxAthletes : t("pricesScreen.table.unlimited");

  modalOptions = data => {
    const { t, race } = this.props;
    const { error, showValidation } = this.state;
    const { dateStart, dateFinish } = data

    //remove time from data
    data = {
      ...data,
      dateStart: moment(dateStart).format('YYYY-MM-DD'),
      dateFinish: moment(dateFinish).format('YYYY-MM-DD')
    }

    return {
      header: t("pricesScreen.edit"),
      size: "large",
      content: (
        <Modal.Description>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width="16">
                <Form
                  showValidation={showValidation}
                  defaultState={data}
                  fields={formFields(t, this.props.modality, race.deleted)}
                  onFormChange={data => {
                    this.setState({ editPriceForm: data }, () => {
                      modalHandler.refreshOptions({
                        ...this.modalOptions(data)
                      });
                    });
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            {error && (
              <Grid.Row>
                <Grid.Column width="16">
                  <Message error content={error} />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Modal.Description>
      ),
      actions: (
        <Grid>
          <Grid.Column width="16">
            {!race.deleted && <Button
              floated="left"
              color="red"
              icon="trash alternate outline"
              content={t("commons.delete")}
              onClick={async () => {
                await this.deletePrice(data);
              }}
            />}
            <ButtonGroup floated="right">
              <Button color="google plus" onClick={() => modalHandler.close()}>
                {t("commons.cancel")}
              </Button>
            {!race.deleted && <Button
                primary
                onClick={async () => {
                  await this.updatePrice(data);
                }}
              >
                {t("commons.save")}
              </Button>}
            </ButtonGroup>
          </Grid.Column>
        </Grid>
      )
    };
  };

  deletePrice = async priceData => {
    const { t } = this.props;

    modalHandler.open({
      header: null,
      content: <Modal.Description>{t("commons.areYouSure")}</Modal.Description>,
      basic: false,
      size: "tiny",
      onYes: async () => {
        const response = await pricesApi.delete(this.state.id);
        if (typeof response === "undefined") {
          this.setState({ error: t("pricesScreen.cannotDelete") }, () => {
            modalHandler.refreshOptions({
              ...this.modalOptions(priceData)
            });
          });
        } else {
          modalHandler.close();
          await this.getData();
        }
      }
    });
  };

  updatePrice = async priceData => {
    const { t } = this.props;
    const { editPriceForm } = this.state;

    if (!editPriceForm.ISFORMVALID) {
      this.setState({ showValidation: true });
      return;
    }

    const areValidDates = await checkDates(editPriceForm, this.props.modality, t);
    if (!areValidDates.valid) {
      DAlert.errorPlus({
        message: areValidDates.message
      });
      return;
    }

    const maxAthletes =
      editPriceForm.maxAthletes == "" ? null : editPriceForm.maxAthletes;

    //check if price is upper default?
    
    const formToSend = {
      ...editPriceForm,
      maxAthletes: maxAthletes ? +maxAthletes : maxAthletes,
      dateFinish:
        editPriceForm.dateFinish === "" ? null : editPriceForm.dateFinish,
      amount:
        typeof editPriceForm.amount === "string"
          ? parseFloat(editPriceForm.amount.replace(",", "."))
          : parseFloat(editPriceForm.amount)
    };

    await pricesApi
      .updatePrice(this.state.id, formToSend)
      .then(async response => {
        if (typeof response === "undefined") {
          DAlert.error({ title: t("commons.errorSaving") });
          this.setState({ error: t("pricesScreen.cannotUpdate") }, () => {
            modalHandler.refreshOptions({
              ...this.modalOptions(priceData)
            });
          });
        } else {
          DAlert.success({ title: t("commons.savedSuccess") });
          modalHandler.close();
          await this.getData();
        }
      });
  };

  render() {
    const { t, id, race } = this.props;

    const { data, totalData } = this.state;

    return (
      <Container>
        <ModalitySubtitle
          icon="money"
          title="raceDetail.menu.modality.prices"
        />
        <Table
          headers={tableHeaders(t)}
          contentData={data}
          totalData={totalData}
          onTableChange={table => this.getData({ ...table })}
          onRowClick={data => {
            this.setState({ id: data.id });
            this.setState({ error: null, showValidation: false }, () => {
              modalHandler.open(this.modalOptions(data));
            });
          }}
          parseData={row => ({
            dateStart: `${this.getDate(row.dateStart)} ${row.timeStart}`,
            dateFinish: `${this.getDate(row.dateFinish)} ${row.timeEnd}`,
            amount: `${row.amount.toFixed(2)} €`,
            maxAthletes: this.getMaxAthletes(t, row.maxAthletes)
          })}
        />
        {!race.deleted && <CreatePriceButton modalityId={id} getData={() => this.getData()} />}
      </Container>
    );
  }
}
