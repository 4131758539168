export const getTableHeaders = (t, isStatus) => {
  if (isStatus)
    return [
      {
        content: t("notificationScreen.recipentsTable.status"),
        accessor: "status",
        noSort: true
      },
      {
        content: t("notificationScreen.recipentsTable.email"),
        accessor: "email",
        noSort: true
      }
    ];
  return [
    {
      content: t("notificationScreen.recipentsTable.send"),
      accessor: "send",
      noSort: true
    },
    {
      content: t("notificationScreen.recipentsTable.name"),
      accessor: "athleteFullName"
    }
  ];
};
