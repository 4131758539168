import decode from "jwt-decode";
import { useCookies } from "react-cookie";
import React from "react";
import {
  Segment,
  Header,
  Breadcrumb,
  Icon,
  BreadcrumbDivider,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch, useLocation } from "react-router";
import _ from "lodash";
import { connect, useSelector } from "react-redux";

import routes from "../../util/routes";

const Title = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const race = useSelector((state) => state.races.actualRace);
  const modality = useSelector((state) => state.modalities.actualModality);

  let actualRoute;
  const [cookies] = useCookies(["token"]);
  let role = null;

  if (!race) return <div />;

  try {
    role = decode(cookies?.token?.token).role;
  } catch (err) {}

  routes.forEach((route) => {
    if (
      !!useRouteMatch({
        path: route.path,
        strict: true,
        exact: true,
      }) === true
    ) {
      actualRoute = route;
    }
  });

  if (!actualRoute || _.indexOf(actualRoute.roles, role) === -1) {
    actualRoute = {
      header: "notFound",
      routes: false,
    };
  }

  if (history.location.pathname === "/home") {
    return <></>;
  } else {
    switch (actualRoute.header) {
      case "race":
        const isRace = race && race.activityType;
        const isEvent = isRace && race.activityType.type === "EVENT";
        const isAssociate = isRace && race.activityType.type === "ASSOCIATES";
        return (
          <Segment basic>
            <Header id="Title" size="huge">
              {isEvent
                ? t(`title.event`)
                : isAssociate
                ? t(`title.associate`)
                : t(`title.${actualRoute?.header}`)}
            </Header>
            {actualRoute.routes && (
              <Breadcrumb id="Breadcrumbs">
                <Breadcrumb.Section onClick={() => history.push("/home")}>
                  <Icon name="home" />
                </Breadcrumb.Section>

                {actualRoute?.routes?.map((route, i) => (
                  <React.Fragment key={`Breadcrumb${i}`}>
                    <BreadcrumbDivider icon="right angle" />
                    {i !== actualRoute?.routes?.length - 1 ? (
                      <Breadcrumb.Section
                        onClick={() => {
                          const isRaces = route.path === "/races" && race;

                          const defaultPath =
                            isRaces && isEvent
                              ? "/events"
                              : isRaces && isAssociate
                              ? "/associates"
                              : route.path;
                          const pushTo = route.path.includes(":")
                            ? route.onFallback(location)
                            : defaultPath;
                          history.push(pushTo);
                        }}
                      >
                        {route.path === "/races" && isEvent
                          ? t(`breadcrumbs.events`)
                          : route.path === "/races" && isAssociate
                          ? t(`breadcrumbs.associates`)
                          : t(`breadcrumbs.${route.title}`)}
                      </Breadcrumb.Section>
                    ) : (
                      <Breadcrumb.Section active>
                        {race.name}
                      </Breadcrumb.Section>
                    )}
                  </React.Fragment>
                ))}
              </Breadcrumb>
            )}
          </Segment>
        );
      case "modality":
        const filteredRoutes = actualRoute.routes.filter(
          (route) => route.title !== "race"
        );
        const isRaceM = race && race.activityType;
        const isEventM = isRaceM && race.activityType.type === "EVENT";
        const isAssociateM = isRaceM && race.activityType.type === "ASSOCIATES";
        return (
          <Segment basic>
            <Header id="Title" size="huge">
              {isEventM
                ? t(`title.event`)
                : isAssociateM
                ? t(`title.associate`)
                : t(`title.${actualRoute?.header}`)}
            </Header>
            {actualRoute.routes && (
              <Breadcrumb id="Breadcrumbs">
                <Breadcrumb.Section onClick={() => history.push("/home")}>
                  <Icon name="home" />
                </Breadcrumb.Section>

                {filteredRoutes.map((route, i) => (
                  <React.Fragment key={`Breadcrumb${i}`}>
                    <BreadcrumbDivider icon="right angle" />
                    {i !== filteredRoutes.length - 1 ? (
                      <Breadcrumb.Section
                        onClick={() => {
                          const isRaces = route.path === "/races" && race;

                          const defaultPath =
                            isRaces && isEventM
                              ? "/events"
                              : isRaces && isAssociateM
                              ? "/associates"
                              : route.path;
                          const pushTo = route.path.includes(":")
                            ? route.onFallback(location)
                            : defaultPath;
                          history.push(pushTo);
                        }}
                      >
                        {route.path === "/races" && isEventM
                          ? t(`breadcrumbs.events`)
                          : route.path === "/races" && isAssociateM
                          ? t(`breadcrumbs.associates`)
                          : t(`breadcrumbs.${route.title}`)}
                      </Breadcrumb.Section>
                    ) : (
                      <>
                        <Breadcrumb.Section
                          onClick={() => {
                            history.push(`/race/${race.id}`);
                          }}
                        >
                          {race.name}
                        </Breadcrumb.Section>
                        <BreadcrumbDivider icon="right angle" />
                        <Breadcrumb.Section active>
                          {modality.name}
                        </Breadcrumb.Section>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </Breadcrumb>
            )}
          </Segment>
        );
        break;
      default:
        const isRaceD = race && race.activityType;
        const isEventD = isRaceD && race.activityType.type === "EVENT";
        const isAssociateD = isRaceD && race.activityType.type === "ASSOCIATES";
        
        return (
          <Segment basic>
            <Header id="Title" size="huge">
              {t(`title.${actualRoute?.header}`)}
            </Header>
            {actualRoute.routes && (
              <Breadcrumb id="Breadcrumbs">
                <Breadcrumb.Section onClick={() => history.push("/home")}>
                  <Icon name="home" />
                </Breadcrumb.Section>

                {actualRoute?.routes?.map((route, i) => (
                  <React.Fragment key={`Breadcrumb${i}`}>
                    <BreadcrumbDivider icon="right angle" />
                    {i !== actualRoute?.routes?.length - 1 ? (
                      <Breadcrumb.Section
                        onClick={() => {
                          const isRaces = route.path === "/races" && race;

                          const defaultPath =
                            isRaces && isEventD
                              ? "/events"
                              : isRaces && isAssociateD
                              ? "/associates"
                              : route.path;
                          const pushTo = route.path.includes(":")
                            ? route.onFallback(location)
                            : defaultPath;
                          history.push(pushTo);
                        }}
                      >
                        {route.title === "race" && race.name
                          ? race.name
                          : route.path === "/races" && isEventD
                          ? t(`breadcrumbs.events`)
                          : route.path === "/races" && isAssociateD
                          ? t(`breadcrumbs.associates`)
                          : t(`breadcrumbs.${route.title}`)}
                      </Breadcrumb.Section>
                    ) : (
                      <Breadcrumb.Section active>
                        {t(`breadcrumbs.${route.title}`)}
                      </Breadcrumb.Section>
                    )}
                  </React.Fragment>
                ))}
              </Breadcrumb>
            )}
          </Segment>
        );
    }
  }
};

export default Title;
