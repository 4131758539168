import { clientApi } from "./index";

export default {
  get: async params => {
    try {
      const response = await clientApi.getClient().get("/clubs", { params: {params, channel_id: 1} });
      return response.data;
    } catch (error) {}
  }
};
