import { createAction, handleActions } from "redux-actions";
import initialState from "./initialState";
import racesApi from "../apis/races";

export const getRace = createAction("GET_RACE", racesApi.fetchRace);
export const updateRace = createAction("UPDATE_RACE");

export default handleActions(
  {
    [getRace]: (state, action) => {
      return {
        ...state,
        actualRace: action.payload
      };
    },
    [updateRace]: (state, action) => {
      return {
        ...state,
        actualRace: {
          ...state.actualRace,
          ...action.payload
        }
      };
    }
  },
  { ...initialState }
);
