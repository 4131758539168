import { createAction, handleActions } from "redux-actions";
import initialState from "./initialState";

export const setRole = createAction("SET_ROLE");
export const setId = createAction("SET_ID");
export const setNotifications = createAction("SET_NOTIFICATIONS");

export default handleActions(
  {
    [setRole]: (state, action) => {
      return {
        ...state,
        role: action.payload
      };
    },
    [setId]: (state, action) => {
      return {
        ...state,
        id: action.payload
      };
    },
    [setNotifications]: (state, action) => {
      return {
        ...state,
        notifications: action.payload
      };
    }
  },
  { ...initialState }
);
