//@ts-check
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Container,
  Segment,
  Grid,
  Header,
  Button,
  Icon
} from "semantic-ui-react";
import templatesApi from "../../../../apis/templates";
import Form from "../../../../components/Form";
import forms from "./forms";
import RichTextEditor from "../../../../components/RichTextEditor";
import { DAlert } from "../../../../components/Dalert";
import {
  EnumNotificationTypes,
  notificationPushForm
} from "../../Notification/NotificationDetail/forms";

const styles = {
  BottomMargin: {
    marginBottom: "3%"
  },
  TopMargin: {
    marginTop: "1%"
  }
};

const createTemplateUrl = "/notifications/createTemplate";

const roleForm = {
  ADMINISTRATOR: forms.admin,
  COMPANY: forms.company
};

@withTranslation()
@connect(state => {
  //template: state.templates.actualTemplate
})
export default class TemplateDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      cardsPerRow: 3,
      showValidation: false,
      showColorSelector: false,
      color: "#ABB8C3",
      messageForm: {
        description: ""
      },
      currentData: {},
      editable: true
    };
  }

  async componentDidMount() {
    if (!this.props.location.editable && this.props.role !== "ADMINISTRATOR")
      this.setState({ editable: false });
    if (this.props.match.url !== createTemplateUrl)
      await this.getTemplateData();
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  async getTemplateData() {
    const response = await templatesApi.getTemplate(this.props.match.params.id);
    if (response.company_id && this.props.role === "COMPANY")
      this.setState({ editable: true });

    this.setState({
      data: response,
      currentData: response
      // messageForm: response ? response.message : ""
    });
  }

  textInsert(autotext) {
    const { messageForm, data, currentData } = this.state;

    if (autotext !== 0) {
      const description = (currentData.message || "") + autotext;

      this.setState({
        currentData: { ...currentData, ...data, message: description }
      });
    }
  }

  handleResize(e) {
    try {
      if (window.innerWidth < 736) {
        this.setState({ cardsPerRow: 1 });
      } else if (window.innerWidth < 992) {
        this.setState({ cardsPerRow: 2 });
      } else {
        this.setState({ cardsPerRow: 3 });
      }
    } catch (error) {}
  }

  async save(t) {
    if (!this.state.currentData.ISFORMVALID) {
      this.setState({ showValidation: true });
      return;
    }

    const updateData = {
      name: this.state.currentData.name,
      message: this.state.currentData.message,
      is_public:
        this.props.role === "ADMINISTRATOR"
          ? this.state.currentData.is_public
          : false,
      template_type: this.state.currentData.template_type,
      notification_push_image: this.state.currentData.notification_push_image
    };
    const response =
      this.props.match.url === createTemplateUrl
        ? await templatesApi.createTemplate(updateData)
        : await templatesApi.updateTemplate(
            this.props.match.params.id,
            updateData
          );
    if (response && response.id) {
      this.props.match.url === createTemplateUrl
        ? DAlert.success({ title: t("templateScreen.createdSuccess") })
        : DAlert.success({ title: t("templateScreen.updatedSuccess") });
      this.props.history.push(`/notifications`);
    } else {
      DAlert.error({ title: t("templateScreen.error") });
    }
  }

  onFormChange(formData) {
    const { currentData, data } = this.state;

    let newCurrentData = { ...currentData, ...formData, id: data.id };
    if (
      formData.template_type === null ||
      (formData.template_type &&
        currentData.template_type &&
        currentData.template_type !== formData.template_type)
    ) {
      newCurrentData = {
        ...newCurrentData,
        notification_push_image: "",
        message: ""
      };
    }

    this.setState({
      currentData: newCurrentData
    });
  }

  render() {
    let { history, t, role } = this.props;
    const {
      data,
      showValidation,
      currentData,
      messageForm,
      editable
    } = this.state;

    let selectedForm = [];
    if (role) {
      selectedForm = roleForm[role](t, this.textInsert.bind(this));
    }
    return (
      <Container>
        <Segment basic>
          <Grid>
            <Grid.Column width={16}>
              <Segment basic>
                <Form
                  fields={selectedForm}
                  onFormChange={formData => this.onFormChange(formData)}
                  defaultState={this.state.currentData}
                  showValidation={showValidation}
                />
              </Segment>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column width={16}>
              {this.state.currentData.template_type ===
                EnumNotificationTypes.EMAIL && (
                <Segment basic>
                  <Header size="small">
                    {t("templateScreen.fields.message")}
                  </Header>
                  <div style={styles.BottomMargin}>
                    <RichTextEditor
                      defaultContent={currentData.message}
                      onBlurEditor={data => {
                        this.setState({
                          currentData: { ...currentData, message: data }
                        });
                      }}
                    />
                  </div>
                </Segment>
              )}

              {this.state.currentData.template_type ===
                EnumNotificationTypes.NOTIFICATION_PUSH && (
                <Segment basic>
                  <Header size="small">
                    {t("notificationScreen.fields.notificationPush")}
                  </Header>
                  <div style={styles.BottomMargin}>
                    <Form
                      fields={notificationPushForm(t)}
                      defaultState={this.state.currentData}
                      showValidation={showValidation}
                      onFormChange={formData => {
                        this.setState({
                          currentData: {
                            ...this.state.currentData,
                            ...formData
                          }
                        });
                      }}
                    />
                  </div>
                </Segment>
              )}
            </Grid.Column>
          </Grid>

          <Grid>
            <Grid.Column width={9}>
              {editable ? (
                <>
                  <Button
                    primary
                    icon
                    labelPosition="left"
                    size="small"
                    onClick={() => this.props.history.goBack()}
                  >
                    {t("templateScreen.comeBack")}
                    <Icon name="triangle left" />
                  </Button>
                  ,
                  <Button
                    primary
                    icon
                    labelPosition="left"
                    size="small"
                    onClick={() => this.save(t)}
                  >
                    {t("templateScreen.save")}
                    <Icon name="save" />
                  </Button>
                  ,
                </>
              ) : (
                <Button
                  primary
                  icon
                  labelPosition="left"
                  size="small"
                  onClick={() => this.props.history.goBack()}
                >
                  {t("templateScreen.comeBack")}
                  <Icon name="triangle left" />
                </Button>
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </Container>
    );
  }
}
