import moment from "moment";
import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Container,
  Divider,
  Header,
  Icon,
  Grid,
  Responsive
} from "semantic-ui-react";
import Filter from "../../components/Filter";
import racesApi from "../../apis/races";
import raceTypes from "../../apis/raceTypes";
import Table from "../../components/DTable";
import FluidResponsiveButton from "../../components/FluidResponsiveButton";
import DCookieConsent from "../../components/DCookieConsent";
import { getFilterFields, getTableHeaders } from "./util";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

@connect(state => ({
  rol: state.user.role
}))
@withTranslation()
export default class HomeScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        previous: [],
        upcoming: []
      },
      totalData: {
        previous: 0,
        upcoming: 0
      },
      params: {
        previous: {},
        upcoming: {}
      },
      fields: {},
      isLoading: false,
      activityTypes: [],
    };
  }

  componentDidMount() {
    this.getData({ params: {} });
  }

  getData = async ({ params, type }) => {
    const parseEvents = (data, activityTypes) =>
    data.map((a) => {
      const actType = activityTypes.find((type) => a.activityTypeId === type.id);
      return { ...a, activityTypeId: actType ? actType.type : "RACE" };
    });

    if (type === "UPCOMING") {
      const response = await racesApi.fetchHome(params.upcoming);
      this.setState({
        data: {
          previous: this.state.data.previous,
          upcoming: parseEvents(response.upcoming.rows, this.state.activityTypes)
        },
        totalData: {
          previous: this.state.totalData.previous,
          upcoming: response.upcoming.count
        }
      });
    }

    if (type === "PREVIOUS") {
      const response = await racesApi.fetchHome(params.previous);
      this.setState({
        data: {
          previous: parseEvents(response.previous.rows, this.state.activityTypes),
          upcoming: this.state.data.upcoming
        },
        totalData: {
          previous: response.previous.count,
          upcoming: this.state.totalData.upcoming
        }
      });
    } else if (type === undefined) {
      const response = await racesApi.fetchHome(params);
      const activityTypes = await raceTypes.fetchActivityTypes();

      this.setState({
        activityTypes: activityTypes,
        data: {
          previous: parseEvents(response.previous.rows, activityTypes),
          upcoming: parseEvents(response.upcoming.rows, activityTypes)
        },
        totalData: {
          previous: response.previous.count,
          upcoming: response.upcoming.count
        }
      });
    }
  };

  filterTable = ({ parameters, fieldsParams, type }) => {
    const { params, fields } = this.state;
    this.setState({
      params: { ...parameters },
      fields: {}
    });

    const p = { ...params, ...parameters };
    const f = { ...fields, ...fieldsParams };

    this.getData({ params: { ...p, ...f }, type });
  };

  render() {
    const { t, rol } = this.props;
    const { data, totalData } = this.state;

    const filterFields = getFilterFields(t);
    const showCookiesWarning = (rol !== "ADMINISTRATOR")

    return (
      <>
        <Container>
          <Responsive minWidth={500}>
            <Grid columns={2}>
              <Grid.Column verticalAlign="bottom">
                {this.props.rol !== "PLANNER" &&
                  this.props.rol !== "POINT_OF_SALE" && (
                    <Link to="/race/create">
                      <FluidResponsiveButton
                        icon
                        labelPosition="left"
                        size="small"
                        primary
                      >
                        <Icon name="flag" />
                        {t("homeScreen.createRace")}
                      </FluidResponsiveButton>
                    </Link>
                  )}
              </Grid.Column>
              <Grid.Column verticalAlign="bottom" textAlign="right">
                <Filter
                  className=""
                  onFind={(filters) =>
                    this.filterTable({
                      params: { previous: {}, upcoming: {} },
                      fieldsParams: filters,
                    })
                  }
                  fields={filterFields}
                />
              </Grid.Column>
            </Grid>
          </Responsive>
          <Responsive maxWidth={500}>
            <Grid columns={1}>
              <Grid.Column>
                <br />
                <Filter
                  onFind={(filters) =>
                    this.filterTable({
                      params: { previous: {}, upcoming: {} },
                      fieldsParams: filters,
                    })
                  }
                  fields={filterFields}
                />
                <br />
                <Link to="/home">
                  <FluidResponsiveButton
                    icon
                    labelPosition="left"
                    size="small"
                    primary
                  >
                    <Icon name="flag" />
                    {t("homeScreen.createRace")}
                  </FluidResponsiveButton>
                </Link>
              </Grid.Column>
            </Grid>
          </Responsive>
          <div style={{ marginTop: 20 }}></div>
          <Divider horizontal>
            <Header as="h4">
              <Icon name="flag" />
              {t("homeScreen.type.upcoming")}
            </Header>
          </Divider>

          <Table
            headers={getTableHeaders(t, 1)}
            contentData={data.upcoming}
            parseData={(data) => ({
              start_date: moment(data.start_date).format("L"),
              activityTypeId: t(`commons.activities.${data.activityTypeId}`),
            })}
            onRowClick={(data) => {
              this.props.history.push(`/race/${data.id}`);
            }}
            totalData={totalData.upcoming}
            onTableChange={(params) =>
              this.filterTable({
                parameters: {
                  upcoming: params,
                  previous: this.state.params.previous,
                },
                type: "UPCOMING",
              })
            }
          />
        </Container>

        <div style={{ marginTop: 20 }}></div>

        <Container>
          <Divider horizontal>
            <Header as="h4">
              <Icon name="flag outline" />
              {t("homeScreen.type.previous")}
            </Header>
          </Divider>
          <Table
            headers={getTableHeaders(t, 2)}
            contentData={data.previous}
            parseData={(data) => ({
              start_date: moment(data.start_date).format("L"),
              activityTypeId: t(`commons.activities.${data.activityTypeId}`),
            })}
            onRowClick={(data) => {
              this.props.history.push(`/race/${data.id}`);
            }}
            totalData={totalData.previous}
            onTableChange={(params) =>
              this.filterTable({
                parameters: {
                  previous: params,
                  upcoming: this.state.params.upcoming,
                },
                type: "PREVIOUS",
              })
            }
          />
        </Container>
        {showCookiesWarning && (
          <Container>
            <DCookieConsent t={t} main={false} />
          </Container>
        )}
      </>
    );
  }
}
