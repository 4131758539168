import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Container, Icon } from "semantic-ui-react";
import fileDownload from "js-file-download";
import moment from "moment";

import Form from "../../../components/Form";
import AsyncButton from "../../../components/AsyncButton";
import { modalHandler } from "../../../components/DModal";
import FluidResponsiveButton from "../../../components/FluidResponsiveButton";

import FilterableTable from "../../../components/FilterableTable";
import {
  filterFields,
  tableHeaders,
  updateFormFields,
  uploadFormFields,
} from "./util";
import raceApi from "../../../apis/races";
import { DAlert } from "../../../components/Dalert";

const { DOC_MAX_SIZE } = process.env;

const RaceDocs = ({ id }) => {
  const [docs, setDocs] = useState({});
  const [params, setParams] = useState({});
  const [activeDoc, setActiveDoc] = useState({});
  const [newDoc, setNewDoc] = useState();
  const [showValidation, setShowValidation] = useState(false);
  const [showValidationForUpdate, setShowValidationForUpdate] = useState(false);
  const [erasing, setErasing] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [t] = useTranslation();

  const fetchRaceDocs = async (_params) => {
    const response = await raceApi.fetchRaceDocs(id, _params || params);

    setParams(_params);
    if (response) {
      setDocs({ data: response.rows, totalData: response.count });
    }
  };

  const { data, totalData } = docs;

  useEffect(() => {
    fetchRaceDocs();
  }, []);

  useEffect(() => {
    modalHandler.refreshOptions(uploadDocModalOptions);
  }, [newDoc, showValidation]);

  useEffect(() => {
    modalHandler.refreshOptions(updateDocModalOptions(activeDoc));
  }, [activeDoc, showValidationForUpdate]);

  const uploadDoc = async () => {
    if (!newDoc || !newDoc.ISFORMVALID) {
      setShowValidation(true);
      return;
    }

    const { document, ISFORMVALID, ...docData } = newDoc;
    const formData = new FormData();
    const docMaxSize = DOC_MAX_SIZE || 25; //should match client_max_body_size directive at api server nginx config
    const docMaxSizeAllowed = docMaxSize * 1024 * 1024;
    const docSize = document.size;
    if(docSize > docMaxSizeAllowed){
      DAlert.error({
        message: t("commons.docTooBig", {
          maxSize: docMaxSize
        })
      });
    } else {
      formData.append("document", document, document.name);
      Object.entries(docData).forEach(([key, value]) =>
        formData.append(key, value)
      );
      formData.append("channel_id", 1);
      const response = await raceApi.createRaceDoc(id, formData);
      if (response) {
        modalHandler.close();

        DAlert.success({
          title: t("commons.savedSuccess"),
        });
        await fetchRaceDocs();
      } else {
        DAlert.error({
          title: t("commons.errorSaving"),
        });
      }
    }

    setShowValidation(false);
  };

  const uploadDocModalOptions = {
    header: t("raceDetail.documents.createDoc"),
    content: (
      <Form
        onFormChange={setNewDoc}
        fields={uploadFormFields(t, showValidation)}
        showValidation={showValidation}
      />
    ),
    actions: (
      <AsyncButton onClick={async () => await uploadDoc()} primary>
        {t("raceDetail.documents.createDoc")}
      </AsyncButton>
    ),
  };

  const handleDownloadDoc = async (data) => {
    setActiveDoc(data);
    setDownloading(true);
    const response = await raceApi.downloadRaceDoc(id, data.id);

    if (response) {
      fileDownload(response, data.publicName,response.type);
    } else {
      DAlert.error({
        title: t("commons.serverError"),
        message: t("commons.serverErrorMessage"),
      });
    }

    setDownloading(false);
  };

  const updateDoc = async () => {
    if (!activeDoc || !activeDoc.ISFORMVALID) {
      setShowValidationForUpdate(true);
      return;
    }

    const { name, publicName, isPublic } = activeDoc;
    const response = await raceApi.updateRaceDoc(id, activeDoc.id, {
      name,
      publicName,
      isPublic,
    });

    if (response) {
      modalHandler.close();

      DAlert.success({
        title: t("commons.savedSuccess"),
      });

      await fetchRaceDocs();
    } else {
      DAlert.error({
        title: t("commons.errorSaving"),
      });
    }

    setShowValidationForUpdate(false);
  };

  const updateDocModalOptions = (data) => ({
    header: t("raceDetail.documents.updateDoc"),
    content: (
      <Form
        defaultState={{
          ...data,
          createdAtParsed: moment(data.createdAt).format("L"),
          email: data && data.user ? data.user.email : null,
        }}
        onFormChange={(changedData) =>
          setActiveDoc({
            email: data && data.user ? data.user.email : null,
            createdAtParsed: moment(data.createdAt).format("L"),
            ...data,
            ...changedData,
          })
        }
        fields={updateFormFields(t)}
        showValidation={showValidationForUpdate}
      />
    ),
    actions: (
      <AsyncButton onClick={updateDoc} primary>
        {t("raceDetail.documents.updateDoc")}
      </AsyncButton>
    ),
  });

  const handleDeleteDoc = async (doc) => {
    setErasing(true);
    setActiveDoc(doc);
    const response = await raceApi.deleteRaceDoc(id, doc.id);

    if (response && response.id) {
      DAlert.success({
        title: t("commons.erasedSuccessfully"),
      });
    } else {
      DAlert.error({
        title: t("commons.errorErased"),
      });
    }

    await fetchRaceDocs();
    setErasing(false);
  };

  return (
    <Container>
      <FilterableTable
        filterFields={filterFields(t)}
        onChange={(table, filters) => fetchRaceDocs({ ...table, ...filters })}
        tableHeaders={tableHeaders(t)}
        contentData={data}
        totalData={totalData}
        onRowClick={(data) => modalHandler.open(updateDocModalOptions(data))}
        parseData={(data) => ({
          path: <a href={data.path}>{data.path}</a>,
          createdAt: moment(data.createdAt).format("L"),
          actions: (
            <Button.Group>
              <Button
                icon="trash"
                color="google plus"
                loading={erasing && activeDoc && activeDoc.id === data.id}
                onClick={() => handleDeleteDoc(data)}
              />
              <Button
                icon="download"
                color="vk"
                loading={downloading && activeDoc && activeDoc.id === data.id}
                onClick={async () => await handleDownloadDoc(data)}
              />
            </Button.Group>
          ),
        })}
      />
      <FluidResponsiveButton
        icon
        labelPosition="left"
        size="small"
        onClick={() => modalHandler.open(uploadDocModalOptions)}
        primary
      >
        <Icon name="upload" />
        {t("raceDetail.documents.createDoc")}
      </FluidResponsiveButton>
    </Container>
  );
};

export default RaceDocs;
