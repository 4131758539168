import React, { useState, useEffect } from "react";
import {
  Container,
  Segment,
  Header,
  Button,
  Placeholder
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import queryString from "querystring";
import { times } from "lodash";

import inscriptionsApi from "../../../../apis/inscriptions";

const RefundResult = props => {
  const { history, match } = props;
  const { raceId, inscriptionId, type } = match.params;
  const [loading, setLoading] = useState(true);
  const [successResponse, setSuccessResponse] = useState(true);
  const [t] = useTranslation();

  useEffect(() => {
    if (type !== "failure" && props.location.search) {
      const params = queryString.parse(props.location.search.replace("?", ""));

      updateInscription(params);
    } else {
      setLoading(false);
    }
  }, []);

  const updateInscription = async params => {
    const response = await inscriptionsApi.afterRefundMoney(
      inscriptionId,
      params
    );
    setLoading(false);
    setSuccessResponse(
      typeof response.ok !== "undefined" && !response.ok ? false : true
    );
  };

  if (loading) {
    return (
      <Container>
        <Placeholder fluid>
          {times(10, i => (
            <Placeholder.Line key={i} />
          ))}
        </Placeholder>
      </Container>
    );
  }

  if (!successResponse) {
    return (
      <Container>
        <Segment attached padded="very">
          <Header textAlign="center" as="h1">
            {t("commons.errorSaving")}
          </Header>
          <p style={{ textAlign: "center" }}>
            {t("inscriptionsScreen.refund.refundSucessButProblemSaving")}
          </p>
        </Segment>
        <Button.Group attached="bottom">
          <Button
            color="vk"
            onClick={() => history.push(`/race/${raceId}#inscriptions`)}
          >
            {t("inscriptionsScreen.refund.backToRaceInscriptions")}
          </Button>
        </Button.Group>
      </Container>
    );
  }

  if (type === "failure") {
    return (
      <Container>
        <Segment attached padded="very">
          <Header textAlign="center" as="h1">
            {t("commons.errorSaving")}
          </Header>
          <p style={{ textAlign: "center" }}>
            {t("inscriptionsScreen.refund.refundFailure")}
          </p>
        </Segment>
        <Button.Group attached="bottom">
          <Button
            color="red"
            onClick={() =>
              history.push(`/race/${raceId}/inscription/${inscriptionId}?f=true`)
            }
          >
            {t("inscriptionsScreen.refund.backToInscription")}
          </Button>
          <Button
            color="vk"
            onClick={() => history.push(`/race/${raceId}#inscriptions`)}
          >
            {t("inscriptionsScreen.refund.backToRaceInscriptions")}
          </Button>
        </Button.Group>
      </Container>
    );
  }

  if (!props.location.search) {
    history.push("/404");
  }

  return (
    <Container>
      <Segment attached padded="very">
        <Header textAlign="center" as="h1">
          {t("commons.savedSuccess")}
        </Header>
        <p style={{ textAlign: "center" }}>
          {t("inscriptionsScreen.refund.refundSuccess")}
        </p>
      </Segment>
      <Button.Group attached="bottom">
        <Button
          color="vk"
          onClick={() => history.push(`/race/${raceId}#inscriptions`)}
        >
          {t("inscriptionsScreen.refund.backToRaceInscriptions")}
        </Button>
      </Button.Group>
    </Container>
  );
};

export default RefundResult;
