import React, { Component } from "react";
import { Card, List, Button, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import DTable from "../DTable";
import moment from "moment";
import { table } from "../../screens/Modality/ModalitySummary/util";

@withTranslation()
export default class ModalitySummaryCard extends Component {
  constructor(props) {
    super(props);
  }

  types(data, t) {
    switch (data.type) {
      case 0:
        return (
          <List relaxed="very" horizontal style={{ margin: "0% 5% 5% 5%" }}>
            {data.rows.map((row, index) => (
              <List.Item
                key={index}
                style={{
                  minWidth: "45%",
                  margin: 0,
                  padding: 0,
                  marginTop: "3%"
                }}
              >
                <List.Content>
                  <List.Header>
                    {t(`modalitySummary.inscriptions.${row.key}`)}
                  </List.Header>
                  {row.value || "---"}
                </List.Content>
              </List.Item>
            ))}
          </List>
        );
      case 1:
        return (
          <DTable
            style={{ cursor: "default" }}
            headers={table[data.header](t)}
            contentData={data.rows}
            showPagination={false}
            showNumberOfRecords={false}
            showItemsPerPage={false}
            parseData={data => ({
              TIPO: t(`modalityAttributes.types.${data.type}`),
              DESDE: data.DESDE ? moment(data.DESDE).format("L") : "---",
              HASTA: data.HASTA ? moment(data.HASTA).format("L") : "---",
              OBLIGATORIO: data.request?true:false
            })}
          />
        );
    }
  }

  render() {
    const { data, t, history } = this.props;
    let headerStyle =
      data.type === 0
        ? {
            borderBottom: "2px solid #120f33",
            paddingBottom: "36px",
            paddingTop: "15px"
          }
        : { paddingBottom: "20px", paddingTop: "15px" };

    return (
      <Card color="blue" fluid>
        <Card.Content style={{ flexGrow: 0 }}>
          <Card.Header style={headerStyle}>
            {t(`modalitySummary.titles.${data.name}`)}
            <Button
              floated="right"
              icon
              onClick={() => {
                this.props.history.push(
                  `${history.location.pathname}#${data.name}`
                );
              }}
            >
              <Icon fitted name="list" />
            </Button>
          </Card.Header>
          {this.types(data, t)}
        </Card.Content>
      </Card>
    );
  }
}
