import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Button, Popup } from "semantic-ui-react";

import { DAlert } from "../Dalert";
import { modalHandler } from "../DModal";
import AsyncButton from "../AsyncButton";

import es from "../../locales/default.es";
import inscriptionsApi from "../../apis/inscriptions";

const AssignPlaceButton = ({ inscriptionId, asIcon = false }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const openConfirmModal = () => {
    modalHandler.open({
      header: t("commons.areYouSure"),
      content: (
        <div style={{ whiteSpace: "pre-line" }}>
          {t("inscriptionsScreen.assignPlaceMessage")}
        </div>
      ),
      basic: false,
      size: "tiny",
      actions: (
            <Grid>
            <Grid.Row>
              <Grid.Column width="8">
                <Button
                  fluid
                  color="google plus"
                  onClick={() => modalHandler.close()}
                >
                  No
                </Button>
              </Grid.Column>
              <Grid.Column width="8">
                <AsyncButton
                  fluid
                  color="vk"
                  onClick={async () => {
                    await assignPlace();
                    modalHandler.close();
                  }}
                >
                  {t("inscriptionsScreen.assignPlace")}
                </AsyncButton>
              </Grid.Column>
            </Grid.Row>
          </Grid>
      ),
    });
  };

  const assignPlace = async () => {
    /*if (inscriptionId) {*/
      console.log('entra');
      const response = await inscriptionsApi.assignPlace(inscriptionId);
    if (response && response.id) {
      console.log(response);
        const { activity_id, id } = response;
        history.push(
          `/race/${activity_id}/inscription/${id}?f=true&c=true`
        );
        history.go(0);
      } else {
          try {
            DAlert.error({
              header: t("commons.errorSaving"),
              message:
                response.error.message in
                es.inscriptionsScreen.detail.inscriptionErrors
                  ? t(
                      `inscriptionsScreen.detail.inscriptionErrors.${response.error.message}`
                    )
                  : t("commons.serverErrorMessage"),
            });
          } catch (error) {
            console.log({error})
            DAlert.error({
              header: t("commons.errorSaving"),
              message : t("commons.serverErrorMessage")
            })
          }
          
        modalHandler.close();
      }
    /*} else {
      DAlert.error({
        title: t("commons.serverError"),
        message: t("commons.serverErrorMessage"),
      });
      modalHandler.close();
    }*/
  };

  if (asIcon) {
    return (
      <Popup
        content={t("inscriptionsScreen.assignPlace")}
        trigger={
          <Button
            style={{ margin: 0, display: "block", margin: "auto" }}
            primary
            onClick={openConfirmModal}
            icon="calendar check"
          />
        }
      />
    );
  }

  return (
    <Button primary onClick={openConfirmModal}>
      {t("inscriptionsScreen.assignPlace")}
    </Button>
  );
};

export default AssignPlaceButton;
