import React, { useState, useEffect } from "react";
import { Label, Grid, Segment, Icon, Table } from "semantic-ui-react";
import DTable from "./DTable";

import FormComponent from "./FormComponents";

// const headers = [
//   { name: "hola", match: "" },
//   { name: "adios", match: "" },
//   { name: "uno", match: "" },
//   { name: "dos", match: "" },
//   { name: "tres", match: "" }
// ];

export default function Match({ headers, onChange, mainFields }) {
  const nDataMobile = 3;
  let dataMobile = []
  for (let index = 0; index < nDataMobile; index++) {
    dataMobile.push({ text: index + 1, value: index + 1 })
  }

  const [selectedItem, setSelectedItem] = useState(-1);
  const [headersState, setHeadersState] = useState(headers);
  const [notMatchHeaders, setNotMatchHeaders] = useState(
    mainFields.map(h => ({
      text: h,
      value: h
    }))
  );

  //get the duplicateds
  const indexDuplicates = headersState.reduce((acc, curr, index) => {
    if (
      headersState.filter(header => header.match && header.match == curr.match)
        .length > 1
    ) {
      acc.push(index);
    }
    return acc;
  }, []);

  //get the mobile duplicateds
  const mobileDuplicates = headersState.reduce((acc, curr, index) => {
    if (
      headersState.filter(header => header.mobile && header.mobile == curr.mobile)
        .length > 1
    ) {
      acc.push(index);
    }
    return acc;
  }, []);

  useEffect(() => {
    onChange({ isValid: indexDuplicates.length == 0, headersState });
  }, [headersState]);

  const makeMatch = (row, match) => {
    const newHeadersState = [...headersState];
    let selected = newHeadersState[row];

    newHeadersState[row] = { ...selected, match };
    setHeadersState(newHeadersState);
    // setSelectedItem(-1);
    // setNotMatchHeaders(notMatchHeaders.filter((_, i) => i !== selectedItem));

    // onChange(newHeadersState);
  };
  const checkMobile = (row, mobile) => {
    const newHeadersState = [...headersState];
    let selected = newHeadersState[row];

    newHeadersState[row] = { ...selected, mobile };
    setHeadersState(newHeadersState);
  }

  return (

    <DTable
      contentData={headersState}
      headers={[
        { accessor: "name", content: "headers" },
        { accessor: "match", content: "match" },
        { accessor: "mobile", content: "mobile" }
      ]}
      rowProps={
        (_, index) =>
          indexDuplicates.includes(index) || mobileDuplicates.includes(index)
            ? { style: { backgroundColor: "#c31d1d" } }
            : {}
      }
      parseData={(data, row) => ({
        match: (
          <FormComponent.Select
            data={notMatchHeaders}
            value={data.match}
            onChange={val => makeMatch(row, val)}
            props={{ closeOnChange: true }}
          />
        ),
        mobile: (
          <FormComponent.Select
            data={dataMobile}
            value={data.mobile}
            onChange={val => checkMobile(row, val)}
            props={{ closeOnChange: true }}
          />
        )
      })}
      showItemsPerPage={false}
    />
  );

  // return (
  //   <div>
  //     <Grid columns={2} divided>
  //       <Grid.Row stretched>
  //         <Grid.Column width="2">
  //           {headersState.map((header, key) => (
  //             <Segment onClick={() => matchHeader(key)}>
  //               <Label as="a" color="blue" image>
  //                 {header.name}
  //                 <Label.Detail>
  //                   {header.match}
  //                   {header.match && <Icon name="close" />}
  //                 </Label.Detail>
  //               </Label>
  //             </Segment>
  //           ))}
  //         </Grid.Column>
  //         <Grid.Column width="2">
  //           {notMatchHeaders.map((header, key) => (
  //             <Segment key={key} onClick={() => setSelectedItem(key)}>
  //               <Label as="a" color="teal" image>
  //                 {header}
  //                 {/* <Label.Detail> </Label.Detail> */}
  //               </Label>
  //             </Segment>
  //           ))}
  //         </Grid.Column>
  //       </Grid.Row>
  //     </Grid>
  //   </div>
  // );
}

Match.defaultProps = {
  headers: [],
  mainFields: []
};
