//@ts-check
import React, { Component } from "react";
import { Container, Grid, Button, Input, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { findIndex } from "lodash";

import Form from "../../../../components/Form";
import { DAlert } from "../../../../components/Dalert";
import { modalHandler } from "../../../../components/DModal";
import Table from "../../../../components/DTable";
import FluidResponsiveButton from "../../../../components/FluidResponsiveButton";

import modalityApi from "../../../../apis/modalities";
import { formFields, tableProductHeaders, tableSelectHeaders } from "./util";

const creationMode = "create";
const updateMode = "update";

const errors = {
  NAME_EXIST: "NAME_EXIST",
  PRICE_IN_USE: "PRICE_IN_USE",
  TYPE_IN_USE: "TYPE_IN_USE",
  ATTRIBUTE_IN_USE: "ATTRIBUTE_IN_USE",
  ALREADY_EXIST: "ALREADY_EXIST",
  PRICE_IN_USE_ATTRIBUTE: "PRICE_IN_USE_ATTRIBUTE"
};

@withTranslation()
export default class ModalityAttributes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.match.params.id ? updateMode : creationMode,
      data: {},
      options: [{ id: -1, name: null, price: null, limit: null }],
      lastOptionAddedId: -1,
      showValidation: false,
      optionsToDelete: [],
      disableButton: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const { mode, options } = this.state;
    const { id } = this.props.match.params;

    if (mode && mode === updateMode) {
      const response = await modalityApi.fetchAttribute(id);

      let responseParsed = { ...response };
      if (
        responseParsed?.type === "PRODUCT" &&
        responseParsed?.price === null
      ) {
        responseParsed = {
          ...responseParsed,
          priceType: "VARIABLE"
        };
      } else {
        responseParsed = {
          ...responseParsed,
          priceType: "FIXED"
        };
      }
      
      this.setState({
        data: responseParsed,
        options:
          response?.ModalityAttributesOptions.length === 0
            ? options
            : response?.ModalityAttributesOptions
      });
    }
  }

  eraseHandler() {
    const { t, match, history } = this.props;
    const { id, raceId, modalityId } = match.params;

    modalHandler.open({
      header: t("commons.areYouSure"),
      content: t("modalityAttributes.eraseAttribute"),
      onYes: async () => {
        const response = await modalityApi.deleteAttribute(id);

        modalHandler.close();
        if (response && response.ok) {
          history.replace(`/race/${raceId}/modality/${modalityId}#attributes`);
          DAlert.success({ message: t("commons.erasedSuccessfully") });
        } else {
          if (this.showError(response)) {
            return;
          }
          DAlert.error({ message: t("commons.errorErased") });
        }
      }
    });
  }

  async createOrUpdateHandler() {
    const { t, match, history } = this.props;
    const { raceId, modalityId, id: attributeId } = match.params;
    const { data, options, mode, optionsToDelete } = this.state;

    if (!data.ISFORMVALID) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ disableButton: true })

    let formToSend = {
      ...data
    };

    const { type: typeOfField } = formToSend;

    if (typeOfField === "STRING") {
      const { maxLength } = formToSend;

      formToSend = {
        ...formToSend,
        maxLength: !maxLength ? 255 : +maxLength,
        price: null
      };
    } else if (typeOfField === "BOOLEAN") {
      const { price } = formToSend;

      formToSend = {
        ...formToSend,
        maxLength: null,
        price: price
          ? parseFloat(
              typeof price === "string" ? price.replace(",", ".") : price
            )
          : null
      };
    } else if (typeOfField === "SELECT" || typeOfField === "PRODUCT") {
      const isValid = options.every(
        ({ name, limit, price }) =>
          name &&
          (limit ? !isNaN(parseInt(limit)) : true) &&
          (price ? !isNaN(parseFloat(price)) : true)
      );

      if (!isValid) {
        DAlert.error({
          message: t("modalityAttributes.newAttribute.optionsAtLeastOne")
        });
        this.setState({ disableButton: false })
        return;
      }

      const { price: totalPrice, priceType } = formToSend;
      const parsedTotalPrice = parseFloat(
        typeof totalPrice === "string"
          ? totalPrice.replace(",", ".")
          : totalPrice
      );

      formToSend = {
        ...formToSend,
        maxLength: null,
        optionsToDelete,
        price:
          typeOfField === "SELECT"
            ? null
            : (priceType === null || priceType === "FIXED") && totalPrice
            ? parsedTotalPrice
            : null,
        attributeOptions: options.map(({ id, name, limit, price, edited }) => ({
          id,
          name,
          limit: limit ? parseInt(limit) : null,
          edited,
          price:
            typeOfField === "PRODUCT" &&
            (priceType === null || priceType === "FIXED")
              ? parsedTotalPrice
              : price
              ? parseFloat(
                  typeof price === "string" ? price.replace(",", ".") : price
                )
              : null
        }))
      };
    } else if (typeOfField === "NUMBER") {
      formToSend = {
        ...formToSend,
        maxLength: null,
        price: null
      };
    }

    let response;
    if (mode === creationMode) {
      response = await modalityApi.createAttribute(modalityId, formToSend);
    } else if (mode === updateMode) {
      response = await modalityApi.updateAttribute(attributeId, formToSend);
    }

    this.setState({ disableButton: false })
    if (response?.id) {
      if (mode === creationMode)
        history.replace(`/race/${raceId}/modality/${modalityId}#attributes`);
      DAlert.success({ message: t("commons.savedSuccess") });
    } else {
      if (this.showError(response)) {
        return;
      }
      DAlert.error({ message: t("commons.errorSaving") });
    }
  }

  showError(response) {
    const { t } = this.props;

    if (response.response.data.error) {
      const errMessage = response.response.data.error.description;
      const errorKey = Object.keys(errors).find(key =>
        errMessage.includes(key)
      );

      if (!errorKey) {
        DAlert.error({ message: t("commons.errorSaving") });

        return true;
      }

      const regexField = /.+, {(\w+)}/gm;

      const field = regexField.exec(errMessage);

      if (field && field[1]) {
        DAlert.error({
          message: t(`modalityAttributes.errors.${errorKey}`, {
            field: field[1]
          })
        });
      } else {
        DAlert.error({ message: t(`modalityAttributes.errors.${errorKey}`) });
      }

      return true;
    }
  }

  optionsTableInputHandler(name, value, id) {
    const { options } = this.state;

    const indexOfValueToChange = options.findIndex(option => option.id === id);
    const newOptions = [...options];

    newOptions[indexOfValueToChange] = {
      ...options[indexOfValueToChange],
      [name]: value,
      edited: true
    };

    // this.setState({
    //   options: [
    //     ...options.slice(0, indexOfValueToChange),
    //     {
    //       ...options.find(optionPredicate),
    //       [name]: value
    //     },
    //     ...options.slice(indexOfValueToChange + 1)
    //   ]
    // });
    this.setState({
      options: newOptions
    });
  }

  deleteOptionHandler(id) {
    const { options, optionsToDelete } = this.state;

    this.setState({
      options: options.filter(option => option.id !== id),
      optionsToDelete: id > 0 ? [...optionsToDelete, id] : optionsToDelete
    });
  }

  selectOrOptionsTable() {
    const { t } = this.props;
    const { data, options, lastOptionAddedId } = this.state;
    const { used } = data;
    const tableHeaders =
      data.type === "SELECT" ||
      (data.type === "PRODUCT" &&
        (data.priceType === null || data.priceType === "FIXED"))
        ? tableSelectHeaders(t)
        : tableProductHeaders(t);

    const tableParser = formData => {
      const { id } = formData;
      const baseParsers = {
        name: (
          <Input
            value={formData.name}
            fluid
            onChange={(_, { value }) =>
              this.optionsTableInputHandler("name", value, id)
            }
            disabled={used}
          />
        ),
        limit: (
          <Input
            value={formData.limit}
            fluid
            onChange={(_, { value }) =>
              this.optionsTableInputHandler("limit", value, id)
            }
            disabled={used}
          />
        )
      };

      const deleteParser = {
        deleteAttribute:
          options.length === 1 ? null : (
            <Button
              floated="right"
              circular
              basic
              as={Icon}
              name="trash alternate outline"
              color="red"
              onClick={() => this.deleteOptionHandler(id)}
              disabled={used}
            />
          )
      };

      return data.type === "SELECT"
        ? { ...baseParsers, ...deleteParser }
        : {
            ...baseParsers,
            price: (
              <Input
                value={formData.price}
                fluid
                onChange={(_, { value }) =>
                  this.optionsTableInputHandler("price", value, id)
                }
                disabled={used}
              />
            ),
            ...deleteParser
          };
    };

    return (
      <React.Fragment>
        <Table
          headers={tableHeaders}
          contentData={options}
          parseData={tableParser}
          showItemsPerPage={false}
          showNumberOfRecords={false}
          showPagination={false}
          showTotalItems={false}
        />
        {!used && (
          <FluidResponsiveButton
            primary
            icon
            labelPosition="left"
            size="small"
            onClick={async () => {
              await this.setState({
                options: [
                  ...options,
                  {
                    id: lastOptionAddedId - 1,
                    name: null,
                    price: null,
                    limit: null
                  }
                ],
                lastOptionAddedId: lastOptionAddedId - 1
              });
            }}
          >
            <Icon name="plus" />
            {t("modalityAttributes.newAttribute.addOption")}
          </FluidResponsiveButton>
        )}
        <br />
      </React.Fragment>
    );
  }

  formHandler(formData) {
    const { data } = this.state;

    this.setState({ data: { ...data, ...formData } });
  }

  render() {
    const { t, history } = this.props;
    const { data, mode, showValidation } = this.state;
    const { type, used, timesUsed } = data;

    const canChangeAttribute = !(
      (type === "SELECT" || type === "PRODUCT") &&
      used
    );

    return (
      <Container>
        <Form
          defaultState={data}
          fields={formFields(t, data, mode)}
          onFormChange={formData => this.formHandler(formData)}
          showValidation={showValidation}
        />
        {(type === "SELECT" || type === "PRODUCT") &&
          this.selectOrOptionsTable()}
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              {mode && mode === updateMode && canChangeAttribute && (
                <Button
                  floated="left"
                  color="red"
                  onClick={() => this.eraseHandler()}
                >
                  {t("commons.delete")}
                </Button>
              )}
            </Grid.Column>

            <Grid.Column>
              {
                <Button
                  floated="right"
                  color="vk"
                  disabled={this.state.disableButton}
                  onClick={() => this.createOrUpdateHandler()}
                >
                  {t("commons.save")}
                </Button>
              }
              <Button
                floated="right"
                color="google plus"
                onClick={() => history.goBack()}
              >
                {t("commons.return")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
