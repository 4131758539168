import React, { Component } from "react";
import { Grid, Divider, Segment, Header } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ImagePicker from "../../../../components/ImagePicker";
import RichTextEditor from "../../../../components/RichTextEditor";
import { updateRace } from "../../../../reducers/races";

@withTranslation()
@connect(
  state => ({
    race: state.races.actualRace
  }),
  dispatch => ({
    actions: bindActionCreators({ updateRace }, dispatch)
  })
)
export default class Customize extends Component {
  constructor(props) {
    super(props);

    this.headerImageChange = this.headerImageChange.bind(this);
    this.emailImageChange = this.emailImageChange.bind(this);
    this.emailEditorHandler = this.emailEditorHandler.bind(this);
    this.commercialDataEditorHandler = this.commercialDataEditorHandler.bind(
      this
    );
    this.voucherImageChange = this.voucherImageChange.bind(
      this
    );
  }

  voucherImageChange(data) {
    this.props.actions.updateRace({
      voucher_image: data
    });
  }

  headerImageChange(data) {
    this.props.actions.updateRace({
      poster_image: data
    });
  }

  emailImageChange(data) {
    this.props.actions.updateRace({
      email_image: data
    });
  }

  emailEditorHandler(htmlString) {
    this.props.actions.updateRace({
      email_content: htmlString
    });
  }

  commercialDataEditorHandler(htmlString) {
    this.props.actions.updateRace({
      commercial_use: htmlString
    });
  }

  render() {
    const { t, race } = this.props;

    return (
      <React.Fragment>
        <Grid container columns={2} padded>
          <Grid.Column mobile={16} computer={8}>
            <Header as="h5">
              {t("raceEdit.customizeTabContent.headerImage",{dimensions:"250px x 400px"})}
            </Header>
            {!race.deleted && <ImagePicker
              content={t("commons.selectImage")}
              onSelect={this.headerImageChange}
              imageSrc={race.poster_image_url}
              buttonProps={{
                fluid: true
              }}
            />}
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <Header as="h5">
              {t("raceEdit.customizeTabContent.headerEmailImage",{dimensions:"700px x 100px"})}
            </Header>
            {!race.deleted && <ImagePicker
              content={t("commons.selectImage")}
              onSelect={this.emailImageChange}
              imageSrc={race.email_image_url}
              buttonProps={{
                fluid: true
              }}
            />}
          </Grid.Column>
        </Grid>
        <Grid container columns={2} padded>
          <Grid.Column mobile={16} computer={8}>
            <Header as="h5">
              {t("raceEdit.customizeTabContent.voucherImage",{dimensions:"700px x 100px"})}
            </Header>
            {!race.deleted && <ImagePicker
              content={t("commons.selectImage")}
              onSelect={this.voucherImageChange}
              imageSrc={race.voucher_image_url}
              buttonProps={{
                fluid: true
              }}
            />}
          </Grid.Column>
        </Grid>
        <Segment basic>
          <Header as="h5">{t("raceEdit.customizeTabContent.emailText")}</Header>
          <RichTextEditor
            defaultContent={race?.email_content}
            onBlurEditor={this.emailEditorHandler}
          />
        </Segment>
        <Divider />
        <Segment basic>
          <Header as="h5">
            {t("raceEdit.customizeTabContent.commercialText")}
          </Header>
          <RichTextEditor
            defaultContent={race?.commercial_use}
            onBlurEditor={this.commercialDataEditorHandler}
          />
        </Segment>
      </React.Fragment>
    );
  }
}
