//@ts-check
import React from "react";
import SearchInput from "../../../../components/SearchInput";
import { autotexts } from "../../../../util/usersUtil";

export const EnumNotificationTypes = {
  EMAIL: "EMAIL",
  NOTIFICATION_PUSH: "NOTIFICATION_PUSH",
  WHATSAPP: "WHATSAPP"
};

const nofitiationTypes = [
  {
    text: "Email",
    value: EnumNotificationTypes.EMAIL
  },
  {
    text: "Notification Push",
    value: EnumNotificationTypes.NOTIFICATION_PUSH
  }
  // {
  //   text: "Whatsapp",
  //   value: EnumNotificationTypes.WHATSAPP,
  // },
];

export const notificationType = (t, isPrefilter) => ({
  fields: !isPrefilter
    ? [
        {
          name: "template_type",
          title: t("notificationScreen.fields.notificationType"),
          placeHolder: t("notificationScreen.fields.notificationType"),
          type: "Select",
          data: nofitiationTypes,
          colSize: 6,
          props: {
            clearable: true
          },
          validation: {
            required: true
          }
        }
      ]
    : []
});

export const notificationPushForm = t => [
  {
    fields: [
      {
        name: "message",
        type: "TextArea",
        colSize: 12,
        validation: {
          required: true
        }
      }
    ]
  },
  {
    fields: [
      {
        name: "notification_push_image",
        title: t("notificationScreen.fields.imageUrl"),
        type: "Input",
        colSize: 12
      }
    ]
  }
];

const templatesField = (t, templatesList, isPrefilter) =>
  isPrefilter
    ? { fields: [] }
    : {
        fields: vals => [
          {
            name: "template_id",
            title: t("notificationScreen.fields.template"),
            placeHolder: t("notificationScreen.fields.template"),
            type: "Select",
            data: templatesList.map(template => ({
              text: template.text,
              value: template.value
            })),
            colSize: 6,
            props: {
              disabled: vals.notification_type == "",
              clearable: true
            }
          }
        ]
      };

const autotext = (t, racesList, autotextInsert, isPrefilter) =>
  isPrefilter
    ? { fields: [] }
    : {
        fields: vals => [
          {
            name: "autotext",
            title: t("notificationScreen.fields.autotext"),
            placeHolder: t("notificationScreen.fields.autotext"),
            type: "Select",
            data: autotexts(t),
            colSize: 6,
            props: {
              disabled: vals.notification_type == "",
              clearable: true
            },
            onChange: click => {
              autotextInsert(click);
            }
          }
        ]
      };

const subject = (t, isPrefilter) =>
  isPrefilter
    ? { fields: [] }
    : {
        fields: vals => [
          {
            name: "subject",
            title: t("notificationScreen.fields.subject"),
            placeHolder: t("notificationScreen.fields.subject"),
            type: "Input",
            colSize: 12,
            props: {
              disabled: vals.notification_type == ""
            },
            validation: {
              required: true
            }
          }
        ]
      };

const prefilter = (
  t,
  companies,
  racesList,
  modalities,
  role,
  onChangeCompany,
  onRaceChange,
  company_id,
  regions
) =>
  role === "ADMINISTRATOR"
    ? {
        fields: [
          {
            name: "company_id",
            title: t("notificationScreen.fields.company"),
            placeHolder: t("notificationScreen.fields.company"),
            type: "Select",
            dflt: company_id,
            value: company_id,
            data: companies.map(company => ({
              text: company.text,
              value: company.value
            })),
            colSize: 6,
            props: {
              clearable: true
            },
            onChange: companySelected => {
              onChangeCompany(companySelected);
            }
          },
          {
            name: "race_id",
            title: t("notificationScreen.fields.race"),
            placeHolder: t("notificationScreen.fields.race"),
            type: "Select",
            data: racesList.map(race => ({
              text: race.text,
              value: race.value
            })),
            colSize: 6,
            props: {
              clearable: true
            },
            onChange: raceId => {
              onRaceChange(raceId);
            }
          },

          {
            name: "modality_id",
            title: t("notificationScreen.fields.modality"),
            placeHolder: t("notificationScreen.fields.modality"),
            type: "Select",
            data: modalities,
            colSize: 6,
            props: {
              clearable: true
            }
          },
          {
            name: "region",
            component: (state, defauld, onChange) => (
              <div className={"eight wide field"}>
                <label>{t("companyDetail.province")}</label>
                <SearchInput
                  onChange={data => {
                    onChange(data);
                  }}
                  data={regions}
                  placeholder={t("companyDetail.province")}
                  value={state.region || ""}
                  noResultsMessage={t("companyDetail.provinceNotFound")}
                />
              </div>
            )
          }
        ]
      }
    : {
        fields: [
          {
            name: "race_id",
            title: t("notificationScreen.fields.race"),
            placeHolder: t("notificationScreen.fields.race"),
            type: "Select",
            data: racesList.map(race => ({
              text: race.text,
              value: race.value
            })),
            colSize: 6,
            props: {
              clearable: true
            },
            onChange: raceId => {
              onRaceChange(raceId);
            }
          },
          {
            name: "modality_id",
            title: t("notificationScreen.fields.modality"),
            placeHolder: t("notificationScreen.fields.modality"),
            type: "Select",
            data: modalities,
            colSize: 6,
            props: {
              clearable: true
            }
          },
          {
            name: "region",
            component: (state, defauld, onChange) => (
              <div className={"eight wide field"}>
                <label>{t("companyDetail.province")}</label>
                <SearchInput
                  onChange={data => {
                    onChange(data);
                  }}
                  data={regions}
                  placeholder={t("companyDetail.province")}
                  value={state.region || ""}
                  noResultsMessage={t("companyDetail.provinceNotFound")}
                />
              </div>
            )
          }
        ]
      };

const basisForm = (t, templates, insert) => [
  {
    fields: [
      {
        name: "notification_type",
        title: t("notificationScreen.fields.notificationType"),
        placeHolder: t("notificationScreen.fields.notificationType"),
        type: "Select",
        data: nofitiationTypes,
        colSize: 6,
        props: {
          clearable: true
        },
        validation: {
          required: true
        }
      }
    ]
  },
  {
    fields: vals => [
      {
        name: "template_id",
        title: t("notificationScreen.fields.template"),
        placeHolder: t("notificationScreen.fields.template"),
        type: "Select",
        data: templates.map(template => ({
          text: template.text,
          value: template.value
        })),
        colSize: 6,
        props: {
          disabled: !vals.notification_type,
          clearable: true
        }
      }
    ]
  },
  {
    fields: vals => [
      {
        name: "subject",
        title: t("notificationScreen.fields.subject"),
        placeHolder: t("notificationScreen.fields.subject"),
        type: "Input",
        colSize: 12,
        props: {
          disabled: !vals.notification_type
        },
        validation: {
          required: true
        }
      }
    ]
  },
  {
    fields: vals => [
      {
        name: "autotext",
        title: t("notificationScreen.fields.autotext"),
        placeHolder: t("notificationScreen.fields.autotext"),
        type: "Select",
        data: autotexts(t),
        colSize: 6,
        props: {
          disabled: !vals.notification_type,
          clearable: true
        },
        onChange: click => {
          insert(click);
        }
      }
    ]
  }
];

export default {
  prefilterForm: (
    t,
    companies,
    race,
    modalities,
    isPrefilter,
    role,
    onChangeCompany,
    onRaceChange,
    company_id
  ) => [
    prefilter(
      t,
      companies,
      race,
      modalities,
      isPrefilter,
      role,
      onChangeCompany,
      onRaceChange,
      company_id
    )
  ],
  admin: (t, templates, insert) => basisForm(t, templates, insert),
  company: (t, templates, insert) => basisForm(t, templates, insert)
};
