import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Container, Icon, Grid, Dimmer, Loader } from "semantic-ui-react";
import moment from "moment";
import fileDownload from "js-file-download";

import FilterableTable from "../../../components/FilterableTable";
import { DAlert } from "../../../components/Dalert";
import FluidResponsiveButton from "../../../components/FluidResponsiveButton";
import AssignPlaceButton from "../../../components/AssignPlaceButton";

import racesApi from "../../../apis/races";
import { filterFields, tableHeaders } from "./util";
import dictionary from "../../../locales/default.en";

@connect(state => ({
  race: state.races.actualRace,
  rol: state.user.role
}))
@withTranslation()
export default class Inscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      totalData: 0,
      loading: false,
      modalities: [],
      availableModalities: false,
      filter: {},
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getData();
    this.setState({ loading: false });
    this.getModalities();
  }

  async getModalities() {
    const data = await racesApi.fetchModalities({ id: this.props.id });

    const modalities = data.map(type => ({
      text: `${type.name}`,
      value: type.name
    }));

    const availableModalities = data.some(element => element.available);
    this.setState({ modalities, availableModalities });
  }

  async getData(params) {
    const data = await racesApi.fetchInscriptions({
      id: this.props.id,
      ...params
    });

    this.setState({ data: data.rows, totalData: data.count });
  }

  render() {
    const { t, history, id, race, rol } = this.props;
    const { data, totalData, loading, modalities, availableModalities, filter } = this.state;

    const showCsvButton = (rol === 'ADMINISTRATOR' || rol === 'COMPANY' || rol === 'PLANNER')
    return (
      <div>
        <Container>
          <FilterableTable
            filterFields={filterFields(t, modalities)}
            tableHeaders={tableHeaders(t, filter)}
            contentData={data}
            onRowClick={(data) =>
              history.push(
                `/race/${data.raceId}/inscription/${
                  data.clientInscriptionId
                }?f=${data.statusId !== 1 ? true : false}`
              )
            }
            totalData={totalData}
            onChange={(table, filter) => {
              this.setState({ filter: filter || table ? { ...filter, ...table } : {} })
              this.getData({ ...table, ...filter })
            }}
            parseData={row => ({
              inscriptionDate: moment(row.inscriptionDate).format("L"),
              // inscriptionStatus: t(`paymentStatus.${row.inscriptionStatus}`),
              combinedStatus: row.combinedStatus ? t(`paymentStatus.${row.combinedStatus}`) : '',
              paymentOperation_code:
                row.paymentOperation_code &&
                `${row.paymentOperation_code} ${row.paymentAuthCode &&
                `(${row.paymentAuthCode})`}`,
              inscriptionType:
                row.statusId !== 1 ? null : row.athleteStatus ===
                  "CANCELLED" ? (
                  t("inscriptionsScreen.notFinished")
                ) : (
                  <AssignPlaceButton inscriptionId={row.clientInscriptionId} asIcon />
                ),
              inscriptionFrom:
                row.inscriptionFrom &&
                Object.keys(dictionary.commons.origin).includes(row.inscriptionFrom )
                  ? t(`commons.origin.${row.inscriptionFrom}`)
                  : t(`commons.origin.UNKNOWN`),
            })}
            loading={loading}
            rowProps={data =>
              data.athleteStatus
                ? { negative: true, className: "strikethrough" }
                : {}
            }
            fixed
          />

          <Grid>
            <Grid.Row>
              <Grid.Column width="16">
                {!race.deleted && race.is_published && availableModalities && (
                  <FluidResponsiveButton
                    icon
                    labelPosition="left"
                    size="small"
                    primary
                    floated="left"
                    onClick={async () => {
                      await history.push(`/race/${this.props.id}/inscription/create`);
                    }}
                    style={{ marginBottom: 10 }}
                  >
                    <Icon name="flag" />
                    {t("inscriptionsScreen.inscriptionButton")}
                  </FluidResponsiveButton>
                )}
                {showCsvButton && <FluidResponsiveButton
                  icon
                  labelPosition="left"
                  size="small"
                  primary
                  floated="right"
                  onClick={async () => {
                    this.setState({ loading: true })
                    const res = await racesApi.fetchInscriptionsCsv(id, { ...filter, language: this.props.i18n.language.toUpperCase() });
                    if (res && res.status == 200) {
                      const file = res.data
                      // fileDownload(file, "inscriptions.csv", 'text/csv', true);
                      fileDownload("\uFEFF" + file, 'inscriptions.csv', 'text/csv');
                    }
                    else {
                      DAlert.errorPlus({
                        title: t("commons.serverError"),
                        message: t("commons.serverErrorMessage")
                      });
                    }
                    this.setState({ loading: false })
                  }}
                  disabled={data.length === 0}
                >
                  <Icon name="file alternate outline" />
                  {t("inscriptionsScreen.downloadCsvButton")}
                </FluidResponsiveButton>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        {loading ? (
          // <Dimmer active style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          <Dimmer active>
            <Loader />
          </Dimmer>
        ) : null}
      </div>
    );
  }
}
