
import * as Cookies from 'tiny-cookie'

export const checkToken = async () => {
  try {
    const token = Cookies.getCookie("token")

    if (token === null) {
      throw ({
        response: {
          data: {
            error: {
              message: 'NO_TOKEN'
            }
          }

        }
      })
    }

    

  } catch (error) {
    throw error;
  }
}