import React from "react";
import { Button, Container } from "semantic-ui-react";
import SearchInput from "../../../components/SearchInput";
import ImagePicker from "../../../components/ImagePicker";
import { modalHandler } from "../../../components/DModal";

import regex from "../../../components/Form/regex"
import { checkNIF, dateIsBetween } from "../../../util/util";
import moment from "moment";

export const selectModalityForm = (t, raceModalities, modality, modalityId) =>
  !modalityId
    ? [
      {
        fields: [
          {
            name: "modality",
            title: t("inscriptionsScreen.detail.selectModality"),
            placeHolder: t("inscriptionsScreen.detail.selectModality"),
            type: "Select",
            colSize: 4,
            data: raceModalities.map((modality) => ({
              text:
                modality.inscriptionsState === "ENABLED"
                  ? modality.name
                  : `${modality.name} (${t(
                    `inscriptionStatus.${modality.inscriptionsState}`
                  )})`,
              value: modality.slug,
              disabled: !(
                modality.inscriptionsState === "ENABLED" ||
                modality.inscriptionsState === "FULL_RACE" ||
                modality.inscriptionsState === "FULL_MODALITY"
              ),
            })),
          },
        ],
      },
    ]
    : [
      {
        fields: [
          {
            name: "modality",
            title: t("inscriptionsScreen.detail.selectModality"),
            placeHolder: t("inscriptionsScreen.detail.selectModality"),
            type: "Input",
            colSize: 4,
            value: modality.name,
            dflt: modality.name,
            props: {
              readOnly: true,
            },
          },
        ],
      },
    ];

export const totalInsert = (t, price) => [
  {
    fields: [
      {
        name: "total",
        title: t("inscriptionsScreen.detail.insertPrice"),
        placeHolder: `${price}€`,
        type: "Input",
        colSize: 16,
        validation: {
          regexType: "doubleWithoutDefault",
          errorMessage: t("commons.mustBeNumber"),
        },
      },
    ],
  },
];

export const getBirthdayErrorMessage = (
  t,
  hasAgeRestrictions,
  inscriptions
) => {
  const hasMin = inscriptions.birthMin !== null;
  const hasMax = inscriptions.birthMax !== null;
  if (!hasAgeRestrictions) {
    return t("commons.fieldRequired");
  } else {
    if (hasMin && hasMax) {
      return t("inscriptionsScreen.detail.birthdayShouldBeBetween.both", {
        minDate: moment(inscriptions.birthMin).format("L"),
        maxDate: moment(inscriptions.birthMax).format("L"),
      });
    } else if (hasMin) {
      return t("inscriptionsScreen.detail.birthdayShouldBeBetween.onlyMin", {
        minDate: moment(inscriptions.birthMin).format("L"),
      });
    } else {
      return t("inscriptionsScreen.detail.birthdayShouldBeBetween.onlyMax", {
        maxDate: moment(inscriptions.birthMax).format("L"),
      });
    }
  }
};

export const modalityForm = (
  t,
  modality,
  showValidation,
  isTeamInscription,
  teamData,
  clubs,
  provinces,
  countries,
  bookDiscount,
  discountSelected
) => {
  const {
    ModalityTeamTypes,
    additionalFields,
    attributes,
    prices,
    discounts,
    inscriptions,
    race
  } = modality;
  const {
    confirmEmail,
    address,
    zipCode,
    location1,
    location2,
    location3,
    originCountry,
    phonePrefix,
    mobile,
    emergencyMobile,
    federatedParticipant,
    localParticipant,
    photo,
    parentalLeave,
    selectClub
  } = additionalFields;
  const { under14_dni } = race

  const areAdditionalFields = Object.values(additionalFields)
    .filter((v) => typeof v !== "string")
    .some((v) => v);
  const nullObject = { type: "Null", colSize: 0 };
  const modalOptions = (link, moreInfo) => ({
    header: null,
    content: (
      <Container>
        {link && (
          <div>
            {t("inscriptionsScreen.detail.moreInfoUrl")}
            <a href={link} target="_blank">
              {link}
            </a>
          </div>
        )}
        {moreInfo}
      </Container>
    ),
    actions: (
      <Button onClick={() => modalHandler.close()}>{t("commons.ok")}</Button>
    ),
  });
  const today = new Date();
  const eighteenYearsAgo = new Date(
    new Date(today).setFullYear(today.getFullYear() - 18)
  );
  const fourteeenYearsAgo = new Date (new Date(
    new Date(today).setFullYear(today.getFullYear() - 14)
  ).setHours(0,0,0))

  // const hasAgeRestrictions = Object.values(inscriptions).map(v => v !== null);
  const hasAgeRestrictions =
    inscriptions.birthMin !== null || inscriptions.birthMax !== null;
  const getBirthdayErrorMessage = (hasAgeRestrictions, inscriptions) => {
    const hasMin = inscriptions.birthMin !== null;
    const hasMax = inscriptions.birthMax !== null;
    if (!hasAgeRestrictions) {
      return t("commons.fieldRequired");
    } else {
      if (hasMin && hasMax) {
        return t("inscriptionsScreen.detail.birthdayShouldBeBetween.both", {
          minDate: moment(inscriptions.birthMin).format("L"),
          maxDate: moment(inscriptions.birthMax).format("L"),
        });
      } else if (hasMin) {
        return t("inscriptionsScreen.detail.birthdayShouldBeBetween.onlyMin", {
          minDate: moment(inscriptions.birthMin).format("L"),
        });
      } else {
        return t("inscriptionsScreen.detail.birthdayShouldBeBetween.onlyMax", {
          maxDate: moment(inscriptions.birthMax).format("L"),
        });
      }
    }
  };

  const teamSelected = (state) =>
    ModalityTeamTypes.find(
      (teamType) => teamType.id == +state["inscription.teamId"]
    );

  const getMinAndMaxOfTeam = (state) => {
    const { minAthletes, maxAthletes } = teamSelected(state);
    return `(${minAthletes} - ${maxAthletes})`;
  };

  const teamSelectedEnd =
    isTeamInscription &&
    ModalityTeamTypes.find((type) => type.id === teamData.teamSelected);

  const discountFields = discounts
    ? !discountSelected
      ? [
        {
          fields: [
            {
              type: "Label",
              title: t("inscriptionsScreen.detail.discount"),
              color: "#2185d0",
            },
          ],
        },
        {
          fields: [
            {
              name: "discountCode",
              title: t("inscriptionsScreen.detail.discount"),
              placeHolder: t("inscriptionsScreen.detail.discountCode"),
              type: "Input",
              colSize: 6,
            },
            {
              name: "inscription.discountId",
              component: (vals, _, onChange) => (
                <Button
                  style={{
                    maxHeight: "3em",
                    marginTop: 20,
                  }}
                  primary
                  onClick={async () => {
                    if (!vals.discountCode) {
                      return;
                    }

                    const discountId = await bookDiscount(vals.discountCode);
                    onChange(discountId);
                  }}
                >
                  {t("inscriptionsScreen.detail.validateDiscount")}
                </Button>
              ),
            },
          ],
        },
      ]
      : [
        {
          fields: [
            {
              type: "Label",
              title: t("inscriptionsScreen.detail.discount"),
              color: "#2185d0",
            },
          ],
        },
        {
          fields: [
            {
              name: "discountCode",
              title: t("inscriptionsScreen.detail.discount"),
              placeHolder: t("inscriptionsScreen.detail.discountCode"),
              type: "Input",
              colSize: 6,
              value: discountSelected?.discountByCode,
              dflt: discountSelected?.discountByCode,
              props: {
                readOnly: true,
              },
            },
            {
              name: "discountValue",
              title: t("inscriptionsScreen.detail.discount"),
              placeHolder: t("inscriptionsScreen.detail.discountCode"),
              type: "Input",
              colSize: 6,
              value: `- ${discountSelected?.value} ${
                !discountSelected?.type || discountSelected?.type === "PCT"
                  ? "%"
                  : "€"
                }`,
              value: `- ${discountSelected?.value} ${
                !discountSelected?.type || discountSelected?.type === "PCT"
                  ? "%"
                  : "€"
                }`,
              props: {
                readOnly: true,
              },
            },
          ],
        },
      ]
    : [];

  const teamFields =
    ModalityTeamTypes.length > 0
      ? !isTeamInscription
        ? [
          {
            fields: [
              {
                type: "Label",
                title: t("inscriptionsScreen.detail.teamInfo"),
                color: "#2185d0",
              },
            ],
          },
          {
            fields: (state) => [
              {
                name: "inscription.teamId",
                title: t("inscriptionsScreen.detail.selectTeamType"),
                placeHolder: t("inscriptionsScreen.detail.selectTeamType"),
                type: "Select",
                colSize: 7,
                data: ModalityTeamTypes.map((teamType) => ({
                  text: teamType.name,
                  value: teamType.id,
                })),
              },
              {
                name: "inscription.teamName",
                title: t("inscriptionsScreen.detail.teamName"),
                placeHolder: t("inscriptionsScreen.detail.teamName"),
                type: "Input",
                colSize: 7,
              },
              {
                name: "teamSize",
                title: t("inscriptionsScreen.detail.teamSize", {
                  between: `${
                    state["inscription.teamId"]
                      ? getMinAndMaxOfTeam(state)
                      : ""
                    }`,
                }),
                placeHolder: t("inscriptionsScreen.detail.teamSize", {
                  between: `${
                    state["inscription.teamId"]
                      ? getMinAndMaxOfTeam(state)
                      : ""
                    }`,
                }),
                type: "Input",
                colSize: 4,
                props: {
                  type: "number",
                },
                validation: {
                  custom: (vals) => {
                    if (vals["inscription.teamId"]) {
                      const { minAthletes, maxAthletes } = teamSelected(vals);

                      return (
                        minAthletes <= vals.teamSize &&
                        vals.teamSize <= maxAthletes
                      );
                    }

                    return true;
                  },
                  errorMessage: t("inscriptionsScreen.detail.teamSizeError"),
                },
              },
            ],
          },
        ]
        : [
          {
            fields: [
              {
                type: "Label",
                title: t("inscriptionsScreen.detail.teamInfo"),
                color: "#2185d0",
              },
            ],
          },
          {
            fields: [
              {
                name: "inscription.teamId",
                title: t("inscriptionsScreen.detail.teamType"),
                type: "Input",
                colSize: 7,
                dflt: teamSelectedEnd.name,
                props: {
                  value: teamSelectedEnd.name,
                  readOnly: true,
                },
              },
              {
                name: "inscription.teamName",
                title: t("inscriptionsScreen.detail.teamName"),
                type: "Input",
                colSize: 7,
                value: teamData.teamName,
                dflt: teamData.teamName,
                props: {
                  readOnly: true,
                },
              },
              {
                name: "teamSize",
                title: t("inscriptionsScreen.detail.teamSize"),
                type: "Input",
                colSize: 7,
                value: teamData.teamMembers,
                dflt: teamData.teamMembers,
                props: {
                  readOnly: true,
                },
              },
            ],
          },
        ]
      : [];

  const priceFields =
    prices.length > 0
      ? prices.length > 1
        ? [
          {
            fields: [
              {
                type: "Label",
                title: t("inscriptionsScreen.detail.selectPrice"),
                color: "#2185d0",
              },
            ],
          },
          {
            fields: [
              {
                name: "inscription.price",
                title: t("inscriptionsScreen.detail.selectPrice"),
                placeHolder: t("inscriptionsScreen.detail.selectPrice"),
                type: "Select",
                colSize: 7,
                data: prices.map((price) => ({
                  text: `${price.name} (${price.amount}€)`,
                  value: `${price.id}`,
                })),
                validation: {
                  required: true,
                  errorMessage: t("commons.fieldRequired"),
                },
              },
            ],
          },
        ]
        : [
          {
            fields: [
              {
                type: "Label",
                title: t("inscriptionsScreen.detail.selectPrice"),
                color: "#2185d0",
              },
            ],
          },
          {
            fields: [
              {
                name: "price",
                title: t("inscriptionsScreen.detail.selectPrice"),
                placeHolder: t("inscriptionsScreen.detail.selectPrice"),
                type: "Input",
                // value: `${prices[0].name} (${prices[0].amount}€)`,
                // dflt: `${prices[0].name} (${prices[0].amount}€)`,
                colSize: 7,
                props: {
                  readOnly: true,
                },
                validation: {
                  required: true,
                  errorMessage: t("commons.fieldRequired"),
                },
              },
            ],
          },
        ]
      : [];

  const basicFields = [
    {
      fields: [
        {
          type: "Label",
          title: t("inscriptionsScreen.detail.inscriptionInfo"),
          color: "#2185d0",
        },
      ],
    },
    {
      fields: [
        {
          name: "athlete.name",
          type: "Input",
          title: t("inscriptionsScreen.detail.firstName"),
          placeHolder: t("inscriptionsScreen.detail.firstName"),
          colSize: 16,
          validation: {
            required: true,
            regexType: "text",
            errorMessage: t("commons.fieldMustBeFilled"),
          },
        },
        {
          name: "athlete.surnames",
          type: "Input",
          title: t("inscriptionsScreen.detail.lastName"),
          placeHolder: t("inscriptionsScreen.detail.lastName"),
          colSize: 16,
          validation: {
            required: true,
            regexType: "text",
            errorMessage: t("commons.fieldMustBeFilled"),
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "athlete.email",
          type: "Input",
          title: t("inscriptionsScreen.detail.email"),
          placeHolder: t("inscriptionsScreen.detail.email"),
          colSize: 16,
          validation: {
            required: true,
            regexType: "email",
            errorMessage: t("commons.INVALID_EMAIL"),
          },
        },
        {
          name: "athlete.birthday",
          type: "DatePicker",
          title: t("inscriptionsScreen.detail.birthday"),
          placeHolder: t("inscriptionsScreen.detail.birthday"),
          colSize: 16,
          props: {
            id: "0",
          },
          validation: {
            required: true,
            custom: (vals) => {
              if (hasAgeRestrictions) {
                const birthdayDate = new Date(vals["athlete.birthday"]);
                const minDate =
                  inscriptions.birthMin && new Date(inscriptions.birthMin);
                const maxDate =
                  inscriptions.birthMax && new Date(inscriptions.birthMax);

                return {
                  valid: dateIsBetween(minDate, maxDate, birthdayDate),
                  errorMessage: getBirthdayErrorMessage(
                    // t,
                    hasAgeRestrictions,
                    inscriptions
                  ),
                };
              } else {
                if (
                  vals["athlete.birthday"] === "" ||
                  vals["athlete.birthday"] === null
                ) {
                  return {
                    valid: false,
                    errorMessage: getBirthdayErrorMessage(
                      // t,
                      hasAgeRestrictions,
                      inscriptions
                    ),
                  };
                }

                const valid = moment(vals["athlete.birthday"]).isBetween(
                  moment().subtract(100, "years").format("YYYY-MM-DD"),
                  moment().subtract(1, "years").format("YYYY-MM-DD")
                );

                return {
                  valid,
                  errorMessage: t(
                    "inscriptionsScreen.detail.birthdayShouldBeBetween.both",
                    {
                      minDate: moment().subtract(100, "years").format("L"),
                      maxDate: moment().subtract(1, "years").format("L"),
                    }
                  ),
                };
              }
            },
          },
        },
        {
          name: "athlete.dni",
          type: "Input",
          title: t("inscriptionsScreen.detail.dni"),
          placeHolder: t("inscriptionsScreen.detail.dni"),
          colSize: 16,
          validation: {
            required: under14_dni,
            custom: (vals) => {
              return new Date(vals["athlete.birthday"]) > fourteeenYearsAgo ?
               checkNIF(vals["athlete.dni"], ["DNI", "NIE"], under14_dni) :
               checkNIF(vals["athlete.dni"], ["DNI", "NIE"]);
            },
            errorMessage: t("commons.invalidCiforNif"),
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "athlete.gender",
          type: "RadioButtons",
          title: t("inscriptionsScreen.detail.gender"),
          radios: [
            {
              value: "MALE",
              label: t("inscriptionsScreen.detail.male"),
            },
            {
              value: "FEMALE",
              label: t("inscriptionsScreen.detail.female"),
            },
          ],
          colSize: 16,
          validation: {
            custom: (vals) => {

              if(!vals['athlete.gender']){
                return false
              }

              const isTeamInscription =
                vals["inscription.teamName"] != null && vals["inscription.teamId"] != null;
            
              if (isTeamInscription) {
                const team = modality.ModalityTeamTypes.find(
                  (teamType) => teamType.id == vals["inscription.teamId"]
                );
            
                //check gender type
                if (
                  team &&
                  team.gender != "BOTH" &&
                  vals["athlete.gender"] != team.gender
                ) {
                  return {
                    valid: false,
                    errorMessage: `${t("inscriptionsScreen.detail.teamGender")} ${t(
                      `commons.gender.${team.gender}`
                    )}`,
                  };
                }
              }
            
              return true;
            },
            // required: true,
            errorMessage: t("commons.fieldRequired"),
          },
        },
        ...(selectClub
          ? [{
            name: "club",
            component: (_, defauld, onChange) => (
              <div className={"sixteen wide field"}>
                <label>{t("inscriptionsScreen.detail.club")}</label>
                <SearchInput
                  onChange={(data) => {
                    if (data) {
                      onChange(data);
                    } else {
                      if (_.club) {
                        onChange("");
                      }
                    }
                  }}
                  data={clubs}
                  placeholder={t("clientScreen.detail.club")}
                  value={defauld && defauld.club ? defauld.club : ""}
                  icon="shield"
                  noResultsMessage={t("inscriptionsScreen.detail.clubNotFound")}
                />
              </div>
            ),
            colSize: 16
          }
          ]
          : [nullObject]),
      ],
    },
  ];

  const extraFields = areAdditionalFields
    ? [
      {
        fields: [
          {
            type: "Label",
            title: t("inscriptionsScreen.detail.inscriptionOther"),
            color: "#2185d0",
          },
        ],
      },
      {
        fields: [
          confirmEmail
            ? {
              name: "confirmEmail",
              title: t("inscriptionsScreen.detail.confirmEmail"),
              placeHolder: t("inscriptionsScreen.detail.confirmEmail"),
              colSize: 16,
              type: "Input",
              validation: {
                required: true,
                custom: (vals) => {
                  return vals["athlete.email"] === vals.confirmEmail;
                },
                errorMessage: t("commons.emailMustBeTheSame"),
              },
            }
            : nullObject,
        ],
      },
      {
        fields: state => [
          address
            ? {
              name: "athlete.address",
              title: t("inscriptionsScreen.detail.address"),
              placeHolder: t("inscriptionsScreen.detail.address"),
              colSize: 16,
              type: "Input",
              validation: {
                required: true,
                regexType: "address",
                errorMessage: t("commons.fieldMustBeFilled"),
              },
            }
            : nullObject,
          location1
            ? {
              name: "athlete.country",
              title: t("inscriptionsScreen.detail.country"),
              placeHolder: t("inscriptionsScreen.detail.country"),
              colSize: 16,
              type: "Select",
              validation: {
                required: true,
                errorMessage: t("commons.fieldRequired"),
              },
              data: countries.map((country) => ({
                text: t(`countries.${country}`),
                value: country,
              })),
            }
            : nullObject,
          ...(location2
            ? [
              {
                name: "athlete.country",
                title: t("inscriptionsScreen.detail.country"),
                placeHolder: t("inscriptionsScreen.detail.country"),
                colSize: 16,
                type: "Select",
                validation: {
                  required: true,
                  errorMessage: t("commons.fieldRequired"),
                },
                data: countries.map((country) => ({
                  text: t(`countries.${country}`),
                  value: country,
                })),
              },
              {
                name: "athlete.region",
                type: state["athlete.country"] == 'ES' ? "Select" : "Input",
                data: state["athlete.country"] == 'ES' ? provinces.map(province => ({
                  text: province.title,
                  value: province.title
                })) : null,
                props: {
                  clearable: true
                },
                title: t("companyDetail.province"),
                placeHolder: t("companyDetail.province"),
                colSize: 16,
                validation: {
                  required: true,
                  regexType: "text",
                  errorMessage: t("commons.fieldMustBeFilled"),
                },
              },
            ]
            : [nullObject]),
          ...(location3
            ? [
              {
                name: "athlete.country",
                title: t("inscriptionsScreen.detail.country"),
                placeHolder: t("inscriptionsScreen.detail.country"),
                colSize: 16,
                type: "Select",
                validation: {
                  required: true,
                  errorMessage: t("commons.fieldRequired"),
                },
                data: countries.map((country) => ({
                  text: t(`countries.${country}`),
                  value: country,
                })),
              },
              {
                name: "athlete.region",
                type: state["athlete.country"] == 'ES' ? "Select" : "Input",
                data: state["athlete.country"] == 'ES' ? provinces.map(province => ({
                  text: province.title,
                  value: province.title
                })) : null,
                props: {
                  clearable: true
                },
                title: t("companyDetail.province"),
                placeHolder: t("companyDetail.province"),
                colSize: 16,
                validation: {
                  required: true,
                  regexType: "text",
                  errorMessage: t("commons.fieldMustBeFilled"),
                },
              },
              {
                name: "athlete.city",
                title: t("inscriptionsScreen.detail.city"),
                placeHolder: t("inscriptionsScreen.detail.city"),
                colSize: 16,
                type: "Input",
                validation: {
                  required: true,
                  regexType: "text",
                  errorMessage: t("commons.fieldMustBeFilled"),
                },
              },
            ]
            : [nullObject]),
          zipCode
            ? {
              name: "athlete.zipCode",
              title: t("inscriptionsScreen.detail.zipCode"),
              placeHolder: t("inscriptionsScreen.detail.zipCode"),
              colSize: 16,
              type: "Input",
              validation: {
                required: true,
                errorMessage: t("commons.fieldRequired"),
              },
            }
            : nullObject,
          originCountry
            ? {
              name: "athlete.originCountry",
              title: t("inscriptionsScreen.detail.originCountry"),
              placeHolder: t("inscriptionsScreen.detail.originCountry"),
              colSize: 16,
              type: "Select",
              validation: {
                required: true,
                errorMessage: t("commons.fieldRequired"),
              },
              data: countries.map((country) => ({
                text: t(`countries.${country}`),
                value: country,
              })),
            }
            : nullObject,
        ],
      },
      {
        fields: [
          phonePrefix
            ? {
              name: "athlete.phonePrefix",
              title: t("inscriptionsScreen.detail.phonePrefix"),
              placeHolder: t("inscriptionsScreen.detail.phonePrefix"),
              colSize: 16,
              type: "Input",
              validation: {
                required: true,
                regexType: "phone_prefix",
                errorMessage: t("commons.fieldMustBeFilled"),
              },
            }
            : nullObject,
          mobile
            ? {
              name: "athlete.mobile",
              title: t("inscriptionsScreen.detail.phone"),
              placeHolder: t("inscriptionsScreen.detail.phone"),
              colSize: 16,
              type: "Input",
              validation: {
                required: true,
                regexType: "phone",
                errorMessage: t("commons.fieldMustBeFilled"),
              },
            }
            : nullObject,
          emergencyMobile
            ? {
              name: "athlete.emergencyMobile",
              title: t("inscriptionsScreen.detail.emergencyPhone"),
              placeHolder: t("inscriptionsScreen.detail.emergencyPhone"),
              colSize: 16,
              type: "Input",
              validation: {
                required: true,
                regexType: "phone",
                errorMessage: t("commons.fieldMustBeFilled"),
              },
            }
            : nullObject,
        ],
      },
      {
        fields: [
          federatedParticipant
            ? {
              name: "athlete.federatedParticipant",
              title: t("inscriptionsScreen.detail.federatedParticipant"),
              placeHolder: t(
                "inscriptionsScreen.detail.federatedParticipant"
              ),
              colSize: 16,
              type: "Checkbox",
              props: {
                style: {
                  marginTop: 10,
                },
              },
            }
            : nullObject,
          localParticipant
            ? {
              name: "athlete.localParticipant",
              title: t("inscriptionsScreen.detail.localParticipant"),
              placeHolder: t("inscriptionsScreen.detail.localParticipant"),
              colSize: 16,
              type: "Checkbox",
              props: {
                style: {
                  marginTop: 10,
                },
              },
            }
            : nullObject,
        ],
      },
      {
        fields: (state) => {
          const validationOfAge =
            !state["athlete.birthday"] ||
            (new Date(state["athlete.birthday"]) > eighteenYearsAgo);

          if (!parentalLeave) return [];
          if (!validationOfAge) return [];
          return [
            {
              name: "athlete.parentName",
              title: t("inscriptionsScreen.detail.parentName"),
              placeHolder: t("inscriptionsScreen.detail.parentName"),
              colSize: 16,
              type: "Input",
              validation: {
                required: true,
                regexType: "text",
                errorMessage: t("commons.fieldMustBeFilled"),
              },
            },
            {
              name: "athlete.parentSurnames",
              title: t("inscriptionsScreen.detail.parentSurnames"),
              placeHolder: t("inscriptionsScreen.detail.parentSurnames"),
              colSize: 16,
              type: "Input",
              validation: {
                required: true,
                regexType: "text",
                errorMessage: t("commons.fieldMustBeFilled"),
              },
            },
            {
              name: "athlete.parentDni",
              title: t("inscriptionsScreen.detail.parentDni"),
              placeHolder: t("inscriptionsScreen.detail.parentDni"),
              colSize: 16,
              type: "Input",
              validation: {
                required: true,
                custom: (values) => {
                  if (
                    !checkNIF(values[`athlete.parentDni`], ["DNI", "NIE"])
                  ) {
                    return false;
                  } else if (
                    values[`athlete.parentDni`] === values[`athlete.dni`]
                  ) {
                    return false;
                  }

                  return true;
                },
                errorMessage: t("commons.invalidParentCiforNif"),
              },
            },
          ];
        },
      },
      {
        fields: (state) => [
          photo
            ? {
              name: "athlete.photo",
              component: (_, __, onChange) => (
                <ImagePicker
                  content={t("inscriptionsScreen.detail.cardPhoto")}
                  showLabelToOpen={false}
                  onSelect={(data) => onChange(data)}
                  imageSrc={state["athlete.photo"]}
                  markAsRequired={showValidation && !state["athlete.photo"]}
                />
              ),
              validation: {
                required: true,
                errorMessage: t("commons.fieldRequired"),
              },
            }
            : nullObject,
        ],
      },
    ]
    : [];

  const extraProps = (link, moreInfo) => ({
    ...(moreInfo || link
      ? {
        action: {
          icon: "plus",
          onClick: () => {
            modalHandler.open(modalOptions(link, moreInfo));
          },
        },
      }
      : {}),
  });

  const extraInfoButton = (link, moreInfo) => (
    <div
      className="one wide field"
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        paddingRight: ".5em",
      }}
    >
      <Button
        style={{ margin: 0 }}
        icon="plus"
        onClick={() => modalHandler.open(modalOptions(link, moreInfo))}
      ></Button>
    </div>
  );

  const extraAttributes =
    attributes.length > 0
      ? [
        {
          fields: [
            {
              type: "Label",
              title: t("inscriptionsScreen.detail.inscriptionExtra"),
              color: "#2185d0",
            },
          ],
        },
        ...attributes.map((attribute) => {
          const {
            name,
            description,
            required,
            id,
            type,
            maxLength,
            price,
            ModalityAttributesOptions,
            moreInfo,
            link,
          } = attribute;

          // try {
          //   ModalityAttributesOptions.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          // } catch (error) {
          // }

          switch (type) {
            case "STRING":
              return {
                fields: [
                  {
                    name: `inscription.attrs.${id}`,
                    title: name,
                    placeHolder: description,
                    colSize: 16,
                    type: "Input",
                    validation: {
                      required,
                      custom: (vals) => {
                        const val = vals[`inscription.attrs.${id}`];
                        // const result = (val === "" || null === val) ||(val||"").toString().match(regex.attr_alphanumeric);
                        // if (!result) {
                        //   return false;
                        // }

                        if (val) {
                          return val.length <= maxLength;
                        }

                        return true;
                      },
                      errorMessage: t("commons.maxLength", {
                        maxLength,
                      }),
                    },
                    props: extraProps(link, moreInfo),
                  },
                ],
              };
            case "NUMBER":
              return {
                fields: [
                  {
                    name: `inscription.attrs.${id}`,
                    title: name,
                    placeHolder: description,
                    colSize: 16,
                    type: "Input",
                    validation: {
                      required,
                      errorMessage: t("commons.fieldRequired"),
                    },
                    props: { type: "number", ...extraProps(link, moreInfo) },
                  },
                ],
              };
            case "BOOLEAN":
              return {
                fields: [
                  {
                    name: `inscription.attrs.${id}`,
                    title: price ? `${name} (${price}€)` : name,
                    placeHolder: description,
                    colSize: link || moreInfo ? 15 : 16,
                    type: "Checkbox",
                    props: {
                      style: {
                        alignItems: "center",
                      },
                    },
                  },
                  {
                    ...(link || moreInfo
                      ? {
                        colSize: 1,
                        component: () => extraInfoButton(link, moreInfo),
                      }
                      : {
                        component: () => <></>,
                      }),
                  },
                ],
              };
            case "SELECT":
              return {
                fields: ModalityAttributesOptions.length > 0 ? [
                   {
                    name: `inscription.attrs.${id}`,
                    title: name,
                    placeHolder: description,
                    colSize: link || moreInfo ? 15 : 16,
                    type: "Select",
                    data: ModalityAttributesOptions.map((option) => ({
                      text: option.name,
                      value: option.id,
                    })),
                    validation: {
                      required,
                      errorMessage: t("commons.fieldRequired"),
                    },
                  },
                  {
                    ...(link || moreInfo
                      ? {
                        colSize: 1,
                        component: () => extraInfoButton(link, moreInfo),
                      }
                      : {
                        component: () => <></>,
                      }),
                  },
                ]
                :[],
              };
            case "PRODUCT":
              return {
                fields: ModalityAttributesOptions.length > 0 ? [
                   {
                    name: `inscription.attrs.${id}`,
                    title: name,
                    placeHolder: description,
                    colSize: link || moreInfo ? 15 : 16,
                    type: "Select",
                    data: ModalityAttributesOptions.map((option) => ({
                      text: `${option.name} (${option.price}€)`,
                      value: option.id,
                    })),
                    validation: {
                      required,
                      errorMessage: t("commons.fieldRequired"),
                    },
                  },
                  {
                    ...(link || moreInfo
                      ? {
                        colSize: 1,
                        component: () => extraInfoButton(link, moreInfo),
                      }
                      : {
                        component: () => <></>,
                      }),
                  },
                ]
                :[],
              };
          }
        }),
      ]
      : [];

  return [
    ...discountFields,
    ...teamFields,
    ...priceFields,
    ...basicFields,
    ...extraFields,
    ...extraAttributes,
  ];
};

export const paymentStatus = (t) => [
  {
    fields: [
      {
        name: "paymentStatus",
        title: t("inscriptionsScreen.detail.paymentStatus"),
        value: "PENDING",
        type: "Select",
        data: [
          {
            text: t("paymentStatus.PAID"),
            value: "PAID",
          },
          {
            text: t("paymentStatus.PENDING"),
            value: "PENDING",
          },
          {
            text: t("paymentStatus.DENIED"),
            value: "REJECTED",
          },
        ],
        colSize: 16,
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired"),
        },
      },
    ],
  },
];

export const teamNameChange = (t) => [
  {
    fields: [
      {
        name: "teamName",
        title: t("inscriptionsScreen.detail.teamName"),
        placeHolder: t("inscriptionsScreen.detail.teamName"),
        type: "Input",
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired"),
        },
        colSize: 16,
      },
    ],
  },
];

export const inscriptionDoneHeader = (t) => [
  {
    content: t("inscriptionsScreen.detail.firstName"),
    accessor: "name",
  },
  {
    content: t("inscriptionsScreen.detail.lastName"),
    accessor: "surnames",
  },
  {
    content: t("inscriptionsScreen.detail.email"),
    accessor: "email",
  },
  // {
  //   content: t("inscriptionsScreen.detail.dorsalAssigned"),
  //   accessor: "dorsalAssigned",
  // }, //using trigger to assign dorsal doesnt return dorsal at this point
];

export const changeDni = (t) => [
  {
    fields: [
      {
        name: "dni",
        type: "Input",
        title: t("inscriptionsScreen.detail.dni"),
        colSize: 16,
        validation: {
          required: true,
          custom: (vals) => {
            return checkNIF(vals.dni, ["DNI", "NIE"]);
          },
          errorMessage: t("commons.invalidCiforNif"),
        },
      },
    ],
  },
];

export const changeDiscount = (t, bookDiscount) => [
  {
    fields: [
        {
          name: "discountCode",
          title: t("inscriptionsScreen.detail.discount"),
          placeHolder: t("inscriptionsScreen.detail.discountCode"),
          type: "Input",
          colSize: 8,
        },
        {
          name: "discount",
          component: (vals, _, onChange) => (
            <Button
              style={{
                maxHeight: "3em",
                marginTop: 20,
              }}
              primary
              onClick={async () => {
                if (!vals.discountCode) {
                  return;
                }

                const discount = await bookDiscount(vals.discountCode, t);
                
                onChange(discount);
              }}
            >
              {t("inscriptionsScreen.detail.validateDiscount")}
            </Button>
          ),
        },
      ],
  },
];
