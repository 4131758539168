import React, { Component } from "react";
import { Container, Button, Icon, Grid } from "semantic-ui-react";
import DTable from "../../../components/DTable";
import ModalitySubtitle from "../../../components/ModalitySubtitle";
import teamTypeApi from "../../../apis/teamTypes";
import { connect, ReactReduxContext } from "react-redux";
import { withTranslation } from "react-i18next";
import { modalHandler } from "../../../components/DModal";
import Form from "../../../components/Form";
import ButtonGroup from "../../../components/ButtonGroup";
import FormTeam from "./FormTeam";
import update from "react-addons-update";
import { DAlert } from "../../../components/Dalert";
import FluidResponsiveButton from "../../../components/FluidResponsiveButton";
import AsyncButton from "../../../components/AsyncButton";

@connect((state) => ({
  modality: state.modalities.actualModality,
  race: state.races.actualRace,
}))
@withTranslation()
export default class TeamTypes extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      formData: {},
      showValidation: false,
    };

    this.addNew = this.addNew.bind(this);
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.getData({});
  }

  async getData(params) {
    const { id } = this.props;

    const result = await teamTypeApi.getTeamTypes(id, params);

    this.setState({ data: result });
  }

  async save(key) {
    const { formData, data } = this.state;
    const { id, t } = this.props;

    this.setState({ showValidation: true });
    if (formData.ISFORMVALID) {
      const formDataParse = {
        ...formData,
        maxAthletes: parseInt(formData.maxAthletes),
        minAthletes: parseInt(formData.minAthletes),
      };
      if (typeof key == "number") {
        const result = await teamTypeApi.update(data[key].id, formDataParse);

        const newData = update(data, { [key]: { $merge: result } });

        this.setState({ data: newData, showValidation: false });

        modalHandler.close();

        DAlert.success({ title: t("commons.savedSuccess") });

        return;
      } else {
        const result = await teamTypeApi.create(id, formDataParse);

        if (result.id) {
          const newData = [result, ...data];

          this.setState({ data: newData, showValidation: false });

          modalHandler.close();
          DAlert.success({ title: t("commons.savedSuccess") });
        }

        return;
      }
    }

    modalHandler.refreshOptions({
      content: (
        <FormTeam
          showValidation
          onFormChange={(formData) => this.setState({ formData })}
        />
      ),
    });
  }

  addNew() {
    const { t } = this.props;
    modalHandler.open({
      header: t("modalityTeamType.addNewTeam"),
      content: (
        <FormTeam onFormChange={(formData) => this.setState({ formData })} />
      ),
      actions: (
        <AsyncButton color="blue" onClick={() => this.save()}>
          <Icon name="save" /> {t("commons.save")}
        </AsyncButton>
      ),
    });
  }

  edit(row, key, flag) {
    const { t, race } = this.props;

    modalHandler.open({
      header: t("modalityTeamType.addNewTeam"),
      content: (
        <FormTeam
          flag={flag || race.deleted}
          defaultState={row}
          onFormChange={(formData) => this.setState({ formData })}
        />
      ),
      actions: (
        <Grid>
          <Grid.Column width="3">
            {!race.deleted && (
              <AsyncButton
                content={t("commons.delete")}
                disabled={flag}
                icon="trash alternate outline"
                floated={"left"}
                color={"red"}
                onClick={() => this.delete(row.id)}
              />
            )}
          </Grid.Column>
          <Grid.Column width="13">
            <Button color={"red"} onClick={() => modalHandler.close()}>
              {t("commons.cancel")}
            </Button>
            {!race.deleted && (
              <AsyncButton
                disabled={flag}
                color={"vk"}
                onClick={() => this.save(key)}
              >
                {t("commons.save")}
              </AsyncButton>
            )}
          </Grid.Column>
        </Grid>
      ),
    });
    if (flag) DAlert.info({ title: t("modalityTeamType.info") });
  }

  async delete(id) {
    const { t } = this.props;

    const modalConfirm = {
      header: t("commons.areYouSure"),
      content: t("modalityTeamType.delete"),
      onYes: async () => {
        const res = await teamTypeApi.delete(id);
        modalHandler.close();
        this.getData({});
        if (res.ok) {
          DAlert.success({ title: t("commons.erasedSuccessfully") });
        } else {
          DAlert.error({
            title: t("commons.serverError"),
            message: t("commons.serverErrorMessage"),
          });
        }
      },
    };

    modalHandler.open(modalConfirm);
  }

  async rowClicked(row, key) {
    const { id, t } = this.props;
    const inscriptions = await teamTypeApi.getInscriptionsByTeamId(id, row.id);
    if (inscriptions.count) {
      this.edit(row, key, true);
    } else {
      this.edit(row, key, false);
    }
  }

  render() {
    const { t, race } = this.props;
    const { data } = this.state;

    return (
      <Container>
        <ModalitySubtitle
          icon="users"
          title="raceDetail.menu.modality.teamTypes"
        />

        <DTable
          headers={[
            { accessor: "name", content: t("modalityTeamType.table.name") },
            { accessor: "gender", content: t("modalityTeamType.table.gender") },
            {
              accessor: "minAthletes",
              content: t("modalityTeamType.table.minAthletes"),
            },
            {
              accessor: "maxAthletes",
              content: t("modalityTeamType.table.maxAthletes"),
            },
          ]}
          onTableChange={(table) => this.getData(table)}
          onRowClick={(row, key) => this.rowClicked(row, key)}
          parseData={(row) => ({
            gender: t(`commons.gender.${row.gender}`),
          })}
          contentData={data}
          showPagination
          showTotalItems
          totalData={data.length}
        />

        {!race.deleted && (
          <FluidResponsiveButton
            icon
            labelPosition="left"
            size="small"
            primary
            onClick={async () => await this.addNew()}
          >
            <Icon name="add" />
            {t("commons.new")}
          </FluidResponsiveButton>
        )}
      </Container>
    );
  }
}
