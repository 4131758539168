import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Tab, Button, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";

import raceApi from "../../../apis/races";
import { updateRace } from "../../../reducers/races";

import { DAlert } from "../../../components/Dalert";
import { modalHandler } from "../../../components/DModal";
import { tabs } from "./util";

const saveMethod = "SAVE";
const publishMethod = "PUBLISH";

@withTranslation()
@connect(
  (state) => ({
    race: state.races.actualRace,
    rol: state.user.role
  }),
  (dispatch) => ({
    actions: bindActionCreators({ updateRace }, dispatch),
  })
)
export default class RaceEdit extends Component {
  constructor(props) {
    super(props);

    this.deleteRaceHandler = this.deleteRaceHandler.bind(this);
    this.saveRaceHandler = this.saveRaceHandler.bind(this);
    this.saveRace = this.saveRace.bind(this);
    this.state = {
      totalInscriptions: 0,
    };
  }

  _getComissionType(raceToSave) {
    if (raceToSave.inscription_commission) {
      let inscriptionComissionType =
        raceToSave.inscription_commission_type || "PCT";
      raceToSave = {
        ...raceToSave,
        inscription_commission_type: inscriptionComissionType,
      };
    }

    return raceToSave;
  }

  async saveRaceHandler(type) {
    const { race, actions, t } = this.props;

    const confirmValidation = !!race?.ISFORMVALID;

    if (!confirmValidation) {
      DAlert.error({ message: t("commons.fieldsRequired") });
      actions.updateRace({ ...race, ISFORMVALID: false });
      return;
    }

    try {
      let raceToSave = { ...race };
      raceToSave = this._getComissionType(race);

      if (type == "PUBLISH") {
        modalHandler.open({
          header: !race.is_published
            ? t("raceEdit.publishRace")
            : t("raceEdit.notPublishRace"),
          content: !race.is_published
            ? t("raceEdit.areYouSureToPublish")
            : t("raceEdit.areYouSureToNotPublish"),
          basic: false,
          size: "tiny",
          onYes: async () => {
            if (race.is_published) {
              raceToSave = {
                ...raceToSave,
                is_published: false,
              };
              this.saveRace(raceToSave);
            } else {
              const modalities = await raceApi.fetchModalities({ id: race.id })
              if (modalities.length) {
                const validation = modalities.some((modality) => {
                  return modality.is_published == true
                })
                if (validation) {
                  raceToSave = {
                    ...raceToSave,
                    is_published: true,
                  };
                  this.saveRace(raceToSave);
                } else {
                  DAlert.info({ title: t("raceEdit.noPublishModality") });
                }

              } else {
                DAlert.info({ title: t("raceEdit.noPublishModality") });
              }
            }
            modalHandler.close();
          }

        });
      } else {
        this.saveRace(raceToSave);
      }
    } catch (err) {
      DAlert.error({ title: t("commons.errorSaving") });
    }
  }

  async componentDidMount() {
    const { race } = this.props;
    await this.getInscriptions(race);
  }

  async getInscriptions(race) {
    const data = await raceApi.fetchInscriptions({ id: race.id });
    this.setState({ totalInscriptions: data.count });
  }

  async saveRace(raceToSave) {
    const { actions, t } = this.props;

    try {
      const raceUpdated = await raceApi.updateRace({ ...raceToSave });

      actions.updateRace({
        ...raceUpdated,
      });
      DAlert.success({ title: t("commons.savedSuccess") });
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        },
      } = error;

      if (message === "RACE_MAX_INSCRIPTIONS") {
        DAlert.error({
          message: t("racesScreen.detail.notValidMaxInscriptions"),
        });
      }
    }
  }

  deleteRaceHandler() {
    const { t, history } = this.props;
    const { totalInscriptions } = this.state;

    modalHandler.open({
      header: null,
      content:
        totalInscriptions > 0 ? (
          <div>
            <h3>{t("commons.areYouSure")}</h3>
            <label>
              {t("raceEdit.areYouSureToDeletRace", { totalInscriptions })}
            </label>
          </div>
        ) : (
            <h3>{t("commons.areYouSure")}</h3>
          ),
      basic: false,
      size: "tiny",
      onYes: async () => {
        const result = await raceApi.delete({ id: this.props.race.id });
        if (result.success) {
          DAlert.success({ title: t("raceEdit.deletedSuccess") });
          history.push(`/races`);
        } else {
          DAlert.error({ title: t("raceEdit.cantDelete") });
        }
        modalHandler.close();
      },
    });
  }

  render() {
    const { t, history, race, rol } = this.props;

const canDelete = !race.deleted && (rol==="ADMINISTRATOR" || rol==="COMPANY")

    return (
      <Container>
        <Tab
          panes={tabs(t)}
          menu={{ attached: true, tabular: true, className: "wrappedTabs" }}
        />
        <Grid container columns={2} padded>
          <Grid.Row>
            <Grid.Column computer={10}>
              {!race.deleted && (
                <Button
                  color="vk"
                  style={{ marginRight: 10, marginBottom: 10 }}
                  onClick={() => this.saveRaceHandler(saveMethod)}
                >
                  {t("commons.save")}
                </Button>
              )}
              {!race.deleted && (
                <Button
                  color="vk"
                  style={{ marginRight: 10, marginBottom: 10 }}
                  onClick={() => this.saveRaceHandler(publishMethod)}
                >
                  {t(
                    `raceEdit.buttons.${
                      !race?.is_published
                        ? "saveAndPublish"
                        : "saveAndNotPublish"
                    }`
                  )}
                </Button>
              )}
              <Button
                color="vk"
                onClick={() => history.push(`/race/${race?.id}#summary`)}
              >
                {t("commons.cancel")}
              </Button>
            </Grid.Column>
            {canDelete && (
              <Grid.Column computer={6}>
                <Button
                  floated="right"
                  color="google plus"
                  onClick={this.deleteRaceHandler}
                >
                  {t("raceEdit.delete")}
                </Button>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
