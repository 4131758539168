import axios from "axios";
import { DAlert } from "../components/Dalert";
import i18n from "../locales";
import { noMatchHandler } from "../components/NoMatch";
import cookie from "js-cookie";

const { API_URL } = process.env;

export let url = API_URL || "http://localhost:10010";

let headers = {
  Accept: ["application/json", "multipart/form-data"],
  "Content-Type": "application/json",
  "x-origin": "backoffice",
  "ChannelToken": 'Bearer i4JLNfwSQF6gp8On',
};

const default_config = {
  baseURL: url,
  headers
};

const timeout = 5000;

class ClientAPI {
  constructor() {
    this.client = axios.create({
      ...default_config,
      timeout,
      headers: {
        ...headers
      }
    });
  }

  setToken(token) {
    this.client = axios.create({
      ...default_config,
      timeout,
      headers: {
        authorization: `Bearer ${token}`,
        ...headers
      }
    });
  }

  showNetworkMessage() {
    DAlert.error({
      title: i18n.t("commons.networkError"),
      message: i18n.t("commons.networkErrorMessage")
    });
  }

  addMiddleWare() {
    this.client.interceptors.response.use(
      response => {
        if (response.headers["content-length"] == 0 || !response.data) {
          this.showNetworkMessage();

          return Promise.reject("Network Error");
        }

        return response;
      },
      error => {
        //update token
        console.log(error.response)
        if (
          error.response.statuss &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name == "TokenExpired"
        ) {
          cookie.remove("token");
        }

        if (!error.response) {
          this.showNetworkMessage();
        }

        if (error.response.status === 500) {
          DAlert.error({
            title: i18n.t("commons.serverError"),
            message: i18n.t("commons.serverErrorMessage")
          });
        }

        if (error.response.status === 404) {
          noMatchHandler.history?.push("/404");
        }

        if (error.response.status === 401) {
        }

        return Promise.reject(error);
      }
    );
  }

  getClient() {
    this.addMiddleWare();
    return this.client;
  }
}

const clientApi = new ClientAPI();

export { clientApi };
