import React from "react";
import { Container, Form } from "semantic-ui-react";
import Table from "../../../../components/DTable";
import { useTranslation } from "react-i18next";

import { returnMoneyHeaders } from "./util";

const CancelInscription = props => {
  const [t] = useTranslation();
  const { unCancel, inscriptions, isFinished } = props;

  const data = (inscriptions).filter(i => i.status === "CANCELLED")

  return (
    <Container>
      {!unCancel && <p>{t("inscriptionsScreen.detail.selectToCancel")}</p>}
      <Table
        headers={returnMoneyHeaders(t)}
        contentData={unCancel ? data : inscriptions}
        showItemsPerPage={false}
        showNumberOfRecords={false}
        showPagination={false}
        showTotalItems={false}
        showSelectedRow={true}
        rowProps={(data) =>
          data.status === "CANCELLED" && !unCancel
            ? { negative: true, className: "strikethrough" }
            : {}
        }
        parseData={(data) => ({
          select:
            data.status === "CANCELLED" && !unCancel ? null : (
              <Form.Checkbox
                onChange={(_, { checked }) => props.onChange(checked, data)}
              />
            ),
          price: isFinished
            ? `${parseFloat(data.price - data.total_refund).toFixed(2)} €`
            : `${parseFloat(data.price).toFixed(2)} €`,
        })}
      />
    </Container>
  );
};

export default CancelInscription;
