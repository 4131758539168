import React, { Component } from "react";
import forms from "./forms";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Container,
  Segment,
  Header,
  Button,
  Flag,
  Label,
  Divider,
  Grid,
  Image,
  Message,
  Dimmer,
  Loader,
  Icon,
  Modal
} from "semantic-ui-react";

import Form from "../../components/Form";

import ButtonGroup from "../../components/ButtonGroup";
import rolesApi from "../../apis/roles";
import usersApi from "../../apis/users";
import regionsApi from "../../apis/regions";
import configApi from "../../apis/appConfig";
import { getCountries } from "../../reducers/countries";

import { modalHandler } from "../../components/DModal";
import regions from "../../apis/regions";

const roleForm = {
  ADMINISTRATOR: forms.admin,
  COMPANY: forms.company,
  PLANNER: forms.planner,
  POINT_OF_SALE: forms.pointOfSale,
  QR_READER: forms.qrReader,
};

@connect(
  state => ({
    countries: state.countries.countries
  }),
  dispatch => ({
    actions: bindActionCreators({ getCountries }, dispatch)
  })
)
@withTranslation()
export default class RegisterFactory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      token: { value: "", role: "" },
      showValidation: false,
      loading: false,
      isValid: true,
      terms_and_conditions: "",
      errorInToken: false,
      tokenErrorCode: "",
      regions: []
    };
  }

  async componentDidMount() {
    const isTokenValid = await this.checkToken();

    if (isTokenValid.success) {
      await this.getTokenFromUrl();
      await this.getRoleByToken();
      this.fetchTerms();
      this.props.actions.getCountries(this.props.countries);
      await this.getRegions();
    }
  }

  async getRegions(){
    const regions = await regionsApi.fetchRegions();
    this.setState({ regions })
  }

  async checkToken() {
    const { token } = this.props.match.params;

    const result = await usersApi.checkToken(token);

    if (result.error) {
      this.setState({
        tokenErrorCode: result.error.message
      });
    }

    return result;
  }

  async fetchTerms() {
    const params = {
      typeof: "PANEL",
      channel_id: 1,
    };
    configApi.getTermsAndConditions(params).then(async terms => {
      if (terms.status === 200)
        this.setState({ terms_and_conditions: terms.data.terms });
    });
  }

  getTokenFromUrl = async () => {
    this.setState({
      token: { value: this.props.match.params.token, role: "" }
    });
  };

  getRoleByToken = async () => {
    const { token } = this.state;
    const tokenData = await rolesApi.fetchRoleByToken({
      token: token.value,
      channel_id: 1,
    });

    const data =
      tokenData.User.role === "COMPANY"
        ? {
            ...tokenData.User,

            companyName: tokenData.Company.name,
            id: tokenData.user_id
          }
        : { ...tokenData.User, id: tokenData.user_id };

    delete data.name;
    this.setState({
      data: { ...data, token: token.value },
      token: { value: token.value, role: tokenData.User.role }
    });
  };

  creationSuccess = async () => {
    const { t, history } = this.props;

    modalHandler.open({
      header: null,
      size: "small",
      content: t("register.success"),
      actions: (
        <Grid>
          <Grid.Column width="16">
            <Button
              fluid
              color="vk"
              onClick={() => {
                history.push("/");
                modalHandler.close();
              }}
            >
              {t("commons.ok")}
            </Button>
          </Grid.Column>
        </Grid>
      )
    });
  };

  modalTermsConditions = t => {
    const { terms_and_conditions } = this.state;
    modalHandler.open({
      header: t(`config.terms.titlePANEL`),
      size: "large",
      content: (
        <Modal.Description>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width="16">
                <div
                  style={{
                    overflow: "scroll",
                    height: "20em",
                    overflowX: "hidden"
                  }}
                  dangerouslySetInnerHTML={{
                    __html: terms_and_conditions
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      ),
      actions: (
        <Grid>
          <Grid.Column width="16">
            <Button
              fluid
              color="vk"
              onClick={() => {
                modalHandler.close();
              }}
            >
              {t("commons.close")}
            </Button>
          </Grid.Column>
        </Grid>
      )
    });
  };

  async save() {
    const { data } = this.state;

    if (data.ISFORMVALID) {
      const dni = data.dni ? data.dni.toUpperCase() : null;
      // const result =
      //   dni === null
      //     ? await usersApi
      //         .update(data.id, data)
      //         .then(() => {
      //           this.setState({
      //             loading: true
      //           });
      //           usersApi
      //             .changeStatus(data.id, "ACTIVE", data.token)
      //             .then(() => {
      //               this.creationSuccess();
      //             })
      //             .catch(e => {});
      //         })
      //         .catch(e => {
      //           this.setState({
      //             loading: false,
      //             isValid: false
      //           });
      //         })
      //     : await usersApi
      //         .update(data.id, { ...data, dni })
      //         .then(() => {
      //           this.setState({
      //             loading: true
      //           });
      //           usersApi
      //             .changeStatus(data.id, "ACTIVE", data.token)
      //             .then(() => {
      //               this.creationSuccess();
      //             })
      //             .catch(e => {});
      //         })
      //         .catch(e => {
      //           this.setState({
      //             loading: false,
      //             isValid: false
      //           });
      //         });

      const params = dni === null ? data : { ...data, dni };

      params['channel_id'] = 1;
      try {
        await usersApi.activateNewUser(data.id, params);
        this.setState({ loading: true });

        // // await usersApi.changeStatus(data.id, "ACTIVE", data.token);
        this.creationSuccess();
      } catch (error) {
        this.setState({
          loading: false,
          isValid: false
        });
      }

      return;
    }

    this.setState({ showValidation: true });
  }

  onFormChange(formData) {
    const { data } = this.state;

    if (JSON.stringify(formData) !== JSON.stringify(data)) {
      this.setState({ data: { ...data, ...formData } });
    }
  }

  render() {
    const { history, i18n, t, countries } = this.props;
    const {
      data,
      token,
      showValidation,
      loading,
      isValid,
      tokenErrorCode,
      regions,
    } = this.state;
    let selectedForm = [];
    if (token.role) {
      selectedForm = roleForm[token.role](t, countries, regions);
    }

    if (tokenErrorCode) {
      return (
        <Container style={{ margin: "3%" }}>
          <Grid.Column width={10} textAlign={"left"}>
            <Message icon error>
              <Icon name="ban" />
              <Message.Content>
                <Message.Header>
                  {t(`register.tokenErrors.errorInToken`)}
                </Message.Header>
                {t(`register.tokenErrors.${tokenErrorCode}`)}
                <Divider hidden fitted />
                <Button
                  floated="right"
                  color="vk"
                  onClick={() => history.push("/")}
                >
                  {t(`recoverPassword.goToLogin`)}
                </Button>
              </Message.Content>
            </Message>
          </Grid.Column>
        </Container>
      );
    }

    return (
      <Container style={{ margin: "3%" }}>
        {!isValid && (
          <Grid.Column width={10} textAlign={"left"}>
            <Message icon error>
              <Icon name="ban" />
              <Message.Content>
                <Message.Header>{t(`recoverPassword.header`)}</Message.Header>
                {t(`recoverPassword.message`)}
                <Divider hidden fitted />
                <Button
                  floated="right"
                  color="vk"
                  onClick={() => history.push("/")}
                >
                  {t(`recoverPassword.goToLogin`)}
                </Button>
              </Message.Content>
            </Message>
          </Grid.Column>
        )}

        {isValid && (
          <Segment basic>
            <Header size="huge">{t("register.title")}</Header>
            <Button.Group
              style={{ position: "absolute", top: 5, right: 5 }}
              toggle
            >
              <Button icon onClick={() => i18n.changeLanguage("es")}>
                <Flag name="es" style={{ margin: 1 }} />
              </Button>
              <Button icon onClick={() => i18n.changeLanguage("en")}>
                <Flag name="gb" style={{ margin: 1 }} />
              </Button>
            </Button.Group>

            <Segment basic>
              <Label
                as="a"
                // color="blue"
                ribbon="right"
                style={{
                  backgroundColor: "#4d7198",
                  color: "#fff",
                  cursor: "default"
                }}
              >
                {t("userScreen.fields.role").toLowerCase()}:{" "}
                {t(`roles.${token.role}`)}
              </Label>
              <Divider />
              <Grid>
                <Grid.Column width={3}>
                  <Image
                    src={
                      "https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg"
                    }
                    // src="https://www.thispersondoesnotexist.com/image"
                    avatar
                    size={"small"}
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width={13}>
                  <Segment basic>
                    <Header size="medium">
                      {t("userScreen.personalData")}
                    </Header>
                    <Form
                      defaultState={data}
                      fields={selectedForm}
                      onFormChange={formData => this.onFormChange(formData)}
                      showValidation={showValidation}
                    />
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => this.modalTermsConditions(t)}
                    >
                      {" "}
                      {t(`config.terms.titlePANEL`)}{" "}
                    </a>
                  </Segment>
                </Grid.Column>
              </Grid>
              <Segment basic>
                <ButtonGroup
                  disabled={
                    data.terms_and_conditions
                      ? !data.terms_and_conditions
                      : true
                  }
                  onSave={() => this.save()}
                />
              </Segment>
            </Segment>
          </Segment>
        )}

        <Dimmer active={loading}>
          <Loader indeterminate>{t("register.loading")}</Loader>
        </Dimmer>
      </Container>
    );
  }
}
