import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Container, Icon } from "semantic-ui-react";
import moment from "moment";
import { Link } from "react-router-dom";

import FilterableTable from "../../components/FilterableTable";
import FluidResponsiveButton from "../../components/FluidResponsiveButton";

import companies from "../../apis/companies";
import { filterFields, tableHeaders } from "./util";

@withTranslation()
export default class Companies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      totalData: 0,
      loading: false
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getData();
    this.setState({ loading: false });
  }

  async getData(params) {
    if (params) {
      params['channel_id'] = 1; 
    } else {
      params = {channel_id : 1};
    }
    const data = await companies.get(params);
    this.setState({ data: data.rows, totalData: data.count });
  }

  render() {
    const { t } = this.props;
    const { data, totalData, loading } = this.state;

    return (
      <div>
        <Container>
          <FilterableTable
            id={"CompaniesTable"}
            filterFields={filterFields(t)}
            tableHeaders={tableHeaders(t)}
            contentData={data}
            parseData={row => ({
              createdAt: moment(row.createdAt).format("L")
            })}
            onRowClick={data =>
              this.props.history.push(`/company/${data.id}`, { data })
            }
            totalData={totalData}
            onChange={(table, filter) => this.getData({ ...table, ...filter })}
            loading={loading}
            rowProps={data => data.blocked ? {negative:true} : {}}
          />

          <Link to="/company">
            <FluidResponsiveButton
              icon
              labelPosition="left"
              size="small"
              primary
            >
              <Icon name="building" />
              {this.props.t("companyScreen.createCompany")}
            </FluidResponsiveButton>
          </Link>
        </Container>
      </div>
    );
  }
}
