import React from 'react';
import { checkNIF } from '../../../util/util';
import { Input } from 'semantic-ui-react';
import SearchInput from '../../../components/SearchInput';

const fullName = t => ({
  fields: [
    {
      name: 'name',
      title: t('userScreen.fields.name'),
      placeHolder: t('userScreen.fields.name'),
      type: 'Input',
      colSize: 8
    },
    {
      name: 'surnames',
      title: t('userScreen.fields.surnames'),
      placeHolder: t('userScreen.fields.surnames'),
      type: 'Input',
      colSize: 8
    }
  ]
});

const emailAndStatus = t => ({
  fields: [
    {
      name: 'email',
      title: t('userScreen.fields.email'),
      placeHolder: t('userScreen.fields.email'),
      type: 'Input',
      colSize: 8,
      props: {
        readOnly: true,
        tabIndex: -1
      }
      // validation: {
      //   require: true,
      //   type: "email"
      // }
    }
    // {
    //   name: "status",
    //   title: t("userScreen.fields.status"),
    //   placeHolder: t("userScreen.fields.status"),
    //   type: "Select",
    //   data: [],
    //   colSize: 4
    // }
  ]
});

const addressAndPhone = t => ({
  fields: [
    {
      name: 'address',
      title: t('userScreen.fields.address'),
      placeHolder: t('userScreen.fields.address'),
      type: 'Input',
      colSize: 6
    },
    {
      name: 'phone',
      title: t('userScreen.fields.phone'),
      placeHolder: t('userScreen.fields.phone'),
      type: 'Input',
      colSize: 5,
      validation: {
        required: false,
        regexType: 'phone',
        errorMessage: t('validation.phone')
      }
    }
  ]
});

const ubication = (t, countryCodes, provinces) => ({
  fields: state => [
    {
      name: 'country',
      type: 'Select',
      data: countryCodes.map(countryCode => ({
        text: t(`countries.${countryCode}`),
        value: countryCode
      })),
      props: {
        clearable: true
      },
      title: t('userScreen.fields.country'),
      placeHolder: t('userScreen.fields.country'),
      validation: {
        required: false
      },
      colSize: 5
    },
    {
      name: 'region',
      title: t('userScreen.fields.region'),
      type: state.country == 'ES' ? "Select" : "Input",
      data: state.country == 'ES' ? provinces.map(province => ({
        text: province.title,
        value: province.title
      })) : null,
      props: {
        clearable: true
      },
      placeHolder: t('companyDetail.province'),
      colSize: 6,
      validation: {
        required: true,
        errorMessage: t("commons.fieldRequired")
      },
    },
    {
      name: 'city',
      title: t('userScreen.fields.city'),
      placeHolder: t('userScreen.fields.city'),
      type: 'Input',
      colSize: 5
    }
  ]
});

const status = t => ({
  fields: [
    {
      name: 'status',
      title: t('userScreen.fields.status'),
      placeHolder: t('userScreen.fields.status'),
      type: 'Select',
      data: [
        { text: t('status.ACTIVE'), value: 'ACTIVE' },
        { text: t('status.BLOCKED'), value: 'BLOCKED' }
      ],
      colSize: 6,
      props: {
        clearable: false
      },
      validation: {
        required: true
      }
    }
  ]
});

export default {
  admin: t => [fullName(t), emailAndStatus(t), status(t)],
  company: (t, countryCodes, provinces) => [
    fullName(t),
    {
      fields: [
        {
          name: 'dni',
          title: t('userScreen.fields.dni'),
          placeHolder: t('userScreen.fields.dni'),
          type: 'Input',
          colSize: 8,
          validation: {
            custom: values => {
              return values.dni ? checkNIF(values.dni, ['cif', 'nif']) : false;
            },
            errorMessage: t('commons.invalidCiforNif')
          }
        },
        ...emailAndStatus(t).fields
      ]
    },
    {
      fields: [
        {
          name: 'contacto',
          title: t('companyDetail.contact'),
          type: 'Label'
          // colSize: 'ten'
        }
      ]
    },
    addressAndPhone(t),
    ubication(t, countryCodes, provinces),
    status(t)
  ],
  planner: (t, countryCodes, provinces) => [
    fullName(t),
    ,
    {
      fields: [
        ...emailAndStatus(t).fields,
        {
          name: 'cif',
          title: t('companyDetail.cifnif'),
          placeHolder: t('companyDetail.cifnif'),
          type: 'Input',
          colSize: 8,
          validation: {
            custom: values => {
              return values.cif ? checkNIF(values.cif, ['cif', 'nif']) : true;
            },
            errorMessage: t('commons.invalidCiforNif')
          }
        }
      ]
    },
    {
      fields: [
        ...addressAndPhone(t).fields,
        {
          name: 'zip_code',
          title: t('userScreen.fields.zip_code'),
          placeHolder: t('userScreen.fields.zip_code'),
          type: 'Input',
          colSize: 5
        }
      ]
    },
    ubication(t, countryCodes, provinces),
    status(t)
  ],
  pointOfSale: t => [
    fullName(t),
    {
      fields: [
        ...emailAndStatus(t).fields,
        {
          name: 'phone',
          title: t('userScreen.fields.phone'),
          placeHolder: t('userScreen.fields.phone'),
          type: 'Input',
          colSize: 8,
          validation: {
            regexType: 'phone',
            errorMessage: t('validation.phone')
          }
        }
      ]
    },
    status(t)
  ],
  client: (t, countryCodes, provinces) => [
    fullName(t),
    {
      fields: [
        ...emailAndStatus(t).fields,
        {
          name: 'dni',
          title: t('userScreen.fields.dni'),
          placeHolder: t('userScreen.fields.dni'),
          type: 'Input',
          colSize: 4,
          validation: {
            custom: values => {
              return values.dni ? checkNIF(values.dni, ['cif', 'nif']) : false;
            },
            errorMessage: t('commons.invalidCiforNif')
          }
        },
        {
          name: 'birthday',
          title: t('userScreen.fields.birthday'),
          placeHolder: t('userScreen.fields.birthday'),
          colSize: 4,
          type: 'DatePicker',
          props: {
            id: '0'
          },
          validation: {
            custom: vals => {
              if (vals.birthday === null || vals.birthday === '') {
                return {
                  valid: false,
                  errorMessage: t('modalities.wrongDateFormat')
                };
              }
              return true;
            }
          }
        }
      ]
    },
    {
      fields: [
        {
          name: 'contacto',
          title: t('companyDetail.contact'),
          type: 'Label'
          // colSize: 'ten'
        }
      ]
    },
    {
      fields: [
        ...addressAndPhone(t).fields,
        {
          name: 'zip_code',
          title: t('userScreen.fields.zip_code'),
          placeHolder: t('userScreen.fields.zip_code'),
          type: 'Input',
          colSize: 5
        }
      ]
    },
    ubication(t, countryCodes, provinces),
    status(t)
  ],
  qr_reader: (t, countryCodes, provinces) => [
    fullName(t),
    {
      fields: [
        ...emailAndStatus(t).fields,
      ]
    },
    status(t)
  ]
};
