export const tableHeaders = (t, params) => {
  return [
    {
      content: t("inscriptionsScreen.table.date"),
      accessor: "inscriptionDate",
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("inscriptionsScreen.table.athleteFullName"),
      accessor: "athleteFullName",
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("inscriptionsScreen.table.athleteEmail"),
      accessor: "athleteEmail",
      headerProps: {
        width: 3,
      },
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("inscriptionsScreen.table.raceName"),
      accessor: "raceName",
      headerProps: {
        width: 2,
      },
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("inscriptionsScreen.table.modalityName"),
      accessor: "modalityName",
      props: { style: { wordBreak: "break-word" } },
    },
    // {
    //   content: t("inscriptionsScreen.table.inscriptionStatus"),
    //   accessor: "inscriptionStatus"
    // },
    {
      content: t("inscriptionsScreen.table.inscriptionStatus"),
      accessor: "combinedStatus",
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("inscriptionsScreen.table.locator"),
      accessor: "athleteLocator",
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("inscriptionsScreen.table.operationCode"),
      accessor: "paymentOperation_code",
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("inscriptionsScreen.table.from"),
      accessor: "inscriptionFrom",
      props: { style: { wordBreak: "break-word" } },
    },
    ...(params && params.showNotFinished
      ? [
          {
            content: "",
            accessor: "inscriptionType",
            headerProps: {
              width: 1,
            },
          },
        ]
      : []),
  ];
};

export const filterFields = (t, activityTypes) => {
  return [
    {
      div: "row",
      fields: [
        {
          name: "dni",
          placeHolder: t("inscriptionsScreen.filter.dni"),
          type: "Input",
          colSize: 6
        },
        {
          name: "name",
          placeHolder: t("inscriptionsScreen.filter.name"),
          type: "Input",
          colSize: 6
        },
        {
          name: "email",
          placeHolder: t("inscriptionsScreen.filter.email"),
          type: "Input",
          colSize: 6
        },
        {
          name: "origin",
          placeHolder: t("inscriptionsScreen.filter.origin"),
          data: [
            { value: "WEBAPP", text: t('commons.origin.WEBAPP') },
            { value: "BACKOFFICE", text: t('commons.origin.BACKOFFICE') },
            { value: "MOBILE", text: t('commons.origin.MOBILE') },
          ],
          type: "Select",
          colSize: 6
        }
      ]
    },
    {
      div: "row",
      fields: [
        {
          name: "activityType",
          placeHolder: t("inscriptionsScreen.filter.activityType"),
          type: "Select",
          data: activityTypes,
          colSize: 6,
        },
        {
          name: "race",
          placeHolder: t("inscriptionsScreen.filter.race"),
          type: "Input",
          colSize: 6
        },
        {
          name: "payDate",
          placeHolder: t("inscriptionsScreen.filter.payDate"),
          type: "DatePicker",
          colSize: 5,
          props:{
            id :'0'
          }
        },
        // {
        //   name: "payForm",
        //   placeHolder: t("inscriptionsScreen.filter.payForm"),
        //   type: "Input",
        //   colSize: 8
        // },

        {
          name: "status",
          placeHolder: t("inscriptionsScreen.filter.status"),
          type: "Select",
          data: [
            { value: "PAID", text: t('paymentStatus.PAID') },
            { value: "PENDING", text: t('paymentStatus.PENDING') },
            { value: "REJECTED", text: t('paymentStatus.REJECTED') },
            { value: "DENIED", text: t('paymentStatus.DENIED') },
            { value: "CANCELLED", text: t('paymentStatus.CANCELLED') },
          ],
          colSize: 5
        },
        {
          name: "showNotFinished",
          placeHolder: t("inscriptionsScreen.filter.showNotFinished"),
          type: "Checkbox",
          colSize: 3,
          props: {
            toggle: true,
            label: t("inscriptionsScreen.filter.showNotFinished"),
          }
        }
      ]
    }
  ];
};

export const formFields = t => {
  return [
    {
      fields: [
        {
          name: "inscriptionDate",
          title: t("inscriptionsScreen.detail.date"),
          placeHolder: t("inscriptionsScreen.detail.date"),
          type: "DatePicker",
          colSize: 16,
          props: {
            readOnly: true
          }
        },
        {
          name: "athleteFirstName",
          title: t("inscriptionsScreen.detail.firstName"),
          placeHolder: t("inscriptionsScreen.detail.firstName"),
          type: "Input",
          colSize: 16,
          props: {
            readOnly: true
          }
        },
        {
          name: "athleteSurname",
          title: t("inscriptionsScreen.detail.lastName"),
          placeHolder: t("inscriptionsScreen.detail.lastName"),
          type: "Input",
          colSize: 16,
          props: {
            readOnly: true
          }
        }
      ]
    }, {
      fields: [
        {
          name: "athleteDni",
          title: t("inscriptionsScreen.detail.dni"),
          placeHolder: t("inscriptionsScreen.detail.dni"),
          type: "Input",
          colSize: 16,
          props: {
            readOnly: true
          }
        },
        {
          name: "athletePhone",
          title: t("inscriptionsScreen.detail.phone"),
          placeHolder: t("inscriptionsScreen.detail.phone"),
          type: "Input",
          colSize: 16,
          props: {
            readOnly: true
          }
        },
        {
          name: "athleteEmail",
          title: t("inscriptionsScreen.detail.email"),
          placeHolder: t("inscriptionsScreen.detail.email"),
          type: "Input",
          colSize: 16,
          props: {
            readOnly: true
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "raceName",
          title: t("inscriptionsScreen.detail.raceName"),
          placeHolder: t("inscriptionsScreen.detail.raceName"),
          type: "Input",
          colSize: 16,
          props: {
            readOnly: true
          }
        },
        {
          name: "modalityName",
          title: t("inscriptionsScreen.detail.modalityName"),
          placeHolder: t("inscriptionsScreen.detail.modalityName"),
          type: "Input",
          colSize: 16,
          props: {
            readOnly: true
          }
        }
      ]
    },
  ];
};