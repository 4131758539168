import { createAction, handleActions } from "redux-actions";
import initialState from "./initialState";

export const changeTab = createAction("CHANGE_TAB");

export default handleActions(
  {
    [changeTab]: (state, action) => {
      return {
        ...state.notifications,
        activeTab: action.payload,
      };
    },
  },
  { ...initialState }
);
