import { ageTypes, genders } from "../../../util/usersUtil";

export const tableHeaders = t => {
  return [{
    content: t("categoriesScreen.table.name"),
    accessor: "name"
  },
  {
    content: t("categoriesScreen.table.gender"),
    accessor: "gender"
  },
  {
    content: t("categoriesScreen.table.interval"),
    accessor: "interval",
    props: {
      // textAlign: "center"
    }
  },
  // {
  //   content: t("categoriesScreen.table.maxAthletes"),
  //   accessor: "maxAthletes",
  //   props: {
  //     textAlign: "right"
  //   }
  // },
  {
    content: t("categoriesScreen.table.inDate"),
    accessor: "ageCheckDate",
    props: {
      textAlign: "right"
    }
  }
  ];
};

export const formFields = (t, agesValidation = {}, disabled) => {
  return [{
    fields: [{
      name: "name",
      title: t("categoriesScreen.detail.name"),
      placeHolder: t("categoriesScreen.detail.name"),
      type: "Input",
      colSize: 13,
      validation: {
        required: true,
        errorMessage: t("commons.fieldRequired")
      },
      props:{
        disabled
      }
    },
    // {
    //   name: "maxAthletes",
    //   title: t("categoriesScreen.detail.maxAthletes"),
    //   placeHolder: t("categoriesScreen.detail.maxAthletes"),
    //   type: "Input",
    //   colSize: 3
    // },
    {
      name: "gender",
      title: t("categoriesScreen.detail.gender"),
      placeHolder: t("categoriesScreen.detail.gender"),
      type: "Select",
      colSize: 3,
      data: genders(t),
      validation: {
        required: true,
        errorMessage: t("commons.fieldRequired")
      },
      props:{
        disabled
      }
    }
    ]
  },
  {
    fields: [{
      name: "description",
      title: t("categoriesScreen.detail.description"),
      placeHolder: t("categoriesScreen.detail.description"),
      type: "TextArea",
      colSize: 16,
      props:{
        disabled
      }
    }]
  },
  {
    fields: [{
      name: "ageType",
      title: t("categoriesScreen.detail.ageType"),
      placeHolder: t("categoriesScreen.detail.ageType"),
      type: "Select",
      colSize: 5,
      data: ageTypes(t),
      validation: {
        required: true,
        errorMessage: t("commons.fieldRequired")
      },
      props:{
        disabled
      }
    },
    {
      name: "minimumAge",
      title: t("categoriesScreen.detail.minimumAge"),
      placeHolder: t("categoriesScreen.detail.minimumAge"),
      type: "Input",
      colSize: 5,
      validation: {
        required: true,
        custom: () => agesValidation.validation,
        errorMessage: agesValidation.agesAreRequired ?
          t("commons.fieldRequired") :
          agesValidation.minAgeLower ?
            t("categoriesScreen.detail.mustBeLower") :
            t("categoriesScreen.detail.mustBeGreater")
      },
      props:{
        disabled
      }
    },

    {
      name: "maximumAge",
      title: t("categoriesScreen.detail.maximumAge"),
      placeHolder: t("categoriesScreen.detail.maximumAge"),
      type: "Input",
      colSize: 5,
      validation: {
        required: true,
        custom: () => agesValidation.validation,
        errorMessage: agesValidation.agesAreRequired ?
          t("commons.fieldRequired") :
          agesValidation.minAgeLower ?
            t("categoriesScreen.detail.mustBeGreater") :
            t("categoriesScreen.detail.mustBeLower")
      },
      props:{
        disabled
      }
    },
    {
      name: "ageCheckDate",
      title: t("categoriesScreen.detail.ageCheckDate"),
      placeHolder: t("categoriesScreen.detail.ageCheckDate"),
      type: "DatePicker",
      props: {
        id: '0',
        disabled
      },
      colSize: 5,
      validation: {
        // required: true,
        custom: vals => {

          console.log(vals.ageType);

          if (vals.ageType == 'AGE' && (vals.ageCheckDate === null || vals.ageCheckDate === "")) {
            return {
              valid: false,
              errorMessage: t("commons.fieldRequired")
            }
          }

          /*if (vals.ageCheckDate === null || vals.ageCheckDate === "") {

            return {
              valid: false,
              errorMessage: t("modalities.wrongDateFormat")
            }
          }*/
          return true
        }
      }
    }
    ]
  }
  ];
};

export const validateAges = (values, ageValidation) => {
  let minAgeLower = false;
  let validation = true;

  const { maximumAge, minimumAge, ageType } = values;
  const required = ageValidation && !!!maximumAge && !!!minimumAge;

  const areValues = !!ageType && !!maximumAge && !!minimumAge;
  const isAgeType = ageType === "AGE";

  if (required) {
    validation = false;
  } else {
    minAgeLower = isAgeType;

    if (
      (!!maximumAge && isNaN(parseInt(maximumAge))) ||
      (!!minimumAge && isNaN(parseInt(minimumAge)))
    ) {
      validation = false;
    }

    if (areValues) {
      validation = isAgeType ?
        +maximumAge > +minimumAge :
        +maximumAge < +minimumAge;
    }
  }

  /**
   * Validation is a boolean that if it is true it wont show the validation
   * agesAreRequired is a boolean that says if the age fields are required
   * minAgeLower is a boolean to show the correct comparative betwwen ages
   */
  return { validation, agesAreRequired: required, minAgeLower };
};