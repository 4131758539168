import { clientApi } from ".";

export default {
  fetchRoles: async () => {
    try {
      const response = await clientApi.getClient().get("/roles", {params: {channel_id: 1}});
      return response.data;
    } catch (err) {}
  },
  fetchRoleByToken: async params => {
    try {
      const response = await clientApi
        .getClient()
        .get("/role/token", { params });
      return response.data;
    } catch (err) {}
  }
};
