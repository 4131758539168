import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Search, Label } from "semantic-ui-react";
import { debounce, escapeRegExp, filter } from "lodash";
import { useTranslation } from "react-i18next";

const SearchInput = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [results, setResults] = useState([]);
  const [t] = useTranslation();
  const minLength = 3;

  useEffect(() => {
    props.onChange(value);
  }, [value]);

  const handleResultSelect = (_, { result }) => setValue(result.title);

  const handleSearchChange = (_, { value }) => {
    setValue(value);
    if (value.length < minLength) {
      return;
    }

    setIsLoading(true);

    setTimeout(() => {
      if (value.length < 1) {
        setIsLoading(false);
        setValue("");
        setResults([]);
        return;
      }

      const re = new RegExp(escapeRegExp(value), "i");
      const isMatch = (result) => re.test(result.title);

      setIsLoading(false);
      setResults(filter(props.data, isMatch));
    }, 30);
  };

  const { showValidation, ...rest } = props; //extract showValidation from props to avoid Unknown Prop warning

  return (
    <>
      <Search
        loading={isLoading}
        fluid
        className={`searchInputComponent ${
          showValidation ? "searchInputComponentValidation" : ""
        }`}
        minCharacters={minLength}
        style={{ width: "100%" }}
        noResultsMessage={t("commons.noResultsFound")}
        onResultSelect={handleResultSelect}
        onSearchChange={debounce(handleSearchChange, 50, {
          leading: true,
        })}
        results={results}
        onFocus={(e) => e.target.setAttribute("autocomplete", "nope")}
        {...rest}
        value={props.value ? props.value : ""}
      />
      {showValidation && (
        <Label prompt pointing>
          {t("commons.fieldRequired")}
        </Label>
      )}
    </>
  );
};

SearchInput.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
};

SearchInput.defaultProps = {
  data: [],
  onChange: () => {},
};

export default SearchInput;
