import moment from 'moment';
/**
 * Return the string with the first letter
 * in uppercase and the reset in lowercase
 * @param {string} string
 */
export const capitalize = string => {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : null;
};

export const changeCase = (string, sCase="UPPER") => {
  if( !string ) return string;
  return sCase==='UPPER'
    ? string.toUpperCase()
    : string.toLowerCase()
};

/**
 * Converts a file to it base64 form.
 * @param {*} file
 */
export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

/**
 * Check if the nif is valid
 * @param {string} nif
 * @param {string|array} type (DNI, CIF, NIE,...)
 */
export const checkNIF = (nif, type, force = true) => {
  if(!force && !nif){ // under14; dont check if there's not dni
    return true;
  } else {
    if (!nif || !type) {
      return false;
    }

    if (typeof type === "string") {
      type = [type.toUpperCase()];
    } else if (typeof type === "object") {
      type = type.map(x => x.toUpperCase());
    }

    nif = nif.toUpperCase().replace(/[\s\-]+/g, "");
    if (/^(\d|[XYZ])\d{7}[A-Z]$/.test(nif)) {
      var num = nif.match(/\d+/);
      num = (nif[0] != "Z" ? (nif[0] != "Y" ? 0 : 1) : 2) + num;
      if (nif[8] == "TRWAGMYFPDXBNJZSQVHLCKE"[num % 23]) {
        const checkDni = /^\d/.test(nif);
        if ((checkDni && type.includes("NIF")) || type.includes("DNI")) {
          return true;
        } else if (!checkDni && type.includes("NIE")) {
          return true;
        }
      }
    } else if (/^[ABCDEFGHJKLMNPQRSUVW]\d{7}[\dA-J]$/.test(nif)) {
      for (var sum = 0, i = 1; i < 8; ++i) {
        var num = nif[i] << i % 2;
        var uni = num % 10;
        sum += (num - uni) / 10 + uni;
      }
      var c = (10 - (sum % 10)) % 10;
      if (nif[8] == c || nif[8] == "JABCDEFGHI"[c]) {
        var out = /^[KLM]/.test(nif);
        if (out && type.includes("ESP")) {
          return true;
        } else if (!out && type.includes("CIF")) {
          return true;
        }
      }
    }
    return false;
  }
};
export const getDatesErrorMessage = (t, startDate, finishDate, dateFormat = "L") => {

  if (startDate === finishDate) {
    //rturn same message
    return t("racesScreen.limitDateShouldBe", {
      date: moment(startDate).format(dateFormat)
    })
  } else {
    return t("racesScreen.limitDateBetween", {
      startDate: moment(startDate).format(dateFormat),
      finishDate: moment(finishDate).format(dateFormat)
    })

  }

}

/**
 *
 * @param {string} lowerDateLimit Lower limit of the range
 * @param {string} upperDateLimit Upper limit of the range
 * @param {string} date Date to test if is between the range
 */
export const dateIsBetween = (lowerDateLimit, upperDateLimit, date) => {
  if (!lowerDateLimit && !upperDateLimit) {
    return true;
  }

  const lowerDate = lowerDateLimit ? new Date(lowerDateLimit) : null;
  const upperDate = upperDateLimit ? new Date(upperDateLimit) : null;
  const dateToTest = new Date(date);

  if (!lowerDateLimit) {
    return dateToTest <= upperDate;
  }

  if (!upperDateLimit) {
    return dateToTest >= lowerDate;
  }

  if (upperDate <= lowerDate) {
    return false;
  }

  return dateToTest >= lowerDate && dateToTest <= upperDate;
};

export const copyTextToClipboard = text => {
  const input = document.createElement("input");

  document.body.appendChild(input);
  input.value = text;

  /* Select the text field */
  input.select();
  input.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");

  document.body.removeChild(input);
};

/**
 * 
 * @param {string} name To search attribute starting by ...
 * @param {object} object The object to search from
 * @example
 *  const a = {attribute.a: 10, attribute.b: 20}
 *  const newA = getObjectByStart("attribute", a);
 *  // newA = { a: 10, b: 20 };
 */
export const getObjectByStart = (name, object) =>
  Object.keys(object)
    .filter(key => key.startsWith(`${name}.`))
    .reduce(
      (acc, key) => ({
        ...acc,
        [key.split(/\.(.+)/)[1]]: object[key]
      }),
      {}
    );

/**
 * Create a moment date from separate date & time
 */
export const getDateAndTime = (date, time) => {
  try {
    date = moment(date).format('YYYY-MM-DD');
  } catch (error) {}
  return date
}