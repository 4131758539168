import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";

import "semantic-ui-css/semantic.min.css";
import App from "./App";

import "./locales/index";
import store from "./store";

import "./styles";
import ErrorBoundary from "./components/ErrorBoundary";

render(
  <ErrorBoundary>
    <Provider store={store()}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);
