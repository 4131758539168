import React, { useState, useEffect } from "react";
import { Image, Form, Input } from "semantic-ui-react";

import { checkNIF, dateIsBetween } from "../../../../util/util";

import SearchInput from "../../../../components/SearchInput";

import ImagePicker from "../../../../components/ImagePicker";
import { getBirthdayErrorMessage } from "../createInscriptionForm";

const today = new Date();
const fourteeenYearsAgo = new Date (new Date(
  new Date(today).setFullYear(today.getFullYear() - 14)
).setHours(0,0,0))
const nullObject = { type: "Null", colSize: 0 };

export const inscriptionFields = (t, clubs, copernico_client_id, under14_dni, activeInscription, selectClub) => [
  {
    div: "row",
    fields: [
      {
        name: "dni",
        title: t("inscriptionsScreen.detail.dni"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 3,
        validation: {
          custom: (values) => {
            let birthday;
            if(!values.birthday){
              birthday = new Date(activeInscription.birthday)
            } else {
              birthday = values.birthday
            }
            const minor = new Date(birthday) > fourteeenYearsAgo;
            return values.dni
              ? checkNIF(values.dni, ["DNI", "cif", "nif"], minor && under14_dni)
              : (minor && !under14_dni || false);
          },
          errorMessage: t("companyDetail.messages.invalidCiforNif"),
        },
      },
      {
        name: "locator",
        title: t("inscriptionsScreen.detail.locator"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 3,
        props: {
          type: "text",
          readOnly: true,
        },
      },
      {
        name: "name",
        title: t("inscriptionsScreen.detail.firstName"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 4,
      },
      {
        name: "surnames",
        title: t("inscriptionsScreen.detail.lastName"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 6,
      },
      {
        name: "birthday",
        title: t("clientScreen.detail.birthday"),
        placeHolder: t("clientScreen.detail.birthday"),
        type: "DatePicker",
        props: {
          id: "0",
        },
        validation: {
          custom: (vals) => {
            if (vals.birthday === null || vals.birthday === "") {
              return {
                valid: false,
                errorMessage: t("modalities.wrongDateFormat"),
              };
            }
            return true;
          },
        },
        colSize: 6,
      },
    ],
  },
  {
    fields: [
      {
        name: "email",
        title: t("inscriptionsScreen.detail.email"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 8,
      },
      {
        name: "gender",
        type: "RadioButtons",
        title: t("inscriptionsScreen.detail.gender"),
        radios: [
          {
            value: "MALE",
            label: t("inscriptionsScreen.detail.male"),
          },
          {
            value: "FEMALE",
            label: t("inscriptionsScreen.detail.female"),
          },
        ],
        colSize: 4,
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired"),
        },
      },
      {
        name: "dorsal_assigned",
        title: t("inscriptionsScreen.detail.dorsalAssigned"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 4,
        props: {
          // type: "number",
          readOnly: true,
        },
      },...(!!selectClub
      ? [{
        name: "club",
        component: (value, defauld, onChange) => (
          <div className={"sixteen wide field"}>
            <label> {t("inscriptionsScreen.detail.club")} </label>{" "}
            <SearchInput
              onChange={(data) => {
                if (data) {
                  onChange(data);
                } else {
                  if (value.club) {
                    onChange("");
                  }
                }
              }}
              data={clubs}
              placeholder={t("clientScreen.detail.club")}
              value={value.club ? value.club : ""}
              icon="shield"
              noResultsMessage={t("inscriptionsScreen.detail.clubNotFound")}
            />{" "}
          </div>
        ),
        colSize: 16,
      }]
      : [nullObject]),
]
  },
  copernico_client_id
    ? {
        div: "row",
        fields: [
          {
            name: "copernico_client_id",
            title: t("inscriptionsScreen.detail.copernicoId"),
            placeHolder: t("commons.none"),
            type: "Input",
            colSize: 16,
            props: {
              // type: "number",
              readOnly: true,
            },
          },
        ],
      }
    : { fields: [] },
];

export const paymentFields = (t) => [
  {
    div: "row",
    fields: [
      {
        name: "createdAt",
        title: t("inscriptionsScreen.detail.paymentDate"),
        placeHolder: t("commons.none"),
        type: "DatePicker",
        colSize: 4,
        props: {
          readOnly: true,
          clearable: false,
        },
      },
      {
        name: "locator",
        title: t("inscriptionsScreen.detail.paymentLocator"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 4,
        props: {
          readOnly: true,
        },
      },
      {
        name: "modalityName",
        title: t("inscriptionsScreen.detail.modality"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 4,
        props: {
          readOnly: true,
        },
      },
      {
        name: "raceName",
        title: t("inscriptionsScreen.detail.raceName"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 4,
        props: {
          readOnly: true,
        },
      },
    ],
  },
  {
    div: "row",
    fields: [
      {
        name: "price",
        title: t("inscriptionsScreen.detail.price"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 4,
        props: {
          readOnly: true,
        },
      },
      {
        name: "tpv",
        title: t("inscriptionsScreen.detail.tpv"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 4,
        props: {
          readOnly: true,
        },
      },
      {
        name: "operation_code",
        title: t("inscriptionsScreen.detail.operationCode"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 4,
        props: {
          readOnly: true,
        },
      },
      {
        name: "auth_code",
        title: t("inscriptionsScreen.detail.authCode"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 4,
        props: {
          readOnly: true,
        },
      },
    ],
  },
  {
    div: "row",
    fields: [
      {
        name: "totalRefund",
        title: t("inscriptionsScreen.detail.partialReturn"),
        placeHolder: t("commons.none"),
        type: "Input",
        colSize: 4,
        props: {
          readOnly: true,
        },
      },
      {
        name: "inscriptionStatus",
        title: t("inscriptionsScreen.detail.paymentStatus"),
        type: "Select",
        data: [
          {
            text: t("paymentStatus.PAID"),
            value: "PAID",
          },
          {
            text: t("paymentStatus.PENDING"),
            value: "PENDING",
          },
          {
            text: t("paymentStatus.CANCELLED"),
            value: "CANCELLED",
          },
          {
            text: t("paymentStatus.DENIED"),
            value: "REJECTED",
          },
        ],
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired"),
        },
        colSize: 4,
      },
    ],
  },
];

export const additionalFields = (
  t,
  {
    createdAt,
    address,
    location1,
    location2,
    location3,
    zipCode,
    originCountry,
    phonePrefix,
    mobile,
    emergencyMobile,
    federatedParticipant,
    localParticipant,
    parentalLeave,
    photo,
  },
  countries,
  provinces,
  inscriptionChanged
) => {
  const nullObject = { type: "Null", colSize: 0 };
  const birthdayDate = new Date(inscriptionChanged.birthday);
  const inscriptionDate = new Date(createdAt);
  const eighteenYearsAgo = new Date(
    new Date(inscriptionDate).setFullYear(inscriptionDate.getFullYear() - 18)
  );
  let areAdditionalFields = false;

  if (
    address ||
    location1 ||
    location2 ||
    location3 ||
    zipCode ||
    originCountry ||
    phonePrefix ||
    mobile ||
    emergencyMobile ||
    federatedParticipant ||
    localParticipant ||
    parentalLeave ||
    photo
  ) {
    areAdditionalFields = true;
  }

  return areAdditionalFields
    ? [
        {
          fields: [
            {
              type: "Label",
              title: t("inscriptionsScreen.detail.inscriptionOther"),
              color: "#2185d0",
            },
          ],
        },
        {
          fields: state => [
            address
              ? {
                  name: "address",
                  title: t("inscriptionsScreen.detail.address"),
                  placeHolder: t("commons.none"),
                  colSize: 16,
                  type: "Input",
                }
              : nullObject,
            location1 && !location2 && !location3
              ? {
                  name: "country",
                  title: t("clientScreen.detail.country"),
                  placeHolder: t("clientScreen.detail.country"),
                  type: "Select",
                  colSize: 10,
                  validation: {
                    required: true,
                    errorMessage: t("commons.fieldRequired"),
                  },
                  data: countries.map((countryCode) => ({
                    text: t(`countries.${countryCode}`),
                    value: countryCode,
                  })),
                }
              : nullObject,
            ...(location2 && !location3
              ? [
                  {
                    name: "country",
                    title: t("clientScreen.detail.country"),
                    placeHolder: t("clientScreen.detail.country"),
                    type: "Select",
                    colSize: 10,
                    validation: {
                      required: true,
                      errorMessage: t("commons.fieldRequired"),
                    },
                    data: countries.map((countryCode) => ({
                      text: t(`countries.${countryCode}`),
                      value: countryCode,
                    })),
                  },
                  {
                    name: "region",
                    type: state.country == 'ES' ? "Select" : "Input",
                    data: state.country == 'ES' ? provinces.map(province => ({
                      text: province.title,
                      value: province.title
                    })) : null,
                    props: {
                      clearable: true
                    },
                    title: t("companyDetail.province"),
                    placeHolder: t("companyDetail.province"),
                    colSize: 16,
                    validation: {
                      required: true,
                      regexType: "text",
                    },
                  },
                ]
              : [nullObject]),
            ...(location3
              ? [
                  {
                    name: "country",
                    title: t("clientScreen.detail.country"),
                    placeHolder: t("clientScreen.detail.country"),
                    type: "Select",
                    colSize: 10,
                    validation: {
                      required: true,
                      errorMessage: t("commons.fieldRequired"),
                    },
                    data: countries.map((countryCode) => ({
                      text: t(`countries.${countryCode}`),
                      value: countryCode,
                    })),
                  },
                  {
                    name: "region",
                    title: t("userScreen.fields.region"),
                    type: state.country == 'ES' ? "Select" : "Input",
                    data: state.country == 'ES' ? provinces.map(province => ({
                      text: province.title,
                      value: province.title
                    })) : null,
                    props: {
                      clearable: true
                    },
                    placeHolder: t("userScreen.fields.region"),
                    validation: {
                      required: false,
                      regexType: "text",
                    },
                    colSize: 16,
                  },
                  {
                    name: "city",
                    title: t("inscriptionsScreen.detail.city"),
                    placeHolder: t("commons.none"),
                    colSize: 16,
                    type: "Input",
                  },
                ]
              : [nullObject]),
            zipCode
              ? {
                  name: "zip_code",
                  title: t("inscriptionsScreen.detail.zipCode"),
                  placeHolder: t("commons.none"),
                  colSize: 16,
                  type: "Input",
                }
              : nullObject,
            originCountry
              ? {
                  name: "origin_country",
                  title: t("inscriptionsScreen.detail.originCountry"),
                  placeHolder: t("commons.none"),
                  colSize: 16,
                  type: "Select",
                  validation: {
                    required: true,
                    errorMessage: t("commons.fieldRequired"),
                  },
                  data: countries.map((countryCode) => ({
                    text: t(`countries.${countryCode}`),
                    value: countryCode,
                  })),
                }
              : nullObject,
          ],
        },
        {
          fields: [
            phonePrefix
              ? {
                  name: "phone_prefix",
                  title: t("inscriptionsScreen.detail.phonePrefix"),
                  placeHolder: t("commons.none"),
                  colSize: 16,
                  type: "Input",
                }
              : nullObject,
            mobile
              ? {
                  name: "phone",
                  title: t("inscriptionsScreen.detail.phone"),
                  placeHolder: t("commons.none"),
                  colSize: 16,
                  type: "Input",
                }
              : nullObject,
            emergencyMobile
              ? {
                  name: "emergency_phone",
                  title: t("inscriptionsScreen.detail.emergencyPhone"),
                  placeHolder: t("commons.none"),
                  colSize: 16,
                  type: "Input",
                }
              : nullObject,
          ],
        },
        {
          fields: [
            federatedParticipant
              ? {
                  name: "is_federated_participant",
                  title: t("inscriptionsScreen.detail.federatedParticipant"),
                  placeHolder: t("commons.none"),
                  colSize: 4,
                  type: "Checkbox",
                }
              : nullObject,
            localParticipant
              ? {
                  name: "is_local_participant",
                  title: t("inscriptionsScreen.detail.localParticipant"),
                  placeHolder: t("commons.none"),
                  colSize: 4,
                  type: "Checkbox",
                }
              : nullObject,
          ],
        },
        {
          fields:
            parentalLeave && birthdayDate > eighteenYearsAgo
              ? [
                  {
                    name: "parent_name",
                    title: t("inscriptionsScreen.detail.parentName"),
                    placeHolder: t("commons.none"),
                    colSize: 16,
                    type: "Input",
                    validation: {
                      required: true,
                      errorMessage: t("commons.fieldRequired"),
                    },
                  },
                  {
                    name: "parent_surnames",
                    title: t("inscriptionsScreen.detail.parentSurnames"),
                    placeHolder: t("commons.none"),
                    colSize: 16,
                    type: "Input",
                    validation: {
                      required: true,
                      errorMessage: t("commons.fieldRequired"),
                    },
                  },
                  {
                    name: "parent_dni",
                    title: t("inscriptionsScreen.detail.parentDni"),
                    placeHolder: t("commons.none"),
                    colSize: 16,
                    type: "Input",
                    validation: {
                      required: true,
                      custom: (vals) => {
                        return checkNIF(vals.parent_dni, ["DNI", "NIE"]);
                      },
                      errorMessage: t("commons.invalidCiforNif"),
                    },
                  },
                ]
              : [],
        },
        {
          fields: (state) => [
            photo
              ? {
                  name: "card_photo_url",
                  component: (_, defaultState, onChange) => (
                    <ImagePicker
                      content={t("inscriptionsScreen.detail.cardPhoto")}
                      showLabelToOpen={false}
                      onSelect={(data) => onChange(data)}
                      imageSrc={state["card_photo_url"]}
                      // markAsRequired={showValidation && !state["athlete.photo"]}
                    />
                  ),
                  validation: {
                    required: true,
                    errorMessage: t("commons.fieldRequired"),
                  },
                }
              : nullObject,
          ],
        },
      ]
    : [];
};

export const extraFields = (t, userAttributes, inscriptionAttributes) =>
  inscriptionAttributes && inscriptionAttributes.length > 0
    ? [
        {
          fields: [
            {
              type: "Label",
              title: t("inscriptionsScreen.detail.inscriptionExtra"),
              color: "#2185d0",
            },
          ],
        },
        ...userAttributes
          .reduce((result, _, index, array) => {
            // if (index % 2 === 0) result.push(array.slice(index, index + 2));
            result.push(array.slice(index, index + 1));
            return result;
          }, [])
          .map((arrays) => {
            return {
              fields: arrays.map(({ value, modalityAttributeId }) => {
                const {
                  name,
                  type,
                  ModalityAttributesOptions: attributesOptions,
                } = inscriptionAttributes.find(
                  (attribute) => attribute.id === modalityAttributeId
                );

                let valueToShow = null;
                if (type === "NUMBER" || type === "STRING") {
                  if (value !== "null") {
                    valueToShow = value;
                  } else {
                    valueToShow = t("commons.none");
                  }
                } else if (type === "PRODUCT" || type === "SELECT") {
                  if (value && value !== "null") {
                    const selectedValue = attributesOptions.find(
                      (option) => option.id === parseInt(value)
                    );
                    try {
                      valueToShow = parseInt(`${selectedValue.id}`);
                    } catch (e) {
                      valueToShow = t("commons.none");
                    }
                  } else {
                    valueToShow = t("commons.none");
                  }
                }

                if (type === "BOOLEAN") {
                  const selectedValue = JSON.parse(value);
                  valueToShow = selectedValue ? selectedValue : false;
                }

                switch (type) {
                  case "STRING":
                    return {
                      name: name,
                      title: name,
                      value: valueToShow || "",
                      placeHolder: t("commons.none"),
                      type: "Input",
                      colSize: 16,
                      readOnlyValue: valueToShow,
                    };
                  case "NUMBER":
                    return {
                      name: name,
                      title: name,
                      value: valueToShow || "",
                      placeHolder: t("commons.none"),
                      type: "Input",
                      colSize: 16,
                      readOnlyValue: valueToShow,
                    };
                  case "BOOLEAN":
                    return {
                      name: name,
                      title: name,
                      value: valueToShow || false,
                      placeHolder: t("commons.none"),
                      type: "Checkbox",
                      colSize: 16,
                      readOnlyValue: valueToShow,
                    };
                  case "SELECT":
                    return {
                      name: name,
                      title: name,
                      value: valueToShow || "",
                      placeHolder: t("commons.none"),
                      type: "Select",
                      data: attributesOptions.map((option) => ({
                        text: option.name,
                        value: option.id,
                      })),
                      colSize: 16,
                      readOnlyValue: valueToShow,
                    };
                  case "PRODUCT":
                    return {
                      name: name,
                      title: name,
                      value: valueToShow || "",
                      placeHolder: t("commons.none"),
                      type: "Select",
                      data: attributesOptions.map((option) => ({
                        text: option.name,
                        value: option.id,
                      })),
                      colSize: 16,
                      readOnlyValue: valueToShow,
                    };
                }
              }),
            };
          }),
      ]
    : [];

export const teamTableHeaders = (t) => [
  {
    content: t("inscriptionsScreen.detail.firstName"),
    accessor: "name",
  },
  {
    content: t("inscriptionsScreen.detail.lastName"),
    accessor: "surnames",
  },
  {
    content: t("inscriptionsScreen.detail.email"),
    accessor: "email",
  },
  {
    content: t("inscriptionsScreen.detail.dorsalAssigned"),
    accessor: "dorsal_assigned",
  },
  {
    content: t("inscriptionsScreen.detail.cancelInscription").split(" ")[0],
    accessor: "btn",
  },
];

export const tableHeaders = (t) => [
  {
    content: t("inscriptionsScreen.detail.qrReaderResetedDate"),
    accessor: "qr_reset_date",
  },
  {
    content: t("inscriptionsScreen.detail.qrReader"),
    accessor: "qr_reader",
  }
];

export const returnMoneyHeaders = (t) => [
  { content: "", accessor: "select" },
  ...teamTableHeaders(t),
  {
    content: t("inscriptionsScreen.detail.price"),
    accessor: "price",
  },
];

export const refundHeaders = (t) => [
  // { content: "", accessor: "select" },
  {
    content: t("inscriptionsScreen.detail.totalAmountToRetun"),
    accessor: "amountToRefund",
  },
  ...teamTableHeaders(t),
  {
    content: t("inscriptionsScreen.detail.price"),
    accessor: "price",
  },
];

export const refundDescriptionField = (t) => [
  {
    fields: [
      {
        name: "description",
        title: t("inscriptionsScreen.detail.refundDescription"),
        type: "TextArea",
        colSize: 16,
        validation: {
          custom: (vals) =>
            vals.description ? vals.description.length <= 255 : true,
          errorMessage: t("commons.maxLength", { maxLength: 255 }),
        },
      },
    ],
  },
];

export const partialRefundFields = (t, inscriptionPrice) => [
  {
    fields: [
      {
        name: "amountToReturn",
        title: t("inscriptionsScreen.detail.amountToReturn"),
        type: "Input",
        colSize: 4,
        validation: {
          custom: (vals) =>
            vals.amountToReturn > 0 && vals.amountToReturn <= inscriptionPrice,
          errorMessage: t(
            "inscriptionsScreen.detail.priceShouldBeMoreThanZero"
          ),
        },
        props: {
          type: "number",
          icon: "euro",
          step: ".01",
        },
      },
    ],
  },
  ...refundDescriptionField(t),
];

export const selectModality = (t, modalities) => [
  {
    fields: [
      {
        name: "selectedModality",
        type: "Select",
        title: t("inscriptionsScreen.detail.selectModality"),
        placeHolder: t("inscriptionsScreen.detail.selectModality"),
        data: modalities,
        colSize: 8,
      },
    ],
  },
];

export const inscriptionFieldsToChangeModality = (
  t,
  modality,
  provinces,
  countries,
  showValidation,
  inscription
) => {
  const birthday = new Date(inscription.birthday);
  const { additionalFields, attributes, inscriptions } = modality;
  const {
    confirmEmail,
    address,
    zipCode,
    location1,
    location2,
    location3,
    originCountry,
    phonePrefix,
    mobile,
    emergencyMobile,
    federatedParticipant,
    localParticipant,
    photo,
    parentalLeave,
  } = additionalFields;
  const areAdditionalFields = Object.values(additionalFields)
    .filter((v) => typeof v !== "string")
    .some((v) => v);
  const nullObject = { type: "Null", colSize: 0 };
  const today = new Date();
  const eighteenYearsAgo = new Date(
    new Date(today).setFullYear(today.getFullYear() - 18)
  );

  const basicFields = [
    {
      fields: [
        {
          type: "Label",
          title: t("inscriptionsScreen.detail.inscriptionInfo"),
          color: "#2185d0",
        },
      ],
    },
    {
      fields: [
        {
          name: "name",
          type: "Input",
          title: t("inscriptionsScreen.detail.firstName"),
          placeHolder: t("inscriptionsScreen.detail.firstName"),
          colSize: 16,
          props: {
            readOnly: true,
          },
        },
        {
          name: "surnames",
          type: "Input",
          title: t("inscriptionsScreen.detail.lastName"),
          placeHolder: t("inscriptionsScreen.detail.lastName"),
          colSize: 16,
          props: {
            readOnly: true,
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "email",
          type: "Input",
          title: t("inscriptionsScreen.detail.email"),
          placeHolder: t("inscriptionsScreen.detail.email"),
          colSize: 16,
          props: {
            readOnly: true,
          },
        },
        {
          name: "dni",
          type: "Input",
          title: t("inscriptionsScreen.detail.dni"),
          placeHolder: t("inscriptionsScreen.detail.dni"),
          colSize: 16,
          props: {
            readOnly: true,
          },
        },
      ],
    },
  ];

  const extraFields = areAdditionalFields
    ? [
        {
          fields: [
            {
              type: "Label",
              title: t("inscriptionsScreen.detail.inscriptionOther"),
              color: "#2185d0",
            },
          ],
        },
        {
          fields: [
            confirmEmail
              ? {
                  name: "confirmEmail",
                  title: t("inscriptionsScreen.detail.confirmEmail"),
                  placeHolder: t("inscriptionsScreen.detail.confirmEmail"),
                  colSize: 16,
                  type: "Input",
                  validation: {
                    required: true,
                    custom: (vals) => {
                      return vals.email === vals.confirmEmail;
                    },
                    errorMessage: t("commons.emailMustBeTheSame"),
                  },
                }
              : nullObject,
          ],
        },
        {
          fields: state => [
            address
              ? {
                  name: "address",
                  title: t("inscriptionsScreen.detail.address"),
                  placeHolder: t("inscriptionsScreen.detail.address"),
                  colSize: 16,
                  type: "Input",
                  validation: {
                    required: true,
                  },
                }
              : nullObject,
            location1
              ? {
                  name: "country",
                  title: t("clientScreen.detail.country"),
                  placeHolder: t("clientScreen.detail.country"),
                  type: "Select",
                  colSize: 10,
                  validation: {
                    required: true,
                    errorMessage: t("commons.fieldRequired"),
                  },
                  data: countries.map((countryCode) => ({
                    text: t(`countries.${countryCode}`),
                    value: countryCode,
                  })),
                }
              : nullObject,
            ...(location2
              ? [
                  {
                    name: "country",
                    title: t("clientScreen.detail.country"),
                    placeHolder: t("clientScreen.detail.country"),
                    type: "Select",
                    colSize: 10,
                    validation: {
                      required: true,
                      errorMessage: t("commons.fieldRequired"),
                    },
                    data: countries.map((countryCode) => ({
                      text: t(`countries.${countryCode}`),
                      value: countryCode,
                    })),
                  },
                  {
                    name: "region",
                    type: state.country == 'ES' ? "Select" : "Input",
                    data: state.country == 'ES' ? provinces.map(province => ({
                      text: province.title,
                      value: province.title
                    })) : null,
                    props: {
                      clearable: true
                    },
                    title: t("companyDetail.province"),
                    placeHolder: t("companyDetail.province"),
                    colSize: 16,
                    validation: {
                      required: true,
                      regexType: "text",
                    },
                  },
                ]
              : [nullObject]),
            ...(location3
              ? [
                  {
                    name: "country",
                    title: t("clientScreen.detail.country"),
                    placeHolder: t("clientScreen.detail.country"),
                    type: "Select",
                    colSize: 10,
                    validation: {
                      required: true,
                      errorMessage: t("commons.fieldRequired"),
                    },
                    data: countries.map((countryCode) => ({
                      text: t(`countries.${countryCode}`),
                      value: countryCode,
                    })),
                  },
                  {
                    name: "region",
                    type: state.country == 'ES' ? "Select" : "Input",
                    data: state.country == 'ES' ? provinces.map(province => ({
                      text: province.title,
                      value: province.title
                    })) : null,
                    props: {
                      clearable: true
                    },
                    title: t("companyDetail.province"),
                    placeHolder: t("companyDetail.province"),
                    colSize: 16,
                    validation: {
                      required: true,
                      regexType: "text",
                    },
                  },
                  {
                    name: "city",
                    title: t("inscriptionsScreen.detail.city"),
                    placeHolder: t("inscriptionsScreen.detail.city"),
                    colSize: 16,
                    type: "Input",
                    validation: {
                      required: true,
                      errorMessage: t("commons.fieldRequired"),
                    },
                  },
                ]
              : [nullObject]),
            zipCode
              ? {
                  name: "zip_code",
                  title: t("inscriptionsScreen.detail.zipCode"),
                  placeHolder: t("inscriptionsScreen.detail.zipCode"),
                  colSize: 16,
                  type: "Input",
                  validation: {
                    required: true,
                  },
                }
              : nullObject,
            originCountry
              ? {
                  name: "origin_country",
                  title: t("inscriptionsScreen.detail.originCountry"),
                  placeHolder: t("commons.none"),
                  colSize: 16,
                  type: "Select",
                  validation: {
                    required: true,
                    errorMessage: t("commons.fieldRequired"),
                  },
                  data: countries.map((countryCode) => ({
                    text: t(`countries.${countryCode}`),
                    value: countryCode,
                  })),
                }
              : nullObject,
          ],
        },
        {
          fields: [
            phonePrefix
              ? {
                  name: "phone_prefix",
                  title: t("inscriptionsScreen.detail.phonePrefix"),
                  placeHolder: t("inscriptionsScreen.detail.phonePrefix"),
                  colSize: 16,
                  type: "Input",
                  validation: {
                    required: true,
                  },
                }
              : nullObject,
            mobile
              ? {
                  name: "phone",
                  title: t("inscriptionsScreen.detail.phone"),
                  placeHolder: t("inscriptionsScreen.detail.phone"),
                  colSize: 16,
                  type: "Input",
                  validation: {
                    required: true,
                  },
                }
              : nullObject,
            emergencyMobile
              ? {
                  name: "emergency_phone",
                  title: t("inscriptionsScreen.detail.emergencyPhone"),
                  placeHolder: t("inscriptionsScreen.detail.emergencyPhone"),
                  colSize: 16,
                  type: "Input",
                  validation: {
                    required: true,
                  },
                }
              : nullObject,
          ],
        },
        {
          fields: [
            federatedParticipant
              ? {
                  name: "is_federated_participant",
                  title: t("inscriptionsScreen.detail.federatedParticipant"),
                  placeHolder: t(
                    "inscriptionsScreen.detail.federatedParticipant"
                  ),
                  colSize: 16,
                  type: "Checkbox",
                  props: {
                    style: {
                      marginTop: 10,
                    },
                  },
                }
              : nullObject,
            localParticipant
              ? {
                  name: "is_local_participant",
                  title: t("inscriptionsScreen.detail.localParticipant"),
                  placeHolder: t("inscriptionsScreen.detail.localParticipant"),
                  colSize: 16,
                  type: "Checkbox",
                  props: {
                    style: {
                      marginTop: 10,
                    },
                  },
                }
              : nullObject,
          ],
        },
        {
          fields:
            parentalLeave && birthday > eighteenYearsAgo
              ? [
                  {
                    name: "parent_name",
                    title: t("inscriptionsScreen.detail.parentName"),
                    placeHolder: t("commons.none"),
                    colSize: 16,
                    type: "Input",
                    validation: {
                      required: true,
                      errorMessage: t("commons.fieldRequired"),
                    },
                  },
                  {
                    name: "parent_surnames",
                    title: t("inscriptionsScreen.detail.parentSurnames"),
                    placeHolder: t("commons.none"),
                    colSize: 16,
                    type: "Input",
                    validation: {
                      required: true,
                      errorMessage: t("commons.fieldRequired"),
                    },
                  },
                  {
                    name: "parent_dni",
                    title: t("inscriptionsScreen.detail.parentDni"),
                    placeHolder: t("commons.none"),
                    colSize: 16,
                    type: "Input",
                    validation: {
                      required: true,
                      custom: (vals) => {
                        return checkNIF(vals.parent_dni, ["DNI", "NIE"]);
                      },
                      errorMessage: t("commons.invalidCiforNif"),
                    },
                  },
                ]
              : [],
        },
        {
          fields: (state) => [
            photo
              ? {
                  name: "photo",
                  component: (_, __, onChange) => (
                    <ImagePicker
                      content={t("inscriptionsScreen.detail.cardPhoto")}
                      showLabelToOpen={false}
                      onSelect={(data) => onChange(data)}
                      imageSrc={state.photo}
                      markAsRequired={showValidation && !state.photo}
                    />
                  ),
                  validation: {
                    required: true,
                  },
                }
              : nullObject,
          ],
        },
      ]
    : [];

  const extraAttributes =
    attributes && attributes.length > 0
      ? [
          {
            fields: [
              {
                type: "Label",
                title: t("inscriptionsScreen.detail.inscriptionExtra"),
                color: "#2185d0",
              },
            ],
          },
          ...attributes.map((attribute) => {
            const {
              name,
              description,
              required,
              id,
              type,
              maxLength,
              price,
              ModalityAttributesOptions,
            } = attribute;

            switch (type) {
              case "STRING":
                return {
                  fields: [
                    {
                      name: `inscriptionAttributes.${id}`,
                      title: name,
                      placeHolder: description,
                      colSize: 16,
                      type: "Input",
                      validation: {
                        required,
                        custom: (vals) => {
                          if (vals[`inscriptionAttributes.${id}`]) {
                            return (
                              vals[`inscriptionAttributes.${id}`].length <=
                              maxLength
                            );
                          }

                          return true;
                        },
                        errorMessage: t("commons.maxLength", {
                          maxLength,
                        }),
                      },
                    },
                  ],
                };
              case "NUMBER":
                return {
                  fields: [
                    {
                      name: `inscriptionAttributes.${id}`,
                      title: name,
                      placeHolder: description,
                      colSize: 16,
                      type: "Input",
                      props: {
                        type: "number",
                      },
                      validation: {
                        required,
                      },
                    },
                  ],
                };
              case "BOOLEAN":
                return {
                  fields: [
                    {
                      name: `inscriptionAttributes.${id}`,
                      title: price ? `${name} (${price}â‚¬)` : `${name}`,
                      placeHolder: description,
                      colSize: 16,
                      type: "Checkbox",
                    },
                  ],
                };
              case "SELECT":
                return {
                  fields: [
                    {
                      name: `inscriptionAttributes.${id}`,
                      title: `${name}`,
                      placeHolder: description,
                      colSize: 16,
                      type: "Select",
                      data: ModalityAttributesOptions.map((option) => ({
                        text: option.name,
                        value: option.id,
                      })),
                      validation: {
                        required,
                      },
                    },
                  ],
                };
              case "PRODUCT":
                return {
                  fields: [
                    {
                      name: `inscriptionAttributes.${id}`,
                      title: `${name}`,
                      placeHolder: description,
                      colSize: 16,
                      type: "Select",
                      data: ModalityAttributesOptions.map((option) => ({
                        text: `${option.name} (${option.price}â‚¬)`,
                        value: option.id,
                      })),
                      validation: {
                        required,
                      },
                    },
                  ],
                };
            }
          }),
        ]
      : [];
  return [...basicFields, ...extraFields, ...extraAttributes];
};

export const createFormRefund = (props) => {
  const form = document.createElement("form");
  form.style = "display:none;";

  Object.keys(props).forEach((prop) => {
    if (prop != "url") {
      const element = document.createElement("input");

      element.name = prop;
      element.value = props[prop];

      form.appendChild(element);
    }
  });

  form.method = "POST";
  form.action = props.url;
  document.body.appendChild(form);
  form.submit();
};

export const dorsalDeliveredByQRFields = (t, activeInscription) =>
  !activeInscription.inscription.modality.is_dorsal_to_be_delivered
    ? [
        {
          fields: [
            {
              type: "Checkbox",
              colSize: 4,
              title: t("inscriptionsScreen.detail.checkedIn"),
              name: "checked_in",
              props: { readOnly: true },
            },
            {
              type: "Input",
              colSize: 8,
              title: t("inscriptionsScreen.detail.qrReader"),
              name: "qrReaderToDeliver",
              props: { readOnly: true },
            },
            {
              type: "Input",
              colSize: 4,
              title: t("inscriptionsScreen.detail.extraDeliveryDate"),
              name: "dorsal_delivery_date",
              props: { readOnly: true },
            },
          ],
        },
      ]
    : [
        {
          fields: [
            {
              type: "Checkbox",
              colSize: 8,
              title: t("inscriptionsScreen.detail.checkedIn"),
              name: "checked_in",
              props: { readOnly: true },
            },
            {
              type: "Checkbox",
              colSize: 8,
              title: t("inscriptionsScreen.detail.dorsalDelivered"),
              name: "dorsal_delivered",
              props: { readOnly: true },
            },
          ],
        },
        {
          fields: [
            {
              type: "Input",
              colSize: 8,
              title: t("inscriptionsScreen.detail.qrReader"),
              name: "qrReaderToDeliver",
              props: { readOnly: true },
            },
            {
              type: "Input",
              colSize: 8,
              title: t("inscriptionsScreen.detail.dorsalDeliveryDate"),
              name: "dorsal_delivery_date",
              props: { readOnly: true },
            },
          ],
        },
      ];

export const extraDeliveredByQRFields = (t, activeInscription) => [
  {
    fields: [
      {
        type: "Label",
        title: t("inscriptionsScreen.detail.inscriptionExtra"),
        color: "#2185d0",
      },
    ],
  },
  ...activeInscription.inscriptionAttributes
    .filter((attr)=> {
      if(attr.modalityAttribute.type == 'BOOLEAN'){
        return attr.value == "true"
      }
      return true
    } )
    .filter(attr =>!!attr.modalityAttribute.isToBeDelivered)
    .map((attr) => ({
      fields: [
        {
          type: "Input",
          title: t("inscriptionsScreen.detail.extraName"),
          name: `name_${attr.modalityAttributeId}`,
          colSize: 4,
          props: {
            readOnly: true,
          },
        },
        {
          type: "Checkbox",
          title: t("inscriptionsScreen.detail.extraDelivered"),
          name: `delivered_${attr.modalityAttributeId}`,
          colSize: 3,
          props: {
            readOnly: true,
          },
        },
        {
          type: "Input",
          title: t("inscriptionsScreen.detail.qrReader"),
          name: `qrReader_${attr.modalityAttributeId}`,
          colSize: 6,
          props: {
            readOnly: true,
          },
        },
        {
          type: "Input",
          title: t("inscriptionsScreen.detail.extraDeliveryDate"),
          name: `deliveryDate_${attr.modalityAttributeId}`,
          colSize: 3,
          props: {
            readOnly: true,
          },
        },
      ],
    })),
];

export const getDefaultStateForExtraDeliverable = (activeInscription) =>
  activeInscription.inscriptionAttributes
    .filter((attr) => !!attr.modalityAttribute.isToBeDelivered)
    .reduce((acc, attr) => {
      const newAcc = {
        ...acc,
        [`name_${attr.modalityAttributeId}`]: attr.modalityAttribute.name,
        [`delivered_${attr.modalityAttributeId}`]: !!attr.deliveryDate,
        [`qrReader_${attr.modalityAttributeId}`]: attr.qrReader?.email,
        [`deliveryDate_${attr.modalityAttributeId}`]: attr.deliveryDate
          ? moment(attr.deliveryDate).format("L")
          : null,
      };

      return newAcc;
    }, {});
