import React, { Component } from "react";
import {
  Container,
  Divider,
  Segment,
  Button,
  Header,
  Grid,
  Dimmer,
  Loader,
  Icon,
  Message,
  Image,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { DAlert } from "../../../../components/Dalert";
import CancelInscription from "./CancelInscription";
import RefundInscription from "./RefundInscription";
import ChangeModality from "./ChangeModality";
import Form from "../../../../components/Form";
import Table from "../../../../components/DTable";
import PriceDetail from "./PriceDetail";
import provincesApi from "../../../../apis/regions";
import AssignPlaceButton from "../../../../components/AssignPlaceButton";

import {
  paymentFields,
  teamTableHeaders,
  inscriptionFields,
  additionalFields,
  extraFields,
  createFormRefund,
  refundDescriptionField,
  dorsalDeliveredByQRFields,
  extraDeliveredByQRFields,
  getDefaultStateForExtraDeliverable,
  tableHeaders
} from "./util";
import { getObjectByStart } from "../../../../util/util";
import modalityApi from "../../../../apis/modalities";
import raceApi from "../../../../apis/races";
import clubApi from "../../../../apis/clubs";
import inscriptionApi from "../../../../apis/inscriptions";
import { modalHandler } from "../../../../components/DModal";
import es from "../../../../locales/default.es";
import { getCountries } from "../../../../reducers/countries";
import { getModality } from "../../../../reducers/modalities";
import { getRace } from "../../../../reducers/races";
import { changeCase } from "../../../../util/util"

@connect(
  (state) => ({
    userRole: state.user.role,
    countries: state.countries.countries,
    race: state.races.actualRace,
  }),
  (dispatch) => ({
    actions: bindActionCreators(
      { getModality, getRace, getCountries },
      dispatch
    ),
  })
)
@withTranslation()
export default class InscriptionDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      inscriptionChanged: {},
      inscriptions: [],
      priceDetail: {},
      activeInscription: {},
      modalityConfig: {},
      inscriptionExtra: [],
      isLoading: true,
      inscriptionsToErase: [],
      inscriptionsToRefund: [],
      amountToRefund: 0,
      descriptionOfRefund: null,
      showRefundButtons: false,
      selectedModality: null,
      inscriptionsToMoveValidation: false,
      inscriptionsToMove: [],
      clubs: {},
      AdditionalChanged: {},
      ExtraChanged: {},
      showValidation: false,
      race: {},
      registryResetQr:[]
    };

    const queryParams = props.location.search;
    this.inscriptionIsFinished = !queryParams
    ? true
    : JSON.parse(new URLSearchParams(queryParams.split("?").pop()).get("f"))
  }

  async getData(actualId = -1) {
    const {
      params: { id, raceId },
    } = this.props.match;

    const {
      inscriptions,
      inscriptionExtraFields,
    } = await inscriptionApi.getInscription(id, { f: this.inscriptionIsFinished });

    const firstInscription = inscriptions[0].inscription;
    const modalityConfig = await modalityApi.fetchModalityConfiguration(
      firstInscription.modality_id
    );

    const modality = firstInscription?.modality;
    const actualInscription = inscriptions.find((inscription) => {
      if (actualId > 0) {
        return inscription.id === actualId;
      } else {
        return inscription.id === +id;
      }
    });
    const race = await raceApi.fetchRace(raceId);
    this.setState({
      race,
      inscriptions,
      priceDetail: firstInscription,
      activeInscription: {
        ...actualInscription,
        inscriptionPrice: actualInscription.inscriptionPrice || {
          amount: modalityConfig.priceDefault,
        },
      },
      modalityConfig,
      inscriptionExtra: inscriptionExtraFields,
      isLoading: false,
      showRefundButtons:
        new Date() < new Date(modality.finish_date || modality.start_date),
      windowWidth: window.innerWidth
    });
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentWillUnMount() {
    window.addEventListener("resize", this.handleResize);
  }
  async componentDidMount() {

    window.addEventListener("resize", this.handleResize);
    const { actions, countries, race, match, t } = this.props;
    const {
      params: { raceId },
    } = match;

    if (isEmpty(race) || raceId !== race.id) {
      actions.getRace(raceId);
    }
    actions.getCountries(countries);
    this.fetchProvinces();
    clubApi.get().then((clubs) => {
      this.setState({ clubs: clubs.map((club) => ({ title: club.name })) });
    });
    this.getData();

    try {
      const queryParams = this.props.location.search;
      const wasCreatedFromNotFinished = !queryParams
        ? false
        : JSON.parse(
            new URLSearchParams(queryParams.split("?").pop()).get("c")
          );
          
      if (wasCreatedFromNotFinished) {
        DAlert.success({
          title: t("commons.savedSuccess"),
        });
      }
    } catch (err) { console.error(err); }
  }

  async fetchProvinces() {
    provincesApi.fetchRegions().then((provinces) => {
      this.setState({
        provinces: provinces.map((provinces) => ({ title: provinces.name })),
      });
    });
  }

  async updateInscriptionStatus() {
    const { t } = this.props;
    const { priceDetail, change } = this.state;
    if (change.ISFORMVALID) {
      this.setState({ isLoading: true });
      const params = {
        inscriptionId: priceDetail.id,
        status: change.inscriptionStatus,
        f: this.inscriptionIsFinished,
      };
      const res = await inscriptionApi.updateInscription(params);
      if (res.status === 200) {
        DAlert.success({ message: t("commons.savedSuccess") });
        this.getData();
      } else {
        this.setState({ isLoading: false });
        DAlert.error({ message: t("commons.errorSaving") });
      }
    }
  }

  async resendVoucher() {
    const { t } = this.props;
    const { activeInscription } = this.state;
    this.setState({ isLoading: true });

    const inscriptionId = activeInscription.id;
    const res = await inscriptionApi.resendVoucher(inscriptionId);
    this.setState({ isLoading: false });
    if(res.error){
      DAlert.error({
        message: t(`inscriptionsScreen.detail.${res.error.message}`),
      });
    }else if (res.ok) {
      DAlert.success({
        message: t("inscriptionsScreen.detail.voucherResended"),
      });
    } else if (!res.ok) {
      DAlert.success({
        message: t("inscriptionsScreen.detail.voucherResended"),
      });
    }
  }

  async downloadVoucher() {
    const { activeInscription } = this.state;
    this.setState({ isLoading: true });

    const slug = activeInscription.inscription.modality.slug;
    const params = {
      nif: activeInscription.dni,
      birthDate: activeInscription.birthday,
    };
    const modalityId = activeInscription.inscription.modality_id;

    await modalityApi.getVoucherPdf(modalityId, params, slug);
    this.setState({ isLoading: false });
  }

  async updateInscription() {
    const { t } = this.props;
    const {
      activeInscription,
      inscriptionChanged,
      AdditionalChanged,
      ExtraChanged,
    } = this.state;
    //AdditionalChanged is empty or valid
    const validAdditionalChanged =
      !Object.keys(AdditionalChanged).length || AdditionalChanged.ISFORMVALID;

    if (inscriptionChanged.ISFORMVALID && validAdditionalChanged) {
      const upperDni = changeCase(inscriptionChanged.dni) //send dni always in upperCase
      this.setState({ isLoading: true, showValidation: false });
      const params = {
        ...AdditionalChanged,
        ...inscriptionChanged, 
        dni:upperDni,
        attributes: { ...ExtraChanged },
        f: this.inscriptionIsFinished,
      };
      const res = await inscriptionApi.updateClientInscription(
        activeInscription.id,
        params
      );
      if (res.status === 200 && res.data.length) {
        DAlert.success({ message: t("commons.savedSuccess") });
        this.getData(activeInscription.id);
      } else {
        this.setState({ isLoading: false });
        if(res.error.message==="TAX_IDS_DUPLICATED") {
          DAlert.error({ message: t("inscriptionsScreen.detail.inscriptionErrors.TAX_IDS_DUPLICATED") });
        }else{
          DAlert.error({ message: t("commons.errorSaving") });
        }
      }
    } else {
      this.setState({ showValidation: true });
    }
  }

  modalOptions() {
    const { t } = this.props;
    const { inscriptions, inscriptionsToErase, error } = this.state;

    const isTeamInscription = inscriptions[0]?.team_id;
    return {
      header: t("inscriptionsScreen.detail.cancelInscriptions"),
      content: (
        <div>
          <Container>
            <CancelInscription
              inscriptions={inscriptions}
              onChange={(checked, data) =>
                this.setState(
                  {
                    inscriptionsToErase: checked
                      ? [
                        ...inscriptionsToErase,
                        {
                          id: data.id,
                          amountToReturn: data.price - data.total_refund,
                        },
                      ]
                      : inscriptionsToErase.filter(
                        (inscription) => inscription.id !== data.id
                      ),
                  },
                  () =>
                    modalHandler.refreshOptions({
                      ...this.modalOptions(),
                    })
                )
              }
            />
            <Divider hidden />
            {isTeamInscription && (
              <p
                style={{
                  backgroundColor: "red",
                  color: "white",
                  fontWeight: "bold",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                {t("inscriptionsScreen.detail.cancelTeamInscriptions")}
              </p>
            )}
            <Divider hidden />
            <Form
              onFormChange={(formData) => {
                if (formData.ISFORMVALID) {
                  this.setState(
                    { descriptionOfRefund: formData.description },
                    () =>
                      modalHandler.refreshOptions({
                        ...this.modalOptions(),
                      })
                  );
                }
              }}
              fields={refundDescriptionField(t)}
            />
            <Divider hidden />
            {error && (
              <Message
                negative
                header={t("commons.errorSaving")}
                content={
                  error in es.inscriptionsScreen.detail.returnMoneyErrors &&
                  t(`inscriptionsScreen.detail.returnMoneyErrors.${error}`)
                }
              />
            )}
            <b>
              {t("inscriptionsScreen.detail.totalAmountToRetun", {
                total: `${parseFloat(
                  inscriptionsToErase.reduce(
                    (acc, inscription) =>
                      acc + (inscription.amountToReturn || 0),
                    0
                  )
                ).toFixed(2)}€`,
              })}
            </b>
          </Container>
        </div>
      ),
      actions: (
        <Button
          onClick={() => this.onCancelHandler()}
          primary
          disabled={inscriptionsToErase.length === 0}
        >
          <Icon name="remove" />
          {t("inscriptionsScreen.detail.cancelInscriptions")}
        </Button>
      ),
    };
  }
  async onCancelHandler() {
    const {
      inscriptionsToErase,
      activeInscription,
      descriptionOfRefund,
    } = this.state;
    const { inscription_id: inscriptionId } = activeInscription;
    const response = await inscriptionApi.cancelInscription(inscriptionId, {
      inscriptions: inscriptionsToErase,
      description: descriptionOfRefund,
    });

    this.refundResponseHandler(response);
  }

  modalUncancelOptions() {
    const { t } = this.props;
    const { inscriptions, inscriptionsToErase, error } = this.state;

    return {
      header: t("inscriptionsScreen.detail.unCancelInscriptions"),
      content: (
        <Container>
          <CancelInscription
            unCancel={true}
            inscriptions={inscriptions}
            onChange={(checked, data) =>
              this.setState(
                {
                  inscriptionsToErase: checked
                    ? [
                      ...inscriptionsToErase,
                      {
                        id: data.id,
                        amountToReturn: this.inscriptionIsFinished ? data.price - data.total_refund : 0,
                      },
                    ]
                    : inscriptionsToErase.filter(
                      (inscription) => inscription.id !== data.id
                    ),
                },
                () =>
                  modalHandler.refreshOptions({
                    ...this.modalUncancelOptions(),
                  })
              )
            }
            isFinished={this.inscriptionIsFinished}
          />
        </Container>
      ),
      actions: (
        <Button
          onClick={() => this.onUncancelHandler()}
          primary
          disabled={inscriptionsToErase.length === 0}
        >
          <Icon name="remove" />
          {t("inscriptionsScreen.detail.unCancelInscriptions")}
        </Button>
      ),
    };
  }
  async onUncancelHandler() {
    const { inscriptionsToErase, activeInscription } = this.state;
    const { inscription_id: inscriptionId } = activeInscription;

    const response = await inscriptionApi.unCancelInscription(inscriptionId, {
      inscriptions: inscriptionsToErase,
      f: this.inscriptionIsFinished
    });

    this.afterUncancelResponseHandler(response);
  }

  afterUncancelResponseHandler(response, isPartial = false) {
    const { history, t } = this.props;
    if (typeof response.ok !== "undefined" && !response.ok) {
      this.setState({
        error: response.error.message,
      });

      return;
    }
    DAlert.success({ message: t("commons.savedSuccess") });
    modalHandler.close();
    history.goBack();
  }

  modalRefundOptions() {
    const { t } = this.props;
    const {
      error,
      priceDetail,
      amountToRefund,
      inscriptions,
      inscriptionsToRefund,
    } = this.state;
    this.setState({
      amountToRefund: parseFloat(
        inscriptionsToRefund.reduce(
          (acc, inscription) => acc + (inscription.amountToRefund || 0),
          0
        )
      ).toFixed(2),
    });
    const alreadyRefund = parseFloat(priceDetail.totalRefund || 0);

    return {
      header: t("inscriptionsScreen.detail.partialReturn"),
      content: (
        <Container>
          <RefundInscription
            inscriptions={inscriptions}
            unCancel={false}
            error={error}
            priceDetail={priceDetail}
            alreadyRefund={alreadyRefund}
            onChange={(value, data) => {
              value = isNaN(parseFloat(value.replace(",", ".")))
                ? 0.0
                : parseFloat(value.replace(",", "."));

              this.setState(
                {
                  inscriptionsToRefund: [
                    ...inscriptionsToRefund.filter(
                      (inscription) =>
                        inscription.clientInscriptionId !== data.id
                    ),
                    {
                      clientInscriptionId: data.id,
                      amountToRefund: +value.toFixed(2),
                      maxToRefund: +(data.price - data.total_refund).toFixed(2),
                    },
                  ],
                },
                () =>
                  modalHandler.refreshOptions({
                    ...this.modalRefundOptions(),
                  })
              );
            }}
          />
          <Divider hidden />
          <Form
            onFormChange={(formData) => {
              if (formData.ISFORMVALID) {
                this.setState(
                  { descriptionOfRefund: formData.description },
                  () =>
                    modalHandler.refreshOptions({
                      ...this.modalRefundOptions(),
                    })
                );
              }
            }}
            fields={refundDescriptionField(t)}
          />
          <Divider hidden />
          {error && (
            <Message
              negative
              header={t("commons.errorSaving")}
              content={
                error in es.inscriptionsScreen.detail.returnMoneyErrors &&
                t(`inscriptionsScreen.detail.returnMoneyErrors.${error}`)
              }
            />
          )}
          <b>
            {t("inscriptionsScreen.detail.totalAmountToRetun", {
              total: `${parseFloat(
                inscriptionsToRefund.reduce(
                  (acc, inscription) => acc + (inscription.amountToRefund || 0),
                  0
                )
              ).toFixed(2)}€`,
            })}
          </b>
        </Container>
      ),
      actions: (
        <Button
          onClick={() => this.onPartialRefund()}
          primary
        // disabled={amountToRefund == 0.00 || amountToRefund === null}
        >
          <Icon name="remove" />
          {t("inscriptionsScreen.detail.partialReturn")}
        </Button>
      ),
    };
  }

  async onPartialRefund() {
    const { t } = this.props;
    const {
      inscriptionsToRefund,
      activeInscription,
      descriptionOfRefund,
    } = this.state;

    const { inscription_id: inscriptionId } = activeInscription;

    const allZero = inscriptionsToRefund.every((i) => i.amountToRefund == 0);
    if (allZero) {
      DAlert.error({
        message: t("inscriptionsScreen.detail.returnMoneyErrors.ZERO_AMOUNT"),
      });
      return;
    }
    const tooMuch = inscriptionsToRefund.some(
      (i) => i.amountToRefund > i.maxToRefund
    );
    if (tooMuch) {
      DAlert.error({
        message: t(
          "inscriptionsScreen.detail.returnMoneyErrors.INCORRECT_AMOUNT"
        ),
      });
      return;
    }
    const response = await inscriptionApi.refundInscription(inscriptionId, {
      inscriptionsToRefund,
      description: descriptionOfRefund,
    });

    this.refundResponseHandler(response, true);
  }

  refundResponseHandler(response, isPartial = false) {
    const { history, t } = this.props;

    if (typeof response.ok !== "undefined" && !response.ok) {
      this.setState({
        error: response.error.message,
      });

      modalHandler.refreshOptions(
        isPartial
          ? {
            ...this.modalRefundOptions(),
          }
          : {
            ...this.modalOptions(),
          }
      );

      return;
    }

    if (response.makeRefund) {
      createFormRefund(response.form);
    } else if (!response.makeRefund && (response.ok || response.ok === undefined)) {
      DAlert.success({ message: t("commons.savedSuccess") });
      modalHandler.close();
      history.goBack();
    } else {
      if (response.error.message === "NO_TOKEN") {
        DAlert.error({
          message: t("inscriptionsScreen.detail.returnMoneyErrors.TOKEN_ERROR"),
        });
      } else {
        DAlert.error({ message: t("inscriptionsScreen.refund.refundFailure") });
      }
    }
  }

  modalChangeModalityOptions() {
    const { t, match } = this.props;
    const { raceId } = match.params;
    const {
      selectedModality,
      activeInscription,
      inscriptions,
      inscriptionsToMoveValidation,
    } = this.state;
    const isTeamInscription = !!activeInscription.team_id;

    return {
      header: t("inscriptionsScreen.detail.changeModality"),
      content: isTeamInscription ? (
        <React.Fragment>
          {t("inscriptionsScreen.detail.changeModalityTeam")}
        </React.Fragment>
      ) : (
          <ChangeModality
            onMoveInscriptions={this.onMoveInscriptions.bind(this)}
            raceId={raceId}
            inscriptions={inscriptions}
            modalityId={activeInscription.inscription.modality.id}
            showValidations={inscriptionsToMoveValidation}
            onFormChange={({ selectedModality }) =>
              this.setState({ selectedModality }, () =>
                modalHandler.refreshOptions({
                  ...this.modalChangeModalityOptions(),
                })
              )
            }
            onInscriptionsChange={(inscriptions) => {
              this.setState({ inscriptionsToMove: inscriptions }, () =>
                modalHandler.refreshOptions({
                  ...this.modalChangeModalityOptions(),
                })
              );
            }}
          />
        ),
      actions: isTeamInscription ? (
        <Button
          onClick={() => modalHandler.close()}
          primary
        // disabled={amountToRefund == 0.00 || amountToRefund === null}
        >
          <Icon name="remove" />
          {t("commons.close")}
        </Button>
      ) : (
          <></>
        ),
    };
  }

  async onMoveInscriptions() {
    const { t } = this.props;
    const {
      inscriptionsToMove,
      activeInscription,
      selectedModality,
    } = this.state;
    const isValid = inscriptionsToMove.every(
      (inscription) => !!inscription.ISFORMVALID
    );

    if (!isValid) {
      this.setState({ inscriptionsToMoveValidation: true }, () =>
        modalHandler.refreshOptions({
          ...this.modalChangeModalityOptions(),
        })
      );
      DAlert.error({ message: t("commons.fieldsRequired") });
      return;
    }

    const newInscriptions = inscriptionsToMove.map((inscription) => ({
      ...inscription,
      attributes: getObjectByStart("inscriptionAttributes", inscription),
    }));

    const response = await inscriptionApi.changeInscriptionModality(
      activeInscription.inscription.id,
      {
        modalitySlug: selectedModality,
        inscriptions: newInscriptions,
      }
    );

    if (typeof response.ok !== "undefined" && !response.ok) {
      DAlert.error({
        header: t("commons.errorSaving"),
        message:
          response.error.message in
          es.inscriptionsScreen.detail.inscriptionErrors &&
          t(
            `inscriptionsScreen.detail.inscriptionErrors.${response.error.message}`
          ),
      });
    } else {
      DAlert.success({ message: t("commons.savedSuccess") });
      this.getData();
      modalHandler.close();
    }
  }

  openModalHandler(type) {
    this.setState(
      {
        error: null,
        inscriptionsToErase: [],
        inscriptionsToRefund: [],
      },
      () =>
        modalHandler.open(
          type === "REFUND"
            ? this.modalRefundOptions()
            : type === "CANCEL"
              ? this.modalOptions()
              : type === "UNCANCEL"
                ? this.modalUncancelOptions()
                : this.modalChangeModalityOptions()
        )
    );
  }

  initialExtras() {
    const { activeInscription } = this.state;

    if (activeInscription.inscriptionAttributes) {
      return activeInscription.inscriptionAttributes.reduce((acc, curr) => {
        let val = null;

        if (curr.modalityAttribute.type == "BOOLEAN") {
          val = curr.value == "true";
        }
        if (
          curr.modalityAttribute.type == "SELECT" ||
          curr.modalityAttribute.type == "PRODUCT"
        ) {
          if (parseInt(curr.value)) {
            val = parseInt(curr.value);
          } else {
            val = curr.value;
          }
        }
        if (curr.modalityAttribute.type == "STRING") {
          val = curr.value;
        }
        if (curr.modalityAttribute.type == "NUMBER") {
          if (parseInt(curr.value)) {
            val = parseInt(curr.value);
          } else {
            val = curr.value;
          }
        }

        acc[curr.modalityAttribute.name] = val;

        return acc;
      }, {});
    }
  }

  cancelInscription = async (row) => {
    const { activeInscription } = this.state;
    if (!row) {
      row = activeInscription;
    }
    const { id, name, surnames, total_refund, price, team_id } = row;

    const isTeamInscription = !!team_id;
    const amount = (price - (total_refund || 0)).toFixed(2);
    const { t } = this.props;

    modalHandler.open({
      header: t("commons.areYouSure"),
      content: (
        <div>
          <p>
            {t("inscriptionsScreen.detail.cancelInscriptionConfirm", {
              name,
              surnames,
              amount,
            })}
          </p>
          {isTeamInscription && (
            <p
              style={{
                backgroundColor: "red",
                color: "white",
                fontWeight: "bold",
                padding: "5px",
                textAlign: "center",
              }}
            >
              {t("inscriptionsScreen.detail.cancelTeamInscription")}
            </p>
          )}
        </div>
      ),
      basic: false,
      size: "tiny",
      onYes: async () => {
        const response = await inscriptionApi.cancelInscriptionAndNotRefundMoney(
          id, { f: this.inscriptionIsFinished }
        );
        if (typeof response === "undefined") {
          DAlert.error({ message: t("commons.serverError") });
        } else {
          modalHandler.close();
          await this.getData();
        }
      },
    });
  };

  predicateBy(prop, sort) {
    if (sort == "desc") {
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      };
    } else {
      return function (a, b) {
        if (a[prop] < b[prop]) {
          return 1;
        } else if (a[prop] > b[prop]) {
          return -1;
        }
        return 0;
      };
    }
  }

  async sort(data) {
    const { inscriptions } = this.state;
    if (data.field && data.sort) {
      const sorted = inscriptions.sort(this.predicateBy(data.field, data.sort));
      this.setState({ inscriptions: sorted });
    }
  }

  async resetQr() {
    const { t } = this.props;
    const { activeInscription} = this.state;
    this.setState({ isLoading: true });
    const id = activeInscription.id
    const res =  await inscriptionApi.resetQr(id)
    if (res.status === 200) {
      DAlert.success({ message: t("commons.resetQrSuccess") });
      this.getData(id);
    } else {
        if(res.error.message === 'reset_not_found')
          DAlert.error({ message: t("commons.resetQrErrorNotFound") });
        else
          DAlert.error({ message: t("commons.resetQrError") });
    }
    this.setState({ isLoading: false });
  }
  render() {
    const { t, history, userRole, countries } = this.props;
    const {
      inscriptionChanged,
      AdditionalChanged,
      ExtraChanged,
      inscriptions,
      priceDetail,
      activeInscription,
      inscriptionExtra,
      modalityConfig,
      isLoading,
      showRefundButtons,
      clubs,
      provinces,
      showValidation,
      race,
      windowWidth,
    } = this.state;
    const isTeamInscription = inscriptions[0]?.team_id;
    const isMobile = windowWidth < 480;
    const {
      birthday,
      gender,
      inscriptionAttributes,
      inscriptionDiscount,
      inscriptionPrice,
    } = activeInscription;
    const copernico_client_id = activeInscription.copernico_client_id ? activeInscription.copernico_client_id : null;
    const momentBirthday = moment(birthday);
    const payment = activeInscription?.inscription?.payment;
    const showButtonsForRole =
      userRole === "ADMINISTRATOR" || userRole === "COMPANY";
    const inscriptionCanceled = activeInscription.status === "CANCELLED";
    const { under14_dni } = race
    const { selectClub } = modalityConfig

    return (
      <Container style={{ minHeight: "80vh" }}>
        {isLoading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}

        <Divider horizontal>
          {t("inscriptionsScreen.detail.inscriptionPaymentInfo")}
        </Divider>

        <Form
          defaultState={{
            ...priceDetail,
            inscriptionStatus: priceDetail.status,
            modalityName: priceDetail?.modality?.name,
            ...payment,
            price: `${
              priceDetail?.price ? priceDetail?.price?.toFixed(2) : 0.0
              } €`,
            totalRefund: this.inscriptionIsFinished ? `${
              inscriptions
                .reduce((acc, curr) => acc + curr.total_refund, 0)
                .toFixed(2) || 0.0
              } €` : '0.00 €',
            raceName: race.name,
          }}
          onFormChange={(data) => {
            this.setState({ change: data });
          }}
          fields={paymentFields(t)}
        />
        <Segment basic>
          <Button
            style={{ margin: '5px 2px' }}
            // floated="right"
            fluid={isMobile}
            color="google plus"
            onClick={() => this.updateInscriptionStatus()}
          >
            {t("inscriptionsScreen.detail.changePayment")}
          </Button>
          {showRefundButtons &&
            showButtonsForRole && this.inscriptionIsFinished &&
            inscriptions.some(
              (inscription) => inscription.status !== "CANCELLED"
            ) && (
              <React.Fragment>
                <Button
                  style={{ margin: '5px 2px' }}
                  // floated="right"
                  fluid={isMobile}
                  color="vk"
                  onClick={() => this.openModalHandler("CANCEL")}
                >
                  {t("inscriptionsScreen.detail.cancelPayment")}
                </Button>
                <Button
                  style={{ margin: '5px 2px' }}
                  // floated="right"
                  fluid={isMobile}
                  color="vk"
                  onClick={() => this.openModalHandler("REFUND")}
                >
                  {t("inscriptionsScreen.detail.refund")}
                </Button>
                <Button
                  style={{ margin: '5px 2px' }}
                  // floated="right"
                  fluid={isMobile}
                  color="vk"
                  onClick={() => this.openModalHandler("MODALITY")}
                >
                  {t("inscriptionsScreen.detail.changeModality")}
                </Button>
              </React.Fragment>
            )}
          {inscriptions.some(
            (inscription) => inscription.status === "CANCELLED"
          ) && (
              <React.Fragment>
                <Button
                  // floated="right"
                  color="vk"
                  onClick={() => this.openModalHandler("UNCANCEL")}
                >
                  {t("inscriptionsScreen.detail.unCancelInscriptions")}
                </Button>
              </React.Fragment>
            )}
        </Segment>
        <br />
        {inscriptions.length > 1 && (
          <React.Fragment>
            <Divider horizontal>
              {isTeamInscription
                ? t("inscriptionsScreen.detail.teamInfo")
                : t("inscriptionsScreen.detail.inscriptionsInfo")}
            </Divider>
            <Segment basic>
              {isTeamInscription && (
                <Grid columns={2} stackable relaxed="very">
                  <Grid.Column verticalAlign="middle">
                    <Header
                      content={inscriptions[0]?.team?.name}
                      subheader={t("inscriptionsScreen.detail.teamName")}
                    />
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle">
                    <Header
                      content={inscriptions[0]?.team?.teamType.name}
                      subheader={t("inscriptionsScreen.detail.teamType")}
                    />
                  </Grid.Column>
                </Grid>
              )}
              <Table
                headers={teamTableHeaders(t)}
                onRowClick={(data) =>
                  this.setState({ activeInscription: data })
                }
                contentData={inscriptions}
                onTableChange={(filter) => this.sort(filter)}
                showItemsPerPage={false}
                showNumberOfRecords={false}
                showPagination={false}
                showTotalItems={false}
                showSelectedRow={true}
                defaultSelectedRow={inscriptions.findIndex(
                  (inscription) => inscription.id === activeInscription.id
                )}
                rowProps={(data) =>
                  data.status === "CANCELLED"
                    ? { negative: true, className: "strikethrough" }
                    : {}
                }
                parseData={(row) => ({
                  btn:
                    row.status !== "CANCELLED" ? (
                      <Icon
                        name="times"
                        style={{ color: "#db2828" }}
                        title={t("inscriptionsScreen.detail.cancelInscription")}
                        onClick={() => this.cancelInscription(row)}
                      />
                    ) : null,
                })}
              />
            </Segment>
          </React.Fragment>
        )}
        <Divider horizontal>
          {t("inscriptionsScreen.detail.inscriptionInfo")}
        </Divider>

        {inscriptionCanceled && (
          <Segment padded color="red" textAlign="center" inverted>
            {t("inscriptionsScreen.detail.cancelled")}
          </Segment>
        )}
        <Segment>
          <Form
            defaultState={activeInscription}
            onFormChange={(data) => {
              this.setState({
                inscriptionChanged: { ...inscriptionChanged, ...data },
              });
            }}
            showValidation={showValidation}
            fields={inscriptionFields(t, clubs, copernico_client_id, under14_dni, activeInscription, selectClub)}
          />
          <Form
            defaultState={activeInscription}
            showValidation={showValidation}
            onFormChange={(data) => {
              this.setState({
                AdditionalChanged: { ...AdditionalChanged, ...data },
              });
            }}
            fields={additionalFields(
              t,
              modalityConfig,
              countries,
              provinces,
              inscriptionChanged,
              showValidation
            )}
          />
          <Form
            defaultState={{ ...this.initialExtras(), ...activeInscription }}
            showValidation={showValidation}
            onFormChange={(data) => {
              if (data.ISFORMVALID) {
                let changes = inscriptionExtra.map((extra) => {
                  return {
                    id: extra.id,
                    value:
                      data[extra.name] != undefined
                        ? data[extra.name].toString()
                        : null,
                  };
                });
                this.setState({
                  ExtraChanged: { ...ExtraChanged, ...changes },
                });
              }
            }}
            fields={extraFields(
              t,
              inscriptionAttributes || [],
              inscriptionExtra
            )}
          />

          {activeInscription && activeInscription.id && this.inscriptionIsFinished && (
            <Segment basic>
              <Grid columns={2} stackable>
                <Grid.Row verticalAlign="middle">
                  <Grid.Column width="5">
                    <Segment
                      basic
                      textAlign="center"
                      style={{ margin: 0, padding: 0 }}
                    >
                      <Header>{t("inscriptionsScreen.detail.qrCode")}</Header>
                      <Image style={{"max-height": "150px"}}
                        centered
                        src={`${activeInscription.qr_code}`}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width="11">
                    <Form
                      fields={dorsalDeliveredByQRFields(t, activeInscription)}
                      defaultState={{
                        ...activeInscription,
                        qrReaderToDeliver: activeInscription.qrReader?.email,
                        dorsal_delivery_date: activeInscription.dorsal_delivery_date 
                          ? moment(activeInscription.dorsal_delivery_date).format("L")
                          : activeInscription.dorsal_delivery_date
                      }}
                    />
                    {activeInscription.inscriptionAttributes.filter(
                      (attr) => !!attr.modalityAttribute.isToBeDelivered
                    ).length > 0 && (
                      <Form
                        defaultState={getDefaultStateForExtraDeliverable(activeInscription)}
                        fields={extraDeliveredByQRFields(t, activeInscription)}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
                  <Table
                    contentData={activeInscription.qr_reset_registry}
                    headers={tableHeaders(t)}
                    autoPaginate={true}
                    rowsPerPage={5}
                    totalData={activeInscription.qr_reset_registry.length}
                    parseData={(data) => ({
                      qr_date: moment(data.qr_date).format("YYYY-MM-DD HH:mm"),
                      qr_reset_date:moment(data.qr_reset_date).format("YYYY-MM-DD HH:mm"),
                    })}
                />
              </Grid>
            </Segment>
          )}
          <Segment basic style={{ marginBottom: 40 }}>
            {!inscriptionCanceled && (
              <div>
                {this.inscriptionIsFinished && (
                  <>
                    <Button
                      style={{ margin: '5px 2px' }}
                      // floated="left"
                      fluid={isMobile}
                      color="vk"
                      onClick={() => this.resendVoucher()}
                    >
                      {t("inscriptionsScreen.detail.button.resendVoucher")}
                    </Button>
                    <Button
                      style={{ margin: '5px 2px' }}
                      // floated="left"
                      fluid={isMobile}
                      color="vk"
                      onClick={() => this.downloadVoucher()}
                    >
                      {t("inscriptionsScreen.detail.button.downloadVoucher")}
                    </Button>
                    <Button
                      style={{ margin: '5px 2px' }}
                      fluid={isMobile}
                      color="vk"
                      onClick={() => this.resetQr()}
                    >
                      {t("inscriptionsScreen.detail.button.resetQr")}
                    </Button>
                  </>
                )}
                {inscriptions.length === 1 && !inscriptionCanceled && (
                  <Button
                    style={{ margin: '5px 2px' }}
                    // floated="left"
                    fluid={isMobile}
                    color="red"
                    onClick={() => this.cancelInscription()}
                  >
                    {t("inscriptionsScreen.detail.cancelInscription")}
                  </Button>
                )}
                {!inscriptionCanceled && (
                  <Button
                    style={{ margin: '5px 2px' }}
                    {...(window.innerWidth > 700 ? { floated:"right" } : {})}
                    fluid={isMobile}
                    color="vk"
                    onClick={() => this.updateInscription()}
                  >
                    {t("commons.save")}
                  </Button>
                )}
                {!this.inscriptionIsFinished && (
                  <AssignPlaceButton
                    inscriptionId={activeInscription?.inscription?.id}
                  />
                )}
              </div>
            )}
          </Segment>
        </Segment>
        <PriceDetail
          inscriptionDiscount={inscriptionDiscount}
          inscriptionPrice={inscriptionPrice}
          inscriptionAttributes={inscriptionAttributes}
          inscriptionExtra={inscriptionExtra}
          activeInscription={activeInscription}
          commision={activeInscription.price_comission}
          total={activeInscription.price}
          totalRefund={activeInscription.total_refund}
          defaultPrice={modalityConfig.priceDefault}
        />
        <Segment basic style={{ marginBottom: 25 }}>
          <Button floated="right" color="vk" onClick={history.goBack}>
            {t("commons.goBack")}
          </Button>
        </Segment>
      </Container>
    );
  }
}
