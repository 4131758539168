import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import moment from "moment";
import spanishMomentLocale from "moment/locale/es";
import englishMomentLocale from "moment/locale/en-gb";

import es from "./default.es";
import en from "./default.en";

const navigatorLanguage = navigator.language.includes("es") ? "es" : "en";

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: es
    },
    en: {
      translation: en
    }
  },

  // Default language to be set
  lng: navigatorLanguage,
  fallbackLng: "es",

  keySeparator: ".",

  interpolation: {
    escapeValue: false
  }
});

moment.locale(navigatorLanguage);

i18n.on("languageChanged", function(lng) {
  moment.updateLocale(
    lng,
    lng === "es" ? spanishMomentLocale : englishMomentLocale
  );
});

export default i18n;
