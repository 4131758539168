import React, { useState, useEffect } from "react";
import { Button, Modal, Grid, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import usersApi from "../../apis/users";
import { modalHandler } from "../../components/DModal";
import Form from "../../components/Form";

const CreateUserButton = props => {
  const [error, setError] = useState();
  const [userForm, setUserForm] = useState();
  const [t] = useTranslation();

  const formHandler = formData => {
    setError(undefined);
    setUserForm(formData);
  };

  const createUserHandler = async () => {
    if (userForm.ISFORMVALID) {
      const response = await usersApi.createUser({
        email: userForm.email,
        role_id: props.roleId,
        company_id: props.companyId,
        channel_id: 1
      });

      if (response.ok !== undefined && !response.ok) {
        setError(response.error.message);
        return;
      } else {
        await props.updateUsers()
        modalHandler.open(afterUserCreation);
      }
    }
  };

  useEffect(() => {
    modalHandler.refreshOptions({
      ...modalOptions
    });
  }, [error, userForm]);

  const afterUserCreation = {
    header: t(`usersScreen.modal.createdSuccess`),
    content: (
      <Modal.Description>
        {t(`usersScreen.modal.createdSuccessMessage`)}
      </Modal.Description>
    ),
    actions: (
      <Modal.Actions>
        <Button
          icon="check"
          color={"instagram"}
          content={t(`usersScreen.modal.createdSuccessButton`)}
          onClick={() => modalHandler.close()}
        />
      </Modal.Actions>
    )
  };

  const modalOptions = {
    header: t("companyDetail.addUser"),
    content: (
      <Modal.Description>
        <Grid padded>
          <Grid.Row>
            <Grid.Column width="16">
              <Form
                onFormChange={formHandler}
                fields={[
                  {
                    fields: [
                      {
                        name: "email",
                        placeHolder: t("companyDetail.email"),
                        type: "Input",
                        colSize: 16,
                        validation: {
                          required: true,
                          regexType: "email",
                          errorMessage: t("commons.INVALID_EMAIL")
                        },
                        props: {
                          icon: "at",
                          iconPosition: "left"
                        }
                      }
                    ]
                  }
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          {error && (
            <Grid.Row>
              <Grid.Column width="16">
                <Message
                  error
                  header={t(`companyDetail.messages.${error}.header`)}
                  content={t(`companyDetail.messages.${error}.message`)}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Modal.Description>
    ),
    actions: (
      <Grid>
        <Grid.Column width="16">
          <Button
            fluid
            disabled={userForm ? !userForm.ISFORMVALID : true}
            color="vk"
            onClick={createUserHandler}
          >
            {t("companyDetail.addUser")}
          </Button>
        </Grid.Column>
      </Grid>
    )
  };

  return (
    <Button color="vk" onClick={() => modalHandler.open(modalOptions)}>
      {t("companyDetail.addUser")}
    </Button>
  );
};

export default CreateUserButton;
