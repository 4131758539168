export const formFields = t => {
  return [
    {
      fields: [
        {
          name: "inscriptions",
          title: t("modalitySummary.info.inscriptions"),
          type: "Input",
          colSize: 5,
          props: {
            readOnly: true
          }
        },
        {
          name: "name",
          title: t("modalitySummary.info.name"),
          type: "Input",
          colSize: 5,
          props: {
            readOnly: true
          }
        },
        {
          name: "modalityDate",
          title: t("modalitySummary.info.modalityDate"),
          type: "Input",
          colSize: 5,
          props: {
            readOnly: true
          }
        },
        {
          name: "modalityHour",
          title: t("modalitySummary.info.modalityHour"),
          type: "Input",
          colSize: 5,
          props: {
            readOnly: true
          }
        }
      ]
    }
  ];
};

export const inscriptionsForm = t => {
  return [
    {
      fields: [
        {
          name: "inscriptions",
          title: t("modalitySummary.info.inscriptions"),
          type: "Input",
          colSize: 8,
          props: {
            readOnly: true
          }
        },
        {
          name: "name",
          title: t("modalitySummary.info.name"),
          type: "Input",
          colSize: 8,
          props: {
            readOnly: true
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "modalityDate",
          title: t("modalitySummary.info.modalityDate"),
          type: "Input",
          colSize: 8,
          props: {
            readOnly: true
          }
        },
        {
          name: "modalityHour",
          title: t("modalitySummary.info.modalityHour"),
          type: "Input",
          colSize: 8,
          props: {
            readOnly: true
          }
        }
      ]
    }
  ];
};

export const table = {
  headerCategorie: t => [
    {
      accessor: "name",
      content: t("modalitySummary.tables.name")
    }
  ],
  headerTeamType: t => [
    {
      accessor: "name",
      content: t("modalitySummary.tables.name")
    },
    {
      accessor: "minAthletes",
      content: t("modalitySummary.tables.minAthletes")
    },
    {
      accessor: "maxAthletes",
      content: t("modalitySummary.tables.maxAthletes")
    }
  ],
  headerAttribute: t => [
    {
      accessor: "name",
      content: t("modalitySummary.tables.name")
    },
    {
      accessor: "TIPO",
      content: t("modalitySummary.tables.type")
    },
    {
      accessor: "OBLIGATORIO",
      content: t("modalitySummary.tables.requared")
    }
  ],
  headerPrice: t => [
    {
      accessor: "name",
      content: t("modalitySummary.tables.name")
    },
    {
      accessor: "PRECIO",
      content: t("modalitySummary.tables.price")
    },
    {
      accessor: "DESDE",
      content: t("modalitySummary.tables.since")
    },
    {
      accessor: "HASTA",
      content: t("modalitySummary.tables.until")
    }
  ]
};
