import { clientApi } from ".";

export default {
  create: async (params) => {
    try {
      const response = await clientApi
        .getClient()
        .post("/races", { ...params, channel_id: 1 });
      return response.data;
    } catch (err) {}
  },
  copy: async (params) => {
    try {
      const response = await clientApi
        .getClient()
        .post("/races/copy", { ...params, channel_id: 1  });
      return response.data;
    } catch (err) {}
  },
  fetchRaces: async (params) => {
    try {
      const response = await clientApi.getClient().get("/races", { params: { ...params, channel_id: 1 },
      timeout: 30000 });
      return response.data;
    } catch (err) {}
  },
  fetchHome: async (params) => {
    try {
      const response = await clientApi
        .getClient()
        .get("/races/home", { params: { ...params, channel_id: 1 }});

      return response.data;
    } catch (err) {}
  },
  fetchRaceTypes: async () => {
    try {
      const response = await clientApi.getClient().get("/raceTypes", {params: {channel_id:1}});
      return response.data;
    } catch (error) {}
  },

  fetchRace: async (id) => {
    try {
      const response = await clientApi.getClient().get(`/race/${id}`, {params: {channel_id:1}});
      return response.data;
    } catch (error) {}
  },
  updateRace: async (params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/race/${params.id}`, { race: { ...params}, channel_id:1 });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchInscriptions: async (params) => {
    const { id } = { ...params };
    delete params.id;
    try {
      const response = await clientApi
        .getClient()
        .get(`/race/inscriptions/${id}`, { params: { ...params, channel_id: 1 } });
      return response.data;
    } catch (err) {}
  },
  fetchModalities: async ({ id }) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/race/modalities/${id}`,  {params:{channel_id: 1}});
      return response.data;
    } catch (err) {}
  },
  fetchDiscounts: async ({ id, params }) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/race/discounts/${id}`, { params :{ ...params, channel_id: 1 }});
      return response.data;
    } catch (err) {}
  },
  delete: async ({ id }) => {
    try {
      const response = await clientApi.getClient().delete(`/race/${id}`, { params: { channel_id: 1 }});
      return response.data;
    } catch (err) {}
  },
  fetchModalitiesStatus: async (slug) => {
    try {
      const response = await clientApi.getClient().get(`/race-s/${slug}`, {params:{channel_id: 1}});
      return response.data;
    } catch (err) {}
  },
  fetchClassifications: async ({ id }) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`race/classifications/${id}`, {params: {channel_id: 1}});
      return response.data;
    } catch (err) {}
  },

  uploadFile: async (params) => {
    try {
      const { raceId, modalityId, file, newMatches, make_map, name } = {
        ...params,
      };
      const data = new FormData();
      data.append("files", file);
      data.append("newMatches", JSON.stringify(newMatches));
      data.append("make_map", make_map);
      data.append("name", name);
      data.append("channel_id", 1);
      const response = await clientApi
        .getClient()
        .post(`/race/${raceId}/classifications/${modalityId}`, data, {
          "Content-Type": "multipart/form-data",
        });
      return response.data;
    } catch (err) {
      return err;
    }
  },
  deleteClassification: async (params) => {
    try {
      const { raceId, modalityId } = { ...params };
      const response = await clientApi
        .getClient()
        .delete(`/race/${raceId}/classifications/${modalityId}`, { params : {...params, channel_id: 1} });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  fetchInscriptionsCsv: async (raceId, params) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/race/inscriptions/csv/${raceId}`, { params : {...params, channel_id: 1} , timeout: 120000});
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  getAccountData: async (raceId) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/race/${raceId}/account`, { params: {channel_id: 1 }, timeout: 60000});
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  getPdfAccountData: async (raceId) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/race/${raceId}/pdf_account`, {params: {channel_id:1},
          headers: {
            "Content-Type": "application/pdf",
          },
          responseType: "blob",
          timeout: 30000,
        });

      var url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.download = `Accounts-${raceId}.pdf`;
      link.setAttribute("download", `Accounts-${raceId}`);
      link.click();
      link.remove();

      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  accountsCsv: async (raceId) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/race/accounts/csv/${raceId}`, {params : {channel_id: 1 }} );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  getWidget: async (id) => {
    try {
      const response = await clientApi.getClient().get(`/race/${id}/getWidget`, {params: {channel_id: 1}});
      return response.data;
    } catch (error) {}
  },
  fetchRaceDocs: async (id,params) => {
    try {
      const response = await clientApi.getClient().get(`/race/${id}/docs`, {params: {...params, channel_id: 1}});
      return response.data;
    } catch (error) {}
  },
  createRaceDoc: async (id, formData) => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/race/${id}/docs`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      return response.data;
    } catch (error) {}
  },
  downloadRaceDoc: async (raceId, id) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/race/${raceId}/docs/${id}`, {
          responseType: "blob",
          params: {channel_id: 1},
        });
      return response.data;
    } catch (error) {}
  },
  deleteRaceDoc: async (raceId, id) => {
    try {
      const response = await clientApi
        .getClient()
        .delete(`/race/${raceId}/docs/${id}`, {params: {channel_id: 1}});
      return response.data;
    } catch (error) {}
  },
  updateRaceDoc: async (raceId, id, raceDoc) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/race/${raceId}/docs/${id}`, {...raceDoc, channel_id: 1 });
      return response.data;
    } catch (error) {}
  },
  resetQr: async (raceId) =>{
    try {
      const response = await clientApi
        .getClient()
        .post(`/race/${raceId}/resetqr`,{
          headers: {
            "Content-Type": "application/pdf"
          },
          timeout: 15000,
          channel_id: 1
        });
      return response;
    } catch (error) {
    }
  }
};
