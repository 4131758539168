import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import decode from "jwt-decode";
import {
  Button,
  Icon,
  Menu,
  Dropdown,
  Segment,
  Sidebar,
  Header,
  Grid,
  Image,
  Responsive,
  Flag,
  Label,
} from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import Logo from "../../../public/images/logo-ged-web.jpg";
import MenuItemsData from "./menuitem.json";
import { useHistory } from "react-router";
import PreferencesModal from "../preferencesModal/preferencesModal";
import { modalHandler } from "../../components/DModal";
import notificationsApi from "../../apis/notifications";

////////////////////Styles:
const styles = {
  //General
  HorizontalCentering: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  VerticalCentering: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  HorizontalStart: {
    display: "flex",
    justifyContent: "flex-start",
  },
  HorizontalEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },

  //HeaderMenu:
  HeaderMenuWrapper: {
    height: "10%",
    backgroundColor: "white",
    margin: 0,
    paddingBottom: "0.5vh",
    borderBottom: "1px solid #DCDCDC",
    marginBottom: "1%",
  },

  //MenuIcon:
  MenuIconWrapperButton: {
    backgroundColor: "#261E45",
    width: "100%",
    borderRadius: 0,
    padding: "7% 0 7% 0",
  },
  MenuIconLabel: {
    backgroundColor: "transparent",
    color: "#F5F5F5",
  },

  //Vertical sidebar:
  Sidebar: {
    backgroundColor: "#110833",
    overflowX: "hidden",
  },
  SidebarTitle: {
    padding: "15%",
    margin: 0,
    color: "#F5F5F5",
  },
  CloseSidebarButton: {
    backgroundColor: "transparent",
    margin: "15% 15% 35% 15%",
  },
};

export const openPreferences = (t) => {
  modalHandler.open({
    header: t("navBar.preferences"),
    size: "tiny",
    content: <PreferencesModal t={t} />,
    actions: <div></div>,
  });
};

const OptionButton = ({ icon, text }) => {
  return (
    <Button
      size="mini"
      style={{ ...styles.HorizontalCentering, backgroundColor: "transparent" }}
    >
      <Icon name={icon} />
      {text}
    </Button>
  );
};

OptionButton.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
};

const HeaderMenu = ({ handleAnimationChange }) => {
  let history = useHistory();
  const [_, __, removeCookie] = useCookies(["token"]);

  const decodedToken = decode(_.token.token);
  const { email, role, companyName } = decodedToken;
  const [t, i18n] = useTranslation();
  let translatedRole;

  if (role === "ADMINISTRATOR") {
    // translatedRole = t("roles.ADMINISTRATOR");
  } else if (role === "COMPANY") {
    translatedRole = companyName;
  }

  const miniAvatar = (
    <>
      <Label>
        {email} {translatedRole ? `(${translatedRole})` : null}
      </Label>
      <Image
        src={"https://semantic-ui.com/images/avatar/small/elliot.jpg"}
        avatar
        style={{ objectFit: "contain" }}
        verticalAlign="middle"
      />
    </>
  );

  const logoutHandler = () => {
    removeCookie("token");
    history.replace("/");
  };

  return (
    <div style={styles.HeaderMenuWrapper}>
      <Grid container columns={3} relaxed="very">
        <Grid.Column
          floated="left"
          verticalAlign="middle"
          style={{
            paddingLeft: window.innerWidth <= 768 ? 0 : "2.5rem",
          }}
        >
          <Button.Group size="small">
            <Responsive maxWidth={1500}>
              <Button
                id="navBar"
                icon
                style={{
                  backgroundColor: "transparent",
                  color: "#110833",
                  marginRight: 10,
                }}
                onClick={handleAnimationChange("push")}
              >
                <Icon name="content" size="large" />
              </Button>
            </Responsive>
            <Button.Group basic style={{ marginTop: 1 }}>
              <Button
                icon
                onClick={() => i18n.changeLanguage("es")}
                style={styles.HorizontalCentering}
              >
                <Flag name="es" style={{ margin: 1 }} />
              </Button>
              <Button
                icon
                onClick={() => i18n.changeLanguage("en")}
                style={styles.HorizontalCentering}
              >
                <Flag name="gb" style={{ margin: 1 }} />
              </Button>
            </Button.Group>
          </Button.Group>
        </Grid.Column>

        <Grid.Column
          verticalAlign="middle"
          style={{
            width: window.innerWidth < 500 ? 0 : 100 / 3 + "%",
            padding: window.innerWidth < 500 ? 0 : "1rem 2.5rem 1rem 2.5rem",
          }}
        >
          <Responsive minWidth={850}>
            <div style={styles.VerticalCentering}>
              <Image src={Logo} size="small" />
            </div>
          </Responsive>
        </Grid.Column>

        <Grid.Column
          verticalAlign="middle"
          style={{
            paddingRight: window.innerWidth <= 768 ? 0 : "2.5rem",
          }}
        >
          <Dropdown
            trigger={miniAvatar}
            openOnFocus
            icon={null}
            direction="left"
            pointing="top left"
            style={{ ...styles.HorizontalEnd, width: "100%" }}
          >
            <Dropdown.Menu>
              {/* <Dropdown.Item>
                <OptionButton icon="help circle" text={t("navBar.help")} />
              </Dropdown.Item> */}
              <Dropdown.Item onClick={() => openPreferences(t)}>
                <OptionButton icon="user" text={t("navBar.preferences")} />
              </Dropdown.Item>
              <Dropdown.Item onClick={logoutHandler}>
                <OptionButton icon="log out" text={t("navBar.logout")} />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Grid.Column>
      </Grid>
    </div>
  );
};

HeaderMenu.propTypes = {
  handleAnimationChange: PropTypes.func,
};

const MenuIcon = ({ id, url, icon, text, closeMenu }) => {
  let history = useHistory();

  return (
    <div>
      <Button
        id={id}
        icon
        toggle
        active={history.location.pathname === url}
        style={{ ...styles.MenuIconWrapperButton }}
        labelPosition="left"
        floated="left"
        onClick={() => {
          if (window.innerWidth < 1500) {
            closeMenu();
          }
          history.push(url);
        }}
      >
        <Icon name={icon} style={{ color: "#F5F5F5", padding: "0 5% 0 5%" }} />
        <Label
          as="a"
          horizontal
          size="large"
          style={{
            ...styles.MenuIconLabel,
            ...styles.HorizontalStart,
          }}
        >
          {text}
        </Label>
      </Button>
    </div>
  );
};

MenuIcon.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  closeMenu: PropTypes.func,
};

const VerticalSidebar = ({ animation, visible, handleAnimationChange,notificationsMenu }) => {
  const [t] = useTranslation();
  const [cookies] = useCookies(["token"]);

  let role = null;
  role = decode(cookies?.token?.token).role;

  return (
    <Sidebar
      id="Sidebar"
      as={Menu}
      animation={animation}
      icon="labeled"
      vertical
      visible={visible}
      style={{ ...styles.Sidebar }}
    >
      <Responsive minWidth={1500}>
        <Header as="h2" style={styles.SidebarTitle}>
          GedSports
        </Header>
      </Responsive>
      <Responsive maxWidth={1500}>
        <Button
          icon
          style={styles.CloseSidebarButton}
          onClick={handleAnimationChange("push")}
        >
          <Icon
            name="arrow left"
            size="big"
            style={{ color: "#F5F5F5" }}
          ></Icon>
        </Button>
      </Responsive>

      {MenuItemsData.map((data, key) => {
        if (data.roles.includes(role)) {
          if(data.id === 'SidebarNotifications' && !notificationsMenu && role === "COMPANY")
          {
            // dont attach notifications menu
          }
          else
          {
            return (
              <div key={key}>
                <MenuIcon
                  id={data.id}
                  url={data.url}
                  icon={data.icon}
                  text={t(data.text)}
                  closeMenu={handleAnimationChange("push")}
                />
              </div>
            );
          }
        }
      })}
    </Sidebar>
  );
};

VerticalSidebar.propTypes = {
  animation: PropTypes.string,
  visible: PropTypes.bool,
};

const NavBar = (props) => {
  const {notifications} = props
  const [animation, setAnimation] = useState("push");
  const [cookies] = useCookies(["token"]);
  const [_, i18n] = useTranslation();
  const [visible, setVisible] = useState(
    window.innerWidth > 1500 ? true : false
  );

  //ComponentDidMount equivalent
  useEffect(() => {
    cookies?.token?.language
      ? i18n.changeLanguage(cookies?.token?.language)
      : null;
    window.addEventListener("resize", handleResize);
    //close nav bar when click in body
    document.addEventListener("click", (e) => {
      const path = e.path || (e.composedPath && e.composedPath());

      if (path[1].id != "navBar" && window.innerWidth < 1500) {
        setVisible(false);
      }
    });
  }, []);

  //ComponentWillUnmount equivalent
  useEffect(() => {
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = (e) => {
    if (window.innerWidth < 1500) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  const handleAnimationChange = (animation) => () => {
    setAnimation(animation);
    setVisible(!visible);
  };

  return (
    <>
      <VerticalSidebar
        animation={animation}
        visible={visible}
        handleAnimationChange={handleAnimationChange}
        notificationsMenu={notifications}
      />
      <Sidebar.Pushable style={{ height: "100%" }}>
        <Sidebar.Pusher>
          <Segment basic>
            <HeaderMenu handleAnimationChange={handleAnimationChange} />

            <div>{props.children}</div>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
};

export default NavBar;
