export default t => [
  {
    fields: [
      {
        name: "currentPassword",
        title: t("commons.actualPassword"),
        placeHolder: t("commons.actualPassword"),
        colSize: "16",
        type: "Input",
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired")
        },
        props: {
          type: "password"
        }
      }
    ]
  },
  {
    fields: [
      {
        name: "password",
        title: t("recoverPassword.newPassword"),
        placeHolder: t("recoverPassword.newPassword"),
        colSize: "8",
        type: "Input",
        validation: {
          required: true,
          regexType: "password",
          errorMessage: t("recoverPassword.invalidPassword")
        },
        props: {
          type: "password"
        }
      },
      {
        name: "repeatPassword",
        title: t("recoverPassword.rNewPassword"),
        placeHolder: t("recoverPassword.rNewPassword"),
        colSize: "8",
        type: "Input",
        validation: {
          custom: ({ repeatPassword, password }) =>
            repeatPassword === password,
          errorMessage: t("register.passwordsDontMatch")
        },
        props: {
          type: "password"
        }
      }
    ]
  }
];
