//@ts-check
import React, { Component } from "react";
import {
  Loader,
  Dimmer,
  Message,
  Icon,
  Button,
  Grid,
  Flag,
  Divider,
  Segment,
  Container,
  Header,
  Label,
  Image,
  Modal
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import Form from "../../components/Form";
const { PUBLIC_WEB_URL } = process.env;
import formFields from "./formFields";
import bgImage from "../../../public/images/loginBg.jpg";
import authApi from "../../apis/auth";

@withTranslation()
export default class RecoverPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isValid: true,
      formData: {},
      error: false,
      passwordChanged: false,
      userChangedRole: null,
      tokenErrorCode: ""
    };

    this.changePasswordHandler = this.changePasswordHandler.bind(this);
  }

  componentDidMount() {
    this.checkToken();
  }

  async checkToken() {
    const { match } = this.props;
    const { token } = match.params;

    const result = await authApi.checkToken(token);

    if (result.error) {
      this.setState({
        tokenErrorCode: result.error.message
      });
    }

    return result;
  }

  async changePasswordHandler() {
    const { formData } = this.state;
    const { match } = this.props;
    const token = match.params.token;

    if (formData.ISFORMVALID) {
      if (formData.password === formData.repeatPassword) {
        this.setState({ error: false, isLoading: true });

        try {
          const userChanged = await authApi.resetPassword({
            token,
            password: formData.password
          });
          this.setState({
            isLoading: false,
            passwordChanged: true,
            userChangedRole: userChanged.role_id
          });
        } catch (err) {
          this.setState({
            isLoading: false,
            isValid: false
          });
        }
      } else {
        this.setState({ error: true });
      }
    }
  }

  render() {
    const { t, i18n, history } = this.props;
    const {
      isLoading,
      isValid,
      error,
      passwordChanged,
      userChangedRole,
      tokenErrorCode
    } = this.state;

    if (tokenErrorCode) {
      return (
        <Container style={{ margin: "3%" }}>
          <Grid.Column width={10} textAlign={"left"}>
            <Message icon error>
              <Icon name="ban" />
              <Message.Content>
                <Message.Header>
                  {t(`register.tokenErrors.errorInToken`)}
                </Message.Header>
                {t(`register.tokenErrors.${tokenErrorCode}`)}
                <Divider hidden fitted />
                <Button
                  floated="right"
                  color="vk"
                  onClick={() => history.push("/")}
                >
                  {t(`recoverPassword.goToLogin`)}
                </Button>
              </Message.Content>
            </Message>
          </Grid.Column>
        </Container>
      );
    }

    let loadingChildren = (
      <Grid.Column width={10} textAlign={"left"}>
        <Segment>
          <Message
            warning
            style={{ whiteSpace: "pre-line" }}
            header={t("recoverPassword.title")}
            content={t("recoverPassword.validFormat")}
          />
          <Form
            id="formReset"
            style={{ padding: "10px 0px 10px" }}
            fields={formFields(t)}
            onFormChange={data => {
              this.setState({ formData: data });
            }}
          />
          {error && (
            <Message
              error
              style={{ whiteSpace: "pre-line" }}
              content={t("recoverPassword.NOT_SAME")}
            />
          )}
          <Button
            color="vk"
            fluid
            size="large"
            onClick={this.changePasswordHandler}
          >
            {t("commons.send")}
          </Button>
        </Segment>
      </Grid.Column>
    );

    if (isLoading) {
      loadingChildren = (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
    }

    if (!isValid && !isLoading) {
      loadingChildren = (
        <Grid.Column width={10} textAlign={"left"}>
          <Message icon error>
            <Icon name="ban" />
            <Message.Content>
              <Message.Header>{t(`recoverPassword.header`)}</Message.Header>
              {t(`recoverPassword.message`)}
              <Divider hidden fitted />
              <Button
                floated="right"
                color="vk"
                onClick={() => history.replace("/")}
              >
                {t(`recoverPassword.goToLogin`)}
              </Button>
            </Message.Content>
          </Message>
        </Grid.Column>
      );
    }

    if (passwordChanged && !isLoading) {
      loadingChildren = (
        <Grid.Column width={10} textAlign={"left"}>
          <Message icon success>
            <Icon name="check" />
            <Message.Content>
              <Message.Header>
                {t(`recoverPassword.changedTitle`)}
              </Message.Header>
              {t(`recoverPassword.changedMessage`)}
              <Divider hidden fitted />
              <Button
                floated="right"
                color="vk"
                onClick={
                  userChangedRole === 5
                    ? () => window.open(PUBLIC_WEB_URL)
                    : () => history.replace("/")
                }
              >
                {userChangedRole === 5
                  ? t("recoverPassword.goToPublicWeb")
                  : t(`recoverPassword.goToLogin`)}
              </Button>
            </Message.Content>
          </Message>
        </Grid.Column>
      );
    }

    return (
      <div style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        >
          <Grid
            textAlign="center"
            style={{
              height: "100%",
              width: "100%",
              filter: "blur(0px)"
            }}
            verticalAlign="middle"
          >
            {!isLoading && (
              <Grid.Row style={{ height: "5%" }}>
                <Button.Group
                  style={{ position: "absolute", top: 5, right: 5 }}
                  toggle
                >
                  <Button icon onClick={() => i18n.changeLanguage("es")}>
                    <Flag name="es" />
                  </Button>
                  <Button icon onClick={() => i18n.changeLanguage("en")}>
                    <Flag name="gb" />
                  </Button>
                </Button.Group>
              </Grid.Row>
            )}
            <Grid.Row style={{ height: isLoading ? "100%" : "95%" }}>
              {loadingChildren}
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}
