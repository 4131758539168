import { ageTypes, genders } from "../../../util/usersUtil";
import { dateIsBetween, getDatesErrorMessage } from "../../../util/util";

export const formField = (t, disable) => [
  {
    fields: [
      {
        name: "allowInscriptions",
        title: t("modalityConfiguration.enableInscriptions"),
        type: "RadioButtons",
        colSize: 3,
        deflt: "ALLOW",
        radios: [
          {
            label: t("commons.yes"),
            value: "ALLOW"
          },
          {
            label: t("commons.no"),
            value: "NOT_ALLOW"
          }
        ],
        props: {
          direction: "horizontal",
          disabled: disable,
          tabIndex: 1
        }
      }
    ]
  }
];

export const formFieldsLeft = (t, config, modality, disable) => [
  {
    fields: [
      {
        name: "startDate",
        title: t("modalityConfiguration.startDate"),
        type: "DatePicker",
        colSize: 8,
        validation: {
          required: config.allowInscriptions,
          custom: vals => {
            if (config.allowInscriptions) {
              const startRaceDate = moment(modality.start_date);
              const dateStartModality = moment(vals.startDate);
              const valid = startRaceDate > dateStartModality;
              return {
                valid: vals.startDate ? valid : false,
                errorMessage: t("racesScreen.startDateLowerThanRaceDate", {
                  startDate: moment(modality.start_date).format("LL")
                })
              };
            } else {
              return true;
            }
          }
        }
        ,props:{
          id:'startDate'
        }
      },
      {
        name: "timeStart",
        title: t("dateTimePicker.hour"),
        colSize: 5,
        type: "Input",
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired")
        },
        props:{
          type: "time",
          id:'startTime',
          step: 15*60
        }
      },
      {
        name: "maxInscriptions",
        title: t("modalityConfiguration.maxModalityInscriptions"),
        type: "Input",
        colSize: 6,
        validation: {
          custom: vals =>
            vals.maxInscriptions
              ? !isNaN(parseInt(vals.maxInscriptions))
              : true,
          errorMessage: t("commons.mustBeNumber"),
          regexType: "number"
        },
        props: {
          disabled: disable,
          tabIndex: 3
        }
      }
    ]
  },
  {
    fields: [
      {
        name: "endDate",
        title: t("modalityConfiguration.finishDate"),
        type: "DatePicker",
        colSize: 8,
        validation: {
          required: config.startDate ? true : false,
          custom: vals => {
            if (config.startDate ? true : false) {
              var patt = new RegExp("Invalid Date");
              if (patt.test(config.startDate)) {
                return {
                  valid: false,
                  errorMessage: t("raceEdit.fieldEmpty", {
                    field: t("modalityConfiguration.startDate")
                  })
                };
              } else {
                const startDate = moment(config.startDate);
                const finishDate = moment(modality.start_date);
                const valid = dateIsBetween(
                  startDate,
                  finishDate,
                  vals.endDate
                );
                return {
                  valid: config.startDate ? valid : false,
                  errorMessage: getDatesErrorMessage(
                    t,
                    startDate,
                    finishDate,
                    "LL"
                  )
                };
              }
            } else {
              return true;
            }
          }
        },
        props: {
          id: 'endDate'
        }
      },
      {
        name: "timeEnd",
        title: t("dateTimePicker.hour"),
        colSize: 5,
        type: "Input",
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired")
        },
        props:{
          type: "time",
          id: 'endTime',
          step: 15*60
        }
      },
      config.maxInscriptions
        ? {
            name: "limitWebInscriptions",
            title: t("modalityConfiguration.maxWebInscriptions"),
            type: "Input",
            colSize: 6,
            validation: {
              custom: vals =>
                vals.limitWebInscriptions
                  ? !isNaN(parseInt(vals.limitWebInscriptions))
                  : true,
              errorMessage: t("commons.mustBeNumber"),
              regexType: "number"
            },
            props: {
              disabled: disable,
              tabIndex: 3
            }
          }
        : {
            name: "limitWebInscriptions",
            title: t("modalityConfiguration.maxWebInscriptions"),
            type: "Input",
            colSize: 6,
            props: {
              disabled: true,
              tabIndex: 3,
              value: ""
            }
          }
    ]
  },
  {
    fields: [
      {
        name: "priceDefault",
        title: t("modalityConfiguration.defaultPrice"),
        type: "Input",
        colSize: 8,
        validation: {
          custom: vals =>
            vals.priceDefault && typeof vals.priceDefault === "string"
              ? !isNaN(parseFloat(vals.priceDefault.replace(",", ".")))
              : true,
          errorMessage: t("commons.INVALID_DOUBLE"),
          regexType: "double"
        },
        props: {
          disabled: disable,
          tabIndex: 10
        }
      }
    ]
  },
  {
    fields: [
      {
        name: "selectClub",
        title: t("modalityConfiguration.selectClub"),
        type: "Checkbox",
        colSize: 8,
        props: {
          disabled: disable,
          tabIndex: 11
        }
      }
    ]
  }
];

export const formFieldsRight = (t, disable) => [
  {
    fields: (state) => [
      {
        name: "minimumAgeType",
        title: t("modalityConfiguration.minAgeType"),
        type: "Select",
        data: ageTypes(t),
        colSize: 8,
        props: {
          disabled: disable,
          tabIndex: 4
        }
      },
      {
        name: "minimumAge",
        title: t("modalityConfiguration.minAge"),
        type: "Input",
        colSize: 8,
        validation: {
          custom: vals =>
            vals.minimumAge ? !isNaN(parseInt(vals.minimumAge)) : true,
          errorMessage: t("commons.mustBeNumber"),
          regexType: "number"
        },
        props: {
          disabled: disable || !state.minimumAgeType,
          tabIndex: 6
        }
      }
    ]
  },
  {
    fields: (state) => [
      {
        name: "maximunAgeType",
        title: t("modalityConfiguration.maxAgeType"),
        type: "Select",
        data: ageTypes(t),
        colSize: 8,
        props: {
          disabled: disable,
          tabIndex: 15
        }
      },
      {
        name: "maximumAge",
        title: t("modalityConfiguration.maxAge"),
        type: "Input",
        colSize: 8,
        validation: {
          custom: vals =>
            vals.maximumAge ? !isNaN(parseInt(vals.maximumAge)) : true,
          errorMessage: t("commons.mustBeNumber"),
          regexType: "number"
        },
        props: {
          disabled: disable || !state.maximunAgeType,
          tabIndex: 20
        }
      }
    ]
  },
  {
    fields: [
      {
        name: "ageCheckDate",
        title: t("modalityConfiguration.ageOnDate"),
        type: "DatePicker",
        colSize: 10,
        props: {
          disabled: disable
          // tabIndex: 24
        }
      }
    ]
  },
  {
    fields: [
      {
        name: "gender",
        title: t("modalityConfiguration.permittedGenders"),
        type: "RadioButtons",
        colSize: 16,
        deflt: "BOTH",
        radios: genders(t).map(v => ({ ...v, label: v.text })),
        props: {
          direction: "horizontal",
          disabled: disable,
          tabIndex: 25
        }
      }
    ]
  }
];

export const formAttrFields = (t, disable) => [
  {
    fields: [
      {
        name: "mobile",
        title: t("modalityConfiguration.mobilePhone"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 30
        }
      },
      {
        name: "emergencyMobile",
        title: t("modalityConfiguration.emergencyNumber"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 35
        }
      },
      {
        name: "phonePrefix",
        title: t("modalityConfiguration.phonePrefix"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 40
        }
      },
      {
        name: "confirmEmail",
        title: t("modalityConfiguration.confirmEmail"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 45
        }
      }
    ]
  },
  {
    fields: [
      {
        name: "originCountry",
        title: t("modalityConfiguration.country"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 50
        }
      },
      {
        name: "location1",
        title: t("modalityConfiguration.localizationOne"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 55
        }
      },
      {
        name: "location2",
        title: t("modalityConfiguration.localizationTwo"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 60
        }
      },
      {
        name: "location3",
        title: t("modalityConfiguration.localityThree"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 65
        }
      }
    ]
  },
  {
    fields: [
      {
        name: "address",
        title: t("modalityConfiguration.address"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 70
        }
      },
      {
        name: "zipCode",
        title: t("modalityConfiguration.zipCode"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 75
        }
      },
      {
        name: "parentalLeave",
        title: t("modalityConfiguration.parentsPermission"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 80
        }
      },
      {
        name: "localParticipant",
        title: t("modalityConfiguration.localParticipant"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 85
        }
      }
    ]
  },
  {
    fields: [
      {
        name: "federatedParticipant",
        title: t("modalityConfiguration.federated"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 90
        }
      },
      // {
      //   name: "selectExit",
      //   title: t("modalityConfiguration.selectExit"),
      //   type: "Checkbox",
      //   colSize: 4
      // },
      {
        name: "photo",
        title: t("modalityConfiguration.photo"),
        type: "Checkbox",
        colSize: 4,
        props: {
          disabled: disable,
          tabIndex: 95
        }
      }
    ]
  }
];
