import { clientApi } from ".";

export default {
  fetchCountryCodes: async () => {
    try {
      const response = await clientApi.getClient().get("/countries", {params: {channel_id: 1}});
      return response.data;
    } catch (err) {}
  }
};
