import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";

import CreateUserButton from "./CreateUserButton";
import FilterableTable from "../../components/FilterableTable";

import usersApi from "../../apis/users";
import rolesApi from "../../apis/roles";
import { getFilterFields, getTableHeaders } from "./util";
import { status as usersStatus } from "../../util/usersUtil";

@connect((state) => ({
  rol: state.user.role,
}))
@withTranslation()
export default class UsersScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      totalData: 0,
      roles: [],
      loading: false,
    };

    this.getData = this.getData.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getData({channel_id: 1});
    this.setState({ loading: false });
    this.getRoles();
  }

  getRoles = async () => {
    const response = await rolesApi.fetchRoles();
    const roles = response.map((role) => ({
      text: role.role,
      value: role.id,
    }));

    this.setState({ roles });
  };

  userRoles = () => {
    const { t } = this.props;

    return this.state.roles
      .map((role) => ({
        text: t(`roles.${role.text}`),
        value: role.value,
      }));
  };

  async getData(params) {
    if (params === undefined) {
      params = {channel_id : 1}
    } else {
      params["channel_id"] = 1
    }

    const response = await usersApi.fetchUsers(params);

    this.setState({ data: response.rows, totalData: response.count });
  }

  render() {
    const { t } = this.props;
    const { data, totalData, loading } = this.state;
    const loggedRole = this.props.role || "";

    const filterFields = getFilterFields(
      t,
      this.userRoles(),
      usersStatus(t),
      loggedRole
    );
    const tableHeaders = getTableHeaders(t, loggedRole);

    return (
      <Container>
        <FilterableTable
          id={"UsersTable"}
          filterFields={filterFields}
          tableHeaders={tableHeaders}
          contentData={data}
          parseData={(data) => ({
            createdAt: moment(data.createdAt).format("L"),
            role: t(`roles.${data.role}`),
            status: t(`status.${data.status}`),
          })}
          onRowClick={(data) => this.props.history.push(`/user/${data.id}`)}
          totalData={totalData}
          onChange={(table, filters) => this.getData({ ...table, ...filters })}
          loading={loading}
        />
        <CreateUserButton updateUsers={this.getData} roles={this.state.roles} />
      </Container>
    );
  }
}
