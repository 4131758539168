import React, { Component } from 'react'
import {
  Segment,
  Container,
  Button,
  Message,
  Icon,
  List,
  Grid,
  Modal,
  Checkbox,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import { withTranslation } from "react-i18next";
import plannersApi from "../../../apis/planners";
import pointOfSaleApi from "../../../apis/pointOfSales";
import qrReadersApi from "../../../apis/qrReaders";
import rolesApi from  "../../../apis/roles";
import { modalHandler } from "../../../components/DModal";
import Form from "../../../components/Form";
import { DAlert } from "../../../components/Dalert";
import usersApi from "../../../apis/users";
import { connect } from "react-redux";
import { times } from 'lodash';
import qrReaders from '../../../apis/qrReaders';


@connect(state => ({
  race: state.races.actualRace,
  rol: state.user.role
}))
@withTranslation()
export default class RaceUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      planners: [],
      PointOfSale: [],
      qrReaders: [],
      valid: false,
      email: '',
      t: this.props.t,
      loading: true,
      loaderText: '',
      roles:[]
    };
  }

  componentDidMount() {
    this.fetchPlanners()
    this.fetchPointOfSale()
    this.fetchQrReaders()
    this.fetchRoles();
    this.setState({ loading: false })
  }

  fetchPlanners() {
    plannersApi.fetchPlannersInRace(this.props.id).then(async planners => {
      await this.setState({ planners, loading: false })
    })
  }

  fetchPointOfSale() {
    pointOfSaleApi.fetchPointOfSaleInRace(this.props.id).then(PointOfSale => {
      this.setState({ PointOfSale, loading: false })
    })
  }
  fetchQrReaders() {
    qrReadersApi.fetchQrReadersInRace(this.props.id).then(qrReaders => {
      this.setState({ qrReaders, loading: false })
    })
  }
  fetchRoles() {
    rolesApi.fetchRoles().then(roles => {
      this.setState({roles, loading: false})
    })
  }

  formHandler = async formData => {
    if (formData.ISFORMVALID) {
      await this.setState({
        email: formData.email,
        valid: formData.ISFORMVALID
      })
    }
  };

  checkboxPlannerClicked = async (planner, t) => {
    this.setState({ loading: true })

    await plannersApi.updateEnabled({
      planner_id: planner.id,
      race_id: parseInt(this.props.id),
      disable: !planner.Planner_race_xref.disable
    }).then(result => {
      if (!result.disable) {
        DAlert.success({ title: t("raceUsers.enable") });
        this.fetchPlanners()
      } else {
        DAlert.success({ title: t("raceUsers.disable") });
        this.fetchPlanners()
      }
      this.setState({ loading: false })
    })

  }

  checkboxPointOfSaleClicked = async (PointOfSale, t) => {
    this.setState({ loading: true })
    await pointOfSaleApi.updateEnabled({
      point_of_sale_id: PointOfSale.id,
      race_id: parseInt(this.props.id),
      disable: !PointOfSale.PointOfSale_race_xref.disable
    }).then(result => {
      if (!result.disable) {
        DAlert.success({ title: t("raceUsers.enable") });
        this.fetchPointOfSale()
      } else {
        DAlert.success({ title: t("raceUsers.disable") });
        this.fetchPointOfSale()
      }
      this.setState({ loading: false })
    })

  }


  checkboxQrReaderClicked = async (QrReader, t) => {
    this.setState({ loading: true })
    await qrReaders.updateEnabled({
      qr_reader_id: QrReader.id,
      race_id: parseInt(this.props.id),
      disable: !QrReader.QrReader_race_xref.disable
    }).then(result => {
      if (!result.disable) {
        DAlert.success({ title: t("raceUsers.enable") });
        this.fetchQrReaders()
      } else {
        DAlert.success({ title: t("raceUsers.disable") });
        this.fetchQrReaders()
      }
      this.setState({ loading: false })
    })

  }

  handlerCreate(t, type) {
    switch (type) {
      case 'planner':
        usersApi.createUser(
          {
            email: this.state.email,
            role_id: this.getRoleByName('PLANNER') //3
          }
        ).then(response => {
          if (response.ok !== undefined && !response.ok) {
            this.setState({ loading: false })
            DAlert.error({ title: t("commons.errorSaving") });
          } else {
            plannersApi.linkPlannerToRace({
              plannerEmail: this.state.email,
              raceId: parseInt(this.props.id)
            }).then(res => {
              DAlert.success({ title: t("commons.savedSuccess") });
              this.fetchPlanners()
              this.setState({ valid: false, email: '' })
            })

          }

        })
        break;
      case 'sale':
        usersApi.createUser(
          {
            email: this.state.email,
            role_id: this.getRoleByName('POINT_OF_SALE') //4
          }
        ).then(response => {
          if (response.ok !== undefined && !response.ok) {
            this.setState({ loading: false })
            DAlert.error({ title: t("commons.errorSaving") });
          } else {

            pointOfSaleApi.linkPlannerToRace({
              PointOfSaleEmail: this.state.email,
              raceId: parseInt(this.props.id)
            }).then(res => {
              DAlert.success({ title: t("commons.savedSuccess") });
              this.fetchPointOfSale()
              this.setState({ valid: false, email: '' })
            })

          }

        })
        break;
        case 'qrReader':
          usersApi.createUser(
            {
              email: this.state.email,
              role_id: this.getRoleByName('QR_READER') //7 en DEV
            }
          ).then(response => {
            if (response.ok !== undefined && !response.ok) {
              this.setState({ loading: false })
              DAlert.error({ title: t("commons.errorSaving") });
            } else {
              qrReadersApi.linkQrReaderToRace({
                qrReaderEmail: this.state.email,
                raceId: parseInt(this.props.id)
              }).then(res => {
                DAlert.success({ title: t("commons.savedSuccess") });
                this.fetchQrReaders()
                this.setState({ valid: false, email: '' })
              })
  
            }
  
          })
          break;
      default:
        this.setState({ loading: false })
        DAlert.error({ title: t("commons.errorSaving") });
    }
  }
  getRoleByName(name) {
    const role = this.state.roles.filter(r => r.role == name);
    return role[0].id;
  }

  modalCreate(t, type) {
    try {
      modalHandler.open({
        header: type === 'planner' ? t("raceUsers.add.tittlePlanner") : (type === 'sale' ? t("raceUsers.add.tittlePOS") : t("raceUsers.add.tittleqrReader")) ,
        size: "long",
        content: <Modal.Description>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width="16">
                {type === 'planner' ? t("raceUsers.add.userPlanner") : (type === 'sale' ? t("raceUsers.add.userSale") : t("raceUsers.add.userQR"))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>,
        actions: (
          <Grid>
            <Grid.Column width="10" >
            </Grid.Column>
            <Grid.Column width="6" >
              <Grid>
                <Grid.Column width="8" >
                  <Button
                    fluid
                    color="vk"
                    onClick={() => {
                      this.handlerCreate(t, type)
                      modalHandler.close();
                      this.setState({ loading: true })
                    }}
                  >
                    {t("commons.ok")}
                  </Button>
                </Grid.Column>
                <Grid.Column width="8" >
                  <Button
                    fluid
                    color="red"
                    onClick={() => {
                      modalHandler.close();
                      this.setState({ valid: false, email: '', loading: false })
                    }}
                  >{t("commons.cancel")}
                  </Button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        )
      });
    }
    catch (e) {}
  }

  handler = async (t, type) => {

    if (this.state.valid) {
      let exist = false
      this.setState({ loading: true })
      switch (type) {
        case 'planner':
          this.state.planners.map(item => {
            if (item.usersView.email === this.state.email) {
              DAlert.error({ title: t("raceUsers.EMAIL_EXISTS") });
              exist = true
            }
          })
          if (!exist) {
            plannersApi.linkPlannerToRace({
              plannerEmail: this.state.email,
              raceId: parseInt(this.props.id)
            }).then(res => {
              if (res.data.code === 401) {
                DAlert.error({ title: t("raceUsers.invalidRol") });
                this.setState({ valid: false, email: '', loading: false })
              } else {
                if (res.data.code === 404) {
                  this.setState({ loading: false })
                  this.modalCreate(t, type)
                } else {
                  this.fetchPlanners()
                  DAlert.success({ title: t("commons.savedSuccess") });
                  this.setState({ valid: false, email: '', loading: false })
                }
              }
            })
            break;
          }
          this.setState({ valid: false, email: '', loading: false })
          break;
        case 'sale':
          this.state.PointOfSale.map(item => {
            if (item.usersView.email === this.state.email) {
              DAlert.error({ title: t("raceUsers.EMAIL_EXISTS") });
              exist = true
            }
          })
          if (!exist) {
            pointOfSaleApi.linkPlannerToRace({
              PointOfSaleEmail: this.state.email,
              raceId: parseInt(this.props.id)
            }).then(res => {

              if (res.data.code === 401) {
                DAlert.error({ title: t("raceUsers.invalidRol") });
                this.setState({ valid: false, email: '', loading: false })
              } else {
                if (res.data.code === 404) {
                  this.setState({ loading: false })
                  this.modalCreate(t, type)
                } else {
                  this.fetchPointOfSale()
                  DAlert.success({ title: t("commons.savedSuccess") });
                  this.setState({ valid: false, email: '', loading: false })
                }
              }
            })
            break;
          }
          this.setState({ valid: false, email: '', loading: false })
          break;
          case 'qrReader':
            this.state.qrReaders.map(item => {
              if (item.usersView.email === this.state.email) {
                DAlert.error({ title: t("raceUsers.EMAIL_EXISTS") });
                exist = true
              }
            })
            if (!exist) {
              qrReadersApi.linkQrReaderToRace({
                qrReaderEmail: this.state.email,
                raceId: parseInt(this.props.id)
              }).then(res => {
                if (res.data.code === 401) {
                  DAlert.error({ title: t("raceUsers.invalidRol") });
                  this.setState({ valid: false, email: '', loading: false })
                } else {
                  if (res.data.code === 404) {
                    this.setState({ loading: false })
                    this.modalCreate(t, type)
                  } else {
                    this.fetchQrReaders()
                    DAlert.success({ title: t("commons.savedSuccess") });
                    this.setState({ valid: false, email: '', loading: false })
                  }
                }
              })
              .catch(err => {
                this.setState({ valid: false, email: '', loading: false })
              })
              break;
            }
            this.setState({ valid: false, email: '', loading: false })
            break;
        default:
          this.setState({ loading: false })
          DAlert.error({ title: t("commons.errorSaving") });
      }
    } else {
      this.setState({ loading: false })
    }

  }

  add(type) {
    const { t, email } = this.state;

    try {
      modalHandler.open({
        header: t(`raceUsers.add.${type}`),
        size: "long",
        content: <Modal.Description>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width="16">
                <Form
                  {...email !== '' ? showValidation : undefined}
                  onFormChange={this.formHandler}
                  fields={[{
                    fields: [
                      {
                        name: "email",
                        placeHolder: t("raceUsers.modal.email"),
                        type: "Input",
                        colSize: 16,
                        validation: {
                          required: true,
                          regexType: "email",
                          errorMessage: t("commons.INVALID_EMAIL")
                        },
                        props: {
                          icon: "at",
                          iconPosition: "left"
                        }
                      }
                    ]
                  }]}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>,
        actions: (
          <Grid>
            <Grid.Column width="16">
              <Button
                fluid
                color="vk"
                onClick={() => {
                  this.setState({ loading: true, loaderText: t(`raceUsers.loader.${type}`) })
                  this.handler(t, type)
                  modalHandler.close();
                }}
              >
                {t("commons.ok")}
              </Button>
            </Grid.Column>
          </Grid>
        )
      });
    }
    catch (e) {}
  }
  render() {
    const { planners, PointOfSale, qrReaders, loading } = this.state
    const { t, rol, race } = this.props;
    const canEdit = (rol === 'ADMINISTRATOR' || rol === 'COMPANY') && !race.deleted
    const canEditQrUsers = canEdit || (rol === 'PLANNER' && !race.deleted)

    return (
      <Container>
        {loading && (
          <Dimmer active={loading} inverted>
            <Loader>{this.state.loaderText}</Loader>
          </Dimmer>
        )}
        <Segment
          textAlign='left'
          clearing>
          {canEdit  && <Button
            onClick={() => this.add('planner')}
            className="ui vk button"
            color="vk"
            floated='right'>
            {t('companyDetail.addUser')}
          </Button>}
          <List celled animated verticalAlign='middle' relaxed>
            <List.Header as='h3'>{t("roles.PLANNER")}</List.Header>
            {planners.length > 0 ? planners.map((planner, i) => {
              return (
                <Segment key={i}>
                  <List.Item >
                    <List.Content style={{
                      pointerEvents: !planner.Planner_race_xref.disable ? null : 'none',
                      opacity: !planner.Planner_race_xref.disable ? null : 0.6
                    }}>
                      <List.Header as='h4'>{planner.usersView.name}</List.Header>
                      <List.Description>{planner.usersView.email}</List.Description>
                      <List.Description>{planner.usersView.phone}</List.Description>
                    </List.Content>
                  </List.Item>
                  <div
                    style={{
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '100%'
                    }}>
                    {canEdit && <Checkbox
                      toggle
                      disabled={!canEdit}
                      checked={!planner.Planner_race_xref.disable}
                      onClick={() => {
                        this.checkboxPlannerClicked(planner, t)
                      }}
                      color='red'
                    />}
                  </div>
                </Segment>

              )
            }) : null
            }
          </List>
          {planners.length === 0 ?
            <Message warning attached='bottom'>
              <Icon name='warning' />
              {t("raceUsers.noPlanners")}
            </Message> : null}
        </Segment>
        <Segment
          textAlign='left'
          clearing>
          {canEdit && <Button
            onClick={() => this.add('sale')}
            className="ui vk button"
            color="vk"
            floated='right'>
            {t('companyDetail.addUser')}
          </Button>}
          <List celled animated verticalAlign='middle' relaxed>
            <List.Header as='h3'>{t("roles.POINT_OF_SALE")}</List.Header>
            {PointOfSale ? PointOfSale.map((PointOfSale, i) => {
              return (
                <Segment key={i}>
                  <List.Item
                    style={{
                      pointerEvents: !PointOfSale.PointOfSale_race_xref.disable ? null : 'none',
                      opacity: !PointOfSale.PointOfSale_race_xref.disable ? null : 0.6
                    }}>
                    <List.Content>
                      <List.Header as='h4'>{PointOfSale.usersView.name}</List.Header>
                      <List.Description>{PointOfSale.usersView.email}</List.Description>
                      <List.Description>{PointOfSale.usersView.phone}</List.Description>
                    </List.Content>
                  </List.Item>
                  <div
                    style={{
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '100%'
                    }}>
                   {canEdit && <Checkbox
                      toggle
                      disabled={!canEdit}
                      checked={!PointOfSale.PointOfSale_race_xref.disable}
                      onClick={() => {
                        this.checkboxPointOfSaleClicked(PointOfSale, t)
                      }}
                    />}
                  </div>
                </Segment>
              )
            }) : null}
          </List>
          {PointOfSale.length === 0 ?
            <Message warning attached='bottom'>
              <Icon name='warning' />
              {t("raceUsers.noPoints")}
            </Message> : null}
        </Segment>
        <Segment
          textAlign='left'
          clearing>
          {canEditQrUsers && <Button
            onClick={() => this.add('qrReader')}
            className="ui vk button"
            color="vk"
            floated='right'>
            {t('companyDetail.addUser')}
          </Button>}
          <List celled animated verticalAlign='middle' relaxed>
            <List.Header as='h3'>{t("roles.QR_READER")}</List.Header>
            {qrReaders ? qrReaders.map((qrReader, i) => {
              return (
                <Segment key={i}>
                  <List.Item
                    style={{
                      pointerEvents: !qrReader.QrReader_race_xref.disable ? null : 'none',
                      opacity: !qrReader.QrReader_race_xref.disable ? null : 0.6
                    }}>
                    <List.Content>
                      <List.Header as='h4'>{qrReader.usersView.name}</List.Header>
                      <List.Description>{qrReader.usersView.email}</List.Description>
                      <List.Description>{qrReader.usersView.phone}</List.Description>
                    </List.Content>
                  </List.Item>
                  <div
                    style={{
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '100%'
                    }}>
                   {canEditQrUsers && <Checkbox
                      toggle
                      disabled={!canEditQrUsers}
                      checked={!qrReader.QrReader_race_xref.disable}
                      onClick={() => {
                        this.checkboxQrReaderClicked(qrReader, t)
                      }}
                    />}
                  </div>
                </Segment>
              )
            }) : null}
          </List>
          {qrReaders.length === 0 ?
            <Message warning attached='bottom'>
              <Icon name='warning' />
              {t("raceUsers.noQrs")}
            </Message> : null}
        </Segment>

      </Container>
    )
  }
}