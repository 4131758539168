import { clientApi } from "./index";
import { checkToken } from "../util/tokenUtils";

export default {
  get: async params => {
    try {
      const response = await clientApi
        .getClient()
        .get("/inscriptions", { params: {...params, channel_id: 1} , timeout: 15000 });
      return response.data;
    } catch (error) { }
  },
  getInscription: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/inscription/${id}`, { params: {...params, channel_id: 1} });
      return response.data;
    } catch (error) {}
  },
  getAllByAthleteId: async (athleteId, params) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/inscriptions/${athleteId}`, { params: {...params, channel_id: 1} });
      return response.data;
    } catch (error) { }
  },
  create: async params => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/inscription`, { ...params, channel_id: 1 });
      return response.data;
    } catch (error) { }
  },
  checkEmail: async params => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/inscriptions/checkEmail`, { params: {...params, channel_id: 1} });
      return response.data;
    } catch (error) { }
  },
  createInscriptions: async params => {
    try {
      // params.push
      const response = await clientApi
        .getClient()
        // .post(`/inscriptions`, params);
      .post(`/inscriptions`, { inscriptions: { ...params }, channel_id: 1 });
      return response.data;
      console.log(response);
    } catch (error) {
      return error.response.data;
    }
  },
  updateInscription: async params => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/inscriptionConfig/${params.inscriptionId}`, { ...params, channel_id: 1 });
      return response;
    } catch (err) { }
  },
  refundInscription: async (inscriptionId, params) => {
    try {
      await checkToken();
      const response = await clientApi
        .getClient()
        .put(`/inscription/${inscriptionId}/refund`, { ...params, channel_id : 1 });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  cancelInscription: async (inscriptionId, params) => {
    try {
      await checkToken();
      const response = await clientApi
        .getClient()
        .put(`/inscription/${inscriptionId}/cancel`, { ...params, channel_id: 1 });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  unCancelInscription: async (inscriptionId, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/inscription/${inscriptionId}/uncancel`, { ...params, channel_id: 1 });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  afterRefundMoney: async (inscriptionId, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/inscription/${inscriptionId}/afterRefund`, { ...params, channel_id: 1 });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  cancelInscriptionAndNotRefundMoney: async (clientInscriptionId, params) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/inscription/${clientInscriptionId}/not_refund`, { params: { ...params, channel_id: 1 } });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  changeInscriptionModality: async (inscriptionId, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/inscription/${inscriptionId}/changeModality`, { ...params, channel_id: 1 });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  updateClientInscription: async (clientId, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/inscriptionUpdate/${clientId}`, {...params, channel_id: 1 });
      return response;
    } catch (error) {
      return error.response.data;
    }
  },
  resendVoucher: async (id) => {

    try {
      const response = await clientApi
        .getClient()
        .get(`/inscription/${id}/resend_voucher`, {
          params: { channel_id: 1 },
          timeout: 15000,
        });
      return response.data;
    } catch (error) {
      if(error.response) {
        return error.response.data;
      } else {
        return {
          res: {
            success:false
          }
        }
      }
    }

  },
  assignPlace: async (id) => {
    try {
      const response = await clientApi
      .getClient()
      .post(`/inscription/${id}/assign`, {channel_id: 1}); // añadido timeout porque esta consulta tarda mucho (pdte de solucionar)
    return response.data;
    } catch (err) {
      if(err.response)
        return err.response.data;
      else
        return err
    }
  },
  resetQr: async (id) => {
    try {
      const response = await clientApi
      .getClient()
      .post(`/inscription/${id}/resetqr`, { timeout: 15000, channel_id: 1 });
    return response;
    } catch (err) {
      return err.response.data;
    }
  }
};
