export default {
  email: /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/gm,
  phone_prefix: /^[+0-9]?[0-9]+$/,
  phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$|^$/gm,
  phone_required: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/gm,
  password:  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/gm,
  text: /^[A-Za-zÀ-ÖØ-ö'ūŠø-ÿ][A-Za-zÀ-ÖØ-ö'ūŠø-ÿ-_&/,.\s]{1,}$/,
  alphanumeric: /^[A-Za-zÀ-ÖØ-ö'ūŠø-ÿ0-9][A-Za-zÀ-ÖØ-ö'ūŠø-ÿ0-9-_&\s]+$/,
  address: /(?:.*?[\w{L}\w{N}]){1,}/gm,
  number: /^[0-9]*$/,
  double: /^\d+(\.|\,)?\d{0,2}$/,
  doubleWithoutDefault: /^\d+(\.|\,)?\d{0,2}$/,
  doubleOrNull: /^(\d+(\.|\,)?\d{0,2}|)$/,
  time: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
  url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  file: /^.+\.\w+$/g
};
