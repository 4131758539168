import React from "react";
import { Icon, Header, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ModalitySubtitle = props => {
  const [t] = useTranslation();

  return (
    <Segment padded basic>
      <Header as="h3" color="black">
        <Icon name={props.icon} />
        <Header.Content>{t(props.title)}</Header.Content>
      </Header>
    </Segment>
  );
};

ModalitySubtitle.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string
};

export default ModalitySubtitle;
