import { clientApi } from "./index";

export default {
  getPrice: async id => {
    try {
      const response = await clientApi.getClient().get(`price/${id}`, {params: {channel_id: 1}});
      return response.data;
    } catch (error) {}
  },
  getByModality: async ({ id, params }) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/prices/${id}`, { params: { ...params, channel_id: 1} });
      return response.data;
    } catch (err) {}
  },
  createPrice: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .post(`prices/${id}`, { ...params, channel_id: 1});
      return response.data;
    } catch (err) {}
  },
  updatePrice: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`price/${id}`, { ...params, channel_id: 1 });
      return response.data;
    } catch (err) {}
  },
  delete: async id => {
    try {
      const response = await clientApi.getClient().delete(`price/${id}`, { params: {channel_id: 1}});
      return response.data;
    } catch (err) {}
  }
};
