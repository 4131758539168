import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import PropTypes from "prop-types";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const RichTextEditor = (props) => {
  const [t] = useTranslation();

  const [editorState, setEditorState] = useState(
    props.defaultContent
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(props.defaultContent))
        )
      : EditorState.createEmpty()
  );

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(props.defaultContent || "")) //if defaultContent is null set it to ""
      )
    );
  }, [props.defaultContent]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const onBlurHandler = (_, editorState) => {
    props.onBlurEditor(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={onEditorStateChange}
      localization={{
        locale: t("currentLanguage"),
      }}
      toolbar={{
        inline: {
          inDropdown: false,
          options: ["bold", "italic", "underline"],
        },
        blockType: { inDropdown: true },
        fontSize: { inDropdown: true },
        fontFamily: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        colorPicker: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
      }}
      onBlur={onBlurHandler}
      {...props}
    />
  );
};

RichTextEditor.propTypes = {
  defaultContent: PropTypes.string,
  onBlurEditor: PropTypes.func,
};

RichTextEditor.defaultProps = {
  defaultContent: "",
  onBlurEditor: () => {},
};

export default RichTextEditor;
