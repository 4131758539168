import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";

import { getModality } from "../../reducers/modalities";
import { getRace } from "../../reducers/races";

import ModalityInscriptions from "./ModalityInscriptions";
import ModalityPrices from "./ModalityPrices";
import ModalityConfig from "./ModalityConfig";
import ModalityAttributes from "./ModalityAttributes";
import ModalityCategories from "./ModalityCategories";
import NoMatch from "../../components/NoMatch";

import ModalityMenu from "../../components/ModalityMenu";
import EditModality from "./EditModality";
import ModalitySummary from "./ModalitySummary";
import TeamTypes from "./TeamTypes";
@connect(
  state => ({
    modality: state.modalities.actualModality,
    race: state.races.actualRace
  }),
  dispatch => ({
    actions: bindActionCreators({ getModality, getRace }, dispatch)
  })
)
export default class Modality extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const { match, actions, race } = this.props;
    const {
      params: { id, raceId }
    } = match;

    actions.getModality(id);

    if (isEmpty(race)) {
      actions.getRace(raceId);
    }
  }

  getRoutes(hash) {
    const { match, history } = this.props;
    const {
      params: { id, raceId }
    } = match;

    if (!hash) {
      hash = "summary";
    }

    const componentRoutes = {
      summary: <ModalitySummary id={id} history={history} />,
      // summary: (
      //   <Container>
      //     <h1>Summary</h1>
      //   </Container>
      // ),
      edit: <EditModality id={id} history={history} />,
      inscriptionconfig: <ModalityConfig id={id} />,
      prices: <ModalityPrices id={id} history={history} />,
      inscriptions: (
        <ModalityInscriptions id={id} history={history} raceId={raceId} />
      ),
      attributes: (
        <ModalityAttributes id={id} raceId={raceId} history={history} />
      ),
      categories: <ModalityCategories id={id} history={history} />,
      teamtypes: <TeamTypes id={id} />
    };

    return componentRoutes[hash] ? componentRoutes[hash] : <NoMatch />;
  }

  onItemClick = item => {
    const { history } = this.props;

    history.push(item);
  };

  render() {
    const { location, match } = this.props;
    const hash = location.hash.split("#")[1];

    return (
      <React.Fragment>
        <ModalityMenu
          modalityId={match.params.id}
          raceId={match.params.raceId}
          activeItem={!hash ? "summary" : hash}
          onItemClick={this.onItemClick}
        />
        {this.getRoutes(hash)}
      </React.Fragment>
    );
  }
}
