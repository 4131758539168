const parseTPVS = (race) =>
  race?.company?.CompanyTPVs
    ? race.company.CompanyTPVs.map((tpv) => ({ text: tpv.name, value: tpv.id }))
    : [];

export const formFields = (t, race, role, isEventOrAssociate) => {
  const basicFields = [
    {
      fields: [
        {
          name: "company_tpv_id",
          title: t("raceEdit.advancedTabContent.tpvProfile"),
          data: parseTPVS(race),
          type: "Select",
          colSize: 6
        }
      ]
    },
    {
      fields: [
        {
          name: "max_inscriptions",
          title: t("raceEdit.advancedTabContent.maxInscriptions"),
          type: "Input",
          colSize: 4,
          validation: {
            custom: vals =>
              vals.max_inscriptions
                ? !isNaN(parseInt(vals.max_inscriptions))
                : true,
            errorMessage: t("commons.mustBeNumber")
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "show_inscription_counter",
          title: t("raceEdit.advancedTabContent.showInscriptionCounter"),
          type: "Checkbox",
          colSize: 16
        }
      ]
    },
    {
      fields: [
        {
          name: "show_inscription__modality_counter",
          title: t(
            "raceEdit.advancedTabContent.showInscriptionModalityCounter"
          ),
          type: "Checkbox",
          colSize: 16
        }
      ]
    },
    {
      fields: [
        {
          name: "show_inscription_list",
          title: t("raceEdit.advancedTabContent.showInscriptionList"),
          type: "Checkbox",
          colSize: 16
        }
      ]
    },
    ...(!isEventOrAssociate
      ? [
          {
            fields: [
              {
                name: "auto_dorsal_assignment",
                title: t("raceEdit.advancedTabContent.autoDorsalAssignment"),
                type: "Checkbox",
                colSize: 16,
              },
            ],
          },
        ]
      : []),
    race.auto_dorsal_assignment ?
    {
      fields: [
        {
          name: "virtual_dorsal",
          title: t("raceEdit.advancedTabContent.virtualDorsal"),
          type: "Checkbox",
          colSize: 16
        }
      ]
    }
    : {
      fields: []
    },
    {
      fields: [
        {
          name: "under14_dni",
          title: t("raceEdit.advancedTabContent.under14_dni"),
          type: "Checkbox",
          colSize: 16
        }
      ]
    }
  ];

  return [
    ...basicFields.slice(0, 2),
    ...(role === "ADMINISTRATOR"
      ? [
          {
            fields: [
              {
                name: "inscription_commission",
                title: t("raceEdit.advancedTabContent.inscriptionCommission"),
                type: "Input",
                colSize: 4,
                validation: {
                  custom: vals =>
                    vals.inscription_comission
                      ? !isNaN(
                          parseFloat(
                            vals.inscription_comission.replace(",", ".")
                          )
                        )
                      : true,
                  errorMessage: t("commons.mustBeNumber")
                }
              },
              {
                name: "inscription_commission_type",
                title: t(
                  "raceEdit.advancedTabContent.inscriptionCommissionType"
                ),
                type: "RadioButtons",
                colSize: 8,
                deflt: "PCT",
                radios: [
                  {
                    label: t("raceEdit.advancedTabContent.PCT"),
                    value: "PCT"
                  },
                  {
                    label: t("raceEdit.advancedTabContent.VAL"),
                    value: "VAL"
                  }
                ]
              }
            ]
          }
        ]
      : []),
    ...basicFields.slice(2),
    ...(race.auto_dorsal_assignment
      ? [
          {
            fields: [
              {
                name: "first_dorsal_to_assign",
                title: t("raceEdit.advancedTabContent.firstDorsalToAssign"),
                // placeHolder: "#",
                type: "Input",
                colSize: 3,
                validation: {
                  required: true,
                  custom: vals =>
                    vals.first_dorsal_to_assign
                      ? !isNaN(parseInt(vals.first_dorsal_to_assign))
                      : true,
                  errorMessage: t("commons.mustBeNumber"),
                  regexType: "number"
                }
              }
            ]
          },
          {
            fields: [
              {
                name: "copernico_race_id",
                title: t("raceEdit.advancedTabContent.copernicoRaceId"),
                // placeHolder: "#",
                type: "Input",
                colSize: 3
              }
            ]
          }
        ]
      : !isEventOrAssociate ? [
        {
          fields: [
            {
              name: "copernico_race_id",
              title: t("raceEdit.advancedTabContent.copernicoRaceId"),
              // placeHolder: "#",
              type: "Input",
              colSize: 3
            }
          ]
        }
      ] : []),
    // ...[
    //   {
    //     fields: [
    //       {
    //         name: "dorsal_assignment_by",
    //         title: t("raceEdit.advancedTabContent.dorsalAssignmentBy"),
    //         type: "RadioButtons",
    //         colSize: 3,
    //         radios: [
    //           {
    //             label: t("raceEdit.advancedTabContent.dorsalAssignmentByRace"),
    //             value: "RACE"
    //           },
    //           {
    //             label: t(
    //               "raceEdit.advancedTabContent.dorsalAssignmentByModality"
    //             ),
    //             value: "MODALITY"
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ]
  ];
};
