export const formFields = (t, raceTypes, countryCodes, provinces, isEventOrAssociate) => {
  return [
    {
      fields: [
        {
          name: "name",
          title: t("raceEdit.basicTabContent.name"),
          placeHolder: t("raceEdit.basicTabContent.name"),
          type: "Input",
          colSize: 16,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "start_date",
          title: t("raceEdit.basicTabContent.startDate"),
          placeHolder: t("raceEdit.basicTabContent.startDate"),
          type: "DatePicker",
          colSize: 6,
          props: {
            id: '0'
          },
          validation: {
            custom: vals => {
              if (vals.start_date === null || vals.startstart_dateDate === "") {
                return {
                  valid: false,
                  errorMessage: t("modalities.wrongDateFormat")
                };
              }
              return true;
            }
          }
        },
        {
          name: "finish_date",
          title: t("raceEdit.basicTabContent.finishDate"),
          placeHolder: t("raceEdit.basicTabContent.finishDate"),
          type: "DatePicker",
          colSize: 6,
          props: {
            id: '1'
          },
          validation: {
            custom: values => {
              if (values.finish_date) {
                const isGreater =
                  new Date(values.start_date) <= new Date(values.finish_date);
                return {
                  valid: isGreater,
                  errorMessage: t(
                    "raceEdit.basicTabContent.finishDateMustBeGreater"
                  )
                };
              }

              if (values.finish_date === null || values.finish_date === "") {
                return {
                  valid: false,
                  errorMessage: t("modalities.wrongDateFormat")
                };
              }

              return true;
            }
          }
        },
        {
          name: "type_id",
          title: !isEventOrAssociate ? t("raceEdit.basicTabContent.raceType") : t("raceEdit.basicTabContent.sportType"),
          placeHolder: !isEventOrAssociate ? t("raceEdit.basicTabContent.raceType") : t("raceEdit.basicTabContent.sportType"),
          type: "Select",
          colSize: 6,
          data: raceTypes,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          }
        }
      ]
    },
    {
      fields: (state) => [
        {
          name: "country",
          type: "Select",
          data: countryCodes.map(countryCode => ({
            text: t(`countries.${countryCode}`),
            value: countryCode
          })),
          props: {
            clearable: true
          },
          title: t("raceEdit.basicTabContent.country"),
          placeHolder: t("raceEdit.basicTabContent.country"),
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
          colSize: 6
        },
        {
          name: "region",
          type: state.country == 'ES' ? "Select" : "Input",
          data: state.country == 'ES' ? provinces.map(province => ({
            text: province.name,
            value: province.slug
          })) : null,
          props: {
            clearable: true
          },
          title: t("raceEdit.basicTabContent.region"),
          placeHolder: t("raceEdit.basicTabContent.region"),
          validation: {
            required: true,
            regexType: "text",
            errorMessage: t("commons.fieldRequired")
          },
          colSize: 6
        },
        {
          name: "start_city",
          title: t("raceEdit.basicTabContent.city"),
          placeHolder: t("raceEdit.basicTabContent.city"),
          type: "Input",
          colSize: 6,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "refund_policy",
          title: t("raceEdit.basicTabContent.refundPolicy"),
          placeHolder: t("raceEdit.basicTabContent.refundPolicy"),
          type: "TextArea",
          colSize: 8
        },
        {
          name: "dorsal_pickup_place",
          title: !isEventOrAssociate ? t("raceEdit.basicTabContent.dorsalPickupPlace") : t("raceEdit.basicTabContent.schedule"),
          placeHolder: !isEventOrAssociate ? t("raceEdit.basicTabContent.dorsalPickupPlace") : t("raceEdit.basicTabContent.schedule"),
          type: "TextArea",
          colSize: 8
        }
      ]
    },
    {
      fields: [
        {
          name: "exit_place_info",
          title: !isEventOrAssociate ? t("raceEdit.basicTabContent.exitPlaceInfo") : t("raceEdit.basicTabContent.celebrationPlace"),
          placeHolder: !isEventOrAssociate ? t("raceEdit.basicTabContent.exitPlaceInfo") : t("raceEdit.basicTabContent.celebrationPlace"),
          type: "TextArea",
          colSize: 16
        }
      ]
    },
    {
      fields: [
        {
          name: "contact",
          title: t("raceEdit.basicTabContent.contact"),
          type: "Label"
        }
      ]
    },
    {
      fields: [
        {
          name: "email",
          title: t("raceEdit.basicTabContent.contactEmail"),
          placeHolder: t("raceEdit.basicTabContent.contactEmail"),
          type: "Input",
          colSize: 8
        },
        {
          name: "phone",
          title: t("raceEdit.basicTabContent.contactPhone"),
          placeHolder: t("raceEdit.basicTabContent.contactPhone"),
          type: "Input",
          colSize: 8
        }
      ]
    }
  ];
};
