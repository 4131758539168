import React from "react";
import moment from "moment";

import {
  Form,
  Label,
  Radio,
  HelpBlock,
  Divider,
  Header,
  Checkbox,
  Grid,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import { DateInput, DateTimeInput } from "semantic-ui-calendar-react";
import DatePicker from "../DatePicker";
import regex from "../Form/regex";

import TrueFalse from "../../components/TrueFalse";
const formElementStyle = {
  height: "38px",
  fontFamily: "Raleway, sans-serif",
  fontSize: "13px",
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const getValueForSelect = (value, data, getOptionValue) => {
  if (value != null) {
    return typeof value != "object"
      ? data.find((option) => option[getOptionValue] === value)
      : value;
  } else {
    return null;
  }
};

const getError = (name, showValidation, usedFields, validation) => {
  // return false

  let valid = getValidation(validation, name, true);
  const errorMessage = getErrorMessage(validation, name);

  if (usedFields && usedFields.includes(name) && valid == "error") {
    return { content: errorMessage };
  }

  return showValidation && valid == "error" ? { content: errorMessage } : false;
};

const getValueForSwitch = (value, isTrueIf) => {
  if (value == "undefined") {
    return false;
  }

  if (typeof value == "string") return value == isTrueIf;

  return value;
};

const getValidation = (validation, name, showValidation) => {
  if (showValidation && validation) {
    if (validation[name] && !validation[name].isValid) {
      return "error";
    } else {
      return null;
    }
  }

  return null;
};

const ValidationComponent = ({ showValidation, valid, message }) => (
  <>
    {
      // showValidation && <FormControl.Feedback />
    }
    {showValidation && valid == "error" && <HelpBlock>{message}</HelpBlock>}
  </>
);

const getErrorMessage = (validation, name) => {
  const [t] = useTranslation();

  if (validation) {
    return validation[name] && validation[name].errorMessage
      ? validation[name].errorMessage
      : t("commons.fieldRequired");
  }

  return "";
};

const Components = {
  Input: ({
    title,
    value,
    onChange,
    name,
    colSize,
    placeHolder,
    props,
    validation,
    showValidation,
    deflt,
    onBlur,
    usedFields,
  }) => {
    if(!value){
      if(validation 
        && validation[name] 
        && validation[name].regexType === "double"){
        value=0.00
      }else{
        value=""
      }
    }

    const error = getError(name, showValidation, usedFields, validation);
    const autoComplete = props && props.autoComplete ? props.autoComplete : "nope"

    return (
      <Form.Input
        error={error}
        label={title}
        width={colSize}
        value={value}
        defaultValue={deflt}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => onBlur(e.target.value)}
        placeholder={placeHolder}
        autoComplete={autoComplete}
        {...props}
      />
    );
  },
  DatePicker: ({
    title,
    value = "",
    onChange,
    name,
    colSize,
    validation,
    showValidation,
    placeHolder,
    props,
    usedFields,
  }) => {
    const getValue = (value) => {
      if (moment(value).isValid()) {
        return moment(value).format("YYYY-MM-DD");
      }
      return value || "";
    };

    const parseValue = (value) => {
      const utcDate = moment(value).format("YYYY-MM-DD");

      return moment(utcDate).isValid() ? utcDate : null;
    };

    const error = getError(name, showValidation, usedFields, validation);

    const colSizeName = (number) => {
      switch (number) {
        case 1:
          return "one";
        case 2:
          return "two";
        case 3:
          return "three";
        case 4:
          return "four";
        case 5:
          return "five";
        case 6:
          return "six";
        case 7:
          return "seven";
        case 8:
          return "eight";
        case 9:
          return "nine";
        case 10:
          return "ten";
        case 11:
          return "eleven";
        case 12:
          return "twelve";
        case 13:
          return "thirteen";
        case 14:
          return "fourteen";
        case 15:
          return "fifteen";
        case 16:
          return "sixteen";
      }
    };

    return (
      <div
        className={
          error
            ? `error ${colSizeName(colSize)} wide field`
            : `${colSizeName(colSize)} wide field`
        }
      >
        {title && <label>{title}</label>}
        {props && props.clearable == false ? (
          <label style={{ fontWeight: 100 }}>{getValue(value)}</label>
        ) : (
          <input
            {...props}
            // error = {error}
            type="date"
            value={getValue(value)}
            min={moment().subtract(150, "years").format("YYYY-MM-DD")}
            max={moment().add(10, "years").format("YYYY-MM-DD")}
            onChange={(event) => onChange(parseValue(event.target.value))}
            onBlur={(event) => onChange(parseValue(event.target.value))}
          ></input>
        )}
        {error ? (
          <div
            id={props.id && `${props.id}-error-message`}
            className="ui pointing above prompt label"
            style={{
              whiteSpace: "normal",
              background: "#fff",
              border: "1px solid #e0b4b4",
              color: "#9f3a38",
            }}
          >
            {error.content}
          </div>
        ) : null}
      </div>
    );
  },
  /*
  DateTimePicker: ({
    title,
    value,
    onChange,
    name,
    colSize,
    validation,
    showValidation,
    placeHolder,
    usedFields,
    props,
  }) => {
    const getValue = (value) => {
      console.log({getValue:value});
      
      if (value !== undefined && moment(value).isValid()) {

        const datePart = moment(value).format("YYYY-MM-DD");
        const timePart = moment(value).format("HH:mm");
        console.log({ value, datePart, timePart })

        // return moment(value).format("YYYY-MM-DDTHH:mm");
        return {
          datePart: moment(value).format("YYYY-MM-DD"),
          timePart: moment(value).format("HH:mm")
        };
      }

      // return value;
      return {
        datePart: '',
        timePart: ''
      }
    };

    const parseDateValue = (value) => {
      console.log({parseDateValue:value});
      const utcDate = moment(value).format("YYYY-MM-DD");
      return moment(utcDate).isValid() ? utcDate : null;
    };
    const parseTimeValue = (value) => {
      console.log({parseTimeValue:value});

      return value;
      

    }
    // const error = getError(name, showValidation, usedFields, validation);
    const error = false
    console.log({ props });


    const colSizeName = (number) => {
      switch (number) {
        case 1:
          return "one";
        case 2:
          return "two";
        case 3:
          return "three";
        case 4:
          return "four";
        case 5:
          return "five";
        case 6:
          return "six";
        case 7:
          return "seven";
        case 8:
          return "eight";
        case 9:
          return "nine";
        case 10:
          return "ten";
        case 11:
          return "eleven";
        case 12:
          return "twelve";
        case 13:
          return "thirteen";
        case 14:
          return "fourteen";
        case 15:
          return "fifteen";
        case 16:
          return "sixteen";
      }
    };


    return (

      <div
        className={
          error
            ? `error ${colSizeName(colSize)} wide field`
            : `${colSizeName(colSize)} wide field`
        }
      >
        <label>{title}</label>
        {props && props.clearable == false ? (
          <label style={{ fontWeight: 100 }}>{getValue(value)}</label>
        ) : (
            <Grid>
              <Grid.Column width={10}>
                <input
                  {...props}
                  type="date"
                  value={getValue(value).datePart}
                  min={moment().subtract(150, "years").format("YYYY-MM-DD")}
                  max={moment().add(10, "years").format("YYYY-MM-DD")}
                  onChange={(event) => onChange(parseDateValue(event.target.value))}
                  onBlur={(event) => onChange(parseDateValue(event.target.value))}
                ></input>
              </Grid.Column >
              <Grid.Column width={6}>
                <input
            style={{
              whiteSpace: "normal",
              background: "#fff",
              border: "1px solid #e0b4b4",
              color: "#9f3a38",
            }}
                  {...props}
                  type="time"
                  value={getValue(value).timePart}
                  onChange={(event) => onChange(parseTimeValue(event.target.value))}
                  onBlur={(event) => onChange(parseTimeValue(event.target.value))}
                />
              </Grid.Column >
            </Grid>
          )}
        {error ? (
          <div
            id={props.id && `${props.id}-error-message`}
            className="ui pointing above prompt label"
            style={{
              whiteSpace: "normal",
              background: "#fff",
              border: "1px solid #e0b4b4",
              color: "#9f3a38",
            }}
          >
            {error.content}
          </div>
        ) : null}
      </div>
      // <div
      //   className={
      //     error
      //       ? `error ${colSizeName(colSize)} wide field`
      //       : `${colSizeName(colSize)} wide field`
      //   }
      // >
      //   {props && props.clearable == false ? (
      //     <label style={{ fontWeight: 100 }}>{getValue(value)}</label>
      //   ) : (
      //     <DatePicker
      //       label={title}
      //       value={getValue(value)}
      //       onChange={(val) => {
      //         onChange(val);
      //       }}
      //     />
      //   )}
      //   {error ? (
      //     <div
      //       className="ui pointing above prompt label"
      //       style={{
      //         whiteSpace: "normal",
      //         background: "#fff",
      //         border: "1px solid #e0b4b4",
      //         color: "#9f3a38",
      //       }}
      //     >
      //       {error.content}
      //     </div>
      //   ) : null}
      // </div>
    );
  },
  */
  Select: ({
    title,
    value,
    data,
    onChange,
    name,
    colSize,
    props,
    placeHolder,
    getOptionLabel,
    getOptionValue,
    validation,
    showValidation,
    usedFields,
  }) => {
    const [t] = useTranslation();

    const error = getError(name, showValidation, usedFields, validation);
    return (
      <Form.Dropdown
        error={error}
        label={title}
        options={[{ text: t("commons.selectAnOption"), value: 0 }, ...data]}
        value={value == 0 ? null : value}
        selection
        width={colSize}
        search
        width={colSize}
        clearable
        placeholder={placeHolder}
        onChange={(e, { value }) => onChange(value)}
        autoComplete="nope"
        onFocus={(e) => e.target.setAttribute("autocomplete", "nope")}
        {...props}
      />
    );
  },
  TextArea: ({
    title,
    value,
    onChange,
    name,
    colSize,
    placeHolder,
    validation,
    showValidation,
    props,
    onBlur,
    usedFields,
  }) => {
    const error = getError(name, showValidation, usedFields, validation);
    if (!value) value = ""; // to avoid Warning: `value` prop on `textarea` should not be null.
    return (
      <Form.TextArea
        error={error}
        label={title}
        width={colSize}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => onBlur(e.target.value)}
        placeholder={placeHolder}
        autoComplete="nope"
        {...props}
      />
    );
  },
  Checkbox: ({
    title,
    value,
    onChange,
    name,
    colSize,
    placeHolder,
    validation,
    showValidation,
    props,
    direction,
    usedFields,
    readOnlyValue,
  }) => {
    value = value === true ? true : false;

    const error = getError(name, showValidation, usedFields, validation);

    const { readOnly } = { ...props };
    if (readOnly) {
      return (
        //<div className={`form-group col-md-${colSize} field`}>
        <Form.Field width={colSize}>
          {direction == "column" && <label>{title}</label>}
          <TrueFalse
            value={
              typeof readOnlyValue !== "undefined" &&
              typeof readOnlyValue !== "null"
                ? readOnlyValue
                : value
            }
            style={{ textAlign: "none", padding: ".67857143em 1em" }}
          />
        </Form.Field>
        //</div>
      );
    }

    return (
      <Form.Field
        style={{ display: "flex", alignItems: "center" }}
        width={colSize}
      >
        <Checkbox
          error={
            error
              ? {
                  ...error,
                  pointing: null,
                }
              : undefined
          }
          label={title}
          width={colSize}
          checked={value}
          onChange={(_, d) => onChange(d.checked)}
          {...props}
        />
      </Form.Field>
    );
  },
  Switch: ({ title, value, onChange, name, colSize, isTrueIf, props }) => (
    <div
      className={`form-group col-md-${colSize}`}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Label>{title}</Label>
      <Switch
        aria-labelledby="neat-label"
        name={name}
        onChange={(val) => onChange(val)}
        checked={getValueForSwitch(value, isTrueIf)}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        onColor="#047eb4"
        {...props}
      />
    </div>
  ),
  Label: ({
    title,
    value,
    name,
    colSize,
    placeHolder,
    validation,
    showValidation,
    color,
    props,
  }) => {
    let valid = getValidation(validation, name, showValidation);
    return (
      <>
        <h3 style={{ marginLeft: 5, marginBottom: 0, color }}>{title}</h3>
        <h4 style={{ marginLeft: 10 }} {...props}>
          {value}
        </h4>
      </>
    );
  },
  RadioButtons: ({
    title,
    value = "",
    name,
    colSize,
    placeHolder,
    radios,
    validation,
    showValidation,
    onChange,
    deflt,
    usedFields,
    props,
    hide,
  }) => {
    const error = getError(name, showValidation, usedFields, validation);

    return (
      <Form.Field error={error} width={colSize} {...props}>
        <label>{title}</label>
        <div
          style={{
            display: "flex",
            flexDirection: props?.direction
              ? props?.direction === "horizontal"
                ? "row"
                : "column"
              : "column",
            justifyContent: props?.direction
              ? props?.direction === "horizontal"
                ? "space-around"
                : "flex-start"
              : "flex-start",
          }}
        >
          {radios.map((radio, key) => (
            <Form.Radio
              key={`${key}_${name}_${radio}`}
              label={radio.label}
              name={name}
              value={radio.value}
              checked={!!value ? value === radio.value : radio.value === deflt}
              onChange={() => onChange(radio.value)}
            />
          ))}
          {error && (
            <Label pointing prompt>
              {error.content}
            </Label>
          )}
        </div>
      </Form.Field>
    );
  },
  Null: ({ colSize = 0 }) => (
    <Form.Field
      {...(colSize === 0 ? {} : { width: colSize })}
      style={colSize === 0 ? { hidden: true, padding: 0 } : {}}
    />
  ),
  Divider: ({ title, props }) => {
    return (
      <Divider horizontal style={{ width: "100%" }} {...props}>
        {title}
      </Divider>
    );
  },
  Warning: ({ title, props }) => {
    return (
      <div
        horizontal
        style={{
          width: "100%",
          color: "white",
          backgroundColor: "#c23321",
          textAlign: "center",
          padding: "1rem",
          whiteSpace: "pre-wrap",
          lineHeight: 2,
        }}
        {...props}
      >
        {title}
      </div>
    );
  },
};

Components.Select.defaultProps = {
  getOptionLabel: "label",
  getOptionValue: "value",
};

Components.Input.defaultProps = {
  onBlur: () => {},
};

Components.Checkbox.defaultProps = {
  direction: "column", //can be column or row
};

Components.RadioButtons.defaultProps = {
  radios: [],
};

export default Components;
