import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Message, Button, Icon, Modal } from "semantic-ui-react";
import { useSelector } from "react-redux";

import FluidResponsiveButton from "../../../components/FluidResponsiveButton";
import { modalHandler } from "../../../components/DModal";
import { useHistory } from "react-router";
import { createModalityForm } from "./util";
import modalitiesApi from "../../../apis/modalities";
import racesApi from "../../../apis/races";
import Form from "../../../components/Form";
import moment from "moment";

const CreateUserModal = props => {
  const history = useHistory();
  const [error, setError] = useState();
  const [showValidation, setShowValidation] = useState(false);
  const [buttonDisbled,setButtonDisbled] = useState(false)
  const [modalityForm, setModalityForm] = useState();
  const [raceTypes, setRaceTypes] = useState([]);
  const race = useSelector(state => state.races.actualRace);
  const [t] = useTranslation();

  const getRaceTypes = async () => {
    await racesApi.fetchRaceTypes().then(response => {
      const raceTypes = response.map(type => ({
        text: type.type,
        value: type.id
      }));
      setRaceTypes(raceTypes);
    });
  };
  const formHandler = formData => {
    setError(undefined);

    setModalityForm(formData);
  };

  const createModalityHandler = async () => {
    setButtonDisbled(true)
    if (!modalityForm.ISFORMVALID) {
      setShowValidation(true);
      setButtonDisbled(false)
      return;
    }

    const { date, distance } = modalityForm;

    modalityForm.start_date = moment(date).format("YYYY-MM-DD");
    modalityForm.race_id = parseInt(props.race_id);
    modalityForm.distance = parseFloat(parseFloat(
      typeof distance === "string" ? distance.replace(",", ".") : distance
    ).toFixed(2));

    await modalitiesApi
      .create(modalityForm)
      .then(response => {
        modalHandler.open(
          afterModalityCreation(response[0].race_id, response[0].id)
        );
        setButtonDisbled(false)
      })
      .catch(e => {
        setButtonDisbled(false)
        setError(e.response.error.message);
      });
  };

  useEffect(() => {
    getRaceTypes();
  }, []);

  useEffect(() => {
    modalHandler.refreshOptions({
      ...modalOptions
    });
  }, [raceTypes, error, modalityForm, showValidation]);

  const afterModalityCreation = (raceId, id) => {
    return {
      header: t(`modalities.create.successModal.createdSuccess`),
      content: (
        <Modal.Description>
          {t(`modalities.create.successModal.createdSuccessMessage`)}
        </Modal.Description>
      ),
      actions: (
        <Modal.Actions>
          <Button
            icon="check"
            color={"instagram"}
            content={t(`modalities.create.successModal.createdSuccessButton`)}
            onClick={() => {
              modalHandler.close();

              history.push(`/race/${raceId}/modality/${id}#summary`);
            }}
          />
        </Modal.Actions>
      )
    };
  };

  const isEventOrAssociate =
    race.activityType.type === "EVENT" ||
    race.activityType.type === "ASSOCIATES";
  const modalOptions = {
    header: t("modalities.createModality"),
    content: (
      <Modal.Description>
        <Grid padded>
          <Grid.Row>
            <Grid.Column width="16">
              <Form
                showValidation={showValidation}
                onFormChange={formHandler}
                fields={createModalityForm(
                  t,
                  raceTypes,
                  race ?.start_date,
                  race ?.finish_date,
                  isEventOrAssociate
                )}
              />
            </Grid.Column>
          </Grid.Row>
          {error && (
            <Grid.Row>
              <Grid.Column width="16">
                <Message
                  error
                  header={t(`usersScreen.modal.messages.${error}.header`)}
                  content={t(`usersScreen.modal.messages.${error}.message`)}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Modal.Description>
    ),
    actions: (
      <Grid>
        <Grid.Column width="16">
          <Button fluid color="vk"
          disabled={buttonDisbled}
          onClick={() => createModalityHandler()}>
            {t("modalities.createModality")}
          </Button>
        </Grid.Column>
      </Grid>
    )
  };

  return (
    <>
      <FluidResponsiveButton
        icon
        labelPosition="left"
        size="small"
        onClick={() => {
          setShowValidation(false);

          modalHandler.open(modalOptions)
        }}
        primary
      >
        <Icon name="stopwatch" />
        {t("modalities.createModality")}
      </FluidResponsiveButton>
    </>
  );
};

export default CreateUserModal;
