import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import raceApi from "../../../../apis/races";
import { formFields } from "./util";
import Form from "../../../../components/Form";
import { updateRace } from "../../../../reducers/races";
import { Segment, Grid, Header, Divider, Button } from "semantic-ui-react";
import ImagePicker from "../../../../components/ImagePicker";
import { DAlert } from "../../../../components/Dalert";
@withTranslation()
@connect(
  state => ({
    race: state.races.actualRace,
    role: state.user.role
  }),
  dispatch => ({
    actions: bindActionCreators({ updateRace }, dispatch)
  })
)

export default class Advanced extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showValidation: false,
      isNew : false,
      btnQrDisable : false
    };
  }
  async resetQr() {
    this.setState({btnQrDisable: true})
    const { t,race } = this.props
    const res = await raceApi.resetQr(race.id)
    if (res.status === 200) {
      DAlert.success({ message: t("commons.resetQrSuccess") });
    } else {
      DAlert.error({ message: t("commons.resetQrError") });
    }
    this.setState({btnQrDisable: false})
  }
  componentDidUpdate(prevProps) {
    if (prevProps.race.ISFORMVALID !== this.props.race.ISFORMVALID) {
      if (prevProps.race.ISFORMVALID) {
        this.setState({ showValidation: !this.props.race.ISFORMVALID });
      }
    }
  }

  headerImageChange(data) {
    this.props.actions.updateRace({
      virtual_dorsal_header: data
    });
  }

  footerImageChange(data) {
    this.props.actions.updateRace({
      virtual_dorsal_footer: data
    });
  }

  render() {
    const { t, race, actions, role } = this.props;
    const { showValidation, 
      btnQrDisable,
        isLoading
    } = this.state;

    const isEventOrAssociate =
      race.activityType.type === "EVENT" ||
      race.activityType.type === "ASSOCIATES";
    return (
      <Segment padded basic>
        <Form
          defaultState={race}
          showValidation={showValidation}
          fields={formFields(t, race, role, isEventOrAssociate)}
          onFormChange={data =>
            actions.updateRace({
              ...data,
              inscription_commission:
                data.inscription_commission &&
                typeof data.inscription_commission === "string"
                  ? parseFloat(data.inscription_commission.replace(",", "."))
                  : data.inscription_commission
            })
          }
        />
        <Button
          style={{ margin: '5px 2px' }}
          color="vk"
          onClick={() => this.resetQr()}
          disabled = {btnQrDisable}
        >
          {t("raceEdit.advancedTabContent.resetQr")}
        </Button>
        {
          race.virtual_dorsal && !isEventOrAssociate ? 
          [
          <Divider horizontal>
            {t("raceEdit.advancedTabContent.virtualDorsal")}
          </Divider>,
          <Grid columns={2}>
            <Grid.Column mobile={16} computer={8}>
              <Header as="h5">{t("raceDetail.header", {dimensions:"600px x 200px"})}</Header>
              {!race.deleted && <ImagePicker
                accept="image/x-png,image/jpeg,image/webp"
                content={t("commons.selectImage")}
                onSelect={data => this.headerImageChange(data)}
                imageSrc={race?.virtual_dorsal_header}
                buttonProps={{
                  fluid: true
                }}
                showImage ={false}
              />}
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <Header as="h5">{t("raceDetail.footer", {dimensions:"600px x 200px"})}</Header>
              {!race.deleted && <ImagePicker
                accept="image/x-png,image/jpeg,image/webp"
                content={t("commons.selectImage")}
                onSelect={data => this.footerImageChange(data)}
                imageSrc={race?.virtual_dorsal_footer}
                buttonProps={{
                  fluid: true
                }}
                showImage ={false}
              />}
            </Grid.Column>
            <Grid.Column mobile={16} computer={16}>
              <Header as="h5">{t("raceDetail.preview")}</Header>
              {
                race.virtual_dorsal_header ?
                <img src={race.virtual_dorsal_header} width={500} style={{display : 'block', marginLeft : 'auto', marginRight : 'auto'}}/>
                :
                null
              }
              {
                [<Grid.Row>
                  <div style={{fontSize: 140, textAlign : 'center', marginBottom : 70, marginTop : 50, fontFamily :'Racing Sans One'}}>
                    {race.first_dorsal_to_assign ? race.first_dorsal_to_assign : "0"}
                  </div>
                </Grid.Row>,
                <Grid.Row>
                  <div style={{fontSize: 26, textAlign : 'center', marginBottom : 30, fontFamily :'Anton'}}>
                    {t("raceDetail.exampleName")}
                  </div>
                </Grid.Row>]
              }
              {
                race.virtual_dorsal_footer ?
                <img src={race.virtual_dorsal_footer} width={500} style={{display : 'block', marginLeft : 'auto', marginRight : 'auto'}} />
                :
                null
              }
            </Grid.Column>
            

          </Grid>
        ]
        :
        null
        }
      </Segment>
    );
  }
}
