import React, { useState } from "react";
import { Container, Divider, Message, Form, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import regex from "../../../../components/Form/regex";
import { partialRefundFields } from "./util";
import es from "../../../../locales/default.es";
// import Form from "../../../../components/Form";
import { refundHeaders } from "./util";
import Table from "../../../../components/DTable";

const RefundInscription = (props) => {
  const [refundQ, setRefundQ] = useState({});
  const [t] = useTranslation();
  const { unCancel } = props;
  return (
    <Container>
      <p>{t("inscriptionsScreen.detail.refundMessage")}</p>
      <Divider hidden />
      <Table
        headers={refundHeaders(t)}
        contentData={props.inscriptions}
        showItemsPerPage={false}
        showNumberOfRecords={false}
        showPagination={false}
        showTotalItems={false}
        // showSelectedRow={true}
        rowProps={(data) =>
          data.status === "CANCELLED" && !unCancel
            ? { negative: true, className: "strikethrough" }
            : {}
        }
        parseData={(data) => ({
          amountToRefund:
            data.status === "CANCELLED" && !unCancel ? null : (
              <Form.Input
                value={refundQ[data.id] ? refundQ[data.id] : null}
                max={data.price - data.total_refund}
                onChange={(_, { value }) => {
               
                  if (value.match(regex["doubleOrNull"])) {

                    setRefundQ({...refundQ, [data.id]: value});
                    props.onChange(value, data);
                  }
                }}
                fluid
              />
            ),
          price: `${parseFloat(data.price - data.total_refund).toFixed(2)}€`,
        })}
      />
    </Container>
  );
};

export default RefundInscription;
