import React, { Component } from "react";
import { Card, Icon, List, Grid, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";

const { PUBLIC_WEB_URL } = process.env;

@withTranslation()
@connect(state => ({
  role: state.user.role
}))
export default class ModalityCard extends Component {
  constructor(props) {
    super(props);
  }

  modalityFinished(start_date) {
    return moment(new Date()).isAfter(moment(start_date).add(1, "day"));
  }

  getCardDescription = modality => {
    const { t, history, role, racePublised, raceDeleted } = this.props;
    const isPOS = role === "POINT_OF_SALE";
    const { race_id, start_date, time_start, id, slug, available, hasClassifications, is_published } = modality;
    const isAvaliable = available === true || available?.isAvaliable
    const finished = this.modalityFinished(start_date);

    const description = (
      <List relaxed style={{ margin: "0% 5% 5% 5%" }}>
        <List.Item>
          <List.Icon name="calendar alternate outline" />
          <List.Content>
            {moment(start_date).format("DD-MM-YYYY")} {time_start}
          </List.Content>
        </List.Item>
        {!raceDeleted && !finished && is_published && isAvaliable && racePublised && (<List.Item
          onClick={() =>
            window.open(`${PUBLIC_WEB_URL}/inscription/${slug}`, "_blank")
          }
        >
          <List.Icon name="address card outline" />
          <List.Content as="a">
            {t("raceDetail.detail.events.inscriptionUrl")}
          </List.Content>
        </List.Item>)}
        {!raceDeleted && <List.Item
          onClick={() =>
            history.push(
              isPOS
                ? `/race/${race_id}#inscriptions`
                : `/race/${race_id}/modality/${id}#inscriptions`
            )
          }
        >
          <List.Icon name="table" />
          <List.Content as="a">
            {t("raceDetail.detail.events.adminInscriptionUrl")}
          </List.Content>
        </List.Item>
        }
        {!raceDeleted && finished && hasClassifications && (<List.Item
          onClick={() =>
            window.open(`${PUBLIC_WEB_URL}/classification/${slug}`, "_blank")
          }>
          <List.Icon name="winner" />
          <List.Content as="a">
            {t("raceDetail.detail.events.clasificationUrl")}
          </List.Content>
        </List.Item>)}
      </List>
    );

    return description;
  };

  render() {
    const { modality, t, raceId, role, raceDeleted } = this.props;
    const isPOS = role === "POINT_OF_SALE";

    return (
      <Card color="blue">
        <Card.Content header={modality.name} />
        <Card.Description>{this.getCardDescription(modality)}</Card.Description>
        <Card.Content extra>
          <Grid columns={2}>
            <Grid.Column width={8} textAlign="left" verticalAlign="middle">
              <Icon fitted name="chart bar outline" />
              &ensp; {modality.inscriptionsCount}{t("raceDetail.detail.inscriptions")}
            </Grid.Column>

            {!isPOS && (
              <Grid.Column width={8} textAlign="right" verticalAlign="middle">
                <Button
                  icon
                  onClick={() => {
                    this.props.history.replace(
                      `/race/${raceId}/modality/${modality.id}#summary`
                    );
                  }}
                >
                  <Icon fitted name="eye" />
                </Button>

                {!raceDeleted && <Button
                  icon
                  onClick={() => {
                    this.props.history.push(
                      `/race/${raceId}/modality/${modality.id}#edit`
                    );
                  }}
                >
                  <Icon fitted name="edit" />
                </Button>}
              </Grid.Column>
            )}
          </Grid>
        </Card.Content>
      </Card>
    );
  }
}
