export const tableHeaders = (t) => {
  return [
    {
      content: t("companyScreen.table.date"),
      accessor: "createdAt",
    },
    {
      content: t("companyScreen.table.company"),
      accessor: "name",
    },
    {
      content: t("companyScreen.table.email"),
      accessor: "email",
    },
    {
      content: t("companyScreen.table.phone"),
      accessor: "phone",
    },
  ];
};

export const filterFields = (t) => {
  return [
    {
      fields: [
        {
          name: "name",
          placeHolder: t("companyScreen.table.name"),
          type: "Input",
          colSize: 8,
          props: { id: "CompaniesFilterName" },
        },
        {
          name: "email",
          placeHolder: t("companyScreen.table.email"),
          type: "Input",
          colSize: 8,
          props: { id: "CompaniesFilterEmail" },
        },
      ],
    },
  ];
};
