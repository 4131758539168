import { checkNIF } from "../../util/util";
const fullName = t => ({
  fields: [
    {
      name: "name",
      title: t("userScreen.fields.name"),
      placeHolder: t("userScreen.fields.name"),
      type: "Input",
      colSize: 8,
      validation: {
        required: false
      }
    },
    {
      name: "surnames",
      title: t("userScreen.fields.surnames"),
      placeHolder: t("userScreen.fields.surnames"),
      type: "Input",
      colSize: 8,
      validation: {
        required: false
      },
    }
  ]
});

const password = t => ({
  fields: [
    {
      name: "password",
      title: t("register.password"),
      placeHolder: t("register.password"),
      type: "Input",
      colSize: 8,
      validation: {
        required: true,
        regexType: "password",
        errorMessage: t("recoverPassword.validFormat")
      },
      props: {
        type: "password",
        autoComplete: "new-password"
      }
    },
    {
      name: "passwordRepeat",
      title: t("register.passwordRepeat"),
      placeHolder: t("register.passwordRepeat"),
      type: "Input",
      colSize: 8,
      validation: {
        required: true,
        custom: values => {
          const password = values.password;
          const passwordRepeat = values.passwordRepeat;

          return password === passwordRepeat;
        },
        errorMessage: t("register.passwordsDontMatch")
      },
      props: {
        type: "password",
        autoComplete: "new-password"
      }
    }
  ]
});

const emailAndStatus = t => ({
  fields: [
    {
      name: "email",
      title: t("userScreen.fields.email"),
      placeHolder: t("userScreen.fields.email"),
      type: "Input",
      colSize: 8,
      props: {
        readOnly: true,
        tabIndex: -1
      }
    }
    // {
    //   name: "status",
    //   title: t("userScreen.fields.status"),
    //   placeHolder: t("userScreen.fields.status"),
    //   type: "Select",
    //   data: [],
    //   colSize: 4
    // }
  ]
});

const addressAndPhone = t => ({
  fields: [
    {
      name: "address",
      title: t("userScreen.fields.address"),
      placeHolder: t("userScreen.fields.address"),
      type: "Input",
      colSize: 10
    },
    {
      name: "phone",
      title: t("userScreen.fields.phone"),
      placeHolder: t("userScreen.fields.phone"),
      type: "Input",
      colSize: 6,
      validation: {
        required: false,
        regexType: "phone",
        errorMessage: t("validation.phone")
      }
    }
  ]
});

const ubication = (t, countries, provinces) => ({
  fields: state => [
    {
      name: "country",
      title: t("userScreen.fields.country"),
      placeHolder: t("userScreen.fields.country"),
      type: "Select",
      colSize: 5,
      data: countries.map(countryCode => ({
        text: t(`countries.${countryCode}`),
        value: countryCode
      }))
    },
    {
      name: "region",
      title: t("userScreen.fields.region"),
      placeHolder: t("userScreen.fields.region"),
      colSize: 5,
      type: state.country == 'ES' ? "Select" : "Input",
      data: state.country == 'ES' ? provinces.map(province => ({
        text: province.name,
        value: province.name
      })) : null,
      props: {
        clearable: true
      },
    },
    {
      name: "city",
      title: t("userScreen.fields.city"),
      placeHolder: t("userScreen.fields.city"),
      type: "Input",
      colSize: 6
    }
  ]
});

const terms = t => ({
  fields: [
    {
      name: "terms_and_conditions",
      type: "Checkbox",
      title: t("config.terms.accept"),
      colSize: 16,
      value: false,
      validation: {
        required: true
      }
    }
  ]
});

export default {
  admin: t => [fullName(t), password(t), emailAndStatus(t), terms(t)],
  company: (t, countries, provinces) => [
    fullName(t),
    { fields: [...password(t).fields] },
    {
      fields: [
        {
          name: "dni",
          title: t("userScreen.fields.dni"),
          placeHolder: t("userScreen.fields.dni"),
          type: "Input",
          colSize: 8,
          validation: {
            required: true,
            custom: vals => {
              return checkNIF(vals.dni, ["DNI", "NIE", 'CIF']);
            },
            errorMessage: t("commons.invalidCiforNif")
          }
        },
        ...emailAndStatus(t).fields
      ]
    },

    {
      fields: [
        {
          name: "contacto",
          title: t("companyDetail.contact"),
          type: "Label"
          // colSize: 'ten'
        }
      ]
    },

    addressAndPhone(t),
    ubication(t, countries, provinces),
    {
      fields: [
        {
          name: "companyName",
          title: t("register.companyName"),
          type: "Input",
          colSize: 10,
          props: {
            readOnly: true
          }
        }
      ]
    },
    terms(t)
  ],
  planner: (t, countries, provinces) => [
    fullName(t),
    { fields: [...password(t).fields] },
    {
      fields: [
        ...emailAndStatus(t).fields,
        {
          name: "cif",
          title: t("companyDetail.cifnif"),
          placeHolder: t("companyDetail.cifnif"),
          type: "Input",
          colSize: 8,
          validation: {
            custom: values => {
              return values.cif ? checkNIF(values.cif, ["cif", "nif"]) : true;
            },
            errorMessage: t("companyDetail.messages.invalidCiforNif")
          }
        }
      ]
    },
    {
      fields: [
        ...addressAndPhone(t).fields,
        {
          name: "zip_code",
          title: t("userScreen.fields.zip_code"),
          placeHolder: t("userScreen.fields.zip_code"),
          type: "Input",
          colSize: 8
        }
      ]
    },
    ubication(t, countries, provinces),
    terms(t)
  ],
  pointOfSale: t => [
    fullName(t),
    { fields: [...password(t).fields] },
    {
      fields: [
        ...emailAndStatus(t).fields,
        {
          name: "phone",
          title: t("userScreen.fields.phone"),
          placeHolder: t("userScreen.fields.phone"),
          type: "Input",
          colSize: 8,
          validation: {
            required: false,
            regexType: "phone",
            errorMessage: t("validation.phone")
          }
        }
      ]
    },
    terms(t)
  ],
  qrReader: t => [fullName(t), password(t), terms(t)]
};
