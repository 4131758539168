export const tableHeaders = t => {
  return [
    {
      content: t("raceClassifications.table.modality"),
      accessor: "name"
    },
    {
      content: t("raceClassifications.table.date"),
      accessor: "date"
    },
    {
      content: t("raceClassifications.table.count"),
      accessor: "total"
    },
    {
      content: t("raceClassifications.table.lastUpdate"),
      accessor: "last_update"
    },
    {
      content: "",
      accessor: "remove"
    }
  ];
};
