import React, { Component } from "react";
import Form from "../Form";
import {
  Button,
  Modal,
  Grid,
  Loader,
  Dimmer,
  Message,
  Flag,
  Divider
} from "semantic-ui-react";
import form from "./form";
import UserApi from "../../apis/users";
import { connect } from "react-redux";
import { DAlert } from "../Dalert";
import { modalHandler } from "../DModal";
import { withTranslation } from "react-i18next";

const styles = {
  //General
  HorizontalCentering: {
    height: "100%",
    display: "flex",
    justifyContent: "center"
  }
};

@withTranslation()
@connect(state => ({
  userId: state.user.id
}))
export default class preferencesModal extends Component {
  constructor() {
    super();
    this.state = {
      data: {},
      isLoading: false,
      animate: "",
      showValidation: false,
      message: "",
      reset: {},
      form: {}
    };
  }

  async changePasswordHandler() {
    const { form } = this.state;
    const { userId, t } = this.props;

    if (form.ISFORMVALID) {
      if (form.password === form.repeatPassword) {
        this.setState({ error: false, isLoading: true });
        try {
          const params = {
            current_password: form.currentPassword,
            new_password: form.password
          };
          await UserApi.changePasword(userId, params).then(res => {
            this.setState({
              isLoading: false
            });
            if (res.success) {
              DAlert.success({
                title: t("commons.savedSuccess"),
                message: t(`recoverPassword.changedMessage`)
              });
            } else if (res.success === false) {
              DAlert.error({
                title: t("commons.INVALID_PASSWORD.header"),
                message: t(`commons.INVALID_PASSWORD.message`)
              });
            } else {
              DAlert.error({
                title: t("commons.networkError"),
                message: t("commons.serverErrorMessage")
              });
            }
            // setTimeout(()=>{
            //   modalHandler.close();
            // },1000)
          });
        } catch (err) {
          this.setState({
            isLoading: false,
            isValid: false
          });
        }
        modalHandler.close();
      } else {
        this.setState({
          error: true
        });
      }
    } else {
      this.setState({
        showValidation: true
      });
    }
  }

  async updateUser(lng){
    const params = {
      language: lng
    }
    const { userId, t } = this.props;
    await UserApi.changeLanguage(userId, params).then(res => {
      if (res){
        DAlert.success({
          title: t("commons.savedSuccess")
        });
      }else{
        DAlert.error({
          title: t("commons.networkError"),
          message: t("commons.serverErrorMessage")
        });
      }
    })
    
  }

  render() {
    const { t, i18n } = this.props;
    const { data, showValidation, error, isLoading } = this.state;

    return (
      <Modal.Description>
        <Grid padded>
          <Grid.Row>
            <Grid.Column width="16">
              <h4>{t("commons.language")}</h4>
              <Button.Group basic style={{ marginTop: 1 }}>
                <Button
                  icon
                  onClick={() => {
                    i18n.changeLanguage("es")
                    modalHandler.refreshOptions({})
                    this.updateUser('es')
                }}
                  style={styles.HorizontalCentering}
                >
                  <Flag name="es" style={{ margin: 1 }} />
                </Button>
                <Button
                  icon
                  onClick={() => {
                  i18n.changeLanguage("en")
                  modalHandler.refreshOptions({})
                  this.updateUser('en')
                }}
                  style={styles.HorizontalCentering}
                >
                  <Flag name="gb" style={{ margin: 1 }} />
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width="16">
              <h4>{t("commons.changePassword")}</h4>
              <Form
                defaultState={data}
                showValidation={showValidation}
                onFormChange={data => {
                  this.setState({ form: data });
                }}
                fields={form(t)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Column width="16">
            {error && (
              <Message
                error
                style={{ whiteSpace: "pre-line" }}
                content={t("recoverPassword.NOT_SAME")}
              />
            )}
            <Button
              color="vk"
              fluid
              onClick={this.changePasswordHandler.bind(this)}
            >
              {t("commons.send")}
            </Button>
          </Grid.Column>
        </Grid>
        {isLoading ? (
          <Dimmer active>
            <Loader />
          </Dimmer>
        ) : null}
      </Modal.Description>
    );
  }
}
