import React, { useState, useEffect } from "react";
import { Modal, Grid, Icon, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const optionsDefault = {
  header: "",
  basic: false,
  size: "large",
  content: null,
  actions: null,
  onYes: null,
  onNo: null,
};

class ModalHandler {
  constructor() {
    this.toggle = null;
    this.isOpen = null;
    this.setOptions = null;
    this.options = null;
  }

  change() {
    this.toggle(!this.isOpen);
  }

  open(options) {
    this.toggle(true);
    this.setOptions({
      ...optionsDefault,
      ...options,
    });
  }

  close() {
    this.toggle(false);
    this.setOptions(optionsDefault);
  }

  refreshOptions(options) {
    this.setOptions({
      ...this.options,
      ...options,
    });
  }

  setToggle(t, isOpen, options, setOptions) {
    this.toggle = t;
    this.isOpen = isOpen;
    this.options = options;
    this.setOptions = setOptions;
  }
}

const modalHandler = new ModalHandler();

export default function DModal() {
  const [isOpen, toggle] = useState(false);
  const [options, setOptions] = useState(optionsDefault);
  const [t] = useTranslation();

  modalHandler.setToggle(toggle, isOpen, options, setOptions);

  const closeModal = () => {
    toggle(false);
    setOptions(optionsDefault);
  };

  return (
    <Modal
      onUnmount={() => closeModal()}
      open={isOpen}
      basic={options.basic}
      size={options.size}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      {options.header && (
        <Modal.Header>
          <Grid columns={2}>
            <Grid.Column textAlign="left">{options.header}</Grid.Column>
            <Grid.Column textAlign="right">
              <Icon name="close" link onClick={() => toggle(false)} />
            </Grid.Column>
          </Grid>
        </Modal.Header>
      )}
      <Modal.Content>{options.content}</Modal.Content>
      <Modal.Actions>
        {options.actions ? (
          options.actions
        ) : (
          <>
            <Button
              className="ModalNo"
              onClick={() => (options.onNo ? options.onNo() : toggle(false))}
              color="google plus"
            >
              <Icon name="remove" /> {t("commons.no")}
            </Button>
            <Button
              className="ModalYes"
              onClick={() => (options.onYes ? options.onYes() : null)}
              color="instagram"
            >
              <Icon name="checkmark" /> {t("commons.yes")}
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
}

export { modalHandler };
