import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Container, Divider, Dimmer, Loader } from "semantic-ui-react";

import { useCookies } from "react-cookie";
import decode from "jwt-decode";
import provincesApi from "../../apis/regions";
import Form from "../../components/Form";
import { DAlert } from "../../components/Dalert";
import ButtonGroup from "../../components/ButtonGroup";

import { copyRaceFrom, raceDetailFormCopy } from "./util";
import companiesApi from "../../apis/companies";
import racesApi from "../../apis/races";
import { getCountries } from "../../reducers/countries";

const CopyRace = (props) => {
  const store = useStore();
  const [races, setRaces] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [raceTypes, setRaceTypes] = useState([]);
  const [selectedRace, setSelectedRace] = useState();
  const [raceForm, setRaceForm] = useState();
  const [showValidation, setShowValidation] = useState();
  const [role, setRole] = useState();
  const [provinces, setProvinces] = useState();
  const [t] = useTranslation();
  const countries = useSelector((state) => state.countries.countries);
  const dispatch = useDispatch();

  const [_, __, removeCookie] = useCookies(["token"]);

  const { user } = store.getState();
  useEffect(() => {
    /** @TODO In here it should get all the race, this endpoint get the races paginated. */
    if (_.token) {
      setRole(user.role);
      getData();
      fetchProvinces();
    } else {
      window.location.replace("/");
    }
  }, []);

  const getData = async () => {
    const response = await racesApi.fetchRaces({ pageSize: -1, type: -1, inscriptions_count: 0 });

    let companies = null;
    if (user.role && user.role === "ADMINISTRATOR") {
      companies = (
        await companiesApi.get({ sort: "ASC", field: "name", pageSize: -1 })
      ).rows.map((company) => ({
        value: company.id,
        text: company.name,
      }));
    }

    const raceTypes = (await racesApi.fetchRaceTypes()).map((raceT) => ({
      value: raceT.id,
      text: raceT.type,
    }));

    dispatch(getCountries());

    setRaces(response.rows);
    setCompanies(companies);
    setRaceTypes(raceTypes);
  };

  const fetchProvinces = async () => {
    const provinces = await provincesApi.fetchRegions();
    setProvinces(
      provinces.map((provinces) => ({
        name: provinces.name,
        slug: provinces.slug,
      }))
    );
  };

  const save = async () => {
    function clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
    }

    if (!raceForm.ISFORMVALID) {
      setShowValidation(true);
    } else {
      raceForm.original_race_id = raceForm.id;
      delete raceForm.id;
      clean(raceForm);
      await racesApi.copy(raceForm).then(async (res) => {
        props.history.push({
          pathname: `/race/${res.id}`,
          state: { id: res.id },
        });
        if (raceForm.prices) {
          DAlert.info({ title: t("racesScreen.copyRacePrice") });
        }
      });
    }
  };

  if (_.token) {
    return (
      <Container>
        <Form
          fields={copyRaceFrom(t, races)}
          onFormChange={(data) => {
            if (!!data.race) {
              const race = races.find((race) => race.id === data.race);

              delete race.start_date;
              delete race.finish_date;
              race.last_edition = race.name;

              const region = provinces.find(province => province.name === race.region);
              setSelectedRace({ ...race, region: region ? region.slug : race.region });
              setRaceForm({ ...race, region: region ? region.slug : race.region });
            } else {
              setSelectedRace();
            }
          }}
        />
        <Form
          defaultState={selectedRace}
          onFormChange={(val) => {
            if (val && !val.modalities) {
              val.prices = false
              val.advancedModalities = false
              val.categories = false
              val.teamTypes = false
              val.attributes = false
            }
            setRaceForm({ ...selectedRace, ...val })
          }}
          fields={raceDetailFormCopy(
            t,
            { raceType: raceTypes, companies, countries, provinces, activityType: selectedRace?.activityType },
            role
          )}
          showValidation={showValidation}
        />
        <br />
        <Divider />
        <ButtonGroup onSave={save} />
      </Container>
    );
  } else {
    return (
      <Dimmer>
        <Loader></Loader>
      </Dimmer>
    );
  }
};

export default CopyRace;
