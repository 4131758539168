import React from "react";
import SearchInput from "../../components/SearchInput";
import moment from "moment";

window.moment = moment;

export const getTableHeaders = (t, role) => {
  if (role !== "COMPANY") {
    return [
      {
        content: t("racesScreen.table.date"),
        accessor: "start_date",
      },
      {
        content: t("racesScreen.table.name"),
        accessor: "name",
      },
      {
        content: t("racesScreen.table.company"),
        accessor: "company",
      },
      {
        content: t("racesScreen.table.planners"),
        accessor: "planners",
      },
      // {
      //   content: t("racesScreen.table.email"),
      //   accessor: "email",
      // },
      // {
      //   content: t("racesScreen.table.locality"),
      //   accessor: "start_city",
      // },
      // {
      //   content: t("racesScreen.table.type"),
      //   accessor: "type",
      // },
      {
        content: t("racesScreen.table.published"),
        accessor: "is_published",
      },
    ];
  } else {
    return [
      {
        content: t("racesScreen.table.date"),
        accessor: "start_date",
      },
      {
        content: t("racesScreen.table.name"),
        accessor: "name",
      },
      {
        content: t("racesScreen.table.planners"),
        accessor: "planners",
      },
      // {
      //   content: t("racesScreen.table.email"),
      //   accessor: "email",
      // },
      // {
      //   content: t("racesScreen.table.locality"),
      //   accessor: "start_city",
      // },
      // {
      //   content: t("racesScreen.table.type"),
      //   accessor: "type",
      // },
      {
        content: t("racesScreen.table.published"),
        accessor: "is_published",
      },
    ];
  }
};

export const getFilterFields = (
  t,
  companies,
  planners,
  raceTypes,
  regions,
  role
) => {
  if (role !== "ADMINISTRATOR") {
    return [
      {
        div: "row",
        fields: [
          {
            name: "date",
            placeHolder: t("racesScreen.table.date"),
            type: "DatePicker",
            props: {
              id: "RaceFilterDate",
            },
          },
          {
            name: "name",
            placeHolder: t("racesScreen.table.name"),
            type: "Input",
            props: {
              id: "RaceFilterName",
            },
          },
          // {
          //   name: "planner",
          //   placeHolder: t("racesScreen.table.planners"),
          //   type: "Select",
          //   data: planners
          // }
          {
            name: "email",
            placeHolder: t("racesScreen.table.email"),
            type: "Input",
            props: {
              id: "RaceFilterEmail",
            },
          },
        ],
      },
      {
        div: "row",
        fields: [
          {
            name: "raceTypeId",
            placeHolder: t("racesScreen.table.type"),
            type: "Select",
            data: raceTypes,
            props: {
              id: "RaceFilterType",
            },
          },
          {
            name: "city",
            placeHolder: t("racesScreen.table.locality"),
            type: "Input",
            props: {
              id: "RaceFilterCity",
            },
          },
          {
            name: "region",
            placeHolder: t("racesScreen.table.region"),
            type: "Input",
            // type: "Select",
            // data: regions,
            props: {
              id: "RaceFilterRegion",
            },
          },
        ],
      },
    ];
  } else {
    return [
      {
        div: "row",
        fields: [
          {
            name: "date",
            placeHolder: t("racesScreen.table.date"),
            type: "DatePicker",
            props: {
              id: "RaceFilterDate",
            },
          },
          {
            id: "RaceFilterName",
            name: "name",
            placeHolder: t("racesScreen.table.name"),
            type: "Input",
            props: {
              id: "RaceFilterName",
            },
          },
          {
            name: "companyId",
            placeHolder: t("racesScreen.table.company"),
            type: "Select",
            data: companies,
            props: {
              id: "RaceFilterCompany",
            },
          },
          {
            name: "planner",
            placeHolder: t("racesScreen.table.planners"),
            type: "Select",
            data: planners,
            props: {
              id: "RaceFilterPlanner",
            },
          },
        ],
      },
      {
        div: "row",
        fields: [
          {
            name: "email",
            placeHolder: t("racesScreen.table.email"),
            type: "Input",
            props: {
              id: "RaceFilterEmail",
            },
          },
          {
            name: "raceTypeId",
            placeHolder: t("racesScreen.table.type"),
            type: "Select",
            data: raceTypes,
            props: {
              id: "RaceFilterType",
            },
          },
          {
            name: "region",
            placeHolder: t("racesScreen.table.region"),
            type: "Input",
            // type: "Select",
            // data: regions,
            props: {
              id: "RaceFilterRegion",
            },
          },
          {
            name: "city",
            placeHolder: t("racesScreen.table.locality"),
            type: "Input",
            props: {
              id: "RaceFilterCity",
            },
          },
        ],
      },
    ];
  }
};

export const raceDetailForm = (
  t,
  { raceType, companies, countries, provinces, activityTypes, comesFrom }
) => {
  const nullObject = { type: "Null", colSize: 0 };
  
  return [
    {
      fields: [
        {
          name: "activityTypeId",
          title: t("racesScreen.detail.activityType"),
          placeHolder: t("racesScreen.detail.activityType"),
          type: "Select",
          colSize: 16,
          data: activityTypes,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
          props: {
            id: "RaceCreateActivityType",
            disabled: comesFrom ? true : false,
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "name",
          title: t("racesScreen.detail.name"),
          placeHolder: t("racesScreen.detail.name"),
          type: "Input",
          colSize: 10,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
          props: {
            id: "RaceCreateName",
          },
        },
        companies.length > 0
          ? {
            name: "company_id",
            title: t("racesScreen.detail.company"),
            placeHolder: t("racesScreen.detail.company"),
            type: "Select",
            data: companies ? companies : [],
            colSize: 10,
            validation: {
              required: true,
              errorMessage: t("commons.fieldRequired")
            },
            props: {
              id: "RaceCreateCompany",
            },
          }
          : nullObject,
      ],
    },
    {
      fields: (state) => [
        {
          name: "type_id",
          title: !state.activityTypeId || state.activityTypeId === 1 ? t("racesScreen.detail.raceType") : t("racesScreen.detail.sportType"),
          placeHolder: !state.activityTypeId || state.activityTypeId === 1 ? t("racesScreen.detail.raceType") : t("racesScreen.detail.sportType"),
          type: "Select",
          data: raceType ? raceType : [],
          colSize: 10,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
          props: {
            id: "RaceCreateType",
          },
        },
        {
          name: "start_date",
          title: t("racesScreen.detail.startDate"),
          placeHolder: t("racesScreen.detail.startDate"),
          type: "DatePicker",
          colSize: 10,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
            /*
            custom: values => {
              const startDate = moment(values.start_date);

              if (startDate.isValid()) {
                return startDate.isSameOrAfter(moment(new Date()).startOf("day"));
              }

              return false;
            },
            errorMessage: t("racesScreen.errors.startDateGreaterThanToday")
            */
          },
          props: {
            id: "RaceCreateStartDate",
          },
        },
        {
          name: "finish_date",
          title: t("racesScreen.detail.endDate"),
          placeHolder: t("racesScreen.detail.endDate"),
          type: "DatePicker",
          colSize: 10,
          value: null,
          validation: {
            custom: (values) => {
              const startDate = moment(values.start_date);
              const finishDate = moment(values.finish_date);

              if (finishDate.isValid()) {
                return finishDate.isSameOrAfter(startDate);
              }

              return true;
            },
            errorMessage: t("racesScreen.errors.endDateGreaterThanStartDate"),
          },
          props: {
            id: "RaceCreateFinishDate",
          },
        },
      ],
    },
    {
      fields: (state) => [
        {
          name: "country",
          title: t("racesScreen.detail.country"),
          placeHolder: t("racesScreen.detail.country"),
          type: "Select",
          colSize: 10,
          data: countries.map((country) => ({
            text: t(`countries.${country}`),
            value: country,
          })),
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
          props: {
            id: "RaceCreateCountry",
          },
        },
        {
          name: "region",
          type: state.country == 'ES' ? "Select" : "Input",
          data: state.country == 'ES' ? provinces.map(province => ({
            text: province.name,
            value: province.slug
          })) : null,
          props: {
            clearable: true
          },
          title: t("raceEdit.basicTabContent.region"),
          placeHolder: t("raceEdit.basicTabContent.region"),
          validation: {
            required: true,
            regexType: "text",
            errorMessage: t("commons.fieldRequired")
          },
          colSize: 6
        },
        {
          name: "start_city",
          title: t("racesScreen.detail.city"),
          placeHolder: t("racesScreen.detail.city"),
          type: "Input",
          colSize: 10,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
          props: {
            id: "RaceCreateCity",
          },
        },
      ],
    },
  ];
};

export const copyRaceFrom = (t, races) => [
  {
    fields: [
      {
        name: "race",
        title: t("racesScreen.race"),
        placeHolder: t("racesScreen.race"),
        type: "Select",
        colSize: 16,
        data: races.map((race) => ({
          text: race.name,
          value: race.id,
        })),
      },
    ],
  },
];

const nullObject = { type: "Null", colSize: 4 };
export const raceDetailFormCopy = (t, { raceType, companies, countries, provinces, activityType }, role) => [
  role === "ADMINISTRATOR"
    ? {
      fields: [
        {
          name: "name",
          title: t("racesScreen.detail.name"),
          placeHolder: t("racesScreen.detail.name"),
          type: "Input",
          colSize: 10,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
        },
        {
          name: "company_id",
          title: t("racesScreen.detail.company"),
          placeHolder: t("racesScreen.detail.company"),
          type: "Select",
          data: companies ? companies : [],
          colSize: 10,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
        },
      ],
    }
    : {
      fields: [
        {
          name: "name",
          title: t("racesScreen.detail.name"),
          placeHolder: t("racesScreen.detail.name"),
          type: "Input",
          colSize: 16,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          },
        }
      ],
    },
  {
    fields: [
      {
        name: "type_id",
        title: !activityType || activityType.type === "RACE" ? t("racesScreen.detail.raceType") : t("racesScreen.detail.sportType"),
        placeHolder: !activityType || activityType.type === "RACE" ? t("racesScreen.detail.raceType") : t("racesScreen.detail.sportType"),
        type: "Select",
        data: raceType ? raceType : [],
        colSize: 10,
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired")
        },
      },
      {
        name: "start_date",
        title: t("racesScreen.detail.startDate"),
        placeHolder: t("racesScreen.detail.startDate"),
        type: "DatePicker",
        props: {
          id: '0'
        },
        colSize: 10,
        validation: {
          custom: (values) => {
            if (moment(values.start_date).isValid()) {
              return moment(values.start_date).isAfter(moment(new Date()));
            }
            return false;
          },
          errorMessage: t("racesScreen.errors.startDateGreaterThanToday"),
        },
      },
      {
        name: "finish_date",
        title: t("racesScreen.detail.endDate"),
        placeHolder: t("racesScreen.detail.endDate"),
        type: "DatePicker",
        props: {
          id: '1'
        },
        colSize: 10,
        value: null,
        validation: {
          custom: (values) => {
            if (moment(values.finish_date).isValid()) {
              return moment(values.finish_date).isSameOrAfter(
                values.start_date
              );
            }
            return true;
          },
          errorMessage: t("racesScreen.errors.endDateGreaterThanStartDate"),
        },
      },
    ],
  },
  {
    fields: (state) => [
      {
        name: "country",
        title: t("racesScreen.detail.country"),
        placeHolder: t("racesScreen.detail.country"),
        type: "Select",
        colSize: 10,
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired")
        },
        data: countries.map((countryCode) => ({
          text: t(`countries.${countryCode}`),
          value: countryCode,
        })),
      },
      {
        name: "region",
        type: state.country == 'ES' ? "Select" : "Input",
        data: state.country == 'ES' ? provinces.map(province => ({
          text: province.name,
          value: province.slug
        })) : null,
        props: {
          clearable: true
        },
        title: t("racesScreen.detail.region"),
        placeHolder: t("racesScreen.detail.region"),
        colSize: 10,
        validation: {
          required: true,
          regexType: "text",
          errorMessage: t("commons.fieldRequired")
        },
      },
      {
        name: "start_city",
        title: t("racesScreen.detail.city"),
        placeHolder: t("racesScreen.detail.city"),
        type: "Input",
        colSize: 10,
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired")
        },
      },
    ],
  },
  {
    fields: [
      {
        name: "last_edition",
        title: t("racesScreen.detail.lastEdition"),
        placeHolder: t("racesScreen.detail.lastEdition"),
        type: "Input",
        colSize: 16,
        validation: {
          required: true,
        },
        props: {
          readOnly: true,
        },
      },
    ],
  },
  {
    fields: [
      {
        type: "Label",
        title: t("racesScreen.detail.optionsToCopy"),
        color: "#2185d0",
      },
    ],
  },
  {
    fields: [
      {
        name: "raceAdvancedOptions",
        title: t("racesScreen.detail.raceAdvanced"),
        placeHolder: t("racesScreen.detail.raceAdvanced"),
        colSize: 4,
        type: "Checkbox",
        props: {
          style: {
            marginTop: 10,
          },
        },
      },
      {
        name: "modalities",
        title: t("racesScreen.detail.modalities"),
        placeHolder: t("racesScreen.detail.modalities"),
        colSize: 4,
        type: "Checkbox",
        props: {
          style: {
            marginTop: 10,
          },
        },
      },
    ],
  },
  {
    fields: (state) => [
      nullObject,
      {
        name: "advancedModalities",
        title: t("racesScreen.detail.advancedModalities"),
        placeHolder: t("racesScreen.detail.advancedModalities"),
        colSize: 4,
        type: "Checkbox",
        props: {
          disabled: !state.modalities,
          style: {
            marginTop: 10,
          },
        },
      },
    ],
  },
  {
    fields: (state) => [
      nullObject,
      {
        name: "categories",
        title: t("racesScreen.detail.categories"),
        placeHolder: t("racesScreen.detail.categories"),
        colSize: 4,
        type: "Checkbox",
        props: {
          disabled: !state.modalities,
          style: {
            marginTop: 10,
          },
        },
      },
    ],
  },
  {
    fields: (state) => [
      nullObject,
      {
        name: "teamTypes",
        title: t("racesScreen.detail.teamTypes"),
        placeHolder: t("racesScreen.detail.teamTypes"),
        colSize: 4,
        type: "Checkbox",
        props: {
          disabled: !state.modalities,
          style: {
            marginTop: 10,
          },
        },
      },
    ],
  },
  {
    fields: (state) => [
      nullObject,
      {
        name: "attributes",
        title: t("racesScreen.detail.attributes"),
        placeHolder: t("racesScreen.detail.attributes"),
        colSize: 4,
        type: "Checkbox",
        props: {
          disabled: !state.modalities,
          style: {
            marginTop: 10,
          },
        },
      },
    ],
  },
  {
    fields: (state) => [
      nullObject,
      {
        name: "prices",
        title: t("racesScreen.detail.prices"),
        placeHolder: t("racesScreen.detail.prices"),
        colSize: 4,
        type: "Checkbox",
        props: {
          disabled: !state.modalities,
          style: {
            marginTop: 10,
          },
        },
      },
    ],
  },
];
