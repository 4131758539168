import React, { Component } from "react";
import {
  Container,
  Grid,
  Divider,
} from "semantic-ui-react";
import Form from "../../components/Form";
import { withTranslation } from "react-i18next";
import inscriptionsApi from "../../apis/inscriptions";
import ButtonGroup from "../../components/ButtonGroup";
import { formFields } from "./util";

@withTranslation()
export default class InscriptionDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isNew: false,
      showValidation: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const queryParams = this.props.location.search;
    const f = !queryParams
      ? true
      : new URLSearchParams(queryParams.split("?").pop()).get("f");

    inscriptionsApi.getInscription(this.props.match.params.id, { f })
      .then(response => {
        this.setState({ data: response })
      });

  }

  render() {
    const { t } = this.props;
    const {
      isNew,
      showValidation,
    } = this.state;

    return (
      <div>
        <Container style={{ padding: 20 }}>
          <Grid stackable divided="vertically">
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form
                  defaultState={this.state.data}
                  onFormChange={data =>
                    this.setState({ data: { ...this.state.data, ...data } })
                  }
                  fields={formFields(t)}
                  showValidation={showValidation}
                />

              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider />
          <ButtonGroup url="/inscriptions" />
        </Container>
      </div>
    );
  }
}
