import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container,
  Modal,
  Grid,
  Button,
  Divider,
  Transition,
  Dimmer,
  Loader
} from "semantic-ui-react";
import moment from "moment";
import { withTranslation } from "react-i18next";

import Form from "../../components/Form";
import MyTable from "../../components/DTable";
import { DAlert } from "../../components/Dalert";
import ButtonGroup from "../../components/ButtonGroup";
import { modalHandler } from "../../components/DModal";
import AssignPlaceButton from "../../components/AssignPlaceButton";

import authApi from "../../apis/auth";
import clientsApi from "../../apis/clients";
import usersApi from "../../apis/users";
import clubApi from "../../apis/clubs";
import inscriptionsApi from "../../apis/inscriptions";
import provincesApi from "../../apis/regions";
import { formFields, tableHeadersInscriptions } from "./util";
import { getCountries } from "../../reducers/countries";

@connect(
  state => ({
    countries: state.countries.countries
  }),
  dispatch => ({
    actions: bindActionCreators({ getCountries }, dispatch)
  })
)
@withTranslation()
export default class ClientDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      inscriptions: [],
      isNew: false,
      showValidation: false,
      showUsers: false,
      changingPassword: false,
      provinces: []
    };
  }

  componentDidMount() {
    const { actions, countries } = this.props;

    actions.getCountries(countries);
    clubApi.get().then(clubs => {
      this.setState({ clubs: clubs.map(club => ({ title: club.name })) });
    });
    provincesApi.fetchRegions().then(provinces => {
      this.setState({
        provinces: provinces.map(provinces => {return {name:provinces.name, slug:provinces.slug}})
      });
    });
    this.getData();
  }

  async getData(params) {
    if (this.props.match.params.id) {
      const data = await clientsApi.getClient(this.props.match.params.id);
      const inscriptions = await inscriptionsApi.getAllByAthleteId(
        this.props.match.params.id,
        params
      );

      this.setState({
        data: {
          ...data
        },
        inscriptions: {
          ...inscriptions
        },
        showUsers: true
      });
    } else {
      this.setState({ isNew: true });
      return;
    }
  }

  async create() {
    const { data } = this.state;
    const { t } = this.props;

    this.setState({ showValidation: true });

    if (data.ISFORMVALID) {
      const result = await clientsApi.create(data);

      if (result.id) {
        this.setState({ isNew: false, showUsers: true, data: result });
        DAlert.success({ title: t("commons.savedSuccess") });
        this.props.history.push(`/company/${result.id}`);
      } else {
        DAlert.error({ title: t("commons.errorSaving") });
      }

      return;
    }
  }

  async update() {
    const { data } = this.state;
    const { t } = this.props;

    this.setState({ showValidation: true });
    if (data.ISFORMVALID) {
      const result = await clientsApi.update(data.id, data);

      if (result.id) {
        DAlert.success({ title: t("commons.savedSuccess") });

        this.setState({ data: result });
      } else {
        DAlert.error({ title: t("commons.errorSaving") });
      }

      return;
    }
  }

  async save() {
    const { data } = this.state;
    const { t } = this.props;

    this.setState({ showValidation: true });

    if (data.ISFORMVALID) {
      try {
        const result = await clientsApi.update(data.user_id, {
          ...data
        });

        if (result.id) {
          DAlert.success({ title: t("commons.savedSuccess") });

          this.setState({
            data: {
              ...result
            }
          });
        }
      } catch (error) {
        DAlert.error({ title: t("commons.errorSaving") });
      }

      return;
    }
  }

  modalOptions() {
    const { t } = this.props;
    const { changingPassword, data } = this.state;
    const email = data?.email;
    const userId = data?.user_id;

    return {
      header: t("clientScreen.resetPassword.beforeHeader"),
      content: (
        <Modal.Description>
          {t("clientScreen.resetPassword.beforeContent", { email })}
          {changingPassword && (
            <Dimmer active style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
              <Loader />
            </Dimmer>
          )}
        </Modal.Description>
      ),
      onYes: async () => {
        try {
          await this.setState({ changingPassword: true });
          modalHandler.refreshOptions({ ...this.modalOptions() });
          await authApi.recoverPassword({ email, userId, channel_id: 1});
          this.setState({ changingPassword: false });

          modalHandler.open({
            header: null,
            size: "small",
            content: t("login.recoverEmailSent"),
            actions: (
              <Grid>
                <Grid.Column width="16">
                  <Button
                    fluid
                    color="vk"
                    onClick={() => {
                      modalHandler.close();
                    }}
                  >
                    {t("commons.ok")}
                  </Button>
                </Grid.Column>
              </Grid>
            )
          });
        } catch (err) {
          this.setState({ changingPassword: false });
        }
      }
    };
  }

  async changeStatus(status) {
    const { t } = this.props;

    modalHandler.open({
      header: null,
      content: <Modal.Description>{t("commons.areYouSure")}</Modal.Description>,
      basic: false,
      size: "tiny",
      onYes: async () => {
        try {
          const result = await usersApi.changeStatus(
            this.state.data.user_id,
            status
          );
          modalHandler.close();
          this.setState({ data: { ...result } });
          DAlert.success({ title: t("commons.savedSuccess") });
        } catch (error) {
          DAlert.error({ title: t("commons.errorSaving") });
        }
      }
    });
  }

  render() {
    const { t, countries } = this.props;
    const {
      isNew,
      showValidation,
      inscriptions,
      showUsers,
      clubs,
      provinces
    } = this.state;

    return (
      <div>
        <Container style={{ padding: 20 }}>
          <Grid stackable divided="vertically">
            <Grid.Row columns={isNew ? 1 : 2}>
              <Grid.Column>
                <Form
                  defaultState={this.state.data}
                  onFormChange={(data) =>
                    this.setState({ data: { ...this.state.data, ...data } })
                  }
                  fields={formFields(t, clubs, countries, provinces)}
                  showValidation={showValidation}
                />

                {!isNew && (
                  <Grid.Row
                    columns={3}
                    verticalAlign={"middle"}
                    style={{ marginTop: 20 }}
                    centered
                  >
                    <Grid columns={3}>
                      <Grid.Column>
                        <Button
                          fluid
                          color="vk"
                          onClick={() => modalHandler.open(this.modalOptions())}
                        >
                          {t("clientScreen.detail.changePassword")}
                        </Button>
                      </Grid.Column>
                    </Grid>
                  </Grid.Row>
                )}
              </Grid.Column>

              <Transition visible={showUsers} animation="scale" duration={500}>
                <Grid.Column style={{ marginTop: "-2%" }}>
                  <h3>{t("clientScreen.detail.inscriptions")}</h3>
                  <MyTable
                    headers={tableHeadersInscriptions(t)}
                    contentData={inscriptions.rows}
                    totalData={inscriptions.count}
                    parseData={(row) => ({
                      inscriptionDate: moment(row.inscriptionDate).format("L"),
                      inscriptionType:
                        row.inscriptionType ===
                        "ACTIVE" ? null : row.athleteStatus === "CANCELLED" ? (
                          t("inscriptionsScreen.notFinished")
                        ) : (
                          <AssignPlaceButton inscriptionId={row.id} asIcon />
                        ),
                    })}
                    onRowClick={(data) =>
                      this.props.history.push(
                        `/race/${data.raceId}/inscription/${
                          data.clientInscriptionId
                        }?f=${data.inscriptionType === "ACTIVE" ? true : false}`,
                        { data }
                      )
                    }
                    onTableChange={(table, filter) =>
                      this.getData({ ...table, ...filter })
                    }
                  />
                </Grid.Column>
              </Transition>
            </Grid.Row>
          </Grid>

          <Divider />
          <ButtonGroup onSave={() => this.save()} url="/clients" />
        </Container>
      </div>
    );
  }
}
