
import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import "./style.css";

const DCookieConsent = props => {
    const { t, main } = props

    const containerClasses = main ? "DCookieConsent-container" : "DCookieConsent-container-rigth"

    //remove warning if exists
    const existingWarning = document.getElementsByClassName("DCookieConsent-overlayStyle")

    if (existingWarning.length > 1) {
        existingWarning[1].parentNode.removeChild(existingWarning[1])
    }

    return (
        <CookieConsent
            sameSite="strict"
            disableStyles={true}
            containerClasses={containerClasses}
            buttonClasses="DCookieConsent-buttonStyle"
            declineButtonClasses="DCookieConsent-declineButtonStyle"
            overlay
            overlayClasses="DCookieConsent-overlayStyle"
            enableDeclineButton
            setDeclineCookie={false}
            onDecline={() => {
                Cookies.remove("token"); //remove user token cookie
                alert(t("commons.cookies.deny")); //show message
                window.location = '/'; //redirect to login
            }}
            declineButtonText={t("commons.cookies.ko")}
            buttonText={t("commons.cookies.ok")}
            location="bottom"
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
        >
            <span style={{ fontSize: "150%" }}>{t("commons.cookies.title")}</span>
            <span style={{ fontSize: "100%" }}> - {t("commons.cookies.description")}</span>
        </CookieConsent>
    );
};

export default DCookieConsent;