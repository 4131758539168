import { createAction, handleActions } from "redux-actions";
import initialState from "./initialState"
import modalitiesApi from "../apis/modalities";

export const getModality = createAction(
  "GET_MODALITY",
  modalitiesApi.fetchModality
);
export const updateModality = createAction("UPDATE_MODALITY");

export default handleActions(
  {
    [getModality]: (state, action) => {
      return {
        ...state,
        actualModality: action.payload
      };
    },
    [updateModality]: (state, action) => {
      return {
        ...state,
        actualModality: {
          ...state.actualModality,
          ...action.payload
        }
      };
    }
  },
  { ...initialState }
);
