import React, { useState } from "react";
import { Button } from "semantic-ui-react";

export default function AsyncButton(props) {
  const [disabled, toggle] = useState(false);
  return (
    <Button
      {...props}
      disabled={disabled}
      onClick={() => {
        if (props.onClick && toggle) {
          toggle(true);

          props.onClick().then(() => toggle(false));
        }
      }}
    />
  );
}
