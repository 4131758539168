import React from "react";
import SearchInput from "../../components/SearchInput";
export const tableHeaders = t => {
  return [
    {
      content: t("clientScreen.table.dni"),
      accessor: "dni"
    },
    {
      content: t("clientScreen.table.name"),
      accessor: "fullName"
    },
    {
      content: t("clientScreen.table.email"),
      accessor: "email"
    },
    {
      content: t("clientScreen.table.phone"),
      accessor: "phone"
    }
  ];
};

export const filterFields = t => {
  return [
    {
      fields: [
        {
          name: "dni",
          placeHolder: t("clientScreen.table.dni"),
          type: "Input",
          colSize: 8
        },
        {
          name: "name",
          placeHolder: t("clientScreen.table.name"),
          type: "Input",
          colSize: 8
        },
        {
          name: "email",
          placeHolder: t("clientScreen.table.email"),
          type: "Input",
          colSize: 8
        },
        {
          name: "phone",
          placeHolder: t("clientScreen.table.phone"),
          type: "Input",
          colSize: 8
        }
      ]
    }
  ];
};

export const tableHeadersInscriptions = t => {
  return [
    {
      content: t("clientScreen.detail.inscriptionsTable.date"),
      accessor: "inscriptionDate"
    },
    {
      content: t("clientScreen.detail.inscriptionsTable.raceName"),
      accessor: "raceName"
    },
        {
      content: "",
      accessor: "inscriptionType"
    }
  ];
};

export const formFields = (t, clubs, countries, provinces) => {
  
  return [
    {
      fields: [
        {
          name: "name",
          title: t("clientScreen.detail.name"),
          placeHolder: t("clientScreen.detail.name"),
          type: "Input",
          colSize: 16,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          }
        },
        {
          name: "surnames",
          title: t("clientScreen.detail.surnames"),
          placeHolder: t("clientScreen.detail.surnames"),
          type: "Input",
          colSize: 16,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "email",
          title: t("clientScreen.detail.email"),
          placeHolder: t("clientScreen.detail.email"),
          type: "Input",
          colSize: 10,
          props: {
            readOnly: true
          },
          validation: {
            required: true,
            regexType: "email",
            errorMessage: t("login.invalidEmailFormat")
          }
        },
        {
          name: "dni",
          title: t("clientScreen.detail.dni"),
          placeHolder: t("clientScreen.detail.dni"),
          type: "Input",
          colSize: 6
        }
      ]
    },
    {
      fields: [
        {
          name: "phone",
          title: t("clientScreen.detail.phone"),
          placeHolder: t("clientScreen.detail.phone"),
          type: "Input",
          colSize: 10,
          validation: {
            regexType: "phone",
            errorMessage: t("validation.phone")
          }
        },
        {
          name: "birthday",
          title: t("clientScreen.detail.birthday"),
          placeHolder: t("clientScreen.detail.birthday"),
          type: "DatePicker",
          validation: {
            custom: vals => {

              // if (vals.birthday === null || vals.birthday === "") {
              //   return {
              //     valid: false,
              //     errorMessage: t("modalities.wrongDateFormat")
              //   };
              // }
              return true;
            }
          },
          colSize: 6,
          props: {
            id: "birthday"
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "club",
          component: (_, defauld, onChange) => (
            <div className={"sixteen wide field"}>
              <label>{t("inscriptionsScreen.detail.club")}</label>
              <SearchInput
                onChange={data => onChange(data)}
                data={clubs}
                placeholder={t("clientScreen.detail.club")}
                value={defauld && defauld.club ? defauld.club : ""}
                icon="shield"
                noResultsMessage={t("inscriptionsScreen.detail.clubNotFound")}
              />
            </div>
          ),
          colSize: 16
        }
      ]
    },
    {
      fields: [
        {
          name: "contacto",
          title: t("userScreen.contact"),
          type: "Label"
        }
      ]
    },
    {
      fields: [
        {
          name: "address",
          title: t("clientScreen.detail.address"),
          placeHolder: t("clientScreen.detail.address"),
          type: "Input",
          colSize: 10
        },
        {
          name: "zip_code",
          title: t("clientScreen.detail.postalCode"),
          placeHolder: t("clientScreen.detail.postalCode"),
          type: "Input",
          colSize: 6
        }
      ]
    },
    {
      fields: [
        {
          name: "country",
          title: t("clientScreen.detail.country"),
          placeHolder: t("clientScreen.detail.country"),
          type: "Select",
          colSize: 10,
          data: countries.map(countryCode => ({
            text: t(`countries.${countryCode}`),
            value: countryCode
          }))
        }
      ]
    },
    {
      fields:  (state) => [
        {
          name: "region",
          type: state.country == 'ES' ? "Select" : "Input",
          data: state.country == 'ES' ? provinces.map(province => ({
            text: province.name,
            value: province.name
          })) : null,
          // props: {
            // clearable: true
          // },
          title: t("raceEdit.basicTabContent.region"),
          placeHolder: t("raceEdit.basicTabContent.region"),
          validation: {
            required: true,
            regexType: "text",
            errorMessage: t("commons.fieldRequired")
          },
          colSize: 6
        },
        {
          name: "city",
          title: t("clientScreen.detail.city"),
          placeHolder: t("clientScreen.detail.city"),
          type: "Input",
          colSize: 8
        }
      ]
    }
  ];
};
