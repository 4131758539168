import React, { useState, useEffect } from "react";
import { Icon, Modal, Grid, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import discountApi from "../../../apis/discounts";
import { modalHandler } from "../../../components/DModal";
import Form from "../../../components/Form";
import FluidResponsiveButton from "../../../components/FluidResponsiveButton";
import { formFields } from "./util";

const CreateDiscountButton = props => {
  const [discountForm, setDiscountForm] = useState();
  const [showValidation, setShowValidation] = useState(false);
  const [showBtnSendDisabled, setBtnSendDisabled] = useState(false);
  const [error, setError] = useState();
  const race = useSelector(state => state.races.actualRace);
  const [t] = useTranslation();

  const formHandler = formData => {
    setError(undefined);
    setDiscountForm(formData);
    setBtnSendDisabled(false)
  };

  const createDiscountHandler = async () => {
    if (!discountForm.ISFORMVALID) {
      setShowValidation(true);
      return;
    }
    setBtnSendDisabled(true)

    const formToSend = {
      ...discountForm,
      totalCodes: +discountForm.totalCodes,
      discountType: discountForm.discountType || 'PCT',
      amount: parseFloat(discountForm.amount.replace(",", "."))
    };

    const response = await discountApi.createDiscounts(
      props.raceId,
      formToSend
    );

    if (response.ok !== undefined && !response.ok) {
      setError(response.error.message);
      return;
    } else {
      modalHandler.close();
      await props.getData();
    }
  };

  useEffect(() => {
    modalHandler.refreshOptions({
      ...modalOptions
    });
  }, [error, discountForm, showValidation, showBtnSendDisabled]);

  const modalOptions = {
    header: t("raceDiscounts.createDiscount"),
    size: "small",
    content: (
      <Modal.Description>
        <Grid padded>
          <Grid.Row>
            <Grid.Column width="16">
              <Form
                showValidation={showValidation}
                onFormChange={formHandler}
                fields={formFields(
                  t,
                  discountForm?.discountType,
                  race?.start_date,
                  race?.finish_date
                )}
              />
            </Grid.Column>
          </Grid.Row>

          {error && (
            <Grid.Row>
              <Grid.Column width="16">
                <Message
                  error
                  header={t(`usersScreen.modal.messages.${error}.header`)}
                  content={t(`usersScreen.modal.messages.${error}.message`)}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Modal.Description>
    ),
    actions: (
      <Grid>
        <Grid.Column width="16">
          <Button 
          fluid color="vk" 
          onClick={createDiscountHandler}
          disabled = {showBtnSendDisabled}
          >
            {t("raceDiscounts.createDiscount")}
          </Button>
        </Grid.Column>
      </Grid>
    )
  };

  return (
    <FluidResponsiveButton
      icon
      labelPosition="left"
      size="small"
      onClick={async () => await modalHandler.open(modalOptions)}
      primary
    >
      <Icon name="tags" />
      {t("raceDiscounts.createDiscount")}
    </FluidResponsiveButton>
  );
};

export default CreateDiscountButton;
