import { clientApi } from "./index";

export default {
  get: async (params) => {
    try {
      const response = await clientApi
        .getClient()
        .get("/companies", { params: {...params, channel_id:1} });
      return response.data;
    } catch (error) {}
  },
  getCompany: async (id, params) => {
    try {
      const response = await clientApi.getClient().get(`/company/${id}`, {params:{...params, channel_id: 1}});
      return response.data;
    } catch (error) {}
  },
  getCompanyUsers: async (id, params) => {
    try {
      const response = await clientApi.getClient().get(`/company/${id}/users`, {params:{channel_id: 1, ...params}});
      return response.data;
    } catch (error) {}
  },
  create: async (params) => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/companies`, { ...params });
      return response.data;
    } catch (error) {}
  },
  update: async (id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/company/${id}`, { ...params });
      return response.data;
    } catch (error) {}
  },
  getMin: async () => {
    try {
      const response = await clientApi.getClient().get("/companies/combos", {params:{channel_id: 1}});
      return response.data;
    } catch (error) {}
  },
  block: async (id) => {
    try {
      const response = await clientApi.getClient().get(`/company/${id}/block`, {params:{channel_id: 1}});
      return response.data;
    } catch (error) {}
  },
  createTPV: async (companyId, params) => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/company/${companyId}/addtpv`, { ...params, channel_id: 1 });
      return response.data;
    } catch (error) {}
  },
  editTPV: async (companyId, tpvId, params) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/company/${companyId}/tpv/${tpvId}`, { ...params, channel_id: 1 });
      return response.data;
    } catch (error) {}
  },
};
