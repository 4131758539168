export default t => [
  {
    fields: [
      {
        name: "password",
        title: t("recoverPassword.newPassword"),
        placeHolder: t("recoverPassword.newPassword"),
        colSize: "8",
        type: "Input",
        validation: {
          required: true,
          regexType: "password",
          errorMessage: t("recoverPassword.invalidPassword")
        },
        props: {
          type: "password"
        }
      },
      {
        name: "repeatPassword",
        title: t("recoverPassword.rNewPassword"),
        placeHolder: t("recoverPassword.rNewPassword"),
        colSize: "8",
        type: "Input",
        validation: {
          required: true,
          regexType: "password",
          errorMessage: t("recoverPassword.invalidPassword"),
          custom: vals => {
            return {
              valid: vals.repeatPassword === vals.password,
              errorMessage: t('recoverPassword.NOT_SAME')
            };
          },
        },
        props: {
          type: "password"
        }
      }
    ]
  }
];
