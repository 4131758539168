import { createAction, handleActions } from "redux-actions";
import initialState from "./initialState";
import countriesApi from "../apis/countries";

export const getCountries = createAction(
  "GET_COUNTRIES",
  (countriesStore = []) => {
    if (countriesStore.length === 0) {
      return countriesApi.fetchCountryCodes();
    } else {
      return countriesStore;
    }
  }
);

export default handleActions(
  {
    [getCountries]: (state, action) => ({
      ...state,
      countries: action.payload
    })
  },
  { ...initialState }
);
