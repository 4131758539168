import React from "react";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

const ButtonGroup = (props) => {
  const [t] = useTranslation();
  let history = useHistory();

  return (
    <Button.Group floated="right">
      <Button
        className="ButtonGroupSave"
        disabled={props.disabled}
        onClick={() => (props.onSave ? props.onSave() : null)}
        color="vk"
      >
        {props.textOk || t("commons.save")}
      </Button>

      <Button.Or text={""} />

      {props.url ? (
        <Link to={props.url}>
          <Button negative>{props.textKo || t("commons.cancel")}</Button>
        </Link>
      ) : (
        <Button
          className="ButtonGroupCancel"
          negative
          onClick={() =>
            props.urlCancel ? history.push(props.urlCancel) : history.goBack()
          }
        >
          {props.textKo || t("commons.cancel")}
        </Button>
      )}
    </Button.Group>
  );
};

ButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
  urlCancel: PropTypes.string,
  url: PropTypes.string,
  textOk: PropTypes.string,
  textKo: PropTypes.string,
};

export default ButtonGroup;
