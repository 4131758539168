import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dimmer, Loader, Container } from "semantic-ui-react";
import { isEmpty } from "lodash";

import Table from "../DTable";
import Filter from "../Filter";

const FilterableTable = (props) => {
  const [tableParams, setTableParams] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  const onChange = (tParams, fParams) => {
    if (!tParams && !isEmpty(tableParams)) {
      tParams = {
        ...tableParams,
      };
    }

    if (fParams) {
      tParams = {
        ...tParams,
        pageNumber: 1,
      };
    }

    if (!fParams && !isEmpty(filterParams)) {
      fParams = {
        ...filterParams,
      };
    }

    setTableParams(tParams);
    setFilterParams(fParams);

    props.onChange(tParams, fParams);
  };

  const { loading, hideFilters, hidePagination } = props;

  useEffect(() => {
    let timer = null;
    if (loading) {
      timer = setTimeout(() => {
        setIsLoading(true);
      }, 500);
    } else {
      if (timer) {
        clearTimeout(timer);
      }

      setIsLoading(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [loading]);

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <Dimmer.Dimmable dimmed={props.loading}>
        {props.loading && isLoading && (
          <Dimmer active={props.loading} inverted>
            <Loader />
          </Dimmer>
        )}
        {
          hideFilters ? null :
          <Filter
          id={props.id}
          {...props}
          fields={props.filterFields}
          onFind={(filter) => onChange(null, filter)}
        />
        }
        <Table
          id={props.id}
          {...props}
          headers={props.tableHeaders}
          activePage={tableParams?.pageNumber ? tableParams?.pageNumber : 1}
          onTableChange={onChange}
          showItemsPerPage = { !hidePagination }
        />
      </Dimmer.Dimmable>
    </div>
  );
};

FilterableTable.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  /** TABLE PROPS */
  tableHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      content: PropTypes.any,
    })
  ),
  parseData: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  onRowClick: PropTypes.func,
  activePage: PropTypes.number,
  totalData: PropTypes.number,
  showTotalItems: PropTypes.bool,
  showPagination: PropTypes.bool,
  showItemsPerPage: PropTypes.bool,
  /** FILTER PROPS */
  filterFields: PropTypes.array,
  onFindClear: PropTypes.func,
  defaultState: PropTypes.object,
  className: PropTypes.string,
  autoPaginate: PropTypes.bool,
};

FilterableTable.defaultProps = {
  onChange: () => {},
  loading: false,
  autoPaginate: false
};

export default FilterableTable;
