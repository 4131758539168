import React from "react";
import { Input } from "semantic-ui-react";
import { autotexts } from "../../../../util/usersUtil";
import { notificationType } from "../../Notification/NotificationDetail/forms";

const templateName = (t) => ({
  fields: [
    {
      name: "name",
      title: t("templateScreen.fields.name"),
      placeHolder: t("templateScreen.fields.name"),
      type: "Input",
      colSize: 8,
      validation: {
        required: true
      }
    },
  ],
});

const autotext = (t, autotextInsert) => ({
  fields: [
    {
      name: "autotext",
      title: t("templateScreen.fields.autotext"),
      placeHolder: t("templateScreen.fields.autotext"),
      type: "Select",
      data: autotexts(t),
      colSize: 6,
      props: {
        clearable: true,
      },
      onChange: (click) => {
        autotextInsert(click);
      },
    },
  ],
});

const showAll = (t) => ({
  fields: [
    {
      name: "is_public",
      title: t("templateScreen.data.showAll"),
      type: "Checkbox",
      value: false,
      colSize: 16,
    },
  ],
});

export default {
  admin: (t, insert) => [
    notificationType(t),
    templateName(t),
    autotext(t, insert),
    showAll(t),
  ],
  company: (t, insert) => [
    notificationType(t),
    templateName(t),
    autotext(t, insert),
  ],
};
