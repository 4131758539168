const spanishTranslations = {
  commons: {
    next: "Siguiente",
    status: "Estado",
    comeBack: "Atrás",
    someWrong: "Algo ha salido mal...",
    goHome: "Ir a Inicio",
    invalidDate: "Fecha inválida.",
    save: "Guardar",
    return: "Volver",
    cancel: "Cancelar",
    close: "Cerrar",
    new: "Nuevo",
    or: "o",
    delete: "Borrar",
    fieldRequired: "Este campo es requerido",
    fieldMustBeFilled: "Este campo no se ha rellenado correctamente",
    selectAnOption: "Selecciona una opción...",
    savedSuccess: "Guardado con éxito",
    erasedSuccessfully: "Borrado exitosamente",
    errorErased: "Un error ha ocurrido al borrar",
    errorSaving: "Ha ocurrido un problema",
    errorReading: "No se pueden recuperar los datos",
    yes: "Si",
    no: "No",
    endDateMustBeHigher: "La fecha de fin debe ser mayor a la de inicio",
    areYouSure: "¿Estás seguro?",
    INVALID_EMAIL:
      "Dirección email no válida. El correo electrónico debe seguir el formato: 'nombre@ejemplo.com'",
    INVALID_NUMBER: "Este valor debe ser un número",
    INVALID_DOUBLE:
      "Este valor debe ser un número entero o decimal (2 decimales maximo).",
    mustBeNumber: "Debe ser un número",
    selectAll: "SELECCIONAR TODOS",
    removeAll: "QUITAR TODOS",
    ok: "Aceptar",
    send: "Enviar",
    selectImage: "Seleccionar Imagen",
    fileUpload: "Subir archivo",
    selectFile: "Seleccione el archivo",
    fileSuccess: " registros añadidos",
    fileError: "Error al cargar el archivo.",
    maxLength: "El campo debe ser válido y tener una longitud máxima de: {{maxLength}}",
    imageTooBig:
      "La imagen es muy grande, debería de ser menor a {{maxSize}} kb",
    docTooBig: "El documento es demasiado grande, debería de ser menor a {{maxSize}} Mb",
    invalidImageFormat: "El archivo no tiene un formato correcto, asegurate de que es una imagen",
    fieldsRequired:
      "Hay algunos campos que son requeridos, no están completos, o no son correctos",
    open: "Abrir",
    none: "Ninguno",
    goBack: "Volver",
    networkError: "Error de Conexión",
    networkErrorMessage:
      "No se puede conectar con el servidor. Intenta más tarde.",
    notFoundError: "El recurso no se encontró",
    notFoundMessage:
      "El recurso que solicitaste no está disponible o no existe.",
    serverError: "Algo salió mal 🙁",
    serverErrorMessage:
      "Algo ha salido mal al obtener los datos, vuelve a intentar o intenta más tarde.",
    gender: {
      BOTH: "Mixto",
      MALE: "Masculino",
      FEMALE: "Femenino"
    },
    ageType: {
      AGE: "Edad",
      YEAR: "Año de nacimiento"
    },
    invalidCiforNif: "Debe de ser un CIF o NIF válido",
    invalidParentCiforNif:
      "El NIF/CIF no es valido o es igual al de la persona que se inscribe",
    inscriptionsNotNull: "Existen inscripciones",
    emailMustBeTheSame: "Los correos electrónicos deben coincidir",
    noResultsFound: "No se encontraron resultados",
    changePassword: "Cambiar contraseña",
    actualPassword: "Contraseña actual",
    INVALID_PASSWORD: {
      header: "Contraseña incorrecta",
      message: "La contraseña actual es incorrecta."
    },
    language: "Lenguaje",
    cookies: {
      title: "Nosotros valoramos tu privacidad",
      description:
        "Esta pagina almacena información en cookies para un mejor funcionamiento.",
      ok: "Aceptar cookies",
      ko: "No acepto",
      deny: "Será reenviado a la página de inicio de sesión "
    },
    origin: {
      WEBAPP: "Web Pública",
      BACKOFFICE: "Backoffice",
      MOBILE: "App Móvil",
      UNKNOWN: "Desconocido"
    },
    activities: {
      RACE: "Carrera",
      EVENT: "Evento",
      ASSOCIATES: "Asociado",
    },
    resetQrSuccess: "El reseteo de QR ha sido un éxito",
    resetQrError: "Error al resetear el QR",
    resetQrErrorNotFound: "El QR no está registrado en estos momentos",

  },
  recoverPassword: {
    header: "Este enlace no es válido",
    message:
      "El enlace no es válido o ha caducado. Inténtalo de nuevo o verifica que la dirección es la correcta",
    goToLogin: "Ir al login",
    goToPublicWeb: "Ir a GedSports",
    title: "Por favor ingrese una nueva contraseña para su cuenta",
    newPassword: "Nueva contraseña",
    rNewPassword: "Repita contraseña",
    invalidPassword: "La contraseña no es válida",
    validFormat:
      "La contraseña debe contener:\n al menos, 8 caracteres, de los cuáles como mínimo 1 ha de ser una letra minúscula,\n otro una letra mayúscula, otro un dígito y otro un\n caracter especial (#$@!%&*?).\n Longitud máxima, 30 caracteres",
    NOT_SAME: "Las contraseñas no son iguales",
    changedTitle: "Contraseña cambiada",
    changedMessage: "La contraseña fue exitosamente cambiada."
  },
  register: {
    title: "Registrar usuario",
    registerMessage: "Usuario registrado con éxito",
    password: "Contraseña",
    passwordRepeat: "Repetir contraseña",
    passwordsDontMatch: "Las contraseñas no son iguales",
    success: "Será enviado a la página de acceso",
    companyName: "Nombre de la empresa",
    loading: "Creando usuario...",
    tokenErrors: {
      errorInToken: "Ha ocurrido un problema con el token",
      token_not_found: "El token no existe",
      token_already_used: "El token ya ha sido utilizado",
      token_expired: "El token ha expirado"
    }
  },
  login: {
    email: "Correo electrónico",
    password: "Contraseña",
    loginText: "Iniciar sesión",
    recoverText: "¿Has olvidado tu contraseña?",
    recoverHeader: "Restablecer contraseña",
    recoverEmail: "Ingresa tu correo electrónico",
    recoverSend: "Enviar correo",
    recoverEmailSent:
      "Un correo fue enviado con instrucciones para restablecer su contraseña",
    messages: {
      EMPTY_FIELDS: {
        header: "Faltan campos por rellenar",
        message: "Asegurese de que ambos campos estén rellenados correctamente."
      },
      INVALID_PASSWORD: {
        header: "Contraseña incorrecta",
        message: "La contraseña es incorrecta o el usuario no tiene contraseña."
      },
      EMAIL_NOT_FOUND: {
        header: "Correo electrónico inválido",
        message:
          "No hay ningún registro de usuario que corresponda a este correo."
      },
      USER_NOT_EXISTS: {
        header: "Correo electrónico inválido",
        message:
          "No hay ningún registro de usuario que corresponda a este correo."
      },
      USER_DISABLED: {
        header: "Este usuario esta bloqueado",
        message: "Esta cuenta ha sido deshabilitada por un administrador."
      },
      USER_BLOCKED: {
        header: "Este usuario esta bloqueado",
        message: "Esta cuenta ha sido deshabilitada por un administrador."
      },
      USER_WITHOUT_PERMISSIONS: {
        header: "Este usuario no tiene permisos para acceder a este módulo",
        message: "Si piensa que es un error contacte con un administrador."
      },
      USER_DELETED: {
        header: "Usuario no existe",
        message: "Esta cuenta ha sido borrada por un administrador."
      },
      NOT_CONFIRMED: {
        header: "Usuario no existe",
        message: "debe confirmar su correo electronico."
      }
    },
    invalidEmailFormat:
      "Dirección email no válida. El correo electrónico debe seguir el formato: 'nombre@ejemplo.com'"
  },
  currentLanguage: "es",
  gedSports: "Ged Sports",
  navBar: {
    home: "Inicio",
    users: "Usuarios",
    companies: "Empresas",
    races: "Carreras",
    events: "Eventos",
    associates: "Asociados",
    inscriptions: "Inscripciones",
    clients: "Atletas",
    qrReaders: "Lectores QR",
    configuration: "Configuración",
    preferences: "Preferencias",
    help: "Ayuda",
    logout: "Salir",
    notifications: "Notificaciones"
  },
  title: {
    home: "Inicio",
    users: "Usuarios",
    user: "Detalle del Usuario",
    companies: "Empresas",
    company: "Detalle de Empresa",
    company_new: "Nueva Empresa",
    races: "Carreras",
    events: "Eventos",
    associates: "Asociados",
    race: "Carrera",
    copyRace: "Copiar Actividad",
    newRace: "Nueva Actividad",
    clients: "Atletas",
    client: "Atleta",
    inscriptions: "Inscripciones",
    inscription: "Inscripción",
    notFound: "",
    discount: "Descuento",
    modality: "Modalidad",
    config: "Configuración",
    createAttribute: "Crear Atributo",
    attribute: "Atributo",
    notifications: "Notificaciones",
    template: "Plantilla",
    notification: "Notificación",
    qrReaders: "Lectores QR",
    event: "Evento",
    associate: "Asociado",
  },
  breadcrumbs: {
    users: "Usuarios",
    user: "Usuario",
    companies: "Empresas",
    company: "Empresa",
    races: "Carreras",
    race: "Carrera",
    activity: "Actividad",
    copyRace: "Copiar Actividad",
    newRace: "Nueva Actividad",
    clients: "Atletas",
    client: "Atleta",
    inscriptions: "Inscripciones",
    inscription: "Inscripción",
    discount: "Descuento",
    modality: "Modalidad",
    notifications: "Notificaciones",
    config: "Configuración",
    attribute: "Atributo",
    template: "Plantilla",
    notification: "Notificación",
    qrReaders: "Lectores QR",
    events: "Eventos",
    associates: "Asociados",
  },
  tableComponent: {
    noContent: "No hay registros disponibles",
    rowsPerPage: "{{number}} filas",
    totalItems: "Mostrando {{rowsPerPage}} de {{total}} registros"
  },
  homeScreen: {
    messages: {},
    table: {
      date: "Fecha",
      name: "Nombre",
      company: "Empresa",
      inscriptions: "Inscritos",
      clasification: "Clasificación",
      published: "Publicada",
      todayInscriptions: "Hoy",
      lastWeekInscriptions: "Semana",
      lastMonthInscriptions: "Mes",
      totalInscriptions: "Inscritos",
      activityTypeId: "Actividad",
    },
    type: {
      previous: "Últimos 15 días",
      upcoming: "Próximos 15 días"
    },
    modal: {},
    createRace: "Crear nuevo",
    searchRace: "Buscar actividad"
  },
  usersScreen: {
    resendEmail: "Reenviar email",
    messages: {
      saveCorrect: "Usuario guardado con éxito",
      emailResend: "El correo electrónico fue exitosamente reenviado",
      aNewMail: "Se envió un nuevo correo a la usuaria."
    },
    table: {
      date: "Fecha",
      username: "Nombre",
      email: "Correo electrónico",
      phone: "Teléfono",
      web: "Sitio web",
      role: "Rol",
      status: "Estado"
    },
    addUser: "Crear Usuario",
    deleteUser: "Borrar usuario",
    modalDeleted: {
      deleted: "¿Está seguro de que quiere borrar este usuario?",
      okMessage: "El usuario ha sido eliminado"
    },
    modal: {
      email: "Correo electrónico",
      role: "Rol",
      company: "Empresa del usuario",
      createdSuccess: "Usuario creado exitosamente",
      createdSuccessMessage:
        "Un correo se ha enviado al usuario para darse de alta",
      createdSuccessButton: "Aceptar",
      messages: {
        OVERLAP: {
          header: "La categoria se superpone con otra categoria",
          message:
            "Las categorias no deben superponerse entre ellas, seleccione otras edades."
        },
        EMAIL_EXISTS: {
          header: "El correo electrónico ya existe.",
          message:
            "El correo electrónico parece que ya está en uso. Escoja otro."
        },
        NOT_COMPANY_ID: {
          header: "Debe indicar una compañía",
          message:
            "Para el rol empresa debe indicar a qué empresa se creará el usuario"
        },
        EMPTY_FIELDS: {
          header: "Faltan campos por rellenar",
          message:
            "Asegurese de que los campos estén rellenados correctamente."
        }
      }
    }
  },
  clientScreen: {
    messages: {
      saveCorrect: "Usuario guardado con éxito"
    },
    resetPassword: {
      beforeHeader: "Cambiar contraseña",
      beforeContent:
        "Se enviará un correo electrónico a {{email}} para cambiar la contraseña. ¿Quiere continuar?",
      afterContent: "Correo enviado exitosamente"
    },
    table: {
      dni: "DNI",
      date: "Fecha",
      name: "Nombre",
      email: "Correo electrónico",
      phone: "Teléfono",
      web: "Sitio web",
      role: "Rol",
      status: "Estado"
    },
    detail: {
      dni: "DNI",
      date: "Fecha",
      name: "Nombre",
      surnames: "Apellidos",
      email: "Correo electrónico",
      phone: "Teléfono",
      club: "Club",
      web: "Sitio web",
      role: "Rol",
      status: "Estado",
      birthday: "Fecha de Nacimiento",
      postalCode: "Código Postal",
      country: "País",
      region: "Provincia",
      city: "Ciudad",
      address: "Dirección",
      changePassword: "Cambiar Clave",
      blockAccount: "Bloquear Cuenta",
      activeAccount: "Activar Cuenta",
      deleteAccount: "Borrar Cuenta ",
      inscriptions: "Inscripciones",
      inscriptionsTable: {
        date: "Fecha",
        raceName: "Carrera"
      }
    },
    downloadCSV: "Descargar CSV",
    addUser: "Crear Usuario",
    modal: {
      createdSuccess: "Usuario creado exitosamente",
      createdSuccessMessage:
        "Un correo se ha enviado al usuario para darse de alta",
      createdSuccessButton: "Perfecto"
    }
  },
  userScreen: {
    personalData: "Datos personales",
    contact: "Contacto",
    delete: "Borrar usuario",
    fields: {
      name: "Nombre",
      surnames: "Apellidos",
      dni: "DNI",
      cif: "CIF",
      email: "Correo electrónico",
      phone: "Teléfono",
      role: "Rol",
      status: "Estado",
      country: "País",
      region: "Provincia",
      city: "Ciudad",
      address: "Dirección",
      zip_code: "Código Postal",
      birthday: "Fecha de Nacimiento"
    }
  },
  companyScreen: {
    table: {
      date: "Fecha",
      name: "Nombre",
      company: "Empresa",
      email: "Email",
      phone: "Teléfono"
    },
    createCompany: "Crear Empresa"
  },
  companyDetail: {
    allowNotifications: "Permitir enviar notificaciones a los atletas",
    notificationEmail: "Email para respuestas de las notificaciones",
    notificationEmailError:
      "Dirección email no válida. El correo electrónico debe seguir el formato: 'nombre@ejemplo.com'",
    name: "Nombre",
    email: "Email",
    cifnif: "CIF NIF",
    phone: "Teléfono",
    managementCommission: "Comisión de gestión",
    comisionType: "Tipo de comisión",
    comisionType_PCT: "Porcentaje",
    comisionType_VAL: "Valor",
    address: "Dirección",
    contact: "Contacto",
    postalCode: "Código Postal",
    country: "País",
    province: "Provincia",
    provinceNotFound: "Provincia no encontrada",
    countryNotFound: "País no encontrado",
    city: "Ciudad",
    tpvs: "TPVs",
    addTPV: "Agregar TPV",
    editTPV: "Editar TVP",
    description: "Descripción",
    changePassword: "Cambiar contraseña",
    logo: "Logo de la compañía",
    blockCompany: {
      message: "Empresa bloqueada",
      btnMessage: {
        true: "Desbloquear Empresa",
        false: "Bloquear Empresa"
      },
      modalMessage: {
        true:
          "¿Está seguro de que quiere desbloquear esta empresa? TODOS los usuarios de esta empresa volverán a tener acceso a la aplicación",
        false:
          "¿Está seguro de que quiere bloquear esta empresa? Los usuarios de esta empresa no podrán acceder a la aplicación"
      },
      okMessage: {
        true: "La empresa ha sido bloqueada",
        false: "La empresa ha sido desbloqueada"
      }
    },
    addUser: "Añadir usuario",
    messages: {
      invalidCiforNif: "Debe de ser un CIF o NIF válido",
      EMAIL_EXISTS: {
        header: "El correo electrónico ya existe.",
        message: "El correo electrónico parece que ya está en uso. Escoja otro."
      }
    }
  },
  qrReadersScreen: {
    table: {
      date: "Fecha",
      name: "Nombre",
      surname: "Apellidos",
      email: "Correo electrónico",
      phone: "Número",
      enabled: "Habilitado",
      createdAt: "Creado el",
      updatedAt: "Actualizado el",
      status: "Estado",
    },
    createUser: "Crear Usuario",
  },
  racesScreen: {
    table: {
      date: "Fecha",
      name: "Nombre",
      company: "Empresa",
      planners: "Organizadores",
      email: "Correo electrónico",
      locality: "Localidad",
      type: "Tipo",
      region: "Provincia",
      published: "Publicada"
    },
    detail: {
      name: "Nombre",
      company: "Empresa",
      raceType: "Tipo de Carrera",
      sportType: "Tipo de Deporte",
      startDate: "Fecha inicio",
      endDate: "Fecha Fin",
      country: "Pais",
      region: "Provincia",
      city: "Ciudad",
      lastEdition: "Última edición",
      optionsToCopy: "Opciones a copiar",
      raceAdvanced: "Ajustes avanzados",
      modalities: "Modalidades",
      advancedModalities: "Ajustes avanzados de las modalidades",
      categories: "Categorias",
      teamTypes: "Tipos de equipo",
      attributes: "Atributos",
      prices: "Precios",
      deleted: "Esta actividad fue borrada",
      notValidMaxInscriptions:
        "El limite de inscripciones no puede ser inferior al limite total de sus modalidades.",
      activityType: "Tipo de actividad",
    },
    modal: {
      dateIsBefore: "La fecha de inicio es anterior a hoy"
    },
    errors: {
      endDateGreaterThanStartDate:
        "La fecha de finalización debe ser mayor a la fecha de inicio",
      startDateGreaterThanToday:
        "La fecha de inicio no puede ser anterior a hoy"
    },
    race: "Actividad a copiar",
    createRace: "Crear Actividad",
    copyRace: "Copiar Actividad",
    copyRacePrice:
      "Los precios se han creado con las fechas originales, revíselos ANTES de publicar la actividad.",
    noModalities: "No hay modalidades para esta actividad",
    startDateLowerThanRaceDate: "La fecha debe ser anterior a {{startDate}}",
    startDateGreaterThanRaceDate: "La fecha debe ser posterior a {{startDate}}",
    limitDateShouldBe: "La fecha debe ser {{date}}",
    limitDateBetween:
      "La fecha límite debe estar dentro de este rango de fechas: {{startDate}} a {{finishDate}}",
    limitDateBetweenRace:
      "La fecha límite debe estar dentro del rango de fechas de la actividad, entre {{startDate}} y {{finishDate}}",
    limitDateBeforeEnd:
      "La fecha límite debe ser menor o igual a la fecha de fin de la actividad, o sea antes del {{finishDate}}"
  },
  raceDetail: {
    menu: {
      detail: "Resumen",
      edit: "Editar",
      inscriptions: "Inscripciones",
      clasifications: "Clasificaciones",
      users: "Usuarios",
      modality: {
        header: "Modalidades",
        detail: "Resumen",
        edit: "Editar",
        race: "Carrera",
        event: "Evento",
        associate: "Asociado",
        modalities: "Modalidades",
        inscriptionsConfiguration: "Configurar inscripciones",
        prices: "Precios",
        inscriptions: "Inscripciones",
        attributes: "Atributos",
        categories: "Categorias",
        teamTypes: "Tipos de equipos",
        diploma: "Diploma"
      },
      moreOptions: {
        header: "Más opciones",
        discounts: "Descuentos",
        accounts: "Cuentas"
      },
      documents: "Documentos"
    },
    detail: {
      inscriptions: " inscritos",
      widget: "Obtener widget",
      generalInformation: {
        divider: "Información general",
        name: "Nombre",
        slug: "Slug",
        date: "Fecha",
        company: "Empresa",
        place: "Lugar",
        published: "Publicada",
        webUrl: "URL pública",
        copy: "Copiar"
      },
      events: {
        divider: "Modalidades",
        name: "Nombre: ",
        date: "Fecha: ",
        inscriptionUrl: "Inscripción web",
        adminInscriptionUrl: "Inscripciones en panel",
        clasificationUrl: "Clasificaciones"
      }
    },
    header: "Imagen superior",
    footer: "Imagen inferior",
    preview: "Vista previa",
    exampleName: "NOMBRE",
    documents: {
      table: {
        id: "Id",
        name: "Nombre",
        publicName: "Nombre Público del Documento",
        path: "Enlace",
        mimeType: "Tipo",
        isPublic: "Público",
        document: "Documento",
        createdAt: "Creado el",
        uploadedBy: "Subido por"
      },
      createDoc: "Subir documento",
      updateDoc: "Actualizar documento",
      deleteDoc: "Borrar documento",
      fileWithExtension: "El nombre del archivo debe de tener una extensión"
    }
  },
  raceEdit: {
    noPublishModality:
      "Para publicar esta actividad debe existir minimo una modalidad asociada publicada",
    fieldEmpty: "debe completar el campo {{field}}",
    areYouSureToDeletRace:
      "La actividad tiene {{totalInscriptions}} inscripciones.",
    delete: "Borrar Actividad",
    deletedSuccess: "Actividad eliminada",
    cantDelete: "Esta actividad no se puede eliminar",
    basicInformationTab: "Información Básica",
    regulationTab: "Reglamento",
    customTab: "Personalizar",
    advancedTab: "Avanzado",
    publishRace: "Publicar actividad",
    notPublishRace: "Remover publicación",
    areYouSureToPublish: "¿Está seguro que desea publicar esta actividad?",
    areYouSureToNotPublish:
      "¿Está seguro que desea retirar la publicación para esta actividad?",
    buttons: {
      saveAndPublish: "Guardar y Publicar",
      saveAndNotPublish: "Guardar y No Publicar"
    },
    customizeTabContent: {
      headerImage:
        "Cartel de la actividad. Dimensiones aproximadas: ({{dimensions}})",
      voucherImage: "Imagen de cabecera del justificante. Dimensiones aproximadas: ({{dimensions}})",
      headerEmailImage:
        "Imagen de cabecera del correo. Dimensiones aproximadas: ({{dimensions}})",
      emailText: "Contenido del correo",
      commercialText: "Contenido del uso comercial de datos"
    },
    basicTabContent: {
      name: "Nombre",
      startDate: "Fecha de inicio",
      finishDate: "Fecha de fin",
      raceType: "Tipo de carrera",
      sportType: "Tipo de deporte",
      country: "País",
      region: "Provincia",
      city: "Ciudad",
      refundPolicy: "Política de devoluciones",
      dorsalPickupPlace: "Recogida de dorsales",
      exitPlaceInfo: "Información sobre el lugar de salida",
      contact: "Contacto",
      contactEmail: "Correo electrónico",
      contactPhone: "Teléfono",
      finishDateMustBeGreater:
        "La fecha de fin debe ser mayor que la fecha de inicio",
      imagesSection: "Imágenes",
      cardImage: "Imagen de tarjeta. Dimensiones aproximadas: ({{dimensions}})",
      bannerImage:
        "Imagen de cabecera Dimensiones aproximadas: ({{dimensions}})",
      celebrationPlace: "Información sobre el lugar de celebración del evento",
      schedule: "Horario",
    },
    advancedTabContent: {
      tpvProfile: "Perfil de TPV",
      maxInscriptions: "Máximo número de inscripciones",
      inscriptionCommission: "Comisión por Inscripción",
      inscriptionCommissionType: "Tipo de Comisión",
      showInscriptionCounter: "Mostrar número de inscripciones",
      showInscriptionModalityCounter:
        "Mostrar número de inscripciones en las modalidades",
      showInscriptionList: "Mostrar lista de inscritos",
      autoDorsalAssignment: "Asignación automática de dorsales",
      firstDorsalToAssign: "Primer dorsal a asignar",
      dorsalAssignmentBy: "Asignación de dorsal por:",
      dorsalAssignmentByRace: "Carrera",
      dorsalAssignmentByModality: "Modalidad",
      PCT: "Porcentaje",
      VAL: "Valor",
      virtualDorsal: "Dorsales virtuales",
      copernicoRaceId: "Identificador de Copernico",
      under14_dni: "DNI requerido a menores de 14 años",
      resetQr: "Resetear QR"
    }
  },
  raceDiscounts: {
    table: {
      createdAt: "Creado",
      discountCode: "Código de Descuento",
      value: "Importe",
      used: "Usado",
      notUsed: "No Usado",
      description: "Descripción",
      limitDate: "Fecha Límite",
      booked: "Reservado",
      amount: "Importe"
    },
    detail: {
      info: "Información del Descuento",
      usedByName: "Usado por (Nombre)",
      usedByEmail: "Usado por (Correo electrónico)",
      usedOn: "Usado el"
    },
    createDiscount: "Crear Descuento",
    createForm: {
      totalCodes: "Total de códigos",
      limitDate: "Fecha límite",
      type: "Tipo de descuento",
      typePCT: "Porcentaje",
      typeVAL: "Total",
      amount: "Importe",
      description: "Descripción"
    },
    modal: {
      editDiscount: "Editar descuento",
      cannotDelete:
        "No puede eliminar este descuento ya que se está utilizando en una inscripción."
    }
  },
  raceUsers: {
    enable: "Usuario habilitado con éxito",
    disable: "Usuario deshabilitado con éxito",
    noPlanners: "No hay usuarios organizadores registrados en esta actividad!",
    noPoints: "No hay usuarios de puntos de venta registrados en esta actividad!",
    noQrs: "No hay usuarios de lector de QR registrados en esta actividad!",
    EMAIL_EXISTS: "El correo electrónico ya existe.",
    EMAIL_NOT_FOUND: "Correo electrónico inválido",
    invalidRol: "El correo ya existe con un rol diferente",
    add: {
      planner: "Introduzca el email del usuario organizador",
      sale: "Introduzca el email del usuario punto de venta",
      qrReader: "Introduzca el email del usuario Lector QR",
      tittlePlanner: "Organizador",
      tittlePOS: "Punto de venta",
      tittleqrReader: "Lector QR",
      userPlanner: "Desea crear un usuario organizador con este correo?",
      userSale: "Desea crear un usuario punto de venta con este correo?",
      userQR: "Desea crear un usuario lector de QR con este correo?"
    },
    loader: {
      planner: "Agregando usuario organizador. Espere por favor",
      sale: "Agregando usuario punto de venta. Espere por favor",
      qrReader: "Agregando usuario Lector QR. Espere por favor"
    },
    modal: {
      email: "Correo electrónico"
    }
  },
  inscriptionsScreen: {
    inscriptionsNotEnable: "No existen modalidades a las que inscribirse",
    inscriptionButton: "Inscribir",
    downloadCsvButton: "Descargar CSV",
    assignPlace: "Asignar plaza",
    assignPlaceMessage: "El estado del pago permanecerá pendiente.",
    notFinished: "No finalizada",
    table: {
      date: "Fecha",
      dni: "DNI",
      raceName: "Actividad",
      modalityName: "Modalidad",
      athleteFullName: "Atleta",
      athleteEmail: "Correo electrónico",
      inscriptionStatus: "Estado",
      locator: "Localizador",
      operationCode: "Código de operación",
      from: "Origen"
    },
    filter: {
      dni: "DNI",
      name: "Nombre atleta",
      email: "Correo electrónico",
      activityType: "Tipo actividad",
      race: "Nombre actividad",
      payDate: "Fecha de Pago",
      payForm: "Forma de Pago",
      status: "Estado",
      date: "Fecha",
      modalityName: "Modalidad",
      athleteFullName: "Nombre atleta",
      athleteEmail: "Correo electrónico",
      tracking: "Localizador",
      operationCode: "Código de operación",
      showNotFinished: "Mostrar no finalizadas",
      origin: "Origen"
    },
    detail: {
      custom: "Personalizado",
      inscriptionsNotEnable:
        "Las inscripciones para esta modalidad no están disponibles",
      commission: "Comisión ({{commissionValue}} {{commissionType}})",
      commissionWithoutParenteses: "Comisión",
      discount: "Descuento",
      discountCode: "Tengo un código de descuento",
      discountValue: "Valor del descuento",
      validateDiscount: "Validar",
      discountNoValid: "Código de descuento no válido",
      addInscription: "Añadir otra inscripción",
      inscribe: "Inscribir ({{number}})",
      selectModality: "Seleccione Modalidad",
      teamType: "Tipo de Equipo",
      selectTeamType: "Seleccione el tipo de equipo",
      selectPrice: "Seleccione Precio",
      basePrice: "Precio de la inscripción",
      teamInfo: "Información del equipo",
      teamName: "Nombre del equipo",
      teamSize: "Número de integrantes {{between}}",
      teamSizeError: "El número de integrantes no está en el rango",
      teamGender: "El género del participante debe ser ",
      teamSizeMax:
        "El número de integrantes para esta inscripción ha llegado al máximo",
      inscriptionPaymentInfo: "Información de pago",
      inscriptionInfo: "Información de la inscripción",
      inscriptionsInfo: "Información de las inscripciones",
      inscriptionOther: "Campos adicionales",
      inscriptionExtra: "Extra",
      insertPrice: "Inserte el precio que desee asignar a la inscripción",
      clubNotFound: "Si no está el club se creará",
      date: "Fecha",
      raceName: "Actividad",
      modalityName: "Modalidad",
      email: "Correo electrónico",
      address: "Dirección",
      firstName: "Nombre",
      lastName: "Apellidos",
      dni: "DNI",
      moreInfoUrl: "Más información en: {{url}}",
      locator: "Localizador",
      phonePrefix: "Prefijo telefónico",
      phone: "Teléfono",
      emergencyPhone: "Teléfono de emergencia",
      birthday: "Fecha de Nacimiento",
      totalRefunded: "Devolución",
      birthdayShouldBeBetween: {
        both:
          "Esta modalidad es solo para personas nacidas entre {{minDate}} y {{maxDate}}",
        onlyMin:
          "Esta modalidad es solo para personas nacidas después de {{minDate}}",
        onlyMax:
          "Esta modalidad es solo para personas nacidas antes de {{maxDate}}"
      },
      zip_code: "Código Postal",
      dorsalAssigned: "Dorsal asignado",
      club: "Club",
      confirmEmail: "Confirmar correo electrónico",
      gender: "Género",
      contact: "Contacto",
      male: "Masculino",
      female: "Femenino",
      documentType: "Tipo de documento",
      country: "País",
      region: "Provincia",
      city: "Ciudad",
      originCountry: "País de origen",
      federatedParticipant: "Participante federado",
      localParticipant: "Participante local",
      cardPhoto: "Foto de carnet",
      parentName: "Nombre del adulto que autoriza",
      parentSurnames: "Apellidos del adulto que autoriza",
      parentDni: "Número de documento del adulto que autoriza",
      zipCode: "Código Postal",
      paymentDate: "Fecha de pago",
      paymentLocator: "Localizador",
      modality: "Modalidad",
      operationCode: "Código de operación",
      price: "Precio",
      tpv: "TPV",
      priceDescription: "Descripción del precio",
      authCode: "Código de autorización",
      refund: "Devolución",
      partialReturn: "Devolución parcial",
      paymentStatus: "Estado de pago",
      returnMoney: "Devolución de dinero",
      refundDescription: "Descripción de la devolución o anulación",
      cancelInscription: "Anular inscripción",
      cancelled: "Esta inscripción fue cancelada",
      cancelInscriptionConfirm:
        "¿Quiere anular la inscripción de {{name}} {{surnames}} sin devolver el dinero ({{amount}}€)?",
      cancelTeamInscription:
        "Esta inscripción pertenece a un equipo, tenga precaución al anularla",
      cancelTeamInscriptions:
        "Estas inscripciones pertenecen a un equipo, tenga precaución al anular alguna de ellas",
      cancelInscriptions: "Anular inscripcion(es)",
      cancelPayment: "Anular pago",
      unCancelInscriptions: "Reactivar inscripcion(es)",
      changeModality: "Cambiar de modalidad",
      changeModalityValidation: `Existe una restricción de edad, genero o dni que no permite cambiar de modalidad a uno o mas atletas`,
      changeModalityTeam:
        "No se puede cambiar de modalidad una inscripción de equipo",
      changeModalityMessage:
        "Las inscripciones se moverán a la modalidad seleccionada. Debe completar los campos faltantes, ya que son obligatorios en la modalidad seleccionada. El precio no se modificará y se le asignará un nuevo dorsal y localizador si corresponde.",
      changePayment: "Cambiar pago",
      priceShouldBeMoreThanZero:
        "El importe a devolver debe de ser mayor a cero y menor al precio que se ha pagado.",
      refundMessage:
        "Inserte el importe a devolver, el precio de cada inscripción será actualizado proporcionalmente. Esta operación es automática pero le redirigirá al TPV Virtual para comprobar que la devolución se ha hecho correctamente.",
      selectToCancel:
        "Seleccione la(s) inscripcion(es) a anular. Si seleciona la casilla de la izquierda se devolverá el dinero de esa persona y la inscripción quedará anulada. Esta operación es automática pero le redirigirá al TPV Virtual para comprobar que la devolución se ha hecho correctamente.",
      amountToReturn: "Importe a devolver",
      totalAmountToRetun: "Total a devolver: {{total}}",
      personalData: "Datos personales",
      insertSomeDataForValidate: "Ingrese Email o DNI para validar atleta",
      inscriptionError: "Ha ocurrido un error con la(s) inscripción(es)",
      inscriptionErrors: {
        MISSING_FIELDS: "Hay campos requeridos que no han sido completados",
        MODALITY_NOT_FOUND: "La modalidad no existe",
        INSCRIPTIONS_NOT_ENABLED:
          "Las inscripciones no están habilitadas para esta modalidad",
        TAX_IDS_DUPLICATED:
          "Parece que uno de los DNI está repetido o ya existe en las inscripciones de la modalidad",
        TEAM_NAME_DUPLICATED: "Parece que este nombre de equipo ya está tomado",
        TEAM_MEMBERS_LENGTH:
          "El número de componentes del equipo es incorrecto",
        MAX_INSCRIPTIONS_REACHED:
          "Se ha alcanzado el número máximo de inscripciones para esta modalidad",
        TOO_MANY_PEOPLE:
          "Ha inscrito un número mayor de personas que el permitido",
        NOT_VALID_FOR_MODALITY:
          "La edad o el género no es válido para esta modalidad",
        NOT_ALL_INSCRIPTIONS:
          "Las inscripciones que desea mover no son todas las inscripciones iniciales",
        DISCOUNT_NOT_AVAILABLE:
          "Los descuentos para estas inscripciones ya no están disponibles",
        INVALID_IMAGE:
          "La imagen de una de las inscripciones no es válida."
      },
      returnMoneyErrors: {
        INCORRECT_AMOUNT:
          "El importe a devolver de una de las inscripciones es mayor que el precio pagado",
        ZERO_AMOUNT: "El importe a devolver es cero.",
        TEAM_MIN_ATHLETES:
          "No puede eliminar esta cantidad de inscripciones ya que no cumpliría con los requisitos mínimos del equipo",
        TOKEN_ERROR:
          "Ha ocurrido un problema con la autenticación. Debe volver a logearse para realizar devoluciones"
      },
      alerts: {
        setAtLeastOneValue: "Debe ingresar al menos un dato",
        thisAthleteAlreadyExist: "Este atleta ya existe para esta modalidad",
        thisAthleteIsAvaliable:
          "Este atleta no se ha inscrito, puedes utilizarlo",
        thisAthleteIsAlreadyInDB: "Este atleta existe en la base de datos",
        someFieldsWillBeFill: "Algunos campos serán rellenados",
        teamMembersLengthDontMatch:
          "El número de miembros del equipo no coincide. Deben ser {{teamSize}} pero sólo existe {{actualInscriptions}} inscripcion(es)",
        cantAddMore:
          "No puede añadir más miembros al equipo. \nDeben ser {{teamSize}} y ya existe {{actualInscriptions}} inscripcion(es). \nPulse sobre 'Inscribir'"
      },
      status: {
        label: "Status",
        PENDING: "Pendiente",
        PAID: "Pagado",
        REJECTED: "Rechazado",
        CANCELLED: 'Cancelado',
      },
      button: {
        downloadVoucher: "Descargar justificante",
        resendVoucher: "Reenviar justificante",
        resetQr: "Resetear QR"
      },
      voucherResended: "Se ha enviado un email con el justificante",
      EMAIL_NOT_SENDED: "Hubo un problema al enviar el email",
      MAIL_SEND_DELAYED: "Se ha enviado un email con el justificante (RETRASADO)",
      copernicoId: "ID Copernico",
      qrCode: "Código QR de la inscripción",
      checkedIn: "Registrado",
      dorsalDelivered: "Dorsal entregado",
      qrReader: "Lector QR que entregó",
      dorsalDeliveryDate: "Fecha de entrega del dorsal",
      extraName: "Nombre",
      extraDeliveryDate: "Fecha de entrega",
      extraDelivered: "Extra entregado",
      qrReaderResetedDate: "Fecha de reseteo QR",
    },
    refund: {
      refundFailure:
        "Ha ocurrido un problema con el TPV Virtual y no se ha podido realizar la devolución. Inténtelo de nuevo.",
      refundSucessButProblemSaving:
        "La devolución se ha realizado con éxito, pero hubo un problema actualizando la inscripción. La devolución o anulación de inscripción no se verá reflejada en el sistema, por favor contacte con el administrador.",
      refundSuccess:
        "La devolución se ha realizado con éxito. El atleta recibirá el dinero en breve en la tarjeta que se uso para la inscripción.",
      backToInscription: "Volver a la inscripción",
      backToRaceInscriptions: "Volver a las inscripciones de la carrera"
    }
  },
  raceClassifications: {
    addNewClassificiation: "Agregar nueva clasificación a ",
    table: {
      modality: "Modalidad",
      date: "Fecha",
      count: "Contador",
      lastUpdate: "Última actualización"
    },
    modal: {
      title: "Borrar Clasificación"
    },
    uploadfile: {
      name: "Nombre",
      make_map: "Mappear Columnas",
      error_mobile: "Debe seleccionar 3 campos para mostrar en la app móvil",
      finishTime:
        "Para un buen funcionamiento de la aplicación debería asignar algún valor al campo finish_time."
    },
    error: {
      UPLOAD_CLASSIFICATION_DUPLICATED:
        "Ya existe una clasificación con ese nombre"
    }
  },
  raceAccounts: {
    wait: "Espere mientras se recuperan los datos",
    waitForPdf: "Espere mientras se genera el documento",
    total: "Datos totales",
    downloadPdf: "Descargar PDF",
    downloadCSV: "Descargar CSV",
    tableColumns: {
      inscriptionType: "Tipo de inscripción",
      inscriptions: "Inscritos",
      raised: "Dinero recaudado",
      concept: "Concepto",
      quantity: "Cantidad",
      totalCommissions: "Total Comisiones"
    },
    tableRows: {
      withTpv: "En estado “Pagado” con TPV a través de web",
      withOutTpv: "En estado “Pagado” sin TPV a través de web",
      cancelled: " En estado “Anulado”",
      cancelledRefund: "En estado “Pagado” con devolución parcial",
      cancelledNotRefund: " En estado “Anulado” con devolución parcial",
      paidInPanel: "En estado “Pagado” a través del panel de administración",
      total: "Total",
      totalAmount: "Importe Total",
      webAmount: "Totales Web",
      refunds: "Devoluciones parciales",
      discounts: "Descuentos",
      basePrice: "Precio base",
      comision_inscripciones_gratuitas: " Comisión inscripciones gratuitas"
    },
    tableTitles: {
      main:
        "DESGLOSE DE INSCRIPCIONES EN ESTADO PAGADO CON TPV A TRAVÉS DE GEDSPORTS",
      event:
        "DESGLOSE DE INSCRIPCIONES EN ESTADO PAGADO CON TPV A TRAVÉS DE GEDSPORTS POR EVENTO",
      panel:
        "DESGLOSE INSCRIPCIONES EFECTUADAS EN EL PANEL DE ADMINISTRACIÓN DE GEDSPORTS",
      commission: "DESGLOSE COMISIONES GEDSPORTS"
    }
  },
  modalities: {
    createModality: "Crear modalidad",
    create: {
      name: "Nombre",
      description: "Descripción",
      date: "Fecha",
      start_time: "Hora de inicio",
      distance: "Distancia (en KM)",
      type: "Tipo de carrera",
      sportType: "Tipo de deporte",
      successModal: {
        createdSuccess: "Éxito",
        createdSuccessMessage: "La modalidad ha sido creada exitosamente",
        createdSuccessButton: "Continuar"
      }
    },
    edit: {
      name: "Nombre",
      published: "Publicado",
      raceType: "Tipo de carrera",
      sportType: "Tipo de deporte",
      distance: "Distancia",
      date: "Fecha",
      time: "Hora",
      automaticAssignation: "Asignación automática de dorsales",
      firstDorsal: "Primer dorsal a asignar",
      warnAboutDorsalAsignment:
        "Los dorsales se asignan globalmente para la carrera",
      moreInformation: "Más información",
      save: "Guardar",
      cancel: "Cancelar",
      delete: "Eliminar",
      dorsalDelivered: "Dorsal será entregado",
      deleteModal: {
        title: "Borrar modalidad"
      },
      alert: {
        successTitle: "Éxito",
        successMessage: "La modalidad ha sido editada exitosamente",
        errorTitle: "Error",
        errorMessage: "Hubo un problema editando la modalidad",
        deleteErrorTitle: "No se pudo eliminar esta modalidad",
        deleteSuccess: "Modalidad eliminada exitosamente"
      },
      resetQr: "Resetear QR",
      autoResetQr: "Resetear automaticamente el QR"
    },
    wrongDateFormat: "Formato de fecha incorrecto",
    limitDateBeforeModality:
      "La fecha debe ser antes del inicio de la modalidad",
    limitDateGreaterAndBefore:
      "La fecha límite debe ser mayor a la de inicio y ser antes del inicio de la modalidad",
    limitDateBetweenModality:
      "La fecha límite debe estar dentro del rango de fechas de la modalidad",
    limitDateBetweenAndGreater:
      "La fecha límite debe ser mayor a la de inicio y debe estar dentro del rango de fechas de la modalidad"
  },
  pricesScreen: {
    table: {
      name: "Nombre",
      dateStart: "Fecha de inicio",
      dateFinish: "Fecha de fin",
      amount: "Importe",
      maxAthletes: "Máximo de inscritos",
      unlimited: "Sin límite"
    },
    new: "Nuevo precio",
    edit: "Editar precio",
    cannotUpdate:
      "No puede actualizar el importe de este precio ya que se está utilizando en una inscripción.",
    cannotDelete:
      "No puede eliminar este precio ya que se está utilizando en una inscripción."
  },
  modalitySummary: {
    info: {
      inscriptions: "Inscritos",
      name: "Nombre",
      modalityDate: "Fecha de la modalidad",
      modalityHour: "Hora de la modalidad"
    },
    inscriptions: {
      state: "Estado de inscripción",
      init: "Inicio de inscripciones",
      end: "Fin de inscripciones",
      max: "Máximo de inscripciones",
      defauldPrices: "Precio por defecto"
    },
    tables: {
      name: "Nombre",
      price: "Precio",
      since: "Desde",
      until: "Hasta",
      type: "Tipo",
      requared: "Obligatorio",
      minAthletes: "Mínimo de atletas",
      maxAthletes: "Máximo de atletas"
    },
    titles: {
      inscriptionconfig: "Configuración de Inscripciones",
      categories: "Categorias",
      teamtypes: "Tipo de equipos",
      attributes: "Atributos",
      prices: "Precios"
    }
  },
  modalityConfiguration: {
    dividerText:
      "Selecciona los campos que quieres que aparezcan en el formulario",
    enableInscriptions: "Permitir inscripciones",
    startDate: "Fecha de inicio",
    finishDate: "Fecha de fin",
    maxModalityInscriptions: "Max. insc.",
    maxWebInscriptions: "Max. insc. web",
    defaultPrice: "Precio por defecto",
    minAgeType: "Tipo de edad mínima",
    maxAgeType: "Tipo de edad máxima",
    minAge: "Edad mínima",
    maxAge: "Edad máxima",
    ageOnDate: "Edad en fecha",
    permittedGenders: "Género permitido en la inscripción",
    mobilePhone: "Teléfono móvil",
    country: "País de origen",
    address: "Dirección",
    federated: "Participante federado",
    emergencyNumber: "Teléfono móvil del emergencia",
    localizationOne: "Localización (País)",
    zipCode: "Código Postal",
    selectExit: "Selecciona una salida",
    phonePrefix: "Prefijo telefónico",
    localizationTwo: "Localización (País, Provincia)",
    parentsPermission: "Permiso paterno",
    photo: "Foto carnet",
    confirmEmail: "Confirmación de email",
    localityThree: "Localización (País, Provincia, Ciudad)",
    localParticipant: "Participante Local",
    savedSuccess: "Configuración guardada exitosamente",
    savedFailure: {
      common: "Algo ha fallado al guardar la configuración",
      dateValidity: "La edad mínima debe ser menor que la edad máxima",
      tooMuchAthletes:
        "El máximo de inscripciones de todas las modalidades combinadas es mayor que el máximo de inscripciones de la carrera"
    },
    savedInfo:
      "Tenga precaución al modificar la configuración de las inscripciones: Ya hay atletas inscritos en la carrera.",
    finishDateError1:
      "La combinación de fecha y hora de fin debe ser posterior a la de inicio ({{startConfigDate}})",
    finishDateError2:
      "La combinación de fecha y hora de fin debe ser anterior a {{startModalityDate}}",
    notValidMaxInscriptions:
      "El limite de inscripciones supera el limite de la carrera.",
    selectClub: "Mostrar control para seleccionar club"
  },
  templateScreen: {
    detail: {
      deleted: "Eliminada"
    },
    fields: {
      name: "Nombre de la plantilla",
      message: "Mensaje",
      autotext: "Autotexto"
    },
    data: {
      autotext: {
        club: "Club",
        surnames: "Apellidos"
      },
      showAll: "Visible para todos"
    },
    save: "Guardar",
    createdSuccess: "Plantilla creada",
    updatedSuccess: "Plantilla actualizada",
    error:
      "No se ha podido llevar a cabo esta acción, por favor, inténtelo de nuevo y si el error persiste, contacte con el administrador del sistema.",
    comeBack: "Volver"
  },
  notificationScreen: {
    detail: {
      deleted: "Eliminada"
    },
    fields: {
      notificationType: "Tipo de Notificación",
      notificationPush: "Notificación Push",
      name: "Nombre de la notificación",
      message: "Mensaje",
      autotext: "Autotexto",
      template: "Plantilla",
      race: "Carrera",
      subject: "Asunto",
      company: "Empresa",
      imageUrl: "Url de Imagen",
      province: "Provincia",
      modality: "Modalidad",
    },
    data: {
      autotext: {
        club: "Club",
        surnames: "Apellidos",
        locator: "Localizador",
        dni: "DNI",
        birthDate: "Fecha de nacimiento",
        nameSurnames: "Nombre y apellidos",
        name: "Nombre"
      },
      deliverStatus: {
        accepted: "Aceptado",
        rejected: "Rechazado",
        delivered: "Enviado",
        failed: "Fallo",
        opened: "Abierto",
        clicked: "Clickado",
        complained: "Reclamado",
        stored: "Almacenado",
        unsubscribed: "Desuscrito"
      },
      showAll: "Visible para todos"
    },
    modal: {
      title: "Seleccionar destinatarios",
      titlePrefilter: "Seleccionar opciones de filtrado",
      titleStatus: "Estado de los envíos",
      deleteTitle: "Eliminar registro"
    },
    save: "Guardar",
    send: "Enviar",
    test: "Prueba",
    recipents: "Destinatarios",
    recipentsTable: {
      name: "Nombre",
      send: "Enviar",
      status: "Estado",
      email: "Email"
    },
    createdSuccess: "Notificación creada",
    updatedSuccess: "Notificación actualizada",
    deleteSuccess: "Registro eliminado",
    error:
      "No se ha podido llevar a cabo esta acción, por favor, inténtelo de nuevo y si el error persiste, contacte con el administrador del sistema.",
    emptyRecipents: "Debe seleccionar al menos un destinatario",
    alreadySent:
      "Esta notificación ya ha sido enviada y esta acción no puede realizarse",
    testSuccess: "Email de prueba enviado correctamente",
    statusInProgress:
      "Su petición se está procesando. Por favor, inténtelo de nuevo",
    selectRace: "Debe seleccionar una carrera",
    noMoreThan5Days: "Solo se podrán consultar los últimos 5 días posteriores al envío"
  },
  notifications: {
    createNotification: "Nueva notificación",
    createTemplate: "Nueva plantilla",
    confirmDelete:
      "¿Está seguro de que desea eliminar el registro seleccionado?",
    list: "Notificaciones",
    templates: "Plantillas",
    table: {
      company: "Empresa",
      name: "Carrera",
      status: "Estado",
      date: "Fecha",
      type: "Tipo",
      template: "Plantilla",
      PENDING: "Pendiente",
      SENT: "Enviada",
      CANCELLED: "Cancelada",
      CREATED: "Creada",
      unknown: "Desconocido"
    },
    notifiationsTypes: {
      NOTIFICATION_PUSH: "Notificacion push",
      EMAIL: "Email"
    }
  },
  config: {
    comition: "Comisión general (%)",
    validTime: "Tiempo de validez de enlaces (horas)",
    blokedTime: "Tiempo de bloqueo de plazas (minutos)",
    bill: "Datos de facturación",
    seeTPV: "Ver configuración de TPV",
    tpv: "Configuración tpv",
    raceType: "Tipo de carrera",
    sportType: "Tipo de deporte",
    addType: "Agregar tipo",
    noTypes: "No hay tipos de deporte registrados!",
    addRaceType: "Agregar tipo de deporte",
    loader: "Agregando tipo de deporte",
    type: "Tipo",
    code: "Código",
    typeExist: "Tipo de deporte ya existe",
    merchantCode: "Código de comerciante",
    terminal: "Terminal",
    merchantSignature: "Firma del comerciante",
    terms: {
      titlePANEL: "Términos y condiciones para usuarios del panel.",
      titleWEB: "Términos y condiciones para atletas.",
      accept: "Aceptar terminos y condiciones de uso."
    },
    notifications_answer_mails: "Email de respuesta de notificaciones",
    notifications: "Notificaciones",
    notificationsFooter: "Pie del email de notificaciones"
  },
  categoriesScreen: {
    table: {
      name: "Nombre",
      gender: "Género",
      maxAthletes: "Máximo de inscritos",
      interval: "Intervalo de edad",
      inDate: "En la fecha"
    },
    detail: {
      name: "Nombre",
      description: "Descripción",
      gender: "Género",
      ageType: "Tipo",
      minimumAge: "Edad mínima",
      maximumAge: "Edad máxima",
      ageCheckDate: "En fecha",
      maxAthletes: "Número máximo de atletas",
      mustBeGreater: "Debe ser mayor y un número",
      mustBeLower: "Debe ser menor y un número"
    },
    new: "Nueva categoría",
    sureDelete: "¿Está seguro que desea borrar esta categoría?"
  },
  modalityInscriptions: {
    table: {
      inscriptionDate: "Fecha de inscripción",
      athleteDni: "DNI",
      athleteFullName: "Atleta",
      athleteEmail: "Correo electrónico",
      inscriptionStatus: "Estado",
      locator: "Localizador",
      operationCode: "Código de operación",
      origin: "Origen"
    }
  },
  modalityTeamType: {
    addNewTeam: "Agregar nuevo equipo",
    addNew: "Agregar Nuevo",
    delete: "¿Está seguro que desea borrar este tipo de equipo?",
    info:
      "No se puede editar/borrar. Hay atletas inscritos con este tipo de equipo.",
    messages: {
      minMaxThanMax:
        "Mínimo de atletas debe ser un número y no puede ser mayor al Máximo de atletas",
      maxMinThanMin:
        "Máximo de atletas debe ser un número y no puede ser menor al Mínimo de atletas"
    },
    table: {
      name: "Nombre",
      gender: "Género",
      minAthletes: "Mínimo de atletas",
      maxAthletes: "Máximo de atletas"
    }
  },
  modalityAttributes: {
    table: {
      createdAt: "Fecha de creación",
      name: "Nombre",
      type: "Tipo de campo",
      required: "Obligatorio",
      visible: "Visible",
      isToBeDelivered: "Será entregado"
    },
    createAttribute: "Crear atributo",
    eraseAttribute: "¿Estás seguro de borrar este atributo?",
    types: {
      STRING: "Texto",
      NUMBER: "Numérico",
      BOOLEAN: "Sí/No",
      SELECT: "Opciones",
      PRODUCT: "Producto"
    },
    newAttribute: {
      name: "Nombre",
      required: "Obligatorio",
      visible: "Visible",
      description: "Descripción",
      moreInfo: "Más Información",
      linkForMoreInfo: "Enlace para más información",
      linkForMoreInfoPlaceHolder: "https://algunaURL.com",
      linkForMoreInfoValidation:
        "Debe tener formato de url como https://algunaURL.com",
      type: "Tipo del campo",
      maxLength: "Máxima longitud",
      maxLengthPlaceholder: "Por defecto es 255 caracteres",
      attributePrice: "Precio del atributo",
      attributePricePlaceholder: "Por defecto es gratis",
      optionsAtLeastOne:
        "Todos los campos de las opciones deben estar con un formato correcto",
      options: "Opciones",
      optionLimit: "Límite",
      priceType: "Tipo de precio",
      optionPrice: "Precio individual",
      fixedPrice: "Precio fijo",
      variablePrice: "Precio variable por opción",
      addOption: "Añadir opción",
      warningAboutAttributeOptions:
        "No se puede editar este atributo porque ya ha sido usado en alguna inscripción.\n Puede ocultar este atributo del formulario y crear y mostrar uno nuevo con los valores deseados",
      attributeImage: "Imagen del atributo",
      showInReceipt: "Mostrar en justificante de pago",
      toBeDelivered: "Es entregable",
    },
    errors: {
      NAME_EXIST: "No puede haber nombres repetidos en atributos visibles",
      PRICE_IN_USE:
        "El precio para {{field}} no puede ser cambiado, ya se esta utilizando",
      TYPE_IN_USE:
        "El tipo de campo no puede ser cambiado, ya se esta utilizando",
      ATTRIBUTE_IN_USE: "Este atributo ya se encuentra en uso",
      ALREADY_EXIST: "El campo {{field}} ya existe",
      PRICE_IN_USE_ATTRIBUTE:
        "El precio no se puede cambiar, ya se encuentra en uso"
    }
  },
  roles: {
    ADMINISTRATOR: "Administrador",
    COMPANY: "Empresa",
    PLANNER: "Organizador",
    POINT_OF_SALE: "Punto de Venta",
    ATHLETE: "Atleta",
    GUEST: "Invitado",
    QR_READER: "Lector QR",
  },
  status: {
    ACTIVE: "Activo",
    BLOCKED: "Bloqueado",
    NOT_CONFIRMED: "No Confirmado",
    DELETED: "Borrado"
  },
  paymentStatus: {
    PAID: "Pagado",
    PENDING: "Pendiente",
    REJECTED: "Rechazado",
    DENIED: "Denegado",
    CANCELLED: "Anulado"
  },
  inscriptionStatus: {
    NOT_STARTED_YET: "Aún no disponible",
    CLOSED: "Cerrado",
    MODALITY_FINISHED: "Evento finalizado",
    FULL_MODALITY: "Evento completo",
    FULL_RACE: "Carrera completa",
    ENABLED: "Abierto",
    NOT_PUBLISHED: "No publicada",
    CLOSED_ENABLED: "Inscribir (Completado)",
  },
  validation: {
    phone: "Sólo se admiten números"
  },
  dateTimePicker: {
    date: "Fecha",
    hour: "Hora",
    minute: "Min.",
    from: "Desde",
    to: "Hasta"
  },
  countries: {
    ES: "España",
    AF: "Afganistán",
    AL: "Albania",
    DZ: "Argelia",
    AS: "Samoa Americana",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguila",
    AQ: "Antártida",
    AG: "Antigua y Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaiyán",
    BS: "Bahamas",
    BH: "Bahrein",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarús",
    BE: "Bélgica",
    BZ: "Belice",
    BJ: "Benin",
    BM: "Bermudas",
    BT: "Bhután",
    BO: "Bolivia",
    BA: "Bosnia y Herzegovina",
    BW: "Botswana",
    BV: "Isla Bouvet",
    BR: "Brasil",
    IO: "Territorio Británico del Océano Índico",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Camboya",
    CM: "Camerún",
    CA: "Canadá",
    CV: "Cabo Verde",
    KY: "Islas Caimán",
    CF: "República Centroafricana",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Isla de Navidad",
    CC: "Islas Cocos (Keeling)",
    CO: "Colombia",
    KM: "Comoras",
    CG: "Congo",
    CD: "Congo (República Democrática del)",
    CK: "Islas Cook",
    CR: "Costa Rica",
    CI: "Costa de Marfil",
    HR: "Croacia",
    CU: "Cuba",
    CY: "Chipre",
    CZ: "República Checa",
    DK: "Dinamarca",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "República Dominicana",
    EC: "Ecuador",
    EG: "Egipto",
    SV: "El Salvador",
    GQ: "Guinea Ecuatorial",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Etiopía",
    FK: "Islas Malvinas",
    FO: "Islas Feroe",
    FJ: "Fiji",
    FI: "Finlandia",
    FR: "Francia",
    GF: "Guayana Francesa",
    PF: "Polinesia Francesa",
    TF: "Tierras Australes Francesas",
    GA: "Gabón",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Alemania",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grecia",
    GL: "Groenlandia",
    GD: "Granada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GN: "Guinea",
    GW: "Guinea Bissau",
    GY: "Guyana",
    HT: "Haití",
    HM: "Heard e Islas McDonald",
    VA: "Santa Sede",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungría",
    IS: "Islandia",
    IN: "India",
    ID: "Indonesia",
    IR: "Irán (República Islámica de)",
    IQ: "Iraq",
    IE: "Irlanda",
    IL: "Israel",
    IT: "Italia",
    JM: "Jamaica",
    JP: "Japón",
    JO: "Jordania",
    KZ: "Kazajstán",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "República Popular Democrática de Corea",
    KR: "República de Corea",
    KW: "Kuwait",
    KG: "Kirguistán",
    LA: "República Democrática Popular de Lao",
    LV: "Letonia",
    LB: "Líbano",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libia",
    LI: "Liechtenstein",
    LT: "Lituania",
    LU: "Luxemburgo",
    MO: "Macao",
    MK: "Macedonia del Norte",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malasia",
    MV: "Maldivas",
    ML: "Malí",
    MT: "Malta",
    MH: "Islas Marshall",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauricio",
    YT: "Mayotte",
    MX: "México",
    FM: "Micronesia",
    MD: "Moldavia",
    MC: "Mónaco",
    MN: "Mongolia",
    MS: "Montserrat",
    MA: "Marruecos",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Países Bajos",
    NC: "Nueva Caledonia",
    NZ: "Nueva Zelanda",
    NI: "Nicaragua",
    NE: "Níger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Isla Norfolk",
    MP: "Isla Marianas del Norte",
    NO: "Noruega",
    OM: "Omán",
    PK: "Pakistán",
    PW: "Palau",
    PS: "Palestina",
    PA: "Panamá",
    PG: "Papua Nueva Guinea",
    PY: "Paraguay",
    PE: "Perú",
    PH: "Filipinas",
    PN: "Pitcairn",
    PL: "Polonia",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunión",
    RO: "Rumania",
    RU: "Rusia",
    RW: "Rwanda",
    SH: "Santa Helena, Ascensión y Tristán de Acuña",
    KN: "Saint Kitts y Nevis",
    LC: "Santa Lucía",
    PM: "San Pedro y Miquelón",
    VC: "San Vicente y las Granadinas",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Santo Tomé y Príncipe",
    SA: "Arabia Saudita",
    SN: "Senegal",
    SC: "Seychelles",
    SL: "Sierra Leona",
    SG: "Singapur",
    SK: "Eslovaquia",
    SI: "Eslovenia",
    SB: "Islas Salomón",
    SO: "Somalia",
    ZA: "Sudáfrica",
    GS: "Georgia del Sur y las Islas Sandwich del Sur",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard y Jan Mayen",
    SZ: "Swazilandia",
    SE: "Suecia",
    CH: "Suiza",
    SY: "República Árabe Siria",
    TW: "Taiwán",
    TJ: "Tayikistán",
    TZ: "Tanzania",
    TH: "Tailandia",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad y Tobago",
    TN: "Túnez",
    TR: "Turquía",
    TM: "Turkmenistán",
    TC: "Islas Turcas y Caicos",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ucrania",
    AE: "Emiratos Árabes Unidos",
    GB: "Reino Unido",
    US: "Estados Unidos",
    UM: "Islas Ultramarinas Menores de los Estados Unidos",
    UY: "Uruguay",
    UZ: "Uzbekistán",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Islas Vírgenes británicas",
    VI: "Islas Vírgenes de los Estados Unidos",
    WF: "Wallis y Futuna",
    EH: "Sahara Occidental",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    AX: "Islas Åland",
    BQ: "Bonaire, San Eustaquio y Saba",
    CW: "Curaçao",
    GG: "Guernsey",
    IM: "Isla de Man",
    JE: "Jersey",
    ME: "Montenegro",
    BL: "Saint Barthélemy",
    MF: "Saint Martin (francesa)",
    RS: "Serbia",
    SX: "Sint Maarten (neerlandesa)",
    SS: "Sudán del Sur",
    XK: "Kosovo"
  }
};

export default spanishTranslations;
