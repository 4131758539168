import React, { Component } from "react";
import {
  Responsive,
  Menu,
  Container,
  Header,
  Accordion,
  Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { backwardsItems, items } from "./util";

@withTranslation()
@connect(state => ({
  modality: state.modalities.actualModality,
  race: state.races.actualRace,
}))
export default class ModalityMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      responsiveOpenAccordion: false
    };
  }

  render() {
    const { t, raceId, modalityId, activeItem, race } = this.props;
    const { responsiveOpenAccordion } = this.state;

    const isEvent = race?.activityType?.type === "EVENT";
    const isAssociate = race?.activityType?.type === "ASSOCIATES";
    const backItems = backwardsItems(t, raceId, isEvent, isAssociate);
    const menuItems = items(t, isEvent || isAssociate);

    return (
      <Container>
        <Responsive minWidth={1100}>
          <Menu secondary>
            {backItems.map((item, key) => (
              <Menu.Item
                key={`${key}-${item.icon}`}
                icon={item.icon}
                name={item.title}
                onClick={() => this.props.onItemClick(item.customOnClick)}
              />
            ))}
            {menuItems.map((item, key) => (
              <Menu.Item
                key={`${key}-${item.name}`}
                name={item.title}
                active={activeItem === item.name}
                onClick={() =>
                  this.props.onItemClick(
                    `/race/${raceId}/modality/${modalityId}#${item.href}`
                  )
                }
              />
            ))}
          </Menu>
        </Responsive>

        {/* Responsive on small screens */}

        <Responsive maxWidth={1100}>
          <Accordion>
            <Accordion.Title
              active={responsiveOpenAccordion}
              onClick={() => {
                this.setState({
                  responsiveOpenAccordion: !this.state.responsiveOpenAccordion
                });
              }}
            >
              <Icon name="browser" />
              <Icon name="dropdown" />
            </Accordion.Title>
            <Accordion.Content active={responsiveOpenAccordion}>
              <Menu vertical fluid>
                {backItems.map((item, key) => (
                  <Menu.Item
                    key={`${key}-${item.icon}`}
                    name={item.title}
                    onClick={() => this.props.onItemClick(item.customOnClick)}
                  >
                    <Header as="h4">
                      <Icon name={item.icon} />
                      {item.title}
                    </Header>
                  </Menu.Item>
                ))}
                {menuItems.map((item, key) => (
                  <Menu.Item
                    key={`${key}-${item.name}`}
                    name={item.title}
                    active={activeItem === item.name}
                    onClick={() =>
                      this.props.onItemClick(
                        `/race/${raceId}/modality/${modalityId}#${item.href}`
                      )
                    }
                  >
                    <Header as="h4">{item.title}</Header>
                  </Menu.Item>
                ))}
              </Menu>
            </Accordion.Content>
          </Accordion>
        </Responsive>
        <br />

        {this.props.children}
      </Container>
    );
  }
}
