import React from "react";
import Form from "../../../components/Form";
import { useTranslation } from "react-i18next";

export default function FormTeam({
  flag,
  showValidation,
  defaultState,
  onFormChange
}) {
  const [t] = useTranslation();
  return (
    <Form
      showValidation={showValidation}
      defaultState={defaultState}
      onFormChange={formData => onFormChange(formData)}
      fields={[
        {
          fields: [
            {
              name: "name",
              type: "Input",
              placeHolder: t("modalityTeamType.table.name"),
              title: t("modalityTeamType.table.name"),
              colSize: 16,
              validation: {
                required: true,
                errorMessage: t("commons.fieldRequired")
              },
              props: {
                readOnly: flag
              }
            }
          ]
        },
        {
          fields: [
            {
              name: "minAthletes",
              type: "Input",
              placeHolder: t("modalityTeamType.table.minAthletes"),
              title: t("modalityTeamType.table.minAthletes"),
              colSize: 16,
              value: 2,
              props: {
                min: 2,
                type: "number",
                readOnly: flag
              },
              validation: {
                required: true,
                custom: values => !(values.minAthletes > values.maxAthletes),
                errorMessage: t("modalityTeamType.messages.minMaxThanMax"),
                // regexType: "number"
              }
            },
            {
              name: "maxAthletes",
              type: "Input",
              placeHolder: t("modalityTeamType.table.maxAthletes"),
              title: t("modalityTeamType.table.maxAthletes"),
              colSize: 16,
              value: 5,
              props: {
                type: "number",
                readOnly: flag
              },
              validation: {
                required: true,
                custom: values => !(values.maxAthletes < values.minAthletes),
                errorMessage: t("modalityTeamType.messages.maxMinThanMin"),
                // regexType: "number"
              }
            }
          ]
        },
        {
          fields: [
            {
              name: "gender",
              type: "RadioButtons",
              title: t("modalityTeamType.table.gender"),
              value: "BOTH",
              radios: [
                { label: t("commons.gender.MALE"), value: "MALE" },
                { label: t("commons.gender.FEMALE"), value: "FEMALE" },
                { label: t("commons.gender.BOTH"), value: "BOTH" }
              ],
              colSize: 16,
              validation: {
                required: true,
                errorMessage: t("commons.fieldRequired")
              },
              props: {
                disabled: flag,
                readOnly: flag
              }
            }
          ]
        }
      ]}
    />
  );
}
