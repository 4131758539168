import React from "react";
import racesApi from "../../../apis/races";
import FormComponents from "../../../components/FormComponents";
import { Button, Icon, Form, Header } from "semantic-ui-react";
import { modalHandler } from "../../../components/DModal";
import { copyTextToClipboard } from "../../../util/util";

const getWidget = async (slug, t) => {
  await racesApi.getWidget(slug).then(response => {
    const modalOptions = {
      header: true,
      content: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          {" "}
          <Header
            as="h4"
            content={response}
            style={{
              margin: "0 1% 0 0",
              background: "#E9E9F3",
              padding: "1%",
              borderRadius: 4
            }}
          />
          <Button onClick={() => copyTextToClipboard(response)}>
            <Icon name="copy" />
          </Button>
        </div>
      ),
      actions: <div></div>
    };
    modalHandler.open(modalOptions);
  });
};

export const formFields = (t, deleted) => {
  return [
    {
      fields: [
        {
          name: "name",
          title: t("raceDetail.detail.generalInformation.name"),
          placeHolder: t("raceDetail.detail.generalInformation.name"),
          type: "Input",
          colSize: 5,
          validation: {
            required: true
          },
          props: {
            readOnly: true
          }
        },

        {
          name: "slug",
          title: t("raceDetail.detail.generalInformation.slug"),
          placeHolder: t("raceDetail.detail.generalInformation.slug"),
          type: "Input",
          colSize: 5,
          validation: {
            required: true
          },
          props: {
            readOnly: true
          }
        },

        {
          name: "start_date",
          title: t("raceDetail.detail.generalInformation.date"),
          placeHolder: t("raceDetail.detail.generalInformation.date"),
          type: "DatePicker",
          colSize: 5,
          validation: {
            required: true
          },
          props: {
              id :'0',
            clearable: false,
            readOnly: true
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "company",
          title: t("raceDetail.detail.generalInformation.company"),
          placeHolder: t("raceDetail.detail.generalInformation.company"),
          type: "Input",
          colSize: 5,
          validation: {
            required: true
          },
          props: {
            readOnly: true
          }
        },

        {
          name: "start_city",
          title: t("raceDetail.detail.generalInformation.place"),
          placeHolder: t("raceDetail.detail.generalInformation.place"),
          type: "Input",
          colSize: 5,
          validation: {
            required: true
          },
          props: {
            readOnly: true
          }
        },

        {
          name: "is_published",
          title: t("raceDetail.detail.generalInformation.published"),
          placeHolder: t("raceDetail.detail.generalInformation.published"),
          type: "Checkbox",
          colSize: 5,
          props: {
            readOnly: true
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "race_url",
          colSize: 16,
          component: (_, data) => (
            <Form.Group widths="16" style={{ alignItems: "flex-end" }}>
              <FormComponents.Input
                title={t("raceDetail.detail.generalInformation.webUrl")}
                value={data.race_url}
                props={{ readOnly: true }}
                colSize={6}
              />
             {!deleted && <div style={{ marginBottom: 5 }}>
                <Button
                  onClick={() => copyTextToClipboard(data.race_url)}
                  animated
                >
                  <Button.Content visible>
                    <Icon name="copy" />
                  </Button.Content>
                  <Button.Content hidden>
                    {t("raceDetail.detail.generalInformation.copy")}
                  </Button.Content>
                </Button>
                <Button onClick={() => getWidget(data.slug)}>
                  <Icon name="download" /> {t("raceDetail.detail.widget")}
                </Button>
              </div>}
            </Form.Group>
          )
        }
      ]
    }
  ];
};

export const urlInput = t => {
  return [
    {
      fields: [
        {
          name: "url",
          title: "URL",
          placeHolder: "url",
          type: "Input",
          colSize: 16,
          validation: {
            required: true
          }
        }
      ]
    }
  ];
};
