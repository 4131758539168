//@ts-check
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Container,
  Modal,
  Grid,
  Button,
  Dimmer,
  Loader,
  Tab
} from "semantic-ui-react";
import { DAlert } from "../../../components/Dalert";
import moment from "moment";
import Form from "../../../components/Form";

import DTable from "../../../components/DTable";

import racesApi from "../../../apis/races";
import { tableHeaders } from "./util";

import { modalHandler } from "../../../components/DModal";
import Match from "../../../components/Match";
import { remove } from 'lodash';

const MakeMatchModal = ({ matches, mainFields, onChange }) => (
  <div>
    <Match headers={matches} mainFields={mainFields} onChange={onChange} />
  </div>
);

@withTranslation()
@connect(
  (state) => ({
    race: state.races.actualRace,
  })
)
export default class Classifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { modalities: [], classifications: [] },
      totalData: 0,
      loading: false,
      modalities: [],
      file: null,
      isModalLoading: false,
      newMatches: null,
      formData: {},
      flag: true
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getData();
    this.setState({ loading: false });
  }

  async getData() {
    const data = await racesApi.fetchClassifications({
      id: this.props.id
    });

    this.setState({ data });
  }

  async onFormSubmit(e, raceId, modalityId, t, type) {
    e.preventDefault();
    if (this.state.newMatches && !this.state.newMatches.isValid) { return } else {
      try {
        const headerState = this.state.newMatches.headersState;
        const mobileFields = [1, 2, 3]
        let existingMobileFields = [];
        headerState.forEach(field => {
          remove(mobileFields, val => val == field.mobile);
          if (field.mobile) { existingMobileFields.push(field.mobile) }
        })
        if ((mobileFields.length > 0 || existingMobileFields.length > 3) && this.state.formData.make_map) {
          DAlert.error({ title: t("raceClassifications.uploadfile.error_mobile") });
          return;

        }
      } catch (e) { }
    }


    this.setState({ isModalLoading: true });

    const response = await racesApi.uploadFile({
      raceId,
      modalityId,
      file: this.state.file,
      ...this.state.formData,
      newMatches: this.state.newMatches
        ? this.state.newMatches.headersState
        : null
    });

    if (response.createMatches) {
      this.setState({ isModalLoading: false });
      modalHandler.open({
        header: "Reasignar valores",
        content: (
          <MakeMatchModal
            matches={response.matches}
            mainFields={response.mainFields}
            onChange={newMatches => this.setState({ newMatches })}
          />
        ),
        actions: (
          <Grid>
            <Grid.Column width="16">
              <Button
                fluid
                color="vk"
                onClick={async e => {
                  const { flag } = this.state
                  const { headersState } = this.state.newMatches
                  const finishTimeMach = headersState.some((element) => element.match === "finish_time")
                  if (flag && !finishTimeMach) {
                    DAlert.info({ title: t("raceClassifications.uploadfile.finishTime") })
                    await this.setState({ flag: false })
                  } else {
                    this.onFormSubmit(e, raceId, modalityId, t, type);
                  }
                }}
              >
                {t("commons.ok")}
              </Button>
            </Grid.Column>
          </Grid>
        )
      });

      return;
    }

    this.setState({ isModalLoading: false, file: null });
    if (response.success) {
      modalHandler.close();

      this.setState({ newMatches: null, data: response.data });
      // this.getData();

      DAlert.success({ title: t("commons.fileSuccess") });
    } else {
      this.setState({
        isModalLoading: false,
        newMatches: null,
        file: null
      });
      modalHandler.close();
      if (response.response.data.error.message) {
        const errMsg = response.response.data.error.message;
        DAlert.error({ title: t(`raceClassifications.error.${errMsg}`) });
      } else {
        DAlert.error({ title: t("commons.fileError") });
      }
    }
  }
  async onChange(e) {
    await this.setState({ file: e.target.files[0] });
  }

  addFile(t, raceid, modalityId) {
    try {
      const { isModalLoading } = this.state;
      modalHandler.open({
        header: t(`commons.fileUpload`),
        size: "tiny",
        content: (
          <Modal.Description>
            <Grid padded>
              <Grid.Row>
                <Grid.Column width="16">
                  <h2>{t("commons.selectFile")}</h2>
                  <Form
                    onFormChange={formData => this.setState({ formData })}
                    fields={[
                      {
                        fields: [
                          {
                            name: "name",
                            type: "Input",
                            title: t("raceClassifications.uploadfile.name"),
                            colSize: 16,
                            validation: {
                              required: true,
                              errorMessage: t("commons.fieldRequired")
                            }
                          }
                        ]
                      },
                      {
                        fields: [
                          {
                            name: "make_map",
                            type: "Checkbox",
                            title: t("raceClassifications.uploadfile.make_map"),
                            colSize: 16,
                            value: true
                          }
                        ]
                      }
                    ]}
                  />
                  <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={this.onChange}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Description>
        ),
        actions: (
          <Grid>
            <Grid.Column width="16">
              <Button
                fluid
                color="vk"
                onClick={e => {
                  modalHandler.close();
                  this.onFormSubmit(e, raceid, modalityId, t);
                  this.setState({ newMatches: null });
                }}
              >
                {t("commons.ok")}
              </Button>
            </Grid.Column>
          </Grid>
        )
      });
    } catch (e) { }
  }

  deleteClassification(t, raceId, modalityId, classificationId) {
    try {
      modalHandler.open({
        header: t("raceClassifications.modal.title"),
        content: (
          <Modal.Description>{t("commons.areYouSure")}</Modal.Description>
        ),
        basic: false,
        size: "tiny",
        onYes: async () => {
          try {
            const result = await racesApi.deleteClassification({
              raceId,
              modalityId,
              classificationId
            });
            this.getData();
            modalHandler.close();
            DAlert.success({ title: t("commons.erasedSuccessfully") });
          } catch (error) {
            DAlert.error({ title: t("commons.errorErased") });
          }
        }
      });
    } catch (error) { }
  }

  getDateTime = date => {
    return date
      ? moment(date).format("L") + " - " + moment(date).format("LT")
      : "---";
  };

  render() {
    const { t, history, race } = this.props;
    const { data, loading, isModalLoading } = this.state;

    const panes = [{ menuItem: "Individual" }, { menuItem: "Club" }];
    return (
      <div>
        <Container>
          <Tab
            panes={data.modalities.map(mod => ({
              menuItem: mod.name,
              render: () => (
                <div>
                  <DTable
                    // style={{ cursor: "default" }}
                    headers={tableHeaders(t)}
                    contentData={data.clasifications.filter(
                      d => d.modality_id == mod.id
                    )}
                    parseData={row => ({
                      date: this.getDateTime(row.createdAt),
                      last_update: this.getDateTime(row.updatedAt),
                      remove: row.total > 0 ? false : "" //(()=>this.deleteClassification(t, this.props.id, data.modality_id))
                    })}
                    onRowClick={
                      data =>
                        data.total > 0
                          ? this.deleteClassification(
                            t,
                            this.props.id,
                            data.modality_id,
                            data.id
                          )
                          : null

                      // history.push(`/race/${this.props.id}/classification/${data.modality_id}`)
                    }
                    loading={loading}
                    showPagination={false}
                    showItemsPerPage={false}
                  />
                  {!race.deleted && <Button
                    onClick={() => {
                      this.addFile(t, this.props.id, mod.id);
                    }}
                  >
                    {`${t("raceClassifications.addNewClassificiation")} ${
                      mod.name
                      }`}
                  </Button>}
                </div>
              )
            }))}
          />

          <Dimmer
            active={isModalLoading}
            style={{ position: "fixed", backgroundColor: "rgba(0, 0, 0, 0.2)" }}
          >
            <Loader />
          </Dimmer>
        </Container>
      </div>
    );
  }
}
