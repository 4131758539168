export const tableHeaders = (t, params) => {
  return [
    {
      content: t("inscriptionsScreen.table.date"),
      accessor: "inscriptionDate",
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("inscriptionsScreen.table.modalityName"),
      accessor: "modalityName",
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("inscriptionsScreen.table.dni"),
      accessor: "athleteDni",
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("inscriptionsScreen.table.athleteFullName"),
      accessor: "athleteFullName",
      headerProps: {
        width: 2,
      },
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("inscriptionsScreen.table.athleteEmail"),
      accessor: "athleteEmail",
      headerProps: {
        width: 3,
      },
      props: { style: { wordBreak: "break-word" } },
    },
    // {
    //   content: t("inscriptionsScreen.table.inscriptionStatus"),
    //   accessor: "inscriptionStatus"
    // },
    {
      content: t("inscriptionsScreen.table.inscriptionStatus"),
      accessor: "combinedStatus",
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("modalityInscriptions.table.locator"),
      accessor: "athleteLocator",
      headerProps: {
        width: 1,
      },
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("modalityInscriptions.table.operationCode"),
      accessor: "paymentOperation_code",
      props: { style: { wordBreak: "break-word" } },
    },
    {
      content: t("modalityInscriptions.table.origin"),
      accessor: "inscriptionFrom",
      headerProps: {
        width: 2,
      },
      props: { style: { wordBreak: "break-word" } },
    },
    ...(params && params.showNotFinished
      ? [
          {
            content: "",
            accessor: "inscriptionType",
            headerProps: {
              width: 1,
            },
          },
        ]
      : []),
  ];
};

export const filterFields = (t, modalities) => {
  return [
    {
      div: "row",
      fields: [
        {
          name: "date",
          placeHolder: t("inscriptionsScreen.filter.date"),
          type: "DatePicker",
          colSize: 4
        },

        {
          name: "modalityName",
          placeHolder: t("inscriptionsScreen.filter.modalityName"),
          type: "Select",
          data: modalities,
          colSize: 4
        },
        {
          name: "dni",
          placeHolder: t("inscriptionsScreen.filter.dni"),
          type: "Input",
          colSize: 4
        },
        {
          name: "name",
          placeHolder: t("inscriptionsScreen.filter.athleteFullName"),
          type: "Input",
          colSize: 4
        },
        {
          name: "origin",
          placeHolder: t("inscriptionsScreen.filter.origin"),
          data: [
            { value: "WEBAPP", text: t('commons.origin.WEBAPP') },
            { value: "BACKOFFICE", text: t('commons.origin.BACKOFFICE') },
            { value: "MOBILE", text: t('commons.origin.MOBILE') },
          ],
          type: "Select",
          colSize: 4
        }
      ]
    },
    {
      div: "row",
      fields: [
        {
          name: "email",
          placeHolder: t("inscriptionsScreen.filter.athleteEmail"),
          type: "Input",
          colSize: 5
        },
        {
          name: "tracking",
          placeHolder: t("inscriptionsScreen.filter.tracking"),
          type: "Input",
          colSize: 3,
          props:{
            type: "number"
          }
        },

        {
          name: "operationCode",
          placeHolder: t("inscriptionsScreen.filter.operationCode"),
          type: "Input",
          colSize: 4
        },
        {
          name: "status",
          placeHolder: t("inscriptionsScreen.filter.status"),
          type: "Select",
          data: [
            { value: "PAID", text: t('paymentStatus.PAID') },
            { value: "PENDING", text: t('paymentStatus.PENDING') },
            { value: "REJECTED", text: t('paymentStatus.REJECTED') },
            { value: "DENIED", text: t('paymentStatus.DENIED') },
            { value: "CANCELLED", text: t('paymentStatus.CANCELLED') }
          ],
          colSize: 3
        },
        {
          name: "showNotFinished",
          placeHolder: t("inscriptionsScreen.filter.showNotFinished"),
          type: "Checkbox",
          colSize: 3,
          props: {
            toggle: true,
            label: t("inscriptionsScreen.filter.showNotFinished"),
          }
        }
      ]
    }
  ];
};
