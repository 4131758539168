import React, { useState, useEffect } from "react";
import {
  Message,
  Grid,
  Segment,
  TransitionablePortal
} from "semantic-ui-react";

class DAlertClass {
  constructor() {
    this.open = null;
    this.setState = null;
    this.setOptions = null;
    this.options = null;
  }

  alert(options) {
    this.setState(true);
    this.setOptions({
      ...options
    });
  }

  success(options) {
    this.setOptions({
      ...options,
      color: "green"
    });
    this.setState(true);

    setTimeout(() => this.close(), 5000);
  }

  info(options) {
    this.setOptions({
      ...options,
      color: "blue"
    });
    this.setState(true);

    setTimeout(() => this.close(), 5000);
  }

  warning(options) {
    this.setOptions({
      ...options,
      color: "blue"
    });
    this.setState(true);

    setTimeout(() => this.close(), 10000);
  }

  error(options) {
    this.setOptions({
      ...options,
      color: "red"
    });
    this.setState(true);
    setTimeout(() => this.close(), 5000);
  }

  errorPlus(options) {
    this.setOptions({
      ...options,
      color: "red"
    });
    this.setState(true);
    setTimeout(() => this.close(), 10000);
  }

  close() {
    this.setState(false);
  }

  setValues(props) {
    this.setState = props.setState;
    this.open = props.open;
    this.options = props.options;
    this.setOptions = props.setOptions;
  }
}

const DAlert = new DAlertClass();

export default function DAlertComponent() {
  const [open, setState] = useState(false);
  const [options, setOptions] = useState({ title: "", message: "", color: "" });

  DAlert.setValues({ open, setState, options, setOptions });

  return (
    <Grid columns={2} style={{ marginTop: "1%", position: "absolute" }}>
      <TransitionablePortal
        open={open}
        transition={{ animation: "fly left", duration: 700 }}
      >
        <Segment
          style={{
            position: "fixed",
            zIndex: 1000,
            minWidth: "20%",
            right: 20
          }}
        >
          <Message {...(options.color && { color: options.color })}>
            <Message.Header>{options.title}</Message.Header>
            <p>{options.message}</p>
          </Message>
        </Segment>
      </TransitionablePortal>
    </Grid>
  );
}

export { DAlert };
