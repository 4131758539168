import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import RichTextEditor from "../../../../components/RichTextEditor";

import { updateRace } from "../../../../reducers/races";

@connect(
  state => ({
    race: state.races.actualRace
  }),
  dispatch => ({
    actions: bindActionCreators({ updateRace }, dispatch)
  })
)
export default class Regulation extends Component {
  onBlurHandler = htmlString => {
    this.props.actions.updateRace({
      regulation: htmlString
    });
  };

  render() {
    return (
      <RichTextEditor
        defaultContent={this.props.race?.regulation}
        onBlurEditor={this.onBlurHandler}
      />
    );
  }
}
