export const items = (t, isEventOrAssociate = false) => [
  { title: t("raceDetail.menu.detail"), name: "summary", href: "summary" },
  { title: t("raceDetail.menu.edit"), name: "edit", href: "edit" },
  {
    title: t("raceDetail.menu.inscriptions"),
    name: "inscriptions",
    href: "inscriptions"
  },
  ...(!isEventOrAssociate
    ? [
        {
          title: t("raceDetail.menu.clasifications"),
          name: "classifications",
          href: "classifications",
        },
      ]
    : []),
  { title: t("raceDetail.menu.users"), name: "users", href: "users" },
  {
    title: t("raceDetail.menu.moreOptions.discounts"),
    name: "discounts",
    href: "discounts"
  },
  {
    title: t("raceDetail.menu.moreOptions.accounts"),
    name: "accounts",
    href: "accounts"
  },
  {
    title: t("raceDetail.menu.modality.header"),
    name: "modalities",
    href: "modalities"
  },
  {
    title: t("raceDetail.menu.documents"),
    name: "documents",
    href: "documents"
  }
];

export const itemsPlanner = (t, isEventOrAssociate = false) => [
  { title: t("raceDetail.menu.detail"), name: "summary", href: "summary" },
  { title: t("raceDetail.menu.edit"), name: "edit", href: "edit" },
  {
    title: t("raceDetail.menu.inscriptions"),
    name: "inscriptions",
    href: "inscriptions"
  },
  ...(!isEventOrAssociate
    ? [
        {
          title: t("raceDetail.menu.clasifications"),
          name: "classifications",
          href: "classifications",
        },
      ]
    : []),
  { title: t("raceDetail.menu.users"), name: "users", href: "users" },
  {
    title: t("raceDetail.menu.moreOptions.discounts"),
    name: "discounts",
    href: "discounts"
  },
  {
    title: t("raceDetail.menu.modality.header"),
    name: "modalities",
    href: "modalities"
  },
  {
    title: t("raceDetail.menu.documents"),
    name: "documents",
    href: "documents"
  }
]


export const itemsPos = t => [
  { title: t("raceDetail.menu.detail"), name: "summary", href: "summary" },
  {
    title: t("raceDetail.menu.inscriptions"),
    name: "inscriptions",
    href: "inscriptions"
  },
  {
    title: t("raceDetail.menu.documents"),
    name: "documents",
    href: "documents"
  }
  // {
  //   title: t("raceDetail.menu.clasifications"),
  //   name: "clasifications",
  //   href: "clasifications"
  // }
];