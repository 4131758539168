import moment from "moment";
import { dateIsBetween,getDatesErrorMessage } from "../../../util/util";

export const createModalityForm = (
  t,
  raceTypes,
  raceStartDate,
  raceFinishDate,
  isEventOrAssociate,
) => {
  const defaultFields = [
    {
      fields: [
        {
          name: "name",
          title: t("modalities.create.name"),
          type: "Input",
          colSize: 16,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "description",
          title: t("modalities.create.description"),
          type: "TextArea",
          colSize: 16,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "date",
          title: t("modalities.create.date"),
          type: "DatePicker",
          colSize: 5,
          validation: {
            required: true,
            // errorMessage: t("commons.fieldRequired")
             custom: vals => {

               const startRaceDate = moment(raceStartDate).startOf("day");
               const finishRaceDate = moment(raceFinishDate).endOf("day");

               return dateIsBetween(startRaceDate, finishRaceDate, vals.date);
             },
             errorMessage: getDatesErrorMessage(t,raceStartDate, raceFinishDate)
          },
          props:{
            id:'date'
          }
        },
        {
            name: "time_start",
            title: t("dateTimePicker.hour"),
            colSize: !isEventOrAssociate ? 3 : 7,
            type: "Input",
            validation: {
              required: true,
              errorMessage: t("commons.fieldRequired")
            },
            props:{
              type: "time",
              id: 'time_start',
              step: 15*60
            }
        },
        ...(!isEventOrAssociate
          ? [
              {
                name: "distance",
                title: t("modalities.create.distance"),
                type: "Input",
                colSize: 4,
                validation: {
                  required: true,
                  regexType: "double",
                  errorMessage: t("commons.INVALID_DOUBLE"),
                },
              },
            ]
          : []),
        {
          name: "race_type",
          title: !isEventOrAssociate ? t("modalities.create.type") : t("modalities.create.sportType"),
          type: "Select",
          data: raceTypes,
          colSize: 4,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          }
        }
      ]
    }
  ];

  return [...defaultFields];
};
