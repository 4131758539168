const englishTranslations = {
  commons: {
    next: "Next",
    status: "Status",
    comeBack: "Back",
    someWrong: "Something went wrong...",
    goHome: "Go Home",
    invalidDate: "Invalid date.",
    save: "Save",
    return: "Back",
    cancel: "Cancel",
    close: "Close",
    new: "New",
    or: "or",
    delete: "Delete",
    fieldRequired: "This field is required",
    fieldMustBeFilled: "This field must be filled correctly",
    selectAnOption: "Select an option...",
    savedSuccess: "Saved successfully",
    erasedSuccessfully: "Erased successfully",
    errorErased: "An error has occurred deleting",
    errorSaving: "An error has occurred",
    errorReading: "Cant retrieve data",
    yes: "Yes",
    no: "No",
    areYouSure: "Are you sure?",
    endDateMustBeHigher: "The end date must be higher than the start date",
    INVALID_EMAIL:
      "Invalid email address. The email must have the following format: 'name@example.com'",
    INVALID_NUMBER: "This value has to be a number",
    INVALID_DOUBLE:
      "This value must be an integer or decimal (2 decimal places maximum).",
    mustBeNumber: "Must be a number",
    selectAll: "SELECT ALL",
    removeAll: "REMOVE ALL",
    ok: "Okay",
    send: "Send",
    selectImage: "Select Image",
    fileUpload: "File upload",
    selectFile: "Select file",
    fileSuccess: " records added",
    fileError: "Error loading file.",
    maxLength: "The filled must be valid and have a maximum length of: {{maxLength}}",
    imageTooBig: "The image is to big, it should be lower than {{maxSize}} kb",
    docTooBig: "The doc is to big, it should be lower than {{maxSize}} Mb",
    invalidImageFormat: "the file does not have a valid format, it must be an image",
    fieldsRequired:
      "There are some fields that are required, are not completed or are incorrect",
    open: "Open",
    none: "None",
    goBack: "Back",
    networkError: "Network Error",
    networkErrorMessage: "Can't connect to server. Please try again later.",
    notFoundError: "The resource could not be found.",
    notFoundMessage:
      "The resource that you requested is not available or does not exists.",
    serverError: "Something went wrong 🙁",
    serverErrorMessage:
      "Failed to retrieve data for this resource. Try again or try later.",
    gender: {
      BOTH: "Both",
      MALE: "Male",
      FEMALE: "Female"
    },
    ageType: {
      AGE: "Age",
      YEAR: "Year of birth"
    },
    invalidCiforNif: "Must be a valid NIF or CIF",
    invalidParentCiforNif:
      "The NIF/CIF is not valid or is the same as that of the person registering",
    inscriptionsNotNull: "There's already inscriptions",
    emailMustBeTheSame: "The emails must be the same",
    noResultsFound: "No results found",
    changePassword: "Change Password",
    actualPassword: "Current password",
    INVALID_PASSWORD: {
      header: "Invalid password",
      message: "The current password is not correct."
    },
    language: "Language",
    cookies: {
      title: "We value your privacy",
      description:
        "This page stores information in cookies for better operation.",
      ok: "Accept cookies",
      ko: "I decline",
      deny: "You will be redirect to login page"
    },
    origin: {
      WEBAPP: "Public Web",
      BACKOFFICE: "Backoffice",
      MOBILE: "Mobile App",
      UNKNOWN: "Unknown"
    },
    activities: {
      RACE: "Race",
      EVENT: "Event",
      ASSOCIATES: "Associate",
    },
    resetQrSuccess: "The QR reset has been a success",
    resetQrError: "Error when resetting the QR",
    resetQrErrorNotFound: "QR not checked at this time",

  },
  recoverPassword: {
    header: "This link is not valid",
    message:
      "The link is not valid or has expired. Try again to reset the password or verify that the url is correct",
    goToLogin: "Go to login",
    goToPublicWeb: "Go to GedSports",
    title: "Please enter a new password for your account",
    newPassword: "New password",
    rNewPassword: "Repeat password",
    invalidPassword: "Invalid password",
    validFormat:
      "The password must contain:\n at least 8 characters, of which at least 1 must be a lowercase letter, another a capital letter,\n another a digit and another a special character (# $ @!% & *?).\n Maximum length, 30 characters",
    NOT_SAME: "The provided passwords are not the same",
    changedTitle: "Password changed",
    changedMessage: "The password was successfully changed."
  },
  register: {
    title: "Register user",
    registerMessage: "User signed in successfully",
    password: "Password",
    passwordRepeat: "Repeat password",
    passwordsDontMatch: "The passwords don't match",
    success: "You will be sent to the login page",
    companyName: "Company name",
    loading: "Creating user...",
    tokenErrors: {
      errorInToken: "Some error has been occure with the token",
      token_not_found: "The token dosen't exist",
      token_already_used: "The token has been already used",
      token_expired: "The token has been expired"
    }
  },
  login: {
    email: "Email",
    password: "Password",
    loginText: "Log in",
    recoverText: "Forgot your password?",
    recoverHeader: "Reset password",
    recoverEmail: "Enter your email",
    recoverSend: "Send email",
    recoverEmailSent:
      "An email has been sent with instructions to reset your password",
    messages: {
      EMPTY_FIELDS: {
        header: "There are some empty fields",
        message: "Be sure to enter both fields correctly."
      },
      INVALID_PASSWORD: {
        header: "Invalid password",
        message: "The password is invalid or the user does not have a password."
      },
      EMAIL_NOT_FOUND: {
        header: "Email not found",
        message: "There is no user record corresponding to this email."
      },
      USER_NOT_EXISTS: {
        header: "Email not valid",
        message:
          "There's not user record with this email."
      },
      USER_DISABLED: {
        header: "User disabled",
        message: "The user account has been disabled by an administrator."
      },
      USER_BLOCKED: {
        header: "User blocked",
        message: "This account has been disabled by an administrator."
      },
      USER_WITHOUT_PERMISSIONS: {
        header: "This user has no permissions to access this module",
        message: "If you think this is wrong, please contact an administrator."
      },
      USER_DELETED: {
        header: "User doesnt exist",
        message: "This account has been deleted by an administrator."
      },
      NOT_CONFIRMED: {
        header: "User doesnt exist",
        message: "You must confirm your email."
      }
    },
    invalidEmailFormat:
      "Invalid email address. The email must have the following format: 'name@example.com'"
  },
  currentLanguage: "en",
  gedSports: "Ged Sports",
  navBar: {
    home: "Home",
    users: "Users",
    companies: "Companies",
    races: "Races",
    events: "Events",
    associates: "Associates",
    inscriptions: "Inscriptions",
    clients: "Athletes",
    qrReaders: "QR Readers",
    configuration: "Configuration",
    preferences: "Preferences",
    help: "Help",
    logout: "Logout",
    notifications: "Notifications"
  },
  title: {
    home: "Home",
    users: "Users",
    user: "User Detail",
    companies: "Companies",
    company: "Company Detail",
    company_new: "New Company",
    races: "Races",
    events: "Events",
    associates: "Associates",
    race: "Race",
    copyRace: "Copy Activity",
    newRace: "New Activity",
    clients: "Athletes",
    client: "Athlete",
    inscriptions: "Inscriptions",
    inscription: "Inscription",
    notFound: "",
    discount: "Discount",
    modality: "Modality",
    config: "Configuration",
    createAttribute: "Create Attribute",
    attribute: "Attribute",
    notifications: "Notifications",
    template: "Template",
    notification: "Notification",
    qrReaders: "QR Readers",
    event: "Event",
    associate: "Associate",
  },
  breadcrumbs: {
    users: "Users",
    user: "User",
    companies: "Companies",
    company: "Company",
    races: "Races",
    race: "Race",
    activity: "Activity",
    copyRace: "Copy Activity",
    newRace: "New Activity",
    clients: "Athletes",
    client: "Athlete",
    inscriptions: "Inscriptions",
    inscription: "Inscription",
    discount: "Discount",
    modality: "Modality",
    notifications: "Notifications",
    config: "Configuration",
    attribute: "Attribute",
    template: "Template",
    notification: "Notification",
    qrReaders: "QR Readers",
    events: "Events",
    associates: "Associates",
  },
  tableComponent: {
    noContent: "No records available",
    rowsPerPage: "{{number}} rows",
    totalItems: "Showing {{rowsPerPage}} out of {{total}} records"
  },

  homeScreen: {
    messages: {},
    table: {
      date: "Date",
      name: "Name",
      company: "Company",
      inscriptions: "Inscriptions",
      clasification: "Classification",
      published: "Published",
      todayInscriptions: "Hoy",
      lastWeekInscriptions: "Semana",
      lastMonthInscriptions: "Mes",
      totalInscriptions: "Inscriptions",
      activityTypeId: "Activity",
    },
    type: {
      previous: "Previous 15 days",
      upcoming: "Upcoming 15 days"
    },
    modal: {},
    createRace: "Create new",
    searchRace: "Search for an activity"
  },

  usersScreen: {
    resendEmail: "Resend Email",
    messages: {
      saveCorrect: "User saved successfully",
      emailResend: "The email was succesfull resended",
      aNewMail: "A new mail was sended to the user"
    },
    table: {
      date: "Date",
      username: "Name",
      email: "Email",
      phone: "Phone",
      web: "Web",
      role: "Role",
      status: "Status"
    },
    addUser: "Add User",
    noPlanners: "There are no registered planners users in this activity!",
    noPoints: "There are no points of sale users registered in this activity!",
    addPlanner: "Enter the email of the planner",
    deleteUser: "Delete user",
    modalDeleted: {
      deleted: "Are you sure you want to delete this user?",
      okMessage: "user has been deleted"
    },
    modal: {
      email: "Email",
      role: "Role",
      company: "User's company",
      createdSuccess: "User created successfully",
      createdSuccessMessage: "An email has been sent to the user to sign up.",
      createdSuccessButton: "Okay",
      messages: {
        OVERLAP: {
          header: "The category is overlap with another category",
          message:
            "Categories should not overlap each other, select other ages."
        },
        EMAIL_EXISTS: {
          header: "Email already in use",
          message:
            "It seems that this email is already in use. Try with another one."
        },
        NOT_COMPANY_ID: {
          header: "You have to choose a company",
          message:
            "For the company role you have to choose the company where the user will belong."
        },
        EMPTY_FIELDS: {
          header: "There are some empty fields",
          message: "Be sure to enter all fields correctly."
        }
      }
    }
  },
  clientScreen: {
    messages: {
      saveCorrect: "User saved successfully"
    },
    resetPassword: {
      beforeHeader: "Change password",
      beforeContent:
        "An email is going to be sent to {{email}} to change the password. Do you want to continue?",
      afterContent: "Email successfully sent"
    },
    table: {
      dni: "DNI",
      date: "Date",
      name: "Name",
      email: "Email",
      phone: "Phone",
      web: "Web",
      role: "Role",
      status: "Status"
    },
    detail: {
      dni: "DNI",
      date: "Date",
      name: "Name",
      surnames: "Surnames",
      email: "Email",
      phone: "Phone",
      club: "Club",
      web: "Web",
      role: "Role",
      status: "Status",
      birthday: "Birthday",
      postalCode: "Zip Code",
      country: "Country",
      region: "Region",
      city: "City",
      address: "Address",
      changePassword: "Change Password",
      blockAccount: "Block Account",
      deleteAccount: "Delete Account ",
      inscriptions: "Inscriptions",
      inscriptionsTable: {
        date: "Date",
        raceName: "Race"
      }
    },
    addUser: "Add User",
    downloadCSV: "Download CSV",
    modal: {
      createdSuccess: "User created successfully",
      createdSuccessMessage: "An email has been sent to the user to sign up.",
      createdSuccessButton: "All done"
    }
  },
  userScreen: {
    personalData: "Personal Information",
    contact: "Contact",
    delete: "Delete User",
    fields: {
      name: "Name",
      surnames: "Surnames",
      dni: "DNI",
      cif: "CIF",
      email: "Email",
      phone: "Phone",
      role: "Role",
      status: "Status",
      country: "Country",
      region: "Region",
      city: "City",
      address: "Address",
      zip_code: "ZIP Code",
      birthday: "Birthday"
    }
  },
  companyScreen: {
    table: {
      date: "Date",
      name: "Name",
      company: "Company",
      email: "Email",
      phone: "Phone"
    },
    createCompany: "Create Company"
  },
  companyDetail: {
    allowNotifications: "Allow sending notifications to athletes",
    notificationEmail: "Response notifications email",
    notificationEmailError:
      "Invalid email address. The email must have the following format: 'name@example.com'",
    name: "Name",
    email: "Email",
    cifnif: "Tax ID",
    phone: "Phone",
    managementCommission: "Managment commission",
    comisionType: "Commission type",
    comisionType_PCT: "Percentage",
    comisionType_VAL: "Value",
    address: "Address",
    contact: "Contact",
    postalCode: "Postal Code",
    country: "Country",
    province: "State",
    provinceNotFound: "State not found",
    countryNotFound: "Country not found",
    city: "City",
    tpvs: "TPVs",
    changePassword: "Change Password",
    logo: "Company logo",
    addTPV: "Add TPV",
    editTPV: "Edit TVP",
    description: "Description",
    blockCompany: {
      message: "Company blocked",
      btnMessage: {
        true: "Unblock Company",
        false: "Block Company"
      },
      modalMessage: {
        true:
          "Are you sure you want to unblock this company? ALL users of this company will have access to the application again",
        false:
          "Are you sure you want to block this company? Users of this company will not be able to access the application"
      },
      okMessage: {
        true: "Company has been blocked",
        false: "Company has been unblocked"
      }
    },
    addUser: "Add User",
    messages: {
      invalidCiforNif: "Must be a valid NIF or CIF",
      EMAIL_EXISTS: {
        header: "Email already in use",
        message:
          "It seems that this email is already in use. Try with another one."
      }
    }
  },
  qrReadersScreen: {
    table: {
      date: "Date",
      name: "Name",
      surname: "Surnames",
      email: "Email",
      phone: "Phone",
      enabled: "Enabled",
      createdAt: "Created at",
      updatedAt: "Updated at",
      status: "Status"
    },
    createUser: "Create User"
  },
  racesScreen: {
    table: {
      date: "Date",
      name: "Name",
      company: "Company",
      planners: "Planners",
      email: "Email",
      locality: "Location",
      type: "Type",
      region: "State",
      published: "Publicada",
      enable: "User successfully enabled",
      disable: "User successfully disabled"
    },
    detail: {
      name: "Name",
      company: "Company",
      raceType: "Race Type",
      startDate: "Start Date",
      endDate: "End Date",
      country: "Country",
      region: "State",
      city: "City",
      lastEdition: "Last edition",
      optionsToCopy: "Options to copy",
      raceAdvanced: "Advanced settings",
      modalities: "Modalities",
      advancedModalities: "Advanced settings of modalities",
      categories: "Categories",
      teamTypes: "Team Types",
      attributes: "Attributes",
      prices: "Prices",
      deleted: "This race was deleted",
      notValidMaxInscriptions:
        "The registration limit cannot be less than the total limit of its modalities.",
      activityType: "Activity Type",
    },
    modal: {
      dateIsBefore: "Start date is before today"
    },
    errors: {
      endDateGreaterThanStartDate:
        "The end date must be greater than the start date",
      startDateGreaterThanToday: "The start date must be greater than today"
    },
    race: "Activity to copy from",
    createRace: "Create Activity",
    copyRace: "Copy Activity from",
    copyRacePrice:
      "The prices have been created with the original dates, check them BEFORE publishing the activity.",
    noModalities: "There are no modalities for this activity",
    startDateLowerThanRaceDate: "The date should be before than {{startDate}}",
    startDateGreaterThanRaceDate: "The date should be after than {{startDate}}",
    limitDateShouldBe: "Date should be {{date}}",
    limitDateBetween:
      "The deadline should be between this dates: {{startDate}} - {{finishDate}}",
    limitDateBetweenRace:
      "The deadline should be between the activity limit dates, between {{startDate}} and {{finishDate}}",
    limitDateBeforeEnd:
      "The deadline should be the same or lower than the finish date of the activity, before {{finishDate}}"
  },
  raceDetail: {
    menu: {
      detail: "Detail",
      edit: "Edit",
      inscriptions: "Inscriptions",
      clasifications: "Classifications",
      users: "Users",
      modality: {
        header: "Modalities",
        detail: "Detail",
        edit: "Edit",
        race: "Race",
        event: "Event",
        associate: "Associate",
        modalities: "Modalities",
        inscriptionsConfiguration: "Inscription settings",
        prices: "Prices",
        inscriptions: "Inscriptions",
        attributes: "Attributes",
        categories: "Categories",
        teamTypes: "Team types",
        diploma: "Diploma"
      },
      moreOptions: {
        header: "More options",
        discounts: "Discounts",
        accounts: "Accounts"
      },
      documents: "Documents"
    },
    detail: {
      inscriptions: " inscriptions",
      widget: "Get widget",
      generalInformation: {
        divider: "General information",
        name: "Name",
        slug: "Slug",
        date: "Date",
        company: "Company",
        place: "Place",
        published: "Published",
        webUrl: "Public URL",
        copy: "Copy"
      },
      events: {
        divider: "Modalities",
        name: "Name: ",
        date: "Date: ",
        inscriptionUrl: "Web inscription",
        adminInscriptionUrl: "Panel inscriptions",
        clasificationUrl: "Classifications"
      },
      header: "Header image",
      footer: "Footer image",
      preview: "Preview",
      exampleName: "NAME"
    },
    documents: {
      table: {
        id: "Id",
        name: "Name",
        publicName: "Public Document Name",
        path: "Link",
        mimeType: "Type",
        isPublic: "Public",
        document: "Document",
        createdAt: "Created at",
        uploadedBy: "Uploaded by"
      },
      createDoc: "Upload document",
      updateDoc: "Update document",
      deleteDoc: "Delete document",
      fileWithExtension: "The filename must contain an extension"
    }
  },
  raceEdit: {
    noPublishModality:
      "To publish this activity there must be at least one associated modality published",
    fieldEmpty: "must complete the field {{field}}",
    areYouSureToDeletRace: "The activity has {{totalInscriptions}} inscriptions.",
    delete: "Delete activity",
    deletedSuccess: "Activity deleted successfully",
    cantDelete: "This activity can't be deleted",
    basicInformationTab: "Basic Information",
    regulationTab: "Regulation",
    customTab: "Customize",
    advancedTab: "Advanced",
    publishRace: "Publish activity",
    notPublishRace: "Remove activity publication",
    areYouSureToPublish: " Are you sure you want to publish this activity?",
    areYouSureToNotPublish:
      "Are you sure you want to delete the publication for this activity?",
    buttons: {
      saveAndPublish: "Save and Publish",
      saveAndNotPublish: "Save and Not Publish"
    },
    customizeTabContent: {
      voucherImage: "Header image of voucher. Approximate dimensions: ({{dimensions}})",
      headerImage: "Activity Poster. Approximate dimensions: ({{dimensions}})",
      headerEmailImage:
        "Header email image. Approximate dimensions: ({{dimensions}})",
      emailText: "Content of email",
      commercialText: "Content of the commercial use of consumer data"
    },
    basicTabContent: {
      name: "Name",
      startDate: "Start date",
      finishDate: "Finish date",
      raceType: "Race type",
      sportType: "Sport type",
      country: "Country",
      region: "Region",
      city: "City",
      refundPolicy: "Return Policy",
      dorsalPickupPlace: "Dorsal Pickup",
      exitPlaceInfo: "Information on the start location",
      contact: "Contact",
      contactEmail: "Email",
      contactPhone: "Phone",
      finishDateMustBeGreater: "Finish date must be greater than start date",
      imagesSection: "Images",
      cardImage: "Card Image. Approximate dimensions: ({{dimensions}})",
      bannerImage: "Header Image. Approximate dimensions: ({{dimensions}})",
      celebrationPlace: "Information about the event celebration place",
      schedule: "Horario",
    },
    advancedTabContent: {
      tpvProfile: "TPV Profile",
      maxInscriptions: "Maximum number of inscriptions",
      inscriptionCommission: "Inscription Commission",
      inscriptionCommissionType: "Commission Type",
      showInscriptionCounter: "Show inscription counter",
      showInscriptionModalityCounter: "Show inscription modality counter",
      showInscriptionList: "Show inscription list",
      autoDorsalAssignment: "Auto dorsal assignment",
      firstDorsalToAssign: "First dorsal to asign",
      dorsalAssignmentBy: "Dorsal assignment by:",
      dorsalAssignmentByRace: "Race",
      dorsalAssignmentByModality: "Modality",
      PCT: "Percentage",
      VAL: "Value",
      virtualDorsal: "Virtual dorsal",
      copernicoRaceId: "Copernico Race Id",
      under14_dni: "ID required for children under 14 years of age",
      resetQr: "Resetear QR"
    }
  },
  raceDiscounts: {
    table: {
      createdAt: "Created",
      discountCode: "Discount Code",
      value: "Amount",
      used: "Used",
      notUsed: "Not Used",
      description: "Description",
      limitDate: "Limit Date",
      booked: "Booked",
      amount: "Importe"
    },
    detail: {
      info: "Discount Detail",
      usedByName: "Used by (Name)",
      usedByEmail: "Used by (Email)",
      usedOn: "Used At"
    },
    createDiscount: "Create Discount",
    createForm: {
      totalCodes: "Total codes",
      limitDate: "Deadline",
      type: "Discount Type",
      typePCT: "Percentage",
      typeVAL: "Total",
      amount: "Amount",
      description: "Description"
    },
    modal: {
      editDiscount: "Edit discount",
      cannotDelete:
        "You cannot delete this discount since is being used in a inscription."
    }
  },
  raceUsers: {
    enable: "User enabled succesfully",
    disable: "User disabled succesfully",
    noPlanners: "There's no registered planners for this activity!",
    noPoints: "There's no registered points of sale for this activity!",
    noQrs: "There's no registered qr readers for this activity!",
    EMAIL_EXISTS: "Email already exists.",
    EMAIL_NOT_FOUND: "Email not found",
    invalidRol: "Email already exists with a different role",
    add: {
      planner: "Input planner user's email",
      sale: "Input point of sale user's email",
      qrReader: "Input QR reader user's email",
      tittlePlanner: "Planner",
      tittlePOS: "Point of sale",
      qrReader: "QR reader",
      userPlanner: "Do you want to create an planner user with this email?",
      userSale: "Do you want to create an point of sale user with this email?",
      userQR: "Do you want to create a QR reader user with this email?",
    },
    loader: {
      planner: "Adding planner user. Please wait",
      sale: "Adding point of sale user. Please wait",
      qrReader: "Adding QR reader user. Please wait"
    },
    modal: {
      email: "Email"
    }
  },
  inscriptionsScreen: {
    inscriptionsNotEnable: "There are no modalities to register for",
    inscriptionButton: "Sign up",
    downloadCsvButton: "Download CSV",
    assignPlace: "Assign place",
    assignPlaceMessage: "The payment status will remain pending.",
    notFinished: "Not finished",
    table: {
      date: "Date",
      raceName: "Activity",
      dni: "DNI",
      modalityName: "Modality",
      athleteFullName: "Athlete",
      athleteEmail: "Email",
      inscriptionStatus: "Status",
      locator: "Locator",
      operationCode: "Operation Code",
      from: "Origin"
    },
    filter: {
      dni: "Tax Id",
      name: "Athlete Name",
      email: "Email",
      activityType: "Activity Type",
      race: "Activity Name",
      payDate: "Pay Date",
      payForm: "Pay Form",
      status: "Status",
      modalityName: "Modality",
      athleteFullName: "Athlete Name",
      athleteEmail: "Email",
      date: "Date",
      tracking: "Tracking",
      operationCode: "Operation code",
      showNotFinished: "Show not finished",
      origin: "Origin"
    },
    detail: {
      custom: "Custom",
      inscriptionsNotEnable:
        "The inscriptions to this modality are not available",
      commission: "Commission ({{commissionValue}} {{commissionType}})",
      commissionWithoutParenteses: "Comission",
      discount: "Discount",
      discountCode: "I have a discount code",
      discountValue: "Discount value",
      validateDiscount: "Validate",
      discountNoValid: "Discount code not valid",
      addInscription: "Add another Inscription",
      inscribe: "Inscribe ({{number}})",
      selectModality: "Select Modality",
      teamType: "Team Type",
      selectTeamType: "Select Team Type",
      selectPrice: "Select Price",
      basePrice: "Inscription price",
      teamInfo: "Team information",
      teamName: "Team Name",
      teamSize: "Number of members {{between}}",
      teamSizeError: "The number of members is not between the range",
      teamGender: "The gender only can be ",
      teamSizeMax:
        "The number of members for this inscription has gotten to the maximum",
      inscriptionPaymentInfo: "Payment Information",
      inscriptionInfo: "Inscription Information",
      inscriptionsInfo: "Inscriptions Information",
      inscriptionOther: "Additional Fields",
      inscriptionExtra: "Extra",
      insertPrice:
        "Insert the price that you want to assign to the inscription",
      clubNotFound: "If the club is not found it will be created",
      date: "Date",
      modalityName: "Modality",
      raceName: "Activity",
      email: "Email",
      firstName: "Name",
      lastName: "Surnames",
      dni: "Tax Id",
      locator: "Locator",
      phonePrefix: "Phone Prefix",
      phone: "Phone",
      emergencyPhone: "Emergency Phone",
      birthday: "Birthday",
      totalRefunded: "Refunded",
      birthdayShouldBeBetween: {
        both:
          "This modality is just for people that was born between {{minDate}} and {{maxDate}}",
        onlyMin:
          "This modality is just for people that was born after {{minDate}}",
        onlyMax:
          "This modality is just for people that was born before {{maxDate}}"
      },

      address: "Address",
      zip_code: "Zip code",
      club: "Club",
      gender: "Gender",
      contact: "Contact",
      dorsalAssigned: "Dorsal Assigned",
      confirmEmail: "Confirm email",
      male: "Male",
      female: "Female",
      documentType: "Document type",
      country: "Country",
      region: "Region",
      city: "City",
      originCountry: "Origin country",
      federatedParticipant: "Federated Participant",
      localParticipant: "Local Participant",
      zipCode: "Zip Code",
      cardPhoto: "Card Photo",
      parentName: "Name of the adult that authorize",
      parentSurnames: "Surnames of the adult that authorize",
      parentDni: "Identification number of the adult that authorize",
      paymentDate: "Payment Date",
      paymentLocator: "Locator",
      modality: "Modality",
      operationCode: "Operation code",
      price: "Price",
      moreInfoUrl: "More information at: {{url}}",
      tpv: "TPV",
      priceDescription: "Price description",
      authCode: "Auth code",
      refund: "Refund",
      partialReturn: "Partial Return",
      paymentStatus: "Payment Status",
      returnMoney: "Return money",
      refundDescription: "Description of the refund or cancellation",
      cancelInscription: "Cancel inscription",
      cancelled: "This inscription was cancelled",
      cancelInscriptionConfirm:
        "Do you want to cancel {{name}} {{surnames}}'s inscription without returning the money ({{amount}}€)?",
      cancelTeamInscription:
        "This inscription belongs to a team, be careful when canceling",
      cancelTeamInscriptions:
        "These inscriptions belong to a team, be careful when canceling any of them",
      cancelInscriptions: "Cancel inscription(s)",
      cancelPayment: "Cancel payment",
      unCancelInscriptions: "Reactivate inscription(s)",
      changeModality: "Change modality",
      changeModalityValidation: `There is an age, gender or dni restriction that does not allow one or more athletes to change modality`,
      changeModalityTeam:
        "Cant change modality because is a team's inscription",
      changeModalityMessage:
        "The inscription(s) will be moved to the selected modality. You have to fill the missing fields since they are required in the selected modality. The price will not be modify and will be assigned a new dorsal and locator if applies.",
      changePayment: "Change payment",
      priceShouldBeMoreThanZero:
        "The amount to return should be more than zero and lower to the paid price.",
      refundMessage:
        "Insert the amount to refund, the price of each inscription will be updated proportionally. This operation is automatic but will redirect you to the Virtual TPV to check that the refund was successful",
      selectToCancel:
        "Select the inscription(s) to cancel. If you select the box to the left the money will be refund and the inscription will be cancel. This operation is automatic but will redirect you to the Virtual TPV to check that the refund was successful.",
      amountToReturn: "Amount to return",
      totalAmountToRetun: "Total amount to return: {{total}}",
      personalData: "Personal Data",
      insertSomeDataForValidate: "Set some data for validate",
      inscriptionError: "There has been an error in the inscription(s)",
      inscriptionErrors: {
        MISSING_FIELDS:
          "There are some fields that are required that have not been filled",
        MODALITY_NOT_FOUND: "The modality does not exists",
        INSCRIPTIONS_NOT_ENABLED:
          "The inscriptions for this modality are not available",
        TAX_IDS_DUPLICATED:
          "It seems that some Tax Id are repeated or are already inscribe in this modality",
        TEAM_NAME_DUPLICATED:
          "It seems that this team name has already been taken",
        TEAM_MEMBERS_LENGTH: "The number of team members is incorrect",
        MAX_INSCRIPTIONS_REACHED:
          "We have reached the maximum number of inscriptions for this modality.",
        TOO_MANY_PEOPLE:
          "You have inscribe a higher number of people that you are allowed to",
        NOT_VALID_FOR_MODALITY:
          "The age or gender are not valid for this modality",
        NOT_ALL_INSCRIPTIONS:
          "The inscriptions you want to move are not the total initial inscriptions",
        DISCOUNT_NOT_AVAILABLE:
          "Discounts for this inscriptions are not available anymore",
        INVALID_IMAGE:
          "The image of an inscription is not valid."
      },
      returnMoneyErrors: {
        INCORRECT_AMOUNT:
          "The amount to return to one of the inscriptions is bigger than the price paid.",
        ZERO_AMOUNT: "The amount to return is zero.",
        TEAM_MIN_ATHLETES:
          "You cannot delete this amount of inscriptions since it would not fulfill all the minimum team requirements",
        TOKEN_ERROR:
          "A problem has occurred with authentication. You must log in again to make refunds"
      },
      alerts: {
        setAtLeastOneValue: "Set at least one value",
        thisAthleteAlreadyExist: "This athlete already exist",
        thisAthleteIsAvaliable: "This Athlete is avaliable",
        thisAthleteIsAlreadyInDB: "This Athlete is already in the data base",
        someFieldsWillBeFill: "Some fields will be fill",
        teamMembersLengthDontMatch:
          "Team members amount doenst match. Should be {{teamSize}} but they are only {{actualInscriptions}} inscription(s)",
        cantAddMore:
          "Can't add more members to team. \nShould be {{teamSize}} and already exist {{actualInscriptions}} inscription(s). \nClick on 'Inscribe'"
      },
      status: {
        label: "Status",
        PENDING: "Pending",
        PAID: "Paid",
        REJECTED: "Rejected"
      },
      button: {
        downloadVoucher: "Download voucher",
        resendVoucher: "Resend voucher",
        resetQr: "Reset QR"
      },
      voucherResended: "An email with the voucher was sended",
      EMAIL_NOT_SENDED: "There was a problem sending the email",
      MAIL_SEND_DELAYED: "An email with the voucher was sended (DELAYED)",
      copernicoId: "Copernico Inscription ID",
      qrCode: "Inscription's QR Code",
      checkedIn: "Check in",
      dorsalDelivered: "Dorsal delivered",
      qrReader: "QR Reader that delivered",
      dorsalDeliveryDate: "Dorsal delivery date",
      extraName: "Name",
      extraDeliveryDate: "Delivery date",
      extraDelivered: "Extra delivered",
      qrReaderResetedDate: "QR date reseted",
    },
    refund: {
      refundFailure:
        "There was a problem with the Virtual TPV and could not make the refund. Try again later.",
      refundSucessButProblemSaving:
        "The refund was made successfully but there was a problem updating the inscription. The refund or inscription's cancellation will not be reflected on the system, please contact the administrator.",
      refundSuccess:
        "The refund was made successfully. The athlete will receive the money back shortly in the card he used for the inscription.",
      backToInscription: "Go back to the inscription",
      backToRaceInscriptions: "Go back to the race inscriptions"
    }
  },
  raceClassifications: {
    addNewClassificiation: "Add new clasification to ",
    table: {
      modality: "Modality",
      date: "Date",
      count: "Count",
      lastUpdate: "Last update"
    },
    modal: {
      title: "Delete classification"
    },
    uploadfile: {
      name: "name",
      make_map: "Map Columns",
      error_mobile: "Should select 3 fields to show in mobile app",
      finishTime:
        "For a good operation of the application you should assign some value to the finish_time field."
    },
    error: {
      UPLOAD_CLASSIFICATION_DUPLICATED:
        "There's already a classification with this name"
    }
  },
  raceAccounts: {
    wait: "Wait while data is recovered",
    waitForPdf: "Wait while document is geneerated",
    total: "Total data",
    downloadPdf: "Download PDF",
    downloadCSV: "Download CSV",
    tableColumns: {
      inscriptionType: "Inscription mode",
      inscriptions: "Inscriptions",
      raised: "Money raised",
      concept: "Concept",
      quantity: "Quantity",
      totalCommissions: "Total Commissions"
    },
    tableRows: {
      withTpv: "In “Paid status with POS via web",
      withOutTpv: "In “Paid” status without POS via web",
      cancelled: "In the “Canceled”state",
      canceledRefund: "In “Paid” status with partial refund",
      canceledNotRefund: "In “Canceled” status with partial refund",
      paidInPanel: "In “Paid” status through the administration panel",
      total: "Total",
      totalAmount: "Total Amount",
      webAmount: "Web Amount",
      refunds: "Partial refunds",
      discounts: "Discounts",
      basePrice: "Base price",
      comision_inscripciones_gratuitas: " Free inscriptions commissions"
    },
    tableTitles: {
      main: "INSCRIPTIONS DETAIL WITH PAID STATUS TROUGH GEDSPORTS TPV",
      event:
        "INSCRIPTIONS DETAIL WITH PAID STATUS TROUGH GEDSPORTS TPV BY EVENT",
      panel: "PANEL INSCRIPTIONS DETAIL",
      commission: "COMISSIONS DETAIL GEDSPORTS"
    }
  },
  modalities: {
    createModality: "Create modality",
    create: {
      name: "Name",
      description: "Description",
      date: "Date",
      start_time: "Start time",
      distance: "Distance (in KM)",
      type: "Race type",
      sportType: "Sport type",
      successModal: {
        createdSuccess: "Success",
        createdSuccessMessage: "Modality created succesfully.",
        createdSuccessButton: "Continue"
      }
    },
    edit: {
      name: "Name",
      published: "Published",
      raceType: "Race type",
      sportType: "Sport type",
      distance: "Distance",
      date: "Date",
      time: "Time",
      automaticAssignation: "Automatic assignment of race numbers",
      firstDorsal: "First race number to assign",
      warnAboutDorsalAsignment: "Numbers are globally assigned for the race",
      moreInformation: "More information",
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
      dorsalDelivered: "Dorsal is going to be delivered",
      deleteModal: {
        title: "Delete modality"
      },
      alert: {
        successTitle: "Success",
        successMessage: "The modality has been updated",
        errorTitle: "Error",
        errorMessage: "There was a problem updating this modality",
        deleteErrorTitle: "There was a problem deleting this modality",
        deleteSuccess: "Modality deleted successfully"
      },
      resetQr: "Reset QR",
      autoResetQr: "Automatically reset the QR"
    },
    wrongDateFormat: "Wrong date format",
    limitDateBeforeModality:
      "The date should be before the start of the modality",
    limitDateGreaterAndBefore:
      "The deadline must be greater that the start date and should be before the start of the modality",
    limitDateBetweenModality:
      "The deadline should be between the modality limit dates",
    limitDateBetweenAndGreater:
      "The deadline must be greater that the start date and should be between the modality limit dates"
  },
  pricesScreen: {
    table: {
      name: "Name",
      dateStart: "Start date",
      dateFinish: "End date",
      amount: "Amount",
      maxAthletes: "Maximum registered",
      unlimited: "Unlimited"
    },
    new: "New price",
    edit: "Edit price",
    cannotUpdate:
      "You cannot update the amount of this price since is being used in a inscription.",
    cannotDelete:
      "You cannot delete this price since is being used in a inscription."
  },
  modalitySummary: {
    info: {
      inscriptions: "Inscriptions",
      name: "Name",
      modalityDate: "Date of modality",
      modalityHour: "modality hour"
    },
    inscriptions: {
      title: "Inscriptions",
      state: "Inscription status",
      init: "Inscription start",
      end: "Inscription deadline",
      max: "Inscription maximum",
      defauldPrices: "Default price"
    },
    tables: {
      name: "Name",
      price: "Price",
      since: "Since",
      until: "Until",
      type: "Type",
      requared: "Requared",
      minAthletes: "Mínimo de atletas",
      maxAthletes: "Máximo de atletas"
    },
    titles: {
      inscriptionconfig: "Inscriptions Settings",
      categories: "Categories",
      teamtypes: "Type of teams",
      attributes: "Atributes",
      prices: "Prices"
    }
  },
  modalityConfiguration: {
    dividerText:
      "Select the fields that you want to show in the inscription form",
    enableInscriptions: "Enable Inscriptions",
    startDate: "Start date",
    finishDate: "Finish Date",
    maxModalityInscriptions: "Max number of inscriptions",
    maxWebInscriptions: "Limit web inscriptions",
    defaultPrice: "Default price",
    minAgeType: "Min age type",
    maxAgeType: "Max age type",
    minAge: "Min age",
    maxAge: "Max age",
    ageOnDate: "Age on date",
    permittedGenders: "Permitted genders in inscription",
    mobilePhone: "Mobile phone",
    country: "Country of origin",
    address: "Address",
    federated: "Federated participant",
    emergencyNumber: "Emergency mobile phone",
    localizationOne: "Localization (Country)",
    zipCode: "Zip Code",
    selectExit: "Select an exit",
    phonePrefix: "Phone prefix",
    localizationTwo: "Localization (Country, Region)",
    parentsPermission: "Parents permission",
    photo: "License photo",
    confirmEmail: "Email confirmation",
    localityThree: "Localization (Country, Region, City)",
    localParticipant: "Local Participant",
    savedSuccess: "Configuration saved successfully",
    savedFailure: {
      common: "Something failed saving the configuration",
      dateValidity: "The min. age has to be smaller then the max. age"
    },
    savedInfo:
      "Use caution when modifying the registration settings: There are already athletes enrolled in the race.",
    finishDateError1:
      "End date & time combination should be after start date ({{startConfigDate}})",
    finishDateError2:
      "End date & time combination should be before {{startModalityDate}}",
    notValidMaxInscriptions: "The registration limit exceeds the race's limit.",
    selectClub: "Show control to select club"
  },
  templateScreen: {
    detail: {
      deleted: "Deleted"
    },
    fields: {
      name: "Template name",
      message: "Message",
      autotext: "Autotext"
    },
    data: {
      autotext: {
        club: "Club",
        surnames: "Surnames",
        locator: "Locator",
        dni: "ID Card",
        birthDate: "Birthday",
        nameSurnames: "Full name",
        name: "First Name"
      },
      showAll: "Show to all"
    },
    save: "Save",
    createdSuccess: "Template created",
    updatedSuccess: "Template updated",
    error:
      "This action cannot be performed at this time. Try again later or contact the administrator",
    comeBack: "Back"
  },
  notificationScreen: {
    detail: {
      deleted: "Deleted"
    },
    fields: {
      notificationType: "Notification Type",
      notificationPush: "Notification Push",
      name: "Notification name",
      message: "Message",
      autotext: "Autotext",
      template: "Template",
      race: "Race",
      subject: "Subject",
      company: "Company",
      imageUrl: "Image Url",
      province: "State",
      modality: "Modality",
    },
    data: {
      autotext: {
        club: "Club",
        surnames: "Surnames",
        name: "Name",
        locator: "Locator",
        dni: "DNI",
        birthDate: "Date of birth",
        nameSurnames: "Name and surnames"
      },
      deliverStatus: {
        accepted: "Accepted",
        rejected: "Rejected",
        delivered: "Delivered",
        failed: "Failed",
        opened: "Opened",
        clicked: "Clicked",
        complained: "Complained",
        stored: "Stored",
        unsubscribed: "Unsubscribed"
      },
      showAll: "Show to all"
    },
    modal: {
      title: "Select recipents",
      titlePrefilter: "Select filter options",
      titleStatus: "Recipient status",
      deleteTitle: "Delete record"
    },
    save: "Save",
    send: "Send",
    recipents: "Recipents",
    test: "Test",
    recipentsTable: {
      name: "Name",
      send: "Send",
      status: "Status",
      email: "Email"
    },
    createdSuccess: "Notification created",
    deleteSuccess: "Record deleted",
    updatedSuccess: "Notification updated",
    error:
      "This action cannot be performed at this time. Try again later or contact the administrator",
    emptyRecipents: "You must select at least one recipent first",
    alreadySent:
      "This notification has been already sent and this action cannot be performed.",
    testSuccess: "Test email sent successfully",
    statusInProgress:
      "Your request is still being processed. Please, try again",
    selectRace: "You must select a race",
    noMoreThan5Days: "We only have information of 5 days after notification was sent"
  },
  notifications: {
    createNotification: "New notification",
    createTemplate: "New template",
    confirmDelete: "Are you sure you want to delete the record selected?",
    list: "Notifications",
    templates: "Templates",
    table: {
      company: "Company",
      name: "Race",
      status: "Status",
      date: "Date",
      type: "Type",
      template: "Template",
      PENDING: "Pending",
      SENT: "Sent",
      CANCELLED: "Cancelled",
      CREATED: "Created",
      unknown: "Unknown"
    },
    notifiationsTypes: {
      NOTIFICATION_PUSH: "Notification push",
      EMAIL: "Email"
    }
  },
  config: {
    comition: "General commission (%)",
    validTime: "Link validity time (hours)",
    blokedTime: "Places blocked time (minutes)",
    bill: "Billing Information",
    seeTPV: "See TPV configuration",
    tpv: "TPV configuration",
    raceType: "Race Type",
    sportType: "Sport Type",
    addType: "Add type",
    noTypes: "There are no registered types of sports!",
    addRaceType: "Add type of sport",
    loader: "Adding race sport",
    type: "Type",
    code: "Code",
    typeExist: "Type of sport already exists",
    merchantCode: "Merchant Code",
    terminal: "Terminal",
    merchantSignature: "Merchant Signature",
    terms: {
      titlePANEL: "Terms and Conditions for panel users.",
      titleWEB: "Terms and Conditions for web users.",
      accept: "Accept terms and conditions of use."
    },
    notifications_answer_mails: "Notifications answer email",
    notifications: "Notifications",
    notificationsFooter: "Notifications email footer"
  },
  categoriesScreen: {
    table: {
      name: "Name",
      gender: "Gender",
      maxAthletes: "Maximum registered",
      interval: "Age interval",
      inDate: "In date"
    },
    detail: {
      name: "Name",
      description: "Description",
      gender: "Gender",
      ageType: "Type",
      minimumAge: "Minimum age",
      maximumAge: "Maximum age",
      ageCheckDate: "In date",
      maxAthletes: "Maximum number of athletes",
      mustBeGreater: "Must be greater and a number",
      mustBeLower: "Must be lower and a number"
    },
    new: "New category",
    sureDelete: "Are you sure you want to delete this category?"
  },
  modalityInscriptions: {
    table: {
      inscriptionDate: "Inscription date",
      athleteDni: "DNI",
      athleteFullName: "Athlete",
      athleteEmail: "Email",
      inscriptionStatus: "Status",
      locator: "Locator",
      operationCode: "Operation Code",
      origin: "Origin"
    }
  },
  modalityTeamType: {
    addNewTeam: "Add new team",
    addNew: "Add new",
    delete: "Are you sure you want to delete this type of team?",
    info:
      "Cannot edit / delete. There are athletes registered with this type of team.",
    messages: {
      minMaxThanMax:
        "Minimum of athletes should be a number and cannot be greater than Maximum of athletes",
      maxMinThanMin:
        "Maximum athletes should be a number and cannot be less than Minimum athletes"
    },
    table: {
      name: "Name",
      gender: "Gender",
      minAthletes: "Minimum of athletes",
      maxAthletes: "Maximum of athletes"
    }
  },
  modalityAttributes: {
    table: {
      createdAt: "Created at",
      name: "Name",
      type: "Type of field",
      required: "Required",
      visible: "Visible",
      isToBeDelivered: "Will be delivered"
    },
    createAttribute: "Create attribute",
    eraseAttribute: "Are you sure to erase this attribute?",
    types: {
      STRING: "Text",
      NUMBER: "Numeric",
      BOOLEAN: "Yes/No",
      SELECT: "Options",
      PRODUCT: "Product"
    },
    newAttribute: {
      name: "Name",
      required: "Required",
      visible: "Visible",
      description: "Description",
      moreInfo: "More Information",
      linkForMoreInfo: "Link for more information",
      linkForMoreInfoPlaceHolder: "https://someURL.com",
      linkForMoreInfoValidation:
        "Must be in url format like https://someURL.com",
      type: "Type of field",
      maxLength: "Maximum length",
      maxLengthPlaceholder: "By default is 255 characters",
      attributePrice: "Price of the attribute",
      attributePricePlaceholder: "By default is free",
      optionsAtLeastOne: "All fields must be with a correct format",
      options: "Options",
      optionLimit: "Limit",
      optionPrice: "Individual Price",
      priceType: "Type of price",
      fixedPrice: "Fixed price",
      variablePrice: "Variable price by option",
      addOption: "Add option",
      warningAboutAttributeOptions:
        "This attribute cannot be edited because it has already been used in some inscription.  N You can hide this from teh inscription form and create and display a new one with the desired values",
      attributeImage: "Attribute Image",
      showInReceipt: "Show in proof of payment",
      toBeDelivered: "Is to be delivered"
    },
    errors: {
      NAME_EXIST: "There can be no repeating names in visible attributes",
      PRICE_IN_USE: "The price for {{field}} cannot be change, already in use",
      TYPE_IN_USE: "Type cannot be change, already in use",
      ATTRIBUTE_IN_USE: "Attribute already in use",
      ALREADY_EXIST: "{{field}} already exist",
      PRICE_IN_USE_ATTRIBUTE: "Price cannot change"
    }
  },
  roles: {
    ADMINISTRATOR: "Administrator",
    COMPANY: "Company",
    PLANNER: "Planner",
    POINT_OF_SALE: "Point of Sale",
    ATHLETE: "Athlete",
    GUEST: "Guest",
    QR_READER: "QR Reader"
  },
  status: {
    ACTIVE: "Active",
    BLOCKED: "Blocked",
    NOT_CONFIRMED: "Not Confirmed",
    DELETED: "Deleted"
  },
  paymentStatus: {
    PAID: "Paid",
    PENDING: "Pending",
    REJECTED: "Rejected",
    DENIED: "Denied",
    CANCELLED: "Cancelled"
  },
  inscriptionStatus: {
    NOT_STARTED_YET: "Not available yet",
    CLOSED: "Closed",
    MODALITY_FINISHED: "Event finished",
    FULL_MODALITY: "Full event",
    FULL_RACE: "Full race",
    ENABLED: "Open",
    NOT_PUBLISHED: "Not published"
  },
  validation: {
    phone: "Only numbers allowed"
  },
  dateTimePicker: {
    date: "Date",
    hour: "Hour",
    minute: "Min.",
    from: "From",
    to: "To"
  },
  countries: {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, the Democratic Republic of the",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote D'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and Mcdonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic of",
    IQ: "Iraq",
    IE: "Ireland",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "North Korea",
    KR: "South Korea",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Moldova, Republic of",
    MC: "Monaco",
    MN: "Mongolia",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MK: "North Macedonia, Republic of",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    SH: "Saint Helena",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States of America",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    AX: "Åland Islands",
    BQ: "Bonaire, Sint Eustatius and Saba",
    CW: "Curaçao",
    GG: "Guernsey",
    IM: "Isle of Man",
    JE: "Jersey",
    ME: "Montenegro",
    BL: "Saint Barthélemy",
    MF: "Saint Martin (French part)",
    RS: "Serbia",
    SX: "Sint Maarten (Dutch part)",
    SS: "South Sudan",
    XK: "Kosovo"
  }
};

export default englishTranslations;
