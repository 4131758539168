import { use } from "i18next";
import { modalityAttributeTypes } from "../../../../util/usersUtil";

export const formFields = (t, form, mode) => {
  const { used, timesUsed } = form;
  const showWarningAboutOptions = mode === "update";

  const basicFields = [
    {
      fields: [
        {
          name: "name",
          title: t("modalityAttributes.newAttribute.name"),
          placeHolder: t("modalityAttributes.newAttribute.name"),
          type: "Input",
          colSize: 10,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "required",
          title: t("modalityAttributes.newAttribute.required"),
          placeHolder: t("modalityAttributes.newAttribute.required"),
          type: "Checkbox",
          colSize: 3,
          props: {
            style: {
              display: "flex",
              alignItems: "center"
            }
          }
        },
        {
          name: "visible",
          title: t("modalityAttributes.newAttribute.visible"),
          placeHolder: t("modalityAttributes.newAttribute.visible"),
          type: "Checkbox",
          colSize: 3
        },
        {
          name: "isToBeDelivered",
          title: t("modalityAttributes.newAttribute.toBeDelivered"),
          placeHolder: t("modalityAttributes.newAttribute.toBeDelivered"),
          type: "Checkbox",
          colSize: 3
        }
      ]
    },
    {
      fields: [
        {
          name: "description",
          title: t("modalityAttributes.newAttribute.description"),
          placeHolder: t("modalityAttributes.newAttribute.description"),
          type: "TextArea",
          colSize: 14,
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          }
        }
      ]
    },
    {
      fields: [
        {
          name: "showInProof",
          title: t("modalityAttributes.newAttribute.showInReceipt"),
          placeholder: t("modalityAttributes.newAttribute.showInReceipt"),
          type: "Checkbox",
          colSize: 6
        }
      ]
    },
    {
      fields: [
        {
          name: "moreInfo",
          title: t("modalityAttributes.newAttribute.moreInfo"),
          placeHolder: t("modalityAttributes.newAttribute.moreInfo"),
          type: "TextArea",
          colSize: 14
        }
      ]
    },
    {
      fields: [
        {
          name: "link",
          title: t("modalityAttributes.newAttribute.linkForMoreInfo"),
          placeHolder: t(
            "modalityAttributes.newAttribute.linkForMoreInfoPlaceHolder"
          ),
          type: "Input",
          colSize: 10,
          validation: {
            regexType: "url",
            errorMessage: t(
              "modalityAttributes.newAttribute.linkForMoreInfoValidation"
            )
          }
        }
      ]
    },

    {
      fields: used
        ? [
            {
              title: t(
                "modalityAttributes.newAttribute.warningAboutAttributeOptions"
              ),
              type: "Warning",
              colSize: 16
            }
          ]
        : []
    },

    {
      fields: [
        {
          name: "type",
          title: t("modalityAttributes.newAttribute.type"),
          placeHolder: t("modalityAttributes.newAttribute.type"),
          type: "Select",
          data: modalityAttributeTypes(t),
          colSize: 10,
          props: {
            disabled: used
          },
          validation: {
            required: true,
            errorMessage: t("commons.fieldRequired")
          }
        }
      ]
    }
  ];

  const textFields = [
    {
      fields: [
        {
          name: "maxLength",
          title: t("modalityAttributes.newAttribute.maxLength"),
          placeHolder: t(
            "modalityAttributes.newAttribute.maxLengthPlaceholder"
          ),
          type: "Input",
          colSize: 10,
          validation: {
            custom: vals =>
              vals.maxLength ? !isNaN(parseInt(vals.maxLength)) : true,
            errorMessage: t("commons.mustBeNumber")
          }
        }
      ]
    }
  ];

  const booleanFields = [
    {
      fields: [
        {
          name: "price",
          title: t("modalityAttributes.newAttribute.attributePrice"),
          placeHolder: t(
            "modalityAttributes.newAttribute.attributePricePlaceholder"
          ),
          type: "Input",
          colSize: 10,
          props: {
            disabled: used
          },
          validation: {
            custom: vals =>
              vals.price
                ? !isNaN(
                    parseInt(
                      typeof vals.price === "string"
                        ? vals.price.replace(",", ".")
                        : vals.price
                    )
                  )
                : true,
            errorMessage: t("commons.mustBeNumber")
          }
        }
      ]
    }
  ];

  const productFields = [
    {
      fields: [
        {
          name: "priceType",
          title: t("modalityAttributes.newAttribute.priceType"),
          type: "RadioButtons",
          colSize: 6,
          deflt: "FIXED",
          radios: [
            {
              label: t("modalityAttributes.newAttribute.fixedPrice"),
              value: "FIXED"
            },
            {
              label: t("modalityAttributes.newAttribute.variablePrice"),
              value: "VARIABLE"
            }
          ],
          props: {
            direction: "horizontal"
          }
        }
      ]
    },
    //show warning only when updating
    // used
    //   ? {
    //       fields: [
    //         {
    //           title: t(
    //             "modalityAttributes.newAttribute.warningAboutAttributeOptions"
    //           ),
    //           type: "Warning",
    //           colSize: 16
    //         }
    //       ]
    //     }
    //   : { fields: [] },
    ...(form.priceType === "VARIABLE" ? [] : booleanFields)
  ];

  const selectFields = [
    //show warning only when updating
    used
      ? {
          fields: [
            {
              title: t(
                "modalityAttributes.newAttribute.warningAboutAttributeOptions"
              ),
              type: "Warning",
              colSize: 16
            }
          ]
        }
      : { fields: [] }
  ];

  let extraFields = [];

  switch (form.type) {
    case "STRING":
      extraFields = textFields;
      break;
    case "BOOLEAN":
      extraFields = booleanFields;
      break;
    case "PRODUCT":
      extraFields = productFields;
      break;
    // case "SELECT":
    //   extraFields = selectFields;
    //   break;
    default:
      extraFields = [];
      break;
  }

  return [...basicFields, ...extraFields];
};

export const tableDeleteHeader = t => [
  {
    content: "",
    accessor: "deleteAttribute",
    props: {
      collapsing: true
    }
  }
];

export const tableProductHeaders = t => [
  ...tableSelectHeadersBasic(t),
  {
    content: t("modalityAttributes.newAttribute.optionPrice"),
    accessor: "price"
  },
  ...tableDeleteHeader(t)
];

export const tableSelectHeadersBasic = t => [
  {
    content: t("modalityAttributes.newAttribute.options"),
    accessor: "name"
  },
  {
    content: t("modalityAttributes.newAttribute.optionLimit"),
    accessor: "limit"
  }
];

export const tableSelectHeaders = t => [
  {
    content: t("modalityAttributes.newAttribute.options"),
    accessor: "name"
  },
  {
    content: t("modalityAttributes.newAttribute.optionLimit"),
    accessor: "limit"
  },
  ...tableDeleteHeader(t)
];
