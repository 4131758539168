import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Container, Grid, Icon, Divider, Card, List } from "semantic-ui-react";
import CreateModalityButton from "./CreateModalityButton";
import FluidResponsiveButton from "../../../components/FluidResponsiveButton";
import ModalityCardList from "../../../components/ModalityCardList";
import racesApi from "../../../apis/races";
import moment from "moment";

const styles = {
  MarginBottom: {
    marginBottom: "4vh"
  }
};

@withTranslation()
@connect(state => ({
  race: state.races.actualRace
}))
export default class RaceModalities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      cardsPerRow: 3
    };
  }

  async componentDidMount() {
    await this.getModalities();
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  async getModalities() {
    await racesApi.fetchModalities({ id: this.props.id }).then(async response => {

      await racesApi.fetchRace(this.props.id).then(race => {
        const raceFinished = this.raceFinished(race);

        this.setState({
          data: response,
          raceFinished
        });
      })
    });

  }

  handleResize() {
    if (window.innerWidth < 736) {
      this.setState({ cardsPerRow: 1 });
    } else if (window.innerWidth < 992) {
      this.setState({ cardsPerRow: 2 });
    } else {
      this.setState({ cardsPerRow: 3 });
    }
  }

  raceFinished(race) {

    let isRaceEnd = false;
    if (race.finish_date) {
      isRaceEnd = moment(race.finish_date).isBefore(moment(new Date()));
    } else {
      isRaceEnd = moment(new Date()).isAfter(
        moment(race.start_date).add(1, "day")
      );
    }
    return isRaceEnd
  }

  getCardDescription(params) {
    const { date } = params;

    const description = (
      <List relaxed style={{ margin: "0% 5% 5% 5%" }}>
        <List.Item>
          <List.Icon name="address card outline" />
          {this.props.t("raceDetail.detail.events.name")}
        </List.Item>
        <List.Item>
          <List.Icon name="calendar alternate outline" />
          {this.props.t("raceDetail.detail.events.date")}
          {moment(date).format("L")}
        </List.Item>
        <List.Item>
          <List.Icon name="hashtag" />
          {this.props.t("raceDetail.detail.events.inscriptionUrl")}
        </List.Item>
        <List.Item>
          <List.Icon name="hashtag" />
          {this.props.t("raceDetail.detail.events.adminInscriptionUrl")}
        </List.Item>
        <List.Item>
          <List.Icon name="hashtag" />
          {this.props.t("raceDetail.detail.events.clasificationUrl")}
        </List.Item>
      </List>
    );

    return description;
  }

  render() {
    const { history, t, id, race: dataRace } = this.props;
    const { data, cardsPerRow, raceFinished } = this.state;

    return (
      <Container>
        {!dataRace.deleted && !raceFinished && (<CreateModalityButton race_id={id} />)}

        <Divider horizontal style={{ ...styles.MarginBottom }}>
          {t("raceDetail.detail.events.divider")}
        </Divider>

        <ModalityCardList
          racePublised={dataRace.is_published}
          raceDeleted={dataRace.deleted}
          raceId={id}
          modalities={data}
          cardsPerRow={cardsPerRow}
          history={history}
        />
      </Container>
    );
  }
}
