import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Responsive,
  Menu,
  Container,
  Header,
  Accordion,
  Icon
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { items, itemsPos, itemsPlanner } from "./util";


@connect(
  state => ({
    rol: state.user.role,
    race: state.races.actualRace,
  })
)
@withTranslation()
export default class RaceCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      responsiveOpenAccordion: false
    };
  }

  render() {
    const { t, raceId, activeItem, rol, race } = this.props;
    const { responsiveOpenAccordion } = this.state;

    const isEventOrAssociate =
      race.activityType.type === "EVENT" ||
      race.activityType.type === "ASSOCIATES";
    const menuItems = rol == "POINT_OF_SALE"
      ? itemsPos(t)
      : (rol === "PLANNER"
        ? itemsPlanner(t, isEventOrAssociate)
        : items(t, isEventOrAssociate))

    return (
      <Container>
        <Responsive minWidth={780}>
          <Menu secondary>
            {menuItems.map((item, key) => (
              <Menu.Item
                key={`${key}-${item.name}`}
                name={item.title}
                active={activeItem === item.name}
                onClick={() =>
                  this.props.onItemClick(`/race/${raceId}#${item.href}`)
                }
              />
            ))}
          </Menu>
        </Responsive>

        {/* Responsive on small screens */}

        <Responsive maxWidth={780}>
          <Accordion>
            <Accordion.Title
              active={responsiveOpenAccordion}
              onClick={() => {
                this.setState({
                  responsiveOpenAccordion: !this.state.responsiveOpenAccordion
                });
              }}
            >
              <Icon name="browser" />
              <Icon name="dropdown" />
            </Accordion.Title>
            <Accordion.Content active={responsiveOpenAccordion}>
              <Menu vertical fluid>
                {menuItems.map((item, key) => (
                  <Menu.Item
                    key={`${key}-${item.name}`}
                    name={item.title}
                    active={activeItem === item.name}
                    onClick={() =>
                      this.props.onItemClick(`/race/${raceId}#${item.href}`)
                    }
                  >
                    <Header as="h4">{item.title}</Header>
                  </Menu.Item>
                ))}
              </Menu>
            </Accordion.Content>
          </Accordion>
        </Responsive>
        <br />

        {this.props.children}
      </Container>
    );
  }
}
