import React, { Component } from "react";
import { Container, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";

import Table from "../../../components/DTable";
import { tableHeaders } from "./util";
import modalityApi from "../../../apis/modalities";
import FluidResponsiveButton from "../../../components/FluidResponsiveButton";
import ModalitySubtitle from "../../../components/ModalitySubtitle"

@withTranslation()
@connect(state => ({
  race: state.races.actualRace
}))
export default class ModalityAttributes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      totalData: 0
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData(params) {
    const { id } = this.props;
    const response = await modalityApi.fetchAttributes(id, params);

    this.setState({ totalData: response.count, data: response.rows });
  }

  render() {
    const { t, history, id, raceId, race } = this.props;
    const { data, totalData } = this.state;

    return (
      <Container>
      <ModalitySubtitle icon="wpforms" title='raceDetail.menu.modality.attributes' />
        <Table
          headers={tableHeaders(t)}
          contentData={data}
          totalData={totalData}
          parseData={data => ({
            createdAt: moment(data.createdAt).format("LL"),
            type: t(`modalityAttributes.types.${data.type}`)
          })}
          onRowClick={data =>
            history.push(`/race/${raceId}/modality/${id}/attribute/${data.id}`)
          }
          onTableChange={params => this.getData(params)}
        />
        {!race.deleted && <FluidResponsiveButton
          onClick={async () =>
            await history.push(`/race/${raceId}/modality/${id}/attribute/create`)
          }
          primary
          icon
          labelPosition="left"
          size="small"
        >
          <Icon name="add" />
          {t("commons.new")}
        </FluidResponsiveButton>}
      </Container>
    );
  }
}
