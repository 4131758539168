export const getTableHeaders = (t, type) => {
  const sameFields = [
    {
      content: t("homeScreen.table.date"),
      accessor: "start_date"
    },
    {
      content: t("homeScreen.table.name"),
      accessor: "name"
    },
    {
      content: t("homeScreen.table.company"),
      accessor: "company"
    }
  ];

  const bothRaces = [
    {
      content: t("homeScreen.table.totalInscriptions"),
      accessor: "totalInscriptions"
    },
    {
      content: t("homeScreen.table.published"),
      accessor: "is_published"
    },
    {
      content: t("homeScreen.table.activityTypeId"),
      accessor: "activityTypeId"
    }
  ];

  const upcomingRaces = [
    {
      content: t("homeScreen.table.todayInscriptions"),
      accessor: "todayInscriptions"
    },
    {
      content: t("homeScreen.table.lastWeekInscriptions"),
      accessor: "lastWeekInscriptions"
    },
    {
      content: t("homeScreen.table.lastMonthInscriptions"),
      accessor: "lastMonthInscriptions"
    }
  ];

  return type === 1
    ? [...sameFields, ...upcomingRaces, ...bothRaces]
    : [...sameFields, ...bothRaces];
};

export const getFilterFields = t => [
  {
    div: "row",
    fields: [
      {
        name: "queryString",
        placeHolder: t("homeScreen.searchRace"),
        type: "Input"
      }
    ]
  }
];
