import { clientApi } from ".";

export default {
  login: async params => {
    try {
      const response = await clientApi.getClient().post("/auth", { ...params, channel_id: 1 });
      return response.data;
    } catch (err) {}
  },
  recoverPassword: async params => {
    try {
      const response = await clientApi
        .getClient()
        .post("/recoverPassword", { ...params });
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  resetPassword: async params => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/recoverPassword/reset/${params.token}`, {
          password: params.password,
          channel_id: 1,
        });
      return response.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  checkToken: async token => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/auth/checktoken/${token}`, {params :{channel_id: 1}});
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
};
