import React, { Component } from "react";
import { Container, Segment, Button, Divider } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { discountFields } from "./util";
import Form from "../../../../components/Form";
import discountApi from "../../../../apis/discounts";

@withTranslation()
export default class DiscountDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      discount: {}
    };
  }

  async componentDidMount() {
    const {
      match: { params }
    } = this.props;

    const discount = await discountApi.getDiscount(params.id);
    // const parsedDiscount = this.parseDiscount(discount);

    this.setState({
      discount: parsedDiscount
    });
  }

  parseDiscount(discount) {
    /*
    In some cases (older cases) discount.inscriptionId 
    can point to a non existent inscription and therefore name, surnames,
    email and createdAt will not exist
    */
    let usedBy = "";
    let usedByEmail = "";
    let usedOn = "";
    if (discount.inscription) {
      usedBy = `${discount.inscription?.name} ${discount.inscription?.surnames}`;
      usedByEmail = discount.inscription?.email;
      usedOn = discount.inscription?.createdAt;
    }

    return {
      ...discount,
      used: !!discount.inscription_id,
      value: `${discount.value + (discount.type === "VAL" ? " €" : " %")}`,
      usedBy,
      usedByEmail,
      usedOn,
      race: discount.race.name
    };
  }

  render() {
    const { t, history } = this.props;
    const { discount } = this.state;

    return (
      <Container>
        <Divider horizontal>{t("raceDiscounts.detail.info")}</Divider>
        <Form defaultState={discount} fields={discountFields(t, discount)} />
        <Segment basic padded>
          <Button floated="right" color="vk" onClick={history.goBack}>
            {t("commons.goBack")}
          </Button>
        </Segment>
      </Container>
    );
  }
}
