import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Container, Icon, Divider, Segment, Grid } from "semantic-ui-react";
import moment from "moment";
import { sortBy, uniqBy } from "lodash";
import { connect } from "react-redux";

import FilterableTable from "../../components/FilterableTable";

import companiesApi from "../../apis/companies";
import plannersApi from "../../apis/planners";
import regionsApi from "../../apis/regions";
import racesApi from "../../apis/races";
import { getTableHeaders, getFilterFields } from "./util";
import FluidResponsiveButton from "../../components/FluidResponsiveButton";

import "./index.css";

@connect((state) => ({
  rol: state.user.role,
  userId: state.user.id,
}))
@withTranslation()
export default class Races extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      totalData: 0,
      companies: [],
      regions: [],
      raceTypes: [],
      planners: [] /** Data that contains the companies that they have worked with */,
      filteredPlanners: [] /** Actual list that is shown in the dropdown */,
      initialPlanners: [] /** Same as planners but parsed to be shown in the dropdown */,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getData();
    this.setState({ loading: false });
    this.getCompanies();
    this.getPlanners();
    this.getRegions();
    this.getRaceTypes();
  }

  async getCompanies() {
    const data = await companiesApi.getMin();

    const companies = data.map((company) => ({
      text: company.name,
      value: company.id,
    }));

    this.setState({ companies });
  }

  async getRaceTypes() {
    const data = await racesApi.fetchRaceTypes();

    const raceTypes = data.map((type) => ({
      text: `${type.type} (${type.code})`,
      value: type.id,
    }));

    this.setState({ raceTypes });
  }

  async getRegions() {
    const data = await regionsApi.fetchRegions();

    const regions = data.map((region) => ({
      text: region.name,
      value: region.name,
    }));

    this.setState({ regions });
  }

  async getPlanners() {
    const data = await plannersApi.fetchPlannersMin();

    const planners = data.map((planner) => ({
      text: planner.name,
      value: planner.name,
      key: planner.id,
    }));

    const sortedPlanners = sortBy(uniqBy(planners, "value"), ["value"]);

    this.setState({
      planners: data,
      filteredPlanners: sortedPlanners,
      initialPlanners: sortedPlanners,
    });
  }

  async getData(params) {
    const { type = "RACE" } = this.props;

    const typeParser = { RACE: 1, EVENT: 2, ASSOCIATES: 3 };
    const newParams = { ...params, type: typeParser[type] };
    const response = await racesApi.fetchRaces(newParams);

    this.setState({ data: response.rows, totalData: response.count });
  }

  filterTable(parameters, fieldsParams) {
    const { planners, initialPlanners } = this.state;

    let filtered = [...initialPlanners];
    if (fieldsParams?.companyId) {
      filtered = planners
        .filter((planner) => planner.companies.includes(fieldsParams.companyId))
        .map((planner) => ({
          text: planner.name,
          value: planner.name,
        }));
    }

    this.setState({
      filteredPlanners: filtered,
    });

    this.getData({ ...parameters, ...fieldsParams });
  }

  render() {
    const { t } = this.props;
    const loggedRole = this.props.rol || "";
    const {
      loading,
      data,
      totalData,
      companies,
      regions,
      raceTypes,
      filteredPlanners,
    } = this.state;

    const filterFields = getFilterFields(
      t,
      companies,
      filteredPlanners,
      raceTypes,
      regions,
      loggedRole
    );
    const tableHeaders = getTableHeaders(t, loggedRole);
    return (
      <Container>
        <FilterableTable
          id={"RacesFilterableTable"}
          filterFields={filterFields}
          onChange={(table, filters) => this.filterTable(table, filters)}
          tableHeaders={tableHeaders}
          onRowClick={(data) => {
            this.props.history.push({
              pathname: `/race/${data.id}`,
              state: { id: data.id },
            });
          }}
          contentData={data}
          parseData={(data) => ({
            start_date: moment(data.start_date).format("L"),
            planners: data.planners.split(","),
          })}
          totalData={totalData}
          loading={loading}
          rowProps={(data) =>
            data.deleted ? { negative: true, className: "strikethrough" } : {}
          }
        />

        {this.props.rol !== "PLANNER" && this.props.rol !== "POINT_OF_SALE" && (
          <div className="button-group">
            <FluidResponsiveButton
              id="RacesCreateButton"
              icon
              labelPosition="left"
              size="small"
              primary
              style={{ marginBottom: 10 }}
              onClick={async () =>
                await this.props.history.push("/race/create", {
                  comesFrom: this.props.type || "RACE",
                })
              }
            >
              <Icon name="flag" />
              {this.props.t("racesScreen.createRace")}
            </FluidResponsiveButton>
            <FluidResponsiveButton
              id="RacesCopyButton"
              icon
              labelPosition="left"
              size="small"
              primary
              onClick={async () => await this.props.history.push("/race/copy")}
            >
              <Icon name="flag" />
              {this.props.t("racesScreen.copyRace")}
            </FluidResponsiveButton>
          </div>
        )}
      </Container>
    );
  }
}
