import { clientApi } from ".";

export default {
  getTeamTypes: async (modality_id, params) => {
    try {
      const response = await clientApi
        .getClient()
        .get(`/teams/${modality_id}`, { params: {...params, channel_id: 1} });
      return response.data;
    } catch (err) {}
  },
  create: async (modality_id, body) => {
    try {
      const response = await clientApi
        .getClient()
        .post(`/teams/${modality_id}`, { ...body, channel_id: 1 });
      return response.data;
    } catch (err) {}
  },
  update: async (id, body) => {
    try {
      const response = await clientApi
        .getClient()
        .put(`/team/${id}`, { ...body, channel_id: 1 });
      return response.data;
    } catch (err) {}
  },
  delete: async (id)  => {
    try {
      const response = await clientApi
        .getClient()
        .delete(`/team/${id}`, {params:{channel_id: 1}});
      return response.data;
    } catch (err) {}
  },
  fetchRoleByToken: async params => {
    try {
      const response = await clientApi
        .getClient()
        .get("/role/token", { params });
      return response.data;
    } catch (err) {}
  },
  getInscriptionsByTeamId: async (modality_id, id) =>{
    try {
      const response = await clientApi
        .getClient()
        .get(`/teams/${modality_id}/team_type/${id}`, {params:{channel_id: 1}});
      return response.data;
    } catch (err) {}
  }
};
