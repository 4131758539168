import { dateIsBetween, getDatesErrorMessage } from "../../../util/util";

export const formFields = (
  t,
  raceTypes,
  modality,
  raceStartDate,
  raceFinishDate,
  raceAutoDorsalAsignment,
  isEventOrAssociate
) => [
  {
    fields: [
      {
        name: "race_type",
        title: !isEventOrAssociate ? t("modalities.edit.raceType") : t("modalities.edit.sportType"),
        placeHolder: !isEventOrAssociate ? t("modalities.edit.raceType") : t("modalities.edit.sportType"),
        type: "Select",
        data: raceTypes,
        colSize: 8,
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired"),
        },
      },
      ...(!isEventOrAssociate
        ? [
            {
              name: "distance",
              title: t("modalities.edit.distance"),
              placeHolder: t("modalities.edit.distance"),
              type: "Input",
              colSize: 8,
              validation: {
                // required: true,
                regexType: "double",
                errorMessage: t("commons.INVALID_DOUBLE"),
              },
              // validation: {
              //   required: true
              // }
            },
          ]
        : []),
    ],
  },
  {
    fields: (state) => [
      {
        name: "start_date",
        title: t("modalities.edit.date"),
        placeHolder: t("modalities.edit.date"),
        type: "DatePicker",
        colSize: 7,
        validation: {
          required: true,
          custom: (vals) => {
            const startRaceDate = moment(raceStartDate).startOf("day");
            const finishRaceDate = moment(raceFinishDate).endOf("day");
            return dateIsBetween(
              startRaceDate,
              finishRaceDate,
              vals.start_date
            );
          },
          errorMessage: getDatesErrorMessage(t, raceStartDate, raceFinishDate),
        },
        props: {
          id: "start_date",
        },
      },
      {
        name: "time_start",
        title: t("dateTimePicker.hour"),
        colSize: 7,
        type: "Input",
        validation: {
          required: true,
          errorMessage: t("commons.fieldRequired"),
        },
        props: {
          type: "time",
          id: "start_time",
          step: 15 * 60,
        },
        // validation: {
        //   required: true,
        //   regexType: "double",
        //   errorMessage: t("commons.INVALID_DOUBLE")
        // }
      },
    ],
  },
  {
    fields: raceAutoDorsalAsignment
      ? [
          {
            title: t("modalities.edit.warnAboutDorsalAsignment"),
            type: "Warning",
            colSize: 16,
          },
        ]
      : [],
  },
  ...(!isEventOrAssociate
    ? [
        {
          fields: (state) => [
            {
              name: "automatic_dorsal_assignation",
              title: t("modalities.edit.automaticAssignation"),
              placeHolder: t("modalities.edit.automaticAssignation"),
              type: "Checkbox",
              colSize: 8,
              validation: {
                required: false,
              },
              props: {
                disabled: raceAutoDorsalAsignment,
              },
            },
            {
              name: "first_dorsal_to_assign",
              title: t("modalities.edit.firstDorsal"),
              placeHolder: t("modalities.edit.firstDorsal"),
              type: "Input",
              colSize: 8,
              validation: {
                // required: state.automatic_dorsal_assignation,
                regexType: "number",
                errorMessage: t("commons.mustBeNumber"),
              },
              props: {
                disabled:
                  raceAutoDorsalAsignment ||
                  (state
                    ? !state.automatic_dorsal_assignation
                    : !modality.automatic_dorsal_assignation),
              },
            },
          ],
        },
      ]
    : []),
];


export const textArea = (t, isEventOrAssociate = false) => {
  return [{
    fields: [{
      name: "name",
      title: t("modalities.edit.name"),
      placeHolder: t("modalities.edit.name"),
      type: "TextArea",
      colSize: 16,
      validation: {
        required: true,
        errorMessage: t("commons.fieldRequired")
      }
    }]
  },
  {
    fields: [{
      name: "is_published",
      title: t("modalities.edit.published"),
      placeHolder: t("modalities.edit.published"),
      type: "Checkbox",
      colSize: 16,
      validation: {
        required: false
      }
    },
    ...(!isEventOrAssociate ? [
      {
        name: "is_dorsal_to_be_delivered",
        title: t("modalities.edit.dorsalDelivered"),
        placeHolder: t("modalities.edit.dorsalDelivered"),
        type: "Checkbox",
        colSize: 16,
        validation: {
          required: false
        }
      }
    ]: []),

    ]
  },
  {
    fields:[
      {
        name: "qr_auto_reset",
        title: t("modalities.edit.autoResetQr"),
        placeHolder: t("modalities.edit.autoResetQr"),
        type: "Checkbox",
        colSize: 16,
        validation: {
          required: false
        } 
      }
    ]
  }
  ];
};