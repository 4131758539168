import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import categoriesApi from "../../../apis/categories";
import Form from "../../../components/Form";
import Table from "../../../components/DTable";
import { Container, Modal, Grid, Button, Message, Dimmer, Loader } from "semantic-ui-react";
import moment from "moment";
import CreateCategoryButton from "./CreateCategoryButton";

import { modalHandler } from "../../../components/DModal";
import { tableHeaders, formFields, validateAges } from "./util";
import { DAlert } from "../../../components/Dalert";
import ModalitySubtitle from "../../../components/ModalitySubtitle"

@withTranslation()
@connect(state => ({
  race: state.races.actualRace,
}))
export default class ModalityCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
      formData: {},
      showValidation: false,
      error: null,
      isLoading: false
    };
  }

  componentDidMount = async () => {
    await this.getData();
  };

  async getData(params) {
    const data = await categoriesApi.getByModality({
      id: this.props.id,
      params
    });

    this.setState({ data: data.rows, totalData: data.count });
  }

  async save() {

    const { id, t } = this.props;
    const { formData } = this.state;
    modalHandler.refreshOptions({
      ...this.modalOptions(formData, false)
    });

    if (!formData.ISFORMVALID) {
      this.setState({ showValidation: true });
      modalHandler.refreshOptions({
        ...this.modalOptions(formData, true)
      });
      return;
    }
    this.setState({ isLoading: true })
    let dataToSend = {
      ...formData,
      modalityId: +id,
      id: +formData.id,
      // maxAthletes: !!!formData.maxAthletes ? null : +formData.maxAthletes,
      ageType: !!!formData.ageType ? null : formData.ageType,
      ageCheckDate: !!!formData.ageCheckDate ? null : formData.ageCheckDate,
      minimumAge: !!!formData.minimumAge ? null : +formData.minimumAge,
      maximumAge: !!!formData.maximumAge ? null : +formData.maximumAge
    };

    const response = await categoriesApi.updateCategory(dataToSend);

    if (typeof response === 'undefined' || response.ok === false) {
      if (response && response.ok === false) {
        await this.setState({ error: response.error.message })
      }
      DAlert.error({ message: t("commons.errorSaving") });
    } else {
      modalHandler.close();
      DAlert.success({ message: t("commons.savedSuccess") });
      this.getData();
    }
    await this.setState({ isLoading: false })
    modalHandler.refreshOptions({
      ...this.modalOptions(formData, false)
    });
    await this.setState({ error: null })
  }

  async delete(id) {
    const { t } = this.props;

    const modalConfirm = {
      header: t("commons.areYouSure"),
      content: t("categoriesScreen.sureDelete"),
      onYes: async () => {
        await categoriesApi.deleteCategory(id);
        modalHandler.close();
        this.getData();
      }
    };

    modalHandler.open(modalConfirm);
  }

  formHandler = formData => {
    const { ageType, minimumAge, maximumAge, ageCheckDate } = formData;
    let ageRequired = false;

    if (!!ageType || !!minimumAge || !!maximumAge || !!ageCheckDate) {
      ageRequired = true;
    }

    this.setState({ formData: { ...this.state.formData, ...formData } });

    modalHandler.refreshOptions({
      ...this.modalOptions(formData, ageRequired)
    });
  };

  modalOptions = (data, ageRequired = false) => {
    const { t, race } = this.props;
    const { error, isLoading } = this.state
    const agesValidation = validateAges(data, true);
    this.setState({ formData: { ...this.state.formData, ...data } });

    return {
      header: data.name,
      content: (
        <Modal.Description>
          <Grid padded>
            <Grid.Column width="16">
              <Form
                defaultState={{...data, minimumAge: data.minimumAge.toString()}}
                showValidation={this.state.showValidation}
                fields={formFields(t, agesValidation, race.deleted)}
                onFormChange={form =>
                  this.formHandler(form)}
              />
            </Grid.Column>
            {error && (
              <Grid.Row>
                <Grid.Column width="16">
                  <Message
                    error
                    header={t(`usersScreen.modal.messages.${error}.header`)}
                    content={t(`usersScreen.modal.messages.${error}.message`)}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          {isLoading && (
            <Dimmer active>
              <Loader />
            </Dimmer>
          )}
        </Modal.Description>
      ),
      actions: (
        <Grid>
          <Grid.Column width="3">
           {!race.deleted && <Button
              content={t("commons.delete")}
              icon="trash alternate outline"
              floated={"left"}
              color={"red"}
              onClick={() => this.delete(this.state.formData.id)}
            />}
          </Grid.Column>
          <Grid.Column width="13">
            <Button color={"red"} onClick={() => modalHandler.close()}>
              {t("commons.cancel")}
            </Button>
          {!race.deleted &&  <Button color={"vk"} onClick={() => {
              this.save()
            }}>
              {t("commons.save")}
            </Button>}
          </Grid.Column>
        </Grid>
      )
    };
  };

  // getMaxAthletes = (t, maxAthletes) =>
  //   maxAthletes ? maxAthletes : t("pricesScreen.table.unlimited");

  getInterval = (t, row) => {
    if (row.minimumAge && row.maximumAge && row.ageType) {
      const min = Math.min(row.minimumAge, row.maximumAge);
      const max = Math.max(row.minimumAge, row.maximumAge);
      const ageType = t(`commons.ageType.${row.ageType}`)
      return `${ageType}: ${min} - ${max}`;
    } else {
      if (row.minimumAge === 0 && row.maximumAge && row.ageType) {
        const min = 0;
        const max = row.maximumAge;
        const ageType = t(`commons.ageType.${row.ageType}`)
        return `${ageType}: ${min} - ${max}`;
      }
      return null;
    }
  };

  getDate = date => (date ? moment(date).format("LL") : null);

  render() {
    const { t, id, race } = this.props;

    const { data, totalData } = this.state;

    return (
      <Container>
        <ModalitySubtitle icon="list alternate outline" title='raceDetail.menu.modality.categories' />
        <Table
          headers={tableHeaders(t)}
          contentData={data}
          onRowClick={data => modalHandler.open(this.modalOptions(data))}
          totalData={totalData}
          onTableChange={table => this.getData({ ...table })}
          parseData={row => ({
            gender: t(`commons.gender.${row.gender}`),
            interval: this.getInterval(t, row),
            // maxAthletes: this.getMaxAthletes(t, row.maxAthletes),
            ageCheckDate: this.getDate(row.ageCheckDate)
          })}
        />
        {!race.deleted && <CreateCategoryButton modalityId={id} getData={() => this.getData()} />}
      </Container>
    );
  }
}
