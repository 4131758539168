import React, {useEffect, useState} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useHistory } from "react-router";
import { Container, Image } from "semantic-ui-react";
import decode from "jwt-decode";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import NoMatch, { NoMatchPage } from "./components/NoMatch";
import DCookieConsent from "./components/DCookieConsent";
import Login from "./screens/Login";
import Register from "./screens/Register";
import RecoverPassword from "./screens/RecoverPassword";
import UsersScreen from "./screens/UsersScreen";
import HomeScreen from "./screens/HomeScreen";
import RaceInscription from "./screens/Race/RaceInscriptions/InscriptionDetail";
import RaceDiscount from "./screens/Race/RaceDiscounts/DiscountDetail";

import UserDetail from "./screens/UsersScreen/UserDetail";
import Companies from "./screens/Companies";
import Races from "./screens/Races";
import NavBar from "./components/NavBar";
import DModal from "./components/DModal";
import DAlert from "./components/Dalert";
import CompanyDetails from "./screens/Companies/CompanyDetails";
import Title from "./components/Title";

import NotAuthEs from "../public/images/./notauthes.png";
import NotAuthEn from "../public/images/./notauthen.png";
import Clients from "./screens/Clients";
import ClientDetail from "./screens/Clients/ClientDetail";
import Inscriptions from "./screens/Inscriptions";
import InscriptionDetail from "./screens/Inscriptions/InscriptionDetail";
import RefundResult from "./screens/Race/RaceInscriptions/InscriptionDetail/RefundResult";
import Race from "./screens/Race";
import Modality from "./screens/Modality";
import Attributes from "./screens/Modality/ModalityAttributes/ModalityAttribute";

import RaceCreate from "./screens/Races/RaceCreate";
import RaceCopy from "./screens/Races/CopyRace";
import { clientApi } from "./apis";
import notificationsApi from "./apis/notifications"
import { setRole, setId } from "./reducers/user";
import CreateInscription from "./screens/Race/RaceInscriptions/CreateInscription";

import Config from "./screens/Config";
import Notifications from "./screens/Notifications";
import TemplateDetail from "./screens/Notifications/Template/TemplateDetail";
import NotificationDetail from "./screens/Notifications/Notification/NotificationDetail";
import QRReaders from "./screens/QRReaders";

const { MY_URL } = process.env;

const NotAuth = () => {
  const [t] = useTranslation();
  let history = useHistory();
  const lang = t("currentLanguage");

  const src = lang == "en" ? NotAuthEn : NotAuthEs;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image
        id="NotAuthImage"
        src={src}
        style={{ cursor: "pointer", objectFit: "contain" }}
        onClick={() => history.push("/")}
      />
    </div>
  );
};

//Aqui irian las rutas por roles, asi podemos dejar ciertas rutas sin el menu (COMO EL LOGIN), ademas de enviar automaticamente una pantalla de fallo en el acceso
const renderRoutes = (role, canSendNotifications) => {
  if (role === "ADMINISTRATOR") {
    return (
      <NavBar>
        <Container>
          <Title />
        </Container>
        <NoMatchPage />
        <Switch>
          <Route path="/home" exact component={HomeScreen}></Route>
          {/* Users */}
          <Route path="/users" exact component={UsersScreen} />
          <Route path="/user/:id" exact component={UserDetail} />

          {/* Companies */}
          <Route path="/companies" exact component={Companies} />
          <Route path="/company/:id?" exact component={CompanyDetails} />

          {/* Clients */}
          <Route path="/clients" exact component={Clients} />
          <Route path="/client/:id" exact component={ClientDetail} />

          {/* Races */}
          <Route path="/races" exact component={Races} />
          <Route path="/race/create" exact component={RaceCreate} />
          <Route path="/race/copy" exact component={RaceCopy} />

          {/* Events */}
          <Route
            path="/events"
            exact
            component={(props) => <Races {...props} type={"EVENT"} />}
          />
          {/* Associates */}
          <Route
            path="/associates"
            exact
            component={(props) => <Races {...props} type={"ASSOCIATES"} />}
          />

          {/* Race */}
          <Route
            path="/race/:id"
            exact
            component={(props) => <Race {...props} role={role} />}
          />
          <Route
            path="/race/:id/inscription/create"
            exact
            component={CreateInscription}
          />

          {/* Modality */}
          <Route
            path="/race/:raceId/modality/:id"
            exact
            component={(props) => <Modality {...props} role={role} />}
          />
          <Route
            path="/modality/category/:id"
            exact
            component={(props) => <Category {...props} role={role} />}
          />

          {/* Modality inscription */}
          <Route
            path="/race/:id/modality/:modalityId/inscription/create"
            exact
            component={CreateInscription}
          />

          {/* Modality attributes create */}
          <Route
            path="/race/:raceId/modality/:modalityId/attribute/create"
            exact
            component={Attributes}
          />
          {/* Modality attributes */}
          <Route
            path="/race/:raceId/modality/:modalityId/attribute/:id"
            exact
            component={Attributes}
          />

          {/* {Inscriptions} */}
          <Route path="/inscriptions" exact component={Inscriptions} />
          <Route path="/inscription/:id" exact component={InscriptionDetail} />

          {/* Race Inscription */}
          <Route
            path="/race/:raceId/inscription/:id"
            exact
            component={RaceInscription}
          />
          {/* Refund of money */}
          <Route
            path="/race/:raceId/inscription/:inscriptionId/:type"
            exact
            component={RefundResult}
          />
          {/* Race Discount */}
          <Route
            path="/race/:raceId/discount/:id"
            exact
            component={RaceDiscount}
          />
          {/* {Notifications} */}
          <Route
            path="/notifications"
            exact
            component={(props) => <Notifications {...props} role={role} />}
          />
          {/* Template Detail */}
          <Route
            path="/notifications/template/:id"
            exact
            component={(props) => <TemplateDetail {...props} role={role} />}
          />
          {/* Notification Detail */}
          <Route
            path="/notifications/notification/:id"
            exact
            component={(props) => <NotificationDetail {...props} role={role} />}
          />
          {/* Template Creation */}
          <Route
            path="/notifications/createTemplate"
            exact
            component={(props) => <TemplateDetail {...props} role={role} />}
          />
          {/* Notification Creation */}
          <Route
            path="/notifications/createNotif"
            exact
            component={(props) => <NotificationDetail {...props} role={role} />}
          />
          {/* {Configuration} */}
          <Route path="/config" exact component={Config} />

          <Route component={NoMatch} />
        </Switch>
      </NavBar>
    );
  } else if (role === "COMPANY") {
    return (
      <NavBar notifications={canSendNotifications}>
        <Container>
          <Title />
        </Container>
        <NoMatchPage />
        <Switch>
          <Route path="/home" exact component={HomeScreen}></Route>
          {/* Users */}
          <Route
            path="/users"
            exact
            render={(props) => <UsersScreen {...props} role={role} />}
          />
          <Route path="/user/:id" exact component={UserDetail} />
          {/* Races */}
          <Route path="/races" exact component={Races} />
          <Route path="/race/create" exact component={RaceCreate} />
          <Route path="/race/copy" exact component={RaceCopy} />

          {/* Events */}
          <Route
            path="/events"
            exact
            component={(props) => <Races {...props} type={"EVENT"} />}
          />
          {/* Associates */}
          <Route
            path="/associates"
            exact
            component={(props) => <Races {...props} type={"ASSOCIATES"} />}
          />

          {/* Race */}
          <Route
            path="/race/:id"
            exact
            component={(props) => <Race {...props} role={role} />}
          />
          <Route
            path="/race/:id/inscription/create"
            exact
            component={CreateInscription}
          />
          {/* Modality */}
          <Route
            path="/race/:raceId/modality/:id"
            exact
            component={(props) => <Modality {...props} role={role} />}
          />
          <Route
            path="/modality/category/:id"
            exact
            component={(props) => <Category {...props} role={role} />}
          />
          {/* Modality inscription */}
          <Route
            path="/race/:id/modality/:modalityId/inscription/create"
            exact
            component={CreateInscription}
          />
          {/* Modality attributes create */}
          <Route
            path="/race/:raceId/modality/:modalityId/attribute/create"
            exact
            component={Attributes}
          />
          {/* Modality attributes */}
          <Route
            path="/race/:raceId/modality/:modalityId/attribute/:id"
            exact
            component={Attributes}
          />
          {/* {Inscriptions} */}
          <Route path="/inscriptions" exact component={Inscriptions} />
          <Route path="/inscription/:id" exact component={InscriptionDetail} />
          {/* Race Inscription */}
          <Route
            path="/race/:raceId/inscription/:id"
            exact
            component={RaceInscription}
          />
          {/* Refund of money */}
          <Route
            path="/race/:raceId/inscription/:inscriptionId/:type"
            exact
            component={RefundResult}
          />
          {/* Race Discount */}
          <Route
            path="/race/:raceId/discount/:id"
            exact
            component={RaceDiscount}
          />
          canSendNotifications ? [
          <Route
            path="/notifications"
            exact
            component={(props) => <Notifications {...props} role={role} />}
          />
          ,
          <Route
            path="/notifications/template/:id"
            exact
            component={(props) => <TemplateDetail {...props} role={role} />}
          />
          ,
          <Route
            path="/notifications/notification/:id"
            exact
            component={(props) => <NotificationDetail {...props} role={role} />}
          />
          ,
          <Route
            path="/notifications/createTemplate"
            exact
            component={(props) => <TemplateDetail {...props} role={role} />}
          />
          ,
          <Route
            path="/notifications/createNotif"
            exact
            component={(props) => <NotificationDetail {...props} role={role} />}
          />
          ] : []
          <Route component={NoMatch} />
        </Switch>
      </NavBar>
    );
  } else if (role === "PLANNER") {
    return (
      <NavBar>
        <Container>
          <Title />
        </Container>
        <NoMatchPage />
        <Switch>
          <Route path="/home" exact component={HomeScreen}></Route>

          {/* Races */}
          <Route path="/races" exact component={Races} />
          <Route path="/race/create" exact component={RaceCreate} />

          {/* Events */}
          <Route
            path="/events"
            exact
            component={(props) => <Races {...props} type={"EVENT"} />}
          />
          {/* Associates */}
          <Route
            path="/associates"
            exact
            component={(props) => <Races {...props} type={"ASSOCIATES"} />}
          />

          {/* Race */}
          <Route
            path="/race/:id"
            exact
            component={(props) => <Race {...props} role={role} />}
          />
          <Route
            path="/race/:id/inscription/create"
            exact
            component={CreateInscription}
          />

          {/* Modality */}
          <Route
            path="/race/:raceId/modality/:id"
            exact
            component={(props) => <Modality {...props} role={role} />}
          />
          {/* Modality attributes create */}
          <Route
            path="/race/:raceId/modality/:modalityId/attribute/create"
            exact
            component={Attributes}
          />
          {/* Modality attributes */}
          <Route
            path="/race/:raceId/modality/:modalityId/attribute/:id"
            exact
            component={Attributes}
          />

          {/* Modality inscription */}
          <Route
            path="/race/:id/modality/:modalityId/inscription/create"
            exact
            component={CreateInscription}
          />

          {/* Race Discount */}
          <Route
            path="/race/:raceId/discount/:id"
            exact
            component={RaceDiscount}
          />

          {/* {Inscriptions} */}
          <Route path="/inscriptions" exact component={Inscriptions} />
          <Route path="/inscription/:id" exact component={InscriptionDetail} />
          {/* Race Inscription */}
          <Route
            path="/race/:raceId/inscription/:id"
            exact
            component={RaceInscription}
          />

          {/* Modality */}
          <Route
            path="/race/:raceId/modality/:id"
            exact
            component={(props) => <Modality {...props} role={role} />}
          />
          <Route
            path="/modality/category/:id"
            exact
            component={(props) => <Category {...props} role={role} />}
          />

          {/* QR Readers */}

          {/* {Inscriptions} */}
          {/* <Route path="/inscriptions" exact component={Inscriptions} />
          <Route path="/inscription/:id" exact component={InscriptionDetail} /> */}

          <Route component={NoMatch} />
        </Switch>
      </NavBar>
    );
  } else if (role == "POINT_OF_SALE") {
    return (
      <NavBar>
        <Container>
          <Title />
        </Container>
        <NoMatchPage />
        <Switch>
          <Route path="/home" exact component={HomeScreen}></Route>

          {/* Races */}
          <Route path="/races" exact component={Races} />
          
          {/* Events */}
          <Route
            path="/events"
            exact
            component={(props) => <Races {...props} type={"EVENT"} />}
          />
          {/* Associates */}
          <Route
            path="/associates"
            exact
            component={(props) => <Races {...props} type={"ASSOCIATES"} />}
          />

          {/* Race */}
          <Route
            path="/race/:id"
            exact
            component={(props) => <Race {...props} role={role} />}
          />
          <Route
            path="/race/:id/inscription/create"
            exact
            component={CreateInscription}
          />

          {/* Modality inscription */}
          <Route
            path="/race/:id/modality/:modalityId/inscription/create"
            exact
            component={CreateInscription}
          />

          {/* {Inscriptions} */}
          <Route path="/inscriptions" exact component={Inscriptions} />
          <Route path="/inscription/:id" exact component={InscriptionDetail} />
          {/* Race Inscription */}
          <Route
            path="/race/:raceId/inscription/:id"
            exact
            component={RaceInscription}
          />

          {/* {Inscriptions} */}
          <Route path="/inscriptions" exact component={Inscriptions} />
          <Route path="/inscription/:id" exact component={InscriptionDetail} />

          <Route component={NoMatch} />
        </Switch>
      </NavBar>
    );
  } else {
    return <NotAuth />;
  }
};



const isLoginPage = () => {
  const currentUrl = window.location.href
  //currentUrl can have trailing slash
  return (currentUrl === MY_URL) || (currentUrl.substr(0, currentUrl.length - 1) === MY_URL)
}

const App = () => {
  const [cookies] = useCookies(["token"]);
  const [canSendNotifications, setNotificationsState] = useState(false);
  let role = null;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  let id = null;
  
  const notificationsVerify = async () => {
    if(role!=="COMPANY")return;

    const notificationsVerify = await notificationsApi.verify();
    setNotificationsState(notificationsVerify.canSendNotifications)
  }

  try {
    clientApi.setToken(cookies?.token?.token);
    role = decode(cookies?.token?.token).role;
    id = decode(cookies?.token?.token).id;
    dispatch(setRole(role));
    dispatch(setId(id));
    dispatch(setNotifications(canSendNotifications));
  } catch (err) {
    // console.log(err);
  }

  useEffect(() => {
    notificationsVerify()
  })

  return (
    <>
      <DModal />
      <DAlert />
      {/* Dont show cookies warning at login page */}
      {!isLoginPage() &&  <DCookieConsent t={t} main={true}/>}
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/register/:token" exact component={Register} />
          <Route
            path="/recoverPassword/:token"
            exact
            component={RecoverPassword}
          />
          {renderRoutes(role,canSendNotifications)}
        </Switch>
      </Router>
    </>
  );
};

export default App;
