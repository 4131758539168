//@ts-check
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Container,
  Segment,
  Grid,
  Header,
  Button,
  Modal,
  Icon
} from "semantic-ui-react";
import moment from "moment";
import notificationsApi from "../../../../apis/notifications";
import companiesApi from "../../../../apis/companies";
import Form from "../../../../components/Form";
import forms, { EnumNotificationTypes, notificationPushForm } from "./forms";
import templatesApi from "../../../../apis/templates";
import clientsApi from "../../../../apis/clients";
import regionsApi from "../../../../apis/regions";
import racesApi from "../../../../apis/races";
import RichTextEditor from "../../../../components/RichTextEditor";
import { DAlert } from "../../../../components/Dalert";
import { modalHandler } from "../../../../components/DModal";
import FilterableTable from "../../../../components/FilterableTable";
import FluidResponsiveButton from "../../../../components/FluidResponsiveButton";
import { getTableHeaders } from "./util";
import { deliverStatus } from "../../../../util/usersUtil";
import Recipients, { actionSubject } from "./Recipients";

const styles = {
  BottomMargin: {
    marginBottom: "3%"
  },
  TopMargin: {
    marginTop: "1%"
  }
};

const createNotificationUrl = "/notifications/createNotif";

const roleForm = {
  ADMINISTRATOR: forms.admin,
  COMPANY: forms.company
};

@withTranslation()
@connect(state => ({
  //template: state.templates.actualTemplate
}))
export default class NotificationDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardsPerRow: 3,
      showValidation: false,
      showColorSelector: false,
      color: "#ABB8C3",
      messageForm: {
        description: ""
      },
      currentData: {},
      templates: [],
      races: [],
      companies: [],
      mailTo: null,
      inscriptionsList: [],
      notificationStatus: [],
      selectAll: false,
      isSent: true,
      selectedCompany: null,
      race_id: null,
      recipients: {},
      tempRecipients: {},
      regions: [],
      modalities: []
    };
  }

  async componentDidMount() {
    this.setState({
      isSent: this.props.location.isSent
    });
    if (this.props.match.url !== createNotificationUrl) {
      await this.getNotificationData();
      await this.getNotificationStatus();
    }
    // await this.getTemplates();
    await this.getRaces();
    await this.getRegions();
    if (this.props.role === "ADMINISTRATOR") await this.getCompanies();
  }

  async getTemplates(template_type) {
    const data = await templatesApi.fetchTemplates({
      pageSize: -1,
      template_type
    }); //get all templates
    const templates = data.rows.map(template => ({
      text: template.name,
      value: template.id
    }));
    this.setState({ templates });
  }

  async getRaces(company_id) {
    let params = { pageSize: -1 };
    if (company_id) params.companyId = company_id;
    const data = await racesApi.fetchRaces(params); //get all races
    const races = data.rows.map(race => ({
      text: race.name,
      value: race.id
    }));
    this.setState({ races });
  }

  async getCompanies() {
    const data = await companiesApi.get({ pageSize: -1 }); //get all companies
    const companies = data.rows.map(company => ({
      text: company.name,
      value: company.id
    }));
    this.setState({ companies });
  }

  async getRegions() {
    const response = await regionsApi.fetchRegions();
    this.setState({
      regions: response.map(province => ({
        title: province.name,
        value: province.slug
      }))
    });
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  async getNotificationData() {
    const response = await notificationsApi.getNotification(
      this.props.match.params.id
    );

    const { currentData } = this.state;

    await this.setState({
      currentData: { ...currentData, ...response },
      messageForm: {
        description: response ? response.message : ""
      }
    });
    if (response.race_id) this.getCurrentRecipents(response.race_id);
    if (response.status === "SENT") this.setState({ isSent: true });
  }

  async getNotificationStatus() {
    const response = await notificationsApi.getNotificationStatus(
      parseInt(this.props.match.params.id)
    );

    this.setState({ notificationStatus: response });
  }

  handleResize(e) {
    try {
      if (window.innerWidth < 736) {
        this.setState({ cardsPerRow: 1 });
      } else if (window.innerWidth < 992) {
        this.setState({ cardsPerRow: 2 });
      } else {
        this.setState({ cardsPerRow: 3 });
      }
    } catch (error) {}
  }

  async selectAll() {
    actionSubject.next();
    return;
    // let { selectAll, inscriptionsList } = this.state;
    // let inscriptionsListModified = [];
    // inscriptionsList.map(inscription => {
    //   inscriptionsListModified.push({
    //     send: !selectAll,
    //     athleteId: inscription.athleteId,
    //     athleteFullName: inscription.athleteFullName
    //   });
    // });
    // await this.setState({
    //   inscriptionsList: inscriptionsListModified,
    //   selectAll: !selectAll
    // });
    // modalHandler.refreshOptions(this.modalOptions());
  }

  modalOptions = () => {
    const { t } = this.props;

    return {
      header: t(`notificationScreen.modal.title`),
      size: "large",
      content: (
        <Recipients
          companyId={this.state.currentData.company_id}
          modalityId={this.state.currentData.modality_id}
          region={this.state.currentData.region}
          onRecipientsChange={recipients =>
            this.setState({ tempRecipients: recipients })
          }
          recipients={this.state.recipients}
          raceId={this.state.currentData.race_id}
        />
      ),
      actions: (
        <Grid>
          <Grid.Column width="16"></Grid.Column>
          <Grid.Column width="4" textAlign="left">
            <Button
              fluid
              color="vk"
              onClick={async () => {
                modalHandler.refreshOptions(await this.modalPrefilter());
                // this.setState({ inscriptionsList: [] });
              }}
            >
              {t("commons.comeBack")}
            </Button>
          </Grid.Column>

          <Grid.Column width="4" textAlign="right">
            <Button
              fluid
              color="grey"
              onClick={() => {
                actionSubject.next(false);
              }}
            >
              {t("commons.removeAll")}
            </Button>
          </Grid.Column>
          <Grid.Column width="4" textAlign="right">
            <Button
              fluid
              color="vk"
              onClick={() => {
                actionSubject.next(true);
              }}
            >
              {t("commons.selectAll")}
            </Button>
          </Grid.Column>
          <Grid.Column width="4" textAlign="right">
            <Button
              fluid
              color="vk"
              onClick={() => {
                const { tempRecipients } = this.state;
                this.setState({
                  loading: false,
                  race_id: null,
                  recipients: tempRecipients
                });
                modalHandler.close();
              }}
            >
              {t("commons.ok")}
            </Button>
          </Grid.Column>
        </Grid>
      )
    };
  };

  modalOptionsStatus = () => {
    const { t } = this.props;
    const tableHeaders = getTableHeaders(t, true);

    return {
      header: t(`notificationScreen.modal.titleStatus`),
      size: "large",
      content: (
        <Modal.Description>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width="16">
                {
                  // compontent
                  <FilterableTable
                    id={"NotificationStatus"}
                    tableHeaders={tableHeaders}
                    autoPaginate
                    contentData={this.state.notificationStatus}
                    hideFilters={true}
                    totalData={this.state.notificationStatus.length}
                    // hidePagination={true}
                    parseData={data => {
                      const index = deliverStatus(t).findIndex(
                        x => x.value === data.status
                      );
                      return {
                        status: deliverStatus(t)[index].text
                      };
                    }}
                  />
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      ),
      actions: <Grid></Grid>
    };
  };

  onChangeCompany = async company_id => {
    const { currentData } = this.state;
    currentData.company_id = company_id;
    // await this.getRaces(company_id);
    await this.setState({
      selectedCompany: company_id,
      currentData: {
        ...currentData,
        company_id,
        race_id: null,
        modality_id: null
      }
    });
    modalHandler.refreshOptions(await this.modalPrefilter());
  };

  async getRaceModality(race_id) {
    const modalities = await racesApi.fetchModalities({ id: race_id });
    await this.setState({
      modalities: modalities.map(modality => ({
        text: modality.name,
        value: modality.id
      }))
    });
  }

  async onRaceChange(race_id) {
    const { currentData } = this.state;

    await this.setState({
      currentData: { ...currentData, race_id, modality_id: null }
    });
    modalHandler.refreshOptions(await this.modalPrefilter());
  }

  async modalPrefilter() {
    const { t, role } = this.props;
    const {
      currentData,
      showValidation,
      templates,
      races,
      companies,
      selectedCompany,
      regions
    } = this.state;

    if (currentData.company_id) await this.getRaces(currentData.company_id);
    if (currentData.race_id) await this.getRaceModality(currentData.race_id);
    let selectedForm = [];
    if (role) {
      selectedForm = forms.prefilterForm(
        t,
        companies,
        currentData.company_id ? this.state.races : [],
        currentData.race_id ? this.state.modalities : [],
        role,
        this.onChangeCompany.bind(this),
        this.onRaceChange.bind(this),
        selectedCompany,
        regions
      );
    }

    return {
      header: t(`notificationScreen.modal.titlePrefilter`),
      size: "large",
      content: (
        <Modal.Description>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width="16">
                {
                  // compontent
                  <Segment basic>
                    <Form
                      defaultState={currentData}
                      fields={selectedForm}
                      onFormChange={formData => {
                        this.setState({
                          currentData: {
                            ...currentData,
                            ...formData,
                            id: currentData.id
                          }
                        });
                      }}
                      // defaultState={this.state.currentData}
                      showValidation={showValidation}
                      style={{ zIndex: 100 }} // to avoid Rich Text Editor overlap on combo select
                    />
                  </Segment>
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      ),
      actions: (
        <Grid>
          <Grid.Column width="8"></Grid.Column>
          <Grid.Column width="4"></Grid.Column>
          <Grid.Column width="4">
            <Button
              fluid
              color="vk"
              onClick={async () => {
                // this.setState({ loading: false });

                // if (
                //   this.state.currentData.company_id &&
                //   !this.state.currentData.race_id
                // ) {
                //   return DAlert.error({
                //     title: t("notificationScreen.selectRace")
                //   });
                // }

                // await this.getCurrentRecipents(this.state.currentData.race_id);
                modalHandler.refreshOptions(this.modalOptions());
              }}
            >
              {t("commons.next")}
            </Button>
          </Grid.Column>
        </Grid>
      )
    };
  }

  async mailToModalHandler() {
    modalHandler.open(await this.modalPrefilter());
  }

  async save(t, isTest, isSent) {
    if (this.state.currentData && !this.state.currentData.ISFORMVALID) {
      this.setState({ showValidation: true });
      return;
    }
    const companyId1 = this.state.currentData.company_id;
    const companyId2 = this.state.selectedCompany;
    const companyId =
      companyId1 && companyId1 !== ""
        ? companyId1
        : companyId2 && companyId2 !== ""
        ? companyId2
        : null;
    const updateData = {
      ...this.state.currentData,
      message: this.state.messageForm.description,
      company_id: companyId,
      // this.state.selectedCompany != ""
      //   ? this.state.selectedCompany
      //   : null,
      // company_id:
      //   this.state.currentData.company_id != ""
      //     ? this.state.currentData.company_id
      //     : null,
      race_id:
        this.state.currentData.race_id != ""
          ? this.state.currentData.race_id
          : null,
      template_id: this.state.currentData.template_id
        ? this.state.currentData.template_id
        : null,
      subject: this.state.currentData.subject,
      status: isSent ? "SENT" : "PENDING",
      modality_id: this.state.currentData.modality_id,
      region: this.state.currentData.region
    };

    const response =
      this.props.match.url === createNotificationUrl
        ? await notificationsApi.createNotification(updateData)
        : await notificationsApi.updateNotification(
            this.props.match.params.id,
            updateData
          );
    if (response && response.id) {
      this.props.match.url === createNotificationUrl
        ? DAlert.success({
            title: isTest
              ? t("notificationScreen.testSuccess")
              : t("notificationScreen.createdSuccess")
          })
        : DAlert.success({
            title: isTest
              ? t("notificationScreen.testSuccess")
              : t("notificationScreen.updatedSuccess")
          });
      this.props.match.url === createNotificationUrl
        ? this.props.history.push({
            pathname: `/notifications/notification/${response.id}`,
            editable: true,
            state: { id: response.id }
          })
        : null;
    } else DAlert.error({ title: t("notificationScreen.error") });
  }

  async testEmail(t) {
    let response;
    const isTest = true;
    try {
      response = await notificationsApi.send({
        subject: this.state.currentData.subject,
        template: this.state.messageForm.description,
        race_id: this.state.currentData.race_id,
        notification_id: parseInt(this.props.match.params.id),
        isTest: true
      });
      this.save(t, isTest);
    } catch (error) {
      DAlert.error({ title: t("notificationScreen.error") });
    }
  }

  async send(t) {
    // let mailTo = [];
    // this.state.inscriptionsList.map(inscription => {
    //   if (inscription.send) mailTo.push(inscription.athleteId);
    // });

    //get all recipients with value true
    const mailTo = Object.entries(this.state.recipients).reduce(
      (acc, [id, val]) => {
        if (val) {
          return [...acc, parseInt(id)];
        }

        return acc;
      },
      []
    );

    if (mailTo.length) {
      try {
        const response = await notificationsApi.send({
          subject: this.state.currentData.subject,
          template: this.state.messageForm.description,
          race_id: this.state.currentData.race_id || null,
          recipients: mailTo,
          notification_id: parseInt(this.props.match.params.id)
        });

        if (response && response.ok) {
          await this.getNotificationData();
          await this.save(t, false, true);
          this.setState({ isSent: true });
        } else DAlert.error({ title: t("notificationScreen.error") });
      } catch (error) {
        DAlert.error({ title: t("notificationScreen.error") });
      }
    } else {
      DAlert.error({ title: t("notificationScreen.emptyRecipents") });
    }
    this.getNotificationStatus();
  }

  textInsert(autotext) {
    const { messageForm, data, currentData } = this.state;

    if (autotext !== 0) {
      const description = messageForm.description + autotext;

      this.setState({
        messageForm: { ...messageForm, description },
        // data: { ...currentData, ...data, message: description },
        currentData: { ...currentData, ...data, message: description }
      });
    }
  }

  async getCurrentRecipents(race_id) {
    const { t } = this.props;
    if (!race_id && this.props.role === "COMPANY") {
      DAlert.error({ title: t("notificationScreen.selectRace") });
      this.setState({ inscriptionsList: [] });
      return;
    }

    const inscriptions = race_id
      ? await racesApi.fetchInscriptions({ id: race_id, pageSize: 9999 })
      : await clientsApi.get({ pageSize: 99999 });

    let inscriptionsArray = [];
    const inscriptionsList = inscriptions.rows.filter(function (inscription) {
      return (
        (inscription.athleteId !== null || inscription.user_id) &&
        (inscription.reciveinfo || inscription.reciveInfo)
      );
    });
    inscriptionsList.map((inscription, i) => {
      inscriptionsArray.push({
        send: false,
        athleteId: race_id ? inscription.athleteId : inscription.id,
        athleteFullName: race_id
          ? inscription.athleteFullName
          : inscription.fullName
      });
    });
    let inscriptionsArrayUnique = inscriptionsArray.filter(
      (inscription, index, self) =>
        index === self.findIndex(ins => ins.athleteId === inscription.athleteId)
    );

    this.setState({ inscriptionsList: inscriptionsArrayUnique });
  }

  sentAlert(t) {
    DAlert.error({ title: t("notificationScreen.alreadySent") });
  }

  async handleChange(formData) {
    const { currentData, messageForm } = this.state;

    const newCurrentData = { ...currentData, ...formData, id: currentData.id };

    let state = {
      currentData: newCurrentData
    };
    if (
      formData.notification_type === null ||
      (formData.notification_type &&
        currentData.notification_type &&
        currentData.notification_type !== formData.notification_type)
    ) {
      //clean form
      state = {
        currentData: {
          ...newCurrentData,
          template_id: "",
          notification_push_image: "",
          message: ""
        }
      };
    }

    this.setState(state);
    const { template_id, race_id } = formData;

    if (currentData.notification_type !== formData.notification_type) {
      await this.getTemplates(formData.notification_type);
    }

    if (
      template_id !== "" &&
      template_id !== 0 &&
      template_id &&
      template_id !== currentData.template_id
    ) {
      let templateMessage = await templatesApi.getTemplate(template_id);

      try {
        if (templateMessage.message) {
          this.setState({
            messageForm: {
              ...messageForm,
              description: templateMessage.message
            },
            currentData: {
              ...currentData,
              message: templateMessage.message,
              template_id,
              notification_push_image: templateMessage.notification_push_image
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
      // this.setState({ messageForm, data, currentData });
    } else if (
      race_id !== "" &&
      race_id !== 0 &&
      race_id &&
      race_id !== currentData.race_id
    ) {
      this.getCurrentRecipents(race_id);
      this.setState({ race_id });
    }
  }

  render() {
    let { history, t, role } = this.props;

    const {
      showValidation,
      currentData,
      messageForm,
      templates,
      races
    } = this.state;
    let selectedForm = [];
    if (role) {
      selectedForm = roleForm[role](t, templates, this.textInsert.bind(this));
    }

    return (
      <Container>
        <Segment basic>
          <Grid>
            <Grid.Column width={16}>
              <Segment basic>
                <Form
                  fields={selectedForm}
                  onFormChange={(formData) => {
                    // this.setState({
                    //   currentData: { ...currentData, ...formData, id: data.id }
                    // });
                    this.handleChange(formData);
                  }}
                  defaultState={this.state.currentData}
                  showValidation={showValidation}
                  style={{ zIndex: 100 }} // to avoid Rich Text Editor overlap on combo select
                />
              </Segment>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column width={16}>
              {this.state.currentData.notification_type ===
                EnumNotificationTypes.EMAIL && (
                <Segment basic>
                  <Header size="small">
                    {t("notificationScreen.fields.message")}
                  </Header>
                  <div style={styles.BottomMargin}>
                    <RichTextEditor
                      defaultContent={currentData.message}
                      onBlurEditor={(data) => {
                        this.setState({
                          messageForm: { ...messageForm, description: data },
                        });
                      }}
                    />
                  </div>
                </Segment>
              )}

              {this.state.currentData.notification_type ===
                EnumNotificationTypes.NOTIFICATION_PUSH && (
                <Segment basic>
                  <Header size="small">
                    {t("notificationScreen.fields.notificationPush")}
                  </Header>
                  <div style={styles.BottomMargin}>
                    <Form
                      fields={notificationPushForm(t)}
                      defaultState={this.state.currentData}
                      showValidation={showValidation}
                      onFormChange={(formData) => {
                        this.setState({
                          messageForm: {
                            ...this.state.messageForm,
                            description: formData.message || "",
                          },
                          currentData: {
                            ...this.state.currentData,
                            message: formData.message,
                            notification_push_image:
                              formData.notification_push_image,
                          },
                        });
                      }}
                    />
                  </div>
                </Segment>
              )}
            </Grid.Column>
          </Grid>
          <Grid>
            <FluidResponsiveButton
              icon="triangle left"
              labelPosition="left"
              size="small"
              primary
              content={t("templateScreen.comeBack")}
              onClick={() => this.props.history.goBack()}
            ></FluidResponsiveButton>
            {this.state.isSent
              ? null
              : [
                  <FluidResponsiveButton
                    icon="save"
                    labelPosition="left"
                    size="small"
                    primary
                    content={t("notificationScreen.save")}
                    onClick={() => {
                      this.state.isSent ? this.sentAlert(t) : this.save(t);
                    }}
                  ></FluidResponsiveButton>,
                ]}
            {!this.state.isSent &&
              this.state.currentData.notification_type ===
                EnumNotificationTypes.EMAIL && (
                <FluidResponsiveButton
                  icon="cogs"
                  labelPosition="left"
                  size="small"
                  primary
                  content={t("notificationScreen.test")}
                  onClick={() => {
                    this.state.isSent ? this.sentAlert(t) : this.testEmail(t);
                  }}
                ></FluidResponsiveButton>
              )}

            {!this.state.isSent && currentData.status == "PENDING" && (
              <FluidResponsiveButton
                icon={this.state.isSent ? "info" : "users"}
                labelPosition="left"
                size="small"
                primary
                content={t("notificationScreen.recipents")}
                onClick={async () => {
                  await this.getNotificationStatus();
                  this.mailToModalHandler();
                }}
              />
            )}
            {this.state.isSent &&
              this.state.currentData.notification_type ===
                EnumNotificationTypes.EMAIL && (
                <FluidResponsiveButton
                  icon={this.state.isSent ? "info" : "users"}
                  labelPosition="left"
                  size="small"
                  primary
                  content={t("commons.status")}
                  onClick={async () => {
                    await this.getNotificationStatus();
                    this.state.isSent
                      ? this.state.notificationStatus.length === 0
                        ? moment
                            .utc()
                            .diff(moment(this.state.currentData.updatedAt)) >
                          1000 * 60 * 60 * 24 * 5
                          ? DAlert.error({
                              message: t("notificationScreen.noMoreThan5Days"),
                            })
                          : DAlert.error({
                              title: t("notificationScreen.statusInProgress"),
                            })
                        : modalHandler.open(this.modalOptionsStatus())
                      : this.mailToModalHandler();
                  }}
                />
              )}
            {this.state.isSent ||
            this.props.match.url === createNotificationUrl ? null : (
              <FluidResponsiveButton
                icon="send"
                labelPosition="left"
                size="small"
                primary
                content={t("notificationScreen.send")}
                onClick={() => {
                  this.state.isSent ? this.sentAlert(t) : this.send(t);
                }}
              ></FluidResponsiveButton>
            )}
          </Grid>
        </Segment>
      </Container>
    );
  }
}
